import { Component, OnInit } from '@angular/core';
import { InformationPageEnum } from '../enum/information-page-enum';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  informationPageNumber = InformationPageEnum.TERMS_OF_USE;

  constructor() { }

  ngOnInit(): void {
  }

}
