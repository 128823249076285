import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { I18nService } from '../../../core/services/i18n.service';
import { StorageService } from 'src/app/models/storage/storage.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
  selector: 'app-nc-consent',
  templateUrl: './nc-consent.component.html',
  styleUrls: ['./nc-consent.component.scss'],
})
export class NcConsentComponent implements OnInit, AfterViewInit {
  showCookies: boolean;
  cookieUrl: string;

  is1536 = this.responsiveService.moreThan1536();

  constructor(
    private responsiveService: ResponsiveService,
    private storageService: StorageService,
    private renderer: Renderer2,
    private i18nService: I18nService
  ) { }

  ngOnInit() {
    this.showCookies = true;

    if (this.storageService.get('cookiesRead')) {
      this.showCookies = false;
    }
  }

  acceptCookies() {
    this.storageService.set('cookiesRead', '1');
    this.showCookies = false;
  }

  onResize() {
    this.is1536 = this.responsiveService.moreThan1536();
    this.adjustBottomPosition();
  }
  ngAfterViewInit() {
    this.adjustBottomPosition(); // Ajustar bottom al cargar la vista
  }
  adjustBottomPosition() {
    const footer = document.querySelector<HTMLElement>('.footer');
    const footerMobile = document.querySelector<HTMLElement>('.mobile-footer');
    const elementoDinamico =
      document.querySelector<HTMLElement>('.cookie-container');
    if (footer && elementoDinamico && !footerMobile) {
      console.log('Dentro del if');
      const footerAltura = footer.offsetHeight;
      // Ajustamos el valor de bottom dinámicamente usando Renderer2
      this.renderer.setStyle(elementoDinamico, 'top', 'auto');
      this.renderer.setStyle(
        elementoDinamico,
        'bottom',
        `${footerAltura + 2}px`
      );
    }
  }
}
