import { Component, HostListener, OnInit } from '@angular/core';
import { ModelService } from '../../core/services/model/model.service';
import { Router } from '@angular/router';
import { Model } from '../../models/model';
import { ResponsiveService } from '../../core/services/responsive.service';
import { GalleryService } from '../../core/services/gallery/gallery.service';
import { environment } from 'src/environments/environment';
import { I18nService } from 'src/app/core/services/i18n.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UpdateMetaService } from 'src/app/core/services/seo/meta-service.service';
import { CategoriesService } from 'src/app/core/services/categories/categories.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  allModels = this.galleryService.allModels$;
  //sliderModels = this.galleryService.sliderModels$;
  gridModels = this.galleryService.gridModels$;
  //areMoreFiles = this.galleryService.areMoreFiles$;

  popularModels: Model[] = [];
  gridModelsToShow: Model[] = [];
  isPublicApp = true;
  categorySelectedId: number;
  areMoreFilesToLoad = false;

  adSenseWidth: number;
  adSenseHeight: number;

  modelsToShow: any;
  userAvatar: any;
  userName: string;
  modelsToLoad: number;

  /**
   * App gallery model item
   */
  sliderWidthPercent = 10;
  sliderSeparation = 0;
  sliderItemHeight = 95;

  isMobile = this.responsiveService.mobileG();
  is1280 = this.responsiveService.isxxll();

  downloadManualsUrl: string;

  private resizeSubject = new Subject();

  constructor(
    private modelService: ModelService,
    private router: Router,
    private responsiveService: ResponsiveService,
    public galleryService: GalleryService,
    private i18nService: I18nService,
    private updateMetaService: UpdateMetaService,
    private categoriesService: CategoriesService
  ) {
    this.resizeSubject.pipe(debounceTime(300)).subscribe(() => {
      this.getPopularModels();
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.resizeSubject.next(event);
  }

  async ngOnInit() {
    this.updateMetaService.updateMetaTags('Home');
    this.downloadManualsUrl = environment.apiUrl + '/download-manuals-file';
    this.isPublicApp = environment.isPublicApp;
    this.getPopularModels();
    this.getAdsenseSize();
    this.categoriesService.setComesFromHome(false);
  }

  itemClick(code: string): void {
    this.router.navigateByUrl(`/model/${code}`);
  }

  Noprofessional() {
    this.router.navigateByUrl('/new3d/load/professional');
  }

  /**
   * Se ejecuta al redimensionar la ventana
   */
  onResize() {
    this.isMobile = this.responsiveService.mobileG();
    this.is1280 = this.responsiveService.isxxll();
    // this.getPopularModels();
    this.getAdsenseSize();
  }

  /**
   * Switch para mostrar las categorias de los modelos populares
   * @param categoryId
   * @returns
   */
  categoryTxt(categoryId: number) {
    switch (categoryId) {
      case 1:
        return this.i18nService.getText('eCommerce');
      case 2:
        return this.i18nService.getText('engineering');
      case 3:
        return this.i18nService.getText('healthCare');
      case 4:
        return this.i18nService.getText('security');
      case 5:
        return this.i18nService.getText('education');
      case 6:
        return this.i18nService.getText('various');
      case 7:
        return this.i18nService.getText('gastronomy');
      case 8:
        return this.i18nService.getText('artAndCulture');
    }
  }

  /**
   * Obtiene el nombre completo del usuario
   * @param model
   * @returns
   */
  getFullName(model: any): string {
    const name = model.full_name.name?.trim() ?? '';
    const surname = model.full_name.surname?.trim() ?? '';

    return `${name} ${surname}`.trim();
  }

  /**
   * Obtiene los modelos para el slider de home
   */
  getPopularModels() {
    if (this.responsiveService.moreThan1024()) {
      this.modelsToLoad = 8;
    } else {
      this.modelsToLoad = 6;
    }

    this.modelService
      .getPopularModels([], this.modelsToLoad, 1, '')
      .subscribe((models: any) => {
        this.modelsToShow = models.data;
      });
  }

  /**
   * Obtiene las medidas del anuncio
   */
  getAdsenseSize() {
    if (this.responsiveService.lessThan414()) {
      this.adSenseWidth = 300;
      this.adSenseHeight = 50;
    }
    if (this.responsiveService.isMobile414()) {
      this.adSenseWidth = 320;
      this.adSenseHeight = 50;
    }
    if (
      this.responsiveService.tabletG() ||
      this.responsiveService.specialMovilResolution()
    ) {
      this.adSenseWidth = 428;
      this.adSenseHeight = 60;
    }
    if (
      (this.responsiveService.is768() || this.responsiveService.is834()) &&
      !this.responsiveService.specialMovilResolution()
    ) {
      this.adSenseWidth = 728;
      this.adSenseHeight = 90;
    }
    if (this.responsiveService.isxxll()) {
      this.adSenseWidth = 120;
      this.adSenseHeight = 600;
    }
    if (this.responsiveService.moreThan1536()) {
      this.adSenseWidth = 160;
    }
  }

  goToProccessingModels() {
    this.categoriesService.setComesFromHome(true);
    this.router.navigate(['/gallery']);
  }
}
