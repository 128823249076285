export const EXTENSION_FILE_TYPE_I18N_ENTRIES = {

  extensionFileType_Data1: {
    es: '<p>Un archivo .obj /.ply estándar suele ir</p><p>acompañado de los ficheros .mtl y .jpg o .png</p>',
    en: '<p>A standard .obj or .ply file usually goes</p><p>together with .mtl and .jpeg or .png files</p>',
    pt: '<p>Um ficheiro .obj /.ply padrão é geralmente</p><p>acompanhado dos ficheiros .mtl e .jpg ou .png </p>',
    fr: '<p>Un fichier .obj /.ply standard est</p><p>généralement accompagné des fichiers .mtl et .jpg ou .png </p>',
    eus: '<p>.obj / .ply fitxategi arruntarekin batera, .mtl eta</p><p>.jpg edo .png luzapena duten fitxategiak joan ohi dira</p>',
    cat: '<p>Un fitxer .obj o .ply sol anar</p><p>acompanyat dels fitxers .mtl i .jpeg o .png </p>',
  },

  extensionFileType_Data2: {
    es: '<p>Si quieres subir estos ficheros haz click en</p><p>aceptar y selecciona los otros ficheros a subir.</p>',
    en: '<p>If you want to upload these files, click</p><p>Accept and select the other files to upload.</p>',
    pt: '<p>Caso pretenda carregar estes ficheiros, clique em</p><p>aceitar e selecione os outros ficheiros a carregar.</p>',
    fr: '<p>Si vous souhaitez télécharger ces fichiers,</p><p>cliquez sur Accepter puis sélectionnez les</p><p>autres fichiers à télécharger.</p>',
    eus: '<p>Fitxategi horiek kargatu nahi badituzu, egin klik Onartu</p><p>aukeran eta hautatu igo nahi dituzun beste fitxategiak.</p>',
    cat: '<p>Si vols carregar aquests fitxers, fes clic a</p><p>Accepta i selecciona els altres fitxers per carregar.</p>',
  },

  extensionFileType_Data3: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

};
