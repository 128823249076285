import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { HomeStepsConfig } from 'src/app/commons/enum/first-experience-configs';
import {
  FirstExperienceCloseEnum,
  FirstExperienceHomeStepsEnum,
} from 'src/app/commons/enum/first-experience-enum';
import { TranslationModeEnum } from 'src/app/commons/enum/translation-mode-enum';
import { CommsService } from 'src/app/core/services/comms.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { FirstExperienceLoadDialogComponent } from '../first-experience-load-dialog/first-experience-load-dialog.component';

@Component({
  selector: 'app-first-experience-home-dialog',
  templateUrl: './first-experience-home-dialog.component.html',
  styleUrls: ['./first-experience-home-dialog.component.scss'],
})
export class FirstExperienceHomeDialogComponent
  implements OnInit, AfterViewInit
{
  welcomeTitle: string = '';
  firstExperienceCloseEnum: typeof FirstExperienceCloseEnum =
    FirstExperienceCloseEnum;
  allSteps: typeof FirstExperienceHomeStepsEnum = FirstExperienceHomeStepsEnum;
  currentStep: FirstExperienceHomeStepsEnum =
    FirstExperienceHomeStepsEnum.FIRST;
  changeTemplate: boolean = false;
  stepDescriptions = HomeStepsConfig;

  outline: number;
  offSet: number;
  leftPosition: number;
  rightPosition: number;
  topPosition: number;
  initialDialogWidth: number;
  initialDialogHeight: number;

  constructor(
    public dialog: MatDialogRef<FirstExperienceHomeDialogComponent>,
    private matDialog: MatDialog,
    private i18nService: I18nService,
    private commsService: CommsService,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.commsService.getCurrentUser().subscribe((user) => {
      this.welcomeTitle = this.i18nService.getText(
        'first_experience_dialogs_Data1',
        [TranslationModeEnum.INSERT_VALUE, user.profile?.name]
      );
    });
  }

  ngAfterViewInit(): void {
    this.getInitialDialog();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.getInitialDialog();
  }

  close(type: FirstExperienceCloseEnum): void {
    if (type === FirstExperienceCloseEnum.NEXT) {
      this.changeTemplate = true;
      this.getInitialDialog();
    } else {
      this.dialog.close(type);
      this.removeAllStepsClasses();
      // const buttonNew3D = document.querySelector(
      //   '.button.new3d'
      // ) as HTMLElement;
      // const buttonGallery = document.querySelector(
      //   '.button.gallery'
      // ) as HTMLElement;
      // const buttonProcess = document.querySelector(
      //   '.button.processing'
      // ) as HTMLElement;
      // const buttonProfessional = document.querySelector(
      //   '.button.professional'
      // ) as HTMLElement;
      // this.renderer.removeClass(buttonNew3D, 'fe-mov-opt');
      // this.renderer.removeClass(buttonGallery, 'fe-mov-opt');
      // this.renderer.removeClass(buttonProcess, 'fe-mov-opt');
      // this.renderer.removeClass(buttonProfessional, 'fe-mov-opt');
    }
  }

  nextStep() {
    if (this.currentStep < FirstExperienceHomeStepsEnum.LAST) {
      this.currentStep++;
      this.getInitialDialog();
    } else {
      this.dialog.close(FirstExperienceCloseEnum.LAST);
    }
  }

  goToStep(stepKey: FirstExperienceHomeStepsEnum) {
    if (stepKey < this.currentStep) {
      this.currentStep = stepKey;
      this.getInitialDialog();
    }
  }

  getStepDescription(step: FirstExperienceHomeStepsEnum): string {
    const stepObject = this.stepDescriptions.find((obj) => obj.key === step);
    return stepObject ? stepObject.description : '';
  }

  getInitialDialog() {
    const initialDialog = document.querySelector(
      '.FirstExperienceHomeDialog'
    ) as HTMLElement;
    if (initialDialog) {
      setTimeout(() => {
        this.initialDialogWidth = initialDialog.offsetWidth;
        this.initialDialogHeight = initialDialog.offsetHeight;
        this.moveDialog();
      }, 1);
    }
  }

  moveDialog(): void {
    this.removeAllStepsClasses();
    if (this.currentStep === FirstExperienceHomeStepsEnum.SECOND) {
      this.moveToNew3D();
    } else if (this.currentStep === FirstExperienceHomeStepsEnum.THIRD) {
      this.moveToGallery();
    } else if (this.currentStep === FirstExperienceHomeStepsEnum.FOURTH) {
      this.moveToProcess();
    } else if (this.currentStep === FirstExperienceHomeStepsEnum.FIFTH) {
      this.moveToRates();
    } else if (this.currentStep === FirstExperienceHomeStepsEnum.LAST) {
      this.moveToNew3D();
    } else {
      this.moveInitial();
    }
  }

  private removeAllStepsClasses() {
    const buttonProfessional = document.querySelector(
      '.button.professional'
    ) as HTMLElement;
    if (buttonProfessional) {
      this.renderer.removeClass(buttonProfessional, 'fe-mov-opt');
    }

    const buttonNew3D = document.querySelector('.button.new3d') as HTMLElement;
    if (buttonNew3D) {
      this.renderer.removeClass(buttonNew3D, 'fe-mov-opt');
    }

    const buttonGallery = document.querySelector(
      '.button.gallery'
    ) as HTMLElement;
    if (buttonGallery) {
      this.renderer.removeClass(buttonGallery, 'fe-mov-opt');
    }

    const buttonProcess = document.querySelector(
      '.button.processing'
    ) as HTMLElement;
    if (buttonProcess) {
      this.renderer.removeClass(buttonProcess, 'fe-mov-opt');
    }
  }

  moveInitial() {
    this.leftPosition = (window.innerWidth - this.initialDialogWidth) / 2;
    this.topPosition = (window.innerHeight - this.initialDialogHeight) / 2;
    this.dialog.updatePosition({
      left: `${this.leftPosition}px`,
      top: `${this.topPosition}px`,
    });
  }

  moveToNew3D(): void {
    const buttonElement = document.querySelector(
      '.button.new3d'
    ) as HTMLElement;
    if (buttonElement) {
      this.renderer.addClass(buttonElement, 'fe-mov-opt');
      const initialX = buttonElement.getBoundingClientRect().left;
      const initialY = buttonElement.getBoundingClientRect().top;
      const finalX = initialX + buttonElement.offsetWidth;
      const finalY = initialY + buttonElement.offsetHeight;
      this.dialog.addPanelClass('top-offset');

      if (this.responsiveService.firstExperience1024()) {
        this.outline = 8;
        this.leftPosition =
          finalX - (finalX - initialX) / 2 - this.initialDialogWidth / 2;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience834()) {
        this.outline = 8;
        this.leftPosition = initialX - this.outline;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience830()) {
        this.outline = 8;
        this.offSet = 20;
        this.leftPosition = finalX + this.offSet;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience414()) {
        this.outline = 8;
        this.leftPosition = initialX - this.outline;
        this.topPosition = finalY;
      } else {
        this.outline = 4;
        this.leftPosition = initialX - this.outline;
        this.topPosition = finalY;
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    } else {
    }
  }

  moveToGallery(): void {
    const buttonElement = document.querySelector(
      '.button.gallery'
    ) as HTMLElement;
    if (buttonElement) {
      this.renderer.addClass(buttonElement, 'fe-mov-opt');
      const initialX = buttonElement.getBoundingClientRect().left;
      const initialY = buttonElement.getBoundingClientRect().top;
      const finalX = initialX + buttonElement.offsetWidth;
      const finalY = initialY + buttonElement.offsetHeight;

      if (this.responsiveService.firstExperience834()) {
        this.outline = 8;
        this.offSet = 30;
        this.leftPosition =
          initialX + (finalX - initialX) / 2 - this.initialDialogWidth / 2;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience830()) {
        this.offSet = 20;
        this.outline = 8;
        this.leftPosition = initialX - this.offSet - this.initialDialogWidth;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience768()) {
        this.outline = 8;
        this.rightPosition = window.innerWidth - finalX - this.outline;
        this.leftPosition =
          initialX - (finalX - initialX) / 2 + this.outline * 2;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience414()) {
        this.outline = 8;
        this.rightPosition = window.innerWidth - finalX - this.outline;
        this.leftPosition =
          window.innerWidth - this.rightPosition - this.initialDialogWidth;
        this.topPosition = finalY;
      } else {
        this.outline = 4;
        this.rightPosition = window.innerWidth - finalX - this.outline;
        this.leftPosition =
          window.innerWidth - this.rightPosition - this.initialDialogWidth;
        this.topPosition = finalY;
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    } else {
    }
  }

  moveToProcess(): void {
    const buttonElement = document.querySelector(
      '.button.processing'
    ) as HTMLElement;
    if (buttonElement) {
      this.renderer.addClass(buttonElement, 'fe-mov-opt');
      const initialX = buttonElement.getBoundingClientRect().left;
      const initialY = buttonElement.getBoundingClientRect().top;
      const finalX = initialX + buttonElement.offsetWidth;
      const finalY = initialY + buttonElement.offsetHeight;

      if (this.responsiveService.firstExperience1920()) {
        this.offSet = 30;
        this.leftPosition = initialX - this.offSet - this.initialDialogWidth;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience1280()) {
        this.leftPosition =
          finalX - (finalX - initialX) / 2 - this.initialDialogWidth / 2;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience1024()) {
        if (this.responsiveService.firstExperience1194()) {
          this.offSet = 30;
        } else {
          this.offSet = 20;
        }
        this.leftPosition = initialX - this.offSet - this.initialDialogWidth;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience834()) {
        this.outline = 8;
        this.leftPosition = finalX + this.outline - this.initialDialogWidth;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience830()) {
        this.outline = 8;
        this.offSet = 20;
        this.leftPosition = initialX - this.offSet - this.initialDialogWidth;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience600()) {
        if (this.responsiveService.firstExperience768()) {
          this.offSet = 25;
        } else {
          this.offSet = 20;
        }
        this.outline = 8;
        this.rightPosition =
          window.innerWidth - initialX + this.outline + this.offSet;
        this.leftPosition =
          window.innerWidth - this.rightPosition - this.initialDialogWidth;
        this.topPosition =
          (finalY - initialY) / 2 + this.outline - this.offSet / 2;
      } else if (this.responsiveService.firstExperience414()) {
        this.outline = 8;
        this.rightPosition = window.innerWidth - finalX - this.outline;
        this.leftPosition =
          window.innerWidth - this.rightPosition - this.initialDialogWidth;
        this.topPosition = finalY;
      } else {
        this.outline = 4;
        this.rightPosition = window.innerWidth - finalX - this.outline;
        this.topPosition = finalY;
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    } else {
    }
  }

  moveToRates(): void {
    const buttonElement = document.querySelector(
      '.button.professional'
    ) as HTMLElement;
    if (buttonElement) {
      this.renderer.addClass(buttonElement, 'fe-mov-opt');
      const initialX = buttonElement.getBoundingClientRect().left;
      const initialY = buttonElement.getBoundingClientRect().top;
      const finalX = initialX + buttonElement.offsetWidth;
      const finalY = initialY + buttonElement.offsetHeight;

      if (this.responsiveService.firstExperience1920()) {
        this.offSet = 30;
        this.leftPosition = initialX - this.offSet - this.initialDialogWidth;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience1280()) {
        this.leftPosition =
          finalX - (finalX - initialX) / 2 - this.initialDialogWidth / 2;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience1024()) {
        if (this.responsiveService.firstExperience1194()) {
          this.offSet = 30;
        } else {
          this.offSet = 20;
        }
        this.outline = 8;
        this.leftPosition = initialX - this.offSet - this.initialDialogWidth;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience834()) {
        this.outline = 8;
        this.leftPosition = finalX + this.outline - this.initialDialogWidth;
        this.topPosition = finalY;
      } else if (this.responsiveService.firstExperience830()) {
        this.outline = 8;
        this.offSet = 20;
        this.leftPosition = initialX - this.offSet - this.initialDialogWidth;
        this.topPosition =
          finalY -
          (finalY - initialY) / 2 -
          this.offSet -
          this.initialDialogHeight / 2;
      } else if (this.responsiveService.firstExperience600()) {
        if (this.responsiveService.firstExperience768()) {
          this.offSet = 25;
        } else {
          this.offSet = 20;
        }
        this.outline = 8;
        this.rightPosition =
          window.innerWidth - initialX + this.outline + this.offSet;
        this.leftPosition =
          window.innerWidth - this.rightPosition - this.initialDialogWidth;
        this.topPosition = initialY - this.outline - this.offSet;
      } else if (this.responsiveService.firstExperience414()) {
        this.outline = 8;
        this.rightPosition = window.innerWidth / 2 - 138;
        this.leftPosition =
          window.innerWidth - this.rightPosition - this.initialDialogWidth;
        this.topPosition = finalY;
      } else {
        this.outline = 4;
        this.rightPosition = window.innerWidth / 2 - 138;
        this.leftPosition =
          window.innerWidth - this.rightPosition - this.initialDialogWidth;
        this.topPosition = finalY;
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    } else {
    }
  }
}
