export enum FirstExperienceCloseEnum {
  CLOSE,
  NEXT,
  SKIP,
  LAST
}

export enum FirstExperienceHomeStepsEnum {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  LAST
}

export enum FirstExperienceLoadStepsEnum {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  LAST
}

export enum FirstExperienceNewModelStepsEnum {
  FIRST = 1,
  SECOND,
  THIRD,
  LAST
}

export enum FirstExperienceGalleryStepsEnum {
  FIRST = 1,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  LAST
}

export enum FirstExperienceViewprojectStepsEnum {
  FIRST = 1,
  SECOND,
  THIRD,
  LAST
}
