export const MetaConfig = {
  Home: {
    title: 'meta_config_Data5',
    description: '',
    robots: '',
  },
  Create: {
    title: 'meta_config_Data6',
    description: '',
    robots: '',
  },
  Load: {
    title: 'meta_config_Data7',
    description: '',
    robots: '',
  },
  Import: {
    title: 'meta_config_Data8',
    description: '',
    robots: '',
  },
  Gallery: {
    title: 'meta_config_Data9',
    description: '',
    robots: '',
  },
  Process: {
    title: 'meta_config_Data10',
    description: '',
    robots: '',
  },
  Rates: {
    title: 'meta_config_Data11',
    description: '',
    robots: '',
  },
  Promotion: {
    title: 'meta_config_Data12',
    description: '',
    robots: '',
  },
  PersonalRates: {
    title: 'meta_config_Data13',
    description: '',
    robots: '',
  },
  Account: {
    title: 'meta_config_Data14',
    description: '',
    robots: '',
  },
  Model: {
    title: 'meta_config_Data15',
    description: '',
    robots: '',
  },
  Viewer: {
    title: 'meta_config_Data16',
    description: '',
    robots: '',
  },
};
