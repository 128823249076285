import { Injectable } from '@angular/core';
import { ScreenDistribution } from 'src/app/viewer-new/models/common/screen-distribution';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  constructor() {
    window.onresize = this.callSubscribers;
  }

  private callbacks = [];

  // taken from bootstrap's grid system
  private breakpoints = {
    // gallery-home grids
    landscape: '(orientation: landscape)',
    mobileG: '(max-width: 599px)',
    mobileGHorizontal: '(max-width: 1023px)',
    tabletG: '(min-width: 600px) and (max-width: 1279px)',
    tabletGHorizontal: '(min-width: 1024px) and (max-width: 1279px)',

    newMobile: '(max-width: 1023px)',
    newTablet:
      '(min-width: 830px) and (min-height: 436px) and (orientation: landscape) and (max-width: 833px)',

    mobile: '(max-width: 414px)',
    tablet: '(min-width:415px) and (max-width:1279px)',
    pc: '(min-width: 1280px)',
    tips: '(max-width: 760px)',

    mobile414: '(min-width:413px) and (max-width:599px)',
    lessThen414: '(max-width:413px)',
    tablet836: '(min-width:834px) and (max-width:1023px)',
    tablet768: '(min-width: 768px)',
    tablet834: '(min-width: 834px)',
    s: '(max-width:627px)',
    m: '(min-width:600px) and (max-width:991px)',
    l: '(min-width:992px) and (max-width:1199px)',
    smr: '(min-width:830px) and (orientation: landscape)   and (max-width: 1023px)',
    lmr: '(min-width:1024px) and (orientation: landscape) and (max-height: 672px)',
    xlmr: '(min-width:1194px) and (orientation: landscape) and (max-height: 766px)',
    more1024: '(min-width:1024px)',
    lessThen834: '(max-width:833px)',
    lessThen1024: '(max-width:1023px)',
    moreThan1194: '(min-width:1194px)',
    moreThan1536: '(min-width:1536px)',
    xl: '(min-width:1200px)',
    xxll: '(min-width: 1280px)',
    xll: '(min-width:1250px)',
    xmll: '(min-width:1600px)',
    xxl: '(min-width:1900px)',
    xxxll: '(min-width: 1280px) and (max-height:700px)',

    firstExperience414: '(min-width:414px)',
    firstExperience600: '(min-width:600px)',
    firstExperience768: '(min-width:768px)',
    firstExperience830: '(min-width:830px)',
    firstExperience834: '(min-width:834px)',
    firstExperience1024: '(min-width:1024px)',
    firstExperience1194: '(min-width:1194px)',
    firstExperience1280: '(min-width:1280px)',
    firstExperience1536: '(min-width:1536px)',
    firstExperience1920: '(min-width:1900px)',
  };

  landscape = () => this.ruleIsMet(this.breakpoints.landscape);
  standardpc = () => this.ruleIsMet(this.breakpoints.xxxll);
  mobileG = () => this.ruleIsMet(this.breakpoints.mobileG);
  mobileGHorizontal = () => this.ruleIsMet(this.breakpoints.mobileGHorizontal);
  tabletG = () => this.ruleIsMet(this.breakpoints.tabletG);
  tabletGHorizontal = () => this.ruleIsMet(this.breakpoints.tabletGHorizontal);
  lessThan414 = () => this.ruleIsMet(this.breakpoints.lessThen414);
  isMobile = () => this.ruleIsMet(this.breakpoints.mobile);
  isMobile414 = () => this.ruleIsMet(this.breakpoints.mobile414);
  isTablet = () => this.ruleIsMet(this.breakpoints.tablet);
  isPC = () => this.ruleIsMet(this.breakpoints.pc);
  specialMovilResolution = () => this.ruleIsMet(this.breakpoints.smr);
  specialTabletResolution = () => this.ruleIsMet(this.breakpoints.lmr);
  specialTabletResolution2 = () => this.ruleIsMet(this.breakpoints.xlmr);
  lessThan1024 = () => this.ruleIsMet(this.breakpoints.lessThen1024);
  lessThan834 = () => this.ruleIsMet(this.breakpoints.lessThen834);
  moreThan1536 = () => this.ruleIsMet(this.breakpoints.moreThan1536);

  public newMobile = () => this.ruleIsMet(this.breakpoints.newMobile);
  public newTablet = () => this.ruleIsMet(this.breakpoints.newTablet);

  public isTips = () => this.ruleIsMet(this.breakpoints.tips);

  public is836 = () => this.ruleIsMet(this.breakpoints.tablet836);
  public is768 = () => this.ruleIsMet(this.breakpoints.tablet768);
  public is834 = () => this.ruleIsMet(this.breakpoints.tablet834);

  public isS = () => this.ruleIsMet(this.breakpoints.s);
  public isM = () => this.ruleIsMet(this.breakpoints.m);
  public isl = () => this.ruleIsMet(this.breakpoints.l);
  public isxl = () => this.ruleIsMet(this.breakpoints.xl);
  public moreThan1024 = () => this.ruleIsMet(this.breakpoints.more1024);
  public moreThan1194 = () => this.ruleIsMet(this.breakpoints.moreThan1194);
  // public isTabletTemp = () => this.ruleIsMet(this.breakpoints.tabletTemp);
  public isxxll = () => this.ruleIsMet(this.breakpoints.xxll);
  public isxll = () => this.ruleIsMet(this.breakpoints.xll);
  public isxmll = () => this.ruleIsMet(this.breakpoints.xmll);
  public isxxl = () => this.ruleIsMet(this.breakpoints.xxl);
  public isxxxll = () => this.ruleIsMet(this.breakpoints.xxxll);

  public firstExperience414 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience414);
  public firstExperience600 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience600);
  public firstExperience768 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience768);
  public firstExperience830 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience830);
  public firstExperience834 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience834);
  public firstExperience1024 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience1024);
  public firstExperience1194 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience1194);
  public firstExperience1280 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience1280);
  public firstExperience1536 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience1536);
  public firstExperience1920 = () =>
    this.ruleIsMet(this.breakpoints.firstExperience1920);

  /*public isSmallScreen =  () => this.ruleIsMet(this.xsOrs);*/

  public registerChangeCallback = (f) => {
    this.callbacks.push(f);
  };

  private ruleIsMet = (rule: string) => window.matchMedia(rule).matches;

  private callSubscribers = () => {
    const len = this.callbacks.length;

    if (len === 0) {
      return;
    }

    let i = 0;

    for (; i < len; i++) {
      this.callbacks[i]();
    }
  };

  /**
   * Método que se ejecuta cuando se cambia el tamaño de la pantalla.
   * @param data
   * @param dialogRef
   */
  public screenResize(data, dialogRef) {
    if (this.isMobile()) {
      // 360x600px (max-width: 414px)
      dialogRef.updateSize(data[0], data[1]);
    }

    if (this.isMobile414()) {
      // 414x732px (min-width:413px) and (max-width:600px)
      dialogRef.updateSize(data[2], data[3]);
    }

    if (this.isM()) {
      // 600x854 (min-width:600px) and (max-width:991px)
      dialogRef.updateSize(data[4], data[5]);
    }

    if (this.is768()) {
      // 768px (min-width:768px)
      dialogRef.updateSize(data[6], data[7]);
    }

    if (this.specialMovilResolution()) {
      // 830x436 (min-width: 830px) and (orientation: landscape) and (max-height: 600px)
      dialogRef.updateSize(data[8], data[9]);
    }

    if (this.is834()) {
      // 834x1126 (min-width: 834px)
      dialogRef.updateSize(data[10], data[11]);
    }

    if (this.moreThan1024()) {
      // 1024x672 (min-width: 1024px)
      dialogRef.updateSize(data[12], data[13]);
    }

    if (this.moreThan1194()) {
      // 1194x766 (min-width: 1194px)
      dialogRef.updateSize(data[14], data[15]);
    }

    if (this.isxxll()) {
      // 1280x700 (min-width: 1280px)
      dialogRef.updateSize(data[16], data[17]);
    }

    if (this.moreThan1536() || this.isxmll()) {
      // 1536x754 (min-width:1536px)
      dialogRef.updateSize(data[18], data[19]);
    }

    if (this.isxxl()) {
      // 1920x969 (min-width:1900px)
      dialogRef.updateSize(data[20], data[21]);
    }
  }

  /**
   * Devuelve el tipo de distribución (MOBILE, TABLET O DESKTOP) actual.
   */
  getCurrentScreenDistribution(): ScreenDistribution {
    if (this.isMobile414()) {
      return ScreenDistribution.MOBILE;
    } else if (this.isTablet()) {
      return ScreenDistribution.TABLET;
    } else {
      return ScreenDistribution.DESKTOP;
    }
  }
}
