import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from '../../api-access/rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class ModelInfoIOTAService {

  private URL = '/iota';

  constructor(private restClientService: RestClientService) { 

  }

  getIOTAInfoImages(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/images`);
  }

  getIOTAInfoprocesses(id: number): Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/processes`);
  }

  getIOTAInfoDownload(id: number) : Observable<any> {
    return this.restClientService.get(`${this.URL}/${id}/downloads`);
  }

}
