export const GALLERY_DIALOGS_I18N_ENTRIES = {
  gallery_dialogs_data1: {
    es: '<p class="new-bold">¿Estás seguro de que quieres descargar los</p><p class="new-bold">modelos 3D seleccionados?</p>',
    en: '<p class="new-bold">Are you sure you want to download</p><p class="new-bold">the selected 3D models?</p>',
    pt: '<p class="new-bold">Tem a certeza de que pretende transferir</p><p class="new-bold">os modelos 3D selecionados?</p>',
    fr: '<p class="new-bold">Êtes-vous sûr(e) de vouloir télécharger les</p><p class="new-bold">modèles 3D sélectionnés ?</p>',
    eus: '<p class="new-bold">Ziur zaude aukeratutako 3D ereduak</p><p class="new-bold">deskargatu nahi dituzula?</p>',
    cat: '<p class="new-bold">Segur que vols baixar</p><p class="new-bold">els models 3D seleccionats?</p>',
  },

  gallery_dialogs_data2: {
    es: '<p>SÍ, DESCARGAR</p>',
    en: '<p>YES, DOWNLOAD</p>',
    pt: '<p>SIM, TRANSFERIR</p>',
    fr: '<p>OUI, TÉLÉCHARGER</p>',
    eus: '<p>BAI, DESKARGATU</p>',
    cat: '<p>SÍ, BAIXA’LS</p>',
  },

  gallery_dialogs_data3: {
    es: '<p>Tu suscripción actual incluye la descarga de %X% modelos 3D.</p><p>Para poder continuar, actualiza el número de descargas</p><p>o selecciona tantos modelos como descargas tengas.</p>',
    en: '<p>Your current subscription includes the download of %X% 3D models.</p><p>To continue, update your number of downloads</p><p>or select only the number of models you are able to download.</p>',
    pt: '<p>A sua subscrição atual inclui a transferência de %X% modelos 3D.</p><p>Para poder continuar, atualize o número de transferências</p><p>ou selecione tantos modelos quantos as transferências que pode efetuar.</p>',
    fr: '<p>Votre abonnement actuel comprend le téléchargement de %X% modèles 3D.</p><p>Pour continuer, actualisez le nombre de téléchargements</p><p>ou sélectionnez autant de modèles que vous avez de téléchargements.</p>',
    eus: '<p>Zure harpidetzarekin %X% 3D eredu deskargatu ditzakezu.</p><p>Aurrera egin ahal izateko, hobetu zure deskarga-muga</p><p>edo aukeratu harpidetzak onartzen dituen deskarga adina eredu.</p>',
    cat: '<p>La teva subscripció actual inclou la baixada de %X% models 3D.</p><p>Per poder continuar, actualitza la quantitat de baixades</p><p>o tria tants models com baixades tinguis.</p>',
  },

  gallery_dialogs_data4: {
    es: '<p>SELECCIONAR MODELOS</p>',
    en: '<p>SELECT MODELS</p>',
    pt: '<p>SELECIONAR MODELOS</p>',
    fr: '<p>SÉLECTIONNER MODÈLES</p>',
    eus: '<p>AUKERATU EREDUAK</p>',
    cat: '<p>SELECCIONA MODELS</p>',
  },

  gallery_dialogs_data5: {
    es: '<p>ACTUALIZAR</p>',
    en: '<p>UPDATE</p>',
    pt: '<p>ATUALIZAR</p>',
    fr: '<p>ACTUALISER</p>',
    eus: '<p>HOBETU</p>',
    cat: '<p>ACTUALITZA</p>',
  },

  gallery_dialogs_data6: {
    es: '<p class="new-bold">¿Estás seguro de que quieres eliminar de tu galería los</p><p class="new-bold">%X%</p><p class="new-bold">modelos 3D seleccionados?</p>',
    en: '<p class="new-bold">Are you sure you want to delete the</p><p class="new-bold">%X%</p><p class="new-bold">selected 3D models from your gallery?</p>',
    pt: '<p class="new-bold">Tem a certeza de que pretende eliminar da sua galeria os</p><p class="new-bold">%X%</p><p class="new-bold">modelos 3D selecionados?</p>',
    fr: '<p class="new-bold">Êtes-vous sûr(e) de vouloir supprimer les</p><p class="new-bold">%X%</p><p class="new-bold">modèles 3D sélectionnés</p><p class="new-bold">de votre galerie ?</p>',
    eus: '<p class="new-bold">Ziur zaude aukeratutako</p><p class="new-bold">%X%</p><p class="new-bold">3D ereduak zure galeriatik ezabatu nahi dituzula?</p>',
    cat: '<p class="new-bold">Segur que vols eliminar de la galeria els</p><p class="new-bold">%X%</p><p class="new-bold">models 3D seleccionats?</p>',
  },

  gallery_dialogs_data7: {
    es: '<p>Esta acción no se puede deshacer, una vez elimines los modelos 3D</p><p>no podrás recuperarlos.</p>',
    en: '<p>This action cannot be undone, once you delete the 3D models</p><p>you will not be able to recover them.</p>',
    pt: '<p>Esta ação não é reversível. Depois de eliminar os modelos 3D</p><p>não poderá recuperá-los.</p>',
    fr: '<p>Cette action ne peut pas être annulée. Une fois les modèles 3D supprimés,</p><p>vous ne pouvez plus les récupérer.</p>',
    eus: '<p>Ekintza hau ezin da desegin.</p><p>Behin 3D ereduak ezabatuta, ezin izango dituzu berreskuratu.</p>',
    cat: '<p>Aquesta acció no es pot desfer. Un cop eliminis els models 3D</p><p>no els podràs recuperar.</p>',
  },

  gallery_dialogs_data8: {
    es: '<p>SÍ, ELIMINAR</p>',
    en: '<p>YES, DELETE</p>',
    pt: '<p>SIM, ELIMINAR</p>',
    fr: '<p>OUI, SUPPRIMER</p>',
    eus: '<p>BAI, EZABATU</p>',
    cat: '<p>SÍ, ELIMINA</p>',
  },

  gallery_dialogs_data9: {
    es: '<p class="new-bold">Selecciona “Aprobar” o “Denegar” para</p><p class="new-bold">gestionar tus solicitudes de uso pendientes.</p>',
    en: '<p class="new-bold">Select “Approve” or “Deny” to</p><p class="new-bold">manage your pending use requests.</p>',
    pt: '<p class="new-bold">Selecione "Aprovar" ou “Recusar” para</p><p class="new-bold">gerir os seus pedidos de utilização pendentes.</p>',
    fr: '<p class="new-bold">Sélectionnez « Approuver » ou « Refuser » pour</p><p class="new-bold">gérer vos demandes d’utilisation en attente.</p>',
    eus: '<p class="new-bold">Aukeratu “Onartu” edo “Ukatu”,</p><p class="new-bold">zure erabilera-eskaerak kudeatzeko.</p>',
    cat: '<p class="new-bold">Selecciona “Aprova” o “Denega” per</p><p class="new-bold">gestionar les sol·licituds d’ús pendents.</p>',
  },

  gallery_dialogs_data10: {
    es: '<span>¿Qué implica esto?</span>',
    en: '<span>What does this mean?</span>',
    pt: '<span>O que é que isto implica?</span>',
    fr: '<span>Qu’est-ce que cela implique ?</span>',
    eus: '<span>Zer esan nahi du honek?</span>',
    cat: '<span>Què implica això?</span>',
  },

  gallery_dialogs_data11: {
    es: '<p>DENEGAR</p>',
    en: '<p>DENY</p>',
    pt: '<p>RECUSAR</p>',
    fr: '<p>REFUSER</p>',
    eus: '<p>UKATU</p>',
    cat: '<p>DENEGA</p>',
  },

  gallery_dialogs_data12: {
    es: '<p>APROBAR</p>',
    en: '<p>APPROVE</p>',
    pt: '<p>APROVAR</p>',
    fr: '<p>APPROUVER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>APROVA</p>',
  },

  gallery_dialogs_data13: {
    es: '<p class="new-bold">Estás a un paso de eliminar las solicitudes</p><p class="new-bold">pendientes seleccionadas.</p>',
    en: '<p class="new-bold">You are one step away from deleting the selected</p><p class="new-bold">pending requests.</p>',
    pt: '<p class="new-bold">Está a um passo de eliminar os pedidos</p><p class="new-bold">pendentes selecionados.</p>',
    fr: '<p class="new-bold">Vous allez supprimer les demandes</p><p class="new-bold">en attente sélectionnées.</p>',
    eus: '<p class="new-bold">Aukeratu dituzun eskaerak</p><p class="new-bold">ezabatzear zaude.</p>',
    cat: '<p class="new-bold">Ets a un pas d’eliminar les sol·licituds</p><p class="new-bold">pendents seleccionades.</p>',
  },

  gallery_dialogs_data14: {
    es: '<p>Esta acción no se puede deshacer y supone la</p><p>denegación de uso de los modelos seleccionados.</p>',
    en: '<p>This action cannot be undone and will</p><p>reject the requests to use the selected models.</p>',
    pt: '<p>Esta ação não é reversível e implica a</p><p>recusa de utilização dos modelos selecionados.</p>',
    fr: '<p>Cette action ne peut pas être annulée. Cela implique le refus d’utilisation des modèles sélectionnés.</p>',
    eus: '<p>Ekintza hau ezin da desegin eta aukeratutako ereduen</p><p>erabilera ukatzea suposatzen du.</p>',
    cat: '<p>Aquesta acció no es pot desfer i implica</p><p>la denegació d’ús dels models seleccionats.</p>',
  },

  gallery_dialogs_data15: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ADOS NAGO</p>',
    cat: '<p>ACCEPTA</p>',
  },

  gallery_dialogs_data16: {
    es: '<p class="new-bold">El almacenamiento extra de la biblioteca online</p><p class="new-bold">finaliza el próximo %X% de %X% de %X%.</p>',
    en: '<p class="new-bold">The extra storage in the online library</p><p class="new-bold">will expire on %X% %X%, %X%.</p>',
    pt: '<p class="new-bold">O armazenamento extra da biblioteca online</p><p class="new-bold">termina no próximo dia %X% de %X% de %X%.</p>',
    fr: '<p class="new-bold">Le stockage supplémentaire de la bibliothèque en</p><p class="new-bold">ligne prend fin le %X% %X% %X%.</p>',
    eus: '<p class="new-bold">Online liburutegian duzun biltegiratze-edukiera gehigarria</p><p class="new-bold">%X%ko %X%ren %X%(e)an bukatuko da.</p>',
    cat: '<p class="new-bold">L’emmagatzematge extra de la biblioteca en línia</p><p class="new-bold">finalitza el pròxim dia %X% de %X% de %X%.</p>',
  },

  gallery_dialogs_data17: {
    es: '<p class="new-bold">Si quieres resolver esta acción renueva tu suscripción o ajusta tu galería</p><p class="new-bold">online para no perder tus modelos 3D.</p>',
    en: '<p class="new-bold">To avoid losing your 3D models, renew your subscription</p><p class="new-bold">or adjust your online gallery.</p>',
    pt: '<p class="new-bold">Se pretende resolver esta ação, renove a sua subscrição ou ajuste a sua galeria</p><p class="new-bold">online para não perder os seus modelos 3D.</p>',
    fr: '<p class="new-bold">Si vous souhaitez résoudre ce problème, veuillez renouveler votre abonnement</p><p class="new-bold">ou ajuster votre galerie en ligne pour ne pas perdre vos modèles 3D.</p>',
    eus: '<p class="new-bold">Jarraitu nahi baduzu, eguneratu zure harpidetza edo egokitu zure online galeria,</p><p class="new-bold">3D ereduak ez galtzeko.</p>',
    cat: '<p class="new-bold">Si vols resoldre aquesta acció, renova la subscripció o ajusta la galeria</p><p class="new-bold">en línia per no perdre els models 3D.</p>',
  },

  gallery_dialogs_data18: {
    es: '<p>AJUSTAR GALERÍA</p>',
    en: '<p>ADJUST GALLERY</p>',
    pt: '<p>AJUSTAR GALERIA</p>',
    fr: '<p>AJUSTER GALERIE</p>',
    eus: '<p>EGOKITU GALERIA</p>',
    cat: '<p>AJUSTA LA GALERIA</p>',
  },

  gallery_dialogs_data19: {
    es: '<p>RENOVAR SUSCRIPCIÓN</p>',
    en: '<p>RENEW SUBSCRIPTION</p>',
    pt: '<p>RENOVAR SUBSCRIÇÃO</p>',
    fr: '<p>RENOUVELER ABONNEMENT</p>',
    eus: '<p>EGUNERATU HARPIDETZA</p>',
    cat: '<p>RENOVA LA SUBSCRIPCIÓ</p>',
  },

  gallery_dialogs_data20: {
    es: '<p>Para poder recuperar el modelo 3D seleccionado, antes</p><p>debes liberar o ampliar espacio de tu galería.</p>',
    en: '<p>To recover the selected 3D model, you must first</p><p>add or free up space in your gallery.</p>',
    pt: '<p>Para recuperar o modelo 3D selecionado, deve</p><p>primeiro libertar espaço da sua galeria.</p>',
    fr: '<p>Pour récupérer le modèle 3D sélectionné,</p><p>vous devez d’abord libérer</p><p>ou augmenter l’espace de votre galerie.</p>',
    eus: '<p>Aukeratu duzun 3D eredua berreskuratu ahal izateko, lehenik</p><p>espazioa handitu edo libre utzi behar duzu zure galerian.</p>',
    cat: '<p>Per poder recuperar el model 3D seleccionat, abans</p><p>has d\'alliberar o ampliar espai de la galeria.</p>',
  },

  gallery_dialogs_data21: {
    es: '<p>ELIMINAR MODELOS</p>',
    en: '<p>DELETE MODELS</p>',
    pt: '<p>ELIMINAR MODELOS</p>',
    fr: '<p>SUPPRIMER MODÈLES</p>',
    eus: '<p>EZABATU EREDUAK</p>',
    cat: '<p>ELIMINA MODELS</p>',
  },

  gallery_dialogs_data22: {
    es: '<p>AUMENTAR GALERÍA</p>',
    en: '<p>EXPAND GALLERY</p>',
    pt: '<p>AUMENTAR GALERIA</p>',
    fr: '<p>AUGMENTER GALERIE</p>',
    eus: '<p>HANDITU GALERIA</p>',
    cat: '<p>AUGMENTA LA GALERIA</p>',
  },

  gallery_dialogs_data23: {
    es: '<p>Tu suscripción actual incluye el almacenamiento de %X%</p><p>modelos 3D, para poder generar o importar nuevos modelos,</p><p>aumenta tu biblioteca online o elimina modelos 3D de tu galería.</p>',
    en: '<p>Your current subscription includes the storage of %X%</p><p>3D models. To generate or import new models,</p><p>expand your online library or delete 3D models from your gallery.</p>',
    pt: '<p>A sua subscrição atual inclui o armazenamento de %X%</p><p>modelos 3D, para poder gerar ou importar novos modelos,</p><p>aumente a sua biblioteca online ou elimine modelos 3D da sua galeria.</p>',
    fr: '<p>Votre abonnement actuel comprend le stockage de %X%</p><p>modèles 3D. Pour générer ou importer de nouveaux modèles,</p><p>augmentez l’espace de votre bibliothèque en ligne ou supprimez des modèles 3D de votre galerie.</p>',
    eus: '<p>Zure harpidetzarekin %X% eredu biltegiratu ditzakezu,</p><p>3D eredu berriak sortu edo inportatu ahal izateko, handitu zure</p><p>online liburutegia edo ezabatu 3D ereduak zure galeriatik.</p>',
    cat: '<p>La subscripció actual inclou l’emmagatzematge de %X%</p><p>models 3D. Per poder generar-ne o importar-ne de nous,</p><p>augmenta la biblioteca en línia o elimina models 3D de la galeria.</p>',
  },

  gallery_dialogs_data24: {
    es: '<p>Se ha alcanzado el límite de modelos 3D incluidos en la biblioteca</p>',
    en: '<p>You have reached the maximum number of 3D models included in the library</p>',
    pt: '<p>Atingiu o limite de modelos 3D incluídos na biblioteca</p>',
    fr: '<p>Vous avez atteint la limite maximum de modèles 3D inclus dans la bibliothèque.</p>',
    eus: '<p>Liburutegian biltegiratu ditzakezun 3D eredu kopuruaren mugara iritsi zara.</p>',
    cat: '<p>S’ha assolit el límit de models 3D inclosos en la biblioteca</p>',
  },

  gallery_dialogs_data25: {
    es: '<p>Estás a un paso de solicitar que se aumente el espacio</p><p>de la galería ¿Deseas continuar?</p>',
    en: '<p>You are one step away from requesting to add space</p><p>to the gallery. Do you wish to continue?</p>',
    pt: '<p>Está a um passo de solicitar o aumento do espaço</p><p>da galeria. Pretende continuar?</p>',
    fr: '<p>Vous allez demander une augmentation</p><p>de l’espace</p><p>de la galerie. Souhaitez-vous continuer ?</p>',
    eus: '<p>Galeriaren edukiera handitzeko eskaera egitear zaude.</p><p>Jarraitu nahi duzu?</p>',
    cat: '<p>Ets a un pas de sol·licitar un augment d’espai</p><p>de la galeria. Vols continuar?</p>',
  },

  gallery_dialogs_data26: {
    es: '<p>Escribe brevemente el motivo por el que deseas que se aumente el tamaño de la biblioteca…</p>',
    en: '<p>Briefly explain why you want to increase the size of the library…</p>',
    pt: '<p>Escreva resumidamente o motivo pela qual pretende que o tamanho da biblioteca seja aumentado…</p>',
    fr: '<p>Indiquez brièvement la raison pour laquelle vous souhaitez augmenter la taille de la bibliothèque...</p>',
    eus: '<p>Idatzi labur zergatik nahi duzun liburutegiaren tamaina handitu...</p>',
    cat: '<p>Indica breument el motiu pel qual vols augmentar l’espai de la biblioteca…</p>',
  },

  gallery_dialogs_data27: {
    es: '<p>SI, SOLICITAR</p>',
    en: '<p>YES, REQUEST</p>',
    pt: '<p>SIM, SOLICITAR</p>',
    fr: '<p>OUI, DEMANDER</p>',
    eus: '<p>BAI, EGIN ESKAERA</p>',
    cat: '<p>SÍ, SOL·LICITA</p>',
  },

  gallery_dialogs_data28: {
    es: '<p>Estás a un paso de solicitar que se aumente</p><p>el espacio de la galería ¿Deseas continuar?</p>',
    en: '<p>You are one step away from requesting to add</p><p>space to the gallery. Do you wish to continue?</p>',
    pt: '<p>Está a um passo de solicitar o aumento do espaço</p><p>da galeria. Pretende continuar?</p>',
    fr: '<p>Vous allez demander une augmentation</p><p>de l’espace de la galerie. Souhaitez-vous continuer ?</p>',
    eus: '<p>Galeriaren edukiera handitzeko eskaera</p><p>egitear zaude. Jarraitu nahi duzu?</p>',
    cat: '<p>Ets a un pas de sol·licitar un augment</p><p>d’espai de la galeria. Vols continuar?</p>',
  },

  gallery_dialogs_data29: {
    es: '<p>Estás a un paso de solicitar que se eliminen de la galería los</p><p>%X% modelos 3D seleccionados,</p><p>¿Deseas continuar?</p>',
    en: '<p>You are one step away from requesting to delete the</p><p>%X% 3D models selected from the gallery.</p><p>Do you wish to continue?</p>',
    pt: '<p>Está a um passo de solicitar a eliminação da galeria dos</p><p>%X% modelos 3D selecionados.</p><p>Pretende continuar?</p>',
    fr: '<p>Vous allez demander la suppression</p><p>des %X% modèles 3D</p><p>sélectionnés de votre galerie.</p><p>Souhaitez-vous continuer ?</p>',
    eus: '<p>Aukeratu dituzun %X% 3D ereduak zure galeriatik ezabatzeko eskaera egitear zaude.</p><p>Jarraitu nahi duzu?</p>',
    cat: '<p>Ets a un pas de sol·licitar que s’eliminin de la galeria</p><p>els %X% models 3D seleccionats.</p><p>Vols continuar?</p>',
  },

  gallery_dialogs_data30: {
    es: '<p>Estás a un paso de solicitar que se eliminen</p><p>de la galería los %X% modelos</p><p>3D seleccionados,</p><p>¿Deseas continuar?</p>',
    en: '<p>You are one step away from requesting to delete</p><p>the %X% 3D models selected</p><p>from the gallery.</p><p>Do you wish to continue?</p>',
    pt: '<p>Está a um passo de solicitar a eliminação da galeria dos</p><p>%X% modelos 3D selecionados.</p><p>Pretende continuar?</p>',
    fr: '<p>Vous allez demander la suppression</p><p>des %X% modèles 3D</p><p>sélectionnés de votre galerie.</p><p>Souhaitez-vous continuer ?</p>',
    eus: '<p>Aukeratu dituzun %X% 3D ereduak zure</p><p>galeriatik ezabatzeko eskaera</p><p>egitear zaude.</p><p>Jarraitu nahi duzu?</p>',
    cat: '<p>Ets a un pas de sol·licitar que s’eliminin</p><p>de la galeria els %X% models</p><p>3D seleccionats.</p><p>Vols continuar?</p>',
  },

  gallery_dialogs_data31: {
    es: '<p>Escribe brevemente el motivo por el que deseas eliminar este modelo…</p>',
    en: '<p>Briefly explain why you want to delete this model…</p>',
    pt: '<p>Escreva resumidamente o motivo pelo qual pretende eliminar este modelo…</p>',
    fr: '<p>Indiquez brièvement la raison pour laquelle vous souhaitez supprimer ce modèle...</p>',
    eus: '<p>Idatzi labur zergatik nahi duzun eredu hori ezabatu...</p>',
    cat: '<p>Indica breument el motiu pel qual vols eliminar aquest model…</p>',
  },

  gallery_dialogs_data32: {
    es: '<p>Estás a un paso de solicitar que se actualice el</p><p>número de descargas ¿Deseas continuar?</p>',
    en: '<p>You are one step away from requesting to update the</p><p>number of downloads. Do you wish to continue?</p>',
    pt: '<p>Está a um passo de solicitar a atualização do</p><p>número de transferências. Pretende continuar?</p>',
    fr: '<p>Vous allez demander une actualisation du</p><p>nombre de téléchargements. Souhaitez-vous continuer ?</p>',
    eus: '<p>Deskarga-muga handitzeko eskaera egitear zaude.</p><p>Jarraitu nahi duzu?</p>',
    cat: '<p>Ets a un pas de sol·licitar que s’actualitzi</p><p>la quantitat de baixades. Vols continuar?</p>',
  },

  gallery_dialogs_data33: {
    es: '<p>Estás a un paso de solicitar que se</p><p>actualice el número de descargas ¿Deseas</p><p>continuar?</p>',
    en: '<p>You are one step away from requesting to</p><p>update the number of downloads. Do you wish</p><p>to continue?</p>',
    pt: '<p>Está a um passo de solicitar a atualização do</p><p>número de transferências. Pretende</p><p>continuar?</p>',
    fr: '<p>Vous allez demander une actualisation du</p><p>nombre de téléchargements. Souhaitez-vous</p><p>continuer ?</p>',
    eus: '<p>Deskarga-muga handitzeko</p><p>eskaera egitear zaude.</p><p>Jarraitu nahi duzu?</p>',
    cat: '<p>Ets a un pas de sol·licitar que</p><p>s’actualitzi la quantitat de baixades.</p><p>Vols continuar?</p>',
  },

  gallery_dialogs_data34: {
    es: '<p>Escribe brevemente el motivo por el que deseas que se amplíe el número de descargas…</p>',
    en: '<p>Briefly explain why you wish to increase the number of downloads…</p>',
    pt: '<p>Escreva resumidamente o motivo pela qual pretende que o número de transferências seja aumentado…</p>',
    fr: '<p>Indiquez brièvement la raison pour laquelle vous souhaitez augmenter le nombre de téléchargements...</p>',
    eus: '<p>Idatzi labur zergatik nahi duzun deskarga-muga handitu...</p>',
    cat: '<p>Indica breument el motiu pel qual vols ampliar la quantitat de baixades…</p>',
  },

  gallery_dialogs_data35: {
    es: '<p>Otros Usuarios de eyesCloud3D pueden solicitar el uso de tus modelos 3D para la realización de trabajos en la plataforma. La aprobación de esta solicitud no tendrá ningún impacto en la propiedad ni integridad de tu modelo original, simplemente le permitirá guardar los ajustes realizados en sus trabajos. Si continuas teniendo preguntas acerca de este proceso no dudes en ponerte en contacto con nosotros en <span class="i18n-bold i18n-color-FF000000">customercare@eyescloud3d.com</span></p>',
    en: '<p>Other eyesCloud3D Users can request to use your 3D models to carry out work on the platform. Approving this request will have no impact on the ownership or integrity of your original model, it will simply allow the users to save the adjustments made in their work. If you have any further questions about this process, please contact us at</p><p><span class="i18n-bold i18n-color-FF000000">customercare@eyescloud3d.com</span></p>',
    pt: '<p>Outros Utilizadores de eyesCloud3D podem pedir para usar os seus modelos 3D para a realização de trabalhos na plataforma. A aprovação deste pedido não terá qualquer impacto na propriedade ou integridade do seu modelo original, simplesmente permitirá guardar os ajustes realizados nos seus trabalhos. Se ainda tiver questões sobre este processo, não hesite em contactar-nos em <span class="i18n-bold i18n-color-FF000000">customercare@eyescloud3d.com</span></p>',
    fr: '<p>D’autres utilisateurs d’eyesCloud3D peuvent demander à utiliser vos modèles 3D pour réaliser des travaux sur la plateforme. L’approbation de cette demande n\'a aucun impact sur la propriété ou l\'intégrité de votre modèle original. Elle permet simplement aux utilisateurs de sauvegarder les ajustements apportés à leurs travaux. Si vous avez des questions concernant ce processus, n\'hésitez pas à nous contacter à l’adresse <span class="i18n-bold i18n-color-FF000000">customercare@eyescloud3d.com</span></p>',
    eus: '<p>eyesCloud3D-ren beste erabiltzaileek baimena eskatu dezakete zure 3D ereduekin plataforman lanean aritzeko. Eskaera hau onartzeak ez du inolako eraginik izango zure jatorrizko modeloaren jabetzan eta osotasunean, haiei beren lanetan egindako aldaketak gordetzeko aukera baino ez die emango. Prozesu honi buruzko galderak badituzu, jarri gurekin harremanetan <span class="i18n-bold i18n-color-FF000000">customercare@eyescloud3d.com</span> helbidean.</p>',
    cat: '<p>Altres usuaris d’eyesCloud3D poden sol·licitar permís per emprar els teus models 3D per fer treballs a la plataforma. L’aprovació d’aquesta sol·licitud no tindrà cap impacte a la propietat ni la integritat del model original, només els permetrà desar els ajustaments que facin als seus treballs. Si tens més preguntes sobre aquest procés, no dubtis a contactar amb nosaltres a <span class="i18n-bold i18n-color-FF000000">customercare@eyescloud3d.com</span></p>',
  },

  gallery_dialogs_data36: {
    es: '<span class="italic i18n-underline">Saber más</span>',
    en: '<span class="italic i18n-underline">Learn more</span>',
    pt: '<span class="italic i18n-underline">Saber mais</span>',
    fr: '<span class="italic i18n-underline">En savoir plus</span>',
    eus: '<span class="italic i18n-underline">Jakin gehiago</span>',
    cat: '<span class="italic i18n-underline">Saber-ne més</span>',
  },

  gallery_dialogs_data37: {
    es: '<span class="i18n-underline">Cerrar</span>',
    en: '<span class="i18n-underline">Close</span>',
    pt: '<span class="i18n-underline">Fechar</span>',
    fr: '<span class="i18n-underline">Fermer</span>',
    eus: '<span class="i18n-underline">Itxi</span>',
    cat: '<span class="i18n-underline">Tanca</span>',
  },

  gallery_dialogs_data38: {
    es: '<p>Tus cambios han sido guardados correctamente.</p>',
    en: '<p>Your changes have been saved correctly.</p>',
    pt: '<p>As suas alterações foram guardadas corretamente.</p>',
    fr: '<p>Vos modifications ont été sauvegardées correctement.</p>',
    eus: '<p>Egin dituzun aldaketak ondo gorde dira.</p>',
    cat: '<p>Els canvis s’han desat correctament.</p>',
  },

  gallery_dialogs_data39: {
    es: '<p class="new-bold">Estás a un paso de eliminar las solicitudes</p><p class="new-bold">pendientes seleccionadas.</p>',
    en: '<p class="new-bold">You are one step away from deleting the selected</p><p class="new-bold">pending requests.</p>',
    pt: '<p class="new-bold">Está a um passo de eliminar os pedidos</p><p class="new-bold">pendentes selecionados.</p>',
    fr: '<p class="new-bold">Vous allez supprimer les demandes</p><p class="new-bold">en attente sélectionnées.</p>',
    eus: '<p class="new-bold">Aukeratu dituzun eskaerak</p><p class="new-bold">ezabatzear zaude.</p>',
    cat: '<p class="new-bold">Ets a un pas d’eliminar les sol·licituds</p><p class="new-bold">pendents seleccionades.</p>',
  },

  gallery_dialogs_data40: {
    es: '<p>Esta acción no se puede deshacer y supone la</p><p>denegación de uso de los modelos seleccionados.</p>',
    en: '<p>This action cannot be undone and will</p><p>reject the requests to use the selected models.</p>',
    pt: '<p>Esta ação não é reversível e implica a</p><p>recusa de utilização dos modelos selecionados.</p>',
    fr: '<p>Cette action ne peut pas être annulée. Cela implique le refus d’utilisation des modèles sélectionnés.</p>',
    eus: '<p>Ekintza hau ezin da desegin eta aukeratutako ereduen</p><p>erabilera ukatzea suposatzen du.</p>',
    cat: '<p>Aquesta acció no es pot desfer i implica</p><p>la denegació d’ús dels models seleccionats.</p>',
  },

  gallery_dialogs_data41: {
    es: '<p class="new-bold">Selecciona “Aprobar” o “Denegar” para</p><p class="new-bold">gestionar tus solicitudes de eliminación.</p>',
    en: '<p class="new-bold">Select “Approve” or “Deny” to</p><p class="new-bold">manage your deletion requests.</p>',
    pt: '<p class="new-bold">Selecione "Aprovar" ou “Recusar” para</p><p class="new-bold">gerir os seus pedidos de eliminação.</p>',
    fr: '<p class="new-bold">Sélectionnez « Approuver » ou « Refuser » pour</p><p class="new-bold">gérer vos demandes de suppression.</p>',
    eus: '<p class="new-bold">Aukeratu “Onartu” edo “Ukatu”,</p><p class="new-bold">zure ezabatze-eskaerak kudeatzeko.</p>',
    cat: '<p class="new-bold">Selecciona “Aprova” o “Denega” per</p><p class="new-bold">gestionar les sol·licituds d’eliminació.</p>',
  },
};
