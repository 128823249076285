export const STANDALONE_CLIENT_I18N_ENTRIES = {

  standalone_total_ERTZAINTZA_Data1: {
    es: '<p>Recreación de escenarios en 3D</p>',
    en: '<p>3D crime scene recreation</p>',
    pt: '<p>Recriação de cenas em 3D</p>',
    fr: '<p>Reconstitution de scénarios en 3D</p>',
    eus: '<p>Gertalekuen berregitea 3Dn</p>',
    cat: '<p>Recreació d’escenaris en 3D</p>',
  },

  standalone_total_PCAMI_Data1: {
    es: '<p>Recreación de escenarios en 3D</p>',
    en: '<p>3D crime scene recreation</p>',
    pt: '<p>Recriação de cenas em 3D</p>',
    fr: '<p>Reconstitution de scénarios en 3D</p>',
    eus: '<p>Gertalekuen berregitea 3Dn</p>',
    cat: '<p>Recreació d’escenaris en 3D</p>',
  },

  standalone_total_PCAMI_Data2: {
    es: '<p class="i18n-bold">Plataforma para la generación de modelos 3D</p><p>y el analisis de los mismo del Parque y Centro de Abastecimiento de Material de Intendencia(PCAMI)</p>',
    en: '<p class="i18n-bold">Platform for the generation of 3D models</p><p>and their analysis by the Parque y Centro de Abastecimiento de Material de Intendencia(PCAMI)</p>',
    pt: '<p class="i18n-bold">Plataforma para a criação de modelos 3D</p><p>e a análise dos mesmos do Parque e Centro de Abastecimento de Material de Intendência(PCAMI) </p>',
    fr: '<p class="i18n-bold">Plateforme pour la génération de modèles 3D</p><p>et l’analyse des modèles du « Parque y Centro de Abastecimiento de Material de Intendencia(PCAMI) »</p>',
    eus: '<p class="i18n-bold">3D ereduak sortzeko eta aztertzeko plataforma</p><p>Intendentziako Materiala Hornitzeko Parke eta Zentroarentzat(PCAMI) </p>',
    cat: '<p class="i18n-bold">Plataforma per a la generació i l’anàlisi de models 3D</p><p>del Parc i Centre d’Abastiment de Material d’Intendència(PCAMI) </p>',
  },

  standalone_total_ERTZAINTZA_Data2: {
    es: '<p><span class="i18n-bold">Plataforma para la generación de escenarios 3D </span>y posterior</p><p>análisis durante la investigación policial</p>',
    en: '<p><span class="i18n-bold">Platform for the generation of 3D scenes </span>and subsequent</p><p>analysis during police investigations</p>',
    pt: '<p><span class="i18n-bold">Plataforma para a criação de cenas em 3D </span>e posterior</p><p>análise durante a investigação policial</p>',
    fr: '<p class="i18n-bold">Plateforme pour la génération de scénarios 3D </p><p>et l\'analyse postérieure lors de l\'enquête de police</p>',
    eus: '<p class="i18n-bold">Polizia ikerketan 3D gertalekuak sortzeko</p><p>eta haien azterketa egiteko plataforma</p>',
    cat: '<p class="i18n-bold">Plataforma per a la generació d’escenaris 3D</p><p>i posterior anàlisi durant la investigació policial</p>',
  },
};

