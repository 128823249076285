export const LANGUAGE_SELECTOR_I18N_ENTRIES = {

  selectorIdioma_data1: {
    es: '<p>Idioma</p>',
    en: '<p>Language</p>',
    pt: '<p>Idioma</p>',
    fr: '<p>Langue</p>',
    eus: '<p>Hizkuntza</p>',
    cat: '<p>Idioma</p>',
  },

  selectorIdioma_data2: {
    es: '<p>Español</p>',
    en: '<p>Spanish</p>',
    pt: '<p>Espanhol</p>',
    fr: '<p>Espagnol</p>',
    eus: '<p>Gaztelania</p>',
    cat: '<p>Espanyol</p>',
  },

  selectorIdioma_data3: {
    es: '<p>Inglés</p>',
    en: '<p>English</p>',
    pt: '<p>Inglês</p>',
    fr: '<p>Anglais</p>',
    eus: '<p>Ingelesa</p>',
    cat: '<p>Anglès</p>',
  },

  selectorIdioma_data4: {
    es: '<p>Portugués</p>',
    en: '<p>Portuguese</p>',
    pt: '<p>Português</p>',
    fr: '<p>Portugais</p>',
    eus: '<p>Portugesa</p>',
    cat: '<p>Portuguès</p>',
  },

  selectorIdioma_data5: {
    es: '<p>Francés</p>',
    en: '<p>French</p>',
    pt: '<p>Francês</p>',
    fr: '<p>Français</p>',
    eus: '<p>Frantsesa</p>',
    cat: '<p>Francès</p>',
  },

  selectorIdioma_data6: {
    es: '<p>Euskera</p>',
    en: '<p>Basque</p>',
    pt: '<p>Euskera</p>',
    fr: '<p>Euskera</p>',
    eus: '<p>Euskara</p>',
    cat: '<p>Èuscar</p>',
  },

  selectorIdioma_data7: {
    es: '<p>Catalán</p>',
    en: '<p>Catalan</p>',
    pt: '<p>Catalão</p>',
    fr: '<p>Catalan</p>',
    eus: '<p>Katalana</p>',
    cat: '<p>Català</p>',
  },

};
