export const HIRING_I18N_ENTRIES = {

  hiring_Data1: {
    es: '<p>Condiciones generales de <span class="gray-title">&nbsp;Contratación&nbsp;</span></p>',
    en: '<p>General Terms and <span class="gray-title">&nbsp;Conditions&nbsp;</span></p>',
    pt: '<p>General Terms and <span class="gray-title">&nbsp;Conditions&nbsp;</span></p>',
    fr: '<p>General Terms and <span class="gray-title">&nbsp;Conditions&nbsp;</span></p>',
    eus: '<p>General Terms and <span class="gray-title">&nbsp;Conditions&nbsp;</span></p>',
    cat: '<p>General Terms and <span class="gray-title">&nbsp;Conditions&nbsp;</span></p>',
  },

  hiring_Data2: {
    es: '<p>Fecha de entrada en vigor:</p>',
    en: '<p>Effective Date:</p>',
    pt: '<p>Effective Date:</p>',
    fr: '<p>Effective Date:</p>',
    eus: '<p>Effective Date:</p>',
    cat: '<p>Effective Date:</p>',
  },

  hiring_Data3: {
    es: '<p>CONDICIONES GENERALES DE CONTRATACIÓN</p>',
    en: '<p>GENERAL TERMS AND CONDITIONS</p>',
    pt: '<p>GENERAL TERMS AND CONDITIONS</p>',
    fr: '<p>GENERAL TERMS AND CONDITIONS</p>',
    eus: '<p>GENERAL TERMS AND CONDITIONS</p>',
    cat: '<p>GENERAL TERMS AND CONDITIONS</p>',
  },

  hiring_Data4: {
    es: '<p>1. Acerca de estas condiciones</p>',
    en: '<p>1. About these Terms</p>',
    pt: '<p>1. About these Terms</p>',
    fr: '<p>1. About these Terms</p>',
    eus: '<p>1. About these Terms</p>',
    cat: '<p>1. About these Terms</p>',
  },

  hiring_Data5: {
    es: '<p>Estas Condiciones constituyen el acuerdo legalmente vinculante entre usted (o “el usuario”) y eyesCloud3D (o “nosotros”) y rigen el uso que haces de la plataforma de eyesCloud3D.</p>',
    en: '<p>These Terms and Conditions constitute the legally binding agreement between you (or “the User”) and eyesCloud3D (or “We” or similar) and govern your use of the eyesCloud3D platform.</p>',
    pt: '<p>These Terms and Conditions constitute the legally binding agreement between you (or “the User”) and eyesCloud3D (or “We” or similar) and govern your use of the eyesCloud3D platform.</p>',
    fr: '<p>These Terms and Conditions constitute the legally binding agreement between you (or “the User”) and eyesCloud3D (or “We” or similar) and govern your use of the eyesCloud3D platform.</p>',
    eus: '<p>These Terms and Conditions constitute the legally binding agreement between you (or “the User”) and eyesCloud3D (or “We” or similar) and govern your use of the eyesCloud3D platform.</p>',
    cat: '<p>These Terms and Conditions constitute the legally binding agreement between you (or “the User”) and eyesCloud3D (or “We” or similar) and govern your use of the eyesCloud3D platform.</p>',
  },

  hiring_Data6: {
    es: '<p>La Plataforma de eyesCloud3D, incluyendo sus funciones, interfaz de usuario, software y todas las aplicaciones, sitios web (como https://app.eyescloud3d.com) y otras plataformas tecnológicas asociadas se denominan conjuntamente en las presentes Condiciones como la “Plataforma”.</p>',
    en: '<p>The eyesCloud3D Platform, including its functions, user interface, software and all applications, websites (such as https://app.eyescloud3d.com) and other associated technological platforms are jointly referred to in these Terms as the "Platform".</p>',
    pt: '<p>The eyesCloud3D Platform, including its functions, user interface, software and all applications, websites (such as https://app.eyescloud3d.com) and other associated technological platforms are jointly referred to in these Terms as the "Platform".</p>',
    fr: '<p>The eyesCloud3D Platform, including its functions, user interface, software and all applications, websites (such as https://app.eyescloud3d.com) and other associated technological platforms are jointly referred to in these Terms as the "Platform".</p>',
    eus: '<p>The eyesCloud3D Platform, including its functions, user interface, software and all applications, websites (such as https://app.eyescloud3d.com) and other associated technological platforms are jointly referred to in these Terms as the "Platform".</p>',
    cat: '<p>The eyesCloud3D Platform, including its functions, user interface, software and all applications, websites (such as https://app.eyescloud3d.com) and other associated technological platforms are jointly referred to in these Terms as the "Platform".</p>',
  },

  hiring_Data7: {
    es: '<p>La titularidad de eyesCloud3D la ostenta: eCapture Research and Development S.L. (en adelante “la empresa”), con domicilio social en Torre Siglo XXI, Paseo Fluvial 15, Planta 11ª Oficina Suroeste, Badajoz, España, inscrita en el Registro Mercantil de Badajoz en el Tomo 539, Folio 171, Hoja BA-23900 Y Número de Identificación Fiscal ESB06619944.</p>',
    en: '<p>The ownership of eyesCloud3D is held by: eCapture Research and Development S.L. (hereinafter "the Company"), with registered office at Torre Siglo XXI, Paseo Fluvial 15, Planta 11ª Oficina Suroeste, Badajoz, Spain, registered in the Companies Registry of Badajoz in Volume 539, Folio 171, Sheet BA-23900 and Tax Identification Number ESB06619944.</p>',
    pt: '<p>The ownership of eyesCloud3D is held by: eCapture Research and Development S.L. (hereinafter "the Company"), with registered office at Torre Siglo XXI, Paseo Fluvial 15, Planta 11ª Oficina Suroeste, Badajoz, Spain, registered in the Companies Registry of Badajoz in Volume 539, Folio 171, Sheet BA-23900 and Tax Identification Number ESB06619944.</p>',
    fr: '<p>The ownership of eyesCloud3D is held by: eCapture Research and Development S.L. (hereinafter "the Company"), with registered office at Torre Siglo XXI, Paseo Fluvial 15, Planta 11ª Oficina Suroeste, Badajoz, Spain, registered in the Companies Registry of Badajoz in Volume 539, Folio 171, Sheet BA-23900 and Tax Identification Number ESB06619944.</p>',
    eus: '<p>The ownership of eyesCloud3D is held by: eCapture Research and Development S.L. (hereinafter "the Company"), with registered office at Torre Siglo XXI, Paseo Fluvial 15, Planta 11ª Oficina Suroeste, Badajoz, Spain, registered in the Companies Registry of Badajoz in Volume 539, Folio 171, Sheet BA-23900 and Tax Identification Number ESB06619944.</p>',
    cat: '<p>The ownership of eyesCloud3D is held by: eCapture Research and Development S.L. (hereinafter "the Company"), with registered office at Torre Siglo XXI, Paseo Fluvial 15, Planta 11ª Oficina Suroeste, Badajoz, Spain, registered in the Companies Registry of Badajoz in Volume 539, Folio 171, Sheet BA-23900 and Tax Identification Number ESB06619944.</p>',
  },

  hiring_Data8: {
    es: '<p>Este documento (así como todo otro documento que aquí se mencione) regula las condiciones por las que se rige el uso de este Sitio Web (https://app.eyescloud3d.com/) y la compra o adquisición de productos y/o servicios en el mismo (en adelante, Condiciones).</p>',
    en: '<p>This document (as well as any other documents mentioned herein) regulates the terms and conditions governing the use of this Website (https://app.eyescloud3d.com/) and the purchase of products and/or services therein (hereinafter, Terms).</p>',
    pt: '<p>This document (as well as any other documents mentioned herein) regulates the terms and conditions governing the use of this Website (https://app.eyescloud3d.com/) and the purchase of products and/or services therein (hereinafter, Terms).</p>',
    fr: '<p>This document (as well as any other documents mentioned herein) regulates the terms and conditions governing the use of this Website (https://app.eyescloud3d.com/) and the purchase of products and/or services therein (hereinafter, Terms).</p>',
    eus: '<p>This document (as well as any other documents mentioned herein) regulates the terms and conditions governing the use of this Website (https://app.eyescloud3d.com/) and the purchase of products and/or services therein (hereinafter, Terms).</p>',
    cat: '<p>This document (as well as any other documents mentioned herein) regulates the terms and conditions governing the use of this Website (https://app.eyescloud3d.com/) and the purchase of products and/or services therein (hereinafter, Terms).</p>',
  },

  hiring_Data9: {
    es: '<p>A efectos de estas Condiciones se entiende que la actividad que desarrolla la empresa a través de eyesCloud3D es la generación automática de modelos 3D con cualquier tipo de cámara y sin necesidad de conocimientos previos de manera rápida y sencilla (en adelante “el servicio”).</p>',
    en: '<p>For the purposes of these Terms, it is understood that the activity carried out by the company through eyesCloud3D is the quick and easy automatic generation of 3D models with any type of camera and without the need for prior knowledge (hereinafter "the Service").</p>',
    pt: '<p>For the purposes of these Terms, it is understood that the activity carried out by the company through eyesCloud3D is the quick and easy automatic generation of 3D models with any type of camera and without the need for prior knowledge (hereinafter "the Service").</p>',
    fr: '<p>For the purposes of these Terms, it is understood that the activity carried out by the company through eyesCloud3D is the quick and easy automatic generation of 3D models with any type of camera and without the need for prior knowledge (hereinafter "the Service").</p>',
    eus: '<p>For the purposes of these Terms, it is understood that the activity carried out by the company through eyesCloud3D is the quick and easy automatic generation of 3D models with any type of camera and without the need for prior knowledge (hereinafter "the Service").</p>',
    cat: '<p>For the purposes of these Terms, it is understood that the activity carried out by the company through eyesCloud3D is the quick and easy automatic generation of 3D models with any type of camera and without the need for prior knowledge (hereinafter "the Service").</p>',
  },

  hiring_Data10: {
    es: '<p>Además de leer las presentes Condiciones, antes de acceder, navegar y/o usar esta página web, el Usuario ha de haber leído el Aviso Legal y las Condiciones Generales de Uso, incluyendo, la política de cookies, y la política de privacidad y de protección de datos de eyesCloud3D.</p>',
    en: '<p>In addition to reading these Terms, before accessing, browsing and/or using this website, the User must have read the Legal Notice and the General Terms of Use, including the Cookie Policy, and the Privacy Policy of eyesCloud3D.</p>',
    pt: '<p>In addition to reading these Terms, before accessing, browsing and/or using this website, the User must have read the Legal Notice and the General Terms of Use, including the Cookie Policy, and the Privacy Policy of eyesCloud3D.</p>',
    fr: '<p>In addition to reading these Terms, before accessing, browsing and/or using this website, the User must have read the Legal Notice and the General Terms of Use, including the Cookie Policy, and the Privacy Policy of eyesCloud3D.</p>',
    eus: '<p>In addition to reading these Terms, before accessing, browsing and/or using this website, the User must have read the Legal Notice and the General Terms of Use, including the Cookie Policy, and the Privacy Policy of eyesCloud3D.</p>',
    cat: '<p>In addition to reading these Terms, before accessing, browsing and/or using this website, the User must have read the Legal Notice and the General Terms of Use, including the Cookie Policy, and the Privacy Policy of eyesCloud3D.</p>',
  },

  hiring_Data11: {
    es: '<p>Al utilizar este Sitio Web o al hacer y/o solicitar la adquisición de un producto y/o servicio a través del mismo el Usuario consiente quedar vinculado por estas Condiciones y por todo lo anteriormente mencionado, por lo que, si no está de acuerdo con todo ello, no debe usar este Sitio Web.</p>',
    en: '<p>By using this Website or by purchasing and/or requesting the purchase of a product and/or service through it, the User consents to be bound by these Terms and by all the aforementioned provisions; in this order of things, if they do not agree with said provisions and documents, they must refrain from using this Website.</p>',
    pt: '<p>By using this Website or by purchasing and/or requesting the purchase of a product and/or service through it, the User consents to be bound by these Terms and by all the aforementioned provisions; in this order of things, if they do not agree with said provisions and documents, they must refrain from using this Website.</p>',
    fr: '<p>By using this Website or by purchasing and/or requesting the purchase of a product and/or service through it, the User consents to be bound by these Terms and by all the aforementioned provisions; in this order of things, if they do not agree with said provisions and documents, they must refrain from using this Website.</p>',
    eus: '<p>By using this Website or by purchasing and/or requesting the purchase of a product and/or service through it, the User consents to be bound by these Terms and by all the aforementioned provisions; in this order of things, if they do not agree with said provisions and documents, they must refrain from using this Website.</p>',
    cat: '<p>By using this Website or by purchasing and/or requesting the purchase of a product and/or service through it, the User consents to be bound by these Terms and by all the aforementioned provisions; in this order of things, if they do not agree with said provisions and documents, they must refrain from using this Website.</p>',
  },

  hiring_Data12: {
    es: '<p>Asimismo, se informa que estas Condiciones podrían ser modificadas. El Usuario es responsable de consultarlas cada vez que acceda, navegue y/o use el Sitio Web ya que serán aplicables aquellas que se encuentren vigentes en el momento en que se solicite la adquisición de productos y/o servicios.</p>',
    en: '<p>Furthermore, We inform the User that these Terms could be modified. The User is responsible for consulting them each time they access, browse and/or use the Website, since the Terms applicable to any purchase of products and/or services shall be those in force at the time of the purchase.</p>',
    pt: '<p>Furthermore, We inform the User that these Terms could be modified. The User is responsible for consulting them each time they access, browse and/or use the Website, since the Terms applicable to any purchase of products and/or services shall be those in force at the time of the purchase.</p>',
    fr: '<p>Furthermore, We inform the User that these Terms could be modified. The User is responsible for consulting them each time they access, browse and/or use the Website, since the Terms applicable to any purchase of products and/or services shall be those in force at the time of the purchase.</p>',
    eus: '<p>Furthermore, We inform the User that these Terms could be modified. The User is responsible for consulting them each time they access, browse and/or use the Website, since the Terms applicable to any purchase of products and/or services shall be those in force at the time of the purchase.</p>',
    cat: '<p>Furthermore, We inform the User that these Terms could be modified. The User is responsible for consulting them each time they access, browse and/or use the Website, since the Terms applicable to any purchase of products and/or services shall be those in force at the time of the purchase.</p>',
  },

  hiring_Data13: {
    es: '<p>Para todas las preguntas que el Usuario pueda tener en relación con las Condiciones puede ponerse en contacto con el titular utilizando los datos de contacto facilitados más arriba o, en su caso, utilizando el formulario de contacto.</p>',
    en: '<p>Should the User have any questions or doubts regarding these Terms, the User can contact the owner of the Site using the contact details provided above or, where appropriate, using the contact form.</p>',
    pt: '<p>Should the User have any questions or doubts regarding these Terms, the User can contact the owner of the Site using the contact details provided above or, where appropriate, using the contact form.</p>',
    fr: '<p>Should the User have any questions or doubts regarding these Terms, the User can contact the owner of the Site using the contact details provided above or, where appropriate, using the contact form.</p>',
    eus: '<p>Should the User have any questions or doubts regarding these Terms, the User can contact the owner of the Site using the contact details provided above or, where appropriate, using the contact form.</p>',
    cat: '<p>Should the User have any questions or doubts regarding these Terms, the User can contact the owner of the Site using the contact details provided above or, where appropriate, using the contact form.</p>',
  },

  hiring_Data14: {
    es: '<p>2. El Usuario</p>',
    en: '<p>2. The User</p>',
    pt: '<p>2. The User</p>',
    fr: '<p>2. The User</p>',
    eus: '<p>2.	The User</p>',
    cat: '<p>2.	The User</p>',
  },

  hiring_Data15: {
    es: '<p>El acceso, la navegación y uso del Sitio Web o la Plataforma, confiere la condición de usuario (en adelante referido, indistintamente, individualmente como Usuario o conjuntamente como Usuarios), por lo que se aceptan, desde que se inicia la navegación, todas las Condiciones aquí establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente normativa legal de obligado cumplimiento según el caso.</p>',
    en: '<p>Any person who has access to, surfs or makes use of the Website or the Platform shall be considered a User (hereinafter referred to, indistinctly, individually as User or jointly as Users), so all the Terms established herein, as well as their subsequent modifications, are accepted from the moment any of these actions occur, without prejudice to the application of the corresponding mandatory legal regulations, where appropriate.</p>',
    pt: '<p>Any person who has access to, surfs or makes use of the Website or the Platform shall be considered a User (hereinafter referred to, indistinctly, individually as User or jointly as Users), so all the Terms established herein, as well as their subsequent modifications, are accepted from the moment any of these actions occur, without prejudice to the application of the corresponding mandatory legal regulations, where appropriate.</p>',
    fr: '<p>Any person who has access to, surfs or makes use of the Website or the Platform shall be considered a User (hereinafter referred to, indistinctly, individually as User or jointly as Users), so all the Terms established herein, as well as their subsequent modifications, are accepted from the moment any of these actions occur, without prejudice to the application of the corresponding mandatory legal regulations, where appropriate.</p>',
    eus: '<p>Any person who has access to, surfs or makes use of the Website or the Platform shall be considered a User (hereinafter referred to, indistinctly, individually as User or jointly as Users), so all the Terms established herein, as well as their subsequent modifications, are accepted from the moment any of these actions occur, without prejudice to the application of the corresponding mandatory legal regulations, where appropriate.</p>',
    cat: '<p>Any person who has access to, surfs or makes use of the Website or the Platform shall be considered a User (hereinafter referred to, indistinctly, individually as User or jointly as Users), so all the Terms established herein, as well as their subsequent modifications, are accepted from the moment any of these actions occur, without prejudice to the application of the corresponding mandatory legal regulations, where appropriate.</p>',
  },

  hiring_Data16: {
    es: '<p>El Usuario asume su responsabilidad de un uso correcto de la Plataforma. Esta responsabilidad se extenderá a:</p>',
    en: '<p>The User is responsible for using the Platform correctly. The User is therefore responsible for:</p>',
    pt: '<p>The User is responsible for using the Platform correctly. The User is therefore responsible for:</p>',
    fr: '<p>The User is responsible for using the Platform correctly. The User is therefore responsible for:</p>',
    eus: '<p>The User is responsible for using the Platform correctly. The User is therefore responsible for:</p>',
    cat: '<p>The User is responsible for using the Platform correctly. The User is therefore responsible for:</p>',
  },

  hiring_Data17: {
    es: '<p>Hacer uso de la Plataforma únicamente para realizar consultas y compras o adquisiciones legalmente válidas.</p>',
    en: '<p>Ensuring they use the Site solely for legally valid searches, requests and purchases.</p>',
    pt: '<p>Ensuring they use the Site solely for legally valid searches, requests and purchases.</p>',
    fr: '<p>Ensuring they use the Site solely for legally valid searches, requests and purchases.</p>',
    eus: '<p>Ensuring they use the Site solely for legally valid searches, requests and purchases.</p>',
    cat: '<p>Ensuring they use the Site solely for legally valid searches, requests and purchases.</p>',
  },

  hiring_Data18: {
    es: '<p>No realizar ninguna compra falsa o fraudulenta. Si razonablemente se pudiera considerar que se ha hecho una compra de esta índole, podría ser anulada y se informaría a las autoridades pertinentes.</p>',
    en: '<p>Do not make any false or fraudulent purchases. If We have reason to believe such a purchase has been made, it could be canceled and the relevant authorities shall be informed.</p>',
    pt: '<p>Do not make any false or fraudulent purchases. If We have reason to believe such a purchase has been made, it could be canceled and the relevant authorities shall be informed.</p>',
    fr: '<p>Do not make any false or fraudulent purchases. If We have reason to believe such a purchase has been made, it could be canceled and the relevant authorities shall be informed.</p>',
    eus: '<p>Do not make any false or fraudulent purchases. If We have reason to believe such a purchase has been made, it could be canceled and the relevant authorities shall be informed.</p>',
    cat: '<p>Do not make any false or fraudulent purchases. If We have reason to believe such a purchase has been made, it could be canceled and the relevant authorities shall be informed.</p>',
  },

  hiring_Data19: {
    es: '<p>Facilitar datos de contacto veraces y lícitos, por ejemplo, dirección de correo electrónico, dirección postal y/u otros datos (ver Aviso Legal y Condiciones Generales de Uso).</p>',
    en: '<p>Provide truthful and lawful contact data, for example, email address, postal address and/or other data (see Legal Notice and General Terms of Use).</p>',
    pt: '<p>Provide truthful and lawful contact data, for example, email address, postal address and/or other data (see Legal Notice and General Terms of Use).</p>',
    fr: '<p>Provide truthful and lawful contact data, for example, email address, postal address and/or other data (see Legal Notice and General Terms of Use).</p>',
    eus: '<p>Provide truthful and lawful contact data, for example, email address, postal address and/or other data (see Legal Notice and General Terms of Use).</p>',
    cat: '<p>Provide truthful and lawful contact data, for example, email address, postal address and/or other data (see Legal Notice and General Terms of Use).</p>',
  },

  hiring_Data20: {
    es: '<p>¿QUIÉN PUEDE DARSE DE ALTA COMO USUARIO EN LA PLATAFORMA?</p>',
    en: '<p>WHO CAN REGISTER AS A USER ON THE PLATFORM?</p>',
    pt: '<p>WHO CAN REGISTER AS A USER ON THE PLATFORM?</p>',
    fr: '<p>WHO CAN REGISTER AS A USER ON THE PLATFORM?</p>',
    eus: '<p>WHO CAN REGISTER AS A USER ON THE PLATFORM?</p>',
    cat: '<p>WHO CAN REGISTER AS A USER ON THE PLATFORM?</p>',
  },

  hiring_Data21: {
    es: '<p>Para poder acceder a la Plataforma, debe tener, al menos, 18 años o haber alcanzado la mayoría de edad legal en el estado o territorio donde residas. Si fueras menor de edad, solo podrías utilizar la Plataforma como Usuario Autorizado de cualquiera de tus progenitores o de tu tutor legal.</p>',
    en: '<p>In order to access the Platform, you must be at least 18 years of age or have reached the legal age in the state or territory where you reside. If you are a minor, you may only use the Platform as an Authorized User, authorized by either of your parents or legal guardian.</p>',
    pt: '<p>In order to access the Platform, you must be at least 18 years of age or have reached the legal age in the state or territory where you reside. If you are a minor, you may only use the Platform as an Authorized User, authorized by either of your parents or legal guardian.</p>',
    fr: '<p>In order to access the Platform, you must be at least 18 years of age or have reached the legal age in the state or territory where you reside. If you are a minor, you may only use the Platform as an Authorized User, authorized by either of your parents or legal guardian.</p>',
    eus: '<p>In order to access the Platform, you must be at least 18 years of age or have reached the legal age in the state or territory where you reside. If you are a minor, you may only use the Platform as an Authorized User, authorized by either of your parents or legal guardian.</p>',
    cat: '<p>In order to access the Platform, you must be at least 18 years of age or have reached the legal age in the state or territory where you reside. If you are a minor, you may only use the Platform as an Authorized User, authorized by either of your parents or legal guardian.</p>',
  },

  hiring_Data22: {
    es: '<p>3. Uso de la Plataforma</p>',
    en: '<p>3. Platform Use</p>',
    pt: '<p>3. Platform Use</p>',
    fr: '<p>3. Platform Use</p>',
    eus: '<p>3.	Platform Use</p>',
    cat: '<p>3.	Platform Use</p>',
  },

  hiring_Data23: {
    es: '<p>Para poder disponer de las funcionalidades de la Plataforma será necesario que crees una cuenta. El registro es gratuito.</p>',
    en: '<p>In order to have access to the Platform\'s functionalities, you will need to create an account. Registration is free. </p>',
    pt: '<p>In order to have access to the Platform\'s functionalities, you will need to create an account. Registration is free. </p>',
    fr: '<p>In order to have access to the Platform\'s functionalities, you will need to create an account. Registration is free. </p>',
    eus: '<p>In order to have access to the Platform\'s functionalities, you will need to create an account. Registration is free. </p>',
    cat: '<p>In order to have access to the Platform\'s functionalities, you will need to create an account. Registration is free. </p>',
  },

  hiring_Data24: {
    es: '<p>eyesCloud3D funciona a través de una serie de suscripciones, cada una con sus propias características, precios y funcionalidades. Si tienes una suscripción de pago, te cobraremos periódicamente en función de tu tarifa y la fecha en que te suscribiste.</p>',
    en: '<p>eyesCloud3D works through a series of subscriptions, each with its own features, prices and functionalities. If you have a paid subscription, you will be charged periodically based on your plan and the date you joined.</p>',
    pt: '<p>eyesCloud3D works through a series of subscriptions, each with its own features, prices and functionalities. If you have a paid subscription, you will be charged periodically based on your plan and the date you joined.</p>',
    fr: '<p>eyesCloud3D works through a series of subscriptions, each with its own features, prices and functionalities. If you have a paid subscription, you will be charged periodically based on your plan and the date you joined.</p>',
    eus: '<p>eyesCloud3D works through a series of subscriptions, each with its own features, prices and functionalities. If you have a paid subscription, you will be charged periodically based on your plan and the date you joined.</p>',
    cat: '<p>eyesCloud3D works through a series of subscriptions, each with its own features, prices and functionalities. If you have a paid subscription, you will be charged periodically based on your plan and the date you joined.</p>',
  },

  hiring_Data25: {
    es: '<p>Las tarifas de los que disponemos son:</p>',
    en: '<p>The plans available are:</p>',
    pt: '<p>The plans available are:</p>',
    fr: '<p>The plans available are:</p>',
    eus: '<p>The plans available are:</p>',
    cat: '<p>The plans available are:</p>',
  },

  hiring_Data26: {
    es: '<p>Tarifa Free:</p>',
    en: '<p>Free Plan::</p>',
    pt: '<p>Free Plan::</p>',
    fr: '<p>Free Plan::</p>',
    eus: '<p>Free Plan::</p>',
    cat: '<p>Free Plan::</p>',
  },

  hiring_Data27: {
    es: '<p>La tarifa Free es la opción perfecta para aquellos que buscan explorar la plataforma sin compromisos. Con acceso gratuito, esta tarifa ofrece una selección básica de características diseñadas para satisfacer las necesidades fundamentales de los usuarios, aunque tiene algunas limitaciones en comparación con las suscripciones de pago, como las herramientas avanzadas o contenido exclusivo, la Tarifa Free proporciona una experiencia sólida para aquellos que desean probar la plataforma antes de comprometerse con una suscripción de pago. Ideal para usuarios ocasionales o aquellos que buscan una introducción sin costos. Puedes cambiar tu tarifa Free a una tarifa de pago cuando lo desees.</p>',
    en: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions – such as advanced tools or exclusive content – the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge. You can upgrade your Free Plan to a paid plan at any time.</p>',
    pt: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions – such as advanced tools or exclusive content – the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge. You can upgrade your Free Plan to a paid plan at any time.</p>',
    fr: '<p><The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions – such as advanced tools or exclusive content – the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge. You can upgrade your Free Plan to a paid plan at any time./p>',
    eus: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions – such as advanced tools or exclusive content – the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge. You can upgrade your Free Plan to a paid plan at any time.</p>',
    cat: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions – such as advanced tools or exclusive content – the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge. You can upgrade your Free Plan to a paid plan at any time.</p>',
  },

  hiring_Data28: {
    es: '<p>Tarifa Pro:</p>',
    en: '<p>Pro Plan:</p>',
    pt: '<p>Pro Plan:</p>',
    fr: '<p>Pro Plan:</p>',
    eus: '<p>Pro Plan:</p>',
    cat: '<p>Pro Plan:</p>',
  },

  hiring_Data29: {
    es: '<p>La tarifa Pro es la opción premium para aquellos que desean llevar su experiencia a un nivel superior. A cambio de una tarifa periódica, los suscriptores obtienen acceso a una gama completa de funciones avanzadas, herramientas únicas y beneficios adicionales. Esta tarifa está diseñado para usuarios más comprometidos y exigentes que buscan aprovechar al máximo la plataforma. Con características como mayor capacidad de almacenamiento, funciones de personalización y soporte prioritario, la Tarifa Pro ofrece una experiencia completa y sin restricciones para satisfacer las necesidades de los usuarios más dedicados. Puedes personalizar tu Tarifa Pro añadiendo funcionalidades específicas para Ingeniería, Seguridad y Agricultura. Puedes encontrar más información aquí en apartado 11. Tarifas y Precios.</p>',
    en: '<p>The Pro Rate: is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This rate is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Rate offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Rate by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    pt: '<p>The Pro Rate: is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This rate is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Rate offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Rate by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    fr: '<p>The Pro Rate: is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This rate is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Rate offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Rate by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    eus: '<p>The Pro Rate: is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This rate is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Rate offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Rate by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    cat: '<p>The Pro Rate: is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This rate is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Rate offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Rate by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
  },

  hiring_Data30: {
    es: '<p>Tarifa Business:</p>',
    en: '<p>Business Rate:</p>',
    pt: '<p>Business Rate:</p>',
    fr: '<p>Business Rate:</p>',
    eus: '<p>Business Rate:</p>',
    cat: '<p>Business Rate:</p>',
  },

  hiring_Data31: {
    es: '<p>La Tarifa Business es la opción ideal para empresas y equipos que buscan herramientas y recursos especializados para potenciar su productividad. Diseñado específicamente para entornos comerciales, esta tarifa de suscripción de pago ofrece funcionalidades avanzadas orientadas a la colaboración, seguridad empresarial y gestión de usuarios. Es la elección perfecta para aquellos que buscan aprovechar al máximo la plataforma en un entorno empresarial ya que permite aumentar el número de usuarios de una cuenta fomentando el trabajo en equipo. Puedes personalizar tu Tarifa Business añadiendo funcionalidades específicas para Ingeniería, Seguridad y Agricultura. Puedes encontrar más información aquí 11. Tarifas y Precios.</p>',
    en: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Plan by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    pt: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Plan by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    fr: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Plan by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    eus: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Plan by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
    cat: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users. You can customize your Pro Plan by adding specific functionalities for Engineering, Safety and Agriculture. You can find more information here in section 11. Fees and Pricing.</p>',
  },

  hiring_Data32: {
    es: '<p>Puedes encontrar más información sobre limitaciones de los contenidos y acceso a herramientas aquí 11. Tarifas y Precios.</p>',
    en: '<p>More information on content limitations and access to tools can be found here 11. Fees and Pricing.</p>',
    pt: '<p>More information on content limitations and access to tools can be found here 11. Fees and Pricing.</p>',
    fr: '<p>More information on content limitations and access to tools can be found here 11. Fees and Pricing.</p>',
    eus: '<p>More information on content limitations and access to tools can be found here 11. Fees and Pricing.</p>',
    cat: '<p>More information on content limitations and access to tools can be found here 11. Fees and Pricing.</p>',
  },

  hiring_Data33: {
    es: '<p>eyesCloud3D también ofrece unos servicios complementarios que permiten aumentar las funcionalidades de la plataforma:</p>',
    en: '<p>eyesCloud3D also offers additional services that allow you to increase the platform\'s features:</p>',
    pt: '<p>eyesCloud3D also offers additional services that allow you to increase the platform\'s features:</p>',
    fr: '<p>eyesCloud3D also offers additional services that allow you to increase the platform\'s features:</p>',
    eus: '<p>eyesCloud3D also offers additional services that allow you to increase the platform\'s features:</p>',
    cat: '<p>eyesCloud3D also offers additional services that allow you to increase the platform\'s features:</p>',
  },

  hiring_Data34: {
    es: '<p>Funcionalidades Adicionales</p>',
    en: '<p>Additional Features</p>',
    pt: '<p>Additional Features</p>',
    fr: '<p>Additional Features</p>',
    eus: '<p>Additional Features</p>',
    cat: '<p>Additional Features</p>',
  },

  hiring_Data35: {
    es: '<p>- Descargas e importaciones adicionales.</p>',
    en: '<p>- Additional downloads and imports.</p>',
    pt: '<p>-	Additional downloads and imports.</p>',
    fr: '<p>-	Additional downloads and imports.</p>',
    eus: '<p>- Additional downloads and imports.</p>',
    cat: '<p>- Additional downloads and imports.</p>',
  },

  hiring_Data36: {
    es: '<p>- Usuarios adicionales.</p>',
    en: '<p>- Additional Users.</p>',
    pt: '<p>-	Additional Users.</p>',
    fr: '<p>-	Additional Users.</p>',
    eus: '<p>- Additional Users.</p>',
    cat: '<p>- Additional Users.</p>',
  },

  hiring_Data37: {
    es: '<p>- Almacenamiento adicional.</p>',
    en: '<p>-	Additional storage.</p>',
    pt: '<p>-	Additional storage.</p>',
    fr: '<p>-	Additional storage.</p>',
    eus: '<p>- Additional storage.</p>',
    cat: '<p>- Additional storage.</p>',
  },

  hiring_Data38: {
    es: '<p>Puedes encontrar más información acerca de las tarifas de estas Funcionalidades Adicionales aquí 11. Tarifas y Precios.</p>',
    en: '<p>You can find more information on the fees for these Additional Features here 11. Fees and Pricing.</p>',
    pt: '<p>You can find more information on the fees for these Additional Features here 11. Fees and Pricing.</p>',
    fr: '<p>You can find more information on the fees for these Additional Features here 11. Fees and Pricing.</p>',
    eus: '<p>You can find more information on the fees for these Additional Features here 11. Fees and Pricing.</p>',
    cat: '<p>You can find more information on the fees for these Additional Features here 11. Fees and Pricing.</p>',
  },

  hiring_Data39: {
    es: '<p>4. Procesos de Compra o Adquisición</p>',
    en: '<p>4. Purchase or Procurement Processes</p>',
    pt: '<p>4. Purchase or Procurement Processes</p>',
    fr: '<p>4. Purchase or Procurement Processes</p>',
    eus: '<p>4.	Purchase or Procurement Processes</p>',
    cat: '<p>4.	Purchase or Procurement Processes</p>',
  },

  hiring_Data40: {
    es: '<p>Los usuarios debidamente registrados pueden comprar suscripciones y funcionalidades adicionales por los medios y formas establecidos. El Usuario deberá comprobar la información que en cada paso se le solicita, aunque durante el proceso de compra, antes de realizar el pago, se pueden modificar los datos de la compra.</p>',
    en: '<p>Duly registered users can purchase subscriptions and additional functionalities by the established means and ways. The User must check the information requested at each step, although during the purchase process, before making the payment, the purchase data can be modified.</p>',
    pt: '<p>Duly registered users can purchase subscriptions and additional functionalities by the established means and ways. The User must check the information requested at each step, although during the purchase process, before making the payment, the purchase data can be modified.</p>',
    fr: '<p>Duly registered users can purchase subscriptions and additional functionalities by the established means and ways. The User must check the information requested at each step, although during the purchase process, before making the payment, the purchase data can be modified.</p>',
    eus: '<p>Duly registered users can purchase subscriptions and additional functionalities by the established means and ways. The User must check the information requested at each step, although during the purchase process, before making the payment, the purchase data can be modified.</p>',
    cat: '<p>Duly registered users can purchase subscriptions and additional functionalities by the established means and ways. The User must check the information requested at each step, although during the purchase process, before making the payment, the purchase data can be modified.</p>',
  },

  hiring_Data41: {
    es: '<p>Seguidamente, el usuario recibirá un correo electrónico confirmando que eyesCloud3D ha recibido su solicitud de compra y/o prestación del servicio, es decir, la confirmación del pedido.</p>',
    en: '<p>The User will then receive an email confirming that eyesCloud3D has received their request for the purchase of the good and/or service – in other words, the order confirmation.</p>',
    pt: '<p>The User will then receive an email confirming that eyesCloud3D has received their request for the purchase of the good and/or service – in other words, the order confirmation.</p>',
    fr: '<p>The User will then receive an email confirming that eyesCloud3D has received their request for the purchase of the good and/or service – in other words, the order confirmation.</p>',
    eus: '<p>The User will then receive an email confirming that eyesCloud3D has received their request for the purchase of the good and/or service – in other words, the order confirmation.</p>',
    cat: '<p>The User will then receive an email confirming that eyesCloud3D has received their request for the purchase of the good and/or service – in other words, the order confirmation.</p>',
  },

  hiring_Data42: {
    es: '<p>Una vez el procedimiento de compra ha concluido, el usuario consiente que eyesCloud3D genere una factura electrónica que se hará llegar al Usuario a través del correo electrónico. Y, en su caso, a través de su espacio personal en la Plataforma. Asimismo, el Usuario puede, si así lo desea, obtener una copia de su factura en papel, solicitándolo a eyesCloud3D utilizando los espacios de contacto Sitio Web o a través de los datos de contacto facilitados más arriba.</p>',
    en: '<p>Once the purchase procedure has been completed, the User consents to eyesCloud3D generating an electronic invoice that will be sent to the User via email. And, where appropriate, through their personal account on the Platform. Likewise, the User may, if he or she so wishes, obtain a paper copy of the invoice, requesting it from eyesCloud3D via the contact spaces on the Website or through the contact details provided above.</p>',
    pt: '<p>Once the purchase procedure has been completed, the User consents to eyesCloud3D generating an electronic invoice that will be sent to the User via email. And, where appropriate, through their personal account on the Platform. Likewise, the User may, if he or she so wishes, obtain a paper copy of the invoice, requesting it from eyesCloud3D via the contact spaces on the Website or through the contact details provided above.</p>',
    fr: '<p>Once the purchase procedure has been completed, the User consents to eyesCloud3D generating an electronic invoice that will be sent to the User via email. And, where appropriate, through their personal account on the Platform. Likewise, the User may, if he or she so wishes, obtain a paper copy of the invoice, requesting it from eyesCloud3D via the contact spaces on the Website or through the contact details provided above.</p>',
    eus: '<p>Once the purchase procedure has been completed, the User consents to eyesCloud3D generating an electronic invoice that will be sent to the User via email. And, where appropriate, through their personal account on the Platform. Likewise, the User may, if he or she so wishes, obtain a paper copy of the invoice, requesting it from eyesCloud3D via the contact spaces on the Website or through the contact details provided above.</p>',
    cat: '<p>Once the purchase procedure has been completed, the User consents to eyesCloud3D generating an electronic invoice that will be sent to the User via email. And, where appropriate, through their personal account on the Platform. Likewise, the User may, if he or she so wishes, obtain a paper copy of the invoice, requesting it from eyesCloud3D via the contact spaces on the Website or through the contact details provided above.</p>',
  },

  hiring_Data43: {
    es: '<p>El usuario reconoce estar al corriente, en el momento de la compra, de ciertas condiciones particulares de venta que conciernen al servicio en cuestión y que se muestran junto a la presentación y reconoce que la realización del pedido de compra materializa la aceptación plena y completa de las condiciones particulares de venta aplicables a cada caso.</p>',
    en: '<p>The User acknowledges they are aware, at the time the purchase is made, that certain special terms and conditions may apply to the sale in question, and which will be duly displayed next to the presentation, and the User further acknowledges that by completing the purchase order process, they fully accept these special terms and conditions of sale applicable to the individual case.</p>',
    pt: '<p>The User acknowledges they are aware, at the time the purchase is made, that certain special terms and conditions may apply to the sale in question, and which will be duly displayed next to the presentation, and the User further acknowledges that by completing the purchase order process, they fully accept these special terms and conditions of sale applicable to the individual case.</p>',
    fr: '<p>The User acknowledges they are aware, at the time the purchase is made, that certain special terms and conditions may apply to the sale in question, and which will be duly displayed next to the presentation, and the User further acknowledges that by completing the purchase order process, they fully accept these special terms and conditions of sale applicable to the individual case.</p>',
    eus: '<p>The User acknowledges they are aware, at the time the purchase is made, that certain special terms and conditions may apply to the sale in question, and which will be duly displayed next to the presentation, and the User further acknowledges that by completing the purchase order process, they fully accept these special terms and conditions of sale applicable to the individual case.</p>',
    cat: '<p>The User acknowledges they are aware, at the time the purchase is made, that certain special terms and conditions may apply to the sale in question, and which will be duly displayed next to the presentation, and the User further acknowledges that by completing the purchase order process, they fully accept these special terms and conditions of sale applicable to the individual case.</p>',
  },

  hiring_Data44: {
    es: '<p>Cuotas</p>',
    en: '<p>Fees</p>',
    pt: '<p>Fees</p>',
    fr: '<p>Fees</p>',
    eus: '<p>Fees</p>',
    cat: '<p>Fees</p>',
  },

  hiring_Data45: {
    es: '<p>Puedes consultar las cuotas vigentes de tu suscripción a eyesCloud3D junto con las distintas modalidades de suscripción disponibles y la duración del Servicio aquí 11. Tarifas y Precios. En la sección de “Cuenta” puedes consultar los detalles de la modalidad de suscripción a la que estés suscrito, la cuota correspondiente y la próxima fecha en la que tu cuota de suscripción será exigible.</p>',
    en: '<p>You can check the current fees of your eyesCloud3D subscription along with the different subscription plans available and the duration of the Service here 11. Fees and Pricing. In the "Account" section you can see the details of the subscription method to which you are subscribed, the corresponding fee and the next date on which your subscription fee will be due.</p>',
    pt: '<p>You can check the current fees of your eyesCloud3D subscription along with the different subscription plans available and the duration of the Service here 11. Fees and Pricing. In the "Account" section you can see the details of the subscription method to which you are subscribed, the corresponding fee and the next date on which your subscription fee will be due.</p>',
    fr: '<p>You can check the current fees of your eyesCloud3D subscription along with the different subscription plans available and the duration of the Service here 11. Fees and Pricing. In the "Account" section you can see the details of the subscription method to which you are subscribed, the corresponding fee and the next date on which your subscription fee will be due.</p>',
    eus: '<p>You can check the current fees of your eyesCloud3D subscription along with the different subscription plans available and the duration of the Service here 11. Fees and Pricing. In the "Account" section you can see the details of the subscription method to which you are subscribed, the corresponding fee and the next date on which your subscription fee will be due.</p>',
    cat: '<p>You can check the current fees of your eyesCloud3D subscription along with the different subscription plans available and the duration of the Service here 11. Fees and Pricing. In the "Account" section you can see the details of the subscription method to which you are subscribed, the corresponding fee and the next date on which your subscription fee will be due.</p>',
  },

  hiring_Data46: {
    es: '<p>El precio y las ofertas de suscripción podrán variar en función del país y el momento. Cada tarifa de suscripción ofrecerá distintas herramientas o funcionalidades y podrá establecer determinadas condiciones o limitaciones, que se le comunicarán durante su proceso de registro o a través de cualquier otro material disponible en la Plataforma.</p>',
    en: '<p>Pricing and subscription offers may vary by country and time. Each subscription fee will offer different tools or features and may establish certain conditions or limitations; these will be communicated to you during your registration process or by means of any other material available on the Platform.</p>',
    pt: '<p>Pricing and subscription offers may vary by country and time. Each subscription fee will offer different tools or features and may establish certain conditions or limitations; these will be communicated to you during your registration process or by means of any other material available on the Platform.</p>',
    fr: '<p>Pricing and subscription offers may vary by country and time. Each subscription fee will offer different tools or features and may establish certain conditions or limitations; these will be communicated to you during your registration process or by means of any other material available on the Platform.</p>',
    eus: '<p>Pricing and subscription offers may vary by country and time. Each subscription fee will offer different tools or features and may establish certain conditions or limitations; these will be communicated to you during your registration process or by means of any other material available on the Platform.</p>',
    cat: '<p>Pricing and subscription offers may vary by country and time. Each subscription fee will offer different tools or features and may establish certain conditions or limitations; these will be communicated to you during your registration process or by means of any other material available on the Platform.</p>',
  },

  hiring_Data47: {
    es: '<p>Descuento y Promociones</p>',
    en: '<p>Discounts and Promotions</p>',
    pt: '<p>Discounts and Promotions</p>',
    fr: '<p>Discounts and Promotions</p>',
    eus: '<p>Discounts and Promotions</p>',
    cat: '<p>Discounts and Promotions</p>',
  },

  hiring_Data48: {
    es: '<p>En caso de que ofreciéramos descuentos u ofertas promocionales tras haber adquirido una suscripción, no tendrá derecho a que se le haga ningún reembolso con efectos retroactivos, ajuste en el precio ni a obtener ningún otro tipo de protección del precio de su cuota.</p>',
    en: '<p>In the event that we offer discounts or promotional offers after you have purchased a subscription, you will not be entitled to a retroactive refund, price adjustment or to protect the price of your membership fee through other means.</p>',
    pt: '<p>In the event that we offer discounts or promotional offers after you have purchased a subscription, you will not be entitled to a retroactive refund, price adjustment or to protect the price of your membership fee through other means.</p>',
    fr: '<p>In the event that we offer discounts or promotional offers after you have purchased a subscription, you will not be entitled to a retroactive refund, price adjustment or to protect the price of your membership fee through other means.</p>',
    eus: '<p>In the event that we offer discounts or promotional offers after you have purchased a subscription, you will not be entitled to a retroactive refund, price adjustment or to protect the price of your membership fee through other means.</p>',
    cat: '<p>In the event that we offer discounts or promotional offers after you have purchased a subscription, you will not be entitled to a retroactive refund, price adjustment or to protect the price of your membership fee through other means.</p>',
  },

  hiring_Data49: {
    es: '<p>Los nuevos clientes solo podrán beneficiarse una única vez de las promociones para “nuevos clientes”.</p>',
    en: '<p>New customers will only be able to benefit from "new customer" promotions once.</p>',
    pt: '<p>New customers will only be able to benefit from "new customer" promotions once.</p>',
    fr: '<p>New customers will only be able to benefit from "new customer" promotions once.</p>',
    eus: '<p>New customers will only be able to benefit from "new customer" promotions once.</p>',
    cat: '<p>New customers will only be able to benefit from "new customer" promotions once.</p>',
  },

  hiring_Data50: {
    es: '<p>Los requisitos para participar en cualquier oferta promocional se establecerán en el momento de lanzar la oferta. Nos reservamos el derecho de anular la Oferta Promocional en el caso de que determinemos que no reúnes los requisitos necesarios para beneficiarte de la misma. </p>',
    en: '<p>Eligibility to participate in any promotional offer will be established at the time the offer is launched. We reserve the right to cancel the Promotional Offer in the event that we determine that you are not eligible to benefit from it. </p>',
    pt: '<p>Eligibility to participate in any promotional offer will be established at the time the offer is launched. We reserve the right to cancel the Promotional Offer in the event that we determine that you are not eligible to benefit from it. </p>',
    fr: '<p>Eligibility to participate in any promotional offer will be established at the time the offer is launched. We reserve the right to cancel the Promotional Offer in the event that we determine that you are not eligible to benefit from it. </p>',
    eus: '<p>Eligibility to participate in any promotional offer will be established at the time the offer is launched. We reserve the right to cancel the Promotional Offer in the event that we determine that you are not eligible to benefit from it. </p>',
    cat: '<p>Eligibility to participate in any promotional offer will be established at the time the offer is launched. We reserve the right to cancel the Promotional Offer in the event that we determine that you are not eligible to benefit from it. </p>',
  },

  hiring_Data51: {
    es: '<p>Dicha cuota no es reembolsable, salvo en los supuestos expresamente previstos en las Condiciones.</p>',
    en: '<p>This fee is non-refundable, except in the cases expressly provided for in the Terms.</p>',
    pt: '<p>This fee is non-refundable, except in the cases expressly provided for in the Terms.</p>',
    fr: '<p>This fee is non-refundable, except in the cases expressly provided for in the Terms.</p>',
    eus: '<p>This fee is non-refundable, except in the cases expressly provided for in the Terms.</p>',
    cat: '<p>This fee is non-refundable, except in the cases expressly provided for in the Terms.</p>',
  },

  hiring_Data52: {
    es: '<p>Cambios en las Tarifas</p>',
    en: '<p>Changes to Fees</p>',
    pt: '<p>Changes to Fees</p>',
    fr: '<p>Changes to Fees</p>',
    eus: '<p>Changes to Fees</p>',
    cat: '<p>Changes to Fees</p>',
  },

  hiring_Data53: {
    es: '<p>Podremos modificar el precio de su Suscripción por los siguientes motivos:</p>',
    en: '<p>We may change the price of your Subscription for the following reasons:</p>',
    pt: '<p>We may change the price of your Subscription for the following reasons:</p>',
    fr: '<p>We may change the price of your Subscription for the following reasons:</p>',
    eus: '<p>We may change the price of your Subscription for the following reasons:</p>',
    cat: '<p>We may change the price of your Subscription for the following reasons:</p>',
  },

  hiring_Data54: {
    es: '<p>1. Para reflejar los costes de las mejoras que introduzcamos en la Plataforma, incluido el desarrollo de características o funcionalidades técnicas nuevas o mejoradas.</p>',
    en: '<p>1. To reflect the costs of improvements we make to the Platform, including the development of new or improved technical features or functionalities.</p>',
    pt: '<p>1. To reflect the costs of improvements we make to the Platform, including the development of new or improved technical features or functionalities.</p>',
    fr: '<p>1. To reflect the costs of improvements we make to the Platform, including the development of new or improved technical features or functionalities.</p>',
    eus: '<p>1. To reflect the costs of improvements we make to the Platform, including the development of new or improved technical features or functionalities.</p>',
    cat: '<p>1. To reflect the costs of improvements we make to the Platform, including the development of new or improved technical features or functionalities.</p>',
  },

  hiring_Data55: {
    es: '<p>2. Debido a cambios significativos en los costes de producción, concesión de licencias o adquisición de contenidos para la plataforma.</p>',
    en: '<p>2. Due to significant changes in production costs, licensing or content acquisition required for the platform.</p>',
    pt: '<p>2. Due to significant changes in production costs, licensing or content acquisition required for the platform.</p>',
    fr: '<p>2. Due to significant changes in production costs, licensing or content acquisition required for the platform.</p>',
    eus: '<p>2.	Due to significant changes in production costs, licensing or content acquisition required for the platform.</p>',
    cat: '<p>2.	Due to significant changes in production costs, licensing or content acquisition required for the platform.</p>',
  },

  hiring_Data56: {
    es: '<p>3. Debido a los costes derivados de acontecimientos de fuerza mayor, como un incendio, una inundación o una tormenta, que afecten a la operativa de la plataforma.</p>',
    en: '<p>3. Due to the costs derived from force majeure events or acts of God, such as a fires, floods or storms, which affect the operation of the platform.</p>',
    pt: '<p>3. Due to the costs derived from force majeure events or acts of God, such as a fires, floods or storms, which affect the operation of the platform.</p>',
    fr: '<p>3. Due to the costs derived from force majeure events or acts of God, such as a fires, floods or storms, which affect the operation of the platform.</p>',
    eus: '<p>3.	Due to the costs derived from force majeure events or acts of God, such as a fires, floods or storms, which affect the operation of the platform.</p>',
    cat: '<p>3.	Due to the costs derived from force majeure events or acts of God, such as a fires, floods or storms, which affect the operation of the platform.</p>',
  },

  hiring_Data57: {
    es: '<p>4. Debido a costes derivados de nuestro cumplimiento de requisitos legales o reglamentarios, tales como leyes que nos sean de aplicación, o cuando estemos obligados en virtud de una orden o sentencia judicial.</p>',
    en: '<p>4. Due to costs arising from our need to comply with legal or regulatory requirements, such as laws that apply to us, or when we are bound by a court order or judgment.</p>',
    pt: '<p>4. Due to costs arising from our need to comply with legal or regulatory requirements, such as laws that apply to us, or when we are bound by a court order or judgment.</p>',
    fr: '<p>4. Due to costs arising from our need to comply with legal or regulatory requirements, such as laws that apply to us, or when we are bound by a court order or judgment.</p>',
    eus: '<p>4.	Due to costs arising from our need to comply with legal or regulatory requirements, such as laws that apply to us, or when we are bound by a court order or judgment.</p>',
    cat: '<p>4.	Due to costs arising from our need to comply with legal or regulatory requirements, such as laws that apply to us, or when we are bound by a court order or judgment.</p>',
  },

  hiring_Data58: {
    es: '<p>5. Debido a cambios en las condiciones del mercado, o al aumento de los costes de negocio (por ejemplo, debido a la inflación), incluidos los costes de los servicios públicos y los proveedores, los costes de personal (incluidos los salarios), los costes administrativos y de atención al cliente y los tributos o cualesquiera otros gravámenes a los que estemos obligados.</p>',
    en: '<p>5. Due to changes in market conditions, or increased business costs (for example, due to inflation), including utility and supplier costs, personnel costs (including salaries), administrative and customer service costs, and taxes or any other levies to which we are liable.</p>',
    pt: '<p>5. Due to changes in market conditions, or increased business costs (for example, due to inflation), including utility and supplier costs, personnel costs (including salaries), administrative and customer service costs, and taxes or any other levies to which we are liable.</p>',
    fr: '<p>5. Due to changes in market conditions, or increased business costs (for example, due to inflation), including utility and supplier costs, personnel costs (including salaries), administrative and customer service costs, and taxes or any other levies to which we are liable.</p>',
    eus: '<p>5.	Due to changes in market conditions, or increased business costs (for example, due to inflation), including utility and supplier costs, personnel costs (including salaries), administrative and customer service costs, and taxes or any other levies to which we are liable.</p>',
    cat: '<p>5.	Due to changes in market conditions, or increased business costs (for example, due to inflation), including utility and supplier costs, personnel costs (including salaries), administrative and customer service costs, and taxes or any other levies to which we are liable.</p>',
  },

  hiring_Data59: {
    es: '<p>Condiciones de renovación automáticas</p>',
    en: '<p>Automatic Renewal Terms</p>',
    pt: '<p>Automatic Renewal Terms</p>',
    fr: '<p>Automatic Renewal Terms</p>',
    eus: '<p>Automatic Renewal Terms</p>',
    cat: '<p>Automatic Renewal Terms</p>',
  },

  hiring_Data60: {
    es: '<p>Si antes de la fecha en la que tu cuota de suscripción sea exigible no nos notificas tu intención de cancelar tu suscripción o tu cuenta de eyesCloud3D con al menos 7 días de antelación, te cargaremos automáticamente la cuota correspondiente a tu modalidad de suscripción que resulte aplicable en ese momento, utilizando para ello el método de pago que hayas seleccionado como predeterminado en la sección de Mi Cuenta.</p>',
    en: '<p>If before the date on which your subscription fee is due you do not notify us of your intention to cancel your subscription or your eyesCloud3D account at least 7 days in advance, we will automatically charge you the fee corresponding to your subscription plan that is still applicable at that time, using the default payment method you have selected in the My Account section.</p>',
    pt: '<p>If before the date on which your subscription fee is due you do not notify us of your intention to cancel your subscription or your eyesCloud3D account at least 7 days in advance, we will automatically charge you the fee corresponding to your subscription plan that is still applicable at that time, using the default payment method you have selected in the My Account section.</p>',
    fr: '<p>If before the date on which your subscription fee is due you do not notify us of your intention to cancel your subscription or your eyesCloud3D account at least 7 days in advance, we will automatically charge you the fee corresponding to your subscription plan that is still applicable at that time, using the default payment method you have selected in the My Account section.</p>',
    eus: '<p>If before the date on which your subscription fee is due you do not notify us of your intention to cancel your subscription or your eyesCloud3D account at least 7 days in advance, we will automatically charge you the fee corresponding to your subscription plan that is still applicable at that time, using the default payment method you have selected in the My Account section.</p>',
    cat: '<p>If before the date on which your subscription fee is due you do not notify us of your intention to cancel your subscription or your eyesCloud3D account at least 7 days in advance, we will automatically charge you the fee corresponding to your subscription plan that is still applicable at that time, using the default payment method you have selected in the My Account section.</p>',
  },

  hiring_Data61: {
    es: '<p>Si dicho método de pago fuera cancelado durante la vigencia de tu suscripción, o si el cargo fuera rechazado por cualquier otra causa ajena a nuestro control, nos autorizas a utilizar cualquier otro método de pago que tengamos registrado en tu cuenta de eyesCloud3D. Si los métodos de pago que tengamos registrados en tu cuenta fuesen rechazados, dispondrás de tres días para facilitarnos otro método de pago válido. En caso contrario, tu suscripción quedará cancelada.</p>',
    en: '<p>If such payment method is canceled during the term of your subscription, or if the charge is declined for any other reason beyond our control, you authorize us to use any other payment method we have on file in your eyesCloud3D account. If the payment methods we have on file for your account are declined, you will have three days to provide us with another valid payment method. Otherwise, your subscription will be canceled.</p>',
    pt: '<p>If such payment method is canceled during the term of your subscription, or if the charge is declined for any other reason beyond our control, you authorize us to use any other payment method we have on file in your eyesCloud3D account. If the payment methods we have on file for your account are declined, you will have three days to provide us with another valid payment method. Otherwise, your subscription will be canceled.</p>',
    fr: '<p>If such payment method is canceled during the term of your subscription, or if the charge is declined for any other reason beyond our control, you authorize us to use any other payment method we have on file in your eyesCloud3D account. If the payment methods we have on file for your account are declined, you will have three days to provide us with another valid payment method. Otherwise, your subscription will be canceled.</p>',
    eus: '<p>If such payment method is canceled during the term of your subscription, or if the charge is declined for any other reason beyond our control, you authorize us to use any other payment method we have on file in your eyesCloud3D account. If the payment methods we have on file for your account are declined, you will have three days to provide us with another valid payment method. Otherwise, your subscription will be canceled.</p>',
    cat: '<p>If such payment method is canceled during the term of your subscription, or if the charge is declined for any other reason beyond our control, you authorize us to use any other payment method we have on file in your eyesCloud3D account. If the payment methods we have on file for your account are declined, you will have three days to provide us with another valid payment method. Otherwise, your subscription will be canceled.</p>',
  },

  hiring_Data62: {
    es: '<p>Aceptación de la suscripción</p>',
    en: '<p>Subscription Acceptance</p>',
    pt: '<p>Subscription Acceptance</p>',
    fr: '<p>Subscription Acceptance</p>',
    eus: '<p>Subscription Acceptance</p>',
    cat: '<p>Subscription Acceptance</p>',
  },

  hiring_Data63: {
    es: '<p>Nos reservamos el derecho a aceptar o rechazar las solicitudes de suscripción a eyesCloud3D, en la medida en que así lo permita la legislación aplicable. Prohibición de cesión o transmisión de la suscripción o de cualesquiera ventajas asociadas a la misma No puedes transferir ni ceder a terceros tu suscripción a eyesCloud3D, ni ninguna de sus ventajas asociadas, salvo previsión expresa al respecto en las Condiciones.</p>',
    en: '<p>We reserve the right to accept or reject subscription requests to eyesCloud3D, to the extent permitted by applicable law. Prohibition of Assignment or Transmission of the Subscription or any Advantages Associated with it. You may not transfer or assign your eyesCloud3D subscription to third parties, or any of its associated benefits, except as expressly provided for in these Terms.</p>',
    pt: '<p>We reserve the right to accept or reject subscription requests to eyesCloud3D, to the extent permitted by applicable law. Prohibition of Assignment or Transmission of the Subscription or any Advantages Associated with it. You may not transfer or assign your eyesCloud3D subscription to third parties, or any of its associated benefits, except as expressly provided for in these Terms.</p>',
    fr: '<p>We reserve the right to accept or reject subscription requests to eyesCloud3D, to the extent permitted by applicable law. Prohibition of Assignment or Transmission of the Subscription or any Advantages Associated with it. You may not transfer or assign your eyesCloud3D subscription to third parties, or any of its associated benefits, except as expressly provided for in these Terms.</p>',
    eus: '<p>We reserve the right to accept or reject subscription requests to eyesCloud3D, to the extent permitted by applicable law. Prohibition of Assignment or Transmission of the Subscription or any Advantages Associated with it. You may not transfer or assign your eyesCloud3D subscription to third parties, or any of its associated benefits, except as expressly provided for in these Terms.</p>',
    cat: '<p>We reserve the right to accept or reject subscription requests to eyesCloud3D, to the extent permitted by applicable law. Prohibition of Assignment or Transmission of the Subscription or any Advantages Associated with it. You may not transfer or assign your eyesCloud3D subscription to third parties, or any of its associated benefits, except as expressly provided for in these Terms.</p>',
  },

  hiring_Data64: {
    es: '<p>5. Procesos de Pago</p>',
    en: '<p>5. Payments</p>',
    pt: '<p>5. Payments</p>',
    fr: '<p>5. Payments</p>',
    eus: '<p>5.	Payments</p>',
    cat: '<p>5.	Payments</p>',
  },

  hiring_Data65: {
    es: '<p>Métodos de pago</p>',
    en: '<p>Payment Method</p>',
    pt: '<p>Payment Method</p>',
    fr: '<p>Payment Method</p>',
    eus: '<p>Payment Method</p>',
    cat: '<p>Payment Method</p>',
  },

  hiring_Data66: {
    es: '<p>Puedes adquirir una suscripción de pago como consumidor directamente a través de eyesCloud3D. Nosotros o nuestros proveedores autorizados tramitaremos el pago.</p>',
    en: '<p>You can purchase a paid consumer subscription directly through eyesCloud3D. We or our authorized suppliers will process the payment.</p>',
    pt: '<p>You can purchase a paid consumer subscription directly through eyesCloud3D. We or our authorized suppliers will process the payment.</p>',
    fr: '<p>You can purchase a paid consumer subscription directly through eyesCloud3D. We or our authorized suppliers will process the payment.</p>',
    eus: '<p>You can purchase a paid consumer subscription directly through eyesCloud3D. We or our authorized suppliers will process the payment.</p>',
    cat: '<p>You can purchase a paid consumer subscription directly through eyesCloud3D. We or our authorized suppliers will process the payment.</p>',
  },

  hiring_Data67: {
    es: '<p>Mediante la introducción de un método de pago y la formalización de una compra aceptas abonar a eyesCloud3D la cuota correspondiente a tu tarifa. Cobraremos la primera cuota en la fecha en la que certifiquemos que tu compra se ha formalizado. Posteriormente, cargaremos periódicamente cualquier cuota aplicable según tu ciclo de facturación, anual o mensual, a través del método de pago que nos hayas proporcionado. El ciclo de facturación se corresponderá con el día del pago. Siempre intentaremos verificar el Medio de Pago que nos facilites. A tal efecto podemos procesar un cargo de prueba, lo cual constituye una práctica habitual. Si la entidad bancaria no autorizase el pago, eyesCloud3D no será responsable por ningún retraso o falta de entrega y no podrá formalizar ningún contrato con el Usuario.</p>',
    en: '<p>By entering a payment method and making a purchase, you agree to pay eyesCloud3D the fee corresponding to your plan. The first installment will be charged on the date on which we certify that your purchase has been successfully approved. Thereafter, we will periodically charge any applicable fees based on your billing cycle – whether annual or monthly – via the payment method you have provided us with. The billing cycle will initiate on the payment day. We will always try to verify any Payment Method provided by you. In order to do so, we may process a trial charge, which is standard practice. If the bank does not authorize the payment, eyesCloud3D will not be responsible for any delay or non-delivery and may not enter into any contract with the User.</p>',
    pt: '<p>By entering a payment method and making a purchase, you agree to pay eyesCloud3D the fee corresponding to your plan. The first installment will be charged on the date on which we certify that your purchase has been successfully approved. Thereafter, we will periodically charge any applicable fees based on your billing cycle – whether annual or monthly – via the payment method you have provided us with. The billing cycle will initiate on the payment day. We will always try to verify any Payment Method provided by you. In order to do so, we may process a trial charge, which is standard practice. If the bank does not authorize the payment, eyesCloud3D will not be responsible for any delay or non-delivery and may not enter into any contract with the User.</p>',
    fr: '<p>By entering a payment method and making a purchase, you agree to pay eyesCloud3D the fee corresponding to your plan. The first installment will be charged on the date on which we certify that your purchase has been successfully approved. Thereafter, we will periodically charge any applicable fees based on your billing cycle – whether annual or monthly – via the payment method you have provided us with. The billing cycle will initiate on the payment day. We will always try to verify any Payment Method provided by you. In order to do so, we may process a trial charge, which is standard practice. If the bank does not authorize the payment, eyesCloud3D will not be responsible for any delay or non-delivery and may not enter into any contract with the User.</p>',
    eus: '<p>By entering a payment method and making a purchase, you agree to pay eyesCloud3D the fee corresponding to your plan. The first installment will be charged on the date on which we certify that your purchase has been successfully approved. Thereafter, we will periodically charge any applicable fees based on your billing cycle – whether annual or monthly – via the payment method you have provided us with. The billing cycle will initiate on the payment day. We will always try to verify any Payment Method provided by you. In order to do so, we may process a trial charge, which is standard practice. If the bank does not authorize the payment, eyesCloud3D will not be responsible for any delay or non-delivery and may not enter into any contract with the User.</p>',
    cat: '<p>By entering a payment method and making a purchase, you agree to pay eyesCloud3D the fee corresponding to your plan. The first installment will be charged on the date on which we certify that your purchase has been successfully approved. Thereafter, we will periodically charge any applicable fees based on your billing cycle – whether annual or monthly – via the payment method you have provided us with. The billing cycle will initiate on the payment day. We will always try to verify any Payment Method provided by you. In order to do so, we may process a trial charge, which is standard practice. If the bank does not authorize the payment, eyesCloud3D will not be responsible for any delay or non-delivery and may not enter into any contract with the User.</p>',
  },

  hiring_Data68: {
    es: '<p>Si tuvieras cualquier pregunta acerca del pago, puedes ponerte en contacto con el servicio de Atención al Cliente de eyesCloud3d.</p>',
    en: '<p>If you have any questions about the payment, you can contact eyesCloud3d Customer Service.</p>',
    pt: '<p>If you have any questions about the payment, you can contact eyesCloud3d Customer Service.</p>',
    fr: '<p>If you have any questions about the payment, you can contact eyesCloud3d Customer Service.</p>',
    eus: '<p>If you have any questions about the payment, you can contact eyesCloud3d Customer Service.</p>',
    cat: '<p>If you have any questions about the payment, you can contact eyesCloud3d Customer Service.</p>',
  },

  hiring_Data69: {
    es: '<p>Actualización de tu medio de pago</p>',
    en: '<p>Updating your Payment Method</p>',
    pt: '<p>Updating your Payment Method</p>',
    fr: '<p>Updating your Payment Method</p>',
    eus: '<p>Updating your Payment Method</p>',
    cat: '<p>Updating your Payment Method</p>',
  },

  hiring_Data70: {
    es: '<p>Puedes actualizar tu medio de pago accediendo a la pestaña “Cuenta” en la Plataforma.</p>',
    en: '<p>You can update your payment method by accessing the "Account" tab on the Platform.</p>',
    pt: '<p>You can update your payment method by accessing the "Account" tab on the Platform.</p>',
    fr: '<p>You can update your payment method by accessing the "Account" tab on the Platform.</p>',
    eus: '<p>You can update your payment method by accessing the "Account" tab on the Platform.</p>',
    cat: '<p>You can update your payment method by accessing the "Account" tab on the Platform.</p>',
  },

  hiring_Data71: {
    es: '<p>Si tu medio de pago caduca y no lo actualizas podemos darte la oportunidad de proporcionar otro medio de pago, o cancelar la suscripción.</p>',
    en: '<p>If your payment method expires and you do not update it, we may give you the opportunity to provide another payment method, or cancel your subscription.</p>',
    pt: '<p>If your payment method expires and you do not update it, we may give you the opportunity to provide another payment method, or cancel your subscription.</p>',
    fr: '<p>If your payment method expires and you do not update it, we may give you the opportunity to provide another payment method, or cancel your subscription.</p>',
    eus: '<p>If your payment method expires and you do not update it, we may give you the opportunity to provide another payment method, or cancel your subscription.</p>',
    cat: '<p>If your payment method expires and you do not update it, we may give you the opportunity to provide another payment method, or cancel your subscription.</p>',
  },

  hiring_Data72: {
    es: '<p>Al modificar tu medio de pago, podría ser necesario que modificáramos también el ciclo de facturación aplicable hasta ese momento. Eres responsable de actualizar y mantener operativo el medio de pago.</p>',
    en: '<p>When you change your payment method, we may also have to change the billing cycle applicable up to that point. You are responsible for updating and keeping the payment method operational.</p>',
    pt: '<p>When you change your payment method, we may also have to change the billing cycle applicable up to that point. You are responsible for updating and keeping the payment method operational.</p>',
    fr: '<p>When you change your payment method, we may also have to change the billing cycle applicable up to that point. You are responsible for updating and keeping the payment method operational.</p>',
    eus: '<p>When you change your payment method, we may also have to change the billing cycle applicable up to that point. You are responsible for updating and keeping the payment method operational.</p>',
    cat: '<p>When you change your payment method, we may also have to change the billing cycle applicable up to that point. You are responsible for updating and keeping the payment method operational.</p>',
  },

  hiring_Data73: {
    es: '<p>Importes pendientes de pago</p>',
    en: '<p>Unpaid Amounts</p>',
    pt: '<p>Unpaid Amounts</p>',
    fr: '<p>Unpaid Amounts</p>',
    eus: '<p>Unpaid Amounts</p>',
    cat: '<p>Unpaid Amounts</p>',
  },

  hiring_Data74: {
    es: '<p>Eres responsable de todos los cargos contraídos en relación con tu suscripción. Si el medio de pago registrado por eyesCloud3D no permitiera pagar la cuota, eyesCloud3D podrá suspender tu acceso a la Plataforma o cancelar tu Cuota de suscripción.</p>',
    en: '<p>You are responsible for all charges incurred in connection with your subscription. If the payment method registered by eyesCloud3D does not allow you to pay the fee, eyesCloud3D may suspend your access to the Platform or cancel your Subscription Fee.</p>',
    pt: '<p>You are responsible for all charges incurred in connection with your subscription. If the payment method registered by eyesCloud3D does not allow you to pay the fee, eyesCloud3D may suspend your access to the Platform or cancel your Subscription Fee.</p>',
    fr: '<p>You are responsible for all charges incurred in connection with your subscription. If the payment method registered by eyesCloud3D does not allow you to pay the fee, eyesCloud3D may suspend your access to the Platform or cancel your Subscription Fee.</p>',
    eus: '<p>You are responsible for all charges incurred in connection with your subscription. If the payment method registered by eyesCloud3D does not allow you to pay the fee, eyesCloud3D may suspend your access to the Platform or cancel your Subscription Fee.</p>',
    cat: '<p>You are responsible for all charges incurred in connection with your subscription. If the payment method registered by eyesCloud3D does not allow you to pay the fee, eyesCloud3D may suspend your access to the Platform or cancel your Subscription Fee.</p>',
  },

  hiring_Data75: {
    es: '<p>Intentaremos o permitiremos que el servicio de pago vuelva a intentar realizar el cobro a través de su medio de pago después de cualquier intento de cobro fallido (por ejemplo, si se rechazara su medio de pago) antes de proceder a la cancelación en caso de impago.</p>',
    en: '<p>We will try or allow the payment service to retry the payment through your payment method after any failed payment attempt (for example, if your payment method is rejected) before proceeding with the cancellation in the event of non-payment.</p>',
    pt: '<p>We will try or allow the payment service to retry the payment through your payment method after any failed payment attempt (for example, if your payment method is rejected) before proceeding with the cancellation in the event of non-payment.</p>',
    fr: '<p>We will try or allow the payment service to retry the payment through your payment method after any failed payment attempt (for example, if your payment method is rejected) before proceeding with the cancellation in the event of non-payment.</p>',
    eus: '<p>We will try or allow the payment service to retry the payment through your payment method after any failed payment attempt (for example, if your payment method is rejected) before proceeding with the cancellation in the event of non-payment.</p>',
    cat: '<p>We will try or allow the payment service to retry the payment through your payment method after any failed payment attempt (for example, if your payment method is rejected) before proceeding with the cancellation in the event of non-payment.</p>',
  },

  hiring_Data76: {
    es: '<p>6. Política de Cancelación</p>',
    en: '<p>6. Cancellation Policy</p>',
    pt: '<p>6. Cancellation Policy</p>',
    fr: '<p>6. Cancellation Policy</p>',
    eus: '<p>6.	Cancellation Policy</p>',
    cat: '<p>6.	Cancellation Policy</p>',
  },

  hiring_Data77: {
    es: '<p>Puedes cancelar la suscripción visitando y ajustando la configuración de tu suscripción desde “Cuenta” en cualquier momento. También puedes ponerte en contacto con nuestro Servicio de Atención al Cliente. Deberás hacerlo con al menos 7 días de antelación a la fecha de renovación para evitar el cobro de la cuota correspondiente al periodo de facturación. Si cancelara tu Suscripción, la cancelación se hará efectiva bien:</p>',
    en: '<p>You can unsubscribe by visiting and adjusting your subscription settings from your “Account” at any time. You can also contact our Customer Service. You must do so at least 7 days before the renewal date to avoid being charged the fee corresponding to the billing period. If you cancel your Subscription, the cancellation will be effective as follows:</p>',
    pt: '<p>You can unsubscribe by visiting and adjusting your subscription settings from your “Account” at any time. You can also contact our Customer Service. You must do so at least 7 days before the renewal date to avoid being charged the fee corresponding to the billing period. If you cancel your Subscription, the cancellation will be effective as follows:</p>',
    fr: '<p>You can unsubscribe by visiting and adjusting your subscription settings from your “Account” at any time. You can also contact our Customer Service. You must do so at least 7 days before the renewal date to avoid being charged the fee corresponding to the billing period. If you cancel your Subscription, the cancellation will be effective as follows:</p>',
    eus: '<p>You can unsubscribe by visiting and adjusting your subscription settings from your “Account” at any time. You can also contact our Customer Service. You must do so at least 7 days before the renewal date to avoid being charged the fee corresponding to the billing period. If you cancel your Subscription, the cancellation will be effective as follows:</p>',
    cat: '<p>You can unsubscribe by visiting and adjusting your subscription settings from your “Account” at any time. You can also contact our Customer Service. You must do so at least 7 days before the renewal date to avoid being charged the fee corresponding to the billing period. If you cancel your Subscription, the cancellation will be effective as follows:</p>',
  },

  hiring_Data78: {
    es: '<p>Al finalizar el periodo de facturación en curso.</p>',
    en: '<p>At the end of the current billing period.</p>',
    pt: '<p>At the end of the current billing period.</p>',
    fr: '<p>At the end of the current billing period.</p>',
    eus: '<p>At the end of the current billing period.</p>',
    cat: '<p>At the end of the current billing period.</p>',
  },

  hiring_Data79: {
    es: '<p>Al finalizar el período mínimo aplicable a tu suscripción, si tal período expira antes que el ciclo de facturación (o inmediatamente si dicho período mínimo ha expirado), por lo que seguirá teniendo acceso a la Plataforma hasta la finalización del mismo.</p>',
    en: '<p>At the end of the minimum period applicable to your subscription, if said period expires before the billing cycle (or immediately, if said minimum period has expired); you will continue to have access to the Platform until the end of this period.</p>',
    pt: '<p>At the end of the minimum period applicable to your subscription, if said period expires before the billing cycle (or immediately, if said minimum period has expired); you will continue to have access to the Platform until the end of this period.</p>',
    fr: '<p>At the end of the minimum period applicable to your subscription, if said period expires before the billing cycle (or immediately, if said minimum period has expired); you will continue to have access to the Platform until the end of this period.</p>',
    eus: '<p>At the end of the minimum period applicable to your subscription, if said period expires before the billing cycle (or immediately, if said minimum period has expired); you will continue to have access to the Platform until the end of this period.</p>',
    cat: '<p>At the end of the minimum period applicable to your subscription, if said period expires before the billing cycle (or immediately, if said minimum period has expired); you will continue to have access to the Platform until the end of this period.</p>',
  },

  hiring_Data80: {
    es: '<p>Si cancelaras tu suscripción, conservaremos su información de conformidad con lo establecido en nuestra Política de privacidad.</p>',
    en: '<p>If you unsubscribe, we will retain your information and data in accordance with our Privacy Policy.</p>',
    pt: '<p>If you unsubscribe, we will retain your information and data in accordance with our Privacy Policy.</p>',
    fr: '<p>If you unsubscribe, we will retain your information and data in accordance with our Privacy Policy.</p>',
    eus: '<p>If you unsubscribe, we will retain your information and data in accordance with our Privacy Policy.</p>',
    cat: '<p>If you unsubscribe, we will retain your information and data in accordance with our Privacy Policy.</p>',
  },

  hiring_Data81: {
    es: '<p>Política de Suspensión</p>',
    en: '<p>Suspension Policy</p>',
    pt: '<p>Suspension Policy</p>',
    fr: '<p>Suspension Policy</p>',
    eus: '<p>Suspension Policy</p>',
    cat: '<p>Suspension Policy</p>',
  },

  hiring_Data82: {
    es: '<p>Podemos inhabilitar, suspender o finalizar su Suscripción, cualquier compra o el acceso a la Plataforma (incluyendo cualquier Contenido) y/o a tu cuenta de eyesCloud3D:</p>',
    en: '<p>We may disable, suspend or terminate your Subscription, any purchase you have made or your access to the Platform (including any Content) and/or your eyesCloud3D account:</p>',
    pt: '<p>We may disable, suspend or terminate your Subscription, any purchase you have made or your access to the Platform (including any Content) and/or your eyesCloud3D account:</p>',
    fr: '<p>We may disable, suspend or terminate your Subscription, any purchase you have made or your access to the Platform (including any Content) and/or your eyesCloud3D account:</p>',
    eus: '<p>We may disable, suspend or terminate your Subscription, any purchase you have made or your access to the Platform (including any Content) and/or your eyesCloud3D account:</p>',
    cat: '<p>We may disable, suspend or terminate your Subscription, any purchase you have made or your access to the Platform (including any Content) and/or your eyesCloud3D account:</p>',
  },

  hiring_Data83: {
    es: '<p>1. Con un preaviso razonable, por cualquier motivo, al final del ciclo de facturación en curso.</p>',
    en: '<p>1. With reasonable notice, for any reason, at the end of the current billing cycle.</p>',
    pt: '<p>1. With reasonable notice, for any reason, at the end of the current billing cycle.</p>',
    fr: '<p>1. With reasonable notice, for any reason, at the end of the current billing cycle.</p>',
    eus: '<p>1.	With reasonable notice, for any reason, at the end of the current billing cycle.</p>',
    cat: '<p>1.	With reasonable notice, for any reason, at the end of the current billing cycle.</p>',
  },

  hiring_Data84: {
    es: '<p>2. Inmediatamente cuando exista una razón válida para hacerlo, incluyendo aquellos casos en los que tenga lugar cualquier actividad fraudulenta relacionada con cualquier compra realizada o información facilitada por usted, que ya no cumpla los Criterios de elegibilidad o que, de alguna otra manera, incumpla lo previsto en las presentes Condiciones.</p>',
    en: '<p>2. Immediately where there is a valid reason to do so, including those cases in which any fraudulent activity takes place related to any purchase made or information provided by you, which no longer meets the Eligibility Criteria or which, in any other way, violates the provisions of these Terms.</p>',
    pt: '<p>2. Immediately where there is a valid reason to do so, including those cases in which any fraudulent activity takes place related to any purchase made or information provided by you, which no longer meets the Eligibility Criteria or which, in any other way, violates the provisions of these Terms.</p>',
    fr: '<p>2. Immediately where there is a valid reason to do so, including those cases in which any fraudulent activity takes place related to any purchase made or information provided by you, which no longer meets the Eligibility Criteria or which, in any other way, violates the provisions of these Terms.</p>',
    eus: '<p>2.	Immediately where there is a valid reason to do so, including those cases in which any fraudulent activity takes place related to any purchase made or information provided by you, which no longer meets the Eligibility Criteria or which, in any other way, violates the provisions of these Terms.</p>',
    cat: '<p>2.	Immediately where there is a valid reason to do so, including those cases in which any fraudulent activity takes place related to any purchase made or information provided by you, which no longer meets the Eligibility Criteria or which, in any other way, violates the provisions of these Terms.</p>',
  },

  hiring_Data85: {
    es: '<p>Si esto sucediera, deberá dejar de usar la Plataforma. Si inhabilitamos tu suscripción o acceso a toda o a parte de la Plataforma, te comprometes a no crear otra cuenta en eyesCloud3D y a no intentar acceder a la Plataforma sin nuestro permiso, y nos reservamos el derecho de rechazar y bloquear cualquier intento de acceso.</p>',
    en: '<p>If this happens, you must stop using the Platform. If we disable your subscription or access to all or part of the Platform, you will refrain from creating another eyesCloud3D account and shall not attempt to access the Platform without our permission, and we further reserve the right to refuse and block any attempts to access the Platform by you.</p>',
    pt: '<p>If this happens, you must stop using the Platform. If we disable your subscription or access to all or part of the Platform, you will refrain from creating another eyesCloud3D account and shall not attempt to access the Platform without our permission, and we further reserve the right to refuse and block any attempts to access the Platform by you.</p>',
    fr: '<p>If this happens, you must stop using the Platform. If we disable your subscription or access to all or part of the Platform, you will refrain from creating another eyesCloud3D account and shall not attempt to access the Platform without our permission, and we further reserve the right to refuse and block any attempts to access the Platform by you.</p>',
    eus: '<p>If this happens, you must stop using the Platform. If we disable your subscription or access to all or part of the Platform, you will refrain from creating another eyesCloud3D account and shall not attempt to access the Platform without our permission, and we further reserve the right to refuse and block any attempts to access the Platform by you.</p>',
    cat: '<p>If this happens, you must stop using the Platform. If we disable your subscription or access to all or part of the Platform, you will refrain from creating another eyesCloud3D account and shall not attempt to access the Platform without our permission, and we further reserve the right to refuse and block any attempts to access the Platform by you.</p>',
  },

  hiring_Data86: {
    es: '<p>7. Sobre las Condiciones</p>',
    en: '<p>7. About the Terms</p>',
    pt: '<p>7. About the Terms</p>',
    fr: '<p>7. About the Terms</p>',
    eus: '<p>7.	About the Terms</p>',
    cat: '<p>7.	About the Terms</p>',
  },

  hiring_Data87: {
    es: '<p>Modificación de las Condiciones</p>',
    en: '<p>Modification of the Terms</p>',
    pt: '<p>Modification of the Terms</p>',
    fr: '<p>Modification of the Terms</p>',
    eus: '<p>Modification of the Terms</p>',
    cat: '<p>Modification of the Terms</p>',
  },

  hiring_Data88: {
    es: '<p>Nos reservamos el derecho a realizar cambios en las Condiciones, en cualquier momento, por cualesquiera causas legales o contractuales; por motivos de seguridad; a efectos de mejorar las funcionalidades existentes o incorporar nuevas funcionalidades al Servicio; al objeto de reflejar cualesquiera mejoras tecnológicas; para realizar ajustes técnicos razonables; y al objeto de garantizar el funcionamiento continuado del Servicio.</p>',
    en: '<p>We reserve the right to make changes to the Terms, at any time, for any legal or contractual reasons; for security reasons; in order to improve existing features or to incorporate new features into the Service; in order to reflect any technological improvements; to make reasonable technical adjustments; and in order to guarantee the continued operation of the Service.</p>',
    pt: '<p>We reserve the right to make changes to the Terms, at any time, for any legal or contractual reasons; for security reasons; in order to improve existing features or to incorporate new features into the Service; in order to reflect any technological improvements; to make reasonable technical adjustments; and in order to guarantee the continued operation of the Service.</p>',
    fr: '<p>We reserve the right to make changes to the Terms, at any time, for any legal or contractual reasons; for security reasons; in order to improve existing features or to incorporate new features into the Service; in order to reflect any technological improvements; to make reasonable technical adjustments; and in order to guarantee the continued operation of the Service.</p>',
    eus: '<p>We reserve the right to make changes to the Terms, at any time, for any legal or contractual reasons; for security reasons; in order to improve existing features or to incorporate new features into the Service; in order to reflect any technological improvements; to make reasonable technical adjustments; and in order to guarantee the continued operation of the Service.</p>',
    cat: '<p>We reserve the right to make changes to the Terms, at any time, for any legal or contractual reasons; for security reasons; in order to improve existing features or to incorporate new features into the Service; in order to reflect any technological improvements; to make reasonable technical adjustments; and in order to guarantee the continued operation of the Service.</p>',
  },

  hiring_Data89: {
    es: '<p>Si realizásemos cualquier modificación, te notificaremos dicha circunstancia en la forma y plazos oportunos y te recordaremos tus derechos al respecto. Podrás optar por no aceptar dichas modificaciones, conservando tu derecho a cancelar tu suscripción en cualquier momento.</p>',
    en: '<p>If we make any changes, we will notify you in a timely manner and remind you of your rights in this regard. You may choose not to accept such modifications, retaining your right to cancel your subscription at any time.</p>',
    pt: '<p>If we make any changes, we will notify you in a timely manner and remind you of your rights in this regard. You may choose not to accept such modifications, retaining your right to cancel your subscription at any time.</p>',
    fr: '<p>If we make any changes, we will notify you in a timely manner and remind you of your rights in this regard. You may choose not to accept such modifications, retaining your right to cancel your subscription at any time.</p>',
    eus: '<p>If we make any changes, we will notify you in a timely manner and remind you of your rights in this regard. You may choose not to accept such modifications, retaining your right to cancel your subscription at any time.</p>',
    cat: '<p>If we make any changes, we will notify you in a timely manner and remind you of your rights in this regard. You may choose not to accept such modifications, retaining your right to cancel your subscription at any time.</p>',
  },

  hiring_Data90: {
    es: '<p>Con independencia de cualquier modificación de las Condiciones, no te será de aplicación ningún aumento de la cuota de suscripción hasta que finalice el período de suscripción en curso.</p>',
    en: '<p>Notwithstanding any amendment to the Terms, no increase in the subscription fee will apply to you until the end of the current subscription period.</p>',
    pt: '<p>Notwithstanding any amendment to the Terms, no increase in the subscription fee will apply to you until the end of the current subscription period.</p>',
    fr: '<p>Notwithstanding any amendment to the Terms, no increase in the subscription fee will apply to you until the end of the current subscription period.</p>',
    eus: '<p>Notwithstanding any amendment to the Terms, no increase in the subscription fee will apply to you until the end of the current subscription period.</p>',
    cat: '<p>Notwithstanding any amendment to the Terms, no increase in the subscription fee will apply to you until the end of the current subscription period.</p>',
  },

  hiring_Data91: {
    es: '<p>Si algún cambio fuera declarado inválido o inaplicable por cualquier motivo, dicho cambio se entenderá excluido sin que ello afecte a la validez ni a la aplicabilidad del resto de cambios o condiciones.</p>',
    en: '<p>If any change is declared invalid or inapplicable for any reason, said change will be understood to be excluded without affecting the validity or applicability of the other changes or conditions.</p>',
    pt: '<p>If any change is declared invalid or inapplicable for any reason, said change will be understood to be excluded without affecting the validity or applicability of the other changes or conditions.</p>',
    fr: '<p>If any change is declared invalid or inapplicable for any reason, said change will be understood to be excluded without affecting the validity or applicability of the other changes or conditions.</p>',
    eus: '<p>If any change is declared invalid or inapplicable for any reason, said change will be understood to be excluded without affecting the validity or applicability of the other changes or conditions.</p>',
    cat: '<p>If any change is declared invalid or inapplicable for any reason, said change will be understood to be excluded without affecting the validity or applicability of the other changes or conditions.</p>',
  },

  hiring_Data92: {
    es: '<p>Nuestra responsabilidad</p>',
    en: '<p>Our Liability</p>',
    pt: '<p>Our Liability</p>',
    fr: '<p>Our Liability</p>',
    eus: '<p>Our Liability</p>',
    cat: '<p>Our Liability</p>',
  },

  hiring_Data93: {
    es: '<p>Seremos responsables por cualquier daño que sufras como consecuencia de cualquier negligencia grave o dolo por nuestra parte, incluyendo por parte de nuestros administradores y representantes legales. Asimismo, seremos responsables por el incumplimiento de nuestras obligaciones previstas en estas Condiciones que fueran de carácter esencial a efectos de la prestación del Servicio y en las que, como usuario, hubieras basado tu decisión de incorporarte al Servicio. En este caso únicamente seremos responsables por aquellos daños y perjuicios que hubieran sido previsibles para ambas partes cuando te suscribiste a nuestra Plataforma. Nada de lo dispuesto en el presente apartado afecta a los derechos como consumidor que pudiera reconocerte la legislación aplicable, ni limita o excluye nuestra responsabilidad en caso de fallecimiento, lesión personal, daño a la salud, incumplimiento de garantías u ocultación dolosa de cualquier defecto del producto. Circunstancias ajenas a nuestro control razonable No seremos responsables de ninguna demora o incumplimiento de nuestras obligaciones derivadas de las Condiciones si dicha demora o incumplimiento fuera atribuible a circunstancias imprevisibles y ajenas a nuestro control razonable.</p>',
    en: '<p>We will be liable for any damage you suffer as a result of any gross negligence or willful misconduct on our part, including by our administrators and legal representatives. Likewise, we will be liable for any breach of a material obligation undertaken by us in these Terms which may be considered essential for the provision of the Service and on which, as a User, you would have reasonably based your decision to use and purchase the Service. In this case, we will only be liable for those damages that would have been foreseeable for both parties at the time you subscribed to our Platform. Nothing in this section affects any rights you may be granted under applicable law as a consumer, nor does it limit or exclude our liability in the event of death, personal injury, damage to health, breach of warranty or intentional concealment of any defect in the product. Circumstances Beyond our Reasonable Control. We will not be liable for any delay or breach of our obligations under the Terms if such delay or breach is attributable to unforeseeable circumstances beyond our reasonable control.</p>',
    pt: '<p>We will be liable for any damage you suffer as a result of any gross negligence or willful misconduct on our part, including by our administrators and legal representatives. Likewise, we will be liable for any breach of a material obligation undertaken by us in these Terms which may be considered essential for the provision of the Service and on which, as a User, you would have reasonably based your decision to use and purchase the Service. In this case, we will only be liable for those damages that would have been foreseeable for both parties at the time you subscribed to our Platform. Nothing in this section affects any rights you may be granted under applicable law as a consumer, nor does it limit or exclude our liability in the event of death, personal injury, damage to health, breach of warranty or intentional concealment of any defect in the product. Circumstances Beyond our Reasonable Control. We will not be liable for any delay or breach of our obligations under the Terms if such delay or breach is attributable to unforeseeable circumstances beyond our reasonable control.</p>',
    fr: '<p>We will be liable for any damage you suffer as a result of any gross negligence or willful misconduct on our part, including by our administrators and legal representatives. Likewise, we will be liable for any breach of a material obligation undertaken by us in these Terms which may be considered essential for the provision of the Service and on which, as a User, you would have reasonably based your decision to use and purchase the Service. In this case, we will only be liable for those damages that would have been foreseeable for both parties at the time you subscribed to our Platform. Nothing in this section affects any rights you may be granted under applicable law as a consumer, nor does it limit or exclude our liability in the event of death, personal injury, damage to health, breach of warranty or intentional concealment of any defect in the product. Circumstances Beyond our Reasonable Control. We will not be liable for any delay or breach of our obligations under the Terms if such delay or breach is attributable to unforeseeable circumstances beyond our reasonable control.</p>',
    eus: '<p>We will be liable for any damage you suffer as a result of any gross negligence or willful misconduct on our part, including by our administrators and legal representatives. Likewise, we will be liable for any breach of a material obligation undertaken by us in these Terms which may be considered essential for the provision of the Service and on which, as a User, you would have reasonably based your decision to use and purchase the Service. In this case, we will only be liable for those damages that would have been foreseeable for both parties at the time you subscribed to our Platform. Nothing in this section affects any rights you may be granted under applicable law as a consumer, nor does it limit or exclude our liability in the event of death, personal injury, damage to health, breach of warranty or intentional concealment of any defect in the product. Circumstances Beyond our Reasonable Control. We will not be liable for any delay or breach of our obligations under the Terms if such delay or breach is attributable to unforeseeable circumstances beyond our reasonable control.</p>',
    cat: '<p>We will be liable for any damage you suffer as a result of any gross negligence or willful misconduct on our part, including by our administrators and legal representatives. Likewise, we will be liable for any breach of a material obligation undertaken by us in these Terms which may be considered essential for the provision of the Service and on which, as a User, you would have reasonably based your decision to use and purchase the Service. In this case, we will only be liable for those damages that would have been foreseeable for both parties at the time you subscribed to our Platform. Nothing in this section affects any rights you may be granted under applicable law as a consumer, nor does it limit or exclude our liability in the event of death, personal injury, damage to health, breach of warranty or intentional concealment of any defect in the product. Circumstances Beyond our Reasonable Control. We will not be liable for any delay or breach of our obligations under the Terms if such delay or breach is attributable to unforeseeable circumstances beyond our reasonable control.</p>',
  },

  hiring_Data94: {
    es: '<p>8. Ley Aplicable y Jurisdicción</p>',
    en: '<p>8. Governing Law and Jurisdiction</p>',
    pt: '<p>8. Governing Law and Jurisdiction</p>',
    fr: '<p>8. Governing Law and Jurisdiction</p>',
    eus: '<p>8.	Governing Law and Jurisdiction</p>',
    cat: '<p>8.	Governing Law and Jurisdiction</p>',
  },

  hiring_Data95: {
    es: '<p>Las presentes Condiciones de Contratación, así como el uso de la Plataforma, se regirán por la legislación española. Para la resolución de cualquier controversia las partes se someterán a los Juzgados y Tribunales del domicilio social del Responsable del sitio web.</p>',
    en: '<p>These Contractual Terms, as well as the use of the Platform, shall be governed by Spanish law. The Courts and Tribunals of the registered office of the Website Manager will have exclusive jurisdiction over any disputes which may arise between the parties.</p>',
    pt: '<p>These Contractual Terms, as well as the use of the Platform, shall be governed by Spanish law. The Courts and Tribunals of the registered office of the Website Manager will have exclusive jurisdiction over any disputes which may arise between the parties.</p>',
    fr: '<p>These Contractual Terms, as well as the use of the Platform, shall be governed by Spanish law. The Courts and Tribunals of the registered office of the Website Manager will have exclusive jurisdiction over any disputes which may arise between the parties.</p>',
    eus: '<p>These Contractual Terms, as well as the use of the Platform, shall be governed by Spanish law. The Courts and Tribunals of the registered office of the Website Manager will have exclusive jurisdiction over any disputes which may arise between the parties.</p>',
    cat: '<p>These Contractual Terms, as well as the use of the Platform, shall be governed by Spanish law. The Courts and Tribunals of the registered office of the Website Manager will have exclusive jurisdiction over any disputes which may arise between the parties.</p>',
  },

  hiring_Data96: {
    es: '<p>Si cualquier estipulación de las presentes Condiciones de Contratación resultara inexigible o nula en virtud de la legislación aplicable o como consecuencia de una resolución judicial o administrativa, dicha inexigibilidad o nulidad no hará que las presentes Condiciones de Contratación resulten inexigibles o nulos en su conjunto. En dichos casos, la empresa procederá a la modificación o sustitución de dicha estipulación por otra que sea válida y exigible y que, en la medida de lo posible, consiga el objetivo y pretensión reflejados en la estipulación original.</p>',
    en: '<p>If any provision of these Terms were to become unenforceable or void by virtue of applicable law or as a result of a judicial ruling or administrative decision, said unenforceability or nullity will not render these Terms unenforceable or void as a whole. In such cases, the company will modify or replace the voided provision with another that is valid and enforceable and that, to the extent possible, achieves the objective reflected in the original provision.</p>',
    pt: '<p>If any provision of these Terms were to become unenforceable or void by virtue of applicable law or as a result of a judicial ruling or administrative decision, said unenforceability or nullity will not render these Terms unenforceable or void as a whole. In such cases, the company will modify or replace the voided provision with another that is valid and enforceable and that, to the extent possible, achieves the objective reflected in the original provision.</p>',
    fr: '<p>If any provision of these Terms were to become unenforceable or void by virtue of applicable law or as a result of a judicial ruling or administrative decision, said unenforceability or nullity will not render these Terms unenforceable or void as a whole. In such cases, the company will modify or replace the voided provision with another that is valid and enforceable and that, to the extent possible, achieves the objective reflected in the original provision.</p>',
    eus: '<p>If any provision of these Terms were to become unenforceable or void by virtue of applicable law or as a result of a judicial ruling or administrative decision, said unenforceability or nullity will not render these Terms unenforceable or void as a whole. In such cases, the company will modify or replace the voided provision with another that is valid and enforceable and that, to the extent possible, achieves the objective reflected in the original provision.</p>',
    cat: '<p>If any provision of these Terms were to become unenforceable or void by virtue of applicable law or as a result of a judicial ruling or administrative decision, said unenforceability or nullity will not render these Terms unenforceable or void as a whole. In such cases, the company will modify or replace the voided provision with another that is valid and enforceable and that, to the extent possible, achieves the objective reflected in the original provision.</p>',
  },

  hiring_Data97: {
    es: '<p>9. Quejas y Reclamaciones</p>',
    en: '<p>9. Complaints and Claims</p>',
    pt: '<p>9. Complaints and Claims</p>',
    fr: '<p>9. Complaints and Claims</p>',
    eus: '<p>9.	Complaints and Claims</p>',
    cat: '<p>9.	Complaints and Claims</p>',
  },

  hiring_Data98: {
    es: '<p>El Usuario puede hacer llegar a eyesCloud3D sus quejas, reclamaciones o todo otro comentario que desee realizar a través de los datos de contacto que se facilitan en el apartado 10 de estas Condiciones.</p>',
    en: '<p>The User can send eyesCloud3D their complaints, claims or any other comments they wish to make through the contact details provided in section 10 of these Terms.</p>',
    pt: '<p>The User can send eyesCloud3D their complaints, claims or any other comments they wish to make through the contact details provided in section 10 of these Terms.</p>',
    fr: '<p>The User can send eyesCloud3D their complaints, claims or any other comments they wish to make through the contact details provided in section 10 of these Terms.</p>',
    eus: '<p>The User can send eyesCloud3D their complaints, claims or any other comments they wish to make through the contact details provided in section 10 of these Terms.</p>',
    cat: '<p>The User can send eyesCloud3D their complaints, claims or any other comments they wish to make through the contact details provided in section 10 of these Terms.</p>',
  },

  hiring_Data99: {
    es: '<p>Además, eyesCloud3D dispone de hojas oficiales de reclamación a disposición de los consumidores y usuarios, que estos pueden solicitar a eyesCloud3D en cualquier momento.</p>',
    en: '<p>In addition, eyesCloud3D has official complaint forms available for consumers and users, which they can request from eyesCloud3D at any time.</p>',
    pt: '<p>In addition, eyesCloud3D has official complaint forms available for consumers and users, which they can request from eyesCloud3D at any time.</p>',
    fr: '<p>In addition, eyesCloud3D has official complaint forms available for consumers and users, which they can request from eyesCloud3D at any time.</p>',
    eus: '<p>In addition, eyesCloud3D has official complaint forms available for consumers and users, which they can request from eyesCloud3D at any time.</p>',
    cat: '<p>In addition, eyesCloud3D has official complaint forms available for consumers and users, which they can request from eyesCloud3D at any time.</p>',
  },

  hiring_Data100: {
    es: '<p>Asimismo, si de la celebración de este contrato de compra entre eyesCloud3D y el Usuario emanara una controversia, el Usuario como consumidor puede solicitar una solución extrajudicial de controversias, de acuerdo con el Reglamento UE Nº 524/2013 del Parlamento Europeo y del Consejo, de 21 de mayo de 2013, sobre resolución de litigios en línea en materia de consumo. Puede acceder a este método a través del sitio web: http://ec.europa.eu/consumers/odr/.</p>',
    en: '<p>In addition, if a dispute arises between eyesCloud3D and the User pertaining to this purchase agreement, the User as a consumer may request an out-of-court settlement of the matter, in accordance with EU Regulation No. 524/2013 of the European Parliament and of the Council of 21 May 2013 on online dispute resolution in consumer matters. You can access this method via the website: http://ec.europa.eu/consumers/odr/.</p>',
    pt: '<p>In addition, if a dispute arises between eyesCloud3D and the User pertaining to this purchase agreement, the User as a consumer may request an out-of-court settlement of the matter, in accordance with EU Regulation No. 524/2013 of the European Parliament and of the Council of 21 May 2013 on online dispute resolution in consumer matters. You can access this method via the website: http://ec.europa.eu/consumers/odr/.</p>',
    fr: '<p>In addition, if a dispute arises between eyesCloud3D and the User pertaining to this purchase agreement, the User as a consumer may request an out-of-court settlement of the matter, in accordance with EU Regulation No. 524/2013 of the European Parliament and of the Council of 21 May 2013 on online dispute resolution in consumer matters. You can access this method via the website: http://ec.europa.eu/consumers/odr/.</p>',
    eus: '<p>In addition, if a dispute arises between eyesCloud3D and the User pertaining to this purchase agreement, the User as a consumer may request an out-of-court settlement of the matter, in accordance with EU Regulation No. 524/2013 of the European Parliament and of the Council of 21 May 2013 on online dispute resolution in consumer matters. You can access this method via the website: http://ec.europa.eu/consumers/odr/.</p>',
    cat: '<p>In addition, if a dispute arises between eyesCloud3D and the User pertaining to this purchase agreement, the User as a consumer may request an out-of-court settlement of the matter, in accordance with EU Regulation No. 524/2013 of the European Parliament and of the Council of 21 May 2013 on online dispute resolution in consumer matters. You can access this method via the website: http://ec.europa.eu/consumers/odr/.</p>',
  },

  hiring_Data101: {
    es: '<p>10. Contacto</p>',
    en: '<p>10.	Contact</p>',
    pt: '<p>10.	Contact</p>',
    fr: '<p>10.	Contact</p>',
    eus: '<p>10. Contact</p>',
    cat: '<p>10. Contact</p>',
  },

  hiring_Data102: {
    es: '<p>Si tiene preguntas o comentarios sobre estos Términos, puede ponerse en contacto con nosotros en Torre Siglo XXI. Paseo Fluvial Nº 15, Planta 11. 06011 Badajoz, España o a través de la dirección de correo electrónico customercare@eyescloud3d.com.</p>',
    en: '<p>If you have questions or comments about these Terms, you can contact us at Torre Siglo XXI. Paseo Fluvial Nº 15, Planta 11. 06011 Badajoz, Spain or via the email address customercare@eyescloud3d.com.</p>',
    pt: '<p>If you have questions or comments about these Terms, you can contact us at Torre Siglo XXI. Paseo Fluvial Nº 15, Planta 11. 06011 Badajoz, Spain or via the email address customercare@eyescloud3d.com.</p>',
    fr: '<p>If you have questions or comments about these Terms, you can contact us at Torre Siglo XXI. Paseo Fluvial Nº 15, Planta 11. 06011 Badajoz, Spain or via the email address customercare@eyescloud3d.com.</p>',
    eus: '<p>If you have questions or comments about these Terms, you can contact us at Torre Siglo XXI. Paseo Fluvial Nº 15, Planta 11. 06011 Badajoz, Spain or via the email address customercare@eyescloud3d.com.</p>',
    cat: '<p>If you have questions or comments about these Terms, you can contact us at Torre Siglo XXI. Paseo Fluvial Nº 15, Planta 11. 06011 Badajoz, Spain or via the email address customercare@eyescloud3d.com.</p>',
  },

  hiring_Data103: {
    es: '<p>11. Tarifas y precios</p>',
    en: '<p>11. Fees and Pricing</p>',
    pt: '<p>11. Fees and Pricing</p>',
    fr: '<p>11. Fees and Pricing</p>',
    eus: '<p>11. Fees and Pricing</p>',
    cat: '<p>11. Fees and Pricing</p>',
  },

  hiring_Data104: {
    es: '<p>En eyesCloud3D nos ajustamos a ti y hemos ideado varias tarifas en función de tus requerimientos: Free, Pro y Business.</p>',
    en: '<p>At eyesCloud3D we adapt to you and have devised several plans based on your requirements: Free, Pro and Business.</p>',
    pt: '<p>At eyesCloud3D we adapt to you and have devised several plans based on your requirements: Free, Pro and Business.</p>',
    fr: '<p>At eyesCloud3D we adapt to you and have devised several plans based on your requirements: Free, Pro and Business.</p>',
    eus: '<p>At eyesCloud3D we adapt to you and have devised several plans based on your requirements: Free, Pro and Business.</p>',
    cat: '<p>At eyesCloud3D we adapt to you and have devised several plans based on your requirements: Free, Pro and Business.</p>',
  },

  hiring_Data105: {
    es: '<p>¿Qué incluye cada tarifa?</p>',
    en: '<p>What does each fee include?</p>',
    pt: '<p>What does each fee include?</p>',
    fr: '<p>What does each fee include?</p>',
    eus: '<p>What does each fee include?</p>',
    cat: '<p>What does each fee include?</p>',
  },

  hiring_Data106: {
    es: '<p>Tarifa Free</p>',
    en: '<p>Free Plan</p>',
    pt: '<p>Free Plan</p>',
    fr: '<p>Free Plan</p>',
    eus: '<p>Free Plan</p>',
    cat: '<p>Free Plan</p>',
  },

  hiring_Data107: {
    es: '<p>La Tarifa Free es la opción perfecta para aquellos que buscan explorar la plataforma sin compromisos. Con acceso gratuito, esta tarifa ofrece una selección básica de características diseñadas para satisfacer las necesidades fundamentales de los usuarios, aunque tiene algunas limitaciones en comparación con las suscripciones de pago, como las herramientas avanzadas o contenido exclusivo, la Tarifa Free proporciona una experiencia sólida para aquellos que desean probar la plataforma antes de comprometerse con una suscripción de pago. Ideal para usuarios ocasionales o aquellos que buscan una introducción sin costos.</p>',
    en: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions such as advanced tools or exclusive content the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge.</p>',
    pt: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions such as advanced tools or exclusive content the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge.</p>',
    fr: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions such as advanced tools or exclusive content the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge.</p>',
    eus: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions such as advanced tools or exclusive content the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge.</p>',
    cat: '<p>The Free Plan is the perfect option for those looking to explore the platform with no strings attached. With free access, this plan offers a basic selection of features designed to meet the fundamental needs of users; although it has some limitations compared to the features offered in the paid subscriptions such as advanced tools or exclusive content the Free Plan provides a thorough experience for those who want to try the platform before committing to a paid subscription. It is ideal for casual users or those looking for an introduction to the software free of charge.</p>',
  },

  hiring_Data108: {
    es: '<p class="tos-bold">La Tarifa Free no tiene coste de suscripción, pero cada importación y cada descarga tiene un precio de 2,95€.</p>',
    en: '<p class="tos-bold">The Free Plan has no subscription cost, but each import and download is priced at €2.95.</p>',
    pt: '<p class="tos-bold">The Free Plan has no subscription cost, but each import and download is priced at €2.95.</p>',
    fr: '<p class="tos-bold">The Free Plan has no subscription cost, but each import and download is priced at €2.95.</p>',
    eus: '<p class="tos-bold">The Free Plan has no subscription cost, but each import and download is priced at €2.95.</p>',
    cat: '<p class="tos-bold">The Free Plan has no subscription cost, but each import and download is priced at €2.95.</p>',
  },

  hiring_Data109: {
    es: '<p><span class="tos-bold">¿Puedo cambiar mi tarifa Free a otro de pago más adelante?</span> Claro que puedes. Siempre que lo desees podrás cambiar tu suscripción de la Tarifa Free a cualquiera de nuestras otras tarifas de pago. Tu ciclo de facturación se iniciará el día que tu pago se realice.</p>',
    en: '<p><span class="tos-bold">Can I upgrade my Free Plan to a paid one later? </span>Of course you can. Whenever you wish, you can upgrade your subscription from the Free Plan to any of our other paid plans. Your billing cycle will start on the day your payment is made.</p>',
    pt: '<p><span class="tos-bold">Can I upgrade my Free Plan to a paid one later? </span>Of course you can. Whenever you wish, you can upgrade your subscription from the Free Plan to any of our other paid plans. Your billing cycle will start on the day your payment is made.</p>',
    fr: '<p><span class="tos-bold">Can I upgrade my Free Plan to a paid one later? </span>Of course you can. Whenever you wish, you can upgrade your subscription from the Free Plan to any of our other paid plans. Your billing cycle will start on the day your payment is made.</p>',
    eus: '<p><span class="tos-bold">Can I upgrade my Free Plan to a paid one later? </span>Of course you can. Whenever you wish, you can upgrade your subscription from the Free Plan to any of our other paid plans. Your billing cycle will start on the day your payment is made.</p>',
    cat: '<p><span class="tos-bold">Can I upgrade my Free Plan to a paid one later? </span>Of course you can. Whenever you wish, you can upgrade your subscription from the Free Plan to any of our other paid plans. Your billing cycle will start on the day your payment is made.</p>',
  },

  hiring_Data110: {
    es: '<p>Tarifa Pro</p>',
    en: '<p>Pro Plan</p>',
    pt: '<p>Pro Plan</p>',
    fr: '<p>Pro Plan</p>',
    eus: '<p>Pro Plan</p>',
    cat: '<p>Pro Plan</p>',
  },

  hiring_Data111: {
    es: '<p>La Tarifa Pro es la opción premium para aquellos que desean llevar su experiencia a un nivel superior. A cambio de una tarifa periódica, los suscriptores obtienen acceso a una gama completa de funciones avanzadas, herramientas únicas y beneficios adicionales. Esta tarifa está diseñada para usuarios más comprometidos y exigentes que buscan aprovechar al máximo la plataforma. Con características como mayor capacidad de almacenamiento, funciones de personalización y soporte prioritario, la Tarifa Pro ofrece una experiencia completa y sin restricciones para satisfacer las necesidades de los usuarios más dedicados.</p>',
    en: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users.</p>',
    pt: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users.</p>',
    fr: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users.</p>',
    eus: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users.</p>',
    cat: '<p>The Pro Plan is the premium option for those who want to take their user experience to the next level. For a recurring fee, subscribers get access to a full range of advanced features, unique tools, and additional benefits. This plan is designed for more engaged and demanding users looking to get the most out of the platform. With features such as increased storage capacity, customization features, and priority support, the Pro Plan offers a complete, unrestricted experience to meet the needs of the most dedicated users.</p>',
  },

  hiring_Data112: {
    es: '<p class="tos-bold">La Tarifa Pro General tiene un precio de 7,95€ al mes o 79,95€ al año. ¡La suscripción anual te permitirá ahorrar un 17%!</p>',
    en: '<p class="tos-bold">The General Pro Plan is priced at €7.95 per month or €79.95 per year. You save 17% with the annual subscription!</p>',
    pt: '<p class="tos-bold">The General Pro Plan is priced at €7.95 per month or €79.95 per year. You save 17% with the annual subscription!</p>',
    fr: '<p class="tos-bold">The General Pro Plan is priced at €7.95 per month or €79.95 per year. You save 17% with the annual subscription!</p>',
    eus: '<p class="tos-bold">The General Pro Plan is priced at €7.95 per month or €79.95 per year. You save 17% with the annual subscription!</p>',
    cat: '<p class="tos-bold">The General Pro Plan is priced at €7.95 per month or €79.95 per year. You save 17% with the annual subscription!</p>',
  },

  hiring_Data113: {
    es: '<p>Además, hemos creado una serie de funcionalidades concretas para proporcionarte las herramientas que necesitas para tu sector específico, creando las tarifas:</p>',
    en: '<p>In addition, we have created a series of specific features to provide you with the tools you need for your particular industry sector, by offering the following plans:</p>',
    pt: '<p>In addition, we have created a series of specific features to provide you with the tools you need for your particular industry sector, by offering the following plans:</p>',
    fr: '<p>In addition, we have created a series of specific features to provide you with the tools you need for your particular industry sector, by offering the following plans:</p>',
    eus: '<p>In addition, we have created a series of specific features to provide you with the tools you need for your particular industry sector, by offering the following plans:</p>',
    cat: '<p>In addition, we have created a series of specific features to provide you with the tools you need for your particular industry sector, by offering the following plans:</p>',
  },

  hiring_Data114: {
    es: '<p>- <span class="tos-bold">Pro Ingeniería:</span> Diseñada específicamente para aquellos que están inmersos en el mundo de la ingeniería. En un entorno empresarial cada vez más competitivo, la necesidad de herramientas avanzadas de generación 3D se ha vuelto fundamental para optimizar procesos y garantizar un diseño preciso y eficiente. Esta tarifa no solo proporciona acceso a una potente suite de herramientas de modelado, sino que también ofrece soluciones personalizadas que abordan los desafíos únicos que enfrentan las empresas del sector. La Tarifa Pro Ingeniería está disponible por 49€ al mes, o puedes suscribirte un año por 490€, ahorrando un 17%.</p>',
    en: '<p>- <span class="tos-bold">Engineering Pro:</span> Designed specifically for those active in the world of engineering. In an increasingly competitive business environment, the need for advanced 3D generation tools has become fundamental to optimize processes and ensure accurate and efficient design. This pricing option not only provides access to a powerful suite of modeling tools, but also offers customized solutions that address the unique challenges faced by companies in the sector. The Pro Engineering Plan is available for €49 per month, or you can save 17% by signing up for the yearly subscription, priced at €490.</p>',
    pt: '<p>- <span class="tos-bold">Engineering Pro:</span> Designed specifically for those active in the world of engineering. In an increasingly competitive business environment, the need for advanced 3D generation tools has become fundamental to optimize processes and ensure accurate and efficient design. This pricing option not only provides access to a powerful suite of modeling tools, but also offers customized solutions that address the unique challenges faced by companies in the sector. The Pro Engineering Plan is available for €49 per month, or you can save 17% by signing up for the yearly subscription, priced at €490.</p>',
    fr: '<p>- <span class="tos-bold">Engineering Pro:</span> Designed specifically for those active in the world of engineering. In an increasingly competitive business environment, the need for advanced 3D generation tools has become fundamental to optimize processes and ensure accurate and efficient design. This pricing option not only provides access to a powerful suite of modeling tools, but also offers customized solutions that address the unique challenges faced by companies in the sector. The Pro Engineering Plan is available for €49 per month, or you can save 17% by signing up for the yearly subscription, priced at €490.</p>',
    eus: '<p>- <span class="tos-bold">Engineering Pro:</span> Designed specifically for those active in the world of engineering. In an increasingly competitive business environment, the need for advanced 3D generation tools has become fundamental to optimize processes and ensure accurate and efficient design. This pricing option not only provides access to a powerful suite of modeling tools, but also offers customized solutions that address the unique challenges faced by companies in the sector. The Pro Engineering Plan is available for €49 per month, or you can save 17% by signing up for the yearly subscription, priced at €490.</p>',
    cat: '<p>- <span class="tos-bold">Engineering Pro:</span> Designed specifically for those active in the world of engineering. In an increasingly competitive business environment, the need for advanced 3D generation tools has become fundamental to optimize processes and ensure accurate and efficient design. This pricing option not only provides access to a powerful suite of modeling tools, but also offers customized solutions that address the unique challenges faced by companies in the sector. The Pro Engineering Plan is available for €49 per month, or you can save 17% by signing up for the yearly subscription, priced at €490.</p>',
  },

  hiring_Data115: {
    es: '<p>- <span class="tos-bold">Pro Seguridad:</span> Diseñada para el sector de la seguridad y fuerzas del estado. Con el continuo aumento de los desafíos en el ámbito de la seguridad, es esencial contar con herramientas avanzadas como el análisis de la trayectoria de la bala o la salpicadura de la sangre. La Tarifa Pro Seguridad está disponible por 69€ al mes, o puedes suscribirte un año por 690€, ahorrando un 17%.</p>',
    en: '<p>- <span class="tos-bold">Security Pro:</span> Designed for with law enforcement and the security industry in mind. With the increasing challenges faced in the safety field, it is essential to have advanced tools such as bullet trajectory or blood splatter analysis. The Pro Security Plan is available for €69 per month, or you can save 17% by signing up for the yearly subscription, priced at €690.</p>',
    pt: '<p>- <span class="tos-bold">Security Pro:</span> Designed for with law enforcement and the security industry in mind. With the increasing challenges faced in the safety field, it is essential to have advanced tools such as bullet trajectory or blood splatter analysis. The Pro Security Plan is available for €69 per month, or you can save 17% by signing up for the yearly subscription, priced at €690.</p>',
    fr: '<p>- <span class="tos-bold">Security Pro:</span> Designed for with law enforcement and the security industry in mind. With the increasing challenges faced in the safety field, it is essential to have advanced tools such as bullet trajectory or blood splatter analysis. The Pro Security Plan is available for €69 per month, or you can save 17% by signing up for the yearly subscription, priced at €690.</p>',
    eus: '<p>- <span class="tos-bold">Security Pro:</span> Designed for with law enforcement and the security industry in mind. With the increasing challenges faced in the safety field, it is essential to have advanced tools such as bullet trajectory or blood splatter analysis. The Pro Security Plan is available for €69 per month, or you can save 17% by signing up for the yearly subscription, priced at €690.</p>',
    cat: '<p>- <span class="tos-bold">Security Pro:</span> Designed for with law enforcement and the security industry in mind. With the increasing challenges faced in the safety field, it is essential to have advanced tools such as bullet trajectory or blood splatter analysis. The Pro Security Plan is available for €69 per month, or you can save 17% by signing up for the yearly subscription, priced at €690.</p>',
  },

  hiring_Data116: {
    es: '<p>- <span class="tos-bold">Pro Agricultura:</span> Diseñada a medida para el sector agrícola, busca revolucionar la forma en que cultivan y gestionan su negocio nuestros usuarios. En un mundo donde la sostenibilidad, la eficiencia y la precisión son esenciales para el éxito agrícola, esta tarifa ofrece un conjunto completo de herramientas diseñadas para aumentar la productividad y mejorar la toma de decisiones en cada etapa del ciclo. La Tarifa Pro Agricultura está disponible por 39€ al mes, o puedes suscribirte un año por 390€, ahorrando un 17%.</p>',
    en: '<p>- <span class="tos-bold">Agriculture Pro:</span> Custom-designed for the farming industry, it seeks to revolutionize how our users grow and manage their business. In a world where sustainability, efficiency, and accuracy are essential to agricultural success, this subscription option offers a comprehensive set of tools designed to increase productivity and improve decision-making at every step. The Agriculture Pro Plan is available for €39 per month, or you can save 17% by signing up for the yearly subscription, priced at €390.</p>',
    pt: '<p>- <span class="tos-bold">Agriculture Pro:</span> Custom-designed for the farming industry, it seeks to revolutionize how our users grow and manage their business. In a world where sustainability, efficiency, and accuracy are essential to agricultural success, this subscription option offers a comprehensive set of tools designed to increase productivity and improve decision-making at every step. The Agriculture Pro Plan is available for €39 per month, or you can save 17% by signing up for the yearly subscription, priced at €390.</p>',
    fr: '<p>- <span class="tos-bold">Agriculture Pro:</span> Custom-designed for the farming industry, it seeks to revolutionize how our users grow and manage their business. In a world where sustainability, efficiency, and accuracy are essential to agricultural success, this subscription option offers a comprehensive set of tools designed to increase productivity and improve decision-making at every step. The Agriculture Pro Plan is available for €39 per month, or you can save 17% by signing up for the yearly subscription, priced at €390.</p>',
    eus: '<p>- <span class="tos-bold">Agriculture Pro:</span> Custom-designed for the farming industry, it seeks to revolutionize how our users grow and manage their business. In a world where sustainability, efficiency, and accuracy are essential to agricultural success, this subscription option offers a comprehensive set of tools designed to increase productivity and improve decision-making at every step. The Agriculture Pro Plan is available for €39 per month, or you can save 17% by signing up for the yearly subscription, priced at €390.</p>',
    cat: '<p>- <span class="tos-bold">Agriculture Pro:</span> Custom-designed for the farming industry, it seeks to revolutionize how our users grow and manage their business. In a world where sustainability, efficiency, and accuracy are essential to agricultural success, this subscription option offers a comprehensive set of tools designed to increase productivity and improve decision-making at every step. The Agriculture Pro Plan is available for €39 per month, or you can save 17% by signing up for the yearly subscription, priced at €390.</p>',
  },

  hiring_Data117: {
    es: '<p>- <span class="tos-bold">Pro Plus:</span> Esta tarifa es la respuesta definitiva para aquellos que buscan una solución completa, sin restricciones y adaptable a múltiples verticales. Abarca las características especializadas de nuestras tarifas Pro Ingeniería, Pro Seguridad y Pro Agricultura, con acceso total a herramientas de modelado 3D avanzadas, es una invitación a explorar las infinitas posibilidades de eyesCloud3D. La Tarifa Pro Plus está disponible por 79€ al mes, o puedes suscribirte por un año por 790€, ahorrando un 17%.</p>',
    en: '<p>- <span class="tos-bold">Pro Plus:</span> This option is the ultimate answer for those looking for a complete solution, unrestricted and adaptable to multiple silos. It combines the specialized features of our Engineering Pro, Security Pro and Agriculture Pro plans, with full access to advanced 3D modeling tools, it\'s an invitation to explore the endless possibilities of eyesCloud3D. The Pro Plus Plan is available for €79 per month, or you can save 17% by signing up for the yearly subscription, priced at €790.</p>',
    pt: '<p>- <span class="tos-bold">Pro Plus:</span> This option is the ultimate answer for those looking for a complete solution, unrestricted and adaptable to multiple silos. It combines the specialized features of our Engineering Pro, Security Pro and Agriculture Pro plans, with full access to advanced 3D modeling tools, it\'s an invitation to explore the endless possibilities of eyesCloud3D. The Pro Plus Plan is available for €79 per month, or you can save 17% by signing up for the yearly subscription, priced at €790.</p>',
    fr: '<p>- <span class="tos-bold">Pro Plus:</span> This option is the ultimate answer for those looking for a complete solution, unrestricted and adaptable to multiple silos. It combines the specialized features of our Engineering Pro, Security Pro and Agriculture Pro plans, with full access to advanced 3D modeling tools, it\'s an invitation to explore the endless possibilities of eyesCloud3D. The Pro Plus Plan is available for €79 per month, or you can save 17% by signing up for the yearly subscription, priced at €790.</p>',
    eus: '<p>- <span class="tos-bold">Pro Plus:</span> This option is the ultimate answer for those looking for a complete solution, unrestricted and adaptable to multiple silos. It combines the specialized features of our Engineering Pro, Security Pro and Agriculture Pro plans, with full access to advanced 3D modeling tools, it\'s an invitation to explore the endless possibilities of eyesCloud3D. The Pro Plus Plan is available for €79 per month, or you can save 17% by signing up for the yearly subscription, priced at €790.</p>',
    cat: '<p>- <span class="tos-bold">Pro Plus:</span> This option is the ultimate answer for those looking for a complete solution, unrestricted and adaptable to multiple silos. It combines the specialized features of our Engineering Pro, Security Pro and Agriculture Pro plans, with full access to advanced 3D modeling tools, it\'s an invitation to explore the endless possibilities of eyesCloud3D. The Pro Plus Plan is available for €79 per month, or you can save 17% by signing up for the yearly subscription, priced at €790.</p>',
  },

  hiring_Data118: {
    es: '<p>¿Qué ocurre cuando alcanzas el límite mensual de descargas o importaciones?</p>',
    en: '<p>What happens when you reach the monthly download or import limit?</p>',
    pt: '<p>What happens when you reach the monthly download or import limit?</p>',
    fr: '<p>What happens when you reach the monthly download or import limit?</p>',
    eus: '<p>What happens when you reach the monthly download or import limit?</p>',
    cat: '<p>What happens when you reach the monthly download or import limit?</p>',
  },

  hiring_Data119: {
    es: '<p>¡No te preocupes! podrás seguir descargando e importando como lo hacías antes, pero se te aplicará un cargo adicional de +2,95€ por cada descargo o importación extra.</p>',
    en: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    pt: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    fr: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    eus: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    cat: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
  },

  hiring_Data120: {
    es: '<p>Tarifa Business</p>',
    en: '<p>Business Plan</p>',
    pt: '<p>Business Plan</p>',
    fr: '<p>Business Plan</p>',
    eus: '<p>Business Plan</p>',
    cat: '<p>Business Plan</p>',
  },

  hiring_Data121: {
    es: '<p>La Tarifa Business es la opción ideal para empresas y equipos que buscan herramientas y recursos especializados para potenciar su productividad. Diseñado específicamente para entornos comerciales, esta tarifa de suscripción de pago ofrece funcionalidades avanzadas orientadas a la colaboración, seguridad empresarial y gestión de usuarios. Es la elección perfecta para aquellos que buscan aprovechar al máximo la plataforma en un entorno empresarial ya que permite aumentar el número de usuarios de una cuenta fomentando el trabajo en equipo.</p>',
    en: '<p>The Business Plan is the ideal option for companies and teams looking for specialized tools and resources to boost their productivity. Designed specifically for business environments, this paid subscription plan offers advanced features geared towards collaboration, enterprise security, and user management. It is the perfect choice for those looking to make the most of the platform in a business environment, as it\'s possible to increase the number of users of an account, thus encouraging teamwork.<p>',
    pt: '<p>The Business Plan is the ideal option for companies and teams looking for specialized tools and resources to boost their productivity. Designed specifically for business environments, this paid subscription plan offers advanced features geared towards collaboration, enterprise security, and user management. It is the perfect choice for those looking to make the most of the platform in a business environment, as it\'s possible to increase the number of users of an account, thus encouraging teamwork.<p>',
    fr: '<p>The Business Plan is the ideal option for companies and teams looking for specialized tools and resources to boost their productivity. Designed specifically for business environments, this paid subscription plan offers advanced features geared towards collaboration, enterprise security, and user management. It is the perfect choice for those looking to make the most of the platform in a business environment, as it\'s possible to increase the number of users of an account, thus encouraging teamwork.<p>',
    eus: '<p>The Business Plan is the ideal option for companies and teams looking for specialized tools and resources to boost their productivity. Designed specifically for business environments, this paid subscription plan offers advanced features geared towards collaboration, enterprise security, and user management. It is the perfect choice for those looking to make the most of the platform in a business environment, as it\'s possible to increase the number of users of an account, thus encouraging teamwork.<p>',
    cat: '<p>The Business Plan is the ideal option for companies and teams looking for specialized tools and resources to boost their productivity. Designed specifically for business environments, this paid subscription plan offers advanced features geared towards collaboration, enterprise security, and user management. It is the perfect choice for those looking to make the most of the platform in a business environment, as it\'s possible to increase the number of users of an account, thus encouraging teamwork.<p>',
  },

  hiring_Data122: {
    es: '<p>Nuestra tarifa Business General está disponible con por un precio de 69€ al mes o 690€ al año.</p>',
    en: '<p>Our General Business plan is available for €69 per month or €690 per year.</p>',
    pt: '<p>Our General Business plan is available for €69 per month or €690 per year.</p>',
    fr: '<p>Our General Business plan is available for €69 per month or €690 per year.</p>',
    eus: '<p>Our General Business plan is available for €69 per month or €690 per year.</p>',
    cat: '<p>Our General Business plan is available for €69 per month or €690 per year.</p>',
  },

  hiring_Data123: {
    es: '<p>Además, puedes personalizar tu suscripción añadiendo las funcionalidades específicas para Ingeniería, Seguridad o Agricultura que se ofrecen en la Tarifa Pro:</p>',
    en: '<p>Additionally, you can customize your subscription by adding the Engineering, Security, or Agriculture-specific features offered in the Pro Plan:</p>',
    pt: '<p>Additionally, you can customize your subscription by adding the Engineering, Security, or Agriculture-specific features offered in the Pro Plan:</p>',
    fr: '<p>Additionally, you can customize your subscription by adding the Engineering, Security, or Agriculture-specific features offered in the Pro Plan:</p>',
    eus: '<p>Additionally, you can customize your subscription by adding the Engineering, Security, or Agriculture-specific features offered in the Pro Plan:</p>',
    cat: '<p>Additionally, you can customize your subscription by adding the Engineering, Security, or Agriculture-specific features offered in the Pro Plan:</p>',
  },

  hiring_Data124: {
    es: '<p class="tos-bold">La Tarifa Business Ingeniería está disponible a un precio de 118€ al mes, o puedes suscribirte por un año entero por 1180€, ahorrando un 17%.</p>',
    en: '<p class="tos-bold">The Engineering Business Plan is available for €118 per month, or you can save 17% by signing up for the yearly subscription, priced at €1180.</p>',
    pt: '<p class="tos-bold">The Engineering Business Plan is available for €118 per month, or you can save 17% by signing up for the yearly subscription, priced at €1180.</p>',
    fr: '<p class="tos-bold">The Engineering Business Plan is available for €118 per month, or you can save 17% by signing up for the yearly subscription, priced at €1180.</p>',
    eus: '<p class="tos-bold">The Engineering Business Plan is available for €118 per month, or you can save 17% by signing up for the yearly subscription, priced at €1180.</p>',
    cat: '<p class="tos-bold">The Engineering Business Plan is available for €118 per month, or you can save 17% by signing up for the yearly subscription, priced at €1180.</p>',
  },

  hiring_Data125: {
    es: '<p class="tos-bold">La Tarifa Business Seguridad está disponible a un precio de 138€ al mes, o puedes suscribirte por un año entero por 1380€, ahorrando un 17%.</p>',
    en: '<p class="tos-bold">The Security Business Plan is available for €138 per month, or you can save 17% by signing up for the yearly subscription, priced at €1380.</p>',
    pt: '<p class="tos-bold">The Security Business Plan is available for €138 per month, or you can save 17% by signing up for the yearly subscription, priced at €1380.</p>',
    fr: '<p class="tos-bold">The Security Business Plan is available for €138 per month, or you can save 17% by signing up for the yearly subscription, priced at €1380.</p>',
    eus: '<p class="tos-bold">The Security Business Plan is available for €138 per month, or you can save 17% by signing up for the yearly subscription, priced at €1380.</p>',
    cat: '<p class="tos-bold">The Security Business Plan is available for €138 per month, or you can save 17% by signing up for the yearly subscription, priced at €1380.</p>',
  },

  hiring_Data126: {
    es: '<p class="tos-bold">La Tarifa Business Agricultura está disponible a un precio de 98€ al mes, o puedes suscribirte por un año entero por 980€, ahorrando un 17%.</p>',
    en: '<p class="tos-bold">The Agriculture Business Plan is available for €98 per month, or you can save 17% by signing up for the yearly subscription, priced at €980.</p>',
    pt: '<p class="tos-bold">The Agriculture Business Plan is available for €98 per month, or you can save 17% by signing up for the yearly subscription, priced at €980.</p>',
    fr: '<p class="tos-bold">The Agriculture Business Plan is available for €98 per month, or you can save 17% by signing up for the yearly subscription, priced at €980.</p>',
    eus: '<p class="tos-bold">The Agriculture Business Plan is available for €98 per month, or you can save 17% by signing up for the yearly subscription, priced at €980.</p>',
    cat: '<p class="tos-bold">The Agriculture Business Plan is available for €98 per month, or you can save 17% by signing up for the yearly subscription, priced at €980.</p>',
  },

  hiring_Data127: {
    es: '<p>¿Y si necesitas más? Ofrecemos la Tarifa Business Plus, en donde encontrarás herramientas tanto básicas como avanzadas y todas las específicas de ingeniería, seguridad y agricultura. La Tarifa Business Plus está disponible a un precio de 148€ al mes o 1480€ al año. La suscripción anual te permitirá ahorrar un 17%.</p>',
    en: '<p>And if you need more? We offer the Business Plus Plan, where you will find both basic and advanced tools and all the specifics of Engineering, Safety and Agriculture. The Business Plus Plan is available at a price of €148 per month or €1,480 per year. You save 17% with the annual subscription.</p>',
    pt: '<p>And if you need more? We offer the Business Plus Plan, where you will find both basic and advanced tools and all the specifics of Engineering, Safety and Agriculture. The Business Plus Plan is available at a price of €148 per month or €1,480 per year. You save 17% with the annual subscription.</p>',
    fr: '<p>And if you need more? We offer the Business Plus Plan, where you will find both basic and advanced tools and all the specifics of Engineering, Safety and Agriculture. The Business Plus Plan is available at a price of €148 per month or €1,480 per year. You save 17% with the annual subscription.</p>',
    eus: '<p>And if you need more? We offer the Business Plus Plan, where you will find both basic and advanced tools and all the specifics of Engineering, Safety and Agriculture. The Business Plus Plan is available at a price of €148 per month or €1,480 per year. You save 17% with the annual subscription.</p>',
    cat: '<p>And if you need more? We offer the Business Plus Plan, where you will find both basic and advanced tools and all the specifics of Engineering, Safety and Agriculture. The Business Plus Plan is available at a price of €148 per month or €1,480 per year. You save 17% with the annual subscription.</p>',
  },

  hiring_Data128: {
    es: '<p>¿Qué ocurre cuando alcanzas el límite mensual de descargas o importaciones?</p>',
    en: '<p>What happens when you reach the monthly download or import limit?</p>',
    pt: '<p>What happens when you reach the monthly download or import limit?</p>',
    fr: '<p>What happens when you reach the monthly download or import limit?</p>',
    eus: '<p>What happens when you reach the monthly download or import limit?</p>',
    cat: '<p>What happens when you reach the monthly download or import limit?</p>',
  },

  hiring_Data129: {
    es: '<p>¡No te preocupes! podrás seguir descargando e importando como lo hacías antes, pero se te aplicará un cargo adicional de +2,95€ por cada descargo o importación extra.</p>',
    en: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    pt: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    fr: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    eus: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
    cat: '<p>Don\'t worry, you\'ll still be able to download and import as you did before, but you\'ll be charged an extra +2.95€ for each additional download or import.</p>',
  },

  hiring_Data130: {
    es: '<p>¿Puedo modificar mi suscripción?</p>',
    en: '<p>Can I change my subscription?</p>',
    pt: '<p>Can I change my subscription?</p>',
    fr: '<p>Can I change my subscription?</p>',
    eus: '<p>Can I change my subscription?</p>',
    cat: '<p>Can I change my subscription?</p>',
  },

  hiring_Data131: {
    es: '<p>¡Si!, puedes modificar tu suscripción cuando quieras siempre que tu tarifa lo permita. Para ser justos contigo, solo pagarás por el tiempo que has utilizado cada tarifa, de forma que prorratearemos los precios en función de los días que has utilizado cada tarifa.</p>',
    en: '<p>Yes, you can modify your subscription whenever you want, as long as your plan allows it. We value fairness, so you will only pay for the time you have used each plan; we\'ll do a pro rata calculation of how many days you have used each plan and charge you accordingly.</p>',
    pt: '<p>Yes, you can modify your subscription whenever you want, as long as your rate allows it. We value fairness, so you will only pay for the time you have used each plan; we\'ll do a pro rata calculation of how many days you have used each rate and charge you accordingly.</p>',
    fr: '<p>Yes, you can modify your subscription whenever you want, as long as your rate allows it. We value fairness, so you will only pay for the time you have used each plan; we\'ll do a pro rata calculation of how many days you have used each rate and charge you accordingly.</p>',
    eus: '<p>Yes, you can modify your subscription whenever you want, as long as your rate allows it. We value fairness, so you will only pay for the time you have used each plan; we\'ll do a pro rata calculation of how many days you have used each rate and charge you accordingly.</p>',
    cat: '<p>Yes, you can modify your subscription whenever you want, as long as your rate allows it. We value fairness, so you will only pay for the time you have used each plan; we\'ll do a pro rata calculation of how many days you have used each rate and charge you accordingly.</p>',
  },

  hiring_Data132: {
    es: '<p>Las modificaciones permitidas en función de la tarifa son:</p>',
    en: '<p>You can make the following changes to your plan:</p>',
    pt: '<p>You can make the following changes to your plan:</p>',
    fr: '<p>You can make the following changes to your plan:</p>',
    eus: '<p>You can make the following changes to your plan:</p>',
    cat: '<p>You can make the following changes to your plan:</p>',
  },

  hiring_Data133: {
    es: '<p>FUNCIONALIDADES ADICIONALES</p>',
    en: '<p>ADDITIONAL FEATURES</p>',
    pt: '<p>ADDITIONAL FEATURES</p>',
    fr: '<p>ADDITIONAL FEATURES</p>',
    eus: '<p>ADDITIONAL FEATURES</p>',
    cat: '<p>ADDITIONAL FEATURES</p>',
  },

  hiring_Data134: {
    es: '<p>Descargas e Importaciones Adicionales</p>',
    en: '<p>Additional Downloads and Imports</p>',
    pt: '<p>Additional Downloads and Imports</p>',
    fr: '<p>Additional Downloads and Imports</p>',
    eus: '<p>Additional Downloads and Imports</p>',
    cat: '<p>Additional Downloads and Imports</p>',
  },

  hiring_Data135: {
    es: '<p>Cada tarifa tiene un límite mensual a las descargas e importaciones. Si sobrepasas ese límite, puedes adquirir descargas e importaciones extra por 2.95€. En caso de que adquieras y no las utilices, serán acumuladas con el saldo del nuevo mes. Siempre se descontarán las descargas o importaciones extra, y luego las correspondientes a tu tarifa mensual.</p>',
    en: '<p>Each plan has a monthly limit on downloads and imports. If you exceed that limit, you can purchase extra downloads and imports for €2.95. If you purchase these features and do not use them, they will be added to the balance of the next month. Extra downloads or imports will always be deducted, and then those corresponding to your monthly plan.</p>',
    pt: '<p>Each plan has a monthly limit on downloads and imports. If you exceed that limit, you can purchase extra downloads and imports for €2.95. If you purchase these features and do not use them, they will be added to the balance of the next month. Extra downloads or imports will always be deducted, and then those corresponding to your monthly plan.</p>',
    fr: '<p>Each plan has a monthly limit on downloads and imports. If you exceed that limit, you can purchase extra downloads and imports for €2.95. If you purchase these features and do not use them, they will be added to the balance of the next month. Extra downloads or imports will always be deducted, and then those corresponding to your monthly plan.</p>',
    eus: '<p>Each plan has a monthly limit on downloads and imports. If you exceed that limit, you can purchase extra downloads and imports for €2.95. If you purchase these features and do not use them, they will be added to the balance of the next month. Extra downloads or imports will always be deducted, and then those corresponding to your monthly plan.</p>',
    cat: '<p>Each plan has a monthly limit on downloads and imports. If you exceed that limit, you can purchase extra downloads and imports for €2.95. If you purchase these features and do not use them, they will be added to the balance of the next month. Extra downloads or imports will always be deducted, and then those corresponding to your monthly plan.</p>',
  },

  hiring_Data136: {
    es: '<p>Usuarios Adicionales</p>',
    en: '<p>Additional Users</p>',
    pt: '<p>Additional Users</p>',
    fr: '<p>Additional Users</p>',
    eus: '<p>Additional Users</p>',
    cat: '<p>Additional Users</p>',
  },

  hiring_Data137: {
    es: '<p>Si lo necesitas, puedes modificar el número de usuarios con acceso a tu cuenta desde 7,95€ al mes y usuario. Esta nueva configuración será consolidada y renovaremos por defecto estas condiciones en los siguientes ciclos de facturación. En caso de que quieras cancelar tu usuario extra, podrás seguir disfrutándolo hasta tu siguiente fecha de facturación.</p>',
    en: '<p>If you need it, you can modify the number of users with access to your account starting at €7.95 per month and per user. These new settings will be saved and we will renew these conditions by default in the following billing cycles. In case you want to cancel your extra user, you can do so and continue to enjoy the extra account until your next billing date.</p>',
    pt: '<p>If you need it, you can modify the number of users with access to your account starting at €7.95 per month and per user. These new settings will be saved and we will renew these conditions by default in the following billing cycles. In case you want to cancel your extra user, you can do so and continue to enjoy the extra account until your next billing date.</p>',
    fr: '<p>If you need it, you can modify the number of users with access to your account starting at €7.95 per month and per user. These new settings will be saved and we will renew these conditions by default in the following billing cycles. In case you want to cancel your extra user, you can do so and continue to enjoy the extra account until your next billing date.</p>',
    eus: '<p>If you need it, you can modify the number of users with access to your account starting at €7.95 per month and per user. These new settings will be saved and we will renew these conditions by default in the following billing cycles. In case you want to cancel your extra user, you can do so and continue to enjoy the extra account until your next billing date.</p>',
    cat: '<p>If you need it, you can modify the number of users with access to your account starting at €7.95 per month and per user. These new settings will be saved and we will renew these conditions by default in the following billing cycles. In case you want to cancel your extra user, you can do so and continue to enjoy the extra account until your next billing date.</p>',
  },

  hiring_Data138: {
    es: '<p>Almacenamiento Adicional</p>',
    en: '<p>Additional Storage</p>',
    pt: '<p>Additional Storage</p>',
    fr: '<p>Additional Storage</p>',
    eus: '<p>Additional Storage</p>',
    cat: '<p>Additional Storage</p>',
  },

  hiring_Data139: {
    es: '<p>Si necesitas más almacenamiento del que te ofrece tu tarifa, puedes comprar almacenamiento extra por:</p>',
    en: '<p>If you need more storage than the one included in your plan, you can purchase extra storage for:</p>',
    pt: '<p>If you need more storage than the one included in your plan, you can purchase extra storage for:</p>',
    fr: '<p>If you need more storage than the one included in your plan, you can purchase extra storage for:</p>',
    eus: '<p>If you need more storage than the one included in your plan, you can purchase extra storage for:</p>',
    cat: '<p>If you need more storage than the one included in your plan, you can purchase extra storage for:</p>',
  },

  hiring_Data140: {
    es: '<p>Para ser justos contigo, solo se te cobrará por la parte proporcional al tiempo que falte para concluir tu ciclo de facturación. </p>',
    en: '<p>We value fairness so, you will only be charged for the portion of your billing cycle left until your next billing cycle. </p>',
    pt: '<p>We value fairness so, you will only be charged for the portion of your billing cycle left until your next billing cycle.</p>',
    fr: '<p>We value fairness so, you will only be charged for the portion of your billing cycle left until your next billing cycle.</p>',
    eus: '<p>We value fairness so, you will only be charged for the portion of your billing cycle left until your next billing cycle.</p>',
    cat: '<p>We value fairness so, you will only be charged for the portion of your billing cycle left until your next billing cycle.</p>',
  },

  hiring_Data141: {
    es: '<p>La compra de almacenamiento extra se consolidará, por lo que a menos de que lo canceles, renovaremos por defecto este servicio en tu próximo periodo de facturación.</p>',
    en: '<p>The purchase of extra storage will be saved as your new settings, so unless you cancel, we will renew this service by default in your next billing period.</p>',
    pt: '<p>The purchase of extra storage will be saved as your new settings, so unless you cancel, we will renew this service by default in your next billing period.</p>',
    fr: '<p>The purchase of extra storage will be saved as your new settings, so unless you cancel, we will renew this service by default in your next billing period.</p>',
    eus: '<p>The purchase of extra storage will be saved as your new settings, so unless you cancel, we will renew this service by default in your next billing period.</p>',
    cat: '<p>The purchase of extra storage will be saved as your new settings, so unless you cancel, we will renew this service by default in your next billing period.</p>',
  },

  hiring_Data142: {
    es: '<p>En casos de cambio en el almacenamiento, siempre te daremos la opción de seleccionar manualmente los modelos que desees eliminar para evitar pérdidas no deseadas, aunque si lo prefieres, podemos ocuparnos nosotros ejecutando una eliminación automática de los modelos en base a tus interacciones con ellos.</p>',
    en: '<p>Whenever there\'s a change in storage, we will give you the option to manually select the models you want to delete to avoid unwanted losses; however, if you prefer, we can execute an automatic deletion of models based on your interactions with them.</p>',
    pt: '<p>Whenever there\'s a change in storage, we will give you the option to manually select the models you want to delete to avoid unwanted losses; however, if you prefer, we can execute an automatic deletion of models based on your interactions with them.</p>',
    fr: '<p>Whenever there\'s a change in storage, we will give you the option to manually select the models you want to delete to avoid unwanted losses; however, if you prefer, we can execute an automatic deletion of models based on your interactions with them.</p>',
    eus: '<p>Whenever there\'s a change in storage, we will give you the option to manually select the models you want to delete to avoid unwanted losses; however, if you prefer, we can execute an automatic deletion of models based on your interactions with them.</p>',
    cat: '<p>Whenever there\'s a change in storage, we will give you the option to manually select the models you want to delete to avoid unwanted losses; however, if you prefer, we can execute an automatic deletion of models based on your interactions with them.</p>',
  },

  hiring_Data143: {
    es: '<p>En casos de tramitar la baja de tu almacenamiento adicional, tendrás un mes para reducir manualmente tu consumo de almacenamiento, o podemos encargarnos nosotros en base a tus interacciones con los modelos.</p>',
    en: '<p>If you cancel your additional storage upgrade, you will have one month to manually reduce your storage data, or we can take care of it based on your interactions with the models.</p>',
    pt: '<p>If you cancel your additional storage upgrade, you will have one month to manually reduce your storage data, or we can take care of it based on your interactions with the models.</p>',
    fr: '<p>If you cancel your additional storage upgrade, you will have one month to manually reduce your storage data, or we can take care of it based on your interactions with the models.</p>',
    eus: '<p>If you cancel your additional storage upgrade, you will have one month to manually reduce your storage data, or we can take care of it based on your interactions with the models.</p>',
    cat: '<p>If you cancel your additional storage upgrade, you will have one month to manually reduce your storage data, or we can take care of it based on your interactions with the models.</p>',
  },

  hiring_Data144: {
    es: '<p>En función de tu lugar de residencia, puede que se te apliquen impuestos además del precio de tu suscripción.</p>',
    en: '<p>Depending on where you live, taxes may be levied on the price of your membership.</p>',
    pt: '<p>Depending on where you live, taxes may be levied on the price of your membership.</p>',
    fr: '<p>Depending on where you live, taxes may be levied on the price of your membership.</p>',
    eus: '<p>Depending on where you live, taxes may be levied on the price of your membership.</p>',
    cat: '<p>Depending on where you live, taxes may be levied on the price of your membership.</p>',
  },
  hiring_Data145:{
    es: '<p>FREE</p>',
    en: '<p>FREE</p>',
    pt: '<p>FREE</p>',
    fr: '<p>FREE</p>',
    eus: '<p>FREE</p>',
    cat: '<p>FREE</p>',
  },
  hiring_Data146:{
    es: '<p>PRO</p>',
    en: '<p>PRO</p>',
    pt: '<p>PRO</p>',
    fr: '<p>PRO</p>',
    eus: '<p>PRO</p>',
    cat: '<p>PRO</p>',
  },
  hiring_Data147:{
    es: '<p>BUSINESS</p>',
    en: '<p>BUSINESS</p>',
    pt: '<p>BUSINESS</p>',
    fr: '<p>BUSINESS</p>',
    eus: '<p>BUSINESS</p>',
    cat: '<p>BUSINESS</p>',
  },
  hiring_Data148:{
    es: '<p>Usuarios</p>',
    en: '<p>Users</p>',
    pt: '<p>Users</p>',
    fr: '<p>Users</p>',
    eus: '<p>Users</p>',
    cat: '<p>Users</p>',
  },
  hiring_Data149:{
    es: '<p>Herramientas</p>',
    en: '<p>Tools</p>',
    pt: '<p>Tools</p>',
    fr: '<p>Tools</p>',
    eus: '<p>Tools</p>',
    cat: '<p>Tools</p>',
  },
  hiring_Data150:{
    es: '<p>Límite de subida</p>',
    en: '<p>Upload limit</p>',
    pt: '<p>Upload limit</p>',
    fr: '<p>Upload limit</p>',
    eus: '<p>Upload limit</p>',
    cat: '<p>Upload limit</p>',
  },
  hiring_Data151:{
    es: '<p>Descargas</p>',
    en: '<p>Downloads</p>',
    pt: '<p>Downloads</p>',
    fr: '<p>Downloads</p>',
    eus: '<p>Downloads</p>',
    cat: '<p>Downloads</p>',
  },
  hiring_Data152:{
    es: '<p>Importaciones</p>',
    en: '<p>Imports</p>',
    pt: '<p>Imports</p>',
    fr: '<p>Imports</p>',
    eus: '<p>Imports</p>',
    cat: '<p>Imports</p>',
  },
  hiring_Data153:{
    es: '<p>Tamaño de la biblioteca</p>',
    en: '<p>Gallery size</p>',
    pt: '<p>Gallery size</p>',
    fr: '<p>Gallery size</p>',
    eus: '<p>Gallery size</p>',
    cat: '<p>Gallery size</p>',
  },
  hiring_Data154:{
    es: '<p>Básicas</p>',
    en: '<p>Basic</p>',
    pt: '<p>Basic</p>',
    fr: '<p>Basic</p>',
    eus: '<p>Basic</p>',
    cat: '<p>Basic</p>',
  },
  hiring_Data155:{
    es: '<p>Básicas + Avanzadas</p>',
    en: '<p>Basic + Advanced</p>',
    pt: '<p>Basic + Advanced</p>',
    fr: '<p>Basic + Advanced</p>',
    eus: '<p>Basic + Advanced</p>',
    cat: '<p>Basic + Advanced</p>',
  },
  hiring_Data156:{
    es: '<p>200 fotos + 2 min de video</p>',
    en: '<p>200 photos + 2 min of video</p>',
    pt: '<p>200 photos + 2 min of video</p>',
    fr: '<p>200 photos + 2 min of video</p>',
    eus: '<p>200 photos + 2 min of video</p>',
    cat: '<p>200 photos + 2 min of video</p>',
  },
  hiring_Data157:{
    es: '<p>200 fotos + 4 min de video</p>',
    en: '<p>200 photos + 4 of video</p>',
    pt: '<p>200 photos + 4 of video</p>',
    fr: '<p>200 photos + 4 of video</p>',
    eus: '<p>200 photos + 4 of video</p>',
    cat: '<p>200 photos + 4 of video</p>',
  },
  hiring_Data158:{
    es: '<p>No incluidas</p>',
    en: '<p>No included</p>',
    pt: '<p>No included</p>',
    fr: '<p>No included</p>',
    eus: '<p>No included</p>',
    cat: '<p>No included</p>',
  },
  hiring_Data159:{
    es: '<p>Herramientas Incluidas</p>',
    en: '<p>Tools Included</p>',
    pt: '<p>Tools Included</p>',
    fr: '<p>Tools Included</p>',
    eus: '<p>Tools Included</p>',
    cat: '<p>Tools Included</p>',
  },
  hiring_Data160:{
    es: '<p>Ingeniería</p>',
    en: '<p>Engineering</p>',
    pt: '<p>Engineering</p>',
    fr: '<p>Engineering</p>',
    eus: '<p>Engineering</p>',
    cat: '<p>Engineering</p>',
  },
  hiring_Data161:{
    es: '<p>Seguridad</p>',
    en: '<p>Safety</p>',
    pt: '<p>Safety</p>',
    fr: '<p>Safety</p>',
    eus: '<p>Safety</p>',
    cat: '<p>Safety</p>',
  },
  hiring_Data162:{
    es: '<p>Agricultura</p>',
    en: '<p>Agriculture</p>',
    pt: '<p>Agriculture</p>',
    fr: '<p>Agriculture</p>',
    eus: '<p>Agriculture</p>',
    cat: '<p>Agriculture</p>',
  },
  hiring_Data163:{
    es: '<p>Plus</p>',
    en: '<p>Plus</p>',
    pt: '<p>Plus</p>',
    fr: '<p>Plus</p>',
    eus: '<p>Plus</p>',
    cat: '<p>Plus</p>',
  },
  hiring_Data164:{
    es: '<p>SI</p>',
    en: '<p>YES</p>',
    pt: '<p>YES</p>',
    fr: '<p>YES</p>',
    eus: '<p>YES</p>',
    cat: '<p>YES</p>',
  },
  hiring_Data165:{
    es: '<p>Matching huellas 2D</p>',
    en: '<p>2D Print Matching</p>',
    pt: '<p>2D Print Matching</p>',
    fr: '<p>2D Print Matching</p>',
    eus: '<p>2D Print Matching</p>',
    cat: '<p>2D Print Matching</p>',
  },
  hiring_Data166:{
    es: '<p>Caras</p>',
    en: '<p>Faces</p>',
    pt: '<p>Faces</p>',
    fr: '<p>Faces</p>',
    eus: '<p>Faces</p>',
    cat: '<p>Faces</p>',
  },
  hiring_Data167:{
    es: '<p>Energía deformación</p>',
    en: '<p>Deformation energy</p>',
    pt: '<p>Deformation energy</p>',
    fr: '<p>Deformation energy</p>',
    eus: '<p>Deformation energy</p>',
    cat: '<p>Deformation energy</p>',
  },
  hiring_Data168:{
    es: '<p>Proyección 3D</p>',
    en: '<p>3D Projection</p>',
    pt: '<p>3D Projection</p>',
    fr: '<p>3D Projection</p>',
    eus: '<p>3D Projection</p>',
    cat: '<p>3D Projection</p>',
  },
  hiring_Data169:{
    es: '<p>Salpicadura sangre</p>',
    en: '<p>Blood Spatter</p>',
    pt: '<p>Blood Spatter</p>',
    fr: '<p>Blood Spatter</p>',
    eus: '<p>Blood Spatter</p>',
    cat: '<p>Blood Spatter</p>',
  },
  hiring_Data170:{
    es: '<p>Trayectoria balas</p>',
    en: '<p>Bullet Trajectory</p>',
    pt: '<p>Bullet Trajectory</p>',
    fr: '<p>Bullet Trajectory</p>',
    eus: '<p>Bullet Trajectory</p>',
    cat: '<p>Bullet Trajectory</p>',
  },
  hiring_Data171:{
    es: '<p>Planos Interiores</p>',
    en: '<p>Interior Blueprints</p>',
    pt: '<p>Interior Blueprints</p>',
    fr: '<p>Interior Blueprints</p>',
    eus: '<p>Interior Blueprints</p>',
    cat: '<p>Interior Blueprints</p>',
  },
  hiring_Data172:{
    es: '<p>Interiores/Exteriores</p>',
    en: '<p>Interior/Exterior</p>',
    pt: '<p>Interior/Exterior</p>',
    fr: '<p>Interior/Exterior</p>',
    eus: '<p>Interior/Exterior</p>',
    cat: '<p>Interior/Exterior</p>',
  },
  hiring_Data173:{
    es: '<p>Visor Offline</p>',
    en: '<p>Offline Viewer</p>',
    pt: '<p>Offline Viewer</p>',
    fr: '<p>Offline Viewer</p>',
    eus: '<p>Offline Viewer</p>',
    cat: '<p>Offline Viewer</p>',
  },
  hiring_Data174:{
    es: '<p>Matching 3D</p>',
    en: '<p>3D Matching</p>',
    pt: '<p>3D Matching</p>',
    fr: '<p>3D Matching</p>',
    eus: '<p>3D Matching</p>',
    cat: '<p>3D Matching</p>',
  },
  hiring_Data175:{
    es: '<p>Grosor</p>',
    en: '<p><Thickness/p>',
    pt: '<p>Thickness</p>',
    fr: '<p>Thickness</p>',
    eus: '<p>Thickness</p>',
    cat: '<p>Thickness</p>',
  },
  hiring_Data176:{
    es: '<p>Sensórica</p>',
    en: '<p>Sensory</p>',
    pt: '<p>Sensory</p>',
    fr: '<p>Sensory</p>',
    eus: '<p>Sensory</p>',
    cat: '<p>Sensory</p>',
  },
  hiring_Data177:{
    es: '<p>Segmentación</p>',
    en: '<p>Segmentation</p>',
    pt: '<p>Segmentation</p>',
    fr: '<p>Segmentation</p>',
    eus: '<p>Segmentation</p>',
    cat: '<p>Segmentation</p>',
  },
  hiring_Data178:{
    es: '<p>Hiperespectrales</p>',
    en: '<p>Hyperspectral</p>',
    pt: '<p>Hyperspectral</p>',
    fr: '<p>Hyperspectral</p>',
    eus: '<p>Hyperspectral</p>',
    cat: '<p>Hyperspectral</p>',
  },
  hiring_Data179:{
    es: '<p>Colección imágenes - Seguridad</p>',
    en: '<p>Image Collection - Safety</p>',
    pt: '<p>Image Collection - Safety</p>',
    fr: '<p>Image Collection - Safety</p>',
    eus: '<p>Image Collection - Safety</p>',
    cat: '<p>Image Collection - Safety</p>',
  },
  hiring_Data180:{
    es: '<p>IFC</p>',
    en: '<p>IFC</p>',
    pt: '<p>IFC</p>',
    fr: '<p>IFC</p>',
    eus: '<p>IFC</p>',
    cat: '<p>IFC</p>',
  },
  hiring_Data181:{
    es: '<p>Calculo vegetación</p>',
    en: '<p>Vegetation Calculation</p>',
    pt: '<p>Vegetation Calculation</p>',
    fr: '<p>Vegetation Calculation</p>',
    eus: '<p>Vegetation Calculation</p>',
    cat: '<p>Vegetation Calculation</p>',
  },
  hiring_Data182:{
    es: '<p>Colección imágenes - Ingeniería</p>',
    en: '<p>Image Collection - Engineering</p>',
    pt: '<p>Image Collection - Engineering</p>',
    fr: '<p>Image Collection - Engineering</p>',
    eus: '<p>Image Collection - Engineering</p>',
    cat: '<p>Image Collection - Engineering</p>',
  },
  hiring_Data183:{
    es: '<p>Colección imágenes - Agricultura</p>',
    en: '<p>Image Collection - Agriculture</p>',
    pt: '<p>Image Collection - Agriculture</p>',
    fr: '<p>Image Collection - Agriculture</p>',
    eus: '<p>Image Collection - Agriculture</p>',
    cat: '<p>Image Collection - Agriculture</p>',
  },
  hiring_Data184:{
    es: '<p>NO</p>',
    en: '<p>NO</p>',
    pt: '<p>NO</p>',
    fr: '<p>NO</p>',
    eus: '<p>NO</p>',
    cat: '<p>NO</p>',
  },
  hiring_Data185:{
    es: '<p>TARIFA NUEVA</p>',
    en: '<p>NEW RATE</p>',
    pt: '<p>NEW RATE</p>',
    fr: '<p>NEW RATE</p>',
    eus: '<p>NEW RATE</p>',
    cat: '<p>NEW RATE</p>',
  },
  hiring_Data186:{
    es: '<p>FREE</p>',
    en: '<p>FREE</p>',
    pt: '<p>FREE</p>',
    fr: '<p>FREE</p>',
    eus: '<p>FREE</p>',
    cat: '<p>FREE</p>',
  },
  hiring_Data187:{
    es: '<p>PRO</p>',
    en: '<p>PRO</p>',
    pt: '<p>PRO</p>',
    fr: '<p>PRO</p>',
    eus: '<p>PRO</p>',
    cat: '<p>PRO</p>',
  },
  hiring_Data188:{
    es: '<p>PRO-I</p>',
    en: '<p>E-PRO</p>',
    pt: '<p>E-PRO</p>',
    fr: '<p>E-PRO</p>',
    eus: '<p>E-PRO</p>',
    cat: '<p>E-PRO</p>',
  },
  hiring_Data189:{
    es: '<p>PRO-S</p>',
    en: '<p>S-PRO</p>',
    pt: '<p>S-PRO</p>',
    fr: '<p>S-PRO</p>',
    eus: '<p>S-PRO</p>',
    cat: '<p>S-PRO</p>',
  },
  hiring_Data190:{
    es: '<p>PRO-A</p>',
    en: '<p>A-PRO</p>',
    pt: '<p>A-PRO</p>',
    fr: '<p>A-PRO</p>',
    eus: '<p>A-PRO</p>',
    cat: '<p>A-PRO</p>',
  },
  hiring_Data191:{
    es: '<p>PRO+</p>',
    en: '<p>PRO+</p>',
    pt: '<p>PRO+</p>',
    fr: '<p>PRO+</p>',
    eus: '<p>PRO+</p>',
    cat: '<p>PRO+</p>',
  },
  hiring_Data192:{
    es: '<p>BUS</p>',
    en: '<p>BUS</p>',
    pt: '<p>BUS</p>',
    fr: '<p>BUS</p>',
    eus: '<p>BUS</p>',
    cat: '<p>BUS</p>',
  },
    hiring_Data193:{
    es: '<p>BUS-I</p>',
    en: '<p>E-BUS</p>',
    pt: '<p>E-BUS</p>',
    fr: '<p>E-BUS</p>',
    eus: '<p>E-BUS</p>',
    cat: '<p>E-BUS</p>',
  }, 
   hiring_Data194:{
    es: '<p>BUS-S</p>',
    en: '<p>S-BUS</p>',
    pt: '<p>S-BUS</p>',
    fr: '<p>S-BUS</p>',
    eus: '<p>S-BUS</p>',
    cat: '<p>S-BUS</p>',
  }, 
   hiring_Data195:{
    es: '<p>BUS-A</p>',
    en: '<p>A-BUS</p>',
    pt: '<p>A-BUS</p>',
    fr: '<p>A-BUS</p>',
    eus: '<p>A-BUS</p>',
    cat: '<p>A-BUS</p>',
  }, 
   hiring_Data196:{
    es: '<p>BUS+</p>',
    en: '<p>BUS+</p>',
    pt: '<p>BUS+</p>',
    fr: '<p>BUS+</p>',
    eus: '<p>BUS+</p>',
    cat: '<p>BUS+</p>',
  }, 
   hiring_Data197:{
    es: '<p>FREE</p>',
    en: '<p>FREE</p>',
    pt: '<p>FREE</p>',
    fr: '<p>FREE</p>',
    eus: '<p>FREE</p>',
    cat: '<p>FREE</p>',
  }, 
   hiring_Data198:{
    es: '<p>PRO</p>',
    en: '<p>PRO</p>',
    pt: '<p>PRO</p>',
    fr: '<p>PRO</p>',
    eus: '<p>PRO</p>',
    cat: '<p>PRO</p>',
  },  
  hiring_Data199:{
    es: '<p>PRO INGENIERIA</p>',
    en: '<p>ENGINEERING PRO</p>',
    pt: '<p>ENGINEERING PRO</p>',
    fr: '<p>ENGINEERING PRO</p>',
    eus: '<p>ENGINEERING PRO</p>',
    cat: '<p>ENGINEERING PRO</p>',
  }, 
   hiring_Data200:{
    es: '<p>PRO SEGURIDAD</p>',
    en: '<p>SAFETY PRO</p>',
    pt: '<p>SAFETY PRO</p>',
    fr: '<p>SAFETY PRO</p>',
    eus: '<p>SAFETY PRO</p>',
    cat: '<p>SAFETY PRO</p>',
  },
  hiring_Data201:{
    es: '<p>PRO AGRICULTURA</p>',
    en: '<p>AGRICULTURE PRO</p>',
    pt: '<p>AGRICULTURE PRO</p>',
    fr: '<p>AGRICULTURE PRO</p>',
    eus: '<p>AGRICULTURE PRO</p>',
    cat: '<p>AGRICULTURE PRO</p>',
  },
  hiring_Data202:{
    es: '<p>PRO +</p>',
    en: '<p>PRO +</p>',
    pt: '<p>PRO +</p>',
    fr: '<p>PRO +</p>',
    eus: '<p>PRO +</p>',
    cat: '<p>PRO +</p>',
  },
  hiring_Data203:{
    es: '<p>BUSINESS</p>',
    en: '<p>BUSINESS</p>',
    pt: '<p>BUSINESS</p>',
    fr: '<p>BUSINESS</p>',
    eus: '<p>BUSINESS</p>',
    cat: '<p>BUSINESS</p>',
  },
  hiring_Data204:{
    es: '<p>BUSINESS INGENIERIA</p>',
    en: '<p>ENGINEERING BUSINESS</p>',
    pt: '<p>ENGINEERING BUSINESS</p>',
    fr: '<p>ENGINEERING BUSINESS</p>',
    eus: '<p>ENGINEERING BUSINESS</p>',
    cat: '<p>ENGINEERING BUSINESS</p>',
  },
  hiring_Data205:{
    es: '<p>BUSINESS SEGURIDAD</p>',
    en: '<p>SAFETY BUSINESS</p>',
    pt: '<p>SAFETY BUSINESS</p>',
    fr: '<p>SAFETY BUSINESS</p>',
    eus: '<p>SAFETY BUSINESS</p>',
    cat: '<p>SAFETY BUSINESS</p>',
  },
  hiring_Data206:{
    es: '<p>BUSINESS AGRICULTURA</p>',
    en: '<p>AGRICULTURE BUSINESS</p>',
    pt: '<p>AGRICULTURE BUSINESS</p>',
    fr: '<p>AGRICULTURE BUSINESS</p>',
    eus: '<p>AGRICULTURE BUSINESS</p>',
    cat: '<p>AGRICULTURE BUSINESS</p>',
  },
  hiring_Data207:{
    es: '<p>BUSINESS +</p>',
    en: '<p>BUSINESS +</p>',
    pt: '<p>BUSINESS +</p>',
    fr: '<p>BUSINESS +</p>',
    eus: '<p>BUSINESS +</p>',
    cat: '<p>BUSINESS +</p>',
  },
  hiring_Data208:{
    es: '<p>TARIFA ACTUAL</p>',
    en: '<p>CURRENT RATE</p>',
    pt: '<p>CURRENT RATE</p>',
    fr: '<p>CURRENT RATE</p>',
    eus: '<p>CURRENT RATE</p>',
    cat: '<p>CURRENT RATE</p>',
  },
  hiring_Data209:{
    es: '<p>+ 5000 MODELOS</p>',
    en: '<p>+ 5000 MODELS</p>',
    pt: '<p>+ 5000 MODELS</p>',
    fr: '<p>+ 5000 MODELS</p>',
    eus: '<p>+ 5000 MODELS</p>',
    cat: '<p>+ 5000 MODELS</p>',
  },
  hiring_Data210:{
    es: '<p>+ 50 MODELOS</p>',
    en: '<p>+ 50 MODELS</p>',
    pt: '<p>+ 50 MODELS</p>',
    fr: '<p>+ 50 MODELS</p>',
    eus: '<p>+ 50 MODELS</p>',
    cat: '<p>+ 50 MODELS</p>',
  },
  hiring_Data211:{
    es: '<p>19.00 €</p>',
    en: '<p>19.00 €</p>',
    pt: '<p>19.00 €</p>',
    fr: '<p>19.00 €</p>',
    eus: '<p>19.00 €</p>',
    cat: '<p>19.00 €</p>',
  },
  hiring_Data212:{
    es: '<p>+ 100 MODELS</p>',
    en: '<p>+ 100 MODELS</p>',
    pt: '<p>+ 100 MODELS</p>',
    fr: '<p>+ 100 MODELS</p>',
    eus: '<p>+ 100 MODELS</p>',
    cat: '<p>+ 100 MODELS</p>',
  },
  hiring_Data213:{
    es: '<p>29.00 €</p>',
    en: '<p>29.00 €</p>',
    pt: '<p>29.00 €</p>',
    fr: '<p>29.00 €</p>',
    eus: '<p>29.00 €</p>',
    cat: '<p>29.00 €</p>',
  },
  hiring_Data214:{
    es: '<p>+ 200 MODELOS</p>',
    en: '<p>+ 200 MODELS</p>',
    pt: '<p>+ 200 MODELS</p>',
    fr: '<p>+ 200 MODELS</p>',
    eus: '<p>+ 200 MODELS</p>',
    cat: '<p>+ 200 MODELS</p>',
  },
  hiring_Data215:{
    es: '<p>39.00 €</p>',
    en: '<p>39.00 €</p>',
    pt: '<p>39.00 €</p>',
    fr: '<p>39.00 €</p>',
    eus: '<p>39.00 €</p>',
    cat: '<p>39.00 €</p>',
  },
  hiring_Data216:{
    es: '<p>+ 500 MODELOS</p>',
    en: '<p>+ 500 MODELS</p>',
    pt: '<p>+ 500 MODELS</p>',
    fr: '<p>+ 500 MODELS</p>',
    eus: '<p>+ 500 MODELS</p>',
    cat: '<p>+ 500 MODELS</p>',
  },
  hiring_Data217:{
    es: '<p>49.00 €</p>',
    en: '<p>49.00 €</p>',
    pt: '<p>49.00 €</p>',
    fr: '<p>49.00 €</p>',
    eus: '<p>49.00 €</p>',
    cat: '<p>49.00 €</p>',
  },
  hiring_Data218:{
    es: '<p>+ 1000 MODELOS</p>',
    en: '<p>+ 1000 MODELS</p>',
    pt: '<p>+ 1000 MODELS</p>',
    fr: '<p>+ 1000 MODELS</p>',
    eus: '<p>+ 1000 MODELS</p>',
    cat: '<p>+ 1000 MODELS</p>',
  },
  hiring_Data219:{
    es: '<p>139.00 €</p>',
    en: '<p>139.00 €</p>',
    pt: '<p>139.00 €</p>',
    fr: '<p>139.00 €</p>',
    eus: '<p>139.00 €</p>',
    cat: '<p>139.00 €</p>',
  },
  hiring_Data220:{
    es: '<p>+ 2000 MODELOS</p>',
    en: '<p>+ 2000 MODELS</p>',
    pt: '<p>+ 2000 MODELS</p>',
    fr: '<p>+ 2000 MODELS</p>',
    eus: '<p>+ 2000 MODELS</p>',
    cat: '<p>+ 2000 MODELS</p>',
  },
  hiring_Data221:{
    es: '<p>249.00 €</p>',
    en: '<p>249.00 €</p>',
    pt: '<p>249.00 €</p>',
    fr: '<p>249.00 €</p>',
    eus: '<p>249.00 €</p>',
    cat: '<p>249.00 €</p>',
  },

  hiring_Data222:{
    es: '<p>549.00 €</p>',
    en: '<p>549.00 €</p>',
    pt: '<p>549.00 €</p>',
    fr: '<p>549.00 €</p>',
    eus: '<p>549.00 €</p>',
    cat: '<p>549.00 €</p>',
  },
};
