import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../../../core/services/loader.service';
import {Subscription} from 'rxjs';
import { LoaderInfo } from 'src/app/core/models/loader/loader-info';
import { LoaderTypeEnum } from 'src/app/core/models/loader/loader-type';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  windowHeight: number;
  loaderSubscribe$: Subscription;
  loaderInfo: LoaderInfo;
  loaderTypes = LoaderTypeEnum;

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderSubscribe$ = this.loaderService.isLoading().subscribe((loaderInfo) => {
      this.windowHeight = window.innerHeight;
      this.loaderInfo = loaderInfo;
    });
  }

  ngOnDestroy(): void {
    this.loaderSubscribe$.unsubscribe();
  }

  onResize() {
    this.windowHeight = window.innerHeight;
  }
}
