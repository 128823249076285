export const PAYMENT_DIALOGS_I18N_ENTRIES = {
  carga_Dialogo_data_1: {
    en: '<p>This 3D model</p><p><span class="i18n-bold">will be generated for free</span></p><p>by the platform.</p>',
    es: '<p>Este modelo 3D</p><p><span class="i18n-bold">será generado de manera gratuita</span></p><p>por la plataforma.</p>',
    pt: '<p>Este modelo 3D</p><p><span class="i18n-bold">será criado de forma gratuita</span></p><p>pela plataforma.</p>',
    fr: '<p>Ce modèle 3D</p><p><span class="i18n-bold">sera généré gratuitement</span></p><p>par la plateforme.</p>',
    eus: '<p>Plataformak</p><p><span class="i18n-bold">doanik sortuko du</span></p><p>3D eredu hau</p>',
    cat: '<p>La plataforma</p><p><span class="i18n-bold">generarà de manera gratuïta</span></p><p>aquest model 3D.</p>',
  },

  carga_Dialogo_data_2: {
    en: '<p>ACCEPT</p>',
    es: '<p>ACEPTAR</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  visores_Dialogos_Pagos_Data_3: {
    en: '<p>You do not have downloads available in the account to perform this action</p><p>If you want to purchase a download or voucher</p><p>click on PURCHASE.</p>',
    es: '<p>No tienes descargas disponibles en la cuenta para realizar esta acción</p><p>Si quieres comprar alguna descarga o bono</p><p>haz click en COMPRAR.</p>',
    pt: '<p>Não possui transferências disponíveis na conta para realizar esta ação</p><p>Se pretender comprar alguma transferência ou vale,</p><p>clique em COMPRAR.</p>',
    fr: '<p>Vous n’avez aucun téléchargement disponible sur votre compte pour réaliser cette action.</p><p>Si vous voulez acheter un téléchargement ou un bon,</p><p>cliquez sur ACHETER.</p>',
    eus: '<p>Ekintza ezin da burutu, ez duzu deskargarik eskuragarri zure kontuan.</p><p>Deskargak edo bonuak eskuratu nahi badituzu,</p><p>sakatu EROSI aukera.</p>',
    cat: '<p>No tens baixades disponibles al compte per fer aquesta acció.</p><p>Si vols comprar una baixada o bo</p><p>fes clic a COMPRA.</p>',
  },

  visores_Dialogos_Pagos_Data_4: {
    en: '<p>PURCHASE</p>',
    es: '<p>COMPRAR</p>',
    pt: '<p>COMPRAR</p>',
    fr: '<p>ACHETER</p>',
    eus: '<p>EROSI</p>',
    cat: '<p>COMPRA</p>',
  },

  visores_Dialogos_Pagos_Data_5: {
    en: '<p>You currently have %X% 3D model downloads</p><p>available in the account.</p><p>If you want to assign a download to this 3D model,</p><p>click on ASSIGN.</p>',
    es: '<p>Actualmente tienes disponibles %X% descargas</p><p>de MODELOS 3D en la cuenta.</p><p>Si deseas asignar una descarga para este modelo 3D</p><p>haz click en ASIGNAR.</p>',
    pt: '<p>Atualmente, tem disponíveis %X% transferências</p><p>de MODELOS 3D na conta.</p><p>Se pretender atribuir uma transferência a este modelo 3D,</p><p>clique em ATRIBUIR.</p>',
    fr: '<p>Vous disposez actuellement de %X% téléchargements</p><p>de MODÈLES 3D sur le compte.</p><p>Si vous voulez assigner un téléchargement pour ce modèle 3D,</p><p>cliquez sur ASSIGNER.</p>',
    eus: '<p>Une honetan, %X% 3D EREDUEN deskargak</p><p>dituzu eskuragarri zure kontuan.</p><p>3D eredu honi deskarga bat ezarri nahi badiozu,</p><p>sakatu EZARRI aukera.</p>',
    cat: '<p>Actualment tens disponibles %X% baixades</p><p>de MODELS 3D al compte.</p><p>Si vols assignar una baixada per a aquest model 3D,</p><p>fes clic a ASSIGNA.</p>',
  },

  visores_Dialogos_Pagos_Data_6: {
    en: '<p>If you want to assign a download to this 3D model, click on ASSIGN.</p>',
    es: '<p>Si deseas asignar una descarga para este modelo 3D haz click en ASIGNAR.</p>',
    pt: '<p>Se pretender atribuir uma transferência a este modelo 3D, clique em ATRIBUIR.</p>',
    fr: '<p>Si vous voulez assigner un téléchargement pour ce modèle 3D, cliquez sur ASSIGNER.</p>',
    eus: '<p>3D eredu honi deskarga bat ezarri nahi badiozu, sakatu EZARRI aukera.</p>',
    cat: '<p>Si vols assignar una baixada per a aquest model 3D, fes clic a ASSIGNA.</p>',
  },

  visores_Dialogos_Pagos_Data_7: {
    en: '<p>ASSIGN</p>',
    es: '<p>ASIGNAR</p>',
    pt: '<p>ATRIBUIR</p>',
    fr: '<p>ASSIGNER</p>',
    eus: '<p>EZARRI</p>',
    cat: '<p>ASSIGNA</p>',
  },

  visores_Dialogos_Pagos_Data_8: {
    en: '<p>You currently have %X% <span class="i18n-bold">4pro3Dmodel</span></p><p>models available in the account.</p>',
    es: '<p>Actualmente tienes disponibles %X%</p><p>modelos <span class="i18n-bold">4pro3Dmodel</span> en la cuenta.</p>',
    pt: '<p>Atualmente, tem disponíveis %X%</p><p>modelos <span class="i18n-bold">4pro3Dmodel</span> na conta.</p>',
    fr: '<p>Vous disposez actuellement de %X%</p><p>modèles <span class="i18n-bold">4pro3Dmodel</span> sur le compte.</p>',
    eus: '<p>Une honetan %X% <span class="i18n-bold">4pro3Dmodel</span> eredu</p><p>dituzu eskuragarri zure kontuan.</p>',
    cat: '<p>Actualment tens disponibles %X%</p><p>models <span class="i18n-bold">4pro3Dmodel</span> al compte.</p>',
  },

  visores_Dialogos_Pagos_Data_9: {
    en: '<p>If you want to assign one of these <span class="i18n-bold">4pro3Dmodel</span> models,</p><p>click on ASSIGN.</p>',
    es: '<p>Si deseas asignar uno de estos modelos <span class="i18n-bold">4pro3Dmodel</span></p><p>haz click en ASIGNAR.</p>',
    pt: '<p>Se pretender atribuir um desses modelos <span class="i18n-bold">4pro3Dmodel</span>,</p><p>clique em ATRIBUIR.</p>',
    fr: '<p></p><p>Si vous voulez assigner un de ces modèles <span class="i18n-bold">4pro3Dmodel</span>,</p><p>cliquez sur ASSIGNER.</p>',
    eus: '<p></p><p><span class="i18n-bold">4pro3Dmodel</span> eredu horietako bat ezarri nahi baduzu,</p><p>sakatu EZARRI aukera.</p>',
    cat: '<p>Si vols assignar un d\'aquests models <span class="i18n-bold">4pro3Dmodel</span>,</p><p>fes clic a ASSIGNA.</p>',
  },

  visores_Dialogos_Pagos_Data_10: {
    en: '<p>ASSIGN</p>',
    es: '<p>ASIGNAR</p>',
    pt: '<p>ATRIBUIR</p>',
    fr: '<p>ASSIGNER</p>',
    eus: '<p>EZARRI</p>',
    cat: '<p>ASSIGNA</p>',
  },

  visores_Dialogos_Pagos_Data_11: {
    en: '<p>You do not have <span class="i18n-bold">4pro3Dmodel</span> models available in</p><p>the account to perform this action</p><p></p><p>If you want to purchase a <span class="i18n-bold">4pro3Dmodel</span> model to be generated</p><p>click on PURCHASE.</p>',
    es: '<p>No tienes modelos <span class="i18n-bold">4pro3Dmodel</span> disponibles en</p><p>la cuenta para realizar esta acción</p><p></p><p>Si quieres comprar la generación de un modelo <span class="i18n-bold">4pro3Dmodel </span></p><p>haz click en COMPRAR.</p>',
    pt: '<p>Não possui modelos <span class="i18n-bold">4pro3Dmodel</span> disponíveis na</p><p>conta para realizar esta ação</p><p></p><p>Se pretender comprar a criação de um modelo <span class="i18n-bold">4pro3Dmodel</span>, </p><p>clique em COMPRAR.</p>',
    fr: '<p>Vous n’avez aucun modèle <span class="i18n-bold">4pro3Dmodel </span>disponible sur </p><p>votre compte pour réaliser cette action.</p><p></p><p>Si vous voulez acheter la génération d’un modèle <span class="i18n-bold">4pro3Dmodel</span>,</p><p>cliquez sur ACHETER.</p>',
    eus: '<p>Ekintza ezin da burutu, ez duzu <span class="i18n-bold">4pro3Dmodel</span> eredurik</p><p>eskuragarri zure kontuan</p><p></p><p><span class="i18n-bold">4pro3Dmodel</span> eredu bat sortu nahi baduzu,</p><p>sakatu EROSI aukera.</p>',
    cat: '<p>No tens models <span class="i18n-bold">4pro3Dmodel</span> disponibles</p><p>al compte per fer aquesta acció.</p><p></p><p>Si vols comprar la generació d’un model <span class="i18n-bold">4pro3Dmodel</span>,</p><p>fes clic a COMPRA.</p>',
  },

  visores_Dialogos_Pagos_Data_12: {
    en: '<p>PURCHASE</p>',
    es: '<p>COMPRAR</p>',
    pt: '<p>COMPRAR</p>',
    fr: '<p>ACHETER</p>',
    eus: '<p>EROSI</p>',
    cat: '<p>COMPRA</p>',
  },

  visores_Dialogos_Pagos_Data_13: {
    en: '<p>We were not able to confirm that the 3D model is your property,</p><p>the actions you perform will not be saved,</p><p>and you will not be able to download it.</p>',
    es: '<p>No hemos podido comprobar que el modelo 3D es de tu propiedad,</p><p>no se guardarán las acciones que realices</p><p>ni podrás descargarlo.</p>',
    pt: '<p>Não conseguimos comprovar que o modelo 3D é propriedade sua,</p><p>pelo que as ações que realizar não serão guardadas,</p><p>nem poderá transferir o modelo.</p>',
    fr: '<p>Nous n’avons pas pu vérifier que vous êtes propriétaire du modèle 3D,</p><p>vos actions ne seront pas enregistrées</p><p>et vous ne pourrez pas le télécharger.</p>',
    eus: '<p>Ezin izan dugu egiaztatu 3D eredu honen jabea zarenik,</p><p>bertan burututako ekintzak ez dira gordeko eta</p><p>ezin izango duzu eredua deskargatu.</p>',
    cat: '<p>No hem pogut comprovar que el model 3D és de la teva propietat,</p><p>no es desaran les accions que facis</p><p>ni el podràs baixar.</p>',
  },

  visores_Dialogos_Pagos_Data_14: {
    en: '<p>ACCEPT</p>',
    es: '<p>ACEPTAR</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  visores_Dialogos_Pagos_Data_15: {
    en: '<p>Please, log in to the platform to save</p><p>the actions and download the 3D model.</p>',
    es: '<p>Por favor, haz log in en la plataforma para guardar</p><p>las acciones y poder descargar el modelo 3D.</p>',
    pt: '<p>Inicie sessão na plataforma para guardar</p><p>as ações e poder transferir o modelo 3D.</p>',
    fr: '<p>Veuillez vous connecter à la plateforme pour enregistrer</p><p>vos actions et télécharger le modèle 3D.</p>',
    eus: '<p>Sartu plataforman zure erabiltzaile kontuarekin,</p><p>burututako ekintzak gorde eta 3D eredua deskargatu ahal izateko.</p>',
    cat: '<p>Si us plau, inicia la sessió en la plataforma per desar</p><p>les accions i poder baixar el model 3D.</p>',
  },

  visores_Dialogos_Pagos_Data_16: {
    en: '<p>ACCEPT</p>',
    es: '<p>ACEPTAR</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  visores_Dialogos_Pagos_Data_17: {
    en: '<p>This 3D model is not your property,</p><p>the actions you perform will not be saved,</p><p>and you will not be able to download it.</p>',
    es: '<p>Este modelo 3D no es de tu propiedad,</p><p>no se guardarán las acciones que realices</p><p>ni podrás descargarlo.</p>',
    pt: '<p>Este modelo 3D não é propriedade sua,</p><p>pelo que as ações que realizar não serão guardadas,</p><p>nem poderá transferir o modelo.</p>',
    fr: '<p>Ce modèle 3D ne vous appartient pas.</p><p>Vos actions ne seront pas enregistrées</p><p>et vous ne pourrez pas le télécharger.</p>',
    eus: '<p>Zu ez zara 3D eredu honen jabea,</p><p>bertan burututako ekintzak ez dira gordeko eta</p><p>ezin izango duzu eredua deskargatu.</p>',
    cat: '<p>Aquest model 3D no és de la teva propietat,</p><p>no es desaran les accions que facis</p><p>ni el podràs baixar.</p>',
  },

  visores_Dialogos_Pagos_Data_18: {
    en: '<p>ACCEPT</p>',
    es: '<p>ACEPTAR</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  visores_Dialogos_Pagos_Data_19: {
    es: '<p>Tu plan actual no permite descargas.</p>',
    en: '<p>You cannot download models with your current plan.</p>',
    pt: '<p>O seu plano atual não permite transferências.</p>',
    fr: '<p>Votre forfait ne permet pas les téléchargements.</p>',
    eus: '<p>Zure planarekin ezin duzu deskargarik egin.</p>',
    cat: '<p>El teu pla actual no permet fer baixades.</p>',
  },


  visores_Dialogos_Pagos_Data_20: {
    es: '<p>ACTUALIZAR PLAN</p>',
    en: '<p>UPDATE YOUR PLAN</p>',
    pt: '<p>ATUALIZAR PLANO</p>',
    fr: '<p>ACTUALISER FORFAIT</p>',
    eus: '<p>HOBETU PLANA</p>',
    cat: '<p>ACTUALITZA EL PLA</p>',
  },
};

