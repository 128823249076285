import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/commons/models/user/user';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { RestClientService } from '../../api-access/rest-client.service';
import { CommsService } from '../../comms.service';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private URL = '/userSession';
  private URL1 = '/userSessionNavigation';

  sessionId: number;
  interval: NodeJS.Timeout;
  user: User;
  mutex: boolean = false;

  routeChanges: Subscription;

  constructor(
    private restClientService: RestClientService,
    private commsService: CommsService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.commsService.getCurrentUser().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.updateNavigationEntry();
        this.listenForRouteChanges();
        this.initializeUpdateInterval();
      }
    });
    this.authenticationService.onLogout().subscribe(() => {
      this.user = null;
      this.sessionId = null;
      this.cancelUpdateInterval();
    });
  }

  private listenForRouteChanges() {
    if (this.routeChanges) {
      this.routeChanges.unsubscribe();
    }
    this.routeChanges = this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.processUrlChange(route.url);
      }
    });
  }

  public createNewSession() {
    if (!this.mutex) {
      this.mutex = true;
      return new Observable((subscriber) => {
        this.restClientService
          .post(`${this.URL}`, null)
          .subscribe((session: { id: number }) => {
            this.mutex = false;
            this.sessionId = session.id;
            subscriber.next();
            subscriber.complete();
          });
      });
    }
  }

  public processUrlChange(url: string) {
    if (this.user) {
      this.updateNavigationEntry()?.subscribe(() => {
        this.createNavigationEntry(url).subscribe(() => {
          this.cancelUpdateInterval();
          this.initializeUpdateInterval();
        });
      });
    }
  }

  public updateNavigationEntry() {
    if (this.sessionId) {
      return this.restClientService.put(
        `${this.URL1}/exit/${this.sessionId}`,
        null
      );
    } else {
      this.createNewSession()?.subscribe(() => {
        return this.restClientService.put(
          `${this.URL1}/exit/${this.sessionId}`,
          null
        );
      });
    }
  }

  private createNavigationEntry(url: string) {
    const body = {
      url: url,
      user_session_id: this.sessionId,
      modelo: url.startsWith('/viewer/'),
    };
    return this.restClientService.post(`${this.URL1}/entry`, body);
  }

  private initializeUpdateInterval() {
    this.cancelUpdateInterval();
    this.interval = setInterval(() => {
      this.updateNavigationEntry().subscribe(() => {});
    }, 10000);
  }

  private cancelUpdateInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
