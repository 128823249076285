export const COOKIE_DIALOG_I18N_ENTRIES = {
  cookie_Dialog_Data1: {
    es: '<p>Utilizamos cookies propias y de terceros para</p><p>obtener datos estadísticos de la navegación</p><p>de nuestros usuarios y mejorar nuestros</p><p>servicios.</p>',
    en: '<p>We use our own and third-party cookies to</p><p>obtain statistical data</p><p>on our users\' browsing preferences</p><p>and to improve our services.</p>',
    pt: '<p>Utilizamos cookies próprios e de terceiros para</p><p>obter dados estatísticos sobre a navegação</p><p>dos nossos utilizadores e para melhorar os nossos</p><p>serviços.</p>',
    fr: '<p>Nous utilisons nos propres cookies ainsi que</p><p>ceux de tiers pour obtenir des données</p><p>statistiques sur les préférences de navigation</p><p>de nos utilisateurs, ainsi que pour améliorer</p><p>nos services.</p>',
    eus: '<p>Geure eta hirugarrenen cookieak erabiltzen ditugu</p><p>gure erabiltzaileen nabigazioari buruzko</p><p>datu estatistikoak lortzeko eta gure</p><p>zerbitzuak hobetzeko.</p>',
    cat: '<p>Fem servir galetes pròpies i de tercers</p><p>per obtenir dades estadístiques de la navegació</p><p>dels nostres usuaris i millorar</p><p>els nostres serveis.</p>',
  },

  cookie_Dialog_Data2: {
    es: '<p>Utilizamos cookies propias y de terceros para</p><p>obtener datos estadísticos de la navegación de</p><p>nuestros usuarios y mejorar nuestros servicios.</p>',
    en: '<p>We use our own and third-party cookies to</p><p>obtain statistical data on our users\' browsing</p><p>preferences and to improve our services.</p>',
    pt: '<p>Utilizamos cookies próprios e de terceiros para</p><p>obter dados estatísticos sobre a navegação dos</p><p>nossos utilizadores e para melhorar os nossos serviços.</p>',
    fr: '<p>Nous utilisons nos propres cookies ainsi que</p><p>ceux de tiers pour obtenir des données</p><p>statistiques sur les préférences de navigation</p><p>de nos utilisateurs, ainsi que pour améliorer</p><p>nos services.</p>',
    eus: '<p>Geure eta hirugarrenen cookieak erabiltzen ditugu</p><p>gure erabiltzaileen nabigazioari buruzko datu</p><p>estatistikoak lortzeko eta gure zerbitzuak hobetzeko.</p>',
    cat: '<p>Fem servir galetes pròpies i de tercers per</p><p>obtenir dades estadístiques de la navegació</p><p>dels nostres usuaris i millorar els nostres serveis.</p>',
  },

  cookie_Dialog_Data3: {
    es: '<p>Si aceptas o continúas navegando, consideramos que aceptas su uso.</p><p>Puedes obtener más información <a href="https://eyescloud3d.com/cookies" target="_blank">aquí</a></p>',
    en: '<p>If you accept or continue to use our website, you will be deemed to have accepted the use of cookies.</p><p>You can get more information <a href="https://eyescloud3d.com/us/cookies" target="_blank">here</a></p>',
    pt: '<p>Se aceitar ou continuar a navegar, consideramos que aceita a utilização dos mesmos.</p><p>Pode obter mais informações <a href="https://eyescloud3d.com/pt/cookies" target="_blank">aqui</a></p>',
    fr: '<p>Si vous acceptez ou que vous continuez à naviguer, nous considérons que vous acceptez leur utilisation.</p><p>Vous pouvez obtenir plus d\'informations<a href="https://eyescloud3d.com/fr/cookies" target="_blank">ici</a></p>',
    eus: '<p>Baietza ematen baduzu edo nabigatzen jarraitzen baduzu, ulertuko dugu haien erabilpena onartzen duzula.</p><p>Konfigurazioa aldatzeko edo informazio gehiago lortzeko, sakatu <a href="https://eyescloud3d.com/us/cookies" target="_blank">hemen</a></p>',
    cat: '<p>Si acceptes o continues navegant, considerarem que n\'acceptes l\'ús.</p><p>Pots canviar la configuració o obtenir més informació <a href="https://eyescloud3d.com/cookies" target="_blank">aquí.</a></p>',
  },

  cookie_Dialog_Data4: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },
};
