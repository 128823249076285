export const APP_I18N_ENTRIES = {
  password: {
    es: '<p>contraseña</p>',
    en: '<p>password</p>',
    pt: '<p>palavra-passe</p>',
    fr: '<p>mot de passe</p>',
    eus: '<p>pasahitza</p>',
    cat: '<p>contrasenya</p>',
  },

  userProfile: {
    es: '<p>Perfil usuario</p>',
    en: '<p>User profile</p>',
    pt: '<p>Perfil utilizador</p>',
    fr: '<p>Profil utilisateur</p>',
    eus: '<p>Erabiltzaile-profila</p>',
    cat: '<p>Perfil d’usuari</p>',
  },

  myAccount: {
    es: '<p>Mi Cuenta</p>',
    en: '<p>My Account</p>',
    pt: '<p>A Minha Conta</p>',
    fr: '<p>Mon compte</p>',
    eus: '<p>Nire kontua</p>',
    cat: '<p>El meu compte</p>',
  },

  openOnlineChat: {
    es: '<p>Abrir chat online</p>',
    en: '<p>Open online chat</p>',
    pt: '<p>Abrir chat online</p>',
    fr: '<p>Lancer chat en ligne</p>',
    eus: '<p>Ireki sareko txata</p>',
    cat: '<p>Obre un xat en línia</p>',
  },

  chatOnline: {
    es: '<p>Chat online</p>',
    en: '<p>Online chat</p>',
    pt: '<p>Chat online</p>',
    fr: '<p>Chat en ligne</p>',
    eus: '<p>Sareko txata</p>',
    cat: '<p>Xat en línia</p>',
  },

  chatOnlineClose: {
    es: '<p>Cerrar chat</p>',
    en: '<p>Close chat</p>',
    pt: '<p>Fechar chat</p>',
    fr: '<p>Fechar chat</p>',
    eus: '<p>Itxi txata</p>',
    cat: '<p>Tanca el xat</p>',
  },

  accept: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },

  email: {
    es: '<p>Correo Electrónico</p>',
    en: '<p>Email</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>E-mail</p>',
    eus: '<p>Helbide elektronikoa</p>',
    cat: '<p>Adreça electrònica</p>',
  },

  newPasswordTitle: {
    es: '<p>Escribe tu nueva contraseña</p>',
    en: '<p>Enter your new password</p>',
    pt: '<p>Escreva a sua nova palavra-passe</p>',
    fr: '<p>Saisissez votre nouveau mot de passe</p>',
    eus: '<p>Idatzi zure pasahitz berria</p>',
    cat: '<p>Escriu la contrasenya nova</p>',
  },

  newPasswordText: {
    es: '<p>Por favor, introduce nueva contraseña </p><p>en ambos campos. </p><p>Después, haz click en <span class="i18n-bold">RESTABLECER</span></p><p><span class="i18n-bold">CONTRASEÑA</span> y podrás registrarte con la </p><p>nueva contraseña.</p>',
    en: '<p>Please, enter your new password in</p><p>both fields.</p><p>Next, click on <span class="i18n-bold">RESET PASSWORD</span></p><p>to log in with your</p><p>new password.</p>',
    pt: '<p>Introduza a nova palavra-passe em</p><p>ambos os campos.</p><p>Em seguida, clique em <span class="i18n-bold">REPOR</span></p><p><span class="i18n-bold">PALAVRA-PASSE</span> e poderá iniciar sessão com a</p><p>nova palavra-passe.</p>',
    fr: '<p>Veuillez saisir votre nouveau mot de passe dans les</p><p>deux champs.</p><p>Ensuite, cliquez sur <span class="i18n-bold">RÉTABLIR</span></p><p><span class="i18n-bold">MOT DE PASSE</span> et vous pourrez vous enregistrer avec le nouveau</p><p>mot de passe.</p>',
    eus: '<p>Mesedez, sartu pasahitz berria</p><p>bi eremutan.</p><p>Gero, sakatu <span class="i18n-bold">BERREZARRI</span></p><p><span class="i18n-bold">PASAHITZA</span> eta erregistratu pasahitz</p><p>berriarekin.</p>',
    cat: '<p>Escriu la nova contrasenya</p><p>en els dos camps.</p><p>Després, fes clic a <span class="i18n-bold">RESTABLEIX LA</span></p><p><span class="i18n-bold">CONTRASENYA</span> i podràs registrar-te</p><p>amb la nova contrasenya.</p>',
  },

  errorTokenText: {
    es: '<p>Error, el token no es válido</p>',
    en: '<p>Error, the token provided is not valid</p>',
    pt: '<p>Erro, o token não é válido</p>',
    fr: '<p>Erreur : le token n\'est pas valide</p>',
    eus: '<p>Errorea, tokenak ez du balio</p>',
    cat: '<p>Error, el testimoni no és vàlid</p>',
  },

  errorNotActivatedText: {
    es: '<p>Error, la cuenta no ha sido activada</p>',
    en: '<p>Error, the account hasn\'t been activated</p>',
    pt: '<p>Erro, a conta não foi ativada</p>',
    fr: '<p>Erreur : le compte n\'a pas été activé</p>',
    eus: '<p>Errorea, ezin izan da kontua aktibatu</p>',
    cat: '<p>Error, el compte no s’ha activat</p>',
  },

  resetPassword: {
    es: '<p>Restablecer contraseña</p>',
    en: '<p>Reset password</p>',
    pt: '<p>Restabelecer palavra-passe</p>',
    fr: '<p>Repor palavra-passe</p>',
    eus: '<p>Berrezarri pasahitza</p>',
    cat: '<p>Restableix la contrasenya</p>',
  },

  pointCloud: {
    es: '<p>Nube de puntos</p>',
    en: '<p>Point cloud</p>',
    pt: '<p>Nuvem de pontos</p>',
    fr: '<p>Nuage de points</p>',
    eus: '<p>Puntu hodeia</p>',
    cat: '<p>Núvol de punts</p>',
  },

  mesh: {
    es: '<p>Malla</p>',
    en: '<p>Mesh</p>',
    pt: '<p>Malha</p>',
    fr: '<p>Maille</p>',
    eus: '<p>Sarea</p>',
    cat: '<p>Malla</p>',
  },

  videos: {
    es: '<p>vídeos</p>',
    en: '<p>videos</p>',
    pt: '<p>vídeos</p>',
    fr: '<p>vidéos</p>',
    eus: '<p>bideoak</p>',
    cat: '<p>vídeos</p>',
  },

  photos: {
    es: '<p>Fotos</p>',
    en: '<p>Photos</p>',
    pt: '<p>Fotografias</p>',
    fr: '<p>Photos</p>',
    eus: '<p>Argazkiak</p>',
    cat: '<p>Fotos</p>',
  },

  myRates: {
    es: '<p>Tarifas</p>',
    en: '<p>Plans</p>',
    pt: '<p>Tarifas</p>',
    fr: '<p>Tarifs</p>',
    eus: '<p>Tarifak</p>',
    cat: '<p>Tarifes</p>',
  },

  modelGeneration: {
    es: '<p>Generación modelo 3D</p>',
    en: '<p>3D model generation</p>',
    pt: '<p>Criação de modelo 3D</p>',
    fr: '<p>Génération modèle 3D</p>',
    eus: '<p>3D eredua sortzea</p>',
    cat: '<p>Generació de model 3D</p>',
  },

  rate_desc_1_model: {
    es: '<p>Modelos 3D de 5 a 50 fotoso hasta 1 min de vídeo</p>',
    en: '<p>3D models from 5 to 50 photos or up to 1 min of video</p>',
    pt: '<p>Modelos 3D de 5 a 50 fotografias ou até 1 min de vídeo</p>',
    fr: '<p>Modèles 3D de 5 à 50 photos ou vidéo de 1 min maximum</p>',
    eus: '<p>5 eta 50 argazki bitarte edo gehienez 1 min-ko bideoa erabiliz sortutako 3D ereduak</p>',
    cat: '<p>Models 3D de 5 a 50 fotos o fins a 1 min de vídeo</p>',
  },

  rate_desc_business: {
    es: '<p>Generación de modelos 3D y descarga</p><p>1000 modelos 3D de 5 a 50 fotos o hasta 1 min de vídeo</p>',
    en: '<p>3D model generation and downloads</p><p>1,000 3D models from 5 to 50 photos or up to 1 min of video</p>',
    pt: '<p>Criação de modelos 3D e transferência</p><p>1000 modelos 3D de 5 a 50 fotografias ou até 1 min de vídeo</p>',
    fr: '<p>Génération de modèles 3D et téléchargements</p><p>1 000 modèles 3D de 5 à 50 photos ou vidéo de 1 min maximum</p>',
    eus: '<p>3D ereduak sortu eta deskargatu 1000 3D eredu 5</p><p>eta 50 argazki bitarte edo gehienez 1 min-ko bideoa erabiliz</p>',
    cat: '<p>Generació de models 3D i baixada</p><p>1000 models 3D de 5 a 50 fotos o fins a 1 min de vídeo</p>',
  },

  rate_desc_big_1_model: {
    es: '<p>Modelos 3D de 50 a 200 fotos o hasta 4 min de vídeo</p>',
    en: '<p>3D models from 50 to 200 photos or up to 4 min of video</p>',
    pt: '<p>Modelos 3D de 50 a 200 fotografias ou até 4 min de vídeo</p>',
    fr: '<p>Modèles 3D de 50 à 200 photos ou vidéo de 4 min maximum</p>',
    eus: '<p>50 eta 200 argazki bitarte edo gehienez 4 min-ko bideoa erabiliz sortutako 3D ereduak</p>',
    cat: '<p>Models 3D de 50 a 200 fotos o fins a 4 min de vídeo</p>',
  },

  rate_desc_big_2_model: {
    es: '<p>Generación de modelos 3D y descarga</p><p>*Hasta 15 modelos al mes</p><p>Modelos 3D de 50 a 200 fotos o hasta 4 min de vídeo</p>',
    en: '<p>3D model generation and downloads</p><p>*Up to 15 models per month</p><p>3D models from 50 to 200 photos or up to 4 min of video</p>',
    pt: '<p>Criação de modelos 3D e transferência</p><p>*Até 15 modelos por mês</p><p>Modelos 3D de 50 a 200 fotografias ou até 4 min de vídeo</p>',
    fr: '<p>Génération de modèles 3D et téléchargements</p><p>*Jusqu\'à 15 modèles par mois</p><p>Modèles 3D de 50 à 200 photos ou vidéo de 4 min maximum</p>',
    eus: '<p>3D ereduak sortu eta deskargatu</p><p>*Hilero 15 eredu arte</p><p>50 eta 200 argazki bitarte edo gehienez 4 min-ko bideoa erabiliz sortutako 3D ereduak</p>',
    cat: '<p>Generació de models 3D i baixada</p><p>* Fins a 15 models al mes</p><p>Models 3D de 50 a 200 fotos o fins a 4 min de vídeo</p>',
  },

  rate_desc_big_3_model: {
    es: '<p>Generación de modelos 3D y descarga</p><p>*Hasta 200 modelos al mes</p><p>Modelos 3D de 50 a 200 fotos o hasta 4 min de vídeo</p>',
    en: '<p>3D model generation and downloads</p><p>*Up to 200 models per year</p><p>3D models from 50 to 200 photos or up to 4 min of video</p>',
    pt: '<p>Criação de modelos 3D e transferência</p><p>*Até 200 modelos por mês</p><p>Modelos 3D de 50 a 200 fotografias ou até 4 min de vídeo</p>',
    fr: '<p>Génération de modèles 3D et téléchargements</p><p>*Jusqu\'à 200 modèles par mois</p><p>Modèles 3D de 50 à 200 photos ou vidéo de 4 min maximum</p>',
    eus: '<p>3D ereduak sortu eta deskargatu</p><p>*Hilero 200 eredu arte</p><p>50 eta 200 argazki bitarte edo gehienez 4 min-ko bideoa erabiliz sortutako 3D ereduak</p>',
    cat: '<p>Generació de models 3D i baixada</p><p>* Fins a 200 models al mes</p><p>Models 3D de 50 a 200 fotos o fins a 4 min de vídeo</p>',
  },

  rate_desc_big_4_model: {
    es: '<p>Generación de modelos 3D y descarga</p><p>1000 modelos 3D entre 5 y 50 fotos o hasta 1 min de vídeo</p><p>200 modelos 3D entre 50 y 200 fotos o</p><p>hasta 4 min de vídeo</p>',
    en: '<p>3D model generation and downloads</p><p>1,000 3D models from 5 to 50 photos or up to 1 min of video</p><p>200 3D models between 50 and 200 photos or</p><p>up to 4 min of video</p>',
    pt: '<p>Criação de modelos 3D e transferência</p><p>1000 modelos 3D de 5 a 50 fotografias ou até 1 min de vídeo</p><p>200 modelos 3D de 50 a 200 fotografias ou</p><p>até 4 min de vídeo</p>',
    fr: '<p>Génération de modèles 3D et téléchargements</p><p>1 000 modèles 3D de 5 à 50 photos ou vidéo de 1 min maximum</p><p>200 modèles 3D de 50 à 200 photos ou</p><p>vidéo de 4 min maximum</p>',
    eus: '<p>3D ereduak sortu eta deskargatu</p><p>1000 3D eredu 5 eta 50 argazki bitarte edo gehienez minutu bateko bideoa erabiliz</p><p>200 3D eredu 50 eta 200 argazki bitarte edo</p><p>gehienez 4 min-ko bideoak erabiliz</p>',
    cat: '<p>Generació de models 3D i baixada</p><p>1000 models 3D entre 5 i 50 fotos o fins a 1 min de vídeo</p><p>200 models 3D entre 50 i 200 fotos o</p><p>fins a 4 min de vídeo</p>',
  },

  emptyPayments: {
    es: '<p>No se han realizado pagos.</p>',
    en: '<p>No payments have been made.</p>',
    pt: '<p>Não foram efetuados pagamentos.</p>',
    fr: '<p>Aucun paiement n\'a été effectué.</p>',
    eus: '<p>Ez duzu ordainketarik egin.</p>',
    cat: '<p>No s\'han efectuat pagaments.</p>',
  },

  emptyPaymentsDesc: {
    es: '<p>Es momento de personalizar la tarifa a tus necesidades.</p><p>Genera y descarga modelos 3D con bonos y planes adaptados.</p>',
    en: '<p>It\'s time to customize your plan to meet your needs.</p><p>Generate and download 3D models with vouchers and tailored plans.</p>',
    pt: '<p>Está na hora de personalizar a tarifa de acordo com as suas necessidades.</p><p>Crie e transfira modelos 3D com vales e planos personalizados.</p>',
    fr: '<p>Il est temps de personnaliser votre tarif selon vos besoins.</p><p>Générez et téléchargez des modèles 3D avec des abonnements et des forfaits adaptés à vos besoins.</p>',
    eus: '<p>Daukazun tarifa zure beharretara egokitzeko unea ailegatu da.</p><p>Sortu eta deskargatu 3D ereduak, zuri egokitutako bono eta planekin.</p>',
    cat: '<p>És el moment de personalitzar la tarifa segons les teves necessitats.</p><p>Genera i baixa models 3D amb bons i plans adaptats.</p>',
  },

  emptyPaymentButton: {
    es: '<p>SELECCIONA TU TARIFA</p>',
    en: '<p>SELECT YOUR PLAN</p>',
    pt: '<p>SELECIONE A SUA TARIFA</p>',
    fr: '<p>SÉLECTIONNEZ VOTRE TARIF</p>',
    eus: '<p>AUKERATU ZURE TARIFA</p>',
    cat: '<p>SELECCIONA LA TEVA TARIFA</p>',
  },

  downloadAllPayments: {
    es: '<p>>> Descargar todas las facturas</p>',
    en: '<p>>> Download all invoices</p>',
    pt: '<p>>> Transferir todas as faturas</p>',
    fr: '<p>>> Télécharger toutes les factures</p>',
    eus: '<p>>> Deskargatu faktura guztiak</p>',
    cat: '<p>>> Baixa totes les factures</p>',
  },

  bonusDownload: {
    es: '<p>Bono descarga</p>',
    en: '<p>Download voucher</p>',
    pt: '<p>Vale transferência</p>',
    fr: '<p>Bon de téléchargement</p>',
    eus: '<p>Deskarga-bonua</p>',
    cat: '<p>Bo de baixada</p>',
  },

  model: {
    es: '<p>modelo 3D</p>',
    en: '<p>3D model</p>',
    pt: '<p>modelo 3D</p>',
    fr: '<p>modèle 3D</p>',
    eus: '<p>3D eredua</p>',
    cat: '<p>model 3D</p>',
  },

  models: {
    es: '<p>modelos</p>',
    en: '<p>models</p>',
    pt: '<p>modelos</p>',
    fr: '<p>modèles</p>',
    eus: '<p>ereduak</p>',
    cat: '<p>models</p>',
  },

  businessPlan: {
    es: '<p>plan empresarial</p>',
    en: '<p>company plan</p>',
    pt: '<p>plano empresarial</p>',
    fr: '<p>Plan entreprise</p>',
    eus: '<p>enpresa plana</p>',
    cat: '<p>pla empresarial</p>',
  },

  totalBusinessPlan: {
    es: '<p>plan empresarial total</p>',
    en: '<p>Total company plan</p>',
    pt: '<p>plano empresarial total</p>',
    fr: '<p>Plan entreprise total</p>',
    eus: '<p>enpresa plan osoa</p>',
    cat: '<p>pla empresarial total</p>',
  },

  customerService: {
    es: '<p>Atención al cliente</p>',
    en: '<p>Customer support</p>',
    pt: '<p>Atenção ao cliente</p>',
    fr: '<p>Service client</p>',
    eus: '<p>Bezeroarentzako arreta</p>',
    cat: '<p>Atenció al client</p>',
  },

  duration: {
    es: '<p>duración:</p>',
    en: '<p>duration:</p>',
    pt: '<p>duração:</p>',
    fr: '<p>validité :</p>',
    eus: '<p>iraupena:</p>',
    cat: '<p>durada:</p>',
  },

  storage: {
    es: '<p>almacenamiento:</p>',
    en: '<p>storage:</p>',
    pt: '<p>armazenamento:</p>',
    fr: '<p>stockage :</p>',
    eus: '<p>biltegiratzea:</p>',
    cat: '<p>emmagatzematge:</p>',
  },

  storage100GbFree2Years: {
    es: '<p>100 GB gratis durante 2 años</p>',
    en: '<p>100 GB free for 2 years</p>',
    pt: '<p>100 GB grátis durante 2 anos</p>',
    fr: '<p>100 Go gratuits pendant 2 ans</p>',
    eus: '<p>100 GB dohainik 2 urtez</p>',
    cat: '<p>100 GB gratis durant 2 anys</p>',
  },

  storage200GbFree2Years: {
    es: '<p>200 GB gratis durante 2 años</p>',
    en: '<p>200 GB free for 2 years</p>',
    pt: '<p>200 GB grátis durante 2 anos</p>',
    fr: '<p>200 Go gratuits pendant 2 ans</p>',
    eus: '<p>200 GB dohainik 2 urtez</p>',
    cat: '<p>200 GB gratis durant 2 anys</p>',
  },

  monthlySubscription: {
    es: '<p>suscripción mensual</p>',
    en: '<p>monthly subscription</p>',
    pt: '<p>subscrição mensal</p>',
    fr: '<p>abonnement mensuel</p>',
    eus: '<p>hileko harpidetza</p>',
    cat: '<p>subscripció mensual</p>',
  },

  yearlySubscription: {
    es: '<p>suscripción anual</p>',
    en: '<p>annual subscription</p>',
    pt: '<p>subscrição anual</p>',
    fr: '<p>abonnement annuel</p>',
    eus: '<p>urteroko harpidetza</p>',
    cat: '<p>subscripció anual</p>',
  },

  storage150GbFree2Years: {
    es: '<p>150 GB gratis durante 2 años</p>',
    en: '<p>150 GB free for 2 years</p>',
    pt: '<p>150 GB grátis durante 2 anos</p>',
    fr: '<p>150 Go gratuits pendant 2 ans</p>',
    eus: '<p>150 GB dohainik 2 urtez</p>',
    cat: '<p>150 GB gratis durant 2 anys</p>',
  },

  year: {
    es: '<p>año</p>',
    en: '<p>year</p>',
    pt: '<p>ano</p>',
    fr: '<p>an</p>',
    eus: '<p>urteko</p>',
    cat: '<p>any</p>',
  },

  address: {
    es: '<p>Dirección</p>',
    en: '<p>Address</p>',
    pt: '<p>Endereço</p>',
    fr: '<p>Adresse</p>',
    eus: '<p>Helbidea</p>',
    cat: '<p>Adreça</p>',
  },

  yourAllModels: {
    es: '<p>Todos tus modelos 3D en proceso</p>',
    en: '<p>All your 3D models in process</p>',
    pt: '<p>Todos os seus modelos 3D em processamento</p>',
    fr: '<p>Tous vos modèles 3D en cours</p>',
    eus: '<p>Abian dauden 3D eredu guztiak</p>',
    cat: '<p>Tots els teus models 3D en curs</p>',
  },

  delete3DModelInProcess: {
    es: '<p>Eliminar Modelos 3D en proceso</p>',
    en: '<p>Delete 3D models in progress</p>',
    pt: '<p>Eliminar modelos 3D em processo</p>',
    fr: '<p>Supprimer les modèles 3D en cours</p>',
    eus: '<p>Ezabatu abian dauden 3D ereduak</p>',
    cat: '<p>Elimina els models 3D en curs</p>',
  },

  estimatedTime: {
    es: '<p>Tiempo Estimado</p>',
    en: '<p>Estimated Time</p>',
    pt: '<p>Tempo estimado</p>',
    fr: '<p>Temps estimé</p>',
    eus: '<p>Aurreikusitako denbora</p>',
    cat: '<p>Temps estimat</p>',
  },

  estimatedTimeProcess: {
    es: '<p>Tiempo estimado de proceso</p>',
    en: '<p>Estimated processing time</p>',
    pt: '<p>Tempo estimado de processamento</p>',
    fr: '<p>Temps estimé de traitement</p>',
    eus: '<p>Prozesua burutzeko aurreikusitako denbora</p>',
    cat: '<p>Temps estimat de processament</p>',
  },

  categorizeYourModel: {
    es: '<p>Categoriza tu modelo 3D</p>',
    en: '<p>Categorize your 3D model</p>',
    pt: '<p>Categorize o seu modelo 3D</p>',
    fr: '<p>Catégorisez votre modèle 3D</p>',
    eus: '<p>Sailkatu zure 3D eredua</p>',
    cat: '<p>Categoritza el teu model 3D</p>',
  },

  yourModels3DInProcess: {
    es: '<p>Tus modelos 3D en proceso</p>',
    en: '<p>Your 3D models in progress</p>',
    pt: '<p>Os seus modelos 3D em processamento</p>',
    fr: '<p>Vos modèles 3D en cours</p>',
    eus: '<p>Abian dauden 3D ereduak</p>',
    cat: '<p>Els teus models 3D en curs</p>',
  },

  download: {
    es: '<p>Descargar</p>',
    en: '<p>Download</p>',
    pt: '<p>Transferir</p>',
    fr: '<p>Télécharger</p>',
    eus: '<p>Deskargatu</p>',
    cat: '<p>Baixa</p>',
  },

  join_models_destination_panel_add_model: {
    es: '<p>Añadir modelo 3D</p>',
    en: '<p>Add 3D model</p>',
    pt: '<p>Adicionar modelo 3D</p>',
    fr: '<p>Ajouter modèle 3D</p>',
    eus: '<p>Gehitu 3D eredua</p>',
    cat: '<p>Afegeix model 3D</p>',
  },

  device_adjustments: {
    es: '<p>Ajustes dispositivo</p>',
    en: '<p>Device adjustments</p>',
    pt: '<p>Configurações do dispositivo</p>',
    fr: '<p>Réglages du dispositif</p>',
    eus: '<p>Gailuaren ezarpenak</p>',
    cat: '<p>Ajustaments del dispositiu</p>',
  },

  camara_object_relationship: {
    es: '<p>Relación cámara/objeto</p>',
    en: '<p>Camera/object relationship</p>',
    pt: '<p>Relação câmara/objeto</p>',
    fr: '<p>Relation appareil photo/objet</p>',
    eus: '<p>Kamera/objektuaren arteko erlazioa</p>',
    cat: '<p>Relació càmera/objecte</p>',
  },

  camera_moves: {
    es: '<p>Movimientos de cámara</p>',
    en: '<p>Camera moves</p>',
    pt: '<p>Movimentos de câmara</p>',
    fr: '<p>Mouvements de la caméra</p>',
    eus: '<p>Kameraren mugimenduak</p>',
    cat: '<p>Moviments de càmera</p>',
  },

  recommendations: {
    es: '<p>Recomendaciones</p>',
    en: '<p>Tips</p>',
    pt: '<p>Recomendações</p>',
    fr: '<p>Recommandations</p>',
    eus: '<p>Gomendioak</p>',
    cat: '<p>Recomanacions</p>',
  },

  photo_or_video: {
    es: '<p>Fotografía o vídeo</p>',
    en: '<p>Photograph or video</p>',
    pt: '<p>Fotografia ou vídeo</p>',
    fr: '<p>Photo ou vidéo</p>',
    eus: '<p>Argazkia edo bideoa</p>',
    cat: '<p>Fotografia o vídeo</p>',
  },

  create_3D_models: {
    es: '<p>Crea tus modelos 3D en tres simples pasos en</p>',
    en: '<p>Create your 3D models following three simple steps in</p>',
    pt: '<p>Crie os seus modelos 3D em três simples passos em</p>',
    fr: '<p>Générez des modèles 3D en seulement trois étapes en</p>',
    eus: '<p>Sortu hemen zure 3D ereduak hiru urrats errazetan:</p>',
    cat: '<p>Crea els teus models 3D en tres senzills passos en</p>',
  },

  create_3D_models_mobile: {
    es: '<p>Crea tus modelos 3D en tres simples pasos:</p>',
    en: '<p>Create your 3D models following three simple steps:</p>',
    pt: '<p>Crie os seus modelos 3D em três simples passos:</p>',
    fr: '<p>Générez des modèles 3D en seulement trois étapes :</p>',
    eus: '<p>Sortu zure 3D ereduak hiru urrats errazetan:</p>',
    cat: '<p>Crea els teus models 3D en tres senzills passos:</p>',
  },

  capture: {
    es: '<p>Captura</p>',
    en: '<p>Capture</p>',
    pt: '<p>Capte</p>',
    fr: '<p>Capturez</p>',
    eus: '<p>Kapturatu</p>',
    cat: '<p>Captura</p>',
  },

  upload: {
    es: '<p>Carga</p>',
    en: '<p>Upload</p>',
    pt: '<p>Carregue</p>',
    fr: '<p>Téléchargez</p>',
    eus: '<p>Kargatu</p>',
    cat: '<p>Carrega</p>',
  },

  visualize: {
    es: '<p>Visualiza</p>',
    en: '<p>Visualize</p>',
    pt: '<p>Visualize</p>',
    fr: '<p>Visualisez</p>',
    eus: '<p>Bistaratu</p>',
    cat: '<p>Mostra</p>',
  },

  take_pictures: {
    es: '<p>Toma fotos o vídeos con</p><p>cualquier dispositivo</p>',
    en: '<p>Take pictures or videos with</p><p>any device</p>',
    pt: '<p>Tire fotografias ou grave vídeos com</p><p>qualquer dispositivo</p>',
    fr: '<p>Prenez des photos ou des vidéos avec</p><p>n\'importe quel appareil</p>',
    eus: '<p>Atera argazkiak edo bideoak</p><p>edozein gailurekin</p>',
    cat: '<p>Fes fotos o vídeos amb</p><p>qualsevol dispositiu</p>',
  },

  any_device_mayus: {
    es: '<p>CUALQUIER DISPOSITIVO</p><p>con cámara</p>',
    en: '<p>ANY DEVICE</p><p>with a camera</p>',
    pt: '<p>QUALQUER DISPOSITIVO</p><p>com câmara</p>',
    fr: '<p>N\'IMPORTE QUEL APPAREIL</p><p>muni d’une caméra</p>',
    eus: '<p>Kamera duen</p><p>EDOZEIN GAILUREKIN</p>',
    cat: '<p>QUALSEVOL DISPOSITIU</p><p>amb càmera</p>',
  },

  upload_platform: {
    es: '<p>Súbelo a la plataforma de</p><p>generación 3D automática</p>',
    en: '<p>Upload them to the automatic</p><p>3D generation platform</p>',
    pt: '<p>Carregue-o para a plataforma de</p><p>criação 3D automática</p>',
    fr: '<p>Téléchargez-le sur la plateforme de</p><p>génération 3D automatique</p>',
    eus: '<p>3D ereduak automatikoki</p><p>sortzeko plataformara igo</p>',
    cat: '<p>Carrega-ho a la plataforma de</p><p>generació 3D automàtica</p>',
  },

  visualize_operate: {
    es: '<p>Visualiza y opera</p><p>con tu modelo 3D</p>',
    en: '<p>Visualize and work</p><p>with your 3D Model</p>',
    pt: '<p>Visualize e trabalhe</p><p>o seu modelo 3D</p>',
    fr: '<p>Visualisez et exploitez</p><p>votre modèle 3D</p>',
    eus: '<p>Bistaratu eta landu</p><p>zure 3D eredua</p>',
    cat: '<p>Mostra el model 3D</p><p>i treballa amb ell</p>',
  },

  review_device_adjustments: {
    es: '<p>Revisa los ajustes del dispositivo a utilizar y sigue</p><p>nuestras recomendaciones para una óptima captura</p>',
    en: '<p>Check your device settings and follow</p><p>our tips for an optimum capture</p>',
    pt: '<p>Verifique as configurações do dispositivo a utilizar e siga as</p><p>nossas recomendações para uma captação ideal</p>',
    fr: '<p>Révisez les réglages de l’appareil à utiliser et suivez</p><p>nos recommandations pour assurer une capture optimale</p>',
    eus: '<p>Erabili nahi duzun gailuaren ezarpenak egiaztatu eta</p><p>segi gure gomendioak kaptura egokia lortzeko</p>',
    cat: '<p>Revisa els ajustaments del dispositiu a utilitzar i segueix</p><p>les nostres recomanacions per a una captura òptima</p>',
  },

  focus: {
    es: '<p>Enfoca</p>',
    en: '<p>Focus</p>',
    pt: '<p>Foca</p>',
    fr: '<p>Faites la mise au point</p>',
    eus: '<p>Fokuratu</p>',
    cat: '<p>Enfoca</p>',
  },

  no_flash: {
    es: '<p>No flash</p>',
    en: '<p>No flash</p>',
    pt: '<p>Sem flash</p>',
    fr: '<p>Pas de flash</p>',
    eus: '<p>Flashik ez</p>',
    cat: '<p>Sense flaix</p>',
  },

  no_zoom: {
    es: '<p>No zoom</p>',
    en: '<p>No zoom</p>',
    pt: '<p>Sem zoom</p>',
    fr: '<p>Pas de zoom</p>',
    eus: '<p>Zoomik ez</p>',
    cat: '<p>Sense zoom</p>',
  },

  focus_object: {
    es: '<p>Ajusta en foco en el</p><p>elemento a capturar</p>',
    en: '<p>Focus on the selected object</p><p>while you capture the images</p>',
    pt: '<p>Ajuste o foco no</p><p>elemento a captar</p>',
    fr: '<p>Faites la mise au point sur</p><p>l’élément à capturer</p>',
    eus: '<p>Kapturatu nahi duzun</p><p>elementura zuzendu fokua</p>',
    cat: '<p>Ajusta el focus en</p><p>l’element que has de capturar</p>',
  },
  focus_object_v360: {
    es: '<p>Ajusta en foco</p><p>en el elemento a</p><p>capturar</p>',
    en: '<p>Focus on the selected</p><p>object while you capture</p><p>the images</p>',
    pt: '<p>Ajuste o foco no</p><p>elemento a captar</p>',
    fr: '<p>Faites la mise au </p><p>point sur l’élément</p><p>à capturer</p>',
    eus: '<p>Kapturatu nahi duzun</p><p>elementura zuzendu</p><p>fokua</p>',
    cat: '<p>Ajusta el focus</p><p>en l’element que has de</p><p>capturar</p>',
  },

  desactivate_flash: {
    es: '<p>Desactiva el flash</p><p>para la captura</p>',
    en: '<p>Deactivate the flash</p><p>when you take the picture/p>',
    pt: '<p>Desative o flash</p><p>para a captação</p>',
    fr: '<p>Désactivez le flash</p><p>pour la capture</p>',
    eus: '<p>Desaktibatu flasha</p><p>kaptura egiteko</p>',
    cat: '<p>Desactiva el flaix</p><p>per fer la captura</p>',
  },

  desactivate_flash_v360: {
    es: '<p>Desactiva el</p><p>flash para la</p><p>captura</p>',
    en: '<p>Deactivate the</p><p>flash when you take</p><p>the picture</p>',
    pt: '<p>Desative o</p><p>flash para a</p><p>captação</p>',
    fr: '<p>Désactivez le</p><p>flash pour la</p><p>capture</p>',
    eus: '<p>Desaktibatu</p><p>flasha kaptura</p><p>egiteko</p>',
    cat: '<p>Desactiva el</p><p>flaix per fer</p><p>la captura</p>',
  },

  not_use_the_zoom: {
    es: '<p>No uses el zoom</p>',
    en: '<p>Do not use the zoom</p>',
    pt: '<p>Não utilize o zoom</p>',
    fr: '<p>N’utilisez pas le zoom</p>',
    eus: '<p>Ez erabili zoomik</p>',
    cat: '<p>No facis servir el zoom</p>',
  },

  not_use_the_zoom_v360: {
    es: '<p>No uses el zoom</p><p>en ninguna</p><p>captura</p>',
    en: '<p>Do not use the zoom</p><p>at any time</p></p>during capture<p>',
    pt: '<p>Não utilize o zoom</p><p>em nenhuma</p>captação<p>',
    fr: '<p>N\'utilisez pas le zoom</p><p>pour la</p><p>capture</p>',
    eus: '<p>Ez erabili</p><p>zoomik</p><p>kapturatan.</p>',
    cat: '<p>No facis servir</p><p>el zoom</p><p>en cap captura.</p>',
  },

  review_scene_conditions: {
    es: '<p>Revisa las condiciones de la escena y el objeto a capturar</p><p>teniendo en cuenta las siguientes recomendaciones:</p>',
    en: '<p>Check the conditions of the space and object to capture,</p><p>keeping in mind the following tips:</p>',
    pt: '<p>Verifique as condições da cena e do objeto a captar</p><p>de acordo com as seguintes recomendações:</p>',
    fr: '<p>Analysez les conditions de la scène et l’objet à capturer</p><p>en tenant compte des recommandations suivantes :</p>',
    eus: '<p>Egiaztatu eszenaren eta kapturatu nahi duzun objektuaren baldintzak</p><p>honako gomendioak kontuan hartuta:</p>',
    cat: '<p>Revisa les condicions de l\'escena i l\'objecte que s’ha de capturar</p><p>tenint en compte les següents recomanacions:</p>',
  },

  empty_obstacles: {
    es: '<p>Espacio sin obstáculos</p>',
    en: '<p>Empty space without obstacles</p>',
    pt: '<p>Espaço sem obstáculos</p>',
    fr: '<p>Espace sans obstacle</p>',
    eus: '<p>Oztoporik gabeko espazioa</p>',
    cat: '<p>Espai sense obstacles</p>',
  },

  not_move: {
    es: '<p>No mover</p>',
    en: '<p>Do not move</p>',
    pt: '<p>Não mover</p>',
    fr: '<p>Ne pas bouger</p>',
    eus: '<p>Ez mugitu</p>',
    cat: '<p>No moguis</p>',
  },

  not: {
    es: '<p>No</p>',
    en: '<p>Do not move</p>',
    pt: '<p>Não</p>',
    fr: '<p>Ne pas</p>',
    eus: '<p>Ez</p>',
    cat: '<p>No</p>',
  },

  move: {
    es: '<p>mover</p>',
    en: '<p></p>',
    pt: '<p>mover</p>',
    fr: '<p>bouger</p>',
    eus: '<p>mugitu</p>',
    cat: '<p>moguis</p>',
  },

  centre: {
    es: '<p>Centrar</p>',
    en: '<p>Center</p>',
    pt: '<p>Centrar</p>',
    fr: '<p>Centrer</p>',
    eus: '<p>Zentratu</p>',
    cat: '<p>Centra</p>',
  },

  distance: {
    es: '<p>Distancia</p>',
    en: '<p>Distance</p>',
    pt: '<p>Distância</p>',
    fr: '<p>Distance</p>',
    eus: '<p>Distantzia</p>',
    cat: '<p>Distància</p>',
  },

  space_object_camera: {
    es: '<p>El espacio entre el objeto</p><p>y la cámara debe estar</p><p>vacío.</p>',
    en: '<p>The space between</p><p>the object and the camera must</p><p>be empty.</p>',
    pt: '<p>O espaço entre</p><p>o objeto e a câmara deve</p><p>estar vazio.</p>',
    fr: '<p>L\'espace entre l\'objet</p><p>et la caméra doit</p><p>être vide.</p>',
    eus: '<p>Objektuaren eta</p><p>kameraren arteko espazioa</p><p>hutsik egon behar du.</p>',
    cat: '<p>L\'espai entre l\'objecte</p><p>i la càmera</p><p>ha de ser buit.</p>',
  },

  space_object_camera_v360: {
    es: '<p>El espacio entre</p><p>el objeto y la cámara</p><p>debe estar vacío.</p>',
    en: '<p>The space between</p><p>the object and the camera</p><p>must be empty.</p>',
    pt: '<p>O espaço entre</p><p>o objeto e a câmara deve</p><p>estar vazio.</p>',
    fr: '<p>L’espace entre</p><p>l\'objet et la caméra doit</p><p>être vide.</p>',
    eus: '<p>Objektuaren eta</p><p>kameraren arteko espazioak</p><p>hutsik egon behar du.</p>',
    cat: '<p>L\'espai entre</p><p>l\'objecte i la càmera</p><p>ha de ser buit.</p>',
  },

  object_static: {
    es: '<p>El objeto debe</p><p>permanecer estático.</p>',
    en: '<p>The object</p><p>must remain stationary.</p>',
    pt: '<p>O objeto deve</p><p>permanecer estático.</p>',
    fr: '<p>L’objet doit</p><p>être immobile.</p>',
    eus: '<p>Objektuak geldirik</p><p>egon behar du.</p>',
    cat: '<p>L\'objecte ha de</p><p>romandre estàtic.</p>',
  },

  object_static_v360: {
    es: '<p>El objeto debe</p><p>permanecer</p><p>estático.</p>',
    en: '<p>The object</p><p>must remain</p><p>stationary.</p>',
    pt: '<p>O objeto deve</p><p>permanecer</p><p>estático.</p>',
    fr: '<p>L’objet doit</p><p>être</p><p>immobile.</p>',
    eus: '<p>Objektuak geldirik</p><p>egon</p><p>behar du.</p>',
    cat: '<p>L\'objecte ha de </p><p>romandre</p><p>estàtic.</p>',
  },

  place_camera: {
    es: '<p>Sitúa la cámara</p><p>colocando el objeto </p><p>en el centro</p><p> de la fotografía.</p>',
    en: '<p>Place the camera</p><p>so that the object</p><p>is in the center</p><p>of the frame.</p>',
    pt: '<p>Situe a câmara</p><p>de forma a colocar o objeto</p><p>no centro</p><p>da fotografia.</p>',
    fr: '<p>Ajustez la caméra</p><p>pour que l’objet se trouve</p><p>au centre</p><p>de la photo.</p>',
    eus: '<p>Mugitu kamera,</p><p>objektua</p><p>argazkiaren erdian</p><p>egon dadin.</p>',
    cat: '<p>Situa la càmera</p><p>col·locant l\'objecte</p><p>en el centre</p><p>de la fotografia.</p>',
  },

  place_camera_v360: {
    es: '<p>Sitúa la cámara</p><p>colocando el objeto</p><p>en el centro de la</p><p>fotografía.</p>',
    en: '<p>Place the camera</p><p>so that the object</p><p>is in the center</p><p>of the frame.</p>',
    pt: '<p>Situe a câmara</p><p>de forma a colocar o objeto</p><p>no centro da</p><p>fotografia.</p>',
    fr: '<p>Ajustez la caméra</p><p>pour que l’objet se trouve</p><p>au centre de la</p><p>photo.</p>',
    eus: '<p>Mugitu kamera,</p><p>objektua</p><p>argazkiaren erdian</p><p>egon dadin.</p>',
    cat: '<p>Situa la càmera</p><p>col·locant l\'objecte en</p><p>el centre</p><p>de la fotografia.</p>',
  },

  keep_same_distance: {
    es: '<p>Mantén una distancia</p><p>y ángulo constante</p><p>respecto al objeto.</p>',
    en: '<p>Maintain a constant</p><p>distance and angle</p><p>from the object.</p>',
    pt: '<p>Mantenha uma distância</p><p>e um ângulo constantes</p><p>relativamente ao objeto.</p>',
    fr: '<p>Maintenez une distance</p><p>et un angle constants</p><p>par rapport à l\'objet.</p>',
    eus: '<p>Mantendu distantzia</p><p>eta angelu konstanteak</p><p>objektuarekiko.</p>',
    cat: '<p>Mantingues una distància</p><p>i un angle constants</p><p>respecte de l\'objecte.</p>',
  },

  keep_same_distance_v360: {
    es: '<p>Mantén una</p><p>distancia y ángulo</p><p>constante respecto</p><p>al objeto.</p>',
    en: '<p>Maintain a</p><p>constant distance</p><p>and angle</p><p>from the object.</p>',
    pt: '<p>Mantenha uma</p><p>distância e um ângulo</p><p>constantes relativamente ao</p><p>objeto.</p>',
    fr: '<p>Maintenez une</p><p>distance et un angle</p><p>constants par rapport</p><p>à l’objet.</p>',
    eus: '<p>Mantendu distantzia</p><p>eta angelu</p><p>konstanteak</p><p>objektuarekiko.</p>',
    cat: '<p>Mantingues una</p><p>distància i un angle</p><p>constants respecte</p><p>de l\'objecte.</p>',
  },

  obtain_optinum_frame: {
    es: '<p>Con el objetivo de obtener una óptima captura sigue las recomendaciones</p><p>de movimientos de cámara; puedes combinar varios movimientos:</p>',
    en: '<p>For an optimum capture, follow the camera movement tips.</p><p>You can combine multiple moves: </p>',
    pt: '<p>Com o objetivo de obter uma captação ideal, siga as recomendações</p><p>de movimentos de câmara; pode combinar vários movimentos:</p>',
    fr: '<p>Afin d’obtenir la meilleure capture, suivez les recommandations</p><p>concernant les mouvements de la caméra ; vous pouvez combiner plusieurs mouvements:</p>',
    eus: '<p>Kaptura egokia lortzeko, jarraitu kameraren mugimenduari buruzko gomendioak;</p><p>hainbat kamera-mugimendu konbina ditzakezu:</p>',
    cat: '<p>Per tal d\'obtenir una captura òptima, segueix les recomanacions</p><p>de moviments de càmera; pots combinar diversos moviments: </p>',
  },

  obtain_optinum_frame_v360: {
    es: '<p>Con el objetivo de obtener una óptima captura sigue</p><p>las recomendaciones de movimientos de cámara;</p><p>puedes combinar varios movimientos:</p>',
    en: '<p>For an optimum capture, follow</p><p>the camera movement tips.</p><p>You can combine multiple moves:</p>',
    pt: '<p>Com o objetivo de obter uma captação ideal, siga as</p><p>recomendações de movimentos de câmara;</p><p>pode combinar vários movimentos:</p>',
    fr: '<p>Afin d’obtenir la meilleure capture,</p><p>suivez les recommandations concernant les mouvements</p><p>de la caméra ; vous pouvez combiner plusieurs mouvements :</p>',
    eus: '<p>Kaptura egokia lortzeko, jarraitu kameraren</p><p>mugimenduari buruzko gomendioak;</p><p>hainbat kamera-mugimendu konbina ditzakezu:</p>',
    cat: '<p>Per tal d\'obtenir una captura òptima, segueix</p><p>les recomanacions de moviments de càmera;</p><p>pots combinar diversos moviments:</p>',
  },

  tils_auspicious: {
    es: '<p>La inclinación es favorable</p>',
    en: '<p>Tilting may be beneficial</p>',
    pt: '<p>A inclinação é favorável</p>',
    fr: '<p>L’inclinaison est favorable</p>',
    eus: '<p>Inklinazioa lagungarria da</p>',
    cat: '<p>La inclinació és favorable</p>',
  },

  axis_direction: {
    es: '<p>Cualquier eje y dirección</p>',
    en: '<p>Any axis and direction</p>',
    pt: '<p>Qualquer eixo e direção</p>',
    fr: '<p>N’importe quel axe et direction</p>',
    eus: '<p>Edozein ardatz edo norabide</p>',
    cat: '<p>Qualsevol eix i direcció</p>',
  },

  inclinate_camera: {
    es: '<p>Es recomendable inclinar la cámara 5º o 10º</p><p>en algunos asos para poder centrar el objeto</p><p> a capturar en el centro de la fotografía o vídeo.</p>',
    en: '<p>In some cases, you may want to tilt the camera 5–10°</p><p>so you can center the object to capture</p><p>in the frame of the photo or video.</p>',
    pt: '<p>Em alguns casos, é recomendável inclinar a câmara entre 5° e 10°</p><p>para poder centrar o objeto a captar</p><p>na fotografia ou no vídeo.</p>',
    fr: '<p>Il est recommandé d’incliner l’appareil de 5º ou 10º</p><p>dans certains cas pour pouvoir situer l’objet</p><p>à capturer au centre de la photo ou vidéo.</p>',
    eus: '<p>Zenbait kasutan, gomendagarria da kamera 5° edo 10°</p><p>inklinatzea, kapturatu nahi duzun objektua</p><p>argazkiaren edo bideoaren erdian jartzeko.</p>',
    cat: '<p>És recomanable inclinar la càmera 5° o 10°</p><p>en alguns casos per poder centrar l\'objecte que</p><p>s\'ha de capturar en el centre de la fotografia o el vídeo.</p>',
  },

  aiming_obtain_optinum_frame: {
    es: '<p>Puedes mover la cámara alrededor del objeto en cualquier eje de movimiento con el fin de capturar todo el detalle del objeto.</p>',
    en: '<p>You can move the camera around the</p><p>object in any direction to capture</p><p>all the details.</p>',
    pt: '<p>Pode mover a câmara em torno do objeto em qualquer eixo de movimento com o objetivo de captar todo o pormenor do objeto.</p>',
    fr: '<p>Vous pouvez déplacer l’appareil photo autour de l’objet sur tous les axes de mouvement afin de capturer le plus grand nombre de détails de l’objet.</p>',
    eus: '<p>Objektuaren xehetasun guztiak kapturatzeko, kamera edozein mugimendu-ardatzean mugi dezakezu objektuaren inguruan.</p>',
    cat: '<p>Pots moure la càmera al voltant de l\'objecte en qualsevol eix de moviment per tal de capturar-ne tots els detalls.</p>',
  },

  circle_movement: {
    es: '<p>Movimiento circular</p>',
    en: '<p>Circular movement</p>',
    pt: '<p>Movimento circular</p>',
    fr: '<p>Mouvement circulaire</p>',
    eus: '<p>Mugimendu zirkularra</p>',
    cat: '<p>Moviment circular</p>',
  },

  around_the_object_until: {
    es: '<p>Alrededor del objeto hasta</p><p>completar un ángulo de </p><p>360º sobre el objeto.</p>',
    en: '<p>Move around the object until</p><p>you complete a full</p><p>360° angle.</p>',
    pt: '<p>Em torno do objeto até</p><p>completar um ângulo de</p><p>360° sobre o mesmo.</p>',
    fr: '<p>Autour de l’objet jusqu’à </p><p>effectuer une rotation de</p><p>360° autour de celui- ci.</p>',
    eus: '<p>Objektuaren inguruan,</p><p>360°- ko angelua</p><p>egin arte.</p>',
    cat: '<p>Al voltant de l\'objecte fins</p><p>completar un angle de</p><p>360° sobre l\'objecte.</p>',
  },

  movement_linear_axis: {
    es: '<p>Movimiento en eje lineal</p>',
    en: '<p>Movement on linear axis</p>',
    pt: '<p>Movimento em eixo linear</p>',
    fr: '<p>Mouvement linéaire</p>',
    eus: '<p>Mugimendua ardatz linealean</p>',
    cat: '<p>Moviment en eix lineal</p>',
  },

  capture_move_device: {
    es: '<p>Para la captura puedes mover el dispositivo en</p><p>paralelo al objeto y realizar la captura en</p><p>perpendicular al mismo.</p>',
    en: '<p>For the capture you can move the device</p><p>parallel to the object and take the capture</p><p>perpendicular to it.</p>',
    pt: '<p>Para a captação, pode mover o dispositivo</p><p>paralelamente ao objeto e efetuar a captação</p><p>perpendicularmente ao mesmo.</p>',
    fr: '<p>Lors de la capture, vous pouvez déplacer l’appareil parallèlement à l’objet et réaliser la capture</p><p>perpendiculairement à celui- ci.</p>',
    eus: '<p>Kaptura egiteko, kamera objektuarekiko paraleloan</p><p>mugi dezakezu eta irudiak horrekiko</p><p>perpendikularrean kapturatu.</p>',
    cat: '<p>Per a la captura, pots moure el dispositiu</p><p>en paral·lel a l\'objecte i fer la captura</p><p>en perpendicular a aquest.</p> ',
  },

  capture_move_device_v360: {
    es: '<p>Para la captura puedes mover el</p><p>dispositivo en paralelo al</p><p>objeto y realizar la captura en</p><p>perpendicular al mismo.</p>',
    en: '<p>For the capture you can move the</p><p>device parallel to the object</p><p>and take the capture</p><p>perpendicular to it.</p>',
    pt: '<p>Para a captação, pode mover o</p><p>dispositivo paralelamente ao</p><p>objeto e efetuar a captação</p><p>perpendicularmente ao mesmo.</p>',
    fr: '<p>Lors de la capture, vous pouvez déplacer</p><p>l’appareil parallèlement à</p><p>l’objet et réaliser la capture</p><p>perpendiculairement à celui- ci.</p>',
    eus: '<p>Kaptura egiteko, kamera objektuarekiko</p><p>paraleloan mugi dezakezu</p><p>eta irudiak horrekiko</p><p>perpendikularrean kapturatu.</p>',
    cat: '<p>Per a la captura, pots moure el dispositiu</p><p>en paral·lel a l\'objecte</p><p>i fer la captura en</p><p>perpendicular a aquest.</p>',
  },

  move_device_object: {
    es: '<p>Se mueve el dispositivo no el objeto</p>',
    en: '<p>Move the device, not the object</p>',
    pt: '<p>Move-se o dispositivo, não o objeto</p>',
    fr: '<p>C’est l’appareil qui doit bouger, pas l’objet</p>',
    eus: '<p>Gailua mugitu behar duzu, ez objektua</p>',
    cat: '<p>Es mou el dispositiu, no l\'objecte</p>',
  },

  movement_has_camera: {
    es: '<p>El movimiento tiene que ser de cámara el objeto debe</p><p>permanecer estático.</p>',
    en: '<p>Only the camera should move;</p><p>the object must remain stationary.</p>',
    pt: '<p>O movimento tem de ser de câmara, o objeto deve</p><p>permanecer estático.</p>',
    fr: '<p>Le mouvement est effectué par l’appareil photo ;</p><p>l’objet doit rester immobile.</p>',
    eus: '<p>Kamera da mugitu behar dena, objektuak</p><p>geldirik egon behar du.</p>',
    cat: '<p>El moviment ha de ser de càmera, l\'objecte ha de</p><p>romandre estàtic.</p>',
  },

  movement_has_camera_v360: {
    es: '<p>El movimiento tiene que ser de cámara,</p><p>el objeto debe permanecer estático.</p>',
    en: '<p>Only the camera should move;</p><p>the object must remain stationary.</p>',
    pt: '<p>O movimento tem de ser de câmara, o objeto deve</p><p>permanecer estático.</p>',
    fr: '<p>Le mouvement est effectué par l’appareil photo ;</p><p>l’objet doit rester immobile.</p>',
    eus: '<p>Kamera da mugitu behar dena,</p><p>objektuak geldirik egon behar du.</p>',
    cat: '<p>El moviment ha de ser de càmera,</p><p>l\'objecte ha de romandre estàtic.</p>',
  },

  general_recommendations: {
    es: '<p>Recomendaciones generales</p>',
    en: '<p>General tips</p>',
    pt: '<p>Recomendações gerais</p>',
    fr: '<p>Recommandations générales</p>',
    eus: '<p>Gomendio orokorrak</p>',
    cat: '<p>Recomanacions generals</p>',
  },

  texture: {
    es: '<p>Textura</p>',
    en: '<p>Texture</p>',
    pt: '<p>Textura</p>',
    fr: '<p>Texture</p>',
    eus: '<p>Testura</p>',
    cat: '<p>Textura</p>',
  },

  achieve_better_results: {
    es: '<p>Conseguirás mejores</p><p>resultados cuando el</p><p>objeto tenga mayor textura (estampados,</p><p>colores, detalles, profundidad…)</p>',
    en: '<p>You will achieve better</p><p>results when the object</p><p>has more texture</p><p>(patterns, colors, details, depth, etc.) </p>',
    pt: '<p>Quanto mais textura o</p><p>objeto tiver, melhores</p><p>resultados conseguirá</p><p>(estampados, cores, pormenores, profundidade, …)</p>',
    fr: '<p>Les objets avec une meilleure</p><p>texture donneront les</p><p>meilleurs résultats</p><p>(imprimés, couleurs, détails, profondeur...)</p>',
    eus: '<p>Emaitza hobeak lortuko</p><p>dituzu objektuak testura</p><p>handiagoa badu(estanpatuak, koloreak,</p><p>  xehetasunak, sakonera, etab.)</p>',
    cat: '<p>Aconseguiràs millors</p><p>resultats quan l\'objecte</p><p>tingui més textura(estampats,</p><p> colors, detalls, profunditat…)</p>',
  },

  what_cannot_be_seen: {
    es: '<p>Lo que no se ve,</p><strong>no se ve</strong>',
    en: '<p>What cannot be seen</p><strong>will not be seen</strong>',
    pt: '<p>O que não se vê,</p><strong>não se vê</strong>',
    fr: '<p>Ce qui n’est pas visible</p><strong>ne peut être montré</strong>',
    eus: '<p>Ikusten ez dena,</p><strong>ez da ikusiko</strong>',
    cat: '<p>Allò que no es veu,</p><strong>no es veu</strong>',
  },

  what_does_not_appear: {
    es: '<p>Lo que no sale en la foto no</p><p>se puede generar.</p>',
    en: '<p>What does not appear in the picture</p><p>cannot be generated.</p>',
    pt: '<p>O que não sai na fotografia</p><p>não se pode criar.</p>',
    fr: '<p>Ce qui n’apparait pas sur la photo</p><p>ne peut pas être généré.</p>',
    eus: '<p>Argazkian ateratzen ez dena</p><p>ezin da ereduan sortu.</p>',
    cat: '<p>El que no surt a la foto,</p><p>no es pot generar.</p>',
  },

  avoid_reflexes: {
    es: '<p>Evitar reflejos</p>',
    en: '<p>Avoid reflections</p>',
    pt: '<p>Evitar reflexos</p>',
    fr: '<p>Éviter les reflets</p>',
    eus: '<p>Ekidin argi-islak</p>',
    cat: '<p>Evita els reflexos</p>',
  },

  avoid_light_reflexes: {
    es: '<p>Evita los reflejos de luz</p><p>artificial y las sombras que</p><p>puedan generar.</p>',
    en: '<p>Avoid reflections and</p><p>shadows from artificial lighting.</p>',
    pt: '<p>Evite os reflexos de luz artificial</p><p>e as sombras que estes</p><p>possam criar.</p>',
    fr: '<p>Évitez les reflets des lumières artificielles</p><p>et les ombres qu’elles</p><p>peuvent créer.</p>',
    eus: '<p>Ekidin itzazu eman</p><p>daitezkeen argi artifizialaren</p><p>islak eta itzalak.</p>',
    cat: '<p>Evita els reflexos de llum</p><p>artificial i les ombres que</p><p>puguin generar.</p>',
  },

  no_indoors: {
    es: '<p>No interiores</p>',
    en: '<p>Avoid indoor spaces</p>',
    pt: '<p>Interiores, não</p>',
    fr: '<p>Éviter les intérieurs</p>',
    eus: '<p>Barnealderik ez</p>',
    cat: '<p>No interiors</p>',
  },

  recommended_indoor: {
    es: '<p>No es recomendable hacer</p><p>espacios interiores que</p><p>contengan 4 paredes blancas.</p>',
    en: '<p>Capture is not recommended in</p><p>indoor spaces enclosed by</p><p>4 white walls.</p>',
    pt: '<p>Não é recomendável trabalhar</p><p>espaços interiores que contenham</p><p>4 paredes brancas.</p>',
    fr: '<p>Il n’est pas recommandé de créer</p><p>des espaces intérieurs avec</p><p>4 murs blancs.</p>',
    eus: '<p>Ez da gomendagarria 4 horma</p><p>zuri dituzten barnealdeen</p><p>kaptura egitea.</p>',
    cat: '<p>No és recomanable fer</p><p>espais interiors que</p><p>continguin 4 parets blanques.</p>',
  },

  no_panoramic: {
    es: '<p>No panorámico</p>',
    en: '<p>No panoramas</p>',
    pt: '<p>Panorâmico, não</p>',
    fr: '<p>Éviter les photos panoramiques</p>',
    eus: '<p>Panoramikarik ez</p>',
    cat: '<p>No panoràmic</p>',
  },

  avoid_panoramic: {
    es: '<p>Evita el movimiento</p><p>panorámico desde un punto</p><p>fijo.</p>',
    en: '<p>Avoid panoramic</p><p>moves from a fixed</p><p>point.</p>',
    pt: '<p>Evite o movimento</p><p>panorâmico a partir de um ponto</p><p>fixo.</p>',
    fr: '<p>Évitez le mouvement</p><p>panoramique depuis un point</p><p>fixe.</p>',
    eus: '<p>Ekidin ezazu puntu finko</p><p>batetik mugimendu</p><p>panoramikoa egitea.</p>',
    cat: '<p>Evita el moviment</p><p>panoràmic des d\'un punt</p><p>fix.</p>',
  },

  steady_light: {
    es: '<p>Luz uniforme</p>',
    en: '<p>Steady lighting</p>',
    pt: '<p>Luz uniforme</p>',
    fr: '<p>Lumière uniforme</p>',
    eus: '<p>Argi uniformea</p>',
    cat: '<p>Llum uniforme</p>',
  },

  recommended_pictures: {
    es: '<p>Es recomendable que todas</p><p>las fotografías tengan una luz</p><p>uniforme.</p>',
    en: '<p>It is recommended that the</p><p>lighting remain consistent</p><p>for all photos.</p>',
    pt: '<p>É recomendável que todas</p><p>as fotografias tenham uma</p><p>luz uniforme.</p>',
    fr: '<p>Il est recommandé que</p><p>la lumière soit uniforme sur toutes</p><p>les photos.</p>',
    eus: '<p>Gomendagarria da argazki</p><p>guztiek argi uniformea</p><p>izan dezaten.</p>',
    cat: '<p>És recomanable que totes</p><p>les fotografies tinguin una llum</p><p>uniforme.</p>',
  },

  targets: {
    es: '<p>Escalado con targets</p>',
    en: '<p>Scaling with targets</p>',
    pt: '<p>Dimensionamento com targets</p>',
    fr: '<p>Mise à l’échelle avec targets</p>',
    eus: '<p>Eskalaketa Target-ekin</p>',
    cat: '<p>Ajustament d\'escala amb targets</p>',
  },

  obtain_model_targets: {
    es: '<p>Obtén desde la primera captura el modelo a</p><p>escala real usando targets en la escena.</p>',
    en: '<p>Obtain a full-scale model from the</p><p>first capture using targets in the scene.</p>',
    pt: '<p>Obtenha a partir da primeira captação o modelo</p><p>à escala real utilizando targets na cena.</p>',
    fr: '<p>Obtenez un modèle à échelle réelle dès la</p><p>premières capture en utilisant des targets dans la scène.</p>',
    eus: '<p>Lortu hasieratik eskala errealean dagoen eredua,</p><p>eszenan Target-ak erabiliz.</p>',
    cat: '<p>Obtingues des de la primera captura el model a</p><p>escala real fent servir targets a l\'escena.</p>',
  },

  reflected_tansparent: {
    es: '<p>Los objetos reflectantes, transparentes</p><p>o MUY finos <strong> resultarán muy difíciles de generar</strong></p>',
    en: '<p>Reflective, transparent or very</p><p>thin objects are</p><p>very difficult to shoot</p>',
    pt: '<p>Os objetos refletores, transparentes ou</p><p>MUITO finos tornar- se - ão</p><p>muito difíceis de criar </p>',
    fr: '<p>Les objets réfléchissants, transparents ou</p><p>TRÈS fin seront</p><p>très difficiles à générer.</p>',
    eus: '<p>Objektu islatzaileak, gardenak edo</p><p>OSO meheak sortzea oso zaila da.</p>',
    cat: '<p>Els objectes reflectors, transparents o</p><p>MOLT fins resultaran</p><p>molt difícils de generar.</p>',
  },

  capture_photo: {
    es: '<p>Captura de fotografías</p>',
    en: '<p>Photo photos</p>',
    pt: '<p>Captação de fotografias</p>',
    fr: '<p>Capture de photos</p>',
    eus: '<p>Argazkiak kapturatzea</p>',
    cat: '<p>Captura de fotografies</p>',
  },

  better_quality: {
    es: '<p>Mejor calidad que cantidad</p>',
    en: '<p>Quality over quantity</p>',
    pt: '<p>É melhor qualidade do que quantidade</p>',
    fr: '<p>Il vaut mieux la qualité à la quantité</p>',
    eus: '<p>Kantitatea baino, hobe da kalitatea</p>',
    cat: '<p>Millor qualitat que quantitat</p>',
  },

  optimus_3dmodel: {
    es: '<p>Puedes obtener un modelo 3D óptimo</p><p>desde 5 fotografías, la calidad del modelo</p><p>dependerá de la calidad de las fotos.</p>',
    en: '<p>You can get an optimum 3D</p><p>model from 5 pictures.The model quality</p><p>depends on how well the pictures were taken.</p>',
    pt: '<p>Pode obter um modelo 3D ideal</p><p>a partir de 5 fotografias, a qualidade do modelo</p><p>dependerá da qualidade das mesmas.</p>',
    fr: '<p>Vous pouvez obtenir des modèles 3D optimaux</p><p>à partir de 5 photos.La qualité du modèle</p><p>dépendra de la qualité des photos.</p>',
    eus: '<p>3D eredu egokia lortzeko, 5 argazki baino ez</p><p>dira beharrezkoak; ereduaren kalitatea argazkien</p><p>kalitatearen araberakoa izango da.</p>',
    cat: '<p>Pots obtenir un model 3D òptim a</p><p>partir de 5 fotografies, la qualitat del model</p><p>dependrà de la qualitat de les fotos.</p>',
  },

  minimum_angle: {
    es: '<p>Ángulo mínimo recomendado 180º</p>',
    en: '<p>180º minimum recommended angle</p>',
    pt: '<p>Ângulo mínimo recomendado 180°</p>',
    fr: '<p>Angle minimum recommandé : 180º</p>',
    eus: '<p>Gomendatutako gutxieneko angelua 180°</p>',
    cat: '<p>Angle mínim recomanat 180°</p>',
  },

  pictures_must_cover: {
    es: '<p>Las fotografías deben cubrir un ángulo</p><p>mínimo recomendado de 180º alrededor</p><p>del objeto a capturar.</p>',
    en: '<p>The pictures must cover a</p><p>minimum recommended angle of 180º around</p><p>the object to capture.</p>',
    pt: '<p>As fotografias devem abranger um ângulo</p><p>mínimo recomendado de 180° em torno</p><p>do objeto a captar.</p>',
    fr: '<p>Les photos doivent couvrir un angle</p><p>minimum recommandé de 180º autour</p><p>de l’objet à capturer.</p>',
    eus: '<p>Kapturatu nahi duzun objektuaren inguruan</p><p>gutxienez 180°-ko angelua barne hartu</p><p>behar dute argazkiek.</p>',
    cat: '<p>Les fotografies han de cobrir un angle</p><p>mínim recomanat de 180° al voltant de</p><p>l’objecte a capturar.</p>',
  },

  picture: {
    es: '<p>Una foto por cada paso</p>',
    en: '<p>A picture for every step</p>',
    pt: '<p>Uma fotografia por cada passo</p>',
    fr: '<p>Une photo par étape</p>',
    eus: '<p>Argazki bat urrats bakoitzeko</p>',
    cat: '<p>Una foto per cada pas</p>',
  },

  recommended_pictured_every_15: {
    es: '<p>Es recomendable una foto cada 15º,</p><p>situando al objeto en el centro. Un paso</p><p>normal hacia la derecha o izquierda</p><p>equivale a un ángulo de 15º.</p>',
    en: '<p>We recommend taking a photo every 15°,</p><p>ensuring the object is centered in the frame.</p><p>A normal step to the left or right</p><p>is equivalent to an angle of 15°.</p>',
    pt: '<p>É recomendável uma fotografia a cada 15°,</p><p>situando o objeto no centro. Um passo</p><p>normal para a direita ou para a esquerda</p><p>equivale a um ângulo de 15°.</p>',
    fr: '<p>Il est recommandé de prendre une photo</p><p>tous les 15° en situant l’objet toujours au</p><p>centre. Un pas de côté vers la droite ou la</p><p>gauche équivaut à un angle de 15°.</p>',
    eus: '<p>Gomendagarria da argazki bat 15°-ro egitea,</p><p>objektua beti erdian kokatuz. Urrats arrunt bat</p><p>eskuinera edo ezkerrera</p><p>15°-ko angelua da.</p>',
    cat: '<p>És recomanable una foto cada 15°,</p><p>situant l\'objecte en el centre. Un pas</p><p>normal cap a la dreta o l’esquerra</p><p>equival a un angle de 15°.</p>',
  },

  overlap: {
    es: '<p>Superposición</p>',
    en: '<p>Overlap</p>',
    pt: '<p>Sobreposição</p>',
    fr: '<p>Superposition</p>',
    eus: '<p>Gainjartzea</p>',
    cat: '<p>Superposició</p>',
  },

  every_picture: {
    es: '<p>Cada fotografía debe cubrir el 60% de</p><p>la fotografía anterior.</p>',
    en: '<p>Every picture must cover 60% of</p><p>the previous picture.</p>',
    pt: '<p>Cada fotografia deve cobrir 60% da</p><p>fotografia anterior.</p>',
    fr: '<p>Chaque photo doit couvrir 60 % de</p><p>la précédente.</p>',
    eus: '<p>Argazki bakoitzak aurreko argazkiaren %60a</p><p>barne hartu behar du.</p>',
    cat: '<p>Cada fotografia ha de cobrir el 60 % de</p><p>la fotografia anterior.</p>',
  },

  tips: {
    es: '<p>Recomendaciones</p>',
    en: '<p>Tips</p>',
    pt: '<p>Recomendações</p>',
    fr: '<p>Recommandations</p>',
    eus: '<p>Gomendioak</p>',
    cat: '<p>Recomanacions</p>',
  },

  capture_video: {
    es: '<p>Captura de vídeos</p>',
    en: '<p>Capturing videos</p>',
    pt: '<p>Captação de vídeos</p>',
    fr: '<p>Capture de vidéos</p>',
    eus: '<p>Bideoak kapturatzea</p>',
    cat: '<p>Captura de vídeos</p>',
  },

  max_video: {
    es: '<p>Vídeo máximo 1 min</p>',
    en: '<p>1 min video maximum</p>',
    pt: '<p>Vídeo máximo 1 min</p>',
    fr: '<p>Vidéo de 1 min maximum</p>',
    eus: '<p>Gehienez 1 min-ko bideoa</p>',
    cat: '<p>Vídeo màxim 1 min</p>',
  },

  video_360: {
    es: '<p>Vídeo 360º</p>',
    en: '<p>360º Video</p>',
    pt: '<p>Vídeo 360°</p>',
    fr: '<p>Vidéo 360°</p>',
    eus: '<p>360°-ko bideoa</p>',
    cat: '<p>Vídeo 360°</p>',
  },

  constant_speed: {
    es: '<p>Velocidad constante</p>',
    en: '<p>Constant speed</p>',
    pt: '<p>Velocidade constante</p>',
    fr: '<p>Vitesse constante</p>',
    eus: '<p>Abiadura egonkorra</p>',
    cat: '<p>Velocitat constant</p>',
  },

  film_video_around: {
    es: '<p>Toma el vídeo alrededor del objeto hasta</p><p>completar un ángulo de 360º sobre el objeto.</p>',
    en: '<p>Film a video around the object until</p><p>you complete a full 360º angle.</p>',
    pt: '<p>Grave o vídeo em torno do objeto </p><p>até completar um ângulo de 360°</p><p>sobre o mesmo.</p>',
    fr: '<p>Faites une vidéo autour de </p><p>l’objet jusqu’à compléter un angle de 360º</p><p>autour de celui-ci.</p>',
    eus: '<p>Atera bideoa objektuaren inguruan,</p><p>360°- ko angelua osatu arte.</p>',
    cat: '<p>Fes el vídeo al voltant de l\'objecte fins a</p><p>completar un angle de 360° sobre l\'objecte</p>',
  },

  keep_constant_speed: {
    es: '<p>Mantén una velocidad constante</p>',
    en: '<p>Keep a constant speed</p>',
    pt: '<p>Mantenha uma velocidade constante</p>',
    fr: '<p>Maintenez une vitesse constante</p>',
    eus: '<p>Mantendu abiadura egonkorra</p>',
    cat: '<p>Mantingues una velocitat constant</p>',
  },

  minute_video_maximum: {
    es: '<p>Captura 1 minuto máximo de vídeo. Pueden ser</p><p>varios vídeos y su suma total no debe de ser</p><p>superior a 1 minuto.</p>',
    en: '<p>Capture a maximum of 1 minute of video.</p><p>You can use multiple videos, but the overall time</p><p>must not exceed 1 minute.</p>',
    pt: '<p>Capte um vídeo com um máximo de 1 min. Podem ser</p><p>vários vídeos e a sua soma total não deve ser</p><p>superior a 1 minuto.</p>',
    fr: '<p>Capturez une vidéo de 1 minute maximum. Vous pouvez</p><p>fractionnez la vidéo, mais la durée totale ne doit pas</p><p>dépasser 1 minute.</p>',
    eus: '<p>Kapturatu gehienez 1 min-ko bideoa. Bideo ugari erabili</p><p>ditzakezu baina haien iraupena guztira ezin da</p><p>minutu 1 baino luzeagoa izan.</p>',
    cat: '<p>Captura 1 minut màxim de vídeo. Poden ser</p><p>diversos vídeos i la durada total dels quals</p><p>no ha de ser superior a 1 minut.</p>',
  },

  footer_tips_capture_object: {
    es: '<p>: Objeto</p>',
    en: '<p>: Object</p>',
    pt: '<p>: Objeto</p>',
    fr: '<p>: Objet</p>',
    eus: '<p>: Objektua</p>',
    cat: '<p>: Objecte</p>',
  },

  footer_tips_capture_camera_movement: {
    es: '<p>: Movimiento de cámara</p>',
    en: '<p>: Camera moves</p>',
    pt: '<p>: Movimento de câmara</p>',
    fr: '<p>: Mouvement de la caméra</p>',
    eus: '<p>: Kameraren ikuspegia</p>',
    cat: '<p>: Moviment de càmera</p>',
  },

  footer_tips_capture_camera_view: {
    es: '<p>: Vista de cámara</p>',
    en: '<p>: Camera view</p>',
    pt: '<p>: Vista de câmara</p>',
    fr: '<p>: Vue de la caméra</p>',
    eus: '<p>: Kameraren bista</p>',
    cat: '<p>: Vista de càmera</p>',
  },

  changeLanguage: {
    es: '<p>Idioma</p>',
    en: '<p>Language</p>',
    pt: '<p>Idioma</p>',
    fr: '<p>Langue</p>',
    eus: '<p>Hizkuntza</p>',
    cat: '<p>Idioma</p>',
  },

  mobileChangeLanguage: {
    es: '<p>Cambiar idioma</p>',
    en: '<p>Change language</p>',
    pt: '<p>Alterar idioma</p>',
    fr: '<p>Changer langue</p>',
    eus: '<p>Aldatu hizkuntza</p>',
    cat: '<p>Canvia l’idioma</p>',
  },

  iota_insertion_validation: {
    es: '<p>Si</p>',
    en: '<p>Yes</p>',
    pt: '<p>Sim</p>',
    fr: '<p>Oui</p>',
    eus: '<p>Bai</p>',
    cat: '<p>Sí</p>',
  },

  iota_download_title: {
    es: '<p>Informe de descarga de modelo 3D</p>',
    en: '<p>3D model download report</p>',
    pt: '<p>Relatório de transferência de modelo 3D</p>',
    fr: '<p>Rapport de téléchargement de modèle 3D</p>',
    eus: '<p>3D ereduaren deskarga txostena</p>',
    cat: '<p>Informe de baixada de model 3D</p>',
  },

  iota_image_tittle: {
    es: '<p>Informe de envío de imágenes</p>',
    en: '<p>Image transfer report</p>',
    pt: '<p>Relatório de envio de imagens</p>',
    fr: '<p>Rapport d\'envoi d\'images</p>',
    eus: '<p>Irudien bidalketa txostena</p>',
    cat: '<p>Informe d’enviament d’imatges</p>',
  },

  iota_mesh_title: {
    es: '<p>Malla</p>',
    en: '<p>Mesh</p>',
    pt: '<p>Malha</p>',
    fr: '<p>Maille</p>',
    eus: '<p>Sarea</p>',
    cat: '<p>Malla</p>',
  },

  iota_not_availabe: {
    es: '<p>Información no disponible</p>',
    en: '<p>Information unavailable</p>',
    pt: '<p>Informação não disponível</p>',
    fr: '<p>Information non disponible</p>',
    eus: '<p>Ez dago informaziorik</p>',
    cat: '<p>Informació no disponible</p>',
  },

  iota_first_container_title: {
    es: '<p>Información del modelo 3D</p>',
    en: '<p>3D model information</p>',
    pt: '<p>Informação do modelo 3D</p>',
    fr: '<p>Information du modèle 3D</p>',
    eus: '<p>3D ereduaren informazioa</p>',
    cat: '<p>Informació del model 3D</p>',
  },

  iota_first_container_title_processing: {
    es: '<p>Modelo 3D generado</p>',
    en: '<p>Generated 3D model</p>',
    pt: '<p>Modelo 3D criado</p>',
    fr: '<p>Modèle 3D généré</p>',
    eus: '<p>Sortutako 3D eredua</p>',
    cat: '<p>Model 3D generat</p>',
  },

  iota_first_container_name: {
    es: '<p>Nombre del modelo 3D</p>',
    en: '<p>3D model name</p>',
    pt: '<p>Nome do modelo 3D</p>',
    fr: '<p>Nom du modèle 3D</p>',
    eus: '<p>3D ereduaren izena</p>',
    cat: '<p>Nom del model 3D</p>',
  },

  iota_first_container_code: {
    es: '<p>Código asignado</p>',
    en: '<p>Assigned code</p>',
    pt: '<p>Código atribuído</p>',
    fr: '<p>Code assigné</p>',
    eus: '<p>Ezarritako kodea</p>',
    cat: '<p>Codi assignat</p>',
  },

  iota_first_container_date: {
    es: '<p>Fecha</p>',
    en: '<p>Date</p>',
    pt: '<p>Data</p>',
    fr: '<p>Date</p>',
    eus: '<p>Data</p>',
    cat: '<p>Data</p>',
  },

  iota_first_container_hour: {
    es: '<p>Hora</p>',
    en: '<p>Time</p>',
    pt: '<p>Hora</p>',
    fr: '<p>Heure</p>',
    eus: '<p>Ordua</p>',
    cat: '<p>Hora</p>',
  },

  iota_first_container_user: {
    es: '<p>Usuario</p>',
    en: '<p>User</p>',
    pt: '<p>Utilizador</p>',
    fr: '<p>Utilisateur</p>',
    eus: '<p>Erabiltzailea</p>',
    cat: '<p>Usuari</p>',
  },

  iota_first_container_user_code: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  iota_first_container_user_email: {
    es: '<p>Email</p>',
    en: '<p>Email</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>E-mail</p>',
    eus: '<p>Helbide elektronikoa</p>',
    cat: '<p>Adreça electrònica</p>',
  },

  iota_first_container_user_ip: {
    es: '<p>IP</p>',
    en: '<p>IP</p>',
    pt: '<p>IP</p>',
    fr: '<p>IP</p>',
    eus: '<p>IP</p>',
    cat: '<p>IP</p>',
  },

  iota_first_container_user_ip_title: {
    es: '<p>IP SOLICITUD DESCARGA</p>',
    en: '<p>IP DOWNLOAD REQUEST</p>',
    pt: '<p>IP PEDIDO TRANSFERÊNCIA</p>',
    fr: '<p>IP DEMANDE TÉLÉCHARGEMENT</p>',
    eus: '<p>DESKARGA ESKAERAREN IPa</p>',
    cat: '<p>IP SOL·LICITUD BAIXADA</p>',
  },

  iota_image_info_container_title: {
    es: '<p>Imágenes aportadas</p>',
    en: '<p>Images provided</p>',
    pt: '<p>Imagens proporcionadas</p>',
    fr: '<p>Images apportées</p>',
    eus: '<p>Erantsitako irudiak</p>',
    cat: '<p>Imatges aportades</p>',
  },

  iota_image_info_container_desc: {
    es: '<p>Número total de imágenes</p>',
    en: '<p>Total number of images</p>',
    pt: '<p>Número total de imagens</p>',
    fr: '<p>Nombre total d’images</p>',
    eus: '<p>Erantsitako irudi kopurua guztira</p>',
    cat: '<p>Nombre total d’imatges</p>',
  },

  iota_image_info_container_total: {
    es: '<p>Total</p>',
    en: '<p>Total</p>',
    pt: '<p>Total</p>',
    fr: '<p>Total</p>',
    eus: '<p>Guztira</p>',
    cat: '<p>Total</p>',
  },

  iota_video_info_container_title: {
    es: '<p>Vídeos aportados</p>',
    en: '<p>Videos provided</p>',
    pt: '<p>Vídeos proporcionados</p>',
    fr: '<p>Vidéos apportées</p>',
    eus: '<p>Erantsitako bideoak</p>',
    cat: '<p>Vídeos aportats</p>',
  },

  iota_video_info_container_desc: {
    es: '<p>Número total de vídeos</p>',
    en: '<p>Total number of videos</p>',
    pt: '<p>Número total de vídeos</p>',
    fr: '<p>Nombre total de vidéos</p>',
    eus: '<p>Erantsitako bideo kopurua guztira</p>',
    cat: '<p>Nombre total de vídeos</p>',
  },

  iota_video_info_container_recording: {
    es: '<p>Duración total de grabación</p>',
    en: '<p>Total recording time</p>',
    pt: '<p>Duração total de gravação</p>',
    fr: '<p>Durée total de l’enregistrement</p>',
    eus: '<p>Grabaketaren iraupena guztira</p>',
    cat: '<p>Durada total de l’enregistrament</p>',
  },

  iota_video_info_container_recording_seconds: {
    es: '<p>seg.</p>',
    en: '<p>sec.</p>',
    pt: '<p>seg.</p>',
    fr: '<p>sec.</p>',
    eus: '<p>seg.</p>',
    cat: '<p>seg.</p>',
  },

  iota_node_info_container_title: {
    es: '<p>Nodo de seguridad</p>',
    en: '<p>Security node</p>',
    pt: '<p>Nó de segurança</p>',
    fr: '<p>Nœud de sécurité</p>',
    eus: '<p>Segurtasun nodoa</p>',
    cat: '<p>Node de seguretat</p>',
  },

  iota_node_info_container_desc: {
    es: '<p>Inserción en nodo de seguridad IOTA</p>',
    en: '<p>Insertion in IOTA security node</p>',
    pt: '<p>Inserção em nó de segurança IOTA</p>',
    fr: '<p>Insertion dans nœud de sécurité IOTA</p>',
    eus: '<p>IOTA segurtasun nodoan sarrera</p>',
    cat: '<p>Inserció al node de seguretat IOTA</p>',
  },

  iota_node_info_date_title: {
    es: '<p>Fecha/Hora de inserción en nodo</p>',
    en: '<p>Date/Time of insertion in the node</p>',
    pt: '<p>Data/Hora de inserção em nó</p>',
    fr: '<p>Date/heure d’insertion dans nœud</p>',
    eus: '<p>Nodoan sartzeko data/ordua</p>',
    cat: '<p>Data i hora d’inserció al node</p>',
  },

  iota_storage_title: {
    es: '<p>Almacenamiento de datos</p>',
    en: '<p>Data storage</p>',
    pt: '<p>Armazenamento de dados</p>',
    fr: '<p>Stockage de données</p>',
    eus: '<p>Datu biltegiratzea</p>',
    cat: '<p>Emmagatzematge de dades</p>',
  },

  iota_company_title: {
    es: '<p>Empresa</p>',
    en: '<p>Company</p>',
    pt: '<p>Empresa</p>',
    fr: '<p>Entreprise</p>',
    eus: '<p>Enpresa</p>',
    cat: '<p>Empresa</p>',
  },

  iota_server_title: {
    es: '<p>Servidor de almacenamiento</p>',
    en: '<p>Storage server</p>',
    pt: '<p>Servidor de armazenamento</p>',
    fr: '<p>Serveur de stockage</p>',
    eus: '<p>Biltegiratze zerbitzaria</p>',
    cat: '<p>Servidor d\'emmagatzematge</p>',
  },

  iota_features_title: {
    es: '<p>Características</p>',
    en: '<p>Features</p>',
    pt: '<p>Caraterísticas</p>',
    fr: '<p>Caractéristiques</p>',
    eus: '<p>Ezaugarriak</p>',
    cat: '<p>Característiques</p>',
  },

  iota_info_container_reception_tittle: {
    es: '<p>Recepción</p>',
    en: '<p>Reception</p>',
    pt: '<p>Receção</p>',
    fr: '<p>Réception</p>',
    eus: '<p>Datu harrera</p>',
    cat: '<p>Recepció</p>',
  },

  iota_info_container_ip_sent: {
    es: '<p>Ip de envío de datos</p>',
    en: '<p>Data transfer IP</p>',
    pt: '<p>IP de envio de dados</p>',
    fr: '<p>IP d’envoi de données</p>',
    eus: '<p>Datuak igortzeko IPa</p>',
    cat: '<p>IP d’enviament de dades</p>',
  },

  iota_images_stores_number: {
    es: '<p>Número</p>',
    en: '<p>Number</p>',
    pt: '<p>Número</p>',
    fr: '<p>Numéro</p>',
    eus: '<p>Zenbakia</p>',
    cat: '<p>Quantitat</p>',
  },

  iota_server_company_name_ertz: {
    es: '<p>Ertzaintza</p>',
    en: '<p>Ertzaintza</p>',
    pt: '<p>Ertzaintza</p>',
    fr: '<p>Police Basque (Ertzaintza)</p>',
    eus: '<p>Ertzaintza</p>',
    cat: '<p>Ertzaintza</p>',
  },

  iota_server_company_location: {
    es: '<p>Almacenamiento Interno</p>',
    en: '<p>Internal Storage</p>',
    pt: '<p>Armazenamento interno</p>',
    fr: '<p>Stockage interne</p>',
    eus: '<p>Barne biltegiratzea</p>',
    cat: '<p>Emmagatzematge intern</p>',
  },

  iota_server_company_characteristics: {
    es: '<p>Servidor local</p>',
    en: '<p>Local server</p>',
    pt: '<p>Servidor local</p>',
    fr: '<p>Serveur local</p>',
    eus: '<p>Zerbitzari lokala</p>',
    cat: '<p>Servidor local</p>',
  },

  iota_ariadnex_company_name: {
    es: '<p>Ariadnex</p>',
    en: '<p>Ariadnex</p>',
    pt: '<p>Ariadnex</p>',
    fr: '<p>Ariadnex</p>',
    eus: '<p>Ariadnex</p>',
    cat: '<p>Ariadnex</p>',
  },

  iota_ariadnex_location: {
    es: '<p>Centro de procesamiento de Ariadnex, España</p>',
    en: '<p>Ariadnex Processing Center, Spain</p>',
    pt: '<p>Centro de processamento da Ariadnex, Espanha</p>',
    fr: '<p>Centre de traitement de données Ariadnex, Espagne</p>',
    eus: '<p>Ariadnex-en prozesatze zentroa, Espainia</p>',
    cat: '<p>Centre de processament d’Ariadnex, Espanya</p>',
  },

  iota_ariadnex_performance: {
    es: '<p>Clúster Intel Xeon Silver 4210</p>',
    en: '<p>Intel Xeon Silver 4210 Cluster</p>',
    pt: '<p>Cluster Intel Xeon Silver 4210</p>',
    fr: '<p>Cluster Intel Xeon Silver 4210</p>',
    eus: '<p>Intel Xeon Silver 4210 klusterra</p>',
    cat: '<p>Clúster Intel Xeon Silver 4210</p>',
  },

  iota_images_stores_name: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  iota_images_stores_size: {
    es: '<p>Tamaño</p>',
    en: '<p>Size</p>',
    pt: '<p>Tamanho</p>',
    fr: '<p>Taille</p>',
    eus: '<p>Tamaina</p>',
    cat: '<p>Mida</p>',
  },

  iota_reportb: {
    es: '<p>Informe IOTA</p>',
    en: '<p>IOTA report</p>',
    pt: '<p>Relatório IOTA</p>',
    fr: '<p>Rapport IOTA</p>',
    eus: '<p>IOTA txostena</p>',
    cat: '<p>Informe IOTA</p>',
  },

  iota_before_implementation: {
    es: '<p>Este modelo 3D ha sido generado con anterioridad a la implementación del sistema IOTA</p>',
    en: '<p>This 3D model was generated prior to the implementation of the IOTA system</p>',
    pt: '<p>Este modelo 3D foi criado anteriormente à implementação do sistema IOTA</p>',
    fr: '<p>Ce modèle 3D a été généré avant l’implémentation du système IOTA</p>',
    eus: '<p>3D eredu hau IOTA sistema inplementatu aurretik sortu zen</p>',
    cat: '<p>Aquest model 3D ha estat generat abans de la implementació del sistema IOTA</p>',
  },

  iota_acceptb: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },

  iota_images_stores_resolution: {
    es: '<p>Resolución</p>',
    en: '<p>Resolution</p>',
    pt: '<p>Resolução</p>',
    fr: '<p>Résolution</p>',
    eus: '<p>Bereizmena</p>',
    cat: '<p>Resolució</p>',
  },

  iota_images_stores_hash: {
    es: '<p>Hash</p>',
    en: '<p>Hash</p>',
    pt: '<p>Hash</p>',
    fr: '<p>Hash</p>',
    eus: '<p>Hash</p>',
    cat: '<p>Hash</p>',
  },

  iota_images_stores_camera: {
    es: '<p>Cámara</p>',
    en: '<p>Camera</p>',
    pt: '<p>Câmara</p>',
    fr: '<p>Caméra</p>',
    eus: '<p>Kamera</p>',
    cat: '<p>Càmera</p>',
  },

  iota_images_stores_duration: {
    es: '<p>Duración</p>',
    en: '<p>Duration</p>',
    pt: '<p>Duração</p>',
    fr: '<p>Durée</p>',
    eus: '<p>Iraupen</p>',
    cat: '<p>Durada</p>',
  },

  iota_images_stores_used: {
    es: '<p>Empleado</p>',
    en: '<p>Employee</p>',
    pt: '<p>Funcionário</p>',
    fr: '<p>Employé</p>',
    eus: '<p>Langilea</p>',
    cat: '<p>Empleat</p>',
  },

  iota_hash_legend_title: {
    es: '<p>Detalles de archivos de IOTA</p>',
    en: '<p>IOTA Files Details</p>',
    pt: '<p>Pormenores de ficheiros de IOTA</p>',
    fr: '<p>Détails des fichiers de IOTA</p>',
    eus: '<p>IOTA fitxategien xehetasunak</p>',
    cat: '<p>Detalls dels fitxers de IOTA</p>',
  },

  iota_banner_text: {
    es: '<p>eyesCloud3d trabaja con un protocolo de seguridad IOTA basado en Blockchain</p>',
    en: '<p>eyesCloud3d works with IOTA protocol security based on Blockchain</p>',
    pt: '<p>eyesCloud3d trabalha com um protocolo de segurança IOTA baseado em Blockchain</p>',
    fr: '<p>eyesCloud3d utilise un protocole de sécurité IOTA basé sur Blockchain</p>',
    eus: '<p>eyesCloud3d-k Blockchain teknologian oinarritutako IOTA segurtasun protokoloa erabiltzen du.</p>',
    cat: '<p>eyesCloud3d treballa amb un protocol de seguretat IOTA basat en Blockchain</p>',
  },

  iota_footer_text: {
    es: '<p>2021 eyesCloud3d.com es un producto de ecaptureDtech. Cada uno de los modelos 3D generados en eyesCloud3d.com son propiedad de sus usuarios</p>',
    en: '<p>2021 eyesCloud3d.com is a product of ecaptureDtech. Each of the 3D models generated in eyesCloud3d.com is the property of its users</p>',
    pt: '<p>2021 eyesCloud3d.com é um produto da ecaptureDtech. Cada um dos modelos 3D criados em eyesCloud3d.com são da propriedade dos seus utilizadores</p>',
    fr: '<p>2021 eyesCloud3d.com est un produit de ecaptureDtech. Tous les modèles 3D générés sur eyesCloud3d.com appartiennent aux utilisateurs</p>',
    eus: '<p>2021 eyesCloud3d.com ecaptureDtech-en produktua da. Erabiltzailea da eyesCloud3d.com-en sortutako 3D eredu ororen jabe</p>',
    cat: '<p>2021 eyesCloud3d.com és un producte d’ecaptureDtech. Cadascun dels models 3D generats a eyesCloud3d.com és propietat dels seus usuaris</p>',
  },

  iota_user_title: {
    es: '<p>Usuario</p>',
    en: '<p>User</p>',
    pt: '<p>Utilizador</p>',
    fr: '<p>Utilisateur</p>',
    eus: '<p>Erabiltzailea</p>',
    cat: '<p>Usuari</p>',
  },

  iota_action_title: {
    es: '<p>Acción</p>',
    en: '<p>Action</p>',
    pt: '<p>Ação</p>',
    fr: '<p>Action</p>',
    eus: '<p>Ekintza</p>',
    cat: '<p>Acció</p>',
  },

  iota_type_title: {
    es: '<p>Tipo de modelo 3D</p>',
    en: '<p>3D Model Type</p>',
    pt: '<p>Tipo de modelo 3D</p>',
    fr: '<p>Type de modèle 3D</p>',
    eus: '<p>3D eredu mota</p>',
    cat: '<p>Tipus de model 3D</p>',
  },

  iota_downloaded_files_title: {
    es: '<p>Ficheros descargados</p>',
    en: '<p>Downloaded files</p>',
    pt: '<p>Ficheiros transferidos</p>',
    fr: '<p>Fichiers téléchargés</p>',
    eus: '<p>Deskargatutako fitxategiak</p>',
    cat: '<p>Fitxers baixats</p>',
  },

  iota_format_title: {
    es: '<p>Formato</p>',
    en: '<p>Format</p>',
    pt: '<p>Formato</p>',
    fr: '<p>Format</p>',
    eus: '<p>Formatua</p>',
    cat: '<p>Format</p>',
  },

  iota_downloaded_title: {
    es: '<p>Ficheros descargados</p>',
    en: '<p>Downloaded files</p>',
    pt: '<p>Ficheiros transferidos</p>',
    fr: '<p>Fichiers téléchargés</p>',
    eus: '<p>Deskargatutako fitxategiak</p>',
    cat: '<p>Fitxers baixats</p>',
  },

  iota_downloaded_desc: {
    es: '<p>Número total de ficheros</p>',
    en: '<p>Total number of files</p>',
    pt: '<p>Número total de ficheiros</p>',
    fr: '<p>Nombre total de fichiers</p>',
    eus: '<p>Fitxategi kopurua guztira</p>',
    cat: '<p>Nombre total de fitxers</p>',
  },

  iota_download_format_desc: {
    es: '<p>Formato de descarga</p>',
    en: '<p>Download format</p>',
    pt: '<p>Formato de transferência</p>',
    fr: '<p>Format de téléchargement</p>',
    eus: '<p>Deskargaren formatua</p>',
    cat: '<p>Format de baixada</p>',
  },

  iota_download_vertex_desc: {
    es: '<p>Nº Puntos / Vertices</p>',
    en: '<p>Number of points/vertices</p>',
    pt: '<p>N.º Pontos/Vértices</p>',
    fr: '<p>Nº de Points / Sommets</p>',
    eus: '<p>Puntu/erpin kopurua</p>',
    cat: '<p>Nre. punts / vèrtexs</p>',
  },

  iota_download_polygons_desc: {
    es: '<p>Nº Polígonos</p>',
    en: '<p>Number of polygons</p>',
    pt: '<p>N.º Polígonos</p>',
    fr: '<p>Nº de Polygones</p>',
    eus: '<p>Poligono kopurua</p>',
    cat: '<p>Nre. polígons</p>',
  },

  iota_proccessing_title: {
    es: '<p>Informe de procesamiento de modelo 3D</p>',
    en: '<p>3D model processing report</p>',
    pt: '<p>Relatório de processamento de modelo 3D</p>',
    fr: '<p>Rapport du traitement du modèle 3D</p>',
    eus: '<p>3D ereduaren prozesatze txostena</p>',
    cat: '<p>Informe de processament del model 3D</p>',
  },

  iota_process_made_title: {
    es: '<p>Procesos realizados</p>',
    en: '<p>Processes completed</p>',
    pt: '<p>Processamentos efetuados</p>',
    fr: '<p>Processus réalisés</p>',
    eus: '<p>Burututako prozesuak</p>',
    cat: '<p>Processos efectuats</p>',
  },

  iota_process_info_title: {
    es: '<p>Procesado</p>',
    en: '<p>Processed</p>',
    pt: '<p>Processado</p>',
    fr: '<p>Traité</p>',
    eus: '<p>Prozesaketa</p>',
    cat: '<p>S’ha processat</p>',
  },

  iota_process_time_title: {
    es: '<p>Tiempo empleado</p>',
    en: '<p>Time Used</p>',
    pt: '<p>Tempo empregue</p>',
    fr: '<p>Temps d’exécution</p>',
    eus: '<p>Erabilitako denbora</p>',
    cat: '<p>Temps emprat</p>',
  },

  iota_process_name_tittle: {
    es: '<p>Nombre del proceso</p>',
    en: '<p>Process name</p>',
    pt: '<p>Nome do processamento</p>',
    fr: '<p>Nom du processus</p>',
    eus: '<p>Prozesuaren izena</p>',
    cat: '<p>Nom del procés</p>',
  },

  iota_process_image_used_yes: {
    es: '<p>Si</p>',
    en: '<p>Yes</p>',
    pt: '<p>Sim</p>',
    fr: '<p>Oui</p>',
    eus: '<p>Bai</p>',
    cat: '<p>Sí</p>',
  },

  iota_process_image_used_no: {
    es: '<p>No</p>',
    en: '<p>No</p>',
    pt: '<p>Não</p>',
    fr: '<p>Non</p>',
    eus: '<p>Ez</p>',
    cat: '<p>No</p>',
  },

  iota_process_index_prefix: {
    es: '<p>Proceso número</p>',
    en: '<p>Process number</p>',
    pt: '<p>Processamento número</p>',
    fr: '<p>Processus numéro</p>',
    eus: '<p>Prozesuaren zenbakia</p>',
    cat: '<p>Procés número</p>',
  },

  iota_process_start_title: {
    es: '<p>Inicio del procesamiento</p>',
    en: '<p>Process starting point</p>',
    pt: '<p>Início do processamento</p>',
    fr: '<p>Début du traitement</p>',
    eus: '<p>Prozesamenduaren hasiera</p>',
    cat: '<p>Inici del processament</p>',
  },

  iota_process_ending_title: {
    es: '<p>Finalización del procesamiento</p>',
    en: '<p>Process ending point</p>',
    pt: '<p>Conclusão do processamento</p>',
    fr: '<p>Fin du traitement</p>',
    eus: '<p>Prozesamenduaren amaiera</p>',
    cat: '<p>Finalització del processament</p>',
  },

  iota_transform_mesh_scale_new: {
    es: '<p>Malla - Nueva escala</p>',
    en: '<p>Mesh - New scale</p>',
    pt: '<p>Malha - Nova escala</p>',
    fr: '<p>Maille - Nouvelle échelle</p>',
    eus: '<p>Sarea - Eskala berria ezarri da</p>',
    cat: '<p>Malla - Nova escala</p>',
  },

  iota_transform_mesh_floor_new: {
    es: '<p>Malla - Cambio del sistema de referencia por establecimiento de plano suelo</p>',
    en: '<p>Mesh - Change of reference system due to establishment of ground plane</p>',
    pt: '<p>Malha - Alteração do sistema de referência por estabelecimento de plano chão</p>',
    fr: '<p>Maille - Changement du système de référence dû à l’établissement du plan sol</p>',
    eus: '<p>Sarea - Erreferentzia sistema aldatu da lurzoru planoa ezartzean</p>',
    cat: '<p>Malla - Canvi del sistema de referència per establiment de pla sòl</p>',
  },

  iota_transform_mesh_floor_modify: {
    es: '<p>Malla - Suelo modificado</p>',
    en: '<p>Mesh - Floor modified</p>',
    pt: '<p>Malha - Chão alterado</p>',
    fr: '<p>Maille - Sol modifié</p>',
    eus: '<p>Sarea - Lurzorua aldatu da</p>',
    cat: '<p>Malla - Sòl modificat</p>',
  },

  iota_transform_mesh_floor_delete: {
    es: '<p>Malla - Anulación de sistema de referencia por eliminación de plano suelo</p>',
    en: '<p>Mesh - Cancellation of reference system due to deletion of ground plane</p>',
    pt: '<p>Malha - Anulação de sistema de referência por eliminação de plano chão</p>',
    fr: '<p>Maille - Annulation du système de référence due à l’élimination du plan sol</p>',
    eus: '<p>Sarea - Erreferentzia sistema deuseztatu da lurzoru planoa ezabatzean</p>',
    cat: '<p>Malla - Anul·lació de sistema de referència per eliminació de pla sòl</p>',
  },

  iota_transform_mesh_reference_new: {
    es: '<p>Malla - Nueva referencia</p>',
    en: '<p>Mesh - New reference</p>',
    pt: '<p>Malha - Nova referência</p>',
    fr: '<p>Maille - Nouvelle référence</p>',
    eus: '<p>Sarea - Erreferentzia berria ezarri da</p>',
    cat: '<p>Malla - Nova referència</p>',
  },

  iota_transform_mesh_reference_modify: {
    es: '<p>Malla - Cambio de sistema de referencia</p>',
    en: '<p>Mesh - Change of reference system</p>',
    pt: '<p>Malha - Alteração de sistema de referência</p>',
    fr: '<p>Maille - Changement du système de référence</p>',
    eus: '<p>Sarea - Erreferentzia sistema aldatu da</p>',
    cat: '<p>Malla - Canvi de sistema de referència</p>',
  },

  iota_transform_mesh_reference_delete: {
    es: '<p>Malla - Anulación de sistema de referencia</p>',
    en: '<p>Mesh - Reset of reference system</p>',
    pt: '<p>Malha - Anulação de sistema de referência</p>',
    fr: '<p>Maille - Suppression du système de référence</p>',
    eus: '<p>Sarea - Erreferentzia sistema deuseztatu da</p>',
    cat: '<p>Malla - Anul·lació de sistema de referència</p>',
  },

  iota_transform_mesh_georreference_new: {
    es: '<p>Malla - Aplicación de georreferenciación</p>',
    en: '<p>Mesh - Application of georeferencing</p>',
    pt: '<p>Malha - Aplicação de georreferenciação</p>',
    fr: '<p>Maille - Mise en place du géoréférencement</p>',
    eus: '<p>Sarea - Geoerreferentziazioa aplikatu da</p>',
    cat: '<p>Malla - Aplicació de georeferenciació</p>',
  },

  iota_transform_mesh_georreference_modify: {
    es: '<p>Malla - Georreferencia modificada</p>',
    en: '<p>Mesh - Georreference modified</p>',
    pt: '<p>Malha - Georreferência alterada</p>',
    fr: '<p>Maille - Géoréférence modifiée</p>',
    eus: '<p>Sarea - Geoerreferentzia aldatu da</p>',
    cat: '<p>Malla - Georeferencia modificada</p>',
  },

  iota_transform_mesh_georreference_delete: {
    es: '<p>Malla - Eliminación de datos de georreferenciación</p>',
    en: '<p>Mesh - Deletion of georeferencing data</p>',
    pt: '<p>Malha - Eliminação de dados de georreferenciação</p>',
    fr: '<p>Maille - Suppression des données de géoréférencement</p>',
    eus: '<p>Sarea - Geoerreferentziazio datuak ezabatu dira</p>',
    cat: '<p>Malla - Eliminació de dades de georeferenciació</p>',
  },

  iota_transform_mesh_clipping_new: {
    es: '<p>Malla - Recorte sobre área seleccionada</p>',
    en: '<p>Mesh - Crop selected area</p>',
    pt: '<p>Malha - Recorte em área selecionada</p>',
    fr: '<p>Maille - Découpe sur la zone sélectionnée</p>',
    eus: '<p>Sarea - Ebakina hautatutako eremuan egin da</p>',
    cat: '<p>Malla - Retall sobre àrea seleccionada</p>',
  },

  iota_transform_mesh_clipping_modify: {
    es: '<p>Malla - Recorte modificado</p>',
    en: '<p>Mesh - Crop modified</p>',
    pt: '<p>Malha - Recorte alterado</p>',
    fr: '<p>Maille - Découpe modifiée</p>',
    eus: '<p>Sarea - Ebakina aldatu da</p>',
    cat: '<p>Malla - Retall modificat</p>',
  },

  iota_transform_mesh_clipping_delete: {
    es: '<p>Malla - Recorte eliminado</p>',
    en: '<p>Mesh - Crop deleted</p>',
    pt: '<p>Malha - Recorte eliminado</p>',
    fr: '<p>Maille - Découpe supprimée</p>',
    eus: '<p>Sarea - Ebakina ezabatu da</p>',
    cat: '<p>Malla - Retall eliminat</p>',
  },

  iota_transform_mesh_reset_new: {
    es: '<p>Malla - Reestablecido a estado inicial de la sesión del usuario</p>',
    en: '<p>Mesh - Reset initial status of the user session</p>',
    pt: '<p>Malha - Reposto para estado inicial da sessão do utilizador</p>',
    fr: '<p>Maille - Rétablir état initial de la session d’utilisateur</p>',
    eus: '<p>Sarea - Erabiltzailearen saio-hasierako egoerara berrezarri da</p>',
    cat: '<p>Malla - Restablert a l\'estat inicial de la sessió de l\'usuari</p>',
  },

  iota_transform_mesh_reset_modify: {
    es: '<p>Malla - Reset modificado</p>',
    en: '<p>Mesh - Reset modified</p>',
    pt: '<p>Malha - Reposição alterada</p>',
    fr: '<p>Maille - Réinitialisation modifiée</p>',
    eus: '<p>Sarea - Berrezarpena aldatu da</p>',
    cat: '<p>Malla - Restabliment modificat</p>',
  },

  iota_transform_mesh_reset_delete: {
    es: '<p>Malla - Reset eliminado</p>',
    en: '<p>Mesh - Reset deleted</p>',
    pt: '<p>Malha - Reposição eliminada</p>',
    fr: '<p>Maille - Réinitialisation éliminée</p>',
    eus: '<p>Sarea - Berrezarpena ezabatu da</p>',
    cat: '<p>Malla - Restabliment eliminat</p>',
  },

  iota_transform_point_cloud_scale_new: {
    es: '<p>Nube de puntos - Nueva escala</p>',
    en: '<p>Point Cloud - New scale</p>',
    pt: '<p>Nuvem de pontos - Nova escala</p>',
    fr: '<p>Nuage de points - Nouvelle échelle</p>',
    eus: '<p>Puntu hodeia - Eskala berria ezarri da</p>',
    cat: '<p>Núvol de punts - Nova escala</p>',
  },

  iota_transform_point_cloud_scale_modify: {
    es: '<p>Nube de puntos - Cambio de escala del modelo</p>',
    en: '<p>Point Cloud - Change of model scale</p>',
    pt: '<p>Nuvem de pontos - Alteração de escala do modelo</p>',
    fr: '<p>Nuage de points - Changement d’échelle du modèle</p>',
    eus: '<p>Puntu hodeia - Ereduaren eskala aldatu da</p>',
    cat: '<p>Núvol de punts - Canvi de l\'escala del model</p>',
  },

  iota_transform_point_cloud_scale_delete: {
    es: '<p>Nube de puntos - Eliminación de escala</p>',
    en: '<p>Point Cloud - Deletion of scale</p>',
    pt: '<p>Nuvem de pontos - Eliminação de escala</p>',
    fr: '<p>Nuage de points - Suppression de l’échelle</p>',
    eus: '<p>Puntu hodeia - Eskala ezabatu da</p>',
    cat: '<p>Núvol de punts - Eliminació de l\'escala</p>',
  },

  iota_transform_point_cloud_floor_new: {
    es: '<p>Nube de puntos - Cambio del sistema de referencia por establecimiento de plano suelo</p>',
    en: '<p>Point Cloud - Change of reference system due to establishment of ground plane</p>',
    pt: '<p>Nuvem de pontos - Alteração do sistema de referência por estabelecimento de plano chão</p>',
    fr: '<p>Nuage de points - Changement du système de référence dû à l’établissement du plan sol</p>',
    eus: '<p>Puntu hodeia - Erreferentzia sistema aldatu da lurzoru planoa ezartzean</p>',
    cat: '<p>Núvol de punts - Canvi del sistema de referència per establiment de pla sòl</p>',
  },

  iota_transform_point_cloud_floor_modify: {
    es: '<p>Nube de puntos - Suelo modificado</p>',
    en: '<p>Point Cloud - Floor modified</p>',
    pt: '<p>Nuvem de pontos - Chão alterado</p>',
    fr: '<p>Nuage de points - Sol modifié</p>',
    eus: '<p>Puntu hodeia - Lurzorua aldatu da</p>',
    cat: '<p>Núvol de punts - Sòl modificat</p>',
  },

  iota_transform_point_cloud_floor_delete: {
    es: '<p>Nube de puntos - Anulación del sistema de referencia por eliminación del plano suelo</p>',
    en: '<p>Point Cloud - Cancellation of reference system due to deletion of ground plane</p>',
    pt: '<p>Nuvem de pontos - Anulação do sistema de referência por eliminação do plano chão</p>',
    fr: '<p>Nuage de points - Annulation du système de référence due à l’élimination du plan sol</p>',
    eus: '<p>Puntu hodeia - Erreferentzia sistema deuseztatu da lurzoru planoa ezabatzean</p>',
    cat: '<p>Núvol de punts - Anul·lació del sistema de referència per eliminació del pla sòl</p>',
  },

  iota_transform_point_cloud_reference_new: {
    es: '<p>Nube de puntos - Nueva referencia</p>',
    en: '<p>Point Cloud - New reference</p>',
    pt: '<p>Nuvem de pontos - Nova referência</p>',
    fr: '<p>Nuage de points - Nouvelle référence</p>',
    eus: '<p>Puntu hodeia - Erreferentzia berria ezarri da</p>',
    cat: '<p>Núvol de punts - Nova referència</p>',
  },

  iota_transform_point_cloud_reference_modify: {
    es: '<p>Nube de puntos - Cambio de sistema de referencia</p>',
    en: '<p>Point Cloud - Reference system change</p>',
    pt: '<p>Nuvem de pontos - Alteração de sistema de referência</p>',
    fr: '<p>Nuage de points - Changement du système de référence</p>',
    eus: '<p>Puntu hodeia - Erreferentzia sistema aldatu da</p>',
    cat: '<p>Núvol de punts - Canvi de sistema de referència</p>',
  },

  iota_transform_point_cloud_reference_delete: {
    es: '<p>Nube de puntos - Anulación de sistema de referencia</p>',
    en: '<p>Point Cloud - Reset of reference system</p>',
    pt: '<p>Nuvem de pontos - Anulação de sistema de referência</p>',
    fr: '<p>Nuage de points - Suppression du système de référence</p>',
    eus: '<p>Puntu hodeia - Erreferentzia sistema deuseztatu da</p>',
    cat: '<p>Núvol de punts - Anul·lació de sistema de referència</p>',
  },

  iota_transform_point_cloud_georreference_new: {
    es: '<p>Nube de puntos - Aplicación de georreferenciación</p>',
    en: '<p>Point Cloud - Application of georeferencing</p>',
    pt: '<p>Nuvem de pontos - Aplicação de georreferenciação</p>',
    fr: '<p>Nuage de points - Mise en place du géoréférencement</p>',
    eus: '<p>Puntu hodeia - Geoerreferentziazioa aplikatu da</p>',
    cat: '<p>Núvol de punts - Aplicació de georeferenciació</p>',
  },

  iota_transform_point_cloud_georreference_modify: {
    es: '<p>Nube de puntos - Georreferencia modificada</p>',
    en: '<p>Point Cloud - Georreference modified</p>',
    pt: '<p>Nuvem de pontos - Georreferência alterada</p>',
    fr: '<p>Nuage de points - Géoréférence modifiée</p>',
    eus: '<p>Puntu hodeia - Geoerreferentzia aldatu da</p>',
    cat: '<p>Núvol de punts - Georeferencia modificada</p>',
  },

  iota_transform_point_cloud_georreference_delete: {
    es: '<p>Nube de puntos - Eliminación de datos de georreferenciación</p>',
    en: '<p>Point Cloud - Deletion of georeferencing data</p>',
    pt: '<p>Nuvem de pontos - Eliminação de dados de georreferenciação</p>',
    fr: '<p>Nuage de points - Suppression des données de géroréférencement</p>',
    eus: '<p>Puntu hodeia - Geoerreferentziazio datuak ezabatu dira</p>',
    cat: '<p>Núvol de punts - Eliminació de dades de georeferenciació</p>',
  },

  iota_transform_point_cloud_clipping_new: {
    es: '<p>Nube de puntos - Recorte sobre área seleccionada</p>',
    en: '<p>Point Cloud - Crop selected area</p>',
    pt: '<p>Nuvem de pontos - Recorte em área selecionada</p>',
    fr: '<p>Nuage de points - Découpe sur la zone sélectionnée</p>',
    eus: '<p>Puntu hodeia - Ebakina hautatutako eremuan egin da</p>',
    cat: '<p>Núvol de punts - Retall sobre àrea seleccionada</p>',
  },

  iota_transform_point_cloud_clipping_modify: {
    es: '<p>Nube de puntos - Recorte modificado</p>',
    en: '<p>Point Cloud - Crop modified</p>',
    pt: '<p>Nuvem de pontos - Recorte alterado</p>',
    fr: '<p>Nuage de points - Découpe modifié</p>',
    eus: '<p>Puntu hodeia - Ebakina aldatu da</p>',
    cat: '<p>Núvol de punts - Retall modificat</p>',
  },

  iota_transform_point_cloud_clipping_delete: {
    es: '<p>Nube de puntos - Recorte eliminado</p>',
    en: '<p>Point Cloud - Crop deleted</p>',
    pt: '<p>Nuvem de pontos - Recorte eliminado</p>',
    fr: '<p>Nuage de points - Découpe éliminé</p>',
    eus: '<p>Puntu hodeia - Ebakina ezabatu da</p>',
    cat: '<p>Núvol de punts - Retall eliminat</p>',
  },

  iota_transform_point_cloud_reset_new: {
    es: '<p>Nube de puntos - Reestablecido a estado inicial de la sesión del usuario</p>',
    en: '<p>Point Cloud - Reset initial status of the user session</p>',
    pt: '<p>Nuvem de pontos - Reposto para estado inicial da sessão do utilizador</p>',
    fr: '<p>Nuage de points - Rétablissement à l’état initial de la session d’utilisateur</p>',
    eus: '<p>Puntu hodeia - Erabiltzailearen saio-hasierako egoerara berrezarri da</p>',
    cat: '<p>Núvol de punts - Restablert a l’estat inicial de la sessió de l\'usuari</p>',
  },

  iota_transform_point_cloud_reset_modify: {
    es: '<p>Nube de puntos - Reset modificado</p>',
    en: '<p>Point Cloud - Reset modified</p>',
    pt: '<p>Nuvem de pontos - Reposição alterada</p>',
    fr: '<p>Nuage de points - Réinitialisation modifiée</p>',
    eus: '<p>Puntu hodeia - Berrezarpena aldatu da</p>',
    cat: '<p>Núvol de punts - Restabliment modificat</p>',
  },

  iota_transform_point_cloud_reset_delete: {
    es: '<p>Nube de puntos - Reset eliminado</p>',
    en: '<p>Point Cloud - Reset deleted</p>',
    pt: '<p>Nuvem de pontos - Reposição eliminada</p>',
    fr: '<p>Nuage de points - Réinitialisation éliminée</p>',
    eus: '<p>Puntu hodeia - Berrezarpena ezabatu da</p>',
    cat: '<p>Núvol de punts - Restabliment eliminat</p>',
  },

  iota_transform_note_new: {
    es: '<p>Inserción de nota</p>',
    en: '<p>Note insertion</p>',
    pt: '<p>Inserção de nota</p>',
    fr: '<p>Insérer une note</p>',
    eus: '<p>Oharra txertatu da</p>',
    cat: '<p>Inserció de nota</p>',
  },

  iota_transform_note_modify: {
    es: '<p>Nota modificada</p>',
    en: '<p>Note modified</p>',
    pt: '<p>Nota alterada</p>',
    fr: '<p>Note modifiée</p>',
    eus: '<p>Oharra aldatu da</p>',
    cat: '<p>Nota modificada</p>',
  },

  iota_transform_note_delete: {
    es: '<p>Nota eliminada</p>',
    en: '<p>Note deleted</p>',
    pt: '<p>Nota eliminada</p>',
    fr: '<p>Note éliminée</p>',
    eus: '<p>Oharra ezabatu da</p>',
    cat: '<p>Nota eliminada</p>',
  },

  iota_transform_classification_new: {
    es: '<p>Clasificación sobre área seleccionada</p>',
    en: '<p>Classification of selected area</p>',
    pt: '<p>Classificação sobre área selecionada</p>',
    fr: '<p>Classification sur zone sélectionnée</p>',
    eus: '<p>Sailkapena hautatutako eremuan egin da</p>',
    cat: '<p>Classificació sobre l’àrea seleccionada</p>',
  },

  iota_transform_classification_modify: {
    es: '<p>Clasificación modificada</p>',
    en: '<p>Classification modified</p>',
    pt: '<p>Classificação alterada</p>',
    fr: '<p>Classification modifiée</p>',
    eus: '<p>Sailkapena aldatu da</p>',
    cat: '<p>Classificació modificada</p>',
  },

  iota_transform_classification_delete: {
    es: '<p>Eliminación de clasificación</p>',
    en: '<p>Deletion of classification</p>',
    pt: '<p>Eliminação de classificação</p>',
    fr: '<p>Éliminer la classification</p>',
    eus: '<p>Sailkapena ezabatu da</p>',
    cat: '<p>Eliminació de classificació</p>',
  },

  iota_process_start_workspace: {
    es: '<p>Inicialización de procesamiento</p>',
    en: '<p>Start of processing</p>',
    pt: '<p>Inicialização do processamento</p>',
    fr: '<p>Initialisation du traitement</p>',
    eus: '<p>Prozesamendua hasi da</p>',
    cat: '<p>Inicialització de processament</p>',
  },

  iota_process_file_format_validation: {
    es: '<p>Validación de formato de ficheros</p>',
    en: '<p>Validation of file formats</p>',
    pt: '<p>Validação de formato de ficheiros</p>',
    fr: '<p>Validation du format des fichiers</p>',
    eus: '<p>Fitxategien formatua baliozkotu da</p>',
    cat: '<p>Validació de format de fitxers</p>',
  },

  iota_process_neural_networks: {
    es: '<p>Detección de tipo de escenario</p>',
    en: '<p>Detection of scenario type</p>',
    pt: '<p>Deteção de tipo de cena</p>',
    fr: '<p>Détection du type de scène</p>',
    eus: '<p>Gertaleku mota antzeman da</p>',
    cat: '<p>Detecció de tipus d\'escenari</p>',
  },

  iota_process_hyperspectral_classification: {
    es: '<p>Comprobación de datos hiperespectrales</p>',
    en: '<p>Checking of hyperspectral data</p>',
    pt: '<p>Comprovação de dados hiperespetrais</p>',
    fr: '<p>Vérification des données hyperspectrales</p>',
    eus: '<p>Datu hiperespektralak egiaztatu dira</p>',
    cat: '<p>Comprovació de dades hiperespectrals</p>',
  },

  iota_process_360_classification: {
    es: '<p>Comprobación de datos 360</p>',
    en: '<p>Checking of 360 data</p>',
    pt: '<p>Comprovação de dados 360</p>',
    fr: '<p>Vérification des données 360</p>',
    eus: '<p>360 datuak egiaztatu dira</p>',
    cat: '<p>Comprovació de dades 360</p>',
  },

  iota_process_initialize_specific_workspace: {
    es: '<p>Optimización de datos</p>',
    en: '<p>Optimization of data</p>',
    pt: '<p>Otimização de dados</p>',
    fr: '<p>Optimisation des données</p>',
    eus: '<p>Datuak optimizatu dira</p>',
    cat: '<p>Optimització de dades</p>',
  },

  iota_process_georref: {
    es: '<p>Cálculo de datos de georreferenciación</p>',
    en: '<p>Calculation of georeferencing data</p>',
    pt: '<p>Cálculo de dados de georreferenciação</p>',
    fr: '<p>Calcul des données de géoréférencement</p>',
    eus: '<p>Geoerreferentziazio datuak kalkulatu dira</p>',
    cat: '<p>Càlcul de dades de georeferenciació</p>',
  },

  iota_process_detecting_targets: {
    es: '<p>Detección de marcadores de escala en escenario</p>',
    en: '<p>Detection of scale markers in scenario</p>',
    pt: '<p>Deteção de marcadores de escala em cena</p>',
    fr: '<p>Détection des marqueurs d’échelle sur la scène</p>',
    eus: '<p>Gertalekuan eskala markatzaileak antzeman dira</p>',
    cat: '<p>Detecció de marcadors d\'escala en escenari</p>',
  },

  iota_process_compute_features: {
    es: '<p>Obtención de puntos clave</p>',
    en: '<p>Obtainment of key points</p>',
    pt: '<p>Obtenção de pontos-chave</p>',
    fr: '<p>Obtention des points clés</p>',
    eus: '<p>Gako puntuak lortu dira</p>',
    cat: '<p>Obtenció de punts clau</p>',
  },

  iota_process_compute_matches: {
    es: '<p>Emparejamiento de puntos clave</p>',
    en: '<p>Matching of key points</p>',
    pt: '<p>Emparelhamento de pontos-chave</p>',
    fr: '<p>Couplage des points clés</p>',
    eus: '<p>Gako puntuak parekatu dira</p>',
    cat: '<p>Aparellament de punts clau</p>',
  },

  iota_process_calculate_sfm: {
    es: '<p>Calibración y orientación de cámaras</p>',
    en: '<p>Calibration/orientation of cameras</p>',
    pt: '<p>Calibração e orientação de câmaras</p>',
    fr: '<p>Calibration et orientation des caméras</p>',
    eus: '<p>Kamerak kalibratu eta orientatu dira</p>',
    cat: '<p>Calibratge i orientació de càmeres</p>',
  },

  iota_process_sfm_rotation: {
    es: '<p>Optimización de orientación</p>',
    en: '<p>Optimization of orientation</p>',
    pt: '<p>Otimização de orientação</p>',
    fr: '<p>Optimisation de l’orientation</p>',
    eus: '<p>Orientazioa optimizatu da</p>',
    cat: '<p>Optimització d\'orientació</p>',
  },

  iota_process_apply_target_scale: {
    es: '<p>Aplicación de escala mediante marcadores</p>',
    en: '<p>Application of scale via markers</p>',
    pt: '<p>Aplicação de escala através de marcadores</p>',
    fr: '<p>Application de l’échelle grâce aux marqueurs</p>',
    eus: '<p>Eskala aplikatu da markatzaileen bidez</p>',
    cat: '<p>Aplicació d\'escala mitjançant marcadors </p>',
  },

  iota_process_apply_georref_scale: {
    es: '<p>Aplicación de datos de georreferenciación</p>',
    en: '<p>Application of georeferencing data</p>',
    pt: '<p>Aplicação de dados de georreferenciação</p>',
    fr: '<p>Application des données de géoréférencement</p>',
    eus: '<p>Geoerreferentziazio datuak aplikatu dira</p>',
    cat: '<p>Aplicació de dades de georeferenciació</p>',
  },

  iota_process_copy_sfm_to_folder: {
    es: '<p>Salvado de datos de escena</p>',
    en: '<p>Saving of scene data</p>',
    pt: '<p>Recuperação de dados de cena</p>',
    fr: '<p>Sauvegarde des données de scène</p>',
    eus: '<p>Eszenaren datuak gorde dira</p>',
    cat: '<p>Conservació de dades d\'escena</p>',
  },

  iota_process_export_rgb_data: {
    es: '<p>Generación de marco común para reconstrucción 3D</p>',
    en: '<p>Generation of common framework for 3D reconstruction</p>',
    pt: '<p>Criação de moldura comum para reconstrução 3D</p>',
    fr: '<p>Création d’un cadre commun pour la reconstruction 3D</p>',
    eus: '<p>3D berreraikuntzarako esparru komuna sortu da/p>',
    cat: '<p>Generació de marc comú per a reconstrucció 3D</p>',
  },

  iota_process_densify_point_cloud: {
    es: '<p>Densificación de nube de puntos 3D</p>',
    en: '<p>Densification of 3D point clouds</p>',
    pt: '<p>Densificação de nuvem de pontos 3D</p>',
    fr: '<p>Densification du nuage de points 3D</p>',
    eus: '<p>3D puntu hodeia dentsifikatu da</p>',
    cat: '<p>Densificació de núvol de punts 3D</p>',
  },

  iota_process_generate_mesh: {
    es: '<p>Generación modelo 3D</p>',
    en: '<p>3D model generation</p>',
    pt: '<p>Criação de modelo 3D</p>',
    fr: '<p>Génération modèle 3D</p>',
    eus: '<p>3D eredua sortu da</p>',
    cat: '<p>Generació de model 3D</p>',
  },

  iota_process_mesh_simplification: {
    es: '<p>Optimización de modelo 3D</p>',
    en: '<p>3D model optimization</p>',
    pt: '<p>Otimização de modelo 3D</p>',
    fr: '<p>Optimisation du modèle 3D</p>',
    eus: '<p>3D eredua optimizatu da</p>',
    cat: '<p>Optimització de model 3D</p>',
  },

  iota_process_mesh_rgb_textures_generation: {
    es: '<p>Generación de texturas</p>',
    en: '<p>Generation of textures</p>',
    pt: '<p>Criação de texturas</p>',
    fr: '<p>Création des textures</p>',
    eus: '<p>Testurak sortu dira</p>',
    cat: '<p>Generació de textures</p>',
  },

  iota_process_clean_point_cloud: {
    es: '<p>Optimización de nube de puntos</p>',
    en: '<p>Optimization of point clouds</p>',
    pt: '<p>Otimização de nuvem de pontos</p>',
    fr: '<p>Optimisation du nuage de points</p>',
    eus: '<p>Puntu hodeia optimizatu da</p>',
    cat: '<p>Optimització de núvol de punts</p>',
  },

  iota_process_refill_textures: {
    es: '<p>Optimización de texturas</p>',
    en: '<p>Optimization of textures</p>',
    pt: '<p>Otimização de texturas</p>',
    fr: '<p>Optimisation des textures</p>',
    eus: '<p>Testurak optimizatu dira</p>',
    cat: '<p>Optimització de textures</p>',
  },

  iota_process_saving_log_files: {
    es: '<p>Salvado de log de proceso</p>',
    en: '<p>Saving of process log</p>',
    pt: '<p>Recuperação de log de processamento</p>',
    fr: '<p>Sauvegarde du log de traitement</p>',
    eus: '<p>Prozesuaren egunkaria gorde da</p>',
    cat: '<p>Conservació de registre de procés</p>',
  },

  iota_process_packaging_results: {
    es: '<p>Empaquetado resultado</p>',
    en: '<p>Unwrapped result</p>',
    pt: '<p>Embalamento resultado</p>',
    fr: '<p>Empaquetage du résultat</p>',
    eus: '<p>Emaitza paketatu da</p>',
    cat: '<p>Empaquetat resultat</p>',
  },

  iota_process_glb_generation: {
    es: '<p>Compresión de modelo 3D</p>',
    en: '<p>Compression of 3D model</p>',
    pt: '<p>Compressão de modelo 3D</p>',
    fr: '<p>Compression du modèle 3D</p>',
    eus: '<p>3D eredua konprimatu da</p>',
    cat: '<p>Compressió de model 3D</p>',
  },

  iota_process_potree_generation: {
    es: '<p>Compresión de nube de puntos</p>',
    en: '<p>Compression of point cloud</p>',
    pt: '<p>Compressão de nuvem de pontos</p>',
    fr: '<p>Compression du nuage de points</p>',
    eus: '<p>Puntu hodeia konprimatu da</p>',
    cat: '<p>Compressió de núvol de punts</p>',
  },

  iota_process_extract_frames: {
    es: '<p>Detección de fotogramas clave de video</p>',
    en: '<p>Detection of key video frames</p>',
    pt: '<p>Deteção de fotogramas-chave de vídeo</p>',
    fr: '<p>Détection d’images clés dans la vidéo</p>',
    eus: '<p>Bideoaren gako fotogramak antzeman dira</p>',
    cat: '<p>Detecció de fotogrames clau de vídeo</p>',
  },

  iota_process_rgb_data_to_mve: {
    es: '<p>Generación de marco común para cálculo de texturas</p>',
    en: '<p>Generation of common framework for calculation of textures</p>',
    pt: '<p>Criação de moldura comum para cálculo de texturas</p>',
    fr: '<p>Création d’un cadre commun pour le calcul des textures</p>',
    eus: '<p>Testurak kalkulatzeko esparru komuna sortu da</p>',
    cat: '<p>Generació de marc comú per a càlcul de textures</p>',
  },

  iota_process_seconds: {
    es: '<p>seg.</p>',
    en: '<p>sec.</p>',
    pt: '<p>seg.</p>',
    fr: '<p>sec.</p>',
    eus: '<p>seg.</p>',
    cat: '<p>seg.</p>',
  },

  begin_upload_external: {
    es: '<p>ACTIVAR SUBIDA</p>',
    en: '<p>ACTIVATE UPLOAD</p>',
    pt: '<p>ATIVAR CARREGAMENTO</p>',
    fr: '<p>ACTIVER TÉLÉCHARGEMENT</p>',
    eus: '<p>AKTIBATU KARGA</p>',
    cat: '<p>ACTIVA LA CÀRREGA</p>',
  },

  begun_upload_external: {
    es: '<p>SUBIDA ACTIVADA</p>',
    en: '<p>UPLOAD ACTIVATED</p>',
    pt: '<p>CARREGAMENTO ATIVADO</p>',
    fr: '<p>TÉLÉCHARGEMENT ACTIVÉ</p>',
    eus: '<p>KARGA AKTIBATUTA</p>',
    cat: '<p>CÀRREGA ACTIVADA</p>',
  },

  goToPaymentMethod: {
    es: '<p>ir al método de pago</p>',
    en: '<p>go to payment method</p>',
    pt: '<p>ir para método de pagamento</p>',
    fr: '<p>aller au mode de paiement</p>',
    eus: '<p>joan ordainketa metodora</p>',
    cat: '<p>ves al mètode de pagament</p>',
  },

  total: {
    es: '<p>Total:</p>',
    en: '<p>Total:</p>',
    pt: '<p>Total:</p>',
    fr: '<p>Total :</p>',
    eus: '<p>Guztira:</p>',
    cat: '<p>Total:</p>',
  },

  anyIncidences: {
    es: '<p>¿Tienes alguna incidencia?</p>',
    en: '<p>Do you have any incidents to report?</p>',
    pt: '<p>Tem alguma incidência?</p>',
    fr: '<p>Avez-vous un incident à signaler ?</p>',
    eus: '<p>Arazorik baduzu?</p>',
    cat: '<p>Tens cap incidència?</p>',
  },

  writeIncidence: {
    es: '<p>Escribir Incidencia</p>',
    en: '<p>Report an incident</p>',
    pt: '<p>Escrever incidência</p>',
    fr: '<p>Signaler incident</p>',
    eus: '<p>Eman arazo baten berri</p>',
    cat: '<p>Escriu una incidència</p>',
  },

  statusIncidence: {
    es: '<p>Estado de incidencias</p>',
    en: '<p>Incident status</p>',
    pt: '<p>Estado de incidências</p>',
    fr: '<p>État des incidents</p>',
    eus: '<p>Arazoen egoera</p>',
    cat: '<p>Estat de les incidències</p>',
  },

  sendIncidence: {
    es: '<p>Enviar Incidencia</p>',
    en: '<p>Submit Incident</p>',
    pt: '<p>Enviar incidência</p>',
    fr: '<p>Envoyer rapport d\'incident</p>',
    eus: '<p>Bidali arazoa</p>',
    cat: '<p>Envia la incidència</p>',
  },

  writeTheIncidence: {
    es: '<p>Escribe Incidencia</p>',
    en: '<p>Report an incident</p>',
    pt: '<p>Escreva a incidência</p>',
    fr: '<p>Signaler incident</p>',
    eus: '<p>Idatzi arazoa hemen</p>',
    cat: '<p>Escriu aquí la incidència</p>',
  },

  payment: {
    es: '<p>pago</p>',
    en: '<p>payment</p>',
    pt: '<p>pagamento</p>',
    fr: '<p>Paiement</p>',
    eus: '<p>ordainketa</p>',
    cat: '<p>pagament</p>',
  },

  month: {
    es: '<p>mes</p>',
    en: '<p>month</p>',
    pt: '<p>mês</p>',
    fr: '<p>mois</p>',
    eus: '<p>hileko</p>',
    cat: '<p>mes</p>',
  },

  modelosProceso_data200: {
    es: '<p class="i18n-bold">Un archivo .obj /.ply estándar suele ir</p><p class="i18n-bold">acompañado de los ficheros .mtl y .jpeg o .png</p>',
    en: '<p class="i18n-bold">A standard .obj or .ply file usually goes</p><p class="i18n-bold">together with .mtl and .jpeg or .png files</p>',
    pt: '<p class="i18n-bold">Um ficheiro .obj /.ply padrão é geralmente</p><p class="i18n-bold">acompanhado dos ficheiros .mtl e .jpeg ou .png</p>',
    fr: '<p class="i18n-bold">Un fichier .obj /.ply standard est généralement/<p><p class="i18n-bold">accompagné des fichiers .mtl et .jpeg ou .png</p>',
    eus: '<p class="i18n-bold">.obj / .ply fitxategi arruntarekin batera, .mtl eta</p><p class="i18n-bold">.jpeg edo .png luzapena duten fitxategiak joan ohi diraa</p>',
    cat: '<p class="i18n-bold">Un fitxer .obj / .ply sol anar acompanyat</p><p class="i18n-bold">dels fitxers .mtl i .jpeg o .png</p>',
  },

  modelosProceso_data201: {
    es: '<p>Si quieres subir estos ficheros haz click en</p><p><span class="i18n-bold">aceptar</span> y selecciona los otros ficheros a subir</p>',
    en: '<p>If you want to upload these files, click</p><p><span class="i18n-bold">Accept</span>and select the other files to upload</p>',
    pt: '<p>Caso pretenda carregar estes ficheiros, clique em</p><p><span class="i18n-bold">aceitar</span> e selecione os outros ficheiros a carregar</p>',
    fr: '<p>Si vous souhaitez télécharger ces fichiers, cliquez sur</p><p><span class="i18n-bold">accepter</span> puis sélectionnez les autres fichiers à télécharger</p>',
    eus: '<p>Fitxategi horiek kargatu nahi badituzu, egin klik Onartu aukeran</p><p>eta hautatu igo nahi dituzun beste fitxategiak</p>',
    cat: '<p>Si vols carregar aquests fitxers, fes clic a</p><p><span class="i18n-bold">accepta</span> i selecciona els altres fitxers per carregar</p>',
  },

  modelosProceso_data202: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },

  modelosProceso_data203: {
    es: '<p>Descarga</p>',
    en: '<p>Download</p>',
    pt: '<p>Transferência</p>',
    fr: '<p>Téléchargement</p>',
    eus: '<p>Deskarga</p>',
    cat: '<p>Baixada</p>',
  },

  removeSelection: {
    es: '<p>Eliminar selección</p>',
    en: '<p>Delete selection</p>',
    pt: '<p>Eliminar seleção</p>',
    fr: '<p>Supprimer la sélection</p>',
    eus: '<p>Ezabatu hautaketa</p>',
    cat: '<p>Elimina la selecció</p>',
  },

  goToImageTreatment: {
    es: '<p>Ir a Trat. Imágenes</p>',
    en: '<p>Go to Image Processing</p>',
    pt: '<p>Ir a Trat. Imagens</p>',
    fr: '<p>Ir para Trat. Imagens</p>',
    eus: '<p>Joan Irudi Tratamendura</p>',
    cat: '<p>Ves a Tract. imatges</p>',
  },

  goYourModel3d: {
    es: '<p>Ir a tu Modelo 3D</p>',
    en: '<p>Go to your 3D model</p>',
    pt: '<p>Ir para o seu Modelo 3D</p>',
    fr: '<p>Aller à votre modèle 3D</p>',
    eus: '<p>Joan zure 3D eredura</p>',
    cat: '<p>Ves al teu model 3D</p>',
  },

  backPanelControl: {
    es: '<p>Atrás</p>',
    en: '<p>Back</p>',
    pt: '<p>Atrás</p>',
    fr: '<p>Retour</p>',
    eus: '<p>Atzera</p>',
    cat: '<p>Enrere</p>',
  },
};
