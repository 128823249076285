<div class="policy-title" [innerHTML]="'hiring_Data3' | i18n"></div>
<div class="policy-black-border">
    <!-- <div class="policy-yellow-background"> -->
    <div class="policy-container">
        <div class="date">
            <div [innerHTML]="'hiring_Data2' | i18n"></div>
            <div [innerHTML]="'12/01/2024'" class="date-number"></div>
        </div>

        <!-- <div class="policy-title" [innerHTML]="'hiring_Data3' | i18n"></div> -->

        <!-- <div class="title" [innerHTML]="'hiring_Data3' | i18n"></div> -->
        <div class="title" [innerHTML]="'hiring_Data4' | i18n"></div>

        <div class="description" [innerHTML]="'hiring_Data5' | i18n"></div>

        <div class="description m-top" [innerHTML]="'hiring_Data6' | i18n"></div>

        <div class="description m-top" [innerHTML]="'hiring_Data7' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data8' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data9' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data10' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data11' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data12' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data13' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data14' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data15' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data16' | i18n"></div>
        <div class="point m-top">
            <div class="point-point"></div>
            <div class="description" [innerHTML]="'hiring_Data17' | i18n"></div>
        </div>
        <div class="point m-top">
            <div class="point-point"></div>
            <div class="description" [innerHTML]="'hiring_Data18' | i18n"></div>
        </div>
        <div class="point m-top">
            <div class="point-point"></div>
            <div class="description" [innerHTML]="'hiring_Data19' | i18n"></div>
        </div>
        <div class="title" [innerHTML]="'hiring_Data20' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data21' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data22' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data23' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data24' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data25' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data26' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data27' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data28' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data29' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data30' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data31' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data32' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data33' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data34' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data35' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data36' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data37' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data38' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data39' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data40' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data41' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data42' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data43' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data44' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data45' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data46' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data47' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data48' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data49' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data50' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data51' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data52' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data53' | i18n"></div>
        <div class="description m-left m-top" [innerHTML]="'hiring_Data54' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data55' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data56' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data57' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data58' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data59' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data60' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data61' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data62' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data63' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data64' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data65' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data66' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data67' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data68' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data69' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data70' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data71' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data72' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data73' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data74' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data75' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data76' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data77' | i18n"></div>
        <div class="point m-top">
            <div class="point-point"></div>
            <div class="description" [innerHTML]="'hiring_Data78' | i18n"></div>
        </div>
        <div class="point">
            <div class="point-point"></div>
            <div class="description" [innerHTML]="'hiring_Data79' | i18n"></div>
        </div>
        <div class="description m-top" [innerHTML]="'hiring_Data80' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data81' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data82' | i18n"></div>
        <div class="description m-top m-left" [innerHTML]="'hiring_Data83' | i18n"></div>
        <div class="description m-left" [innerHTML]="'hiring_Data84' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data85' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data86' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data87' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data88' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data89' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data90' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data91' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data92' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data93' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data94' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data95' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data96' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data97' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data98' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data99' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data100' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data101' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data102' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data103' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data104' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data105' | i18n"></div>

        <table class="table-rate">
            <thead>
                <tr>
                    <th></th>
                    <th [innerHTML]="'hiring_Data145' | i18n"></th>
                    <th [innerHTML]="'hiring_Data146' | i18n"></th>
                    <th [innerHTML]="'hiring_Data147' | i18n"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td [innerHTML]="'hiring_Data148' | i18n" class="first-colum"></td>
                    <td>1</td>
                    <td>1</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data149' | i18n" class="first-colum"></td>
                    <td [innerHTML]="'hiring_Data154' | i18n"></td>
                    <td [innerHTML]="'hiring_Data155' | i18n"></td>
                    <td [innerHTML]="'hiring_Data155' | i18n"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data150' | i18n" class="first-colum"></td>
                    <td [innerHTML]="'hiring_Data156' | i18n"></td>
                    <td [innerHTML]="'hiring_Data157' | i18n"></td>
                    <td [innerHTML]="'hiring_Data157' | i18n"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data151' | i18n" class="first-colum"></td>
                    <td [innerHTML]="'hiring_Data158' | i18n"></td>
                    <td>10</td>
                    <td>100</td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data152' | i18n" class="first-colum"></td>
                    <td [innerHTML]="'hiring_Data158' | i18n"></td>
                    <td>5</td>
                    <td>25</td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data153' | i18n" class="first-colum"></td>
                    <td>50</td>
                    <td>200</td>
                    <td>2000</td>
                </tr>
            </tbody>
        </table>
        <div class="title" #freeRate [innerHTML]="'hiring_Data106' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data107' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data108' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data109' | i18n"></div>
        <div class="title" #proRate [innerHTML]="'hiring_Data110' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data111' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data112' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data113' | i18n"></div>
        <div class="description m-top m-left" [innerHTML]="'hiring_Data114' | i18n"></div>
        <div class="description m-top m-left" [innerHTML]="'hiring_Data115' | i18n"></div>
        <div class="description m-top m-left" [innerHTML]="'hiring_Data116' | i18n"></div>
        <div class="description m-top m-left" [innerHTML]="'hiring_Data117' | i18n"></div><br>
        <table class="table-yes-no">
            <thead>
                <tr>
                    <th [innerHTML]="'hiring_Data159' | i18n" class="align-header"></th>
                    <th [innerHTML]="'hiring_Data160' | i18n"></th>
                    <th [innerHTML]="'hiring_Data161' | i18n"></th>
                    <th [innerHTML]="'hiring_Data162' | i18n"></th>
                    <th [innerHTML]="'hiring_Data163' | i18n"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td [innerHTML]="'hiring_Data165' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data166' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data167' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data168' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data169' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table">
                    </td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table">
                    </td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table">
                    </td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table">
                    </td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data170' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data171' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data172' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data173' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data174' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data175' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data176' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data177' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data178' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data179' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data180' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data181' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data182' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data183' | i18n" class="table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                </tr>
            </tbody>
        </table>
        <div class="title" [innerHTML]="'hiring_Data118' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data119' | i18n"></div>
        <div class="title" #businessRate [innerHTML]="'hiring_Data120' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data121' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data122' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data123' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data124' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data125' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data126' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data127' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data128' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data129' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data130' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data131' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data132' | i18n"></div><br>
        <div class="third-table-header">
            <div class="left-content-third-table"></div>
            <div class="right-content-third-table" [innerHTML]="'hiring_Data208' | i18n"></div>
        </div>
        <table class="third-table">
            <thead>
                <tr>
                    <th [innerHTML]="'hiring_Data185' | i18n" class="width-reference"></th>
                    <th [innerHTML]="'hiring_Data186' | i18n"></th>
                    <th [innerHTML]="'hiring_Data187' | i18n"></th>
                    <th [innerHTML]="'hiring_Data188' | i18n"></th>
                    <th [innerHTML]="'hiring_Data189' | i18n"></th>
                    <th [innerHTML]="'hiring_Data190' | i18n"></th>
                    <th [innerHTML]="'hiring_Data191' | i18n"></th>
                    <th [innerHTML]="'hiring_Data192' | i18n"></th>
                    <th [innerHTML]="'hiring_Data193' | i18n"></th>
                    <th [innerHTML]="'hiring_Data194' | i18n"></th>
                    <th [innerHTML]="'hiring_Data195' | i18n"></th>
                    <th [innerHTML]="'hiring_Data196' | i18n"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td [innerHTML]="'hiring_Data197' | i18n" class="third-table-alig-left"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>

                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data198' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>

                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data199' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data200' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data201' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data202' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data203' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data204' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data205' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data206' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                    <td [innerHTML]="'hiring_Data184' | i18n" class="no-table"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data207' | i18n" class="third-table-alig-left"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td [innerHTML]="'hiring_Data164' | i18n" class="yes-table"></td>
                    <td>-</td>
                </tr>
            </tbody>
        </table>
        <div class="title" [innerHTML]="'hiring_Data133' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data134' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data135' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data136' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data137' | i18n"></div>
        <div class="title" [innerHTML]="'hiring_Data138' | i18n"></div>
        <div class="description" [innerHTML]="'hiring_Data139' | i18n"></div><br>
        <table class="last-table">

            <tbody>
                <tr>
                    <td [innerHTML]="'hiring_Data210' | i18n"></td>
                    <td [innerHTML]="'hiring_Data211' | i18n"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data212' | i18n"></td>
                    <td [innerHTML]="'hiring_Data213' | i18n"></td>

                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data214' | i18n"></td>
                    <td [innerHTML]="'hiring_Data215' | i18n"></td>

                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data216' | i18n"></td>
                    <td [innerHTML]="'hiring_Data217' | i18n"></td>

                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data218' | i18n"></td>
                    <td [innerHTML]="'hiring_Data219' | i18n"></td>

                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data220' | i18n"></td>
                    <td [innerHTML]="'hiring_Data221' | i18n"></td>
                </tr>
                <tr>
                    <td [innerHTML]="'hiring_Data209' | i18n"></td>
                    <td [innerHTML]="'hiring_Data222' | i18n"></td>
                </tr>
            </tbody>
        </table>
        <div class="description m-top" [innerHTML]="'hiring_Data140' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data141' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data142' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data143' | i18n"></div>
        <div class="description m-top" [innerHTML]="'hiring_Data144' | i18n"></div>
    </div>
    <!-- </div> -->
</div>