<div class="background">
  <div class="header-container">
    <app-header *ngIf="true"
                [showBackground]="false"
                [navbarBackgroundBlue]="false">
    </app-header>
  </div>

  <div class="no-professional-container" *ngIf="step == 0 ">
    <img class="no-professional" src="/assets/images/new3d/mobile-no-professional-user-ico.png">
    <div class="text first-title">
      <p class="strong">
        {{'noProfessionalNoticeText1_1' | i18n}}
        <span class="underline">{{'noProfessionalNoticeText1_2' | i18n}}</span>
        {{'noProfessionalNoticeText1_3' | i18n}}.
      </p>
      <p class="small-text">{{'noProfessionalNoticeText2' | i18n}} {{'noProfessionalNoticeText3' | i18n}}</p>
    </div>
    <div class="logo"><p>4U</p></div>
    <div class="text second-title">
      <p class="strong">
        {{'noProfessionalNoticeText4' | i18n}}
      </p>
      <p class="small-text">{{'noProfessionalNoticeText5' | i18n}} {{'noProfessionalNoticeText6' | i18n}}</p>
    </div>
    <a class="first-button" routerLink="/profile/mobile-user-rates">{{'noProfessionalNoticeText7' | i18n}}</a>
    <img class="company-image" src="/assets/images/new3d/mobile-add-user-as-company-ico.png">
    <div class="text third-title">
      <p class="strong">
        {{'noProfessionalNoticeText8' | i18n}}
      </p>
      <p class="small-text">{{'noProfessionalNoticeText9' | i18n}}, {{'noProfessionalNoticeText10' | i18n}}…</p>
    </div>
    <a class="second-button" (click)="mobileRequest()">{{'noProfessionalNoticeText11' | i18n}}</a>
  </div>

  <!------ SOLICITUD DE EMPRESA ------>

  <div class="no-professional-container" *ngIf="step == 1">
    <p class="title">SOLICITUD EMPRESA</p>
    <p class="subtitle">Panel de control de gestión, usuarios ilimitados, departamentos, estadísticas…</p>
    <p class="clarification">Todo usuario empresa necesita mínimo 2 usuarios administradores</p>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="save()" novalidate>
      <label class="w-100">
        <input formControlName="nif" type="text" class="form-control" placeholder="{{'profile_Data100' | i18n}}*"
               [ngClass]="{ 'is-invalid': submitted && f.nif.errors }">
        <div *ngIf="submitted && f.nif.errors" class="invalid-feedback">
          <div [innerHTML]="'nifRequired' | i18n"></div>
        </div>
      </label>
      <label class="w-100">
        <input formControlName="businessName"
               type="text"
               class="form-control"
               placeholder="{{'businessName' | i18n: translationModes.PLACEHOLDER }}*"
               [ngClass]="{ 'is-invalid': submitted && f.businessName.errors }">
        <div *ngIf="submitted && f.businessName.errors" class="invalid-feedback">
          <div [innerHTML]="'businesNameRequired' | i18n"></div>
        </div>
      </label>
      <label class="w-100">
        <input formControlName="address"
               type="text"
               class="form-control"
               placeholder="{{'address' | i18n: translationModes.PLACEHOLDER }}*"
               [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
          <div [innerHTML]="'addressRequired' | i18n"></div>
        </div>
      </label>
      <label class="w-100">
        <input formControlName="country"
               type="text"
               class="form-control"
               placeholder="{{'profile_Data28' | i18n: translationModes.PLACEHOLDER }}*"
               [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
        <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
          <div [innerHTML]="'countryRequired' | i18n"></div>
        </div>
      </label>
      <label class="w-100">
        <input formControlName="contact"
               type="text"
               class="form-control"
               placeholder="{{'contactPerson' | i18n: translationModes.PLACEHOLDER }}"
               [ngClass]="{ 'is-invalid': submitted && f.contact.errors }">
        <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
          <div [innerHTML]="'contactPersonRequired' | i18n "></div>
        </div>
      </label>
      <label class="w-100">
        <input formControlName="phone"
               type="text"
               class="form-control"
               placeholder="{{'profile_Data32' | i18n: translationModes.PLACEHOLDER }}"
               [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
          <div [innerHTML]="'phoneRequired' | i18n"></div>
        </div>
      </label>
      <p class="form-title" [innerHTML]="'requestAdminUser1' | i18n"></p>
      <label class="w-100">
        <input formControlName="email1" type="email" class="form-control" placeholder="{{'email' | i18n: translationModes.PLACEHOLDER }}"
               [ngClass]="{ 'is-invalid': submitted && f.email1.errors }">
        <div *ngIf="submitted && f.email1.errors" class="invalid-feedback">
          <div *ngIf="f.email1.errors.required" [innerHTML]="'emailRequired' | i18n "></div>
          <div *ngIf="f.email1.errors.email" [innerHTML]="'badEmail' | i18n "></div>
          <div *ngIf="f.email1.errors.emailDomain" [innerHTML]="'notCorporateMail' | i18n "></div>
        </div>
      </label>
      <label class="w-100">
        <input formControlName="name1" type="email" class="form-control" placeholder="{{'name' | i18n: translationModes.PLACEHOLDER }}"
               [ngClass]="{ 'is-invalid': submitted && f.name1.errors }">
        <div *ngIf="submitted && f.name1.errors" class="invalid-feedback">
          <div [innerHTML]="'nameRequired' | i18n "></div>
        </div>
      </label>
      <p class="form-title" [innerHTML]="'requestAdminUser2' | i18n"></p>
      <label class="w-100">
        <input formControlName="email2" type="email" class="form-control" placeholder="{{'email' | i18n: translationModes.PLACEHOLDER }}"
               [ngClass]="{ 'is-invalid': submitted && f.email2.errors }">
        <div *ngIf="submitted && f.email2.errors" class="invalid-feedback">
          <div *ngIf="f.email2.errors.required" [innerHTML]="'emailRequiredsendRequest' | i18n "></div>
          <div *ngIf="f.email2.errors.email" [innerHTML]="'badEmail' | i18n "></div>
          <div *ngIf="f.email2.errors.emailDomain" [innerHTML]="'notCorporateMail' | i18n "></div>
        </div>
      </label>
      <button type="submit" (click)="save()" [innerHTML]="'sendRequest' | i18n "></button>
    </form>
  </div>

<!--<div *ngIf="submitted && errorMessage1.length != 0 && errorMessage2.length != 0 && step == 3" class="form-error error-message">
      <div class="error-container">
        <p>SOLICITUD DE EMPRESA</p>
        <div class="error-image">
          <img src="assets/images/error-icon.svg">
        </div>
        <p class="error-bold"><span>{{errorMessage1}}</span><br> {{errorMessage2}}</p>
      </div>
</div>-->
<div class="success-mesage" *ngIf="successMesage && step == 4">
  <div class="success-mesage">
    <p class="success-bold">Gracias por hacer la solicitad para darse de alta como empresa.</p>
    <p>Le responderemos en la mayor brevedad</p>
  </div>
</div>
  <div class="footer-container">
    <app-footer *ngIf="true"></app-footer>
  </div>
</div>
