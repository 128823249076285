<div class="maintenance-container">
  <img class="logo" src="/assets/images/maintenance/ec3d-maintenance-logo.svg">
  <div class="title">
    <p [innerHTML]="'maintance_data1' | i18n"></p>
  </div>
  <div class="sub-title">
    <p [innerHTML]="'maintance_data2' | i18n"></p>
  </div>
  <img class="maintance-logo" src="/assets/images/maintenance/maintenance-logo.svg">
  <div class="maintenance-text">
    <p [innerHTML]="'maintance_data3' | i18n"></p>
  </div>
</div>
