import {StorageService} from './storage.service';

export abstract class BaseStorageService<T> {
    protected constructor(protected storageService: StorageService) {}

    public saveObject(value: T) {
        this.storageService.setObject(this.getStorageKey(), value);
    }

    public getObjectValue(): T {
        return this.storageService.getObject(this.getStorageKey());
    }

    public deleteFromStorage(): void {
        this.storageService.delete(this.getStorageKey());
    }

    public isSessionValueDefined(): boolean {
        const aux = this.getObjectValue();
        return  aux !== null && aux !== undefined;
    }

    protected abstract getStorageKey(): string;
}
