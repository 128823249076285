import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-changes-will-be-lost-dialog',
  templateUrl: './changes-will-be-lost-dialog.component.html',
  styleUrls: ['./changes-will-be-lost-dialog.component.scss'],
})
export class ChangesWillBeLostDialogComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<ChangesWillBeLostDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.disableZoom();
    this.addTouchListeners();
    this.blockScroll();
  }

  // Cuando el componente se destruye, reactiva el zoom
  ngOnDestroy(): void {
    this.enableZoom();
    this.removeTouchListeners();
    this.unblockScroll();
  }
  closeDialog() {
    this.dialogRef.close();
  }

  disableZoom(): void {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (metaViewport) {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
      );
    }
  }

  enableZoom(): void {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (metaViewport) {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, user-scalable=yes'
      );
    }
  }
  addTouchListeners(): void {
    document.addEventListener('touchstart', this.preventZoom, {
      passive: false,
    });
    document.addEventListener('touchmove', this.preventZoom, {
      passive: false,
    });
  }

  removeTouchListeners(): void {
    document.removeEventListener('touchstart', this.preventZoom);
    document.removeEventListener('touchmove', this.preventZoom);
  }

  preventZoom(event: TouchEvent): void {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }
  blockScroll(): void {
    document.body.classList.add('modal-open'); // Agrega clase para bloquear scroll
  }

  unblockScroll(): void {
    document.body.classList.remove('modal-open'); // Elimina clase para permitir scroll
  }
}
