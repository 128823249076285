<div class="selectLanguage" [ngClass]="{'selectLanguageFooter': footer,'header-bg': !footer, 'mireia-bg-color': mireia && !footer, 'mireia-footer': mireia && footer}">
  <button mat-button [matMenuTriggerFor]="menu">
    <ng-container>
      <span class="selector-text" [innerHTML]="'selectorIdioma_data1' | i18n"></span>
      <mat-icon>{{ footer ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
    </ng-container>
  </button>
  <mat-menu #menu="matMenu" class="{{footerClass}}">
    <button mat-menu-item *ngIf="currentLanguage.code != 'es'" (click)="setCurrentLanguage('es')">
      <p [innerHTML]="'selectorIdioma_data2' | i18n"></p>
    </button>
    <button mat-menu-item *ngIf="currentLanguage.code != 'en'" (click)="setCurrentLanguage('en')">
      <p [innerHTML]="'selectorIdioma_data3' | i18n"></p>
    </button>
    <button mat-menu-item *ngIf="currentLanguage.code != 'pt'" (click)="setCurrentLanguage('pt')">
      <p [innerHTML]="'selectorIdioma_data4' | i18n"></p>
    </button>
    <button mat-menu-item *ngIf="currentLanguage.code != 'fr'" (click)="setCurrentLanguage('fr')">
      <p [innerHTML]="'selectorIdioma_data5' | i18n"></p>
    </button>
    <button mat-menu-item *ngIf="currentLanguage.code != 'eus'" (click)="setCurrentLanguage('eus')">
      <p [innerHTML]="'selectorIdioma_data6' | i18n"></p>
    </button>
    <button mat-menu-item *ngIf="currentLanguage.code != 'cat'" (click)="setCurrentLanguage('cat')">
      <p [innerHTML]="'selectorIdioma_data7' | i18n"></p>
    </button>
  </mat-menu>
</div>
