export const FIRST_EXPERIENCE_DIALOGS_I18N_ENTRIES = {
  first_experience_dialogs_Data1: {
    es: '<p>Hola,</p><p>%X%.</p>',
    en: '<p>Hello,</p><p>%X%.</p>',
    pt: '<p>Olá,</p><p>%X%.</p>',
    fr: '<p>Bonjour,</p><p>%X%.</p>',
    eus: '<p>Kaixo,</p><p>%X%!</p>',
    cat: '<p>Hola,</p><p>%X%.</p>',
  },

  first_experience_dialogs_Data2: {
    es: '<p>Te invitamos a quedarte en nuestro tour</p><p>virtual de primeros pasos.</p><p>Avanza y descubre cómo sacar el máximo</p><p>rendimiento a tu cuenta de <span class="i18n-bold">eyesCloud3D</span></p>',
    en: '<p>We invite you to take the virtual tour</p><p>through your first steps.</p><p>Discover how to get the most</p><p>out of your <span class="i18n-bold">eyesCloud3D</span> account</p>',
    pt: '<p>Convidamo-lo a permanecer no nosso tour</p><p>virtual de primeiros passos.</p><p>Avance e descubra como tirar o máximo</p><p>partido da sua conta da <span class="i18n-bold">eyesCloud3D</span></p>',
    fr: '<p>Nous vous invitons à réaliser</p><p>notre visite virtuelle d’initiation.</p><p>Découvrez comment tirer le meilleur parti</p><p>de votre compte <span class="i18n-bold">eyesCloud3D</span></p>',
    eus: '<p>Lehen urratsak emateko tour birtuala</p><p>egitera animatzen zaitugu.</p><p>Egin aurrera eta ikusi nola atera dezakezun</p><p>etekinik handiena zure <span class="i18n-bold">eyesCloud3D</span> kontuari.</p>',
    cat: '<p>Et convidem a quedar-te a la</p><p>visita virtual de primers passos.</p><p>Avança i descobreix com treure el màxim</p><p>rendiment del compte d’<span class="i18n-bold">eyesCloud3D</span></p>',
  },

  first_experience_dialogs_Data3: {
    es: '<p>Empezar</p>',
    en: '<p>Start</p>',
    pt: '<p>Começar</p>',
    fr: '<p>Commencer</p>',
    eus: '<p>Hasi</p>',
    cat: '<p>Comença</p>',
  },

  first_experience_dialogs_Data4: {
    es: '<p>Saltar</p>',
    en: '<p>Skip</p>',
    pt: '<p>Ignorar</p>',
    fr: '<p>Passer</p>',
    eus: '<p>Utzi</p>',
    cat: '<p>Omet</p>',
  },

  first_experience_dialogs_Data5: {
    es: '<p>Estás en la pantalla de inicio de <span class="i18n-bold">eyesCloud3D.</span></p><p>Desde aquí, podrás acceder a la generación y</p><p>visualización de modelos 3D, edición de Tu</p><p>Perfil y gestión de Tarifas.</p>',
    en: '<p>This is the <span class="i18n-bold">eyesCloud3D.</span> home screen.</p><p>From here, you can access 3D model generation and visualization, edit</p><p>Your Profile, and manage your Plans.</p>',
    pt: '<p>Está no ecrã inicial da <span class="i18n-bold">eyesCloud3D.</span></p><p>Aqui, poderá aceder à criação e</p><p>visualização de modelos 3D, à edição de</p><p>O Seu Perfil e à gestão das tarifas.</p>',
    fr: '<p>Vous êtes sur l’écran d’accueil d’<span class="i18n-bold">eyesCloud3D.</span></p><p>Ici, vous pouvez générer et visualiser les modèles 3D,</p><p>modifier votre profil et gérer votre abonnement.</p>',
    eus: '<p><span class="i18n-bold">eyesCloud3D</span>-ren hasierako pantailan zaude.</p><p>Hemen, 3D ereduak sortu eta bistaratu,</p><p>Zure Profila editatu eta Tarifak kudeatu</p><p>ahal izango dituzu.</p>',
    cat: '<p>Ets a la pantalla d\'inici d’<span class="i18n-bold">eyesCloud3D.</span></p><p>Des d\'aquí, podràs accedir a la generació</p><p>i visualització de models 3D, l’edició</p><p>del perfil i la gestió de tarifes.</p>',
  },

  first_experience_dialogs_Data6: {
    es: '<p>Siguiente</p>',
    en: '<p>Next</p>',
    pt: '<p>Seguinte</p>',
    fr: '<p>Suivant</p>',
    eus: '<p>Aurrera</p>',
    cat: '<p>Següent</p>',
  },

  first_experience_dialogs_Data7: {
    es: '<p>Incorpora modelos 3D a tu cuenta.</p><p>Genera nuevos modelos 3D a partir de vídeos</p><p>y fotografías del objeto a recrear.</p><p>También puedes importar modelos 3D ya</p><p>generados.</p>',
    en: '<p>Incorporate 3D models into your account.</p><p>Generate new 3D models from videos</p><p>and photos of the object you want to recreate.</p><p>You can also import 3D models</p><p>that have already been generated.</p>',
    pt: '<p>Incorpore modelos 3D na sua conta.</p><p>Crie novos modelos 3D a partir de vídeos</p><p>e fotografias do objeto a recriar.</p><p>Também pode importar modelos 3D já</p><p>criados.</p>',
    fr: '<p>Ajoutez des modèles 3D à votre compte.</p><p>Générez de nouveaux modèles 3D à partir de vidéos</p><p>et de photographies de l\'objet que vous souhaitez recréer.</p><p>Vous pouvez également importer des modèles 3D</p><p>qui ont déjà été générés.</p>',
    eus: '<p>Gehitu 3D ereduak zure kontura.</p><p>Sortu 3D eredu berriak, erreplikatu nahi duzun</p><p>objektuaren bideoak eta argazkiak erabiliz.</p><p>Lehendik sortu dituzun 3D ereduak ere</p><p>inportatu ditzakezu.</p>',
    cat: '<p>Incorpora models 3D al compte.</p><p>Genera nous models 3D a partir de vídeos</p><p>i fotografies de l\'objecte que s’ha de recrear.</p><p>També pots importar models 3D</p><p>generats prèviament.</p>',
  },

  first_experience_dialogs_Data8: {
    es: '<p>Visualiza todos tus modelos 3D en la galería.</p><p>Comparte galería con los miembros de tu</p><p>equipo en la versión Business y explora</p><p>modelos 3D de otros usuarios.</p>',
    en: '<p>View all your 3D models in the gallery.</p><p>Share the gallery with your team members</p><p>in the Business version and explore</p><p>3D models owned by other users.</p>',
    pt: '<p>Visualize todos os seus modelos 3D na galeria.</p><p>Partilhe a galeria com os membros da sua</p><p>equipa na versão Business e explore</p><p>modelos 3D de outros utilizadores.</p>',
    fr: '<p>Visualisez tous vos modèles 3D dans la galerie.</p><p>Partagez la galerie avec les membres de votre</p><p>équipe dans la version Business et découvrez</p><p>les modèles 3D d\'autres utilisateurs.</p>',
    eus: '<p>Bistaratu zure 3D eredu guztiak galerian.</p><p>Partekatu galeria zure taldeko kideekin</p><p>Business bertsioan, eta arakatu beste</p><p>erabiltzaile batzuen 3D ereduak.</p>',
    cat: '<p>Visualitza tots els models 3D en la galeria.</p><p>Comparteix la galeria amb els membres del</p><p>teu equip en la versió Business i explora</p><p>models 3D d\'altres usuaris.</p>',
  },

  first_experience_dialogs_Data9: {
    es: '<p>Consulta los modelos 3D que tienes en</p><p>proceso durante su generación.</p>',
    en: '<p>Consult the 3D models you have in</p><p>progress while generating them.</p>',
    pt: '<p>Consulte os modelos 3D que se encontram em</p><p>processamento durante a sua criação.</p>',
    fr: '<p>Consultez les modèles 3D qui se</p><p>trouvent en cours de génération.</p>',
    eus: '<p>Kontsultatu abian dituzun 3D ereduak,</p><p>hauek sortzen ari zaren bitartean.</p>',
    cat: '<p>Consulta els models 3D que tens</p><p>en curs durant la generació.</p>',
  },

  first_experience_dialogs_Data10: {
    es: '<p>Consulta las tarifas disponibles</p><p>y opciones de upgrades para tu cuenta</p>',
    en: '<p>See available plans</p><p>and upgrade options for your account</p>',
    pt: '<p>Consulte as tarifas disponíveis</p><p>e as opções de upgrades para a sua conta</p>',
    fr: '<p>Consultez les tarifs disponibles et les options</p><p>de mise à niveau de votre compte</p>',
    eus: '<p>Kontsultatu eskuragarri dituzun tarifak</p><p>eta zure kontua hobetzeko aukerak</p>',
    cat: '<p>Consulta les tarifes disponibles</p><p>i les opcions de millora per al compte</p>',
  },

  first_experience_dialogs_Data11: {
    es: '<p>¡Genera tu primer modelo 3D!</p>',
    en: '<p>Generate your first 3D model!</p>',
    pt: '<p>Crie o seu primeiro modelo 3D!</p>',
    fr: '<p>Générez votre premier modèle 3D !</p>',
    eus: '<p>Sortu zure lehen 3D eredua!</p>',
    cat: '<p>Genera el teu primer model 3D!</p>',
  },

  first_experience_dialogs_Data12: {
    es: '<p>Generar modelo</p>',
    en: '<p>Generate model</p>',
    pt: '<p>Criar modelo</p>',
    fr: '<p>Générer le modèle</p>',
    eus: '<p>Sortu eredua</p>',
    cat: '<p>Genera un model</p>',
  },

  first_experience_dialogs_Data13: {
    es: '<p>Estás en la pantalla de <span class="i18n-bold">"Carga/Captura".</span></p><p>Desde aquí podrás acceder a la generación </p><p>importación de modelos 3D, y la Descarga de</p><p>Targets de Escalado.</p>',
    en: '<p>This is the <span class="i18n-bold">"Upload/Capture"</span> screen.</p><p>From here you can generate or import 3D models and download</p><p>Scaling Targets.</p>',
    pt: '<p>Está no ecrã de <span class="i18n-bold">"Carregamento/Captura".</span></p><p>Aqui, poderá aceder à criação e</p><p>importação de modelos 3D, e à transferência de</p><p>Targets de Escalação.</p>',
    fr: '<p>Vous êtes sur l’écran <span class="i18n-bold">« Téléchargement/Capture ».</span></p><p>Ici, vous pouvez générer et importer des modèles 3D</p><p>et télécharger des targets de mise à l\'échelle.</p>',
    eus: '<p><span class="i18n-bold">“Karga/Kaptura”</span> egiteko pantailan zaude.</p><p>Hemen, 3D ereduak sortu eta inportatu ahal</p><p>izango dituzu, bai eta eskalaketa egiteko Target-ak</p><p>deskargatu ere.</p>',
    cat: '<p>Ets a la pantalla de <span class="i18n-bold">"Càrrega/Captura".</span></p><p>Des d\'aquí, podràs accedir a la generació</p><p>i importació de models 3D, i a la baixada</p><p>de targets d’ajustament d’escala.</p>',
  },

  first_experience_dialogs_Data14: {
    es: '<p>Carga los vídeos y fotografías capturados</p><p>alrededor del objeto, persona o espacio a</p><p>generar en 3D.</p>',
    en: '<p>Upload videos and photos captured</p><p>around the object, person, or space</p><p>to generate it in 3D.</p>',
    pt: '<p>Carregue os vídeos e fotografias capturados</p><p>em volta do objeto, pessoa ou espaço a ser</p><p>criado em 3D.</p>',
    fr: '<p>Téléchargez les vidéos et les photographies capturées</p><p>autour de l\'objet, de la personne ou de l\'espace</p><p>que vous souhaitez générer en 3D.</p>',
    eus: '<p>Kargatu 3Dn sortu nahi duzun objektuaren,</p><p>pertsonaren edo espazioaren inguruan kapturatutako </p><p>bideoak eta argazkiak.</p>',
    cat: '<p>Carrega els vídeos i fotografies capturats</p><p>al voltant de l\'objecte, persona o espai</p><p>que vulguis generar en 3D.</p>',
  },

  first_experience_dialogs_Data15: {
    es: '<p>Accede a esta pantalla para importar</p><p>tus propios modelos 3D.</p>',
    en: '<p>Navigate to this screen to import</p><p>your own 3D models.</p>',
    pt: '<p>Aceda a este ecrã para importar</p><p>os seus próprios modelos 3D.</p>',
    fr: '<p>Accédez à cet écran pour importer</p><p>vos propres modèles 3D.</p>',
    eus: '<p>Sartu pantaila honetan zure 3D eredu</p><p>propioak inportatzeko.</p>',
    cat: '<p>Accedeix a aquesta pantalla per importar</p><p>els teus propis models 3D.</p>',
  },

  first_experience_dialogs_Data16: {
    es: '<p>Descarga Targets para generar modelos</p><p>3D con una escala que refleje el tamaño</p><p>real del objeto a generar en 3D.</p>',
    en: '<p>Download Targets to generate 3D models</p><p>with a scale that reflects the real size</p><p>of the object to generate in 3D.</p>',
    pt: '<p>Transfira Targets para criar modelos</p><p>3D com uma escala que reflete o tamanho</p><p>real do objeto a criar em 3D.</p>',
    fr: '<p>Téléchargez des targets pour générer des modèles</p><p>3D dont l\'échelle reflète la taille réelle de l\'objet</p><p>que vous souhaitez générer en 3D.</p>',
    eus: '<p>Deskargatu Target-ak, 3D ereduak</p><p>erreplikatu nahi duzun objektuaren benetako tamaina</p><p>islatzen duen eskalarekin sortzeko.</p>',
    cat: '<p>Baixa targets per generar models 3D</p><p>amb una escala que reflecteixi la mida</p><p>real de l\'objecte que vols generar en 3D.</p>',
  },

  first_experience_dialogs_Data17: {
    es: '<p>Antes de importar o generar un</p><p>nuevo modelo 3D ponle</p><p>un nombre.</p>',
    en: '<p>Name each new 3D model</p><p>before importing or</p><p>generating it.</p>',
    pt: '<p>Antes de importar ou criar um</p><p>novo modelo 3D, atribua</p><p>um nome.</p>',
    fr: '<p>Avant d\'importer ou de générer un</p><p>nouveau modèle 3D,</p><p>nommez-le.</p>',
    eus: '<p>3D eredu berri bat inportatu</p><p>edo sortu baino lehen,</p><p>ezarri izena.</p>',
    cat: '<p>Abans d\'importar o generar</p><p>un nou model 3D, posa-li</p><p>un nom.</p>',
  },

  first_experience_dialogs_Data18: {
    es: '<p>Salir</p>',
    en: '<p>Exit</p>',
    pt: '<p>Sair</p>',
    fr: '<p>Quitter</p>',
    eus: '<p>Atera</p>',
    cat: '<p>Surt</p>',
  },

  first_experience_dialogs_Data19: {
    es: '<p>Estás en la pantalla de <span class="i18n-bold">"Nuevo Modelo 3D"</span> de</p><p><span class="i18n-bold">eyesCloud3D.</span>Desde aquí podrás añadir</p><p>vídeos y fotografías para generar modelos 3D</p>',
    en: '<p>This is the <span class="i18n-bold">"New 3D model"</span> screen</p><p>of <span class="i18n-bold">eyesCloud3D</span>. From here you can add videos</p><p>and photos to generate 3D models</p>',
    pt: '<p>Está no ecrã de <span class="i18n-bold">"Novo modelo 3D"</span> da</p><p><span class="i18n-bold">eyesCloud3D</span>. Aqui, poderá adicionar vídeos e fotografias para criar modelos 3D</p>',
    fr: '<p>Vous êtes sur l’écran <span class="i18n-bold">« Nouveau modèle 3D »</span></p><p>d\'<span class="i18n-bold">eyesCloud3D</span>. Ici, vous pouvez ajouter</p><p>des vidéos et des photos pour générer des modèles 3D</p>',
    eus: '<p><span class="i18n-bold">eyesCloud3D</span>-ren <span class="i18n-bold">"3D eredu berria"</span></p><p>pantailan zaude. Hemen, 3D ereduak sortzeko</p><p>bideoak eta argazkiak gehitu ahal izango dituzu.</p>',
    cat: '<p>Ets a la pantalla <span class="i18n-bold">"Nou model 3D"</span></p><p>d\'<span class="i18n-bold">eyesCloud3D</span>. Des d\'aquí podràs afegir</p><p>vídeos i fotografies per generar models 3D</p>',
  },

  first_experience_dialogs_Data20: {
    es: '<p>Desde este panel podrás añadir vídeos</p><p>arrastrando los archivos hasta el recuadro o</p><p>pulsando en el botón ⊕.</p><p>Dependiendo de tu tarifa, podrás subir un</p><p>vídeo de 2 o 4 minutos.</p>',
    en: '<p>From this panel you can add videos by</p><p>dragging the files into the box or</p><p>clicking on the ⊕ button.</p><p>Depending on your plan, you can upload a</p><p>2 or 4-minute video.</p>',
    pt: '<p>Neste painel, poderá adicionar vídeos</p><p>arrastando os ficheiros para o quadrado ou</p><p>clicando no botão ⊕.</p><p>Dependendo da sua tarifa, poderá carregar um</p><p>vídeo de 2 ou 4 minutos.</p>',
    fr: '<p>Sur ce panneau, vous pouvez ajouter des vidéos</p><p>en faisant glisser les fichiers dans la boîte</p><p>ou en cliquant sur le bouton ⊕.</p><p>En fonction de votre tarif, vous pouvez</p><p>télécharger une vidéo de 2 ou 4 minutes.</p>',
    eus: '<p>Panel honen bidez bideoak gehitu ditzakezu,</p><p>fitxategiak laukiraino arrastatuz edo</p><p>⊕ botoia sakatuz.</p><p>Zure tarifaren arabera, 2 edo 4 minutuko bideoa</p><p>kargatu ahal izango duzu.</p>',
    cat: '<p>Des d\'aquest panell podràs afegir vídeos</p><p>arrossegant els fitxers fins al requadre o</p><p>prement el botó ⊕.</p><p>Depenent de la teva tarifa, podràs carregar</p><p>un vídeo de 2 o 4 minuts.</p>',
  },

  first_experience_dialogs_Data21: {
    es: '<p>Desde este panel podrás añadir hasta 200</p><p>fotos, arrastrando los archivos hasta el</p><p>recuadro o pulsando en el botón ⊕.</p><p>Tendrás que subir, al menos, 5 fotografías,</p><p>para generar el modelo, tratando de cubrir al</p><p>completo, el objeto a generar.</p>',
    en: '<p>From this panel you can add up to 200</p><p>photos by dragging the files into the box or</p><p>clicking on the ⊕ button.</p><p>You must upload at least 5 photos</p><p>to generate the model. Try to capture</p><p>every part of the object to be generated.</p>',
    pt: '<p>Neste painel, poderá adicionar até 200</p><p>fotos, arrastando os ficheiros para o</p><p>quadrado ou clicando no botão ⊕.</p><p>Terá de carregar, pelo menos, 5 fotografias,</p><p>para criar o modelo, cobrindo todo o</p><p>objeto a criar.</p>',
    fr: '<p>Sur ce panneau, vous pouvez ajouter jusqu\'à 200 photos</p><p>en faisant glisser les fichiers dans la boîte ou en cliquant</p><p>sur le bouton ⊕.</p><p>Pour générer le modèle, vous devez télécharger</p><p>au moins 5 photos, en essayant de couvrir</p><p>l\'ensemble de l\'objet à générer.</p>',
    eus: '<p>Panel honen bidez 200 argazki arte gehitu</p><p>ditzakezu, fitxategiak laukiraino arrastatuz</p><p>edo ⊕ botoia sakatuz.</p><p>Gutxienez, 5 argazki kargatu beharko dituzu</p><p>eredua sortu ahal izateko. Saiatu argazkiek</p><p>objektua bere osotasunean har dezaten.</p>',
    cat: '<p>Des d\'aquest panell podràs afegir fins a 200</p><p>fotos arrossegant els fitxers fins al</p><p>requadre o prement en el botó ⊕.</p><p>Hauràs de carregar, com a mínim, 5 fotografies</p><p>per generar el model, tractant de cobrir la totalitat</p><p>de l’objecte que vols generar.</p>',
  },

  first_experience_dialogs_Data22: {
    es: '<p>Por último, ponle nombre al modelo 3D,</p><p>haz clic en siguiente y espera a que tu modelo 3D</p><p>se genere. En apenas minutos, podrás</p><p>visualizarlo en tu galería.</p>',
    en: '<p>Finally, name the 3D model,</p><p>click on next and wait for your 3D model</p><p>to be generated. In a few minutes, you will be able to</p><p>see it in your gallery.</p>',
    pt: '<p>Por fim, atribua um nome ao modelo 3D,</p><p>clique em seguinte e aguarde que o seu modelo 3D</p><p>seja criado. Em apenas alguns minutos, poderá</p><p>visualizá-lo na sua galeria.</p>',
    fr: '<p>Enfin, nommez le modèle 3D,</p><p>cliquez sur Suivant et attendez que votre modèle 3D</p><p>soit généré. En quelques minutes, vous pouvez</p><p>le visualiser dans votre galerie.</p>',
    eus: '<p>Bukatzeko, jarri izena 3D ereduari,</p><p>sakatu Aurrera botoia eta itxaron 3D eredua</p><p>sortu arte. Minutu gutxi barru, zure galerian</p><p>ikusi ahal izango duzu.</p>',
    cat: '<p>Finalment, posa-li nom al model 3D,</p><p>fes clic en Següent i espera</p><p>que el model 3D es generi. En uns minuts</p><p>el podràs visualitzar a la galeria.</p>',
  },

  first_experience_dialogs_Data23: {
    es: '<p>Cerrar</p>',
    en: '<p>Close</p>',
    pt: '<p>Fechar</p>',
    fr: '<p>Fermer</p>',
    eus: '<p>Itxi</p>',
    cat: '<p>Tanca</p>',
  },

  first_experience_dialogs_Data24: {
    es: '<p>Estás en la pantalla de <span class="i18n-bold">“Galería”</span> de</p><p><span class="i18n-bold">eyesCloud3D</span>. Desde aquí podrás acceder</p><p>a tus modelos 3D, modelos 3D de tu</p><p>equipo, en caso de disponer de una</p><p>cuenta Business y Explorar modelos 3D</p><p>de otros usuarios.</p>',
    en: '<p>This is the <span class="i18n-bold">“Gallery”</span></p><p>screen of <span class="i18n-bold">eyesCloud3D</span>.</p><p>From here you can access your 3D models and explore 3D models</p><p>owned by other users.</p><p>If you have a Business account,</p><p>you can also access your team\'s 3D models.</p>',
    pt: '<p>Está no ecrã <span class="i18n-bold">"Galeria"</span> da</p><p><span class="i18n-bold">eyesCloud3D</span>. Aqui poderá aceder</p><p>aos seus modelos 3D, modelos 3D da sua</p><p>equipa, se tiver uma</p><p>conta Business, e explorar os modelos 3D</p><p>de outros utilizadores.</p>',
    fr: '<p>Vous êtes sur l’écran <span class="i18n-bold">« Galerie »</span></p><p><span class="i18n-bold">eyesCloud3D</span>. Ici, vous pouvez accéder</p><p>à vos modèles 3D, aux modèles 3D de votre</p><p>équipe si vous disposez d’un compte Business</p><p>et aux modèles 3D d\'autres utilisateurs.</p>',
    eus: '<p><span class="i18n-bold">eyesCloud3D</span>-ren <span class="i18n-bold">"Galeria"</span></p><p>pantailan zaude. Hemen, zure 3D eredu propioak edo,</p><p>Business kontua baduzu,</p><p>zure taldeko kideen 3D ereduak</p><p>bistaratu eta beste erabiltzaileen</p><p>3D ereduak arakatu ditzakezu.</p>',
    cat: '<p>Ets a la pantalla <span class="i18n-bold">“Galeria”</span></p><p><span class="i18n-bold">eyesCloud3D</span>. Des d\'aquí podràs accedir</p><p>als teus models 3D, els models 3D del teu</p><p>equip, si disposes d\'un compte</p><p>Business, i explorar models 3D</p><p>d\'altres usuaris.</p>',
  },

  first_experience_dialogs_Data25: {
    es: '<p>En la parte superior de tu galería se</p><p>muestran los modelos 3D creados</p><p>recientemente o con los que más</p><p>interactúas.</p><p>En la parte superior derecha podrás</p><p>consultar información acerca de las</p><p>posibilidades que se pueden dar al trabajar</p><p>con un modelo 3D.</p>',
    en: '<p>At the top of your gallery,</p><p>you can see recently created 3D models</p><p>and the 3D models</p><p>you interact with most.</p><p>At the top right,</p><p>you can access information about</p><p>what types of work you can do</p><p>on 3D models.</p>',
    pt: '<p>Na parte superior da sua galeria,</p><p>pode ver os modelos 3D criados</p><p>recentemente ou com os quais mais</p><p>interage.</p><p>Na parte superior direita poderá</p><p>consultar informação sobre as</p><p>possibilidades ao trabalhar</p><p>com um modelo 3D.</p>',
    fr: '<p>En haut de votre galerie s’affichent</p><p>les modèles 3D que vous avez</p><p>récemment créés ou avec lesquels</p><p>vous interagissez le plus.</p><p>En haut à droite, vous pouvez consulter</p><p>des informations sur les options dont vous</p><p>disposez lorsque vous travaillez avec</p><p>un modèle 3D.</p>',
    eus: '<p>Zure galeriaren goiko aldean,</p><p>duela gutxi sortu dituzun edo gehien</p><p>erabiltzen dituzun 3D ereduak</p><p>agertzen dira.</p><p>Pantailaren goiko aldean, eskuinean,</p><p>3D eredu batekin lan egiteko</p><p>aukerei buruzko informazioa</p><p>kontsultatu dezakezu.</p>',
    cat: '<p>A la part superior de la galeria es</p><p>mostren els models 3D creats</p><p>recentment o amb què</p><p>interactues més.</p><p>En la part superior dreta podràs</p><p>consultar informació sobre les</p><p>possibilitats que es poden donar en treballar</p><p>amb un model 3D.</p>',
  },

  first_experience_dialogs_Data26: {
    es: '<p>Para buscar un modelo 3D, introduce el</p><p>nombre en el buscador. Para borrar esta</p><p>búsqueda, haz clic en la papelera de la</p><p>parte derecha.</p>',
    en: '<p>To look for a 3D model,</p><p>type the name in the search bar. To delete the search,</p><p>click on the trash bin</p><p>on the right.</p>',
    pt: '<p>Para procurar um modelo 3D, introduza o</p><p>nome no motor de pesquisa. Para apagar esta</p><p>pesquisa, clique na reciclagem no</p><p>lado direito.</p>',
    fr: '<p>Pour rechercher un modèle 3D, saisissez le</p><p>nom dans le moteur de recherche. Pour effacer cette</p><p>recherche, cliquez sur la corbeille</p><p>à droite.</p>',
    eus: '<p>3D eredu bat bilatzeko, idatzi</p><p>honen izena bilatzailean. Egin duzun bilaketa</p><p>ezabatzeko, egin klik eskuinaldeko</p><p>zakarrontzian.</p>',
    cat: '<p>Per cercar un model 3D, introdueix</p><p>el nom en el cercador. Per esborrar aquesta</p><p>cerca, fes clic a la paperera</p><p>de la part dreta.</p>',
  },

  first_experience_dialogs_Data27: {
    es: '<p>Existen 3 posibilidades para filtrar tus</p><p>búsquedas de modelos 3D, por categorías,</p><p>por subcategorías y departamentos, en</p><p>caso de disponer de una cuenta Business.</p><p>Todas se pueden combinar, solo tienes</p><p>que seleccionar las que quieras en los</p><p>desplegables. Para eliminar la búsqueda,</p><p>haz clic en la papelera de la derecha.</p>',
    en: '<p>If you have a Business account, you can</p><p>filter your searches for 3D models</p><p>in 3 ways: by category, subcategory,</p><p>or department.</p><p>You can use all the filters at the same time,</p><p>simply select the ones you want</p><p>from the drop-down menu. To delete the search,</p><p>click on the trash bin on the right.</p>',
    pt: '<p>Existem três possibilidades para filtrar as suas</p><p>pesquisas de modelos 3D, por categorias,</p><p>por subcategorias e departamentos, se</p><p>tiver uma conta Business.</p><p>Todas podem ser combinadas, só tem</p><p>de selecionar as que pretende nos</p><p>menus pendentes. Para apagar a pesquisa,</p><p>clique na reciclagem no lado direito.</p>',
    fr: '<p>Il existe 3 options pour filtrer vos</p><p>recherches de modèles 3D : par catégorie,</p><p>par sous-catégorie et par département, si vous</p><p>si vous disposez d’un compte Business.</p><p>Toutes ces possibilités peuvent être combinées.</p><p>Il vous suffit de sélectionner celles que vous</p><p>souhaitez dans les menus déroulants. Pour vous supprimer la recherche,</p><p>cliquez sur la corbeille à droite.</p>',
    eus: '<p>3D ereduen bilaketak filtratzeko, 3 aukera</p><p>dituzu: kategorien, azpikategorien</p><p>edo, Business kontua baduzu,</p><p>departamentuen arabera.</p><p>Filtro guztiak konbinatu ditzakezu,</p><p>menu zabalgarrian nahi dituzunak hautatu</p><p>besterik ez duzu egin behar. Bilaketa ezabatzeko,</p><p>egin klik eskuinaldeko zakarrontzian.</p>',
    cat: '<p>Hi ha 3 possibilitats per filtrar les cerques</p><p>de models 3D: per categories,</p><p>per subcategories i per departaments,</p><p>si disposes d\'un compte Business.</p><p>Totes tres es poden combinar, només</p><p>has de seleccionar les que vulguis</p><p>als desplegables. Per eliminar la cerca</p><p>fes clic a la paperera de la dreta.</p>',
  },

  first_experience_dialogs_Data28: {
    es: '<p>Puedes añadir filtros adicionales a tu</p><p>búsqueda de tal forma que puedas filtrar por:</p>',
    en: '<p>You can add additional filters</p><p>to your search, so you can filter by:</p>',
    pt: '<p>Pode adicionar filtros adicionais à sua</p><p>pesquisa para poder filtrar por:</p>',
    fr: '<p>Vous pouvez ajouter des filtres supplémentaires à votre</p><p>recherche pour filtrer par :</p>',
    eus: '<p>Zure bilaketan beste irizpide batzuk gehitu</p><p>ditzakezu, honako filtroak erabiliz:</p>',
    cat: '<p>Pots afegir filtres addicionals a la cerca</p><p>per tal de filtrar per:</p>',
  },

  first_experience_dialogs_Data29: {
    es: '<p>Todos</p>',
    en: '<p>All</p>',
    pt: '<p>Todos</p>',
    fr: '<p>Tous</p>',
    eus: '<p>Guztiak</p>',
    cat: '<p>Tots</p>',
  },

  first_experience_dialogs_Data30: {
    es: '<p>Favoritos</p>',
    en: '<p>Favorites</p>',
    pt: '<p>Favoritos</p>',
    fr: '<p>Favoris</p>',
    eus: '<p>Gogokoenak</p>',
    cat: '<p>Preferits</p>',
  },

  first_experience_dialogs_Data31: {
    es: '<p>Modelo fallido</p>',
    en: '<p>Failed model</p>',
    pt: '<p>Modelo com falha</p>',
    fr: '<p>Modèle échoué</p>',
    eus: '<p>Huts egindako eredua</p>',
    cat: '<p>Model fallit</p>',
  },

  first_experience_dialogs_Data32: {
    es: '<p>Modelo pendiente de aprobación (Por mi)</p>',
    en: '<p>Model pending approval (From me)</p>',
    pt: '<p>Modelo a aguardar aprovação (por mim)</p>',
    fr: '<p>Modèle en attente d’approbation (par moi-même)</p>',
    eus: '<p>Onartzeke dagoen eredua (nire partetik)</p>',
    cat: '<p>Model pendent d\'aprovació (per mi)</p>',
  },

  first_experience_dialogs_Data33: {
    es: '<p>Modelo pendiente de aprobación (Por otros)</p>',
    en: '<p>Model pending approval (From others)</p>',
    pt: '<p>Modelo a aguardar aprovação (por outros)</p>',
    fr: '<p>Modèle en attente d’approbation (par d’autres)</p>',
    eus: '<p>Onartzeke dagoen eredua (besteen partetik)</p>',
    cat: '<p>Model pendent d\'aprovació (per altres persones)</p>',
  },

  first_experience_dialogs_Data34: {
    es: '<p>Solicitudes para la eliminación de modelos</p>',
    en: '<p>Requests to delete models</p>',
    pt: '<p>Pedidos de eliminação de modelos</p>',
    fr: '<p>Demandes de suppression de modèles</p>',
    eus: '<p>Ereduak ezabatzeko eskaerak</p>',
    cat: '<p>Sol·licituds per a l\'eliminació de models</p>',
  },

  first_experience_dialogs_Data35: {
    es: '<p>El botón de selección abre una pantalla</p><p>adicional donde podrás hacer una</p><p>selección múltiple de modelos 3D e</p><p>interactuar con ellos.</p>',
    en: '<p>The selection button opens another screen</p><p>where you can select</p><p>multiple 3D models</p><p>and interact with them.</p>',
    pt: '<p>O botão de seleção abre um ecrã</p><p>adicional onde poderá fazer uma</p><p>seleção múltipla de modelos 3D e</p><p>interagir com eles.</p>',
    fr: '<p>Le bouton de sélection permet d’ouvrir un</p><p>écran additionnel sur lequel vous pouvez</p><p>sélectionner plusieurs modèles 3D et</p><p>interagir avec eux.</p>',
    eus: '<p>Hautaketa botoiak pantaila osagarri bat</p><p>irekitzen du, 3D ereduen hautapen</p><p>anizkoitza egin eta haiekin interakzioan</p><p>jardun ahal izateko.</p>',
    cat: '<p>El botó de selecció obre una pantalla</p><p>addicional on podràs fer una</p><p>selecció múltiple de models 3D</p><p>i interactuar amb ells.</p>',
  },

  first_experience_dialogs_Data36: {
    es: '<p>En la galería aparecen todos los modelos</p><p>3D a los que tienes acceso. En la parte</p><p>inferior podrás pasar o retroceder de</p><p>página. Puedes añadir a favoritos</p><p>pulsando en el corazón.</p>',
    en: '<p>All the 3D models you have access to</p><p>appear in the gallery. At the bottom you can</p><p>go forward or back</p><p>a page. You can add favorites</p><p>by clicking on the heart.</p>',
    pt: '<p>Na galeria aparecem todos os modelos</p><p>3D a que tem acesso. Na parte</p><p>inferior poderá passar para a página seguinte ou</p><p>retroceder. Pode adicionar aos favoritos</p><p>clicando no coração.</p>',
    fr: '<p>Tous les modèles 3D auxquels vous avez accès</p><p>s’affichent dans la galerie. Dans la partie inférieure,</p><p>vous pouvez tourner la page ou revenir</p><p>en arrière. Vous pouvez ajouter un modèle aux</p><p>favoris en cliquant sur l’icône du cœur.</p>',
    eus: '<p>Galerian, eskura dituzun 3D eredu guztiak</p><p>agertzen dira. Behealdean,</p><p>aurrera eta atzera egin dezakezu</p><p>orrialdeetatik. Bihotzean klik eginez, eredua</p><p>gogokoenen artean sar dezakezu.</p>',
    cat: '<p>En la galeria apareixen tots els models</p><p>3D als quals tens accés. En la part inferior</p><p>podràs passar o retrocedir</p><p>de pàgina. Pots afegir elements a preferits</p><p>prement en el cor.</p>',
  },

  first_experience_dialogs_Data37: {
    es: '<p>Estás en la pantalla “Modelo 3D”. Aquí podrás verificar el</p><p>estado de tus modelos, acceder al visor para la</p><p>visualización y editar la información asociada a tus</p><p>modelos 3D.</p><br><p>Además, tendrás acceso directo a funcionalidades como</p><p>la generación de informes, el tratamiento de imágenes y</p><p>la incorporación de imágenes hiperespectrales.</p>',
    en: '<p>This is the “3D Model” screen. Here you can verify</p><p>the status of your models, access the viewer</p><p>to visualize the models, and edit the information</p><p>associated with your 3D model<br><p>In addition, you have direct access to features like</p><p>report generation, image processing, and</p><p>incorporation of hyperspectral images.</p>',
    pt: '<p>Está no ecrã "Modelo 3D". Aqui poderá verificar o</p><p>estado dos seus modelos, aceder ao visor para</p><p>visualizar e editar as informações associadas aos seus</p><p>modelos 3D.</p><br><p>Além disso, terá acesso direto a funcionalidades como</p><p>criação de relatórios, tratamento de imagens e</p><p>incorporação de imagens hiperespetrais.</p>',
    fr: '<p>Vous êtes sur l\'écran « Modèle 3D ». Ici, vous pouvez vérifier l\'état de vos modèles, accéder à la visionneuse</p><p>et modifier les informations associées</p><p>à vos modèles 3D.</p><p>Vous pouvez aussi accéder directement à des fonctionnalités</p><p>telles que la génération de rapports, le traitement d\'images</p><p>et l\'intégration d\'images hyperspectrales.</p>',
    eus: '<p>"3D eredua" pantailan zaude. Hemen zure ereduen egoera</p><p>egiaztatu ahal izango duzu, bai eta ikustailean sartu</p><p>hauek bistaratzeko eta zure 3D ereduekin</p><p>lotutako informazioa editatu ere.</p><p>Gainera, txostenak sortzea, irudien tratamendua egitea,</p><p>eta irudi hiperespektralak eranstea bezalako</p><p>funtzionalitateetara zuzeneko atzipena izango duzu.</p>',
    cat: '<p>Ets a la pantalla “Model 3D”. Aquí podràs verificar l\'estat</p><p>dels models, accedir al visor per a</p><p>la visualització i editar la informació</p><p>associada als models 3D.</p><p>A més, tindràs accés directe a funcionalitats com</p><p>la generació d\'informes, el tractament d\'imatges</p><p>i la incorporació d\'imatges hiperespectrals.</p>',
  },

  first_experience_dialogs_Data38: {
    es: '<p>En la parte superior, tendrás acceso al</p><p>visor, donde podrás interactuar con tu</p><p>modelo 3D en Nube de Puntos y Malla.</p><br><p>Además, podrás consultar y descargar los</p><p>archivos de imagen y vídeo utilizados para</p><p>la generación del modelo 3D.</p>',
    en: '<p>At the top, you can access the</p><p>viewer, where you can interact with your</p><p>3D model in Cloud Point and Mesh.</p><br><p>You can also view and download the</p><p>image and video files used</p><p>to generate the 3D model.</p>',
    pt: '<p>Na parte superior, terá acesso ao</p><p>visor, onde poderá interagir com o seu</p><p>modelo 3D em Nuvem de Pontos e Malha.</p><br><p>Além disso, poderá consultar e transferir os</p><p>ficheiros de imagem e de vídeo utilizados para a</p><p>criação do modelo 3D.</p>',
    fr: '<p>Dans la partie supérieure, vous pouvez accéder à la</p><p>visionneuse qui vous permet d’interagir avec votre</p><p>modèle 3D en nuage de points et en maille.</p><br><p>En outre, vous pouvez consulter et télécharger</p><p>les images et vidéos utilisés pour</p><p>générer le modèle 3D.</p>',
    eus: '<p>Goiko aldean, ikustailera duzu atzipena,</p><p>zure 3D ereduarekin Puntu hodei edo Sare</p><p>gisa interakzioan jardun ahal izateko.</p><p>Gainera, 3D eredua sortzeko erabili diren</p><p>irudi eta bideo fitxategiak ikus eta</p><p>deskargatu ahal izango dituzu.</p>',
    cat: '<p>En la part superior, tindràs accés al visor,</p><p>on podràs interactuar amb el model 3D</p><p>en Núvol de punts i Malla.</p><p>A més, podràs consultar i baixar</p><p>els fitxers d\'imatge i vídeo emprats</p><p>per generar el model 3D.</p>',
  },

  first_experience_dialogs_Data39: {
    es: '<p>Edita los datos de tu modelo 3D, categorízalo</p><p>para poder filtrar en la galería, compártelo</p><p>con tus contactos o redes sociales y</p><p>selecciona si deseas que sea un modelo 3D</p><p>público o privado.</p>',
    en: '<p>Edit your 3D model’s data, categorize it so it</p><p>can be found using gallery filters, share it with</p><p>your contacts or on social media, and</p><p>select whether you want a 3D model to be</p><p>public or private.</p>',
    pt: '<p>Edite os dados do seu modelo 3D, categorize-o</p><p>para poder filtrar na galeria, partilhe-o</p><p>com os seus contactos ou redes sociais e</p><p>selecione se pretende que seja um modelo 3D</p><p>público ou privado.</p>',
    fr: '<p>Modifiez les données de votre modèle 3D, catégorisez-le</p><p>pour filtrer dans la galerie, partagez-le avec vos contacts</p><p>ou sur vos réseaux sociaux et choisissez si vous souhaitez</p><p>qu’il soit public ou privé.</p>',
    eus: '<p>Editatu zure 3D ereduaren datuak, kategoriatan</p><p>sailkatu galerian filtratu ahal izateko, partekatu</p><p>zure kontaktuekin edo sare sozialetan eta</p><p>aukeratu 3D eredu publikoa edo pribatua</p><p>izatea nahi duzun.</p>',
    cat: '<p>Edita les dades del teu model 3D, categoritza\'l</p><p>per emprar el filtre en la galeria, comparteix-lo</p><p>amb els teus contactes o xarxes socials i</p><p>selecciona si vols que sigui un model 3D</p><p>públic o privat.</p>',
  },

  first_experience_dialogs_Data40: {
    es: '<p>Añade imágenes hiperespectrales a tu modelo 3D,</p><p>escribe una incidencia en relación con el modelo 3D,</p><p>descarga informes de IOTA, ve directamente a la</p><p>colección Tratamiento de Imágenes en el visor o</p><p>elimina el modelo 3D.</p><br><p>Estas funcionalidades estarán disponibles o no,</p><p>dependiendo de tu suscripción.</p>',
    en: '<p>Add hyperspectral images to your 3D model,</p><p>report an incident related to the 3D model,</p><p>download IOTA reports, go directly to the</p><p>Image Processing collection in the viewer,</p><p>or delete the 3D model.</p><br><p>These features may or may not be available,</p><p>depending on your plan.</p>',
    pt: '<p>Adicione imagens hiperespetrais ao seu modelo 3D,</p><p>escreva uma incidência relacionada com o modelo 3D,</p><p>transfira relatórios de IOTA, vá diretamente para a</p><p>coleção Tratamento de Imagens no visor ou</p><p>elimine o modelo 3D.</p><br><p>Estas funcionalidades estarão disponíveis ou não,</p><p>dependendo da sua subscrição.</p>',
    fr: '<p>Ajoutez des images hyperspectrales à votre modèle 3D,</p><p>signalez un incident en lien avec le modèle 3D,</p><p>téléchargez des rapports d\'IOTA, accédez directement à la collection</p><p>Traitement d\'images dans la visionneuse</p><p>ou effacez le modèle 3D.</p><p>En fonction de votre abonnement, ces fonctionnalités</p><p>peuvent être disponibles ou non.</p>',
    eus: '<p>Gehitu irudi hiperespektralak zure 3D ereduari,</p><p>idatzi zure 3D ereduarekin lotutako arazoa,</p><p>deskargatu IOTA txostenak, joan zuzenean</p><p>Irudien tratamendua bildumara ikustailetik</p><p>edo ezabatu 3D eredua.</p><br><p>Kontratatu duzun harpidetzaren arabera,</p><p>funtzionalitate horiek erabilgarri izango dituzu.</p>',
    cat: '<p>Afegeix imatges hiperespectrals al model 3D,</p><p>escriu una incidència sobre el model 3D,</p><p>baixa informes de IOTA, ves directament a la</p><p>col·lecció Tractament d\'imatges en el visor o</p><p>elimina el model 3D.</p><p>Aquestes funcionalitats estaran disponibles o no,</p><p>depenent de la subscripció.</p>',
  },
};
