import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelRemoteService } from 'src/app/core/services/remote/model/model-remote.service';

@Component({
  selector: 'app-join-request-action',
  templateUrl: './join-request-action.component.html',
  styleUrls: ['./join-request-action.component.scss'],
})
export class JoinRequestActionComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modelRemoteService: ModelRemoteService
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token');
    if (this.router.url.includes('deny')) {
      this.modelRemoteService.denyModelJoinRequest(token).subscribe(
        () => {
          this.router.navigateByUrl('');
        },
        () => {
          this.router.navigateByUrl('');
        }
      );
    } else {
      this.modelRemoteService.approveModelJoinRequest(token).subscribe(
        () => {
          this.router.navigateByUrl('');
        },
        () => {
          this.router.navigateByUrl('');
        }
      );
    }
  }
}
