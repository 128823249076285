export const SECTORS_I18N_ENTRIES = {
  sectors_data1: {
    es: 'Personal',
    en: 'Personal',
    pt: 'Pessoal',
    fr: 'Personnel',
    eus: 'Pertsonala',
    cat: 'Personal',
  },
  sectors_data2: {
    es: 'Distribuidor',
    en: 'Distributor',
    pt: 'Distribuidor',
    fr: 'Distributeur',
    eus: 'Banatzailea',
    cat: 'Distribuïdor',
  },
  sectors_data3: {
    es: 'Educacional',
    en: 'Educational',
    pt: 'Educacional',
    fr: 'Éducatif',
    eus: 'Hezkuntza',
    cat: 'Educatiu',
  },
  sectors_data4: {
    es: 'Gobierno',
    en: 'Government',
    pt: 'Governo',
    fr: 'Gouvernement',
    eus: 'Gobernua',
    cat: 'Govern',
  },
  sectors_data5: {
    es: 'Negocios',
    en: 'Business',
    pt: 'Negócios',
    fr: 'Affaires',
    eus: 'Negozioak',
    cat: 'Negocis',
  },
  sectors_data6: {
    es: 'Profesional',
    en: 'Professional',
    pt: 'Profissional',
    fr: 'Professionnel',
    eus: 'Profesionala',
    cat: 'Professional',
  },
  sectors_data7: {
    es: 'I+D',
    en: 'R&D',
    pt: 'I+D',
    fr: 'R&D',
    eus: 'I+G',
    cat: 'I+D',
  },
  sectors_data8: {
    es: 'Otro',
    en: 'Other',
    pt: 'Outro',
    fr: 'Autre',
    eus: 'Beste bat',
    cat: 'Altres',
  },
};
