export const IOTA_IMAGES_PDF_I18N_ENTRIES = {
  iota_images_pdf_Data1: {
    es: '<p>Informe de envío de imágenes</p>',
    en: '<p>Image transfer report</p>',
    pt: '<p>Relatório de envio de imagens</p>',
    fr: '<p>Rapport d’envoi d’images</p>',
    eus: '<p>Irudien bidalketa txostena</p>',
    cat: '<p>Informe d’enviament d’imatges</p>',
  },

  iota_images_pdf_Data2: {
    es: '<p>Información del modelo 3D</p>',
    en: '<p>3D model information</p>',
    pt: '<p>Informação do modelo 3D</p>',
    fr: '<p>Information du modèle 3D</p>',
    eus: '<p>3D ereduaren informazioa</p>',
    cat: '<p>Informació del model 3D</p>',
  },

  iota_images_pdf_Data3: {
    es: '<p>Nombre del modelo 3D:</p>',
    en: '<p>3D model name:</p>',
    pt: '<p>Nome do modelo 3D:</p>',
    fr: '<p>Nom du modèle 3D :</p>',
    eus: '<p>3D ereduaren izena:</p>',
    cat: '<p>Nom del model 3D:</p>',
  },

  iota_images_pdf_Data4: {
    es: '<p>Código asignado:</p>',
    en: '<p>Assigned code:</p>',
    pt: '<p>Código atribuído:</p>',
    fr: '<p>Code attribué :</p>',
    eus: '<p>Ezarritako kodea:</p>',
    cat: '<p>Codi assignat:</p>',
  },

  iota_images_pdf_Data5: {
    es: '<p>Envío</p>',
    en: '<p>Transfer</p>',
    pt: '<p>Envio</p>',
    fr: '<p>Envoi</p>',
    eus: '<p>Igorpena</p>',
    cat: '<p>Enviament</p>',
  },

  iota_images_pdf_Data6: {
    es: '<p>Usuario</p>',
    en: '<p>User</p>',
    pt: '<p>Utilizador</p>',
    fr: '<p>Utilisateur</p>',
    eus: '<p>Erabiltzailea</p>',
    cat: '<p>Usuari</p>',
  },

  iota_images_pdf_Data7: {
    es: '<p>IP de envío de datos:</p>',
    en: '<p>Data transfer IP:</p>',
    pt: '<p>IP de envio de dados:</p>',
    fr: '<p>IP d’envoi de données :</p>',
    eus: '<p>Datuak igortzeko IP helbidea:</p>',
    cat: '<p>IP d’enviament de dades:</p>',
  },

  iota_images_pdf_Data8: {
    es: '<p>Fecha:</p>',
    en: '<p>Date:</p>',
    pt: '<p>Data:</p>',
    fr: '<p>Date :</p>',
    eus: '<p>Data:</p>',
    cat: '<p>Data:</p>',
  },

  iota_images_pdf_Data9: {
    es: '<p>Hora:</p>',
    en: '<p>Time:</p>',
    pt: '<p>Hora:</p>',
    fr: '<p>Heure :</p>',
    eus: '<p>Ordua:</p>',
    cat: '<p>Hora:</p>',
  },

  iota_images_pdf_Data10: {
    es: '<p>Nombre:</p>',
    en: '<p>Name:</p>',
    pt: '<p>Nome:</p>',
    fr: '<p>Nom :</p>',
    eus: '<p>Izena:</p>',
    cat: '<p>Nom:</p>',
  },

  iota_images_pdf_Data11: {
    es: '<p>Email:</p>',
    en: '<p>Email:</p>',
    pt: '<p>E-mail:</p>',
    fr: '<p>E-mail :</p>',
    eus: '<p>Helbide elektronikoa:</p>',
    cat: '<p>Adreça electrònica:</p>',
  },

  iota_images_pdf_Data12: {
    es: '<p>IP:</p>',
    en: '<p>IP:</p>',
    pt: '<p>IP:</p>',
    fr: '<p>IP :</p>',
    eus: '<p>IP helbidea:</p>',
    cat: '<p>IP:</p>',
  },

  iota_images_pdf_Data13: {
    es: '<p>Imágenes aportadas</p>',
    en: '<p>Images provided</p>',
    pt: '<p>Imagens proporcionadas</p>',
    fr: '<p>Images apportées</p>',
    eus: '<p>Erantsitako irudiak</p>',
    cat: '<p>Imatges aportades</p>',
  },

  iota_images_pdf_Data14: {
    es: '<p>Vídeos aportados</p>',
    en: '<p>Videos provided</p>',
    pt: '<p>Vídeos proporcionados</p>',
    fr: '<p>Vidéos apportées</p>',
    eus: '<p>Erantsitako bideoak</p>',
    cat: '<p>Vídeos aportats</p>',
  },

  iota_images_pdf_Data15: {
    es: '<p>Nodo de seguridad</p>',
    en: '<p>Security node</p>',
    pt: '<p>Nó de segurança</p>',
    fr: '<p>Nœud de sécurité</p>',
    eus: '<p>Segurtasun nodoa</p>',
    cat: '<p>Node de seguretat</p>',
  },

  iota_images_pdf_Data16: {
    es: '<p>Total:</p>',
    en: '<p>Total:</p>',
    pt: '<p>Total:</p>',
    fr: '<p>Total :</p>',
    eus: '<p>Guztira:</p>',
    cat: '<p>Total:</p>',
  },

  iota_images_pdf_Data17: {
    es: '<p>Inserción en nodo de</p>',
    en: '<p>Insertion in security</p>',
    pt: '<p>Inserção em nó de</p>',
    fr: '<p>Insertion dans le nœud de</p>',
    eus: '<p>Segurtasun nodoan</p>',
    cat: '<p>Inserció en node de</p>',
  },

  iota_images_pdf_Data18: {
    es: '<p>seguridad</p>',
    en: '<p>node</p>',
    pt: '<p>segurança</p>',
    fr: '<p>sécurité</p>',
    eus: '<p>sarrera</p>',
    cat: '<p>seguretat</p>',
  },

  iota_images_pdf_Data19: {
    es: '<p>Sí</p>',
    en: '<p>Yes</p>',
    pt: '<p>Sim</p>',
    fr: '<p>Oui</p>',
    eus: '<p>Bai</p>',
    cat: '<p>Sí</p>',
  },

  iota_images_pdf_Data20: {
    es: '<p>No</p>',
    en: '<p>No</p>',
    pt: '<p>Não</p>',
    fr: '<p>Non</p>',
    eus: '<p>Ez</p>',
    cat: '<p>No</p>',
  },

  iota_images_pdf_Data21: {
    es: '<p>Duración total:</p>',
    en: '<p>Total duration:</p>',
    pt: '<p>Duração total:</p>',
    fr: '<p>Durée totale :</p>',
    eus: '<p>Guztizko iraupena:</p>',
    cat: '<p>Durada total:</p>',
  },

  iota_images_pdf_Data23: {
    es: '<p>Fecha/Hora de inserción</p>',
    en: '<p>Date/Time of insertion</p>',
    pt: '<p>Data/Hora de inserção</p>',
    fr: '<p>Date/heure d’insertion</p>',
    eus: '<p>Nodoan sartzeko</p>',
    cat: '<p>Data i hora d’inserció</p>',
  },

  iota_images_pdf_Data24: {
    es: '<p>en nodo:</p>',
    en: '<p>in the node:</p>',
    pt: '<p>em nó:</p>',
    fr: '<p>dans le nœud :</p>',
    eus: '<p>data/ordua:</p>',
    cat: '<p>al node:</p>',
  },

  iota_images_pdf_Data25: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  iota_images_pdf_Data26: {
    es: '<p>Tamaño</p>',
    en: '<p>Size</p>',
    pt: '<p>Tamanho</p>',
    fr: '<p>Taille</p>',
    eus: '<p>Tamaina</p>',
    cat: '<p>Mida</p>',
  },

  iota_images_pdf_Data27: {
    es: '<p>Resolución</p>',
    en: '<p>Resolution</p>',
    pt: '<p>Resolução</p>',
    fr: '<p>Résolution</p>',
    eus: '<p>Bereizmena</p>',
    cat: '<p>Resolució</p>',
  },

  iota_images_pdf_Data28: {
    es: '<p>Cámara</p>',
    en: '<p>Camera</p>',
    pt: '<p>Câmara</p>',
    fr: '<p>Caméra</p>',
    eus: '<p>Kamera</p>',
    cat: '<p>Càmera</p>',
  },

  iota_images_pdf_Data29: {
    es: '<p>Duración</p>',
    en: '<p>Duration</p>',
    pt: '<p>Duração</p>',
    fr: '<p>Durée</p>',
    eus: '<p>Iraupen</p>',
    cat: '<p>Durada</p>',
  },

  iota_images_pdf_Data30: {
    es: '<p>Hash</p>',
    en: '<p>Hash</p>',
    pt: '<p>Hash</p>',
    fr: '<p>Hash</p>',
    eus: '<p>Hash</p>',
    cat: '<p>Hash</p>',
  },

  iota_images_pdf_Data31: {
    es: '<p>N/D</p>',
    en: '<p>N/D</p>',
    pt: '<p>N/D</p>',
    fr: '<p>N/D</p>',
    eus: '<p>Ez dago eskuragarri</p>',
    cat: '<p>N/D</p>',
  },
};
