export const VEGETATION_REPORT_I18N_ENTRIES = {
  vegetation_Report_data1: {
    es: '<p>Informe de Análisis de Vegetación</p>',
    en: '<p>Vegetation Analysis Report</p>',
    pt: '<p>Relatório de análise de vegetação</p>',
    fr: '<p>Rapport d\'analyse de végétation</p>',
    eus: '<p>Landaretza analisiari buruzko txostena</p>',
    cat: '<p>Informe d\'anàlisi de vegetació</p>',
  },

  vegetation_Report_data2: {
    es: '<p>Nombre:</p>',
    en: '<p>Name:</p>',
    pt: '<p>Nome:</p>',
    fr: '<p>Nom:</p>',
    eus: '<p>Izena:</p>',
    cat: '<p>Nom:</p>',
  },

  vegetation_Report_data3: {
    es: '<p>Código del Modelo:</p>',
    en: '<p>Model Code:</p>',
    pt: '<p>Código do modelo:</p>',
    fr: '<p>Code du modèle:</p>',
    eus: '<p>Ereduaren kodea:</p>',
    cat: '<p>Codi del model:</p>',
  },

  vegetation_Report_data4: {
    es: '<p>Fecha:</p>',
    en: '<p>Date:</p>',
    pt: '<p>Data:</p>',
    fr: '<p>Date:</p>',
    eus: '<p>Data:</p>',
    cat: '<p>Data:</p>',
  },

  vegetation_Report_data5: {
    es: '<p>Modelo</p>',
    en: '<p>Model</p>',
    pt: '<p>Modelo</p>',
    fr: '<p>Modèle</p>',
    eus: '<p>Eredua</p>',
    cat: '<p>Model</p>',
  },

  vegetation_Report_data6: {
    es: '<p>Total</p>',
    en: '<p>Total</p>',
    pt: '<p>Total</p>',
    fr: '<p>Total</p>',
    eus: '<p>Guztira</p>',
    cat: '<p>Total</p>',
  },

  vegetation_Report_data7: {
    es: '<p>Vol / quad.</p>',
    en: '<p>Vol / quad.</p>',
    pt: '<p>Vol. / quad.</p>',
    fr: '<p>Vol / quad.</p>',
    eus: '<p>Bol / koadr.</p>',
    cat: '<p>Vol / quad.</p>',
  },

  vegetation_Report_data8: {
    es: '<p>% Vegetación</p>',
    en: '<p>% Vegetation</p>',
    pt: '<p>% de Vegetação</p>',
    fr: '<p>% de végétation</p>',
    eus: '<p>Landaretza %</p>',
    cat: '<p>% vegetació</p>',
  },
};

