import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Model } from '../../../models/model';
import { ModelSearchCriteria } from '../../../models/security/model-search-criteria';
import { ModelRemoteService } from '../remote/model/model-remote.service';
import { ModelFile } from '../../../commons/models/model-file';
import { GalleryCategoryEnum } from 'src/app/models/common/gallery-category-enum';
import { ModelSourcesResponse } from 'src/app/commons/models/model/model-sources-response';
import { GalleryRemoteService } from '../remote/model/gallery-remote.service';
import { GalleryOptions } from '../../models/gallery/gallery-options';

@Injectable({ providedIn: 'root' })
export class ModelService {
  /*private models = new BehaviorSubject<Model[]>([]);*/
  constructor(
    private modelRemoteService: ModelRemoteService,
    private galleryRemoteService: GalleryRemoteService
  ) {}
  getProcessingModels(): Observable<Model[]> {
    return this.modelRemoteService.getProcessings();
  }

  save(model: Model): Observable<Model> {
    return this.modelRemoteService.save(model);
  }

  saveObj(file: Blob): Observable<any> {
    return this.modelRemoteService.saveObj(file);
  }

  /*public getModelsRole(id: number) {
    const criteria = new ModelSearchCriteria();
    criteria.name = null;
    this.modelRemoteService.search(criteria).subscribe(models => {
      this.models.next(models);
    });
  }*/

  public getModelsByUser(
    categories: number[],
    subcategories: number[],
    pageSize: number = 30,
    page: number = 1,
    search: string = '',
    departments: number[],
    extra_filters: number[]
  ) {
    return this.galleryRemoteService.getUserModels(
      new GalleryOptions(
        page,
        pageSize,
        search,
        categories,
        subcategories,
        extra_filters,
        departments
      )
    );
  }

  public getPopularModels(
    categories: number[],
    pageSize: number = 30,
    page: number = 1,
    filter: string = ''
  ): Observable<Model[]> {
    return this.galleryRemoteService.getPopularModels(
      new GalleryOptions(page, pageSize, filter, categories, [], [], [])
    );
  }

  public getFavoritesModels(
    categoryId: string,
    pageSize: number = 30,
    page: number = 1,
    filter: string = '',
    department: string = '',
    filterBy: string = ''
  ) {
    return this.modelRemoteService.getFavorites(
      categoryId,
      pageSize,
      page,
      filter,
      department,
      filterBy
    );
  }

  public getLastModels() {
    return this.modelRemoteService.getLastModels();
  }

  public getPreviewImage(modelId: number) {
    return this.modelRemoteService.getPreviewImage(modelId);
  }

  public getPreviewImageByName(modelId: number, fileName: string) {
    return this.modelRemoteService.getPreviewByName(modelId, fileName);
  }

  public getPhotoSceneSnapshotByName(modelId: number, fileName: string) {
    return this.modelRemoteService.getPhotoSnapshotByName(modelId, fileName);
  }

  public getSourceImageByName(modelId: number, fileName: string) {
    return this.modelRemoteService.getSourceByName(modelId, fileName);
  }

  public getModelFilesName(
    modelId: number,
    isDesktop: boolean
  ): Observable<ModelSourcesResponse> {
    return this.modelRemoteService.getModelFilesName(modelId, isDesktop);
  }

  public getModelFilesNameP2P(
    modelId: number,
    isDesktop: boolean
  ): Observable<ModelFile[]> {
    return this.modelRemoteService.getModelFilesNameP2P(modelId, isDesktop);
  }

  public getThumbnailImage(modelId: number, fileName: string) {
    return this.modelRemoteService.getThumbailImage(modelId, fileName);
  }

  public getSourcesName(modelId: number) {
    return this.modelRemoteService.getSourcesName(modelId);
  }

  public getPhotoReadjustmentImageByName(modelId: number, fileName: string) {
    return this.modelRemoteService.getPhotoReadjustmentByName(
      modelId,
      fileName
    );
  }
}
