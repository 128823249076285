export const DELETE_ACCOUNT_DIALOG_I18N_ENTRIES = {

  borrar_Cuenta_Data1: {
    es: '<p>Dar de baja la cuenta</p>',
    en: '<p>Close account</p>',
    pt: '<p>Eliminar conta</p>',
    fr: '<p>Supprimer le compte</p>',
    eus: '<p>Baja eman kontuari</p>',
    cat: '<p>Dona de baixa el compte</p>',
  },

  borrar_Cuenta_Data2: {
    es: '<p>Si das de baja tu cuenta se eliminarán todos los datos,</p><p>modelos 3D e información del perfil de manera permanente.</p>',
    en: '<p>If you close your account, all of your data,</p><p>3D models, and profile information will be permanently deleted.</p>',
    pt: '<p>Se eliminar a sua conta, todos os dados,</p><p>modelos 3D e informações de perfil serão permanentemente eliminado</p>',
    fr: '<p>Si vous supprimez votre compte, toutes les données,</p><p>tous les modèles 3D et toutes les informations du profil seront supprimés de manière permanente.</p>',
    eus: '<p>Zure kontuari baja ematen badiozu, betiko ezabatuko dira datu guztiak,</p><p>3D ereduak eta profilari buruzko informazioa.</p>',
    cat: '<p>Si dones de baixa el teu compte, s\'eliminaran totes les dades,</p><p>els models 3D i la informació del perfil de forma permanent.</p>',
  },

  borrar_Cuenta_Data3: {
    es: '<p>Dar de baja la cuenta</p>',
    en: '<p>Close account</p>',
    pt: '<p>Eliminar conta</p>',
    fr: '<p>Supprimer le compte</p>',
    eus: '<p>Baja eman kontuari</p>',
    cat: '<p>Dona de baixa el compte</p>',
  },

  borrar_Usuario_Data1: {
    es: '<p>Dar de baja al usuario</p>',
    en: '<p>Delete user</p>',
    pt: '<p>Eliminar utilizador</p>',
    fr: '<p>Supprimer l\'utilisateur</p>',
    eus: '<p>Baja eman erabiltzaileari</p>',
    cat: '<p>Dona de baixa l\'usuari</p>',
  },

  borrar_Usuario_Data2: {
    es: '<p>Si das de baja al usuario %X% se eliminarán todos los datos</p><p>e información del perfil de manera permanente.</p>',
    en: '<p>If you delete the user %X%, all data</p><p>and profile information will be permanently deleted.</p>',
    pt: '<p>Se eliminar o utilizador %X%, também serão eliminados todos os dados</p><p>e informações do perfil de forma permanente.</p>',
    fr: '<p>Si vous supprimez l\'utilisateur %X%, toutes les données</p><p>et les informations du profil seront supprimées de manière permanente.</p>',
    eus: '<p>%X% erabiltzaileari baja ematen badiozu, betiko ezabatuko dira</p><p>profilari buruzko informazio eta datu guztiak.</p>',
    cat: '<p>Si dones de baixa l\'usuari %X%, totes les dades</p><p>i la informació del perfil se suprimiran de manera permanent.</p>',
  },

  borrar_Usuario_Data3: {
    es: '<p>Los modelos 3D que pertenezcan al usuario pasarán a estar asignados</p><p>al Empleado General de Departamente. Si deseas asignar alguno de</p><p>estos modelos 3D a otro usuario, por favor, hazlo previamente a la</p><p>eliminación de la cuenta.</p>',
    en: '<p>3D models that belong to the user will be assigned</p><p>to the General Employee of Department. If you want to assign</p><p>any of these 3D models to another user, please do it before</p><p>deleting the account.</p>',
    pt: '<p>Os modelos 3D que pertencem ao utilizador serão atribuídos</p><p>ao Funcionário Geral do Departamento. Se pretender atribuir</p><p>qualquer um destes modelos 3D a outro utilizador, faça-o antes</p><p>de eliminar a conta.</p>',
    fr: '<p>Les modèles 3D qui appartiennent à l\'utilisateur seront attribués</p><p>à l\'employé général du département. Si vous souhaitez attribuer l’un de</p><p>ces modèles 3D à un autre utilisateur, veuillez le faire avant de</p><p>supprimer le compte.</p>',
    eus: '<p>Erabiltzaile horren 3D ereduak Departamentuko Langile Orokorra</p><p>profilera transferituko dira. 3D eredu horietakoren bat beste</p><p>erabiltzaile bati transferitu nahi badiozu, egin aldaketa kontua</p><p>ezabatu baino lehen.</p>',
    cat: '<p>Els models 3D que pertanyen a l\'usuari s\'assignaran</p><p>a l\'Empleat General de Departament. Si vols assignar algun</p><p>d’aquests models 3D a un altre usuari, fes-ho</p><p>abans que s’elimini el compte.</p>',
  },

  borrar_Usuario_Data4: {
    es: '<p>Si este usuario no pertenece a ningún departamento, sus modelos 3D</p><p>pasarán a estar asignados al Empleado General de Empresa.</p>',
    en: '<p>If this user does not belong to any department, its 3D models</p><p>will be assigned to the General Employee of Company.</p>',
    pt: '<p>Se este utilizador não pertencer a nenhum departamento, os seus modelos 3D</p><p>passarão a estar atribuídos ao Funcionário Geral da Empresa.</p>',
    fr: '<p>Si cet utilisateur n’appartient à aucun département, ses modèles 3D</p><p>seront assignés à l’employé général de l’entreprise.</p>',
    eus: '<p>Erabiltzaile hori departamentu baten barruan ez badago, haren 3D ereduak</p><p>Enpresako Langile Orokorra profilera transferituko dira.</p>',
    cat: '<p>Si aquest usuari no pertany a cap departament, els seus models 3D</p><p>s\'assignaran a l\'Empleat General d\'Empresa.</p>',
  },

  borrar_Usuario_Data5: {
    es: '<p>Dar de baja la cuenta</p>',
    en: '<p>Close account</p>',
    pt: '<p>Eliminar conta</p>',
    fr: '<p>Supprimer le compte</p>',
    eus: '<p>Baja eman kontuari</p>',
    cat: '<p>Dona de baixa el compte</p>',
  },

  borrar_Departamento_Data1: {
    es: '<p>Dar de baja al departamento</p>',
    en: '<p>Close department</p>',
    pt: '<p>Eliminar departamento</p>',
    fr: '<p>Supprimer le département</p>',
    eus: '<p>Baja eman departamentuari</p>',
    cat: '<p>Dona de baixa el departament</p>',
  },

  borrar_Departamento_Data2: {
    es: '<p>Si das de baja el departamento %X% se eliminarán</p><p>todos los datos e información del departamento de manera permanente.</p>',
    en: '<p>If you close the department %X%, all data</p><p>and information of the department will be deleted permanently.</p>',
    pt: '<p>Se eliminar o departamento %X%, também serão eliminados</p><p>todos os dados e informações do departamento de forma permanente.</p>',
    fr: '<p>Si vous supprimez le département %X%, toutes les données et informations du département</p><p>seront éliminées de façon permanente.</p>',
    eus: '<p>%X% departamentuari baja ematen badiozu,</p><p>betiko ezabatuko dira departamentuari buruzko informazio eta datu guztiak.</p>',
    cat: '<p>Si dones de baixa el departament %X%, s\'eliminaran</p><p>totes les dades i la informació del departament de forma permanent.</p>',
  },

  borrar_Departamento_Data3: {
    es: '<p>Además, todos los usuarios que pertenezcan al departamento serán</p><p>eliminados, al igual que todos sus datos e información del perfil. Los modelos</p><p>3D que pertenezcan al usuario pasarán a estar asignados al Empleado</p><p>General de Empresa. Si deseas asignar alguno de estos modelos 3D a otro</p><p>usuario, por favor, hazlo previamente a la eliminación de la cuenta.</p>',
    en: '<p>Also, all users who belong to the department will be</p><p>deleted, as will all their data and information on the profile. The 3D</p><p>models that belong to the user will be assigned to the General Employee</p><p>of Company. If you want to assign any of these 3D models to another</p><p>user, please do it previously to the deletion of the account.</p>',
    pt: '<p>Além disso, todos os utilizadores que pertençam ao departamento serão</p><p>eliminados, assim como todos os seus dados e informações do perfil. Os modelos</p><p>3D que pertencem ao utilizador serão atribuídos ao Funcionário Geral da Empresa.</p><p>Se pretender atribuir qualquer um destes modelos 3D a outro utilizador,</p><p>faça-o antes de eliminar a conta.</p>',
    fr: '<p>De plus, tous les utilisateurs qui appartiennent au département seront</p><p>supprimés, ainsi que toutes leurs données et informations du profil. Les modèles</p><p>3D qui appartiennent à l\'utilisateur seront attribués à l\'employé général de l\'entreprise.</p><p>Si vous souhaitez attribuer l\'un de ces modèles 3D à un autre utilisateur, veuillez</p><p>le faire avant de supprimer le compte.</p>',
    eus: '<p>Horrez gain, departamentu horretan dauden erabiltzaile guztiak ezabatuko dira,</p><p>baita haien profileko informazio eta datu guztiak ere. Erabiltzaile horren 3D</p><p>ereduak Departamentuko Langile Orokorra profilera transferituko dira.</p><p>3D eredu horietakoren bat beste erabiltzaile bati transferitu nahi badiozu,</p><p>egin aldaketa kontua ezabatu baino lehen.</p>',
    cat: '<p>A més, s’eliminaran tots els usuaris del departament i</p><p>totes les seves dades i informació del perfil. Els models</p><p>3D que pertanyin a l’usuari s\'assignaran a l’Empleat General de</p><p>l’Empresa. Si vols assignar algun d’aquests models 3D a un altre usuari,</p><p>fes-ho abans que s’elimini el compte.</p>',
  },

  borrar_Departamento_Data4: {
    es: '<p>Para no eliminar los usuarios que pertenecen a este departamento, por favor,</p><p>transfiérelos a otro departamento antes de continuar con el proceso.</p>',
    en: '<p>To avoid deleting any users belonging to this department, please</p><p>transfer them to another department before proceeding.</p>',
    pt: '<p>Para não eliminar os utilizadores que pertencem a este departamento,</p><p>transfira-os para outro departamento antes de continuar com o processo.</p>',
    fr: '<p>Pour ne pas supprimer les utilisateurs qui appartiennent à ce département, veuillez,</p><p>les transférer à un autre département avant de continuer le processus.</p>',
    eus: '<p>Departamentu honen barruan dauden erabiltzaileak ez ezabatzeko,</p><p>transferitu itzazu beste departamentu batera prozesuarekin jarraitu aurretik.</p>',
    cat: '<p>Per no eliminar els usuaris que pertanyin a aquest departament,</p><p>cal transferir-los a un altre departament abans de continuar amb el procés.</p>',
  },

  borrar_Departamento_Data5: {
    es: '<p>Dar de baja al departamento</p>',
    en: '<p>Close department</p>',
    pt: '<p>Eliminar departamento</p>',
    fr: '<p>Supprimer le département</p>',
    eus: '<p>Baja eman departamentuari</p>',
    cat: '<p>Dona de baixa el departament</p>',
  },

  transferir_Data1: {
    es: '<p>Modelos 3d del empleado</p><p>general empresa</p>',
    en: '<p>3d models of the general</p><p>employee company</p>',
    pt: '<p>Modelos 3D do funcionário</p><p>geral da empresa</p>',
    fr: '<p>Modèles 3D de l’employé</p><p>général de l’entreprise</p>',
    eus: '<p>Enpresako Langile Orokorra</p><p>profilaren 3D ereduak</p>',
    cat: '<p>Models 3D de l\'empleat</p><p>general empresa</p>',
  },

  transferir_Data2: {
    es: '<p>Modelos 3d del empleado</p><p>general del departamento</p>',
    en: '<p>3d models of the general</p><p>employee of the department</p>',
    pt: '<p>Modelos 3D do funcionário</p><p>geral do departamento</p>',
    fr: '<p>Modèles 3D de l’employé</p><p>général du département</p>',
    eus: '<p>Departamentuko Langile Orokorra</p><p>profilaren 3D ereduak</p>',
    cat: '<p>Models 3D de l\'empleat</p><p>general del departament</p>',
  },

  transferir_Data3: {
    es: '<p>Transferir modelo 3D:</p>',
    en: '<p>Transfer 3D model:</p>',
    pt: '<p>Transferir modelo 3D:</p>',
    fr: '<p>Transférer le modèle 3D :</p>',
    eus: '<p>Transferitu 3D eredua:</p>',
    cat: '<p>Transfereix model 3D:</p>',
  },

  transferir_Data4: {
    es: '<p>Transferir usuarios entre departamentos</p>',
    en: '<p>Transfer users between departments</p>',
    pt: '<p>Transferir utilizadores entre departamentos</p>',
    fr: '<p>Transférer les utilisateurs entre les départements</p>',
    eus: '<p>Transferitu erabiltzaileak departamentuen artean</p>',
    cat: '<p>Transfereix usuaris entre departaments</p>',
  },

  transferir_Data5: {
    es: '<p>Al transferir este usuario a otro departamento, todos los modelos 3d</p><p>que pertenezcan a este usuario pasarán al nuevo departamento</p><p>asignado, desapareciendo del anterior.</p>',
    en: '<p>By transferring this user to another department, all of the 3D</p><p>models belonging to that user will be transferred to the new</p><p>department assigned and disappear from the old one.</p>',
    pt: '<p>Ao transferir este utilizador para outro departamento, todos os modelos 3D</p><p>que pertençam a este utilizador passarão para o novo departamento</p><p>atribuído, desaparecendo do anterior.</p>',
    fr: '<p>En transférant cet utilisateur à un autre département, tous les modèles 3D</p><p>appartenant à cet utilisateur seront assignés au nouveau département</p><p>et disparaîtront de l’ancien.</p>',
    eus: '<p>Erabiltzaile hau beste departamentu batera transferitzean, bere 3D eredu guztiak</p><p>aukeratu duzun departamentu berrira pasatuko dira, eta</p><p>hasierako departamentutik desagertuko dira.</p>',
    cat: '<p>En transferir aquest usuari a un altre departament, tots els models 3D</p><p>d’aquest usuari passaran al nou departament</p><p>assignat i desapareixeran de l’anterior.</p>',
  },

  transferir_Data6: {
    es: '<p>Guardar</p>',
    en: '<p>Save</p>',
    pt: '<p>Guardar</p>',
    fr: '<p>Sauvegarder</p>',
    eus: '<p>Gorde</p>',
    cat: '<p>Desa</p>',
  },

  transferir_Data7: {
    es: '<p>Cancelar</p>',
    en: '<p>Cancel</p>',
    pt: '<p>Cancelar</p>',
    fr: '<p>Annuler</p>',
    eus: '<p>Utzi</p>',
    cat: '<p>Cancel·la</p>',
  },

  transferir_Data8: {
    es: '<p>Claves api</p>',
    en: '<p>Api keys</p>',
    pt: '<p>Chaves api</p>',
    fr: '<p>Clés API</p>',
    eus: '<p>API gakoak</p>',
    cat: '<p>Claus api</p>',
  },

  transferir_Data9: {
    es: '<p>Transferir</p>',
    en: '<p>Transfer</p>',
    pt: '<p>Transferir</p>',
    fr: '<p>Transférer</p>',
    eus: '<p>Transferitu</p>',
    cat: '<p>Transfereix</p>',
  },

  transferir_Data10: {
    es: '<p>Transferir modelo 3D:</p>',
    en: '<p>Transfer 3D model:</p>',
    pt: '<p>Transferir modelo 3D:</p>',
    fr: '<p>Transférer le modèle 3D :</p>',
    eus: '<p>Transferitu 3D eredua:</p>',
    cat: '<p>Transfereix model 3D:</p>',
  },

};

