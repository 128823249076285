/**
 * Enumerates all existing tools.
 */
export enum Tools {
  NONE,
  MEASURE,
  MEASURE_MULTIPLE,
  HEIGHT,
  HEIGHT_MULTIPLE,
  AREA,
  AREA_MULTIPLE,
  VOLUME,
  VOLUME_MULTIPLE,
  SURFACE,
  SURFACE_MULTIPLE,
  SURFACE_MEASURE_MULTIPLE,
  SURFACE_AREA_MULTIPLE,
  SURFACE_VOLUME_MULTIPLE,
  MULTIPLE,
  CONTOUR,
  CONTOUR_MULTIPLE,
  SCALE,
  COORDINATE,
  REFERENCE_SYSTEM,
  GEOREFERENCE_SYSTEM,
  FLOOR,
  Z_AXIS,
  NOTES,
  CLIPPING,
  MODEL_JOIN,
  OBJECT_SELECTION,
  SECTION,
  PROJECTION,
  ELEVATION_MAP,
  VERTEX_POINTS,
  VIRTUAL_TOUR,
  SEGMENTATION,
  INITIAL_POSITION,
  ROTATION_CENTER,
  RESTORE,
  DEFORMATION_ENERGY,
  BLOOD_SPLATTER,
  BULLET_PATH,
  INTERIOR_BLUEPRINTS,
  EXTERIOR_INTERIOR,
  OFFLINE_VIEWER,
  POINT_TO_POINT,
  PHOTORECTIFICATION,
  IOTA,
  MULTICAM,
  RECORD,
  FILTERS,
  MULTILAYER,
  MAP,
  SENSORIC,
  HYPERSPECTRAL,
  FULLSCREEN,
  SHARE,
  BACKGROUND_COLOR,
  LANGUAGE_SELECTOR,
  INCIDENCES,
  ANGLE,
  ANGLE_MULTIPLE,
  IFC,
  THICKNESS,
  THREE_D_FACES,
  THREE_D_MANUAL,
  THREE_D_PROJECTION,
  THREE_D_MATCHING,
  TWO_D_MATCHING,
  VEGETATION_CALCULATION,
  ZOOM,
  ZOOM_PANEL,
  FIRST_PERSON,
  IOTA_DOWNLOAD,
  IOTA_PROCESS,
  IOTA_IMAGES,
  REDUCED_VIRTUAL_TOUR,
  VR,
  TRAFFIC_ANIMATION,
  RUN_OVER,
  BRAKING_FOOTPRINT,
  ORTHOMOSAIC
}
