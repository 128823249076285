import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WindowService } from './window.service';

/**
 * Servicios de respuestas
 */
@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  /**
   * Constructor que inicialia el onresize del navegador
   */
  constructor(private windowService: WindowService) {}

  /**
   * Guardar las llamadas
   * @type {[]}
   */
  private callbacks = [];

  /**
   * Guardar las resoluciones de las pantallas
   * @type {any}
   */
  private breakpoints = {
    mobileXS: '(max-width: 389px)',
    mobile: '(min-width: 390px)',
    tablet: '(min-width: 900px)',
    landscape: '(min-width: 1024px)',
    pc1280: '(min-width: 1280px)',
    pc: '(min-width: 1366px)',
  };

  /**
   * Comprueba Si se cumplen los breakpoints especificos
   */
  public mobileXS = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.mobileXS);
  public isMobile = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.mobile);
  public isTablet = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.tablet);
  public isLandscape = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.landscape);
  public isPC1280 = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.pc1280);
  public isPC = () =>
    this.windowService.isBrowser && this.ruleIsMet(this.breakpoints.pc);

  /**
   * Comprueba los cambios de las llamadas
   * @param {any} f
   */
  public registerChangeCallback = (f: never) => {
    this.callbacks.push(f);
  };
  /**
   * Comprueba las reglas del navegador
   * @param {string} rule
   * @returns {any}
   */
  // private ruleIsMet = (rule: string) => window.matchMedia(rule).matches;
  private ruleIsMet(rule: string): boolean {
    if (this.windowService.isBrowser) {
      return window.matchMedia(rule).matches;
    }
    return false;
  }
}
