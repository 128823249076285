export const AUTH_I18N_ENTRIES = {
  auth_data1: {
    es: '<p>* El nombre es obligatorio</p>',
    en: '<p>* Name is required</p>',
    pt: '<p>* O nome é obrigatório</p>',
    fr: '<p>* Le nom est obligatoire</p>',
    eus: '<p>* Izena beharrezkoa da</p>',
    cat: '<p>* El nom és obligatori</p>',
  },

  auth_data2: {
    es: '<p>* El correo electrónico es obligatorio</p>',
    en: '<p>* Email is required</p>',
    pt: '<p>* O e-mail é obrigatório</p>',
    fr: '<p>* L’e-mail est obligatoire</p>',
    eus: '<p>* Helbide elektronikoa beharrezkoa da</p>',
    cat: '<p>* L\'adreça electrònica és obligatòria</p>',
  },

  auth_data3: {
    es: '<p>* La contraseña es obligatoria</p>',
    en: '<p>* Password is required</p>',
    pt: '<p>* O campo Palavra-passe é obrigatório</p>',
    fr: '<p>* Un mot de passe est requis</p>',
    eus: '<p>* Pasahitza beharrezkoa da</p>',
    cat: '<p>* La contrasenya és obligatòria</p>',
  },

  auth_data4: {
    es: '<p>* Contraseña mínimo 8 caracteres con un carácter especial</p>',
    en: '<p>* Password must have at least 8 characters and a special character</p>',
    pt: '<p>* A palavra-passe deve ter, no mínimo, 8 caracteres com um caráter especial</p>',
    fr: '<p>* Le mot de passe doit avoir au moins 8 caractères avec un caractère spécial</p>',
    eus: '<p>* Pasahitzak gutxienez 8 karaktere izan behar ditu karaktere berezi batekin</p>',
    cat: '<p>* La contrasenya ha de tenir, com a mínim, 8 caràcters amb un caràcter especial</p>',
  },

  auth_data5: {
    es: '<p>* Por favor, repita la contraseña</p>',
    en: '<p>* Please repeat the password</p>',
    pt: '<p>* Por favor, repita a palavra-passe</p>',
    fr: '<p>* Veuillez répéter le mot de passe</p>',
    eus: '<p>* Mesedez, errepikatu pasahitza</p>',
    cat: '<p>* Si us plau, repeteix la contrasenya</p>',
  },

  auth_data6: {
    es: '<p>* Las contraseñas no coinciden</p>',
    en: '<p>* Passwords do not match</p>',
    pt: '<p>* As palavras-passe não coincidem</p>',
    fr: '<p>* Les mots de passe ne sont pas identiques</p>',
    eus: '<p>* Pasahitzak ez datoz bat</p>',
    cat: '<p>* Les contrasenyes no coincideixen</p>',
  },

  auth_data7: {
    es: '<p>Por favor, acepta los Términos de Uso y Condiciones</p>',
    en: '<p>Please, accept the Terms of Use and Conditions</p>',
    pt: '<p>Por favor, aceite os Termos de Utilização e Condições</p>',
    fr: '<p>Veuillez accepter les conditions générales d’utilisation</p>',
    eus: '<p>Mesedez, onartu erabiltzeko baldintzak eta terminoak</p>',
    cat: '<p>Si us plau, accepta els Termes d\'ús i condicions</p>',
  },

  auth_data8: {
    es: '<p>* Correo electrónico o contraseña incorrecto</p>',
    en: '<p>* Incorrect email or password</p>',
    pt: '<p>* E-mail ou palavra-passe incorretos</p>',
    fr: '<p>* Adresse e-mail ou mot de passe incorrect</p>',
    eus: '<p>* Helbide elektroniko edo pasahitz okerra</p>',
    cat: '<p>* Adreça electrònica o contrasenya incorrecte</p>',
  },

  auth_data9: {
    es: '<p>* Correo electrónico incorrecto</p>',
    en: '<p>* Incorrect email</p>',
    pt: '<p>* E-mail incorreto</p>',
    fr: '<p>* Adresse e-mail incorrecte</p>',
    eus: '<p>* Helbide elektroniko okerra</p>',
    cat: '<p>* Adreça electrònica incorrecta</p>',
  },

  auth_data10: {
    es: '<p>Por favor, introduce la nueva contraseña<p></p><p>en ambos campos.</p><p>Después, haz click en <span class="i18n-bold">RESTABLECER</span></p><p><span class="i18n-bold">CONTRASEÑA</span> y podras registrarte con la</p><p>nueva contraseña.</p>',
    en: '<p>Please, write your new password</p><p>in both fields.</p><p>Then, click on <span class="i18n-bold">RESET</span></p><p><span class="i18n-bold">PASSWORD</span> and you will be able to register with</p><p>the new password.</p>',
    pt: '<p>Introduza a nova palavra-passe</p><p>nos dois campos. Em seguida, clique em <span class="i18n-bold">REPOR</span></p><p><span class="i18n-bold">PALAVRA-PASSE</span> e poderá registar-se com a</p><p>nova palavra-passe.</p>',
    fr: '<p>Veuillez saisir votre nouveau mot de passe</p><p>dans les deux champs. Ensuite, cliquez sur <span class="i18n-bold">RÉINITIALISER</span></p><p><span class="i18n-bold">MOT DE PASSE</span> et vous pourrez vous inscrire avec le nouveau mot de passe.</p>',
    eus: '<p>Mesedez, idatzi zure pasahitz berria</p><p>bi eremuetan. Ondoren, sakatu <span class="i18n-bold">BERREZARRI</span></p><p><span class="i18n-bold">PASAHITZA</span> eta erregistratu pasahitz berriarekin.</p>',
    cat: '<p>Escriu la nova contrasenya</p><p>en els dos camps.</p><p>Després, fes clic a <span class="i18n-bold">RESTABLEIX LA</span></p><p><span class="i18n-bold">CONTRASENYA</span> i podràs registrar-te amb la</p><p>nova contrasenya.</p>',
  },

  auth_data11: {
    es: '<p>Correo electrónico</p>',
    en: '<p>Email</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>E-mail</p>',
    eus: '<p>Helbide elektronikoa</p>',
    cat: '<p>Adreça electrònica</p>',
  },

  auth_data12: {
    es: '<p>Recuérdame</p>',
    en: '<p>Remember me</p>',
    pt: '<p>Lembrar-me</p>',
    fr: '<p>Se souvenir de moi</p>',
    eus: '<p>Gogora nazazu</p>',
    cat: '<p>Recorda\'m</p>',
  },

  auth_data13: {
    es: '<p>¿Has olvidado tu contraseña?</p>',
    en: '<p>Forgot your password?</p>',
    pt: '<p>Esqueceu-se da sua palavra-passe?</p>',
    fr: '<p>Mot de passe oublié ?</p>',
    eus: '<p>Zure pasahitza ahaztu duzu?</p>',
    cat: '<p>Has oblidat la contrasenya?</p>',
  },

  auth_data14: {
    es: '<p>Restablece la contraseña</p>',
    en: '<p>Reset Password</p>',
    pt: '<p>Reponha a palavra-passe</p>',
    fr: '<p>Rétablir votre mot de passe</p>',
    eus: '<p>Berrezarri pasahitza</p>',
    cat: '<p>Restableix la contrasenya</p>',
  },

  auth_data15: {
    es: '<p>Restablecer contraseña</p>',
    en: '<p>Reset password</p>',
    pt: '<p>Repor palavra-passe</p>',
    fr: '<p>Rétablir mot de passe</p>',
    eus: '<p>Berrezarri pasahitza</p>',
    cat: '<p>Restableix la contrasenya</p>',
  },

  auth_data16: {
    es: '<p>Restablecer</p>',
    en: '<p>Reset</p>',
    pt: '<p>Repor</p>',
    fr: '<p>Rétablir</p>',
    eus: '<p>Berrezarri</p>',
    cat: '<p>Restableix</p>',
  },

  auth_data17: {
    es: '<p>Escribe tu correo y haz click en <span class="i18n-bold">OBTENER CORREO</span></p><p>Te enviaremos un correo con instrucciones para</p><p>restablecer tu contraseña.</p>',
    en: '<p>Enter your email and click on <span class="i18n-bold">SEND ME AN EMAIL</span></p><p>We will send you an email with instructions on how</p><p>to reset your password.</p>',
    pt: '<p>Insira o seu e-mail e clique em <span class="i18n-bold">OBTER E-MAIL</span></p><p>Enviar-lhe-emos um e-mail com instruções para</p><p>repor a sua palavra-passe.</p>',
    fr: '<p>Introduisez votre e-mail et cliquez sur <span class="i18n-bold">OBTENIR E-MAIL.</span></p><p>Nous vous enverrons un e- mail avec les instructions</p><p>pour rétablir votre mot de passe.</p>',
    eus: '<p>Idatzi zure helbide elektronikoa eta sakatu <span class="i18n-bold">JASO POSTA</span></p><p>Zure pasahitza berrezartzeko argibideak</p><p>dituen posta bidaliko dizugu.</p>',
    cat: '<p>Escriu la teva adreça electrònica i fes clic a <span class="i18n-bold">REP CORREU.</span></p><p>T\'enviarem un missatge de correu amb instruccions per</p><p>restablir la contrasenya.</p>',
  },

  auth_data18: {
    es: '<p>Obtener correo</p>',
    en: '<p>Send me an email</p>',
    pt: '<p>Obter e-mail</p>',
    fr: '<p>Envoyez-moi un e-mail</p>',
    eus: '<p>Jaso posta</p>',
    cat: '<p>Rep correu</p>',
  },

  auth_data19: {
    es: '<p>Confirmar correo</p>',
    en: '<p>Confirm email address</p>',
    pt: '<p>Confirmar e-mail</p>',
    fr: '<p>Confirmer e-mail</p>',
    eus: '<p>Berretsi helbide elektronikoa</p>',
    cat: '<pConfirma l\'adreça electrònica</p>',
  },

  auth_data20: {
    es: '<p>Te hemos enviado un correo.</p><p>Por favor, revisa tu bandeja de entrada y de Spam, y</p><p>sigue las instrucciones del correo.</p>',
    en: '<p>We have sent you an email.</p><p>Please, check your inbox and spam folder and</p><p>follow the instructions in the email.</p>',
    pt: '<p>Enviámos-lhe um e-mail</p><p>Verifique a sua caixa de entrada e a pasta de Spam e</p><p>siga as instruções.</p>',
    fr: '<p>Nous vous avons envoyé un e-mail</p><p>Veuillez vérifier votre boîte de réception et vos courriers indésirables et</p><p>suivez les instructions.</p>',
    eus: '<p>Posta bat bidali dizugu.</p><p>Mesedez, begiratu sarrerako ontzian eta spamaren karpetan, eta</p><p>postaren argibideak jarraitu.</p>',
    cat: '<p>T\'hem enviat un missatge de correu electrònic.</p><p>Revisa la safata d\'entrada i la de correu brossa i</p><p>segueix les instruccions que s\'indiquen al correu.</p>',
  },

  auth_data21: {
    es: '<p>Genera modelos 3D en solo 3 pasos.</p>',
    en: '<p>Generate 3D Models in just 3 simple steps.</p>',
    pt: '<p>Crie modelos 3D em apenas 3 passos.</p>',
    fr: '<p>Générez des modèles 3D en seulement 3 étapes.</p>',
    eus: '<p>Sortu 3D ereduak hiru urrats errazetan.</p>',
    cat: '<p>Genera models 3D en només 3 passos.</p>',
  },

  auth_data22: {
    es: '<p>Genera modelos 3D con tu propio Smartphone</p>',
    en: '<p>Generate 3D Models using your smartphone</p>',
    pt: '<p>Crie modelos 3D com o seu próprio Smartphone</p>',
    fr: '<p>Générez des modèles 3D avec votre Smartphone</p>',
    eus: '<p>Sortu 3D ereduak zure telefonoarekin</p>',
    cat: '<p>Genera models 3D amb el teu smartphone</p>',
  },

  auth_data23: {
    es: '<p>Inicia sesión en eyesCloud3d</p>',
    en: '<p>Log in to eyesCloud3d</p>',
    pt: '<p>Inicie sessão em eyesCloud3d</p>',
    fr: '<p>Se connecter à eyesCloud3d</p>',
    eus: '<p>Hasi saioa eyesCloud3d-n</p>',
    cat: '<p>Inicia sessió a eyesCloud3d</p>',
  },

  auth_data24: {
    es: '<p>Inicia sesión</p>',
    en: '<p>Log In</p>',
    pt: '<p>Inicie sessão</p>',
    fr: '<p>Se connecter</p>',
    eus: '<p>Hasi saioa</p>',
    cat: '<p>Inicia sessió</p>',
  },

  auth_data25: {
    es: '<p>¿Ya tienes una cuenta?</p>',
    en: '<p>Do you already have an account?</p>',
    pt: '<p>Já tem uma conta?</p>',
    fr: '<p>Avez-vous déjà un compte ?</p>',
    eus: '<p>Dagoeneko kontu bat daukazu?</p>',
    cat: '<p>Ja tens un compte?</p>',
  },

  auth_data26: {
    es: '<p>Iniciar sesión</p>',
    en: '<p>Log In</p>',
    pt: '<p>Iniciar sessão</p>',
    fr: '<p>Se connecter</p>',
    eus: '<p>Hasi saioa</p>',
    cat: '<p>Inicia sessió</p>',
  },

  auth_data27: {
    es: '<p>¡Prueba eyesCloud3d ahora en solo 3 pasos!</p>',
    en: '<p>Try out eyesCloud3d in just 3 simple steps!</p>',
    pt: '<p>Experimente a eyesCloud3d agora em apenas 3 passos!</p>',
    fr: '<p>Essayez eyesCloud3d tout de suite en seulement 3 étapes!</p>',
    eus: '<p>Frogatu orain eyesCloud3d hiru urrats errazetan!</p>',
    cat: '<p>Prova eyesCloud3d ara en només 3 passos!</p>',
  },

  auth_data28: {
    es: '<p class="i18n-bold">Toma fotos o vídeos</p><p>y crea tu mundo en 3D</p>',
    en: '<p class="i18n-bold">Shoot photos or videos</p><p>and create your own 3D world</p>',
    pt: '<p class="i18n-bold">Tire fotografias ou grave vídeos</p><p>e crie o seu mundo em 3D</p>',
    fr: '<p class="i18n-bold">Prenez des photos ou des vidéos</p><p>et créez votre monde en 3D</p>',
    eus: '<p class="i18n-bold">Argazkiak eta bideoak atera eta</p><p>sortu zure mundua 3Dn</p>',
    cat: '<p class="i18n-bold">Fes fotos o vídeos</p><p>i crea el teu món en 3D</p>',
  },

  auth_data29: {
    es: '<p>eyesCloud3d es una plataforma cloud</p><p>de generación automática de modelos 3D</p><p>a partir de fotografías o vídeos.</p>',
    en: '<p>eyesCloud3d is a cloud platform which</p><p>allows you to automatically generate 3D models</p><p>from photos or videos.</p>',
    pt: '<p>eyesCloud3d é uma plataforma cloud</p><p>para a criação automática de modelos 3D</p><p>a partir de fotografias ou vídeos.</p>',
    fr: '<p>eyesCloud3d est une plateforme Cloud</p><p>qui vous permet de générer automatiquement des modèles 3D</p><p>à partir de photos ou de vidéos.</p>',
    eus: '<p>eyesCloud3d hodeiko plataforma bat da,</p><p>argazkiak eta bideoak erabiliz,</p><p>3D ereduak automatikoki sortzeko.</p>',
    cat: '<p>eyesCloud3d és una plataforma al núvol</p><p>de generació automàtica de models 3D</p><p>a partir de fotografies i vídeos.</p>',
  },

  auth_data30: {
    es: '<p>Crea una cuenta gratis</p>',
    en: '<p>Create a free account</p>',
    pt: '<p>Crie uma conta gratuita</p>',
    fr: '<p>Créez un compte gratuit</p>',
    eus: '<p>Eskuratu doako kontu bat</p>',
    cat: '<p>Crea un compte gratis</p>',
  },

  auth_data31: {
    es: '<p>¿Es tu primera vez?</p>',
    en: '<p>Is this your first time?</p>',
    pt: '<p>É a sua primeira vez?</p>',
    fr: '<p>C\'est votre première fois?</p>',
    eus: '<p>Zure lehenengo aldia da?</p>',
    cat: '<p>És la primera vegada que hi accedeixes?</p>',
  },

  auth_data32: {
    es: '<p>Facebook</p>',
    en: '<p>Facebook</p>',
    pt: '<p>Facebook</p>',
    fr: '<p>Facebook</p>',
    eus: '<p>Facebook</p>',
    cat: '<p>Facebook</p>',
  },

  auth_data33: {
    es: '<p>Conecta con</p>',
    en: '<p>Connect with</p>',
    pt: '<p>Aceda com</p>',
    fr: '<p>Se connecter avec</p>',
    eus: '<p>Konektatu:</p>',
    cat: '<p>Connecta amb</p>',
  },

  auth_data34: {
    es: '<p>Confirma la contraseña</p>',
    en: '<p>Confirm password</p>',
    pt: '<p>Confirme a palavra-passe</p>',
    fr: '<p>Confirmez votre mot de passe</p>',
    eus: '<p>Berretsi pasahitza</p>',
    cat: '<p>Confirma la contrasenya</p>',
  },

  auth_data35: {
    es: '<p>He leído y acepto los</p>',
    en: '<p>I have read and accept the</p>',
    pt: '<p>Li e aceito os</p>',
    fr: '<p>J\'ai lu et j\'accepte les</p>',
    eus: '<p>Irakurri ditut eta onartzen ditut</p>',
    cat: '<p>He llegit i accepto els</p>',
  },

  auth_data36: {
    es: '<p>Comenzar</p>',
    en: '<p>Start</p>',
    pt: '<p>Começar</p>',
    fr: '<p>Commencer</p>',
    eus: '<p>Hasi</p>',
    cat: '<p>Comença</p>',
  },

  auth_data37: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  auth_data38: {
    es: '<p>Mínimo de 8 caracteres e incluir mayúsculas, minúsculas, números y símbolos.</p>',
    en: '<p>Minimum 8 characters, with uppercase, numbers, and symbols.</p>',
    pt: '<p>Mínimo de 8 carateres e deve incluir maiúsculas, minúsculas, números e símbolos.</p>',
    fr: '<p>Le mot de passe doit contenir au moins 8 caractères et comprendre des majuscules, des minuscules, des chiffres et des symboles.</p>',
    eus: '<p>Gutxienez 8 karaktere eduki behar ditu, letra larri, letra xehe, zenbaki eta ikurrekin.</p>',
    cat: '<p>Mínim de 8 caràcters, incloent-hi majúscules, minúscules, nombres i símbols.</p>',
  },
  auth_data39: {
    es: '<p>La contraseña actual no es correcta</p>',
    en: '<p>Incorrect password</p>',
    pt: '<p>A palavra-passe atual não está correta</p>',
    fr: '<p>Le mot de passe actuel n’est pas correct</p>',
    eus: '<p>Sartu duzun pasahitza ez da zuzena</p>',
    cat: '<p>La contrasenya actual no és correcta</p>',
  },
};
