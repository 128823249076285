import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import packageJson from '../../package.json';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { CommsService } from './core/services/comms.service';
import { GeneralUserType } from './commons/models/general-user-type';
import { AuthenticationService } from './services/security/authentication.service';
import { PrivateUrlRemoteService } from './core/services/remote/private-url/private-url-remote.service';
import { ViewerCommsService } from './viewer-new/services/viewer-comms.service';
import { Tools } from './viewer-new/models/tools/tools';
import { CheckContrastService } from './core/services/check-contrast.service';
import { BackgroundColorEnum } from './core/models/background-color/background-color-enum';
import { RestClientService } from './core/services/api-access/rest-client.service';
import { UserSessionService } from './core/services/remote/user/user-session.service';
import { UserRemoteService } from './core/services/remote/user/user.remote.service';
import { FirstExpService } from './core/services/remote/user/first-exp.service';

declare const gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public version: string = packageJson.version;
  subscriptions: Subscription[] = [];
  isMulticamActive: Boolean = false;
  isMulticamTextBlack = false;
  multicamIconsColor = BackgroundColorEnum;
  currentMulticamIconsColor = BackgroundColorEnum.WHITE;
  sessionNavig;
  private previousDevicePixelRatio = window.devicePixelRatio;

  constructor(
    private router: Router,
    private commsService: CommsService,
    private authenticationService: AuthenticationService,
    private privateUrlRemoteService: PrivateUrlRemoteService,
    private firstExpService: FirstExpService,
    private userSessionService: UserSessionService,
    private viewerCommsService: ViewerCommsService,
    private checkContrast: CheckContrastService,
    private restClientService: RestClientService
  ) {
    this.getAuthUser();
    this.subscriptions.push(
      this.authenticationService.onLogin().subscribe(() => {
        this.getAuthUser();
      })
    );
    this.subscriptions.push(
      this.viewerCommsService.onCurrentTools().subscribe((tools) => {
        this.isMulticamActive =
          tools.findIndex((tool) => tool.tool === Tools.MULTICAM) !== -1;
      })
    );
    this.privateUrlRemoteService.checkUrl();
  }

  ngOnInit(): void {
    window.addEventListener('resize', this.checkZoom.bind(this));
    this.checkOfflineViewerRedirection();
    this.initializeGeneralUser();
    this.showCurrentVersion();
    this.listenForUserUpdateRequests();
    this.listenForBackgroundColorChanges();
  }

  private listenForUserUpdateRequests() {
    this.subscriptions.push(
      this.commsService.onRequestUserUpdate().subscribe(() => {
        this.getAuthUser();
      })
    );
  }

  private listenForBackgroundColorChanges() {
    this.subscriptions.push(
      this.viewerCommsService
        .onCurrentBackgroundColor()
        .subscribe((backgroundColor) => {
          this.changeMultiCamIcons(backgroundColor);
        })
    );
  }

  private checkZoom(): void {
    document.body.style.transform = 'scale(1)';
    setTimeout(() => {
      document.body.style.transform = '';
    }, 1);
  }

  private getAuthUser() {
    this.subscriptions.push(
      this.authenticationService.get().subscribe((authUser) => {
        this.commsService.setCurrentUser(authUser);
      })
    );
  }

  private changeMultiCamIcons(color: string): void {
    if (color && !color.includes('gradient')) {
      this.processFlatColor(color);
    } else {
      this.processGradient(color);
    }
  }

  private processFlatColor(color: string) {
    const contrasts = this.checkContrast.getBestContrast(color);
    if (
      contrasts.black > contrasts.white &&
      contrasts.black > contrasts.orange
    ) {
      this.chooseBlack();
    } else if (
      Math.abs(contrasts.white - contrasts.orange) + 3 <
      Math.abs(contrasts.orange - contrasts.black)
    ) {
      this.chooseOrange();
    } else {
      this.chooseWhite();
    }
  }

  private chooseOrange() {
    this.isMulticamTextBlack = false;
    this.currentMulticamIconsColor = this.multicamIconsColor.WHITE;
  }

  private chooseWhite() {
    this.isMulticamTextBlack = false;
    this.currentMulticamIconsColor = this.multicamIconsColor.WHITE;
  }

  private chooseBlack(fromGradient: boolean = false) {
    this.isMulticamTextBlack = true;
    this.currentMulticamIconsColor = this.multicamIconsColor.BLACK;
  }

  private processGradient(color: string): void {
    if (
      [
        'radial-gradient(circle, #FFF5D6 0%, #FFD900 100%)',
        'radial-gradient(circle, #FFFFFF 0%, #ACA8A8 100%)',
      ].includes(color)
    ) {
      this.chooseBlack(true);
    } else if (color === 'radial-gradient(circle, #575C5F 0%, #232C31 100%)') {
      this.chooseWhite();
    }
  }

  private showCurrentVersion() {
    console.log('eyesCloud3d ' + this.version);
  }

  private initializeGeneralUser() {
    this.commsService.sendGeneralUser(GeneralUserType.NONE);
  }

  private checkOfflineViewerRedirection() {
    if (environment.isOfflineViewer) {
      this.restClientService.getOfflineViewerModel().subscribe((code) => {
        this.router.navigateByUrl(`viewer/${code}/mesh`);
      });
      return;
    }
  }
}
