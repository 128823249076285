import { Component, OnInit } from '@angular/core';
import { InformationPageEnum } from '../enum/information-page-enum';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements OnInit {

  informationPageNumber = InformationPageEnum.COOKIE_POLICY;

  constructor() { }

  ngOnInit(): void {
  }

}
