import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslationModeEnum } from 'src/app/commons/enum/translation-mode-enum';
import { I18nService } from 'src/app/core/services/i18n.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CountryRemoteService } from 'src/app/core/services/remote/common/country-remote.service';
import { UserRemoteService } from 'src/app/core/services/remote/user/user.remote.service';
import { Country } from 'src/app/models/country';
import { RequestToBeACompany } from '../../new-3d/models/request-to-be-a-company';

@Component({
  selector: 'app-mobile-no-professional-notice',
  templateUrl: './mobile-no-professional-notice.component.html',
  styleUrls: ['./mobile-no-professional-notice.component.scss'],
})
export class MobileNoProfessionalNoticeComponent implements OnInit {
  step = 0;
  successMesage = false;
  submitted = false;
  errorMessage1 = '';
  errorMessage2 = '';
  form: UntypedFormGroup;
  countries: Country[] = [];
  translationModes = TranslationModeEnum;

  constructor(
    private loaderService: LoaderService,
    private countryRemoteService: CountryRemoteService,
    private i18nService: I18nService,
    private formBuilder: UntypedFormBuilder,
    private userRemoteService: UserRemoteService
  ) {}

  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.loaderService.showLoader();
    this.loadCountriesData();
  }

  save(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.loaderService.showLoader();

      const request = new RequestToBeACompany();
      request.nif = this.form.controls.nif.value;
      request.businessName = this.form.controls.businessName.value;
      request.address = this.form.controls.address.value;
      request.country = this.form.controls.country.value;
      request.contact = this.form.controls.contact.value;
      request.phone = this.form.controls.phone.value;
      request.email = this.form.controls.email.value;
      request.web = this.form.controls.web.value;
      this.loaderService.hideLoader();
      // console.log(request.country);
      this.userRemoteService.sendRequestToBeACompany(request).subscribe(
        () => {
          this.loaderService.hideLoader();
          // this.router.navigateByUrl('');
          // this.dialogRef.close();
          this.successMesage = true;
          this.step = 4;
        },
        () => {
          this.loaderService.hideLoader();
          this.step = 3;
          this.errorMessage1 = 'No se han podido enviar los datos,';
          this.errorMessage2 = 'inténtelo de nuevo.';
        }
      );
    }
  }

  private loadCountriesData() {
    this.countryRemoteService.search(null).subscribe((countries) => {
      this.countries = countries;
      this.createForm();
    });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      nif: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      contact: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email1: [
        '',
        [Validators.required, Validators.email, this.emailDomainValidator],
      ],
      email2: [
        '',
        [Validators.required, Validators.email, this.emailDomainValidator],
      ],
      name1: ['', [Validators.required]],
      name2: ['', [Validators.required]],
    });
    this.loaderService.hideLoader();
  }

  emailDomainValidator(control: UntypedFormControl) {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [_, domain] = email.split('@');
      if (
        domain === 'hotmail.com' ||
        domain === 'hotmail.es' ||
        domain === 'gmail.com' ||
        domain === 'outlook.com' ||
        domain === 'yandex.com' ||
        domain === 'yandex.ru' ||
        domain === 'yahoo.com' ||
        domain === 'yahoo.es' ||
        domain === 'wanadoo.es' ||
        domain === 'orangemail.es' ||
        domain === 'movistar.es'
      ) {
        return {
          emailDomain: {
            parsedDomain: domain,
          },
        };
      }
    }
    return null;
  }

  mobileRequest() {
    this.step = 1;
  }
}
