export const MAX_EXTENSION_FILES_I18N_ENTRIES = {

  maxExtensionFiles_Data1: {
    es: '<p>Se han detectado <span class="new-bold">más de un archivo</span> con extensión</p><p>%X% para continuar con la</p><p>generación del modelo elimina archivos hasta quedarte</p><p>con uno de cada extensión.</p>',
    en: '<p>More than one %X% file has been detected.</p><p>To continue generating the model, please</p><p>delete files so that there is only</p><p>one file of each file type.</p>',
    pt: '<p>Foi detetado mais um ficheiro com extensão</p><p>%X%.Para continuar com a</p><p>criação do modelo, elimine ficheiros até ficar</p><p>com um de cada extensão.</p>',
    fr: '<p>Plusieurs fichiers portant l’extension</p><p>%X% ont été détectés.</p><p>Pour poursuivre la génération</p><p>du modèle, supprimez les fichiers</p><p>jusqu’à ce qu’il ne reste plus</p><p>qu’un fichier de chaque extension.</p>',
    eus: '<p>%X% luzapena duten fitxategi bat baino gehiago</p><p>aurkitu dira; eredua sortzen jarraitzeko,</p><p>ezabatu fitxategiak luzapen bakoitzeko fitxategi bakar</p><p>batekin geratu arte.</p>',
    cat: '<p>S’ha detectat més d’un fitxer amb l’extensió</p><p>%X%. Per continuar amb la generació</p><p>del model, elimina fitxers fins quedar-te</p><p>amb un de cada extensió.</p>',
  },

  maxExtensionFiles_Data2: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },
};
