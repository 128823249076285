import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Saveable } from '../../../../commons/models/saveable';
import { Retrievable } from '../../../../commons/models/retrievable';
import { RestClientService } from '../../api-access/rest-client.service';
import { Deleteable } from '../../../../commons/models/deleteable';
import { Searchable } from '../../../../commons/models/searchable';
import { ModelSearchCriteria } from '../../../../models/security/model-search-criteria';
import { PointCloud } from '../../../../models/model/point-cloud';
import { ModelFile } from '../../../../commons/models/model-file';
import { UserModel } from '../../../../commons/models/user-model';
import { ExternalInfo } from 'src/app/models/external-info';
import { Model } from '../../../../models/model';
import { ModelConverter } from 'src/app/viewer/models/point-cloud/model-converter';
import { ModelSourcesResponse } from 'src/app/commons/models/model/model-sources-response';
import { ModelSourceDownload } from 'src/app/core/models/source-download/model-source-download';

@Injectable({
  providedIn: 'root',
})
export class ModelRemoteService
  implements
    Searchable<Model, ModelSearchCriteria>,
    Retrievable<Model, number>,
    Saveable<Model>,
    Deleteable<Model, number>
{
  private URL = '/3dmodels';
  public static scope: ModelRemoteService;

  constructor(private restClientService: RestClientService) {
    ModelRemoteService.scope = this;
  }

  public getProcessings(
    criteria: ModelSearchCriteria = null
  ): Observable<Model[]> {
    return this.restClientService.get(
      `${this.URL}/byFilter/processings`,
      criteria
    );
  }

  save(model: Model): Observable<Model> {
    if (model.id !== null && model.id !== undefined) {
      return this.restClientService.put(`${this.URL}/${model.id}`, model);
    }
    return this.restClientService.post(this.URL, model);
  }

  saveObj(file: Blob): Observable<any> {
    return this.restClientService.post(`${this.URL}/from-obj`, file);
  }

  get(id: number): Observable<Model> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }

  search(criteria?: ModelSearchCriteria): Observable<Model[]> {
    return this.restClientService.get(this.URL, criteria);
  }

  /*getPortfolio(): Observable<Model[]> {
    return this.restClientService.get('/models/portfolio');
  }*/

  getByUser(
    categoryId: string,
    subcategoryId: string,
    pageSize: number,
    page: number,
    filter: string,
    department: string,
    filterBy: string
  ): Observable<Model[]> {
    return this.restClientService.get(
      `${this.URL}/byFilter/byuser?page=${page}&pageSize=${pageSize}&search=${filter}&category=[${categoryId}]&subcategory=[${subcategoryId}]&filterBy=[${filterBy}]&department=[${department}]`
    );
  }

  getPopulars(
    categoryId: string,
    pageSize: number,
    page: number,
    filter: string,
    criteria: ModelSearchCriteria = null
  ): Observable<Model[]> {
    return this.restClientService.get(
      `${this.URL}/byFilter/populars?page=${page}&pageSize=${pageSize}&search=${filter}&category=[${categoryId}]`,
      criteria
    );
  }

  getFavorites(
    categoryId: string,
    pageSize: number,
    page: number,
    filter: string,
    department: string,
    filterBy: string
  ): Observable<Model[]> {
    return this.restClientService.get(
      `${this.URL}/byFilter/favorites?page=${page}&pageSize=${pageSize}&search=${filter}&category=[${categoryId}]&filterBy=[${filterBy}]&department=[${department}]`
    );
  }

  getLastModels(): Observable<Model[]> {
    return this.restClientService.get(`${this.URL}/byFilter/lasts`);
  }

  getPreviewImage(modelId: number): Observable<Blob> {
    return this.restClientService.getBlob(`${this.URL}/${modelId}/preview`);
  }

  getPreviewByName(modelId: number, filename: string): Observable<Blob> {
    return this.restClientService.getBlob(
      `${this.URL}/${modelId}/preview-by-name/${filename}`
    );
  }

  getPhotoSnapshotByName(modelId: number, filename: string): Observable<Blob> {
    return this.restClientService.getBlob(
      `${this.URL}/${modelId}/photorectification-snapshot-by-name/${filename}`
    );
  }

  getSourceByName(modelId: number, filename: string): Observable<Blob> {
    return this.restClientService.getBlob(
      `${this.URL}/${modelId}/source-by-name/${filename}`
    );
  }

  getModelFilesName(
    modelId: number,
    isDesktop: boolean
  ): Observable<ModelSourcesResponse> {
    return this.restClientService.get(
      `${this.URL}/${modelId}/files-name${isDesktop ? '' : '-reduced'}`
    );
  }

  getModelFilesNameP2P(
    modelId: number,
    isDesktop: boolean
  ): Observable<ModelFile[]> {
    return this.restClientService.get(
      `${this.URL}/${modelId}/files-nameP2P${isDesktop ? '' : '-reduced'}`
    );
  }

  getThumbailImage(modelId: number, filename: string): Observable<Blob> {
    return this.restClientService.getBlob(`${this.URL}/${modelId}/thumbnail`, {
      fileName: filename,
    });
  }

  getByCode(code: string): Observable<Model> {
    return this.restClientService.get(`${this.URL}/byCode/${code}`);
  }

  getCamera(idCamera: number): Observable<string> {
    return this.restClientService.get(`${this.URL}/camera/${idCamera}`);
  }

  getTags(id: number): Observable<string[]> {
    return this.restClientService.get(`${this.URL}/${id}/tags`);
  }

  recoverModel(userId: number, modelId: number): Observable<number> {
    return this.restClientService.post(
      `${this.URL}/${modelId}/recover-model`,
      {}
    );
  }

  moreStorageRequest(userId: number, reason: string): Observable<string> {
    return this.restClientService.post(
      `${this.URL}/${userId}/more-storage-request`,
      { reason }
    );
  }

  moreDownloadsRequest(reason: string, userId: number): Observable<string> {
    return this.restClientService.post(
      `${this.URL}/${userId}/more-downloads-request`,
      { reason }
    );
  }

  deleteModelsRequest(
    modelIds: string,
    userId: number,
    reason: string
  ): Observable<string> {
    return this.restClientService.post(
      `${this.URL}/${modelIds}/delete-models-request`,
      { userId, reason }
    );
  }

  delete(id: number): Observable<Model> {
    return this.restClientService.delete(`${this.URL}/${id}`);
  }

  deleteMoreThanOneModel(modelIds: string): Observable<string> {
    return this.restClientService.delete(
      `${this.URL}/more-than-one/${modelIds}`
    );
  }

  deleteDeleteRequest(modelIds: string): Observable<string> {
    console.log(modelIds);
    return this.restClientService.delete(
      `${this.URL}/delete-requests/${modelIds}`
    );
  }

  approveJoinRequest(tokens: string): Observable<string> {
    console.log(tokens);
    return this.restClientService.put(`model-save-tool/${tokens}/approve`, {});
  }

  denyJoinRequest(tokens: string): Observable<string> {
    return this.restClientService.put(`model-save-tool/${tokens}/deny`, {});
  }

  sendToGenerate(model: Model): Observable<Model> {
    return this.restClientService.post(
      `${this.URL}/${model.id}/generate-model`,
      model
    );
  }

  sendToGenerateHyperespectral(model: Model): Observable<Model> {
    return this.restClientService.post(
      `${this.URL}/${model.id}/generate-hyperespectral-model`,
      model
    );
  }

  sendToGenerateExternal(model: Model): Observable<Model> {
    return this.restClientService.post(
      `${this.URL}/${model.id}/generate-model-external`,
      model
    );
  }

  sendToIotaServer(model: Model): Observable<Model> {
    return this.restClientService.post(
      `${this.URL}/${model.id}/send-iota-server`,
      model
    );
  }

  getPointCloud(modelCode: string): Observable<PointCloud> {
    return this.restClientService.get(`${this.URL}/${modelCode}/cloud-points`);
  }

  saveThumbnail(model: Model, img: string) {
    if (model !== undefined && img !== undefined) {
      const data = {
        img,
      };
      return this.restClientService.post(
        `${this.URL}/${model.id}/upload-thumbnail`,
        data
      );
    }
  }

  getSourcesName(modelId: number): Observable<any[]> {
    return this.restClientService.get(`${this.URL}/${modelId}/files-name`);
  }

  downloadModels(modelIds: string): Observable<ArrayBuffer> {
    return;
  }

  downloadFiles(modelId: number): Observable<ArrayBuffer> {
    return this.restClientService.getArrayBuffer(
      `${this.URL}/${modelId}/download-files`
    );
  }

  existArvrFile(modelId: number): Observable<boolean> {
    return this.restClientService.get(`${this.URL}/${modelId}/exist-arvr-file`);
  }

  downloadArvrFile(modelId: number): Observable<Blob> {
    return this.restClientService.getBlob(
      `${this.URL}/${modelId}/download-arvr-file`
    );
  }

  markUnmarkAsFavorite(data: UserModel): Observable<UserModel> {
    return this.restClientService.post(
      `${this.URL}/${data.model_id}/mark-unmark-as-favorite`,
      data
    );
  }

  /*existSfm(model: Model): Observable<ExistSfm> {
    return this.restClientService.get(`${this.URL}/exist-sfm/${model.id}`);
  }*/

  getPhotoReadjustmentByName(
    modelId: number,
    filename: string
  ): Observable<Blob> {
    return this.restClientService.getBlob(
      `${this.URL}/${modelId}/photo-readjustment-file/${filename}`
    );
  }

  externalValidation(model: Model): Observable<ExternalInfo> {
    return this.restClientService.get(
      `${this.URL}/external-validate/${model.id}`
    );
  }

  createBlkFolder(model: Model): Observable<any> {
    return this.restClientService.post(
      `${this.URL}/${model.id}/create-blk-folder`,
      null
    );
  }

  deleteBlkFolder(model: Model): Observable<any> {
    return this.restClientService.delete(
      `${this.URL}/${model.id}/delete-blk-folder`
    );
  }

  getIotaInfo(model: Model): Observable<Model> {
    return this.restClientService.get(
      `${this.URL}/${model.id}/iota-info`,
      model
    );
  }

  checkIotaInformation(model: Model): Observable<any> {
    return this.restClientService.get(
      `${this.URL}/${model.id}/check-iota-information`
    );
  }

  getIotaProcessDownloadInfo(model: Model, createdAt: Date): Observable<Model> {
    return this.restClientService.get(
      `${this.URL}/${model.id}/iota-info-download/${createdAt}`
    );
  }
  getIotaModelDownloadInfo(model: Model): Observable<any> {
    return this.restClientService.get(
      `${this.URL}/${model.id}/model-download-iota-info`,
      null
    );
  }

  downloadModelSTP(model: Model, extension: string) {
    return this.restClientService.post(
      `/viewer/mesh/${model.id}/download-stp/${extension}`,
      model
    );
  }

  downloadConverterZip(model: Model, extension: any) {
    return this.restClientService.getBlob(
      `/viewer/mesh/${model.id}/download-stp/${extension}`
    );
  }

  public getConversionProgress(
    converterId: number
  ): Observable<ModelConverter> {
    return this.restClientService.get(
      `/viewer/point-cloud/${converterId}/model-converter`,
      null
    );
  }

  saveModelJoinRequest(modelId: number) {
    return this.restClientService.get(
      `/model-save-tool/${modelId}/send-request-email`
    );
  }

  saveModelJoin(request: any) {
    return this.restClientService.post(`/model-save-tool`, request);
  }

  approveModelJoinRequest(token: string) {
    return this.restClientService.put(
      `/model-save-tool/${token}/approve`,
      null
    );
  }

  denyModelJoinRequest(token: string) {
    return this.restClientService.put(`/model-save-tool/${token}/deny`, null);
  }

  public createSourceDownload(
    modelId: number
  ): Observable<ModelSourceDownload> {
    return this.restClientService.post(
      `${this.URL}/${modelId}/create-source-download`,
      null
    );
  }

  public checkSourceDownload(
    sourceDownloadId: number
  ): Observable<ModelSourceDownload> {
    return this.restClientService.get(
      `${this.URL}/${sourceDownloadId}/check-source-download`
    );
  }

  public getJoinRequests(modelId: number) {
    return this.restClientService.get(`model-save-tool/${modelId}`);
  }
}
