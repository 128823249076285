import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [],
  exports: [RouterModule],
  providers: [],
})
export class CoreModule {}
