export const VIEWER_I18N_ENTRIES = {
  continueMessage: {
    es: '<p>Continuar</p>',
    en: '<p>Continue</p>',
    pt: '<p>Continuar</p>',
    fr: '<p>Continuer</p>',
    eus: '<p>Jarraitu</p>',
    cat: '<p>Continua</p>',
  },

  meshTitle: {
    es: '<p>Malla</p>',
    en: '<p>Mesh</p>',
    pt: '<p>Malha</p>',
    fr: '<p>Maille</p>',
    eus: '<p>Sarea</p>',
    cat: '<p>Malla</p>',
  },

  pointCloudTitle: {
    es: '<p>Nube de Puntos</p>',
    en: '<p>Point Cloud</p>',
    pt: '<p>Nuvem de pontos</p>',
    fr: '<p>Nuage de points</p>',
    eus: '<p>Puntu hodeia</p>',
    cat: '<p>Núvol de punts</p>',
  },

  activateHeight: {
    es: '<p>activar altura</p>',
    en: '<p>activate height</p>',
    pt: '<p>ativar altura</p>',
    fr: '<p>activer hauteur</p>',
    eus: '<p>aktibatu altuera</p>',
    cat: '<p>activa l\'alçària</p>',
  },

  activateDistance: {
    es: '<p>activar medida</p>',
    en: '<p>activate distance</p>',
    pt: '<p>ativar medida</p>',
    fr: '<p>activer mesure</p>',
    eus: '<p>aktibatu neurria</p>',
    cat: '<p>activa mida</p>',
  },

  toolbar_point_to_point: {
    es: '<p>Punto a Punto (P2P)</p>',
    en: '<p>Point to Point (P2P)</p>',
    pt: '<p>Ponto a Ponto (P2P)</p>',
    fr: '<p>Point à Point (P2P)</p>',
    eus: '<p>Puntutik puntura (P2P)</p>',
    cat: '<p>Punt a punt (P2P)</p>',
  },

  point_to_point_panel_steps: {
    es: '<p>Seleccione un máximo de dos imágenes para calcular la longitud.</p>',
    en: '<p>Select a maximum of two images to calculate the length.</p>',
    pt: '<p>Selecione um máximo de duas imagens para calcular o comprimento.</p>',
    fr: '<p>Sélectionnez un maximum de deux images pour calculer la longueur.</p>',
    eus: '<p>Aukeratu, gehienez, bi irudi luzera kalkulatzeko.</p>',
    cat: '<p>Selecciona un màxim de dues imatges per calcular la llargària.</p>',
  },

  selected_photos: {
    es: '<p>Fotos seleccionadas</p>',
    en: '<p>Selected photos</p>',
    pt: '<p>Fotografias selecionadas</p>',
    fr: '<p>Photos sélectionnées</p>',
    eus: '<p>Aukeratutako irudiak</p>',
    cat: '<p>Fotos seleccionades</p>',
  },

  toolGroupProcessingImages: {
    es: '<p>Punto a Punto (P2P)</p>',
    en: '<p>Point to Point (P2P)</p>',
    pt: '<p>Ponto a Ponto (P2P)</p>',
    fr: '<p>Point à Point (P2P)</p>',
    eus: '<p>Puntutik puntura (P2P)</p>',
    cat: '<p>Punt a punt (P2P)</p>',
  },

  point_to_point_execute_back_text: {
    es: '<p>Volver a selección de imágenes</p>',
    en: '<p>Back to image selection</p>',
    pt: '<p>Regressar à seleção de imagens</p>',
    fr: '<p>Retour à la sélection d\'images</p>',
    eus: '<p>Itzuli irudiak hautatzera</p>',
    cat: '<p>Torna a selecció d’imatges</p>',
  },

  heightCalculation: {
    es: '<p>Cálculo de altura</p>',
    en: '<p>Height calculation</p>',
    pt: '<p>Cálculo de altura</p>',
    fr: '<p>Calcul de hauteur</p>',
    eus: '<p>Altueraren kalkulua</p>',
    cat: '<p>Càlcul de l’alçària</p>',
  },

  p2pAccuracyText: {
    es: '<p>Grado de precisión</p>',
    en: '<p>Degree of accuracy</p>',
    pt: '<p>Grau de precisão</p>',
    fr: '<p>Degré de précision</p>',
    eus: '<p>Xehetasun maila</p>',
    cat: '<p>Grau de precisió</p>',
  },

  addNewHeight: {
    es: '<p>Añadir nueva altura</p>',
    en: '<p>Add new height</p>',
    pt: '<p>Adicionar nova altura</p>',
    fr: '<p>Ajouter nouvelle hauteur</p>',
    eus: '<p>Gehitu altuera berria</p>',
    cat: '<p>Afegeix nova alçària</p>',
  },

  toolbar_scale: {
    es: '<p>Escala</p>',
    en: '<p>Scale</p>',
    pt: '<p>Escala</p>',
    fr: '<p>Échelle</p>',
    eus: '<p>Eskala</p>',
    cat: '<p>Escala</p>',
  },

  record_video: {
    es: '<p>REC</p>',
    en: '<p>REC</p>',
    pt: '<p>REC</p>',
    fr: '<p>REC</p>',
    eus: '<p>REC</p>',
    cat: '<p>REC</p>',
  },

  recording_video: {
    es: '<p>Detener grabación</p>',
    en: '<p>Stop recording</p>',
    pt: '<p>Deter gravação</p>',
    fr: '<p>Arrêter l\'enregistrement</p>',
    eus: '<p>Eten grabaketa</p>',
    cat: '<p>Atura l\'enregistrament</p>',
  },

  filter_image_button: {
    es: '<p>Filtros</p>',
    en: '<p>Filters</p>',
    pt: '<p>Filtros</p>',
    fr: '<p>Filtres</p>',
    eus: '<p>Filtroak</p>',
    cat: '<p>Filtres</p>',
  },

  loading: {
    es: '<p>Cargando</p>',
    en: '<p>Loading</p>',
    pt: '<p>A carregar</p>',
    fr: '<p>Chargement</p>',
    eus: '<p>Kargatzen</p>',
    cat: '<p>S\'està carregant</p>',
  },

  scaleModelManually: {
    es: '<p>Escalar Modelo Manualmente</p>',
    en: '<p>Scale Model Manually</p>',
    pt: '<p>Dimensionar modelo manualmente</p>',
    fr: '<p>Mise à l\'échelle manuelle du modèle</p>',
    eus: '<p>Eskuz eskalatu eredua</p>',
    cat: '<p>Ajusta l\'escala del model manualment</p>',
  },

  scaleModel: {
    es: '<p>Escala del modelo:</p>',
    en: '<p>Model scale:</p>',
    pt: '<p>Escala do modelo:</p>',
    fr: '<p>Échelle du modèle :</p>',
    eus: '<p>Ereduaren eskala</p>',
    cat: '<p>Escala del model:</p>',
  },

  toolbar_p2p: {
    es: '<p>Punto a Punto</p>',
    en: '<p>Point to Point</p>',
    pt: '<p>Ponto a Ponto</p>',
    fr: '<p>Point à Point</p>',
    eus: '<p>Puntutik puntura</p>',
    cat: '<p>Punt a punt</p>',
  },

  toolbar_photoreadjustment: {
    es: '<p>Fotorrectificación</p>',
    en: '<p>Image rectification</p>',
    pt: '<p>Fotorretificação</p>',
    fr: '<p>Rectification d\'image</p>',
    eus: '<p>Irudi zuzenketa</p>',
    cat: '<p>Fotorectificació</p>',
  },

  toolbar_photoreadjustmen_gallery: {
    es: '<p>Galería Fotorrectificación</p>',
    en: '<p>Image Rectification Gallery</p>',
    pt: '<p>Galeria Fotorretificação</p>',
    fr: '<p>Galerie de rectification d\'image</p>',
    eus: '<p>Irudi zuzenketaren galeria</p>',
    cat: '<p>Galeria Fotorectificació</p>',
  },

  scale_panel_title: {
    es: '<p>Cambiar escala</p>',
    en: '<p>Change scale</p>',
    pt: '<p>Mudar de escala</p>',
    fr: '<p>Changer échelle</p>',
    eus: '<p>Aldatu eskala</p>',
    cat: '<p>Canvia l’escala</p>',
  },

  toolbar_modify: {
    es: '<p>Modificar</p>',
    en: '<p>Modify</p>',
    pt: '<p>Modificar</p>',
    fr: '<p>Modifier</p>',
    eus: '<p>Aldatu</p>',
    cat: '<p>Modifica</p>',
  },

  toolbar_view: {
    es: '<p>Vista</p>',
    en: '<p>View</p>',
    pt: '<p>Vista</p>',
    fr: '<p>Vue</p>',
    eus: '<p>Ikuspegia</p>',
    cat: '<p>Vista</p>',
  },

  toolbar_movement: {
    es: '<p>Movimiento</p>',
    en: '<p>Movement</p>',
    pt: '<p>Movimento</p>',
    fr: '<p>Mouvement</p>',
    eus: '<p>Mugimendua</p>',
    cat: '<p>Moviment</p>',
  },

  toolbar_virtual_tour: {
    es: '<p>Tour Virtual</p>',
    en: '<p>Virtual Tour</p>',
    pt: '<p>Tour Virtual</p>',
    fr: '<p>Visite virtuelle</p>',
    eus: '<p>Txango birtuala</p>',
    cat: '<p>Visita virtual</p>',
  },

  toolbar_iota: {
    es: '<p>IOTA</p>',
    en: '<p>IOTA</p>',
    pt: '<p>IOTA</p>',
    fr: '<p>IOTA</p>',
    eus: '<p>IOTA</p>',
    cat: '<p>IOTA</p>',
  },

  iota_process_image: {
    es: '<p>Procesos realizados</p>',
    en: '<p>Processes completed</p>',
    pt: '<p>Processamentos efetuados</p>',
    fr: '<p>Processus réalisés</p>',
    eus: '<p>Burututako prozesuak</p>',
    cat: '<p>Processos efectuats</p>',
  },

  iota_panel_download: {
    es: '<p>Descarga realizada</p>',
    en: '<p>Download completed</p>',
    pt: '<p>Transferência efetuada</p>',
    fr: '<p>Téléch. effectué</p>',
    eus: '<p>Osatutako deskarga</p>',
    cat: '<p>Baixada efectuada</p>',
  },

  toolbar_image_processing: {
    es: '<p>Tratamiento de Imágenes</p>',
    en: '<p>Image processing</p>',
    pt: '<p>Tratamento de imagens</p>',
    fr: '<p>Traitement des images</p>',
    eus: '<p>Irudien tratamendua</p>',
    cat: '<p>Tractament d’imatges</p>',
  },

  language_name: {
    es: '<p>Idioma</p>',
    en: '<p>Language</p>',
    pt: '<p>Idioma</p>',
    fr: '<p>Langue</p>',
    eus: '<p>Hizkuntza</p>',
    cat: '<p>Idioma</p>',
  },

  toolbar_rotation_center: {
    es: '<p>Centro de Rotación</p>',
    en: '<p>Center of Rotation</p>',
    pt: '<p>Centro de rotação</p>',
    fr: '<p>Centre de Rotation</p>',
    eus: '<p>Errotazio zentroa</p>',
    cat: '<p>Centre de rotació</p>',
  },

  reset_panel_name: {
    es: '<p>Restablecer</p>',
    en: '<p>Reset</p>',
    pt: '<p>Repor</p>',
    fr: '<p>Rétablir</p>',
    eus: '<p>Berrezarri</p>',
    cat: '<p>Restableix</p>',
  },

  change_color_name: {
    es: '<p>Color de fondo</p>',
    en: '<p>Background color</p>',
    pt: '<p>Cor de fundo</p>',
    fr: '<p>Couleur d\'arrière-plan</p>',
    eus: '<p>Hondoko kolorea</p>',
    cat: '<p>Color de fons</p>',
  },

  social_share_name: {
    es: '<p>Compartir</p>',
    en: '<p>Share</p>',
    pt: '<p>Partilhar</p>',
    fr: '<p>Partager</p>',
    eus: '<p>Partekatu</p>',
    cat: '<p>Comparteix</p>',
  },

  full_screen: {
    es: '<p>Pantalla completa</p>',
    en: '<p>Full screen</p>',
    pt: '<p>Ecrã completo</p>',
    fr: '<p>Plein écran</p>',
    eus: '<p>Pantaila osoa</p>',
    cat: '<p>Pantalla completa</p>',
  },

  go_back_main_menu: {
    es: '<p>Volver al menú</p>',
    en: '<p>Back to menu</p>',
    pt: '<p>Regressar ao menu</p>',
    fr: '<p>Retour au menu</p>',
    eus: '<p>Itzuli menura</p>',
    cat: '<p>Torna al menú</p>',
  },

  go_back_to_gallery: {
    es: '<p>Volver a la galería</p>',
    en: '<p>Back to gallery</p>',
    pt: '<p>Regressar à galeria</p>',
    fr: '<p>Retour à la galerie</p>',
    eus: '<p>Itzuli galeriara</p>',
    cat: '<p>Torna a la galeria</p>',
  },

  moveonAxes: {
    es: '<p>Mover en ejes</p>',
    en: '<p>Move by axis</p>',
    pt: '<p>Mover em eixos</p>',
    fr: '<p>Déplacement sur les axes</p>',
    eus: '<p>Mugitu ardatzetan</p>',
    cat: '<p>Desplaça en eixos</p>',
  },

  orbitalMovement: {
    es: '<p>Movimiento orbital</p>',
    en: '<p>Orbital movement</p>',
    pt: '<p>Movimento orbital</p>',
    fr: '<p>Mouvement orbital</p>',
    eus: '<p>Mugimendu orbitala</p>',
    cat: '<p>Moviment orbital</p>',
  },

  autofocus: {
    es: '<p>Autofocus</p>',
    en: '<p>Autofocus</p>',
    pt: '<p>Autofocus</p>',
    fr: '<p>Autofocus</p>',
    eus: '<p>Fokatze automatikoa</p>',
    cat: '<p>Enfocament automàtic</p>',
  },

  showFloor: {
    es: '<p>Mostrar suelo</p>',
    en: '<p>Show ground</p>',
    pt: '<p>Mostrar chão</p>',
    fr: '<p>Montrer le sol</p>',
    eus: '<p>Erakutsi lurzorua</p>',
    cat: '<p>Mostra el sòl</p>',
  },

  showAxesReference: {
    es: '<p>Mostrar ejes y centro de referencia</p>',
    en: '<p>Show axes and reference center</p>',
    pt: '<p>Mostrar eixos e centro de referência</p>',
    fr: '<p>Afficher les axes et le centre de référence</p>',
    eus: '<p>Erakutsi ardatzak eta erreferentzia zentroa</p>',
    cat: '<p>Mostra els eixos i el centre de referència</p>',
  },

  zoom: {
    es: '<p>Zoom</p>',
    en: '<p>Zoom</p>',
    pt: '<p>Zoom</p>',
    fr: '<p>Zoom</p>',
    eus: '<p>Zooma</p>',
    cat: '<p>Zoom</p>',
  },

  multilayer_name: {
    es: '<p>Multicapa</p>',
    en: '<p>Multilayer</p>',
    pt: '<p>Multicamada</p>',
    fr: '<p>Multicouches</p>',
    eus: '<p>Multigeruza</p>',
    cat: '<p>Multicapa</p>',
  },

  editormodel3d_name: {
    es: '<p>Editor modelo 3D</p>',
    en: '<p>3D model editor</p>',
    pt: '<p>Editor modelo 3D</p>',
    fr: '<p>Éditeur modèle 3D</p>',
    eus: '<p>3D ereduaren editorea</p>',
    cat: '<p>Editor model 3D</p>',
  },

  brushes_name: {
    es: '<p>Pinceles</p>',
    en: '<p>Brushes</p>',
    pt: '<p>Pincéis</p>',
    fr: '<p>Pinceaux</p>',
    eus: '<p>Pintzelak</p>',
    cat: '<p>Pinzells</p>',
  },

  standard_name: {
    es: '<p>Standard</p>',
    en: '<p>Standard</p>',
    pt: '<p>Padrão</p>',
    fr: '<p>Standard</p>',
    eus: '<p>Arrunta</p>',
    cat: '<p>Estàndard</p>',
  },

  smooth_name: {
    es: '<p>Smooth</p>',
    en: '<p>Smooth</p>',
    pt: '<p>Smooth</p>',
    fr: '<p>Assouplir</p>',
    eus: '<p>Leundu</p>',
    cat: '<p>Suavitza</p>',
  },

  flatten_name: {
    es: '<p>Flatten</p>',
    en: '<p>Flatten</p>',
    pt: '<p>Flatten</p>',
    fr: '<p>Aplatir</p>',
    eus: '<p>Lautu</p>',
    cat: '<p>Simplifica</p>',
  },

  inflate_name: {
    es: '<p>Inflate</p>',
    en: '<p>Inflate</p>',
    pt: '<p>Inflate</p>',
    fr: '<p>Gonfler</p>',
    eus: '<p>Puztu</p>',
    cat: '<p>Infla</p>',
  },

  crease_name: {
    es: '<p>Crease</p>',
    en: '<p>Crease</p>',
    pt: '<p>Crease</p>',
    fr: '<p>Froisser</p>',
    eus: '<p>Tolestu</p>',
    cat: '<p>Plega</p>',
  },

  pinch_name: {
    es: '<p>Pinch</p>',
    en: '<p>Pinch</p>',
    pt: '<p>Pinch</p>',
    fr: '<p>Pincer</p>',
    eus: '<p>Atximurkatu</p>',
    cat: '<p>Pessiga</p>',
  },

  move_name: {
    es: '<p>Movimiento</p>',
    en: '<p>Movement</p>',
    pt: '<p>Movimento</p>',
    fr: '<p>Mouvement</p>',
    eus: '<p>Mugimendua</p>',
    cat: '<p>Moviment</p>',
  },

  drag_name: {
    es: '<p>Drag</p>',
    en: '<p>Drag</p>',
    pt: '<p>Drag</p>',
    fr: '<p>Tirer</p>',
    eus: '<p>Arrastatu</p>',
    cat: '<p>Arrossega</p>',
  },

  paint_name: {
    es: '<p>Pintar</p>',
    en: '<p>Paint</p>',
    pt: '<p>Pintar</p>',
    fr: '<p>Dessiner</p>',
    eus: '<p>Margotu</p>',
    cat: '<p>Pinta</p>',
  },

  symmetry_editmodel: {
    es: '<p>Simetría</p>',
    en: '<p>Symmetry</p>',
    pt: '<p>Simetria</p>',
    fr: '<p>Symétrie</p>',
    eus: '<p>Simetria</p>',
    cat: '<p>Simetria</p>',
  },

  continuous_editmodel: {
    es: '<p>Contínuo</p>',
    en: '<p>Continuous</p>',
    pt: '<p>Contínuo</p>',
    fr: '<p>Continu</p>',
    eus: '<p>Jarraitua</p>',
    cat: '<p>Continu</p>',
  },

  photo_readjustment_name: {
    es: '<p>Fotorrectificación</p>',
    en: '<p>Image rectification</p>',
    pt: '<p>Fotorretificação</p>',
    fr: '<p>Rectification d\'image</p>',
    eus: '<p>Irudi zuzenketa</p>',
    cat: '<p>Fotorectificació</p>',
  },

  toolbar_height: {
    es: '<p>Altura</p>',
    en: '<p>Height</p>',
    pt: '<p>Altura</p>',
    fr: '<p>Hauteur</p>',
    eus: '<p>Altuera</p>',
    cat: '<p>Alçària</p>',
  },

  toolbar_area: {
    es: '<p>Área</p>',
    en: '<p>Area</p>',
    pt: '<p>Área</p>',
    fr: '<p>Aire</p>',
    eus: '<p>Azalera</p>',
    cat: '<p>Àrea</p>',
  },

  toolbar_volume: {
    es: '<p>Volumen</p>',
    en: '<p>Volume</p>',
    pt: '<p>Volume</p>',
    fr: '<p>Volume</p>',
    eus: '<p>Bolumena</p>',
    cat: '<p>Volum</p>',
  },

  help: {
    es: '<p>Ayuda</p>',
    en: '<p>Help</p>',
    pt: '<p>Ajuda</p>',
    fr: '<p>Assistance</p>',
    eus: '<p>Laguntza</p>',
    cat: '<p>Ajuda</p>',
  },

  tools_panel_title: {
    es: '<p>Herramientas</p>',
    en: '<p>Tools</p>',
    pt: '<p>Ferramentas</p>',
    fr: '<p>Outils</p>',
    eus: '<p>Tresnak</p>',
    cat: '<p>Eines</p>',
  },

  toolbar_coordinate: {
    es: '<p class="i18n-long-title">Coordenada</p>',
    en: '<p class="i18n-long-title">Coordinates</p>',
    pt: '<p class="i18n-long-title">Coordenada</p>',
    fr: '<p class="i18n-long-title">Coordonnée</p>',
    eus: '<p class="i18n-long-title">Koordenatua</p>',
    cat: '<p class="i18n-long-title">Coordenada</p>',
  },

  toolbar_reference_system: {
    es: '<p>Sistema</p><p>de Referencia</p>',
    en: '<p>Reference</p><p>System</p>',
    pt: '<p>Sistema</p><p>de referência</p>',
    fr: '<p>Système</p><p>de référence</p>',
    eus: '<p>Erreferentzia</p><p>Sistema</p>',
    cat: '<p>Sistema</p><p>de referència</p>',
  },

  reference_system_panel_steps: {
    es: '<p>Haz click sobre el modelo 3D para determinar los 3 puntos e inserta los datos de sus nuevas coordenadas.</p>',
    en: '<p>Click on the 3D model to determine the 3 points and insert the new coordinates.</p>',
    pt: '<p>Clique no modelo 3D para determinar os 3 pontos e insira os dados das suas novas coordenadas.</p>',
    fr: '<p>Cliquez sur le modèle 3D pour déterminer les 3 points et les informations de vos nouvelles coordonnées.</p>',
    eus: '<p>Egin klik 3D ereduan, 3 puntuak zehazteko, eta sartu horren koordenatu berrien datuak.</p>',
    cat: '<p>Fes clic sobre el model 3D per determinar els 3 punts i insereix les dades de les noves coordenades.</p>',
  },

  reference_system_panel_steps_warning: {
    es: '<p>Necesita al menos 3 puntos para aplicar la transformación.</p>',
    en: '<p>You need at least 3 points to apply the transformation.</p>',
    pt: '<p>Precisa de pelo menos 3 pontos para aplicar a transformação.</p>',
    fr: '<p>Vous avez besoin d’un minimum de 3 points pour appliquer la transformation.</p>',
    eus: '<p>Transformazioa egiteko 3 puntu behar dituzu gutxienez.</p>',
    cat: '<p>Calen com a mínim 3 punts per aplicar la transformació.</p>',
  },

  reference_system_panel_apply_changes_button: {
    es: '<p>APLICAR CAMBIOS</p>',
    en: '<p>APPLY CHANGES</p>',
    pt: '<p>APLICAR ALTERAÇÕES</p>',
    fr: '<p>APPLIQUER LES MODIFICATIONS</p>',
    eus: '<p>APLIKATU ALDAKETAK</p>',
    cat: '<p>APLICA ELS CANVIS</p>',
  },

  georeference_system_utm_select_tittle: {
    es: '<p>Seleccionar coordenada UTM</p>',
    en: '<p>Select UTM coordinates</p>',
    pt: '<p>Selecionar coordenada UTM</p>',
    fr: '<p>Sélectionner coordonnées UTM</p>',
    eus: '<p>Hautatu UTM koordenatua</p>',
    cat: '<p>Selecciona coordenada UTM</p>',
  },

  georeference_system_change_point: {
    es: '<p>Cambiar Punto</p>',
    en: '<p>Change Point</p>',
    pt: '<p>Alterar Ponto</p>',
    fr: '<p>Changer point</p>',
    eus: '<p>Aldatu puntua</p>',
    cat: '<p>Canvia punt</p>',
  },

  reference_system_panel_add_point: {
    es: '<p>SELECCIONAR PUNTOS</p>',
    en: '<p>SELECT POINTS</p>',
    pt: '<p>SELECIONAR PONTOS</p>',
    fr: '<p>SÉLECTIONNER LES POINTS</p>',
    eus: '<p>HAUTATU PUNTUAK</p>',
    cat: '<p>SELECCIONA ELS PUNTS</p>',
  },

  reference_system_panel_adding_point: {
    es: '<p>SELECCIONANDO PUNTOS</p>',
    en: '<p>SELECTING POINTS</p>',
    pt: '<p>A SELECIONAR PONTOS</p>',
    fr: '<p>SÉLECTION DES POINTS</p>',
    eus: '<p>PUNTUAK HAUTATZEN</p>',
    cat: '<p>SELECCIONANT ELS PUNTS</p>',
  },

  reference_system_panel_cancel_steps: {
    es: '<p>Elimina todos los puntos y anula el cambio de sistema</p>',
    en: '<p>Delete all points and cancel the system change</p>',
    pt: '<p>Elimine todos os pontos e anule a alteração de sistema</p>',
    fr: '<p>Éliminez tous les points et annulez le changement de système</p>',
    eus: '<p>Ezabatu puntu guztiak eta deuseztatu sistema-aldaketa</p>',
    cat: '<p>Elimina tots els punts i anul·la el canvi de sistema</p>',
  },

  toolbar_georeferencing_system: {
    es: '<p>Sistema Georreferenciado</p>',
    en: '<p>Georeferencing System</p>',
    pt: '<p>Sistema de georreferenciação</p>',
    fr: '<p>Système de géoréférencement</p>',
    eus: '<p>Geoerreferentziazio sistema</p>',
    cat: '<p>Sistema de georeferenciació</p>',
  },

  toolbar_floor_plane: {
    es: '<p>Establecer suelo</p>',
    en: '<p>Establish ground</p>',
    pt: '<p>Estabelecer chão</p>',
    fr: '<p>Définir le sol</p>',
    eus: '<p>Ezarri lurzorua</p>',
    cat: '<p>Estableix el sòl</p>',
  },

  toolbar_notes: {
    es: '<p>Notas</p>',
    en: '<p>Notes</p>',
    pt: '<p>Notas</p>',
    fr: '<p>Notes</p>',
    eus: '<p>Oharrak</p>',
    cat: '<p>Notes</p>',
  },

  notes_panel_create_note: {
    es: '<p>CREAR NOTA</p>',
    en: '<p>CREATE NOTE</p>',
    pt: '<p>CRIAR NOTA</p>',
    fr: '<p>CRÉER UNE NOTE</p>',
    eus: '<p>SORTU OHARRA</p>',
    cat: '<p>CREA UNA NOTA</p>',
  },

  toolbar_clipping: {
    es: '<p>Recorte</p>',
    en: '<p>Crop</p>',
    pt: '<p>Recorte</p>',
    fr: '<p>Découpe</p>',
    eus: '<p>Ebakina</p>',
    cat: '<p>Retall</p>',
  },

  model_save_correct: {
    es: '<p>Modelo 3D guardado correctamente</p>',
    en: '<p>3D model saved correctly</p>',
    pt: '<p>Modelo 3D guardado corretamente</p>',
    fr: '<p>Modèle 3D sauvegardé correctement</p>',
    eus: '<p>3D eredua ondo gorde da</p>',
    cat: '<p>El model 3D s’ha desat correctament</p>',
  },

  toolbar_join_models: {
    es: '<p>Unir Modelos 3D</p>',
    en: '<p>Merge 3D Models</p>',
    pt: '<p>Unir modelos 3D</p>',
    fr: '<p>Unir modèles 3D</p>',
    eus: '<p>Bateratu 3D ereduak</p>',
    cat: '<p>Uneix models 3D</p>',
  },

  toolbar_match_models: {
    es: '<p>Matching 3D</p>',
    en: '<p>3D Matching</p>',
    pt: '<p>Matching 3D</p>',
    fr: '<p>Matching 3D</p>',
    eus: '<p>3D parekatzea</p>',
    cat: '<p>Matching 3D</p>',
  },

  overlap: {
    es: '<p>SUPERPOSICIÓN</p>',
    en: '<p>OVERLAY</p>',
    pt: '<p>SOBREPOSIÇÃO</p>',
    fr: '<p>SUPERPOSITION</p>',
    eus: '<p>GAINJARTZEA</p>',
    cat: '<p>SUPERPOSICIÓ</p>',
  },

  toolbar_selection: {
    es: '<p>Selección objetos 3D</p>',
    en: '<p>3D object selection</p>',
    pt: '<p>Seleção objetos 3D</p>',
    fr: '<p>Sélection d’objets 3D</p>',
    eus: '<p>3D objektuen hautaketa</p>',
    cat: '<p>Selecció d’objectes 3D</p>',
  },

  join_models_panel_steps: {
    es: '<p>Para unir los modelos 3D es necesario</p><p>seleccionar 2 modelos 3D.</p>',
    en: '<p>You must select 2 3D models</p><p>to merge them.</p>',
    pt: '<p>Para unir os modelos 3D é</p><p>selecionar 2 modelos 3D.</p>',
    fr: '<p>Afin d\'unir les modèles 3D, vous devez</p><p>sélectionner 2 modèles 3D.</p>',
    eus: '<p>3D ereduak bateratzeko,</p><p>2 3D eredu hautatu behar dituzu.</p>',
    cat: '<p>Per unir els models 3D</p><p>cal seleccionar 2 models 3D.</p>',
  },

  longitude: {
    es: '<p>Longitud:</p>',
    en: '<p>Longitude:</p>',
    pt: '<p>Comprimento:</p>',
    fr: '<p>Longitude :</p>',
    eus: '<p>Luzera:</p>',
    cat: '<p>Longitud:</p>',
  },

  geo_system: {
    es: '<p>*Sistema Geodésico Mundial 1984 (WGS 84)</p>',
    en: '<p>*1984 World Geodetic System (WGS 84)</p>',
    pt: '<p>*Sistema Geodésico Mundial 1984 (WGS 84)</p>',
    fr: '<p>*Système Géodésique Mondial 1984 (WGS 84)</p>',
    eus: '<p>*Munduko Sistema Geodesikoa 1984 (WGS 84)</p>',
    cat: '<p>*Sistema Geodèsic Mundial 1984 (WGS 84)</p>',
  },

  toolbar_projection: {
    es: '<p>Proyección</p>',
    en: '<p>Projection</p>',
    pt: '<p>Projeção</p>',
    fr: '<p>Projection</p>',
    eus: '<p>Proiekzioa</p>',
    cat: '<p>Projecció</p>',
  },

  toolbar_perspective_projection: {
    es: '<p>Proyección perspectiva</p>',
    en: '<p>Perspective projection</p>',
    pt: '<p>Projeção perspetiva</p>',
    fr: '<p>Projection perspective</p>',
    eus: '<p>Perspektiba proiekzioa</p>',
    cat: '<p>Projecció perspectiva</p>',
  },

  toolbar_ortographic: {
    es: '<p>Proyección ortográfica</p>',
    en: '<p>Orthographic projection</p>',
    pt: '<p>Projeção ortográfica</p>',
    fr: '<p>Projection orthographique</p>',
    eus: '<p>Proiekzio ortografikoa</p>',
    cat: '<p>Projecció ortogràfica</p>',
  },

  toolbar_editormodel_perspective: {
    es: '<p>P. Perspectiva</p>',
    en: '<p>Perspective P.</p>',
    pt: '<p>P. Perspetiva</p>',
    fr: '<p>P. Perspective</p>',
    eus: '<p>Perspektiba p.</p>',
    cat: '<p>P. perspectiva</p>',
  },

  toolbar_editormodel_ortograhpic: {
    es: '<p>P. Ortográfica</p>',
    en: '<p>Orthographic P.</p>',
    pt: '<p>P. Ortográfica</p>',
    fr: '<p>P. Orthographique</p>',
    eus: '<p>P. ortografikoa</p>',
    cat: '<p>P. ortogràfica</p>',
  },

  toolbar_section: {
    es: '<p>Sección</p>',
    en: '<p>Section</p>',
    pt: '<p>Secção</p>',
    fr: '<p>Section</p>',
    eus: '<p>Sekzioa</p>',
    cat: '<p>Secció</p>',
  },

  toolbar_elevation_map: {
    es: '<p>Mapa de Niveles</p>',
    en: '<p>Level Map</p>',
    pt: '<p>Mapa de níveis</p>',
    fr: '<p>Carte de niveaux</p>',
    eus: '<p>Maila mapa</p>',
    cat: '<p>Mapa de nivells</p>',
  },

  toolbar_planes: {
    es: '<p>Planos</p>',
    en: '<p>Planes</p>',
    pt: '<p>Planos</p>',
    fr: '<p>Plans</p>',
    eus: '<p>Planoak</p>',
    cat: '<p>Plans</p>',
  },

  elevation_map_panel_accept_button: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  toolbar_vertexs: {
    es: '<p>Vértices</p>',
    en: '<p>Vertices</p>',
    pt: '<p>Vértices</p>',
    fr: '<p>Sommets</p>',
    eus: '<p>Erpinak</p>',
    cat: '<p>Vèrtex</p>',
  },

  toolbar_vertexs_points: {
    es: '<p>Puntos</p>',
    en: '<p>Points</p>',
    pt: '<p>Pontos</p>',
    fr: '<p>Points</p>',
    eus: '<p>Puntuak</p>',
    cat: '<p>Punts</p>',
  },

  toolbar_segmentation: {
    es: '<p>Segmentación</p>',
    en: '<p>Segmentation</p>',
    pt: '<p>Segmentação</p>',
    fr: '<p>Segmentation</p>',
    eus: '<p>Segmentazioa</p>',
    cat: '<p>Segmentació</p>',
  },

  toolbar_segmentation_objects: {
    en: '<p class="i18n-long-title">3D Object Segmentation</p>',
    es: '<p class="i18n-long-title">Segmentación objetos 3D</p>',
    pt: '<p class="i18n-long-title">Segmentação objetos 3D</p>',
    fr: '<p class="i18n-long-title">Segmentation objets 3D</p>',
    eus: '<p class="i18n-long-title-basque">3D objektuen segmentazioa</p>',
    cat: '<p class="i18n-long-title">Segmentació objectes 3D</p>',
  },

  toolbar_establish_initial_position: {
    es: '<p class="i18n-long-title">Establecer posición inicial</p>',
    en: '<p class="i18n-long-title">Establish initial position</p>',
    pt: '<p class="i18n-long-title">Estabelecer posição inicial</p>',
    fr: '<p class="i18n-long-title">Définir position initiale</p>',
    eus: '<p class="i18n-long-title">Ezarri hasierako posizioa</p>',
    cat: '<p class="i18n-long-title">Estableix pos. inicial</p>',
  },

  toolGroupMeasure: {
    es: '<p>Medición</p>',
    en: '<p>Measurement</p>',
    pt: '<p>Medição</p>',
    fr: '<p>Mesure</p>',
    eus: '<p>Neurketa</p>',
    cat: '<p>Mesures</p>',
  },

  toolGroupScale: {
    es: '<p>Escala</p>',
    en: '<p>Scale</p>',
    pt: '<p>Escala</p>',
    fr: '<p>Échelle</p>',
    eus: '<p>Eskala</p>',
    cat: '<p>Escala</p>',
  },

  toolGroupModify: {
    es: '<p>Modificar</p>',
    en: '<p>Modify</p>',
    pt: '<p>Modificar</p>',
    fr: '<p>Modifier</p>',
    eus: '<p>Aldatu</p>',
    cat: '<p>Modifica</p>',
  },

  toolGroupMovement: {
    es: '<p>Movimiento</p>',
    en: '<p>Movement</p>',
    pt: '<p>Movimento</p>',
    fr: '<p>Mouvement</p>',
    eus: '<p>Mugimendua</p>',
    cat: '<p>Moviment</p>',
  },

  editormodelGroup: {
    es: '<p>Editor Modelo 3D</p>',
    en: '<p>3D Model Editor</p>',
    pt: '<p>Editor Modelo 3D</p>',
    fr: '<p>Éditeur modèle 3D</p>',
    eus: '<p>3D ereduaren editorea</p>',
    cat: '<p>Editor model 3D</p>',
  },

  toolGroupVisualization: {
    es: '<p>Proyección</p>',
    en: '<p>Projection</p>',
    pt: '<p>Projeção</p>',
    fr: '<p>Projection</p>',
    eus: '<p>Proiekzioa</p>',
    cat: '<p>Projecció</p>',
  },

  toolGroupDefault: {
    es: '<p>Herramientas</p>',
    en: '<p>Tools</p>',
    pt: '<p>Ferramentas</p>',
    fr: '<p>Outils</p>',
    eus: '<p>Tresnak</p>',
    cat: '<p>Eines</p>',
  },

  toolbar_measure: {
    es: '<p>Medición</p>',
    en: '<p>Measurement</p>',
    pt: '<p>Medição</p>',
    fr: '<p>Mesure</p>',
    eus: '<p>Neurketa</p>',
    cat: '<p>Mesures</p>',
  },

  login: {
    es: '<p>Iniciar sesión</p>',
    en: '<p>Log in</p>',
    pt: '<p>Iniciar sessão</p>',
    fr: '<p>Se connecter</p>',
    eus: '<p>Hasi saioa</p>',
    cat: '<p>Inicia sessió</p>',
  },

  download: {
    es: '<p>Descargar</p>',
    en: '<p>Download</p>',
    pt: '<p>Transferir</p>',
    fr: '<p>Télécharger</p>',
    eus: '<p>Deskargatu</p>',
    cat: '<p>Baixa</p>',
  },

  logout: {
    es: '<p>Cerrar sesión</p>',
    en: '<p>Log out</p>',
    pt: '<p>Terminar sessão</p>',
    fr: '<p>Se déconnecter</p>',
    eus: '<p>Itxi saioa</p>',
    cat: '<p>Tanca sessió</p>',
  },

  toolbar_photoreadjustment_items_history: {
    es: '<p>Historial de objetos</p>',
    en: '<p>Object list</p>',
    pt: '<p>Histórico de objetos</p>',
    fr: '<p>Historique d\'objets</p>',
    eus: '<p>Objektuen historia</p>',
    cat: '<p>Historial d’objectes</p>',
  },

  toolbar_surfaces: {
    es: '<p>Superficies</p>',
    en: '<p>Surfaces</p>',
    pt: '<p>Superfícies</p>',
    fr: '<p>Surfaces</p>',
    eus: '<p>Gainazalak</p>',
    cat: '<p>Superfícies</p>',
  },

  toolbar_multiple_measures: {
    es: '<p>M. Múltiple</p>',
    en: '<p>Multiple M.</p>',
    pt: '<p>M. Múltipla</p>',
    fr: '<p>M. Multiple</p>',
    eus: '<p>N. anizkoitza</p>',
    cat: '<p>M. múltiple</p>',
  },

  toolbar_multiple_tittle: {
    es: '<p>Medición</p>',
    en: '<p>Measurement</p>',
    pt: '<p>Medição</p>',
    fr: '<p>Mesure</p>',
    eus: '<p>Neurketa</p>',
    cat: '<p>Mesures</p>',
  },

  toolbar_incidences_tittle: {
    es: '<p>Incidencias</p>',
    en: '<p>Incidents</p>',
    pt: '<p>Incidências</p>',
    fr: '<p>Incidents</p>',
    eus: '<p>Arazoak</p>',
    cat: '<p>Incidències</p>',
  },

  toolbar_iota_title: {
    es: '<p>IOTA</p>',
    en: '<p>IOTA</p>',
    pt: '<p>IOTA</p>',
    fr: '<p>IOTA</p>',
    eus: '<p>IOTA</p>',
    cat: '<p>IOTA</p>',
  },

  filterPanel: {
    es: '<p>Filtros</p>',
    en: '<p>Filters</p>',
    pt: '<p>Filtros</p>',
    fr: '<p>Filtres</p>',
    eus: '<p>Filtroak</p>',
    cat: '<p>Filtres</p>',
  },

  loading_sensoric: {
    es: '<p>Buscando datos</p>',
    en: '<p>Searching for data</p>',
    pt: '<p>A procurar dados</p>',
    fr: '<p>Recherche de données</p>',
    eus: '<p>Datuak bilatzen</p>',
    cat: '<p>S\'estan cercant dades</p>',
  },

  no_sensoric: {
    es: '<p>Datos no encontrados para esta zona.</p>',
    en: '<p>Data not found for this area.</p>',
    pt: '<p>Dados não encontrados para esta zona.</p>',
    fr: '<p>Aucune donnée trouvée pour cette zone.</p>',
    eus: '<p>Ez da zonalde honi buruzko daturik aurkitu.</p>',
    cat: '<p>No s’han trobat dades per a aquesta zona.</p>',
  },

  point_not_found_title: {
    es: '<p>Seleccione otro punto</p>',
    en: '<p>Select another point</p>',
    pt: '<p>Selecione outro ponto</p>',
    fr: '<p>Choisissez un autre point</p>',
    eus: '<p>Hautatu beste puntu bat</p>',
    cat: '<p>Selecciona un altre punt</p>',
  },

  point_not_found_description: {
    es: '<p>El punto seleccionado no se ha encontrado</p>',
    en: '<p>The selected point has not been found</p>',
    pt: '<p>O ponto selecionado não foi encontrado</p>',
    fr: '<p>Le point sélectionné est introuvable</p>',
    eus: '<p>Hautatu duzun puntua ez da aurkitu</p>',
    cat: '<p>No s’ha trobat el punt seleccionat</p>',
  },

  point_not_found_button_text: {
    es: '<p>volver a seleccionar</p>',
    en: '<p>reselect</p>',
    pt: '<p>selecionar novamente</p>',
    fr: '<p>sélectionner à nouveau</p>',
    eus: '<p>berriz ere hautatu</p>',
    cat: '<p>torna a seleccionar</p>',
  },

  big: {
    es: '<p>Grande</p>',
    en: '<p>Large</p>',
    pt: '<p>Grande</p>',
    fr: '<p>Grand</p>',
    eus: '<p>Handia</p>',
    cat: '<p>Gran</p>',
  },

  small: {
    es: '<p>Pequeño</p>',
    en: '<p>Small</p>',
    pt: '<p>Pequeno</p>',
    fr: '<p>Petit</p>',
    eus: '<p>Txikia</p>',
    cat: '<p>Petit</p>',
  },

  medium: {
    es: '<p>Mediano</p>',
    en: '<p>Medium</p>',
    pt: '<p>Médio</p>',
    fr: '<p>Moyen</p>',
    eus: '<p>Ertaina</p>',
    cat: '<p>Mitjà</p>',
  },

  actionCompleted: {
    es: '<p>ACCIÓN REALIZADA</p>',
    en: '<p>ACTION COMPLETED</p>',
    pt: '<p>AÇÃO EFETUADA</p>',
    fr: '<p>ACTION EFFECTUÉE</p>',
    eus: '<p>EKINTZA BURUTU DA</p>',
    cat: '<p>ACCIÓ EFECTUADA</p>',
  },

  satisfactionQuestion: {
    es: '<p>¿Cómo te ha resultado la Alineación?</p>',
    en: '<p>How did the Alignment work out for you?</p>',
    pt: '<p>Qual a sua opinião sobre o Alinhamento?</p>',
    fr: '<p>Quel est le résultat de l\'alignement ?</p>',
    eus: '<p>Zer iruditu zaizu lerrokatzea?</p>',
    cat: '<p>Com ha estat l\'alineació?</p>',
  },

  satisfactionCorrect: {
    es: '<p>Correcta</p>',
    en: '<p>Successful</p>',
    pt: '<p>Correto</p>',
    fr: '<p>Correct</p>',
    eus: '<p>Zuzena</p>',
    cat: '<p>Correcta</p>',
  },

  satisfactionIncorrect: {
    es: '<p>Incorrecta</p>',
    en: '<p>Unsuccessful</p>',
    pt: '<p>Incorreto</p>',
    fr: '<p>Incorrect</p>',
    eus: '<p>Okerra</p>',
    cat: '<p>Incorrecta</p>',
  },

  newModelName: {
    es: '<p>Nombre nuevo modelo 3D:</p>',
    en: '<p>New 3D model name:</p>',
    pt: '<p>Nome do novo modelo 3D:</p>',
    fr: '<p>Nom nouveau modèle 3D :</p>',
    eus: '<p>3D eredu berriaren izena:</p>',
    cat: '<p>Nom del nou model 3D:</p>',
  },

  saveModelSelection: {
    es: '<p>GUARDAR MODELO 3D</p>',
    en: '<p>SAVE 3D MODEL</p>',
    pt: '<p>GUARDAR MODELO 3D</p>',
    fr: '<p>SAUVEGARDER MODÈLE 3D</p>',
    eus: '<p>GORDE 3D EREDUA</p>',
    cat: '<p>DESA EL MODEL 3D</p>',
  },

  goBacksaveModel: {
    es: '<p>[ < NO GUARDAR, VOLVER ATRÁS ]</p>',
    en: '<p>[ < DO NOT SAVE, GO BACK ]</p>',
    pt: '<p>[ < NÃO GUARDAR, REGRESSAR ]</p>',
    fr: '<p>[ < NE PAS SAUVEGARDER, REVENIR EN ARRIÈRE ]</p>',
    eus: '<p>[ < EZ GORDE, ATZERA EGIN ]</p>',
    cat: '<p>[ < NO DESIS, TORNA ENRERE ]</p>',
  },

  visores_PanelDerecho_Ayuda_data4: {
    es: '<p>ABRIR CHAT ONLINE</p>',
    en: '<p>OPEN ONLINE CHAT</p>',
    pt: '<p>ABRIR CHAT ONLINE</p>',
    fr: '<p>LANCER CHAT EN LIGNE</p>',
    eus: '<p>IREKI SAREKO TXATA</p>',
    cat: '<p>OBRE XAT EN LÍNIA</p>',
  },

  visores_PanelIzquierdo_Descarga_data1: {
    es: '<p>Para acceder a este formato, debes hacerlo desde</p><p>tu cuenta en la plataforma BIMServer, descargando</p><p>en ella eyesCloud3d.</p>',
    en: '<p>You can access this format by downloading</p><p>eyesCloud3d through your account</p><p>in the BIMServer platform.</p>',
    pt: '<p>Para aceder a este formato, deverá fazê-lo</p><p>a partir da sua conta na plataforma BIMServer,</p><p>transferindo a eyesCloud3d através da mesma.</p>',
    fr: '<p>Pour utiliser ce format, vous devez y accéder</p><p>depuis votre compte sur la plateforme</p><p>BIMServer, en y téléchargeant eyesCloud3d.</p>',
    eus: '<p>Formatu hori eskuratzeko, zure kontura sartu</p><p>behar zara BIMServer plataforman,</p><p>bertan eyesCloud3d deskargatuz.</p>',
    cat: '<p>Per accedir a aquest format, has de fer-ho</p><p>des del teu compte en la plataforma BIMServer,</p><p>baixant allà l’eyesCloud3d.</p>',
  },

  visores_PanelIzquierdo_Descarga_data2: {
    es: '<p>Este modelo 3D ha sido generado con anterioridad</p><p>a la implementación del formato AR/VR.</p>',
    en: '<p>This 3D model was generated prior to the</p><p>implementation of the AR/VR format.</p>',
    pt: '<p>Este modelo 3D foi criado anteriormente</p><p>à implementação do formato AR/VR.</p>',
    fr: '<p>Ce modèle 3D a été généré avant</p><p>l’implémentation du format RA/RV.</p>',
    eus: '<p>3D eredu hau EA/EB formatua</p><p>inplementatu aurretik sortu zen.</p>',
    cat: '<p>Aquest model 3D ha estat generat abans de</p><p>la implementació del format RA/ RV.</p>',
  },

  visores_PanelIzquierdo_Descarga_data3: {
    es: '<p>El modelo 3D no está georreferenciado.</p><p>La descarga GTIFF solo está disponible con</p><p>modelos 3D georreferenciados.</p>',
    en: '<p>The 3D model is not georeferenced.</p><p>Only georeferenced 3D models can be downloaded</p><p>in GTIFF format.</p>',
    pt: '<p>O modelo 3D não está georreferenciado.</p><p>A transferência GTIFF só está disponível com</p><p>modelos 3D georreferenciados.</p>',
    fr: '<p>Votre modèle 3D n’est pas géoréférencé.</p><p>Le téléchargement GTIFF n’est disponible que pour</p><p>les modèles 3D géoréférencés.</p>',
    eus: '<p>3D eredua ez dago geoerreferentziatuta.</p><p>GTIFF deskarga 3D eredu geoerreferentziatuekin</p><p>soilik dago erabilgarri.</p>',
    cat: '<p>El model 3D no està georeferenciat.</p><p>La baixada GTIFF només està disponible amb</p><p>models 3D georeferenciats.</p>',
  },

  visores_PanelIzquierdo_Descarga_data7: {
    es: '<p>Selecciona método de pago:</p>',
    en: '<p>Select payment method:</p>',
    pt: '<p>Selecione o método de pagamento:</p>',
    fr: '<p>Sélectionnez votre mode de paiement :</p>',
    eus: '<p>Hautatu ordainketa metodoa:</p>',
    cat: '<p>Selecciona el mètode de pagament:</p>',
  },

  visores_PanelIzquierdo_Descarga_data8: {
    es: '<p>CONTINUAR</p>',
    en: '<p>CONTINUE</p>',
    pt: '<p>CONTINUAR</p>',
    fr: '<p>CONTINUER</p>',
    eus: '<p>JARRAITU</p>',
    cat: '<p>CONTINUA</p>',
  },

  toolbar_georeferencing_system_title: {
    es: '<p>Sist. Georreferenciado</p>',
    en: '<p>Georeferencing System</p>',
    pt: '<p>Sist. de georreferenciação</p>',
    fr: '<p>Syst. de géoréférencement</p>',
    eus: '<p>Geoerreferentziazio sist.</p>',
    cat: '<p>Sist. de georeferenciació</p>',
  },

  addons_security: {
    es: '<p>Seguridad</p>',
    en: '<p>Safety</p>',
    pt: '<p>Segurança</p>',
    fr: '<p>Sécurité</p>',
    eus: '<p>Segurtasuna</p>',
    cat: '<p>Seguretat</p>',
  },

  addons_images: {
    es: '<p>Imágenes</p>',
    en: '<p>Images</p>',
    pt: '<p>Imagens</p>',
    fr: '<p>Images</p>',
    eus: '<p>Irudiak</p>',
    cat: '<p>Imatges</p>',
  },

  addons_security_energy: {
    es: '<p>Energía deformación</p>',
    en: '<p>Deformation energy</p>',
    pt: '<p>Energia deformação</p>',
    fr: '<p>Énergie de déformation</p>',
    eus: '<p>Deformazio energia</p>',
    cat: '<p>Energia deformació</p>',
  },

  addons_security_projection: {
    es: '<p>Proyección en 3D</p>',
    en: '<p>3D Projection</p>',
    pt: '<p>Projeção em 3D</p>',
    fr: '<p>Projection 3D</p>',
    eus: '<p>3D proiekzioa</p>',
    cat: '<p>Projecció en 3D</p>',
  },

  toolGroupAddonsSecurity: {
    es: '<p>Addons Seguridad</p>',
    en: '<p>Safety Addons</p>',
    pt: '<p>Addons Segurança</p>',
    fr: '<p>Addons Sécurité</p>',
    eus: '<p>Segurtasun Addon-ak</p>',
    cat: '<p>Addons Seguretat</p>',
  },

  toolGroupAddonsImages: {
    es: '<p>Addons Imágenes</p>',
    en: '<p>Image Addons</p>',
    pt: '<p>Addons Imagens</p>',
    fr: '<p>Addons Images</p>',
    eus: '<p>Irudi Addon-ak</p>',
    cat: '<p>Addons Imatges</p>',
  },

  toolGroupAddonsEngineering: {
    es: '<p>Addons Ingeniería</p>',
    en: '<p>Engineering Addons</p>',
    pt: '<p>Addons Engenharia</p>',
    fr: '<p>Addons Ingénierie</p>',
    eus: '<p>Ingeniaritza Addon-ak</p>',
    cat: '<p>Addons Enginyeria</p>',
  },

  addons_engineering: {
    en: '<p>Engineering</p>',
    es: '<p>Ingeniería</p>',
    pt: '<p>Engenharia</p>',
    fr: '<p>Ingénierie</p>',
    eus: '<p>Ingeniaritza</p>',
    cat: '<p>Enginyeria</p>',
  },

  toolGroupAddonsAgriculture: {
    es: '<p>Addons Agricultura</p>',
    en: '<p>Agriculture Addons</p>',
    pt: '<p>Addons Agricultura</p>',
    fr: '<p>Addons Agriculture</p>',
    eus: '<p>Nekazaritza Addon-ak</p>',
    cat: '<p>Addons Agricultura</p>',
  },

  multicam: {
    es: '<p>Multicam</p>',
    en: '<p>Multicam</p>',
    pt: '<p>Multicâmara</p>',
    fr: '<p>Multicam</p>',
    eus: '<p>Multikamera</p>',
    cat: '<p>Multicam</p>',
  },

  toolbar_contour: {
    es: '<p>Contornos</p>',
    en: '<p>Outlines</p>',
    pt: '<p>Contornos</p>',
    fr: '<p>Contours</p>',
    eus: '<p>Ingeradak</p>',
    cat: '<p>Contorns</p>',
  },

  toolbar_surface: {
    es: '<p>Superficie</p>',
    en: '<p>Surface</p>',
    pt: '<p>Superficie</p>',
    fr: '<p>Surface</p>',
    eus: '<p>Gainazala</p>',
    cat: '<p>Superficie</p>',
  },

  multiple_measures_header: {
    es: '<p>Medición Múltiple</p>',
    en: '<p>Multiple Measurements</p>',
    pt: '<p>Medição múltipla</p>',
    fr: '<p>Mesure multiple</p>',
    eus: '<p>Neurketa anizkoitza</p>',
    cat: '<p>Mesurament múltiple</p>',
  },

  toolGroupAddons: {
    es: '<p>Addons</p>',
    en: '<p>Addons</p>',
    pt: '<p>Addons</p>',
    fr: '<p>Addons</p>',
    eus: '<p>Addon-ak</p>',
    cat: '<p>Addons</p>',
  },

  toolbar_addons: {
    es: '<p>Addons</p>',
    en: '<p>Addons</p>',
    pt: '<p>Addons</p>',
    fr: '<p>Addons</p>',
    eus: '<p>Addon-ak</p>',
    cat: '<p>Addons</p>',
  },

  toolbar_z_axis: {
    es: '<p>Eje Z</p>',
    en: '<p>Z axis</p>',
    pt: '<p>Eixo Z</p>',
    fr: '<p>Axe Z</p>',
    eus: '<p>Z ardatza</p>',
    cat: '<p>Eix Z</p>',
  },

  calculo_vegetacion_button_data1: {
    es: '<p class="i18n-bold">Agricultura</p>',
    en: '<p class="i18n-bold">Agriculture</p>',
    pt: '<p class="i18n-bold">Agricultura</p>',
    fr: '<p class="i18n-bold">Agriculture</p>',
    eus: '<p class="i18n-bold">Nekazaritza</p>',
    cat: '<p class="i18n-bold">Agricultura</p>',
  },

  calculo_vegetacion_button_data2: {
    es: '<p>Cálculo</p><p>Vegetación</p>',
    en: '<p>Vegetation</p><p>Calculation</p>',
    pt: '<p>Cálculo</p><p>vegetação</p>',
    fr: '<p>Calcul de</p><p>la végétation</p>',
    eus: '<p>Landaretza</p><p>kalkulua</p>',
    cat: '<p>Càlcul</p><p>vegetació</p>',
  },

  toolbar_reduced_virtual_tour: {
    es: '<p>Tour Virtual Reducido</p>',
    en: '<p>Reduced Virtual Tour</p>',
    pt: '<p>Tour Virtual Reduzido</p>',
    fr: '<p>Visite virtuelle réduite</p>',
    eus: '<p>Txango birtual laburtua</p>',
    cat: '<p>Visita virtual reduïda</p>',
  },
};
