<div class="home-container" (window:resize)="onResize()">
  <div class="home-buttons-container">
    <div class="button new3d" routerLink="/new3d" id="Home_interaction-click-create">
      <img class="icon" src="../../../assets/images/main/home/make-model-ico.svg" alt="Nuevo modelo" id="Home_interaction-click-create">
      <p [innerHTML]="'home_Data1' | i18n" id="Home_interaction-click-create"></p>
      <!-- <p class="new-sub" [innerHTML]="'home_Data8' | i18n" id="Home_interaction-click-create"></p> -->
      <img class="path" src="../../../assets/images/main/home/path-down-ico.svg" id="Home_interaction-click-create">
    </div>
    <div class="button gallery" routerLink="/gallery" id="Home_interaction-click-gallery">
      <img class="icon" src="../../../assets/images/main/home/gallery-ico.svg" alt="Galería" id="Home_interaction-click-gallery">
      <p [innerHTML]="'home_Data2' | i18n" id="Home_interaction-click-gallery"></p>
      <!-- <p class="gallery-sub" [innerHTML]="'home_Data9' | i18n" id="Home_interaction-click-gallery"></p> -->
      <img class="path" src="../../../assets/images/main/home/path-down-ico.svg" id="Home_interaction-click-gallery">
    </div>
    <div class="button processing" (click)="goToProccessingModels()" id="Home_interaction-click-process">
      <p [innerHTML]="'home_Data3' | i18n" id="Home_interaction-click-process"></p>
      <img class="arrow" src="../../../assets/images/main/home/arrow.svg" id="Home_interaction-click-process">
    </div>
    <div class="button professional" routerLink="/profile/user-account-layout/user-rates" id="Home_interaction-click-rates">
      <p [innerHTML]="'home_Data5' | i18n" id="Home_interaction-click-rates"></p>
      <img class="arrow" src="../../../assets/images/main/home/arrow.svg" id="Home_interaction-click-rates">
    </div>
  </div>
  <div class="home-gallery-container">
    <div class="gallery-title" [innerHTML]="'home_Data10' | i18n"></div>
    <div class="home-slider-container">
      <div class="model-container" *ngFor="let model of modelsToShow" style="background-image: url({{model.preview_img}});" routerLink="/model/{{model.code}}">
        <div class="opacity-container"></div>
        <div class="category"><p [innerHTML]="categoryTxt(model?.categories[0]?.id)"></p></div>
        <div class="model-name" [innerHTML]="model.name"></div>
        <div class="separator"></div>
        <div class="model-user-container">
          <img *ngIf="model?.avatar_img" [src]="model.avatar_img">
          <div *ngIf="!model?.avatar_img" class="contente-avatar-initial-leter">
            <p class="avatrar-leter" >{{ model?.full_name?.initial_letter }}</p>
          </div>
          <div class="name-container">
            <p *ngIf="getFullName(model) !== ''" [innerHTML]="getFullName(model)"></p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="go-to-gallery-container" routerLink="/gallery">
      <div class="go-to-gallery" [innerHTML]="'home_Data7' | i18n"></div>
      <img src="../../../assets/images/main/home/circle-path-ico.svg" alt="Ir a galería">
    </div> -->
  </div>
</div>
