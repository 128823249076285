import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoaderTypeEnum } from '../models/loader/loader-type';
import { LoaderInfo } from '../models/loader/loader-info';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public static scope: LoaderService;
  private loading = new BehaviorSubject<LoaderInfo>({
    state: false,
    type: LoaderTypeEnum.NONE,
  });

  constructor() {
    LoaderService.scope = this;
  }

  public showLoader(loaderType: LoaderTypeEnum = LoaderTypeEnum.NONE): void {
    this.loading.next({ state: true, type: loaderType });
  }

  public hideLoader(): void {
    this.loading.next({ state: false, type: LoaderTypeEnum.NONE });
  }

  public isLoading(): Observable<LoaderInfo> {
    return this.loading;
  }
}
