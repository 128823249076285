/**
 * Enumerator to identify what panel layout to use depending on the current device.
 */
export enum ScreenDistribution
{
    NONE = 0,
    DESKTOP = 1,
    MOBILE = 2,
    TABLET = 3,
}
