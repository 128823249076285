import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ViewprojectStepsConfig } from 'src/app/commons/enum/first-experience-configs';
import { FirstExperienceViewprojectStepsEnum } from 'src/app/commons/enum/first-experience-enum';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
  selector: 'app-first-experience-viewproject-dialog',
  templateUrl: './first-experience-viewproject-dialog.component.html',
  styleUrls: ['./first-experience-viewproject-dialog.component.scss'],
})
export class FirstExperienceViewprojectDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  allSteps: typeof FirstExperienceViewprojectStepsEnum =
    FirstExperienceViewprojectStepsEnum;
  currentStep: FirstExperienceViewprojectStepsEnum =
    FirstExperienceViewprojectStepsEnum.FIRST;
  stepDescriptions = ViewprojectStepsConfig;

  /**
   * Tamaño del outline blanco al resaltar el elemento
   */
  outline: number;
  /**
   * Tamaño de la flecha del diálogo.
   */
  offSet: number;
  leftPosition: number;
  topPosition: number;
  initialDialogWidth: number;
  initialDialogHeight: number;

  buttonModelInfo: HTMLElement;
  buttonModelOptions: HTMLElement;
  buttonName: HTMLElement;

  initialX: number;
  initialY: number;
  finalX: number;
  finalY: number;
  halfWidthElement: number;
  halfHeightElement: number;

  constructor(
    public dialog: MatDialogRef<FirstExperienceViewprojectDialogComponent>,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.getInitialDialog();
  }

  ngOnDestroy(): void {
    this.removeAllClasses();
  }

  private removeAllClasses() {
    if (this.buttonModelInfo) {
      this.renderer.removeClass(this.buttonModelInfo, 'fe-mov-opt');
    }
    if (this.buttonModelOptions) {
      this.renderer.removeClass(this.buttonModelOptions, 'fe-mov-opt');
    }
    if (this.buttonName) {
      this.renderer.removeClass(this.buttonName, 'fe-mov-opt');
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.getInitialDialog();
  }

  close() {
    this.dialog.close();
  }

  getStepDescription(step: FirstExperienceViewprojectStepsEnum): string {
    const stepObject = this.stepDescriptions.find((obj) => obj.key === step);
    return stepObject ? stepObject.description : '';
  }

  nextStep() {
    if (this.currentStep < FirstExperienceViewprojectStepsEnum.LAST) {
      this.currentStep++;
      this.getInitialDialog();
    } else {
      this.dialog.close(FirstExperienceViewprojectStepsEnum.LAST);
    }
  }

  goToStep(stepKey: FirstExperienceViewprojectStepsEnum) {
    if (stepKey < this.currentStep) {
      this.currentStep = stepKey;
      this.getInitialDialog();
    }
  }

  getInitialDialog() {
    const initialDialog = document.querySelector(
      '.FirstExperienceViewprojectDialog'
    ) as HTMLElement;
    if (initialDialog) {
      setTimeout(() => {
        this.buttonModelInfo = document.querySelector('.model-data');
        this.buttonModelOptions = document.querySelector(
          '.model-buttons-container'
        );
        this.buttonName = document.querySelector('.mesh-point-selector');
        this.initialDialogWidth = initialDialog.offsetWidth;
        this.initialDialogHeight = initialDialog.offsetHeight;
        this.moveDialog();
      }, 1);
    }
  }

  moveDialog(): void {
    this.removeAllClasses();
    if (this.currentStep === FirstExperienceViewprojectStepsEnum.FIRST) {
      this.moveInitial();
    } else if (
      this.currentStep === FirstExperienceViewprojectStepsEnum.SECOND
    ) {
      this.moveToName();
    } else if (this.currentStep === FirstExperienceViewprojectStepsEnum.THIRD) {
      this.moveToModelInfo();
    } else {
      this.moveToModelOptions();
    }
  }

  moveInitial() {
    this.leftPosition = (window.innerWidth - this.initialDialogWidth) / 2;
    this.topPosition = (window.innerHeight - this.initialDialogHeight) / 2;
    this.dialog.updatePosition({
      left: `${this.leftPosition}px`,
      top: `${this.topPosition}px`,
    });
  }

  moveToName(): void {
    //TODO - Cambiar por las clases pertinentes una vez se cambie el diseño del viewproject

    if (this.buttonName) {
      this.renderer.addClass(this.buttonName, 'fe-mov-opt');
      this.getElementSize(this.buttonName);
      this.moveBottom();
      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToModelInfo(): void {
    //TODO - Cambiar por las clases pertinentes una vez se cambie el diseño del viewproject
    this.removeAllClasses();

    if (this.buttonModelInfo) {
      this.renderer.addClass(this.buttonModelInfo, 'fe-mov-opt');
      this.getElementSize(this.buttonModelInfo);
      if (this.responsiveService.firstExperience1024()) {
        this.moveRight();
      } else if (
        this.responsiveService.firstExperience830() &&
        !this.responsiveService.firstExperience834()
      ) {
        this.moveRight();
      } else {
        this.moveTop();
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToModelOptions(): void {
    //TODO - Cambiar por las clases pertinentes una vez se cambie el diseño del viewproject
    this.removeAllClasses();

    if (this.buttonModelOptions) {
      this.renderer.addClass(this.buttonModelOptions, 'fe-mov-opt');
      this.getElementSize(this.buttonModelOptions);

      if (this.responsiveService.firstExperience1024()) {
        this.moveLeft();
      } else if (
        this.responsiveService.firstExperience830() &&
        !this.responsiveService.firstExperience834()
      ) {
        this.moveLeft();
      } else {
        this.moveTop();
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  getElementSize(element: HTMLElement): void {
    this.initialX = element.getBoundingClientRect().left;
    this.initialY = element.getBoundingClientRect().top;
    this.finalX = this.initialX + element.offsetWidth;
    this.finalY = this.initialY + element.offsetHeight;
    this.halfWidthElement = (this.finalX - this.initialX) / 2;
    this.halfHeightElement = (this.finalY - this.initialY) / 2;
    if (this.responsiveService.firstExperience1536()) {
      this.outline = 20;
      this.offSet = 18;
    } else if (this.responsiveService.firstExperience1024()) {
      this.outline = 12;
      this.offSet = 12;
    } else if (this.responsiveService.firstExperience834()) {
      this.outline = 12;
      this.offSet = 20;
    } else if (this.responsiveService.firstExperience830()) {
      this.outline = 4;
      this.offSet = 13;
    } else if (this.responsiveService.firstExperience600()) {
      this.outline = 8;
      this.offSet = 17;
    } else if (this.responsiveService.firstExperience414()) {
      this.outline = 8;
      this.offSet = 13;
    } else {
      this.outline = 4;
      this.offSet = 13;
    }
  }

  moveBottom() {
    this.leftPosition =
      this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
    this.topPosition = this.finalY + this.outline + this.offSet;
  }

  moveTop() {
    this.leftPosition =
      this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
    this.topPosition =
      this.initialY - this.initialDialogHeight - this.outline - this.offSet;
  }

  moveRight() {
    this.leftPosition = this.finalX + this.outline + this.offSet;
    this.topPosition =
      this.finalY - this.halfHeightElement - this.initialDialogHeight / 2;
  }

  moveLeft() {
    this.leftPosition =
      this.initialX - this.outline - this.offSet - this.initialDialogWidth;
    this.topPosition =
      this.finalY - this.halfHeightElement - this.initialDialogHeight / 2;
  }
}
