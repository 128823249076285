<div class="nc-container" *ngIf="showCookies" (window:resize)="onResize()">
  <div class="close-container">
    <img class="close-icon" src="/assets/images/main/iota-cookies/close.svg" (click)="acceptCookies()">
  </div>
  <img class="cookie-icon" src="/assets/images/main/iota-cookies/logo.svg">
  <div class="cookie-text-container">
    <p class="protocol" [innerHTML]="!is1536 ? ('cookie_Dialog_Data1' | i18n) : ('cookie_Dialog_Data2' | i18n)"></p>
    <p class="policy" [innerHTML]="'cookie_Dialog_Data3' | i18n"></p>

  </div>
  <div class="cookie-btn" [innerHTML]="'cookie_Dialog_Data4' | i18n" (click)="acceptCookies()"></div>
</div>

