import { Injectable } from '@angular/core';
import {CountrySearchCriteria} from '../../../../models/country-search-criteria';
import {Country} from '../../../../models/country';
import {Retrievable} from '../../../../commons/models/retrievable';
import {Searchable} from '../../../../commons/models/searchable';
import {RestClientService} from '../../api-access/rest-client.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CountryRemoteService implements
Searchable<Country, CountrySearchCriteria>,
Retrievable<Country, number> {

  private URL = '/countries';

  constructor(private restClientService: RestClientService) { }

  public search(criteria?: CountrySearchCriteria): Observable<Country[]> {
    return this.restClientService.get(this.URL, criteria);
  }

  public get(id: number): Observable<Country> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }
}
