<app-loader></app-loader>
<router-outlet></router-outlet>
<div *ngIf="isMulticamActive" class="multicam-views-container">
    <div class="multicam-first-view-subcontainer" [ngClass]="{'black-text': isMulticamTextBlack}">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.ORANGE" src="assets/images/viewers/multicam/new/multicam-1st-view.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.WHITE" src="assets/images/viewers/multicam/new/multicam-1st-view-white-icon.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.BLACK" src="assets/images/viewers/multicam/new/multicam-1st-view-black-icon.svg">
        <p [innerHTML]="'visores_Multicam_data1' | i18n"></p>
    </div>
    <div class="multicam-second-view-subcontainer" [ngClass]="{'black-text': isMulticamTextBlack}">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.ORANGE" src="assets/images/viewers/multicam/new/multicam-2nd-view.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.WHITE" src="assets/images/viewers/multicam/new/multicam-2nd-view-white-icon.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.BLACK" src="assets/images/viewers/multicam/new/multicam-2nd-view-black-icon.svg">
        <p [innerHTML]="'visores_Multicam_data2' | i18n"></p>
    </div>
    <div class="multicam-third-view-subcontainer" [ngClass]="{'black-text': isMulticamTextBlack}">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.ORANGE" src="assets/images/viewers/multicam/new/multicam-3rd-view.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.WHITE" src="assets/images/viewers/multicam/new/multicam-3rd-view-white-icon.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.BLACK" src="assets/images/viewers/multicam/new/multicam-3rd-view-black-icon.svg">
        <p [innerHTML]="'visores_Multicam_data3' | i18n"></p>
    </div>
    <div class="multicam-fourth-view-subcontainer" [ngClass]="{'black-text': isMulticamTextBlack}">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.ORANGE" src="assets/images/viewers/multicam/new/multicam-4th-view.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.WHITE" src="assets/images/viewers/multicam/new/multicam-4th-view-white-icon.svg">
        <img *ngIf="currentMulticamIconsColor === multicamIconsColor.BLACK" src="assets/images/viewers/multicam/new/multicam-4th-view-black-icon.svg">
        <p [innerHTML]="'visores_Multicam_data4' | i18n"></p>
    </div>
</div>
