<img class="close-icon" src="/assets/images/main/iota-cookies/close.svg" (click)="close()">
<div class="home-steps-container" [ngClass]="{
    'steps-container-second': currentStep === allSteps.SECOND,
    'steps-container-third': currentStep === allSteps.THIRD,
    'steps-container-fourth': currentStep === allSteps.FOURTH,
    'steps-container-fifth': currentStep === allSteps.FIFTH,
    'steps-container-sixth': currentStep === allSteps.SIXTH,
    'steps-container-last': currentStep === allSteps.LAST
  }">
  <div class="triangle" *ngIf="currentStep !== allSteps.FIRST" [ngClass]="{
      'second-step': currentStep === allSteps.SECOND,
      'third-step': currentStep === allSteps.THIRD,
      'fourth-step': currentStep === allSteps.FOURTH,
      'fifth-step': currentStep === allSteps.FIFTH,
      'sixth-step': currentStep === allSteps.SIXTH,
      'last-step': currentStep === allSteps.LAST
    }">
  </div>
  <div class="home-steps-description" [innerHTML]="getStepDescription(currentStep) | i18n" [ngClass]="{
      'second-desc': currentStep === allSteps.SECOND,
      'third-desc': currentStep === allSteps.THIRD,
      'fourth-desc': currentStep === allSteps.FOURTH,
      'fifth-desc': currentStep === allSteps.FIFTH,
      'sixth-desc': currentStep === allSteps.SIXTH,
      'last-desc': currentStep === allSteps.LAST
    }">
  </div>
  <div class="filter-options" [ngClass]="{'hide': currentStep !== allSteps.FIFTH}">
    <div class="option" [innerHTML]="'first_experience_dialogs_Data29' | i18n"></div>
    <div class="option">
      <img src="/assets/images/main/first-experience/fav.svg" alt="icon">
      <div [innerHTML]="'first_experience_dialogs_Data30' | i18n"></div>
    </div>
    <div class="option">
      <img src="/assets/images/main/first-experience/failed.svg" alt="icon">
      <div [innerHTML]="'first_experience_dialogs_Data31' | i18n"></div>
    </div>
    <div class="option">
      <img src="/assets/images/main/first-experience/pending-me.svg" alt="icon">
      <div [innerHTML]="'first_experience_dialogs_Data32' | i18n"></div>
    </div>
    <div class="option">
      <img src="/assets/images/main/first-experience/pending-others.svg" alt="icon">
      <div [innerHTML]="'first_experience_dialogs_Data33' | i18n"></div>
    </div>
    <div class="option">
      <img src="/assets/images/main/first-experience/delete.svg" alt="icon">
      <div [innerHTML]="'first_experience_dialogs_Data34' | i18n"></div>
    </div>
  </div>
  <div class="steps">
    <div>{{ currentStep }}/7</div>
    <div class="step" *ngFor="let step of stepDescriptions" (click)="goToStep(step.key)" [ngClass]="{ 'current-step': step.key === currentStep, 'clickable': step.key < currentStep }">
    </div>
  </div>
  <div class="next-step" (click)="nextStep()" [ngClass]="{
      'last-next-button': currentStep === allSteps.LAST,
      'alt-next-btn': currentStep !== allSteps.FIRST
    }">
    <div
      [innerHTML]="(currentStep === allSteps.LAST ? 'first_experience_dialogs_Data23' : 'first_experience_dialogs_Data6') | i18n">
    </div>
    <img *ngIf="currentStep !== allSteps.LAST" class="next-arrow" src="/assets/images/main/home/path-down-ico.svg">
  </div>
</div>
