export const environment = {
  production: false,
  apiUrl: 'https://dev.api.eyescloud3d.com',
  stripeApi: 'https://dev.api.payment.ecapturedtech.com',
  isPublicApp: true,
  privateAppName: 'PCAMI',
  limitVersion: {
    expirationDate: '2029-11-26',
    countUsers: 5,
  },
  pwaUrl: 'https://dev.app.eyescloud3d.com',
  landingUrl: 'https://dev.eyescloud3d.com',
  chatLaravelApiUrl: 'https://api.irischat.ecapturedtech.com',
  chatNodeApiUrl: 'https://dev.node.irischat.ecapturedtech.com:3001',
  chatToken: '1|hnlO5O9JaKwWp2QXAzsyy3Y0DZizsZxXo2re93Am',
  stripePublicKey:
    'pk_test_51LawEPHmoV2GBrmTkuEpU3Y3bTSkB9x34AWvw5uoZYMnwqxMtNPmwmMrvx9EVgP6e4UGnLmlzUEVTdelgtLMIQR300YIodJewn',
  isOfflineViewer: false,
};
