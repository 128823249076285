export const VIEWPROJECT_I18N_ENTRIES = {
  viewproject_Data1: {
    es: '<p>Tu Modelo 3D:</p>',
    en: '<p>Your 3D Model:</p>',
    pt: '<p>O seu modelo 3D:</p>',
    fr: '<p>Votre modèle 3D :</p>',
    eus: '<p>Zure 3D eredua:</p>',
    cat: '<p>El teu model 3D:</p>',
  },

  viewproject_Data2: {
    es: '<p>Nube de puntos</p>',
    en: '<p>Point cloud</p>',
    pt: '<p>Nuvem de pontos</p>',
    fr: '<p>Nuage de points</p>',
    eus: '<p>Puntu hodeia</p>',
    cat: '<p>Núvol de punts</p>',
  },

  viewproject_Data3: {
    es: '<p>Fotos</p>',
    en: '<p>Photos</p>',
    pt: '<p>Fotografias</p>',
    fr: '<p>Photos</p>',
    eus: '<p>Argazkiak</p>',
    cat: '<p>Fotos</p>',
  },

  viewproject_Data4: {
    es: '<p>Vídeos</p>',
    en: '<p>Videos</p>',
    pt: '<p>Vídeos</p>',
    fr: '<p>Vidéos</p>',
    eus: '<p>Bideoak</p>',
    cat: '<p>Vídeos</p>',
  },

  viewproject_Data5: {
    es: '<p id="Model_interaction-click-view">Ver</p>',
    en: '<p id="Model_interaction-click-view">View</p>',
    pt: '<p id="Model_interaction-click-view">Ver</p>',
    fr: '<p id="Model_interaction-click-view">Voir</p>',
    eus: '<p id="Model_interaction-click-view">Ikusi</p>',
    cat: '<p id="Model_interaction-click-view">Mostra</p>',
  },

  viewproject_Data6: {
    es: '<p>Malla</p>',
    en: '<p>Mesh</p>',
    pt: '<p>Malha</p>',
    fr: '<p>Maille</p>',
    eus: '<p>Sarea</p>',
    cat: '<p>Malla</p>',
  },

  viewproject_Data7: {
    es: '<p>Datos del modelo 3D</p>',
    en: '<p>3D model details</p>',
    pt: '<p>Dados do modelo 3D</p>',
    fr: '<p>Données du modèle 3D</p>',
    eus: '<p>3D ereduaren datuak</p>',
    cat: '<p>Dades del model 3D</p>',
  },

  viewproject_Data8: {
    es: '<p>Nombre:</p>',
    en: '<p>Name:</p>',
    pt: '<p>Nome:</p>',
    fr: '<p>Nom :</p>',
    eus: '<p>Izena:</p>',
    cat: '<p>Nom:</p>',
  },

  viewproject_Data9: {
    es: '<p>Dirección:</p>',
    en: '<p>Address:</p>',
    pt: '<p>Endereço:</p>',
    fr: '<p>Adresse :</p>',
    eus: '<p>Helbidea:</p>',
    cat: '<p>Adreça:</p>',
  },

  viewproject_Data10: {
    es: '<p>Cámara:</p>',
    en: '<p>Camera:</p>',
    pt: '<p>Câmara:</p>',
    fr: '<p>Caméra :</p>',
    eus: '<p>Kamera:</p>',
    cat: '<p>Càmera:</p>',
  },

  viewproject_Data11: {
    es: '<p>Fecha:</p>',
    en: '<p>Date:</p>',
    pt: '<p>Data:</p>',
    fr: '<p>Date :</p>',
    eus: '<p>Data:</p>',
    cat: '<p>Data:</p>',
  },

  viewproject_Data12: {
    es: '<p>Categoría del modelo 3D:</p>',
    en: '<p>3D Model Category:</p>',
    pt: '<p>Categoria do modelo 3D:</p>',
    fr: '<p>Catégorie du modèle 3D :</p>',
    eus: '<p>3D ereduaren kategoria:</p>',
    cat: '<p>Categoria del model 3D:</p>',
  },

  viewproject_Data13: {
    es: '<p>Accesible por URL:</p>',
    en: '<p>Accessible by URL:</p>',
    pt: '<p>Acessível através de URL:</p>',
    fr: '<p>Accessible par URL :</p>',
    eus: '<p>URL bidez eskuragarria:</p>',
    cat: '<p>Accessible per URL:</p>',
  },

  viewproject_Data14: {
    es: '<p>Visible para usuarios:</p>',
    en: '<p>Visible to users:</p>',
    pt: '<p>Visível para utilizadores:</p>',
    fr: '<p>Visible pour les utilisateurs :</p>',
    eus: '<p>Erabiltzaileentzat ikusgai:</p>',
    cat: '<p>Visible per als usuaris:</p>',
  },

  viewproject_Data15: {
    es: '<p>Visible para departamento:</p>',
    en: '<p>Visible to the department:</p>',
    pt: '<p>Visível para o departamento:</p>',
    fr: '<p>Visible pour le département :</p>',
    eus: '<p>Departamentuarentzat ikusgai:</p>',
    cat: '<p>Visible per al departament:</p>',
  },

  viewproject_Data16: {
    es: '<p>GUARDAR</p>',
    en: '<p>SAVE</p>',
    pt: '<p>GUARDAR</p>',
    fr: '<p>SAUVEGARDER</p>',
    eus: '<p>GORDE</p>',
    cat: '<p>DESA</p>',
  },

  viewproject_Data17: {
    es: '<p>Añadir imágenes hiperespectrales</p>',
    en: '<p>Add hyperspectral images</p>',
    pt: '<p>Adicionar imagens hiperespetrais</p>',
    fr: '<p>Ajouter images hyperspectrales</p>',
    eus: '<p>Gehitu irudi hiperespektralak</p>',
    cat: '<p>Afegeix imatges hiperespectrals</p>',
  },

  viewproject_Data18: {
    es: '<p>Añadir imágenes hiperespectrales</p>',
    en: '<p>Add hyperspectral images</p>',
    pt: '<p>Adicionar imagens hiperespetrais</p>',
    fr: '<p>Ajouter images hyperspectrales</p>',
    eus: '<p>Gehitu irudi hiperespektralak</p>',
    cat: '<p>Afegeix imatges hiperespectrals</p>',
  },

  viewproject_Data19: {
    es: '<p>Sube o arrastra las imágenes hiperespectrales que deseas</p><p>incluir en el modelo 3D.</p>',
    en: '<p>Upload or drag and drop the hyperspectral images you wish</p><p>to include in your 3D model.</p>',
    pt: '<p>Carregue ou arraste as imagens hiperespetrais que pretende</p><p>incluir no modelo 3D.</p>',
    fr: '<p>Téléchargez ou faites glisser les images hyperspectrales que</p><p>vous souhaitez inclure dans le modèle 3D.</p>',
    eus: '<p>Kargatu edo arrastatu zure 3D eredura erantsi nahi dituzun</p><p>irudi hiperespektralak.</p>',
    cat: '<p>Carrega o arrossega les imatges hiperespectrals que vulguis</p><p>incloure en el model 3D.</p>',
  },

  viewproject_Data20: {
    es: '<p>Añadir imágenes</p><p>Hiperespectrales</p>',
    en: '<p>Add hyperspectral</p><p>images</p>',
    pt: '<p>Adicionar imagens</p><p>hiperespetrais</p>',
    fr: '<p>Ajouter images</p><p>hyperspectrales</p>',
    eus: '<p>Gehitu irudi</p><p>hiperespektralak</p>',
    cat: '<p>Afegeix imatges</p><p>hiperespectrals</p>',
  },

  viewproject_Data21: {
    es: '<p>PROCESAR MODELO 3D</p>',
    en: '<p>PROCESS 3D MODEL</p>',
    pt: '<p>PROCESSAR MODELO 3D</p>',
    fr: '<p>TRAITER MODÈLE 3D</p>',
    eus: '<p>PROZESATU 3D EREDUA</p>',
    cat: '<p>PROCESSA EL MODEL 3D</p>',
  },

  viewproject_Data22: {
    es: '<p>IOTA</p>',
    en: '<p>IOTA</p>',
    pt: '<p>IOTA</p>',
    fr: '<p>IOTA</p>',
    eus: '<p>IOTA</p>',
    cat: '<p>IOTA</p>',
  },

  viewproject_Data23: {
    es: '<p>INFORMES DE:</p>',
    en: '<p>REPORTS ON:</p>',
    pt: '<p>RELATÓRIOS DE:</p>',
    fr: '<p>RAPPORTS DE :</p>',
    eus: '<p>TXOSTENAK:</p>',
    cat: '<p>INFORMES DE:</p>',
  },

  viewproject_Data24: {
    es: '<p>Envío de</p><p>imágenes</p>',
    en: '<p>Image</p><p>transfer</p>',
    pt: '<p>Envio de</p><p>imagens</p>',
    fr: '<p>Envoi</p><p>d\'images</p>',
    eus: '<p>Irudien</p><p>bidalketa</p>',
    cat: '<p>Enviament</p><p>d\'imatges</p>',
  },

  viewproject_Data25: {
    es: '<p>Procesos</p><p>realizados</p>',
    en: '<p>Processes</p><p>completed</p>',
    pt: '<p>Processamentos</p><p>efetuados</p>',
    fr: '<p>Processus</p><p>réalisés</p>',
    eus: '<p>Burututako</p><p>prozesuak</p>',
    cat: '<p>Processos</p><p>efectuats</p>',
  },

  viewproject_Data26: {
    es: '<p>Descarga</p><p>realizada</p>',
    en: '<p>Download</p><p>completed</p>',
    pt: '<p>Transferência</p><p>efetuada</p>',
    fr: '<p>Téléch.</p><p>effectué</p>',
    eus: '<p>Osatutako</p><p>deskarga</p>',
    cat: '<p>Baixada</p><p>efectuada</p>',
  },

  viewproject_Data27: {
    es: '<p>SISTEMA</p><p>SEGURIDAD POR</p><p>BLOCKCHAIN</p>',
    en: '<p>BLOCKCHAIN</p><p>SECURITY</p><p>SYSTEM</p>',
    pt: '<p>SISTEMA</p><p>DE SEGURANÇA POR</p><p>BLOCKCHAIN</p>',
    fr: '<p>SYSTÈME DE</p><p>SÉCURITÉ PAR</p><p>BLOCKCHAIN</p>',
    eus: '<p>BLOCKCHAIN</p><p>BIDEZKO SEGURTASUN</p><p>SISTEMA</p>',
    cat: '<p>SISTEMA DE</p><p>SEGURETAT PER</p><p>BLOCKCHAIN</p>',
  },

  viewproject_Data27v2: {
    es: '<p>SISTEMA SEGURIDAD</p><p>POR BLOCKCHAIN</p>',
    en: '<p>BLOCKCHAIN</p><p>SECURITY SYSTEM</p>',
    pt: '<p>SISTEMA DE SEGURANÇA</p><p>POR BLOCKCHAIN</p>',
    fr: '<p>SYSTÈME DE SÉCURITÉ</p><p>PAR BLOCKCHAIN</p>',
    eus: '<p>BLOCKCHAIN BIDEZKO</p><p>SEGURTASUN SISTEMA</p>',
    cat: '<p>SISTEMA DE SEGURETAT</p><p>PER BLOCKCHAIN</p>',
  },

  viewproject_Data28: {
    es: '<p>DESCARGAS REALIZADAS</p>',
    en: '<p>DOWNLOADS COMPLETED</p>',
    pt: '<p>TRANSFERÊNCIAS EFETUADAS</p>',
    fr: '<p>TÉLÉCHARGEMENTS RÉALISÉS</p>',
    eus: '<p>OSATUTAKO DESKARGAK</p>',
    cat: '<p>BAIXADES EFECTUADES</p>',
  },

  viewproject_Data29: {
    es: '<p>HISTORIAL DE DESCARGA</p>',
    en: '<p>PAST DOWNLOADS</p>',
    pt: '<p>HISTÓRICO DE TRANSFERÊNCIAS</p>',
    fr: '<p>HISTORIQUE DES TÉLÉCHARGEMENTS</p>',
    eus: '<p>DESKARGEN HISTORIA</p>',
    cat: '<p>HISTORIAL DE BAIXADA</p>',
  },

  viewproject_Data30: {
    es: '<p>Nº</p>',
    en: '<p>Number</p>',
    pt: '<p>N.º</p>',
    fr: '<p>Nº</p>',
    eus: '<p>Zenb.</p>',
    cat: '<p>Núm.</p>',
  },

  viewproject_Data31: {
    es: '<p>Formato</p>',
    en: '<p>Format</p>',
    pt: '<p>Formato</p>',
    fr: '<p>Format</p>',
    eus: '<p>Formatua</p>',
    cat: '<p>Format</p>',
  },

  viewproject_Data32: {
    es: '<p>Fecha</p>',
    en: '<p>Date</p>',
    pt: '<p>Data</p>',
    fr: '<p>Date</p>',
    eus: '<p>Data</p>',
    cat: '<p>Data</p>',
  },

  viewproject_Data33: {
    es: '<p>DESCARGAR INFORME IOTA</p>',
    en: '<p>DOWNLOAD IOTA REPORT</p>',
    pt: '<p>TRANSFERIR RELATÓRIO IOTA</p>',
    fr: '<p>TÉLÉCHARGER LE RAPPORT IOTA</p>',
    eus: '<p>DESKARGATU IOTA TXOSTENA</p>',
    cat: '<p>BAIXA L\'INFORME IOTA</p>',
  },

  viewproject_Data34: {
    es: '<p>Informe IOTA</p>',
    en: '<p>IOTA report</p>',
    pt: '<p>Relatório IOTA</p>',
    fr: '<p>Rapport IOTA</p>',
    eus: '<p>IOTA txostena</p>',
    cat: '<p>Informe IOTA</p>',
  },

  viewproject_Data35: {
    es: '<p>Este modelo 3D ha sido generado con</p><p>anterioridad a la implementación del</p><p>sistema IOTA</p>',
    en: '<p>This 3D model was generated</p><p>prior to the implementation of the</p><p>IOTA system</p>',
    pt: '<p>Este modelo 3D foi criado</p><p>anteriormente à implementação do</p><p>sistema IOTA</p>',
    fr: '<p>Ce modèle 3D a été généré</p><p>avant l’implémentation du</p><p>système IOTA</p>',
    eus: '<p>3D eredu hau</p><p>IOTA sistema inplementatu</p><p>aurretik sortu zen</p>',
    cat: '<p>Aquest model 3D ha estat generat</p><p>abans de la implementació</p><p>del sistema IOTA</p>',
  },

  viewproject_Data36: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },

  viewproject_Data37: {
    es: '<p>Vídeo</p>',
    en: '<p>Video</p>',
    pt: '<p>Vídeo</p>',
    fr: '<p>Vidéo</p>',
    eus: '<p>Bideoa</p>',
    cat: '<p>Vídeo</p>',
  },

  viewproject_Data38: {
    es: '<p>Foto</p>',
    en: '<p>Photo</p>',
    pt: '<p>Fotografia</p>',
    fr: '<p>Photo</p>',
    eus: '<p>Argazkia</p>',
    cat: '<p>Foto</p>',
  },

  viewproject_Data39: {
    es: '<p>Este formato de vídeo no puede</p><p>reproducirse en el navegador</p>',
    en: '<p>This video format cannot be</p><p>played in your browser</p>',
    pt: '<p>Este formato de vídeo não pode</p><p>ser reproduzido no navegador</p>',
    fr: '<p>Ce format de vidéo ne peut pas être</p><p>reproduit dans le navigateur</p>',
    eus: '<p>Bideo formatu hau ezin da</p><p>nabigatzailean erreproduzitu</p>',
    cat: '<p>Aquest format de vídeo no es pot</p><p>reproduir al navegador</p>',
  },

  viewproject_Data40: {
    es: '<p>El vídeo utilizado para la generación del modelo 3D</p><p>no es compatible con su navegador.</p>',
    en: '<p>The video used to generate the 3D model</p><p>is not compatible with your browser.</p>',
    pt: '<p>O vídeo utilizado para a criação do modelo 3D</p><p>não é compatível com o seu navegador.</p>',
    fr: '<p>La vidéo utilisée pour la génération du modèle 3D</p><p>n\'est pas compatible avec votre navigateur.</p>',
    eus: '<p>3D eredua sortzeko erabili duzun bideoa</p><p>ez da zure nabigatzailearekin bateragarria.</p>',
    cat: '<p>El vídeo emprat per a generar el model 3D</p><p>no és compatible amb el navegador.</p>',
  },

  viewproject_Data41: {
    es: '<p>¿Quieres eliminar el modelo 3D %X%?</p>',
    en: '<p>Do you want to delete the 3D model %X%?</p>',
    pt: '<p>Pretende eliminar o modelo 3D%X%?</p>',
    fr: '<p>Souhaitez-vous supprimer le modèle 3D %X% ?</p>',
    eus: '<p>Ezabatu nahi duzu %X% 3D eredua?</p>',
    cat: '<p>Vols eliminar el model 3D %X%?</p>',
  },

  viewproject_Data42: {
    es: '<p>Al eliminar este modelo 3D también se</p><p>eliminarán sus datos.</p>',
    en: '<p>If you delete this 3D model, the corresponding</p><p>data will also be deleted.</p>',
    pt: '<p>Ao eliminar este modelo 3D, eliminar-se-ão também</p><p>os dados do mesmo.</p>',
    fr: '<p>Si vous supprimez ce modèle 3D, vous supprimerez également toutes les données qu\'il contient.</p>',
    eus: '<p>3D eredua ezabatzean, honen datu guztiak</p><p>ere ezabatuko dira.</p>',
    cat: '<p>Si elimines aquest model 3D, també</p><p>s’eliminaran les seves dades.</p>',
  },

  viewproject_Data43: {
    es: '<p>Esta acción NO se puede deshacer.</p>',
    en: '<p>This action CANNOT BE undone.</p>',
    pt: '<p>Esta ação NÃO é reversível.</p>',
    fr: '<p>Cette action NE PEUT PAS être annulée.</p>',
    eus: '<p>Ekintza hau EZIN DA desegin.</p>',
    cat: '<p>Aquesta acció NO es pot desfer.</p>',
  },

  viewproject_Data44: {
    es: '<p>Cancelar</p>',
    en: '<p>Cancel</p>',
    pt: '<p>Cancelar</p>',
    fr: '<p>Annuler</p>',
    eus: '<p>Utzi</p>',
    cat: '<p>Cancel·la</p>',
  },

  viewproject_Data45: {
    es: '<p>Eliminar</p>',
    en: '<p>Delete</p>',
    pt: '<p>Eliminar</p>',
    fr: '<p>Supprimer</p>',
    eus: '<p>Ezabatu</p>',
    cat: '<p>Elimina</p>',
  },

  viewproject_Data46: {
    es: '<p>El modelo 3D "%X%" no se pudo generar.</p>',
    en: '<p>The 3D model "%X%" could not be generated.</p>',
    pt: '<p>Não foi possível criar o modelo 3D "%X%".</p>',
    fr: '<p>Le modèle 3D "%X%" n’a pas pu être généré.</p>',
    eus: '<p>"%X%" 3D eredua ezin izan da sortu.</p>',
    cat: '<p>El model 3D "%X%" no s’ha pogut generar.</p>',
  },

  viewproject_Data47: {
    es: '<p>ACEPTAR</p>',
    en: '<p>ACCEPT</p>',
    pt: '<p>ACEITAR</p>',
    fr: '<p>ACCEPTER</p>',
    eus: '<p>ONARTU</p>',
    cat: '<p>ACCEPTA</p>',
  },

  viewproject_Data2_2: {
    es: '<p>Nube de puntos</p>',
    en: '<p>Point cloud</p>',
    pt: '<p>Nuvem de pontos</p>',
    fr: '<p>Nuage de points</p>',
    eus: '<p>Puntu hodeia</p>',
    cat: '<p>Núvol de punts</p>',
  },

  viewproject_Data6_2: {
    es: '<p>Malla</p>',
    en: '<p>Mesh</p>',
    pt: '<p>Malha</p>',
    fr: '<p>Maille</p>',
    eus: '<p>Sarea</p>',
    cat: '<p>Malla</p>',
  },

  viewproject_Data48: {
    es: '<p>Público</p>',
    en: '<p>Audience</p>',
    pt: '<p>Público</p>',
    fr: '<p>Public</p>',
    eus: '<p>Publikoa</p>',
    cat: '<p>Públic</p>',
  },

  viewproject_Data49: {
    es: '<p>Compartir</p>',
    en: '<p>Share</p>',
    pt: '<p>Partilhar</p>',
    fr: '<p>Partager</p>',
    eus: '<p>Partekatu</p>',
    cat: '<p>Comparteix</p>',
  },

  viewproject_Data50: {
    es: '<p>Selecciona categoría</p>',
    en: '<p>Select category</p>',
    pt: '<p>Selecione a categoria</p>',
    fr: '<p>Sélectionnez une catégorie</p>',
    eus: '<p>Aukeratu kategoria</p>',
    cat: '<p>Selecciona una categoria</p>',
  },

  viewproject_Data51: {
    es: '<p>Selecciona subcategoría</p>',
    en: '<p>Select subcategory</p>',
    pt: '<p>Selecione a subcategoria</p>',
    fr: '<p>Sélectionnez une sous-catégorie</p>',
    eus: '<p>Aukeratu azpikategoria</p>',
    cat: '<p>Selecciona una subcategoria</p>',
  },

  viewproject_Data52: {
    es: '<p>Selecciona un departamento</p>',
    en: '<p>Select a department</p>',
    pt: '<p>Selecione um departamento</p>',
    fr: '<p>Sélectionnez un département</p>',
    eus: '<p>Aukeratu departamentu bat</p>',
    cat: '<p>Selecciona un departament</p>',
  },

  viewproject_Data53: {
    es: '<p>Localización</p>',
    en: '<p>Location</p>',
    pt: '<p>Localização</p>',
    fr: '<p>Emplacement</p>',
    eus: '<p>Kokalekua</p>',
    cat: '<p>Localització</p>',
  },

  viewproject_Data54: {
    es: '<p>HIPERESPECTRALES</p>',
    en: '<p>HYPERSPECTRALS</p>',
    pt: '<p>HIPERESPETRAIS</p>',
    fr: '<p>HYPERSPECTRALES</p>',
    eus: '<p>HIPERESPEKTRALAK</p>',
    cat: '<p>HIPERESPECTRALS</p>',
  },

  viewproject_Data55: {
    es: '<p>DESCARGAR INFORMES</p>',
    en: '<p>DOWNLOAD REPORT</p>',
    pt: '<p>TRANSFERIR RELATÓRIOS</p>',
    fr: '<p>TÉLÉCHARGER RAPPORTS</p>',
    eus: '<p>DESCARGATU TXOSTENAK</p>',
    cat: '<p>BAIXA INFORMES</p>',
  },

  viewproject_Data56: {
    es: '<p>TRATAMIENTO DE IMÁGENES</p>',
    en: '<p>IMAGE PROCESSING</p>',
    pt: '<p>TRATAMENTO DE IMAGENS</p>',
    fr: '<p>TRAITEMENT D’IMAGES</p>',
    eus: '<p>IRUDIEN TRATAMENDUA</p>',
    cat: '<p>TRACTAMENT D’IMATGES</p>',
  },

  viewproject_Data57: {
    es: '<p>ELIMINAR MODELO</p>',
    en: '<p>DELETE MODEL</p>',
    pt: '<p>ELIMINAR MODELO</p>',
    fr: '<p>SUPPRIMER MODÈLE</p>',
    eus: '<p>EZABATU EREDUA</p>',
    cat: '<p>ELIMINA MODEL</p>',
  },

  viewproject_Data58: {
    es: '<p>ESCRIBIR INCIDENCIA</p>',
    en: '<p>REPORT AN INCIDENT</p>',
    pt: '<p>ESCREVER INCIDÊNCIA</p>',
    fr: '<p>SIGNALER INCIDENT</p>',
    eus: '<p>EMAN ARAZO BATEN BERRI</p>',
    cat: '<p>ESCRIU UNA INCIDÈNCIA</p>',
  },

  viewproject_Data59: {
    es: '<p>Descargar informes de:</p>',
    en: '<p>Download reports:</p>',
    pt: '<p>Transferir relatórios de:</p>',
    fr: '<p>Télécharger les rapports de :</p>',
    eus: '<p>Deskargatu txostenak:</p>',
    cat: '<p>Baixa informes de:</p>',
  },

  viewproject_Data60: {
    es: '<p>INFORME DE ENVÍO DE IMÁGENES</p>',
    en: '<p>IMAGE TRANSFER REPORT</p>',
    pt: '<p>RELATÓRIO DE ENVIO DE IMAGENS</p>',
    fr: '<p>RAPPORT D’ENVOI D’IMAGES</p>',
    eus: '<p>IRUDIEN BIDALKETA TXOSTENA</p>',
    cat: '<p>INFORME D’ENVIAMENT D’IMATGES</p>',
  },

  viewproject_Data61: {
    es: '<p>INFORME DE PROCESOS REALIZADOS</p>',
    en: '<p>PROCESSES COMPLETED REPORT</p>',
    pt: '<p>RELATÓRIO DE PROCESSAMENTOS REALIZADOS</p>',
    fr: '<p>RAPPORT DE PROCESSUS RÉALISÉS</p>',
    eus: '<p>BURUTUTAKO PROZESUEN TXOSTENA</p>',
    cat: '<p>INFORME DE PROCESSOS EFECTUATS</p>',
  },

  viewproject_Data62: {
    es: '<p>INFORME DE DESCARGAS REALIZADAS</p>',
    en: '<p>COMPLETED DOWNLOADS REPORT</p>',
    pt: '<p>RELATÓRIO DE TRANSFERÊNCIAS EFETUADAS</p>',
    fr: '<p>RAPPORTS DE TÉLÉCHARGEMENTS RÉALISÉS</p>',
    eus: '<p>OSATUTAKO DESKARGEN TXOSTENA</p>',
    cat: '<p>LLISTAT DE BAIXADES EFECTUADES</p>',
  },

  viewproject_Data63: {
    es: '<p>ELIMINAR LOGOTIPO ACTUAL</p>',
    en: '<p>DELETE CURRENT LOGO</p>',
    pt: '<p>ELIMINAR LOGOTIPO ATUAL</p>',
    fr: '<p>SUPPRIMER LE LOGO ACTUEL</p>',
    eus: '<p>EZABATU UNEKO LOGOTIPOA</p>',
    cat: '<p>ELIMINA EL LOGOTIP ACTUAL</p>',
  },

  viewproject_Data64: {
    es: '<p>¿Quieres añadir tu propio logotipo al informe?</p>',
    en: '<p>Would you like to add your own logo to the report?</p>',
    pt: '<p>Pretende adicionar o seu próprio logotipo ao relatório?</p>',
    fr: '<p>Souhaitez-vous ajouter votre logo au rapport ?</p>',
    eus: '<p>Zure logotipo propioa erantsi nahi diozu txostenari?</p>',
    cat: '<p>Vols afegir el teu logotip a l’informe?</p>',
  },

  viewproject_Data65: {
    es: '<p>Estas actualizaciones se mantendrán y se podrán</p><p>cambiar en el apartado logotipo de <span class="i18n-underline i18n-bold i18n-color-white cursor-pointer"><a class="i18n-color-white i18n-bold" href="/profile/logo-report">mi cuenta</a></span></p>',
    en: '<p>These updates will be saved. They can be</p><p>changed in the logo section of <span class="i18n-underline i18n-bold i18n-color-white cursor-pointer"><a class="i18n-color-white i18n-bold" href="/profile/logo-report">my account</a></span></p>',
    pt: '<p>Estas atualizações serão mantidas e poderão</p><p>ser alteradas na secção logotipo de <span class="i18n-underline i18n-bold i18n-color-white cursor-pointer"><a class="i18n-color-white i18n-bold" href="/profile/logo-report">A minha conta</a></span></p>',
    fr: '<p>Ces changements seront conservés et peuvent</p><p>être modifiés dans la section logo de <span class="i18n-underline i18n-bold i18n-color-white cursor-pointer"><a class="i18n-color-white i18n-bold" href="/profile/logo-report">mon compte</a></span></p>',
    eus: '<p>Eguneratze hauek mantenduko dira eta <span class="i18n-underline i18n-bold i18n-color-white cursor-pointer"><a class="i18n-color-white i18n-bold" href="/profile/logo-report">zure kontuko</a></span></p><p>atalean aldatu ahal izango dira.</p>',
    cat: '<p>Aquestes actualitzacions es mantindran i es podran</p><p>modificar des de l\'apartat “logotip” de la secció <span class="i18n-underline i18n-bold i18n-color-white cursor-pointer"><a class="i18n-color-white i18n-bold" href="/profile/logo-report">El meu compte</a></span></p>',
  },

  viewproject_Data66: {
    es: '<p>RECOMENDACIONES:</p>',
    en: '<p>TIPS:</p>',
    pt: '<p>RECOMENDAÇÕES:</p>',
    fr: '<p>RECOMMANDATIONS :</p>',
    eus: '<p>GOMENDIOAK:</p>',
    cat: '<p>RECOMANACIONS:</p>',
  },

  viewproject_Data67: {
    es: '<p>Recomendamos que adjuntes una captura de pantalla para poder ver los errores de tu modelo 3D, pulsa botón derecho sobre la pantalla y haz clic en inspeccionar, a continuación haz una captura y adjúntala.</p>',
    en: '<p>We recommend that you attach a screenshot so you can see the errors in your 3D model. Right-click on the screen and select Inspect, then take a screenshot and attach it.</p>',
    pt: '<p>Recomendamos que anexe uma captura de ecrã para poder ver os erros do seu modelo 3D, carregue no botão direito sobre o ecrã e clique em inspecionar. Em seguida, realize a captura e anexe-a.</p>',
    fr: '<p>Nous vous recommandons de joindre une capture d\'écran pour voir les erreurs dans votre modèle 3D.Faites un clic droit sur l\'écran et sélectionnez Inspecter, puis effectuez une capture d\'écran et joignez-la.</p>',
    eus: '<p>Pantaila kaptura bat eranstea gomendatzen dizugu zure 3D ereduaren erroreak ikusi ahal izateko, sakatu eskuineko botoia pantailaren gainean eta egin klik Ikuskatu aukeran, ondoren kaptura bat egin eta erants ezazu.</p>',
    cat: '<p>Et recomanem adjuntar una captura de pantalla per poder veure els errors del model 3D, prem el botó dret sobre la pantalla i fes clic a l’opció d’inspeccionar. A continuació, fes una captura i adjunta-la.</p>',
  },

  viewproject_Data68: {
    es: '<p>AÑADIR HIPERESPECTRALES</p>',
    en: '<p>ADD HYPERSPECTRALS</p>',
    pt: '<p>ADICIONAR HIPERESPETRAIS</p>',
    fr: '<p>AJOUTER HYPERSPECTRALES</p>',
    eus: '<p>GEHITU HIPERESPEKTRALAK</p>',
    cat: '<p>AFEGEIX HIPERESPECTRALS</p>',
  }, 
 
  viewproject_Data69: {
    es: '<p>DESCARGAR</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  }, 
};
