<div class="layout-container" #layoutContainer (window:resize)="onResize()" >
  <app-header [ngClass]="{ 'header': isHelpContainer }"></app-header >
  <div class="main-container" [ngClass]="{ 'help-container': isHelpContainer }">
    <main>
      <router-outlet (activate)="onComponentActivate($event)"></router-outlet>
    </main>
  </div>
  <app-footer [ngClass]="{ 'footer': isHelpContainer }"></app-footer>
  <app-nc-consent></app-nc-consent>
  <app-iota-notice></app-iota-notice>
</div>
