import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RestClientErrorHandlerService } from './rest-client-error-handler.service';
import { SecurityTokenStorage } from '../../../services/security/security-token-storage';
import { BaseProfile } from '../../../models/security/base-profile';
import { DateService } from '../../../services/date.service';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../../../services/config/app-config.service';
import { I18nService } from '../i18n.service';
import { UserToken } from '../../../models/security/user-token';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/models/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class RestClientService {
  jsonFile: any;

  constructor(
    private httpClient: HttpClient,
    private i18nService: I18nService,
    private restClientErrorHandler: RestClientErrorHandlerService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private storageService: StorageService
  ) {}

  public get<T>(url: string, params?: any): Observable<T> {
    if (!environment.isOfflineViewer) {
      const completeUrl = this.getCompleteUrl(url);
      return this.httpClient
        .get<T>(completeUrl, this.getRequestOptions(params))
        .pipe(
          catchError((error) => this.restClientErrorHandler.handleError(error))
        );
    } else {
      return new Observable((subscriber) => {
        if (url[0] === '/') {
          url = url.slice(1);
        }
        const questionMarkIndex = url.indexOf('?');
        if (questionMarkIndex !== -1) {
          url = url.slice(0, questionMarkIndex);
        }
        console.log(url);
        if (this.jsonFile) {
          subscriber.next(this.jsonFile[url]);
          subscriber.complete();
        } else {
          this.httpClient
            .get<any>('assets/extraResources/offline-viewer-data.json')
            .subscribe((jsonFile) => {
              this.jsonFile = jsonFile;
              subscriber.next(this.jsonFile[url]);
              subscriber.complete();
            });
        }
      });
    }
  }

  public getExternal<T>(url: string, params?: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .get<T>(completeUrl, this.getRequestOptionsExternal(params))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public post<T>(
    url: string,
    data: any,
    httpHeaders?: HttpHeaders
  ): Observable<T> {
    if (!environment.isOfflineViewer) {
      const completeUrl = this.getCompleteUrl(url);
      return this.httpClient
        .post<T>(completeUrl, data, this.getRequestOptions({}, httpHeaders))
        .pipe(
          catchError((error) => this.restClientErrorHandler.handleError(error))
        );
    } else {
      return new Observable((subscriber) => {
        subscriber.next();
        subscriber.complete();
      });
    }
  }

  public postExternal<T>(
    url: string,
    data: any,
    httpHeaders?: HttpHeaders
  ): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .post<T>(
        completeUrl,
        data,
        this.getRequestOptionsExternal({}, httpHeaders)
      )
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public put<T>(url: string, data: any): Observable<T> {
    if (!environment.isOfflineViewer) {
      const completeUrl = this.getCompleteUrl(url);
      return this.httpClient
        .put<T>(completeUrl, data, this.getRequestOptions({}))
        .pipe(
          catchError((error) => this.restClientErrorHandler.handleError(error))
        );
    } else {
      return new Observable((subscriber) => {
        subscriber.next();
        subscriber.complete();
      });
    }
  }

  public putExternal<T>(url: string, data: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .put<T>(completeUrl, data, this.getRequestOptionsExternal({}))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public delete<T>(url: string, params?: any): Observable<T> {
    if (!environment.isOfflineViewer) {
      const completeUrl = this.getCompleteUrl(url);
      return this.httpClient
        .request<T>('delete', completeUrl, {
          ...this.getRequestOptions({}),
          ...{ body: params },
        })
        .pipe(
          catchError((error) => this.restClientErrorHandler.handleError(error))
        );
    } else {
      return new Observable((subscriber) => {
        subscriber.next();
        subscriber.complete();
      });
    }
  }

  public getBlob<T>(url: string, params?: any): Observable<Blob> {
    return this.httpClient
      .get(this.getCompleteUrl(url), {
        responseType: 'blob',
        headers: this.getHttpHeaders(null),
        params: this.getHttpParams(params),
      })
      .pipe(
        catchError((error) =>
          this.restClientErrorHandler.handleBlobError(error)
        )
      );
  }

  public getArrayBuffer<T>(url: string, params?: any): Observable<ArrayBuffer> {
    return this.httpClient
      .get(this.getCompleteUrl(url), {
        responseType: 'arraybuffer',
        headers: this.getHttpHeaders(null),
        params: this.getHttpParams(params),
      })
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public getText<T>(url: string, params?: any): Observable<string> {
    return this.httpClient
      .get(this.getCompleteUrl(url), {
        responseType: 'text',
        headers: this.getHttpHeaders(null),
        params: this.getHttpParams(params),
      })
      .pipe(
        catchError((error) =>
          this.restClientErrorHandler.handleBlobError(error)
        )
      );
  }

  public getOfflineViewerModel(): Observable<string> {
    return new Observable((subscriber) => {
      if (this.jsonFile) {
        subscriber.next(this.jsonFile['model_code']);
        subscriber.complete();
      } else {
        this.httpClient
          .get<any>('assets/extraResources/offline-viewer-data.json')
          .subscribe((jsonFile) => {
            this.jsonFile = jsonFile;
            subscriber.next(this.jsonFile['model_code']);
            subscriber.complete();
          });
      }
    });
  }

  private getCompleteUrl(url: string): string {
    if (url.includes(environment.apiUrl)) {
      return url;
    }
    return url.length > 0 && url[0] === '/'
      ? `${environment.apiUrl}${url}`
      : `${environment.apiUrl}/${url}`;
  }

  private getRequestOptions(params: any, httpHeaders?: HttpHeaders) {
    return {
      headers: this.getHttpHeaders(httpHeaders),
      params: this.getHttpParams(params),
    };
  }

  private getRequestOptionsExternal(params: any, httpHeaders?: HttpHeaders) {
    return {
      headers: this.getHttpHeadersExternal(httpHeaders),
      params: this.getHttpParams(params),
    };
  }

  public getHttpHeaders(httpHeaders: HttpHeaders): HttpHeaders {
    httpHeaders = httpHeaders || new HttpHeaders();
    httpHeaders = httpHeaders.set(
      'Accept-Language',
      this.i18nService.getCurrentLanguage().httpHeader
    );

    const acceptedLogin = this.securityTokenStorage.getObjectValue();
    if (acceptedLogin && acceptedLogin.token) {
      httpHeaders = httpHeaders.set('Authorization', acceptedLogin.token);
    }
    return httpHeaders;
  }

  public getHttpHeadersExternal(httpHeaders: HttpHeaders): HttpHeaders {
    httpHeaders = httpHeaders || new HttpHeaders();
    httpHeaders = httpHeaders.set(
      'Accept-Language',
      this.i18nService.getCurrentLanguage().httpHeader
    );
    const acceptedLogin = this.storageService.get('externalToken', '');
    if (acceptedLogin !== '') {
      httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + acceptedLogin);
    }
    return httpHeaders;
  }

  private getHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });
    }
    return httpParams;
  }
}
