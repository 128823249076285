<div class="contenedor">
    <img  src="assets/images/viewers/new-close-popup-black.svg"  class="close-ico" (click)="closeDialog()">
    <img src="assets/images/main/profile/My-account/fail-ico.svg"  class="main-icon">
    <div class="text-container">
        <p  [innerHTML]="'profile_Data126' | i18n "></p>
    </div>
    <div class="second-text-container">
        <p [innerHTML]="'profile_Data127' | i18n "></p>
    </div>
    <div class="button-try-again" (click)="goToPersonalArea()"><p [innerHTML]="'profile_Data128' | i18n"></p></div>
</div>
