<div class="terms-layout-container">
  <div class="menu-terms-container">
    <div class="menu-terms-menus-container">
      <div class="menu-terms-menu-container terms-of-use" routerLink="terms-of-use" (click)="changeText(informationPageEnum.TERMS_OF_USE)">
        <div class="menu-terms-menu-container-text terms-of-use-text" [innerHTML]="'termsOfUse_data1' | i18n"></div>
      </div>
      <div class="menu-terms-menu-container privacy-policy" routerLink="privacy-policy" (click)="changeText(informationPageEnum.PRIVACY_POLICY)">
        <div class="menu-terms-menu-container-text privacy-policy-text" [innerHTML]="'privacyPolicy_data1' | i18n"></div>
      </div>
      <div class="menu-terms-menu-container cookie-policy" routerLink="cookie-policy" (click)="changeText(informationPageEnum.COOKIE_POLICY)">
        <div class="menu-terms-menu-container-text cookies-policy-text" [innerHTML]="'cookiesPolicy_data1' | i18n"></div>
      </div>
    </div>
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>