/**
 * Enumerates all existing viewer controls on the platform.
 */
export enum ViewerControls
{
    NONE,
    MOVE,
    ROTATE,
    AUTOFOCUS,
    GRID,
    AXES,
    ZOOM_IN,
    ZOOM_OUT
}
