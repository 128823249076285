import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GalleryStepsConfig } from 'src/app/commons/enum/first-experience-configs';
import { FirstExperienceGalleryStepsEnum } from 'src/app/commons/enum/first-experience-enum';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

enum CenterOptions {
  CENTER,
  RIGHT,
  LEFT,
  TOP,
  BOTTOM,
}
@Component({
  selector: 'app-first-experience-gallery-dialog',
  templateUrl: './first-experience-gallery-dialog.component.html',
  styleUrls: ['./first-experience-gallery-dialog.component.scss'],
})
export class FirstExperienceGalleryDialogComponent
  implements OnInit, AfterViewInit, OnDestroy {
  allSteps: typeof FirstExperienceGalleryStepsEnum =
    FirstExperienceGalleryStepsEnum;
  currentStep: FirstExperienceGalleryStepsEnum =
    FirstExperienceGalleryStepsEnum.FIRST;
  stepDescriptions = GalleryStepsConfig;

  /**
   * Tamaño del outline blanco al resaltar el elemento
   */
  outline: number;
  /**
   * Tamaño de la flecha del diálogo.
   */
  offSet: number;
  leftPosition: number;
  topPosition: number;
  initialDialogWidth: number;
  initialDialogHeight: number;

  buttonSlider: HTMLElement;
  buttonSearch: HTMLElement;
  buttonCategories: HTMLElement;
  buttonFilter: HTMLElement;
  buttonSelection: HTMLElement;
  buttonGallery: HTMLElement;

  initialX: number;
  initialY: number;
  finalX: number;
  finalY: number;
  halfWidthElement: number;
  halfHeightElement: number;

  constructor(
    public dialog: MatDialogRef<FirstExperienceGalleryDialogComponent>,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    //TODO - Cambiar por las clases/id correctos una vez remaquetada la galería.
    setTimeout(() => {
      this.setButtonSelectors();
      this.getInitialDialog();
    }, 1000);
  }

  private setButtonSelectors() {
    this.buttonSlider = document.querySelector('#gallery-selector-with-slider');
    this.buttonSearch = document.querySelector('.search-header');
    this.buttonCategories = document.querySelector(
      this.responsiveService.firstExperience600()
        ? '.fs-st'
        : '.gallery-categories'
    );
    this.buttonFilter = document.querySelector('#filter-btn');
    this.buttonSelection = document.querySelector('.fs-fd');
    this.buttonGallery = document.querySelector('.gallery-images-container');
  }

  ngOnDestroy(): void {
    this.removeAllClasses();
  }

  private removeAllClasses() {
    if (this.buttonSlider) {
      this.renderer.removeClass(this.buttonSlider, 'fe-mov-opt');
    }
    if (this.buttonSearch) {
      this.renderer.removeClass(this.buttonSearch, 'fe-mov-opt');
    }
    if (this.buttonCategories) {
      this.renderer.removeClass(this.buttonCategories, 'fe-mov-opt');
    }
    if (this.buttonFilter) {
      this.renderer.removeClass(this.buttonFilter, 'fe-mov-opt');
    }
    if (this.buttonSelection) {
      this.renderer.removeClass(this.buttonSelection, 'fe-mov-opt');
    }
    if (this.buttonGallery) {
      this.renderer.removeClass(this.buttonGallery, 'fe-mov-opt');
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.setButtonSelectors();
    this.getInitialDialog();
  }

  close() {
    this.dialog.close();
  }

  getStepDescription(step: FirstExperienceGalleryStepsEnum): string {
    const stepObject = this.stepDescriptions.find((obj) => obj.key === step);
    return stepObject ? stepObject.description : '';
  }

  nextStep() {
    if (this.currentStep < FirstExperienceGalleryStepsEnum.LAST) {
      this.currentStep++;
      this.getInitialDialog();
    } else {
      this.dialog.close(FirstExperienceGalleryStepsEnum.LAST);
    }
  }

  goToStep(stepKey: FirstExperienceGalleryStepsEnum) {
    if (stepKey < this.currentStep) {
      this.currentStep = stepKey;
      this.getInitialDialog();
    }
  }

  getInitialDialog() {
    const initialDialog = document.querySelector(
      '.FirstExperienceGalleryDialog'
    ) as HTMLElement;
    if (initialDialog) {
      setTimeout(() => {
        this.initialDialogWidth = initialDialog.offsetWidth;
        this.initialDialogHeight = initialDialog.offsetHeight;
        this.moveDialog();
      }, 1);
    }
  }

  moveDialog(): void {
    this.removeAllClasses();
    if (this.currentStep === FirstExperienceGalleryStepsEnum.FIRST) {
      this.moveInitial();
    } else if (this.currentStep === FirstExperienceGalleryStepsEnum.SECOND) {
      this.moveToSlider();
    } else if (this.currentStep === FirstExperienceGalleryStepsEnum.THIRD) {
      this.moveToSearch();
    } else if (this.currentStep === FirstExperienceGalleryStepsEnum.FOURTH) {
      this.moveToCategories();
    } else if (this.currentStep === FirstExperienceGalleryStepsEnum.FIFTH) {
      this.moveToFilter();
    } else if (this.currentStep === FirstExperienceGalleryStepsEnum.SIXTH) {
      this.moveToSelection();
    } else {
      this.moveToGallery();
    }
  }

  moveInitial() {
    this.leftPosition = (window.innerWidth - this.initialDialogWidth) / 2;
    this.topPosition = (window.innerHeight - this.initialDialogHeight) / 2;
    this.dialog.updatePosition({
      left: `${this.leftPosition}px`,
      top: `${this.topPosition}px`,
    });
  }

  moveToSlider() {
    if (this.buttonSlider) {
      this.renderer.addClass(this.buttonSlider, 'fe-mov-opt');
      this.getElementSize(this.buttonSlider);

      this.moveBottom(CenterOptions.CENTER);

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToSearch() {
    if (this.buttonSearch) {
      this.renderer.addClass(this.buttonSearch, 'fe-mov-opt');
      this.getElementSize(this.buttonSearch);

      if (this.responsiveService.firstExperience1920()) {
        this.moveBottom(CenterOptions.LEFT);
      } else if (this.responsiveService.firstExperience1280()) {
        this.moveTop(CenterOptions.LEFT);
      } else if (this.responsiveService.firstExperience1024()) {
        this.moveBottom(CenterOptions.LEFT);
      } else if (
        this.responsiveService.firstExperience830() &&
        !this.responsiveService.firstExperience834()
      ) {
        this.moveBottom(CenterOptions.LEFT);
      } else if (this.responsiveService.firstExperience600()) {
        this.moveBottom(CenterOptions.CENTER);
      } else {
        this.moveTop(CenterOptions.CENTER);
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToCategories() {
    if (this.buttonCategories) {
      this.renderer.addClass(this.buttonCategories, 'fe-mov-opt');
      this.getElementSize(this.buttonCategories);

      if (this.responsiveService.firstExperience1920()) {
        this.moveBottom(CenterOptions.CENTER);
      } else if (this.responsiveService.firstExperience1280()) {
        this.moveTop(CenterOptions.CENTER);
      } else if (
        this.responsiveService.firstExperience834() &&
        !this.responsiveService.firstExperience1024()
      ) {
        this.moveBottom(CenterOptions.LEFT);
      } else if (
        this.responsiveService.firstExperience600() &&
        !this.responsiveService.firstExperience830()
      ) {
        this.moveBottom(CenterOptions.LEFT);
      } else {
        this.moveBottom(CenterOptions.CENTER);
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToFilter() {
    if (this.buttonFilter) {
      this.renderer.addClass(this.buttonFilter, 'fe-mov-opt');
      this.getElementSize(this.buttonFilter);

      if (this.responsiveService.firstExperience1920()) {
        this.moveBottom(CenterOptions.CENTER);
      } else if (this.responsiveService.firstExperience1280()) {
        this.moveTop(CenterOptions.CENTER);
      } else if (
        this.responsiveService.firstExperience834() &&
        !this.responsiveService.firstExperience1024()
      ) {
        this.moveBottom(CenterOptions.CENTER);
      } else if (
        this.responsiveService.firstExperience600() &&
        !this.responsiveService.firstExperience830()
      ) {
        this.moveBottom(CenterOptions.CENTER);
      } else {
        this.moveBottom(CenterOptions.RIGHT);
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToSelection() {
    if (this.buttonSelection) {
      this.renderer.addClass(this.buttonSelection, 'fe-mov-opt');
      this.getElementSize(this.buttonSelection);

      if (this.responsiveService.firstExperience1920()) {
        this.moveBottom(CenterOptions.RIGHT);
      } else if (this.responsiveService.firstExperience1280()) {
        this.moveTop(CenterOptions.RIGHT);
      } else {
        this.moveBottom(CenterOptions.RIGHT);
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToGallery() {
    if (this.buttonGallery) {
      this.renderer.addClass(this.buttonGallery, 'fe-mov-opt');
      this.getElementSize(this.buttonGallery);

      this.moveTop(CenterOptions.CENTER);

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  getElementSize(element: HTMLElement): void {
    this.initialX = element.getBoundingClientRect().left;
    this.initialY = element.getBoundingClientRect().top;
    this.finalX = this.initialX + element.offsetWidth;
    this.finalY = this.initialY + element.offsetHeight;
    this.halfWidthElement = (this.finalX - this.initialX) / 2;
    this.halfHeightElement = (this.finalY - this.initialY) / 2;
    if (this.responsiveService.firstExperience1920()) {
      this.outline = 16;
      this.offSet = 18;
    } else if (this.responsiveService.firstExperience1280()) {
      this.outline = 8;
      this.offSet = 20;
    } else if (this.responsiveService.firstExperience1194()) {
      this.outline = 8;
      this.offSet = 24;
    } else if (this.responsiveService.firstExperience1024()) {
      this.outline = 8;
      this.offSet = 12;
    } else if (this.responsiveService.firstExperience834()) {
      this.outline = 8;
      this.offSet = 20;
    } else if (this.responsiveService.firstExperience830()) {
      this.outline = 4;
      this.offSet = 13;
    } else if (this.responsiveService.firstExperience600()) {
      this.outline = 8;
      this.offSet = 17;
    } else if (this.responsiveService.firstExperience414()) {
      this.outline = 8;
      this.offSet = 15;
    } else {
      this.outline = 4;
      this.offSet = 13;
    }
  }

  moveBottom(position: CenterOptions) {
    if (position === CenterOptions.CENTER) {
      this.leftPosition =
        this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
    } else if (position === CenterOptions.RIGHT) {
      this.leftPosition = this.finalX + this.outline - this.initialDialogWidth;
    } else {
      this.leftPosition = this.initialX - this.outline;
    }
    this.topPosition = this.finalY + this.outline + this.offSet;
  }

  moveTop(position: CenterOptions) {
    if (position === CenterOptions.CENTER) {
      this.leftPosition =
        this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
    } else if (position === CenterOptions.RIGHT) {
      this.leftPosition = this.finalX + this.outline - this.initialDialogWidth;
    } else {
      this.leftPosition = this.initialX - this.outline;
    }
    this.topPosition =
      this.initialY - this.initialDialogHeight - this.outline - this.offSet;
  }

  moveRight(position: CenterOptions) { }

  moveLeft(position: CenterOptions) { }
}
