<img class="close-icon" src="/assets/images/main/iota-cookies/close.svg" (click)="close(firstExperienceCloseEnum.CLOSE)">
<div *ngIf="changeTemplate; then template2 else template1"></div>

<ng-template #template1>
  <div class="welcome-container">
    <div class="welcome-title" [innerHTML]="welcomeTitle"></div>
    <div class="welcome-description" [innerHTML]="'first_experience_dialogs_Data2' | i18n"></div>
    <div class="welcome-start-btn" [innerHTML]="'first_experience_dialogs_Data3' | i18n" (click)="close(firstExperienceCloseEnum.NEXT)"></div>
    <div class="welcome-exit" [innerHTML]="'first_experience_dialogs_Data4' | i18n" (click)="close(firstExperienceCloseEnum.SKIP)"></div>
  </div>
</ng-template>

<ng-template #template2>
  <div class="welcome-steps-container" [ngClass]="{'steps-container-second': currentStep !== allSteps.FIRST}">
    <div class="triangle" *ngIf="currentStep !== allSteps.FIRST"
      [ngClass]="{
          'third-step': currentStep === allSteps.THIRD,
          'fourth-step': currentStep === allSteps.FOURTH,
          'fifth-step': currentStep === allSteps.FIFTH,
          'last-step': currentStep === allSteps.LAST
        }">
    </div>
    <div class="home-steps-description" [innerHTML]="getStepDescription(currentStep) | i18n"
    [ngClass]="{
      'last-description': currentStep === allSteps.LAST,
      'second-description': currentStep === allSteps.SECOND
      }"></div>
    <div class="steps">
      <div>{{ currentStep }}/6</div>
      <div class="step" *ngFor="let step of stepDescriptions" (click)="goToStep(step.key)" [ngClass]="{ 'current-step': step.key === currentStep, 'clickable': step.key < currentStep }"></div>
    </div>
    <div class="next-step" (click)="nextStep()" [ngClass]="{'last-next-button': currentStep === allSteps.LAST}">
      <div [innerHTML]="(currentStep === allSteps.LAST ? 'first_experience_dialogs_Data12' : 'first_experience_dialogs_Data6') | i18n"></div>
      <img *ngIf="currentStep !== allSteps.LAST" class="next-arrow" src="/assets/images/main/home/path-down-ico.svg">
    </div>
  </div>
</ng-template>
