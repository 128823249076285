export const IOTA_DOWNLOAD_PDF_I18N_ENTRIES = {
  iota_download_pdf_Data1: {
    es: '<p>Seguridad, integridad y privacidad</p>',
    en: '<p>Security, integrity, and privacy</p>',
    pt: '<p>Segurança, integridade e privacidade</p>',
    fr: '<p>Sécurité, intégrité et confidentialité</p>',
    eus: '<p>Segurtasuna, osotasuna eta pribatutasuna</p>',
    cat: '<p>Seguretat, integritat i privadesa</p>',
  },

  iota_download_pdf_Data2: {
    es: '<p>Informe de descarga de modelo 3D</p>',
    en: '<p>3D model download report</p>',
    pt: '<p>Relatório de transferência de modelo 3D</p>',
    fr: '<p>Rapport de téléchargement de modèle 3D</p>',
    eus: '<p>3D ereduaren deskarga txostena</p>',
    cat: '<p>Informe de baixada de model 3D</p>',
  },

  iota_download_pdf_Data3: {
    es: '<p>Información del modelo 3D</p>',
    en: '<p>3D model information</p>',
    pt: '<p>Informação do modelo 3D</p>',
    fr: '<p>Information du modèle 3D</p>',
    eus: '<p>3D ereduaren informazioa</p>',
    cat: '<p>Informació del model 3D</p>',
  },

  iota_download_pdf_Data4: {
    es: '<p>Nombre del modelo 3D:</p>',
    en: '<p>3D model name:</p>',
    pt: '<p>Nome do modelo 3D:</p>',
    fr: '<p>Nom du modèle 3D :</p>',
    eus: '<p>3D ereduaren izena:</p>',
    cat: '<p>Nom del model 3D:</p>',
  },

  iota_download_pdf_Data5: {
    es: '<p>Código asignado:</p>',
    en: '<p>Assigned code:</p>',
    pt: '<p>Código atribuído:</p>',
    fr: '<p>Code attribué :</p>',
    eus: '<p>Ezarritako kodea:</p>',
    cat: '<p>Codi assignat:</p>',
  },

  iota_download_pdf_Data6: {
    es: '<p>Descarga</p>',
    en: '<p>Download</p>',
    pt: '<p>Transferência</p>',
    fr: '<p>Téléchargement</p>',
    eus: '<p>Deskarga</p>',
    cat: '<p>Baixada</p>',
  },

  iota_download_pdf_Data7: {
    es: '<p>Usuario</p>',
    en: '<p>User</p>',
    pt: '<p>Utilizador</p>',
    fr: '<p>Utilisateur</p>',
    eus: '<p>Erabiltzailea</p>',
    cat: '<p>Usuari</p>',
  },

  iota_download_pdf_Data8: {
    es: '<p>IP DE ENVÍO DE DATOS:</p>',
    en: '<p>DATA TRANSFER IP:</p>',
    pt: '<p>IP DE ENVIO DE DADOS:</p>',
    fr: '<p>IP D’ENVOI DE DONNÉES :</p>',
    eus: '<p>DATUAK IGORTZEKO IP HELBIDEA:</p>',
    cat: '<p>IP D’ENVIAMENT DE DADES:</p>',
  },

  iota_download_pdf_Data9: {
    es: '<p>Fecha:</p>',
    en: '<p>Date:</p>',
    pt: '<p>Data:</p>',
    fr: '<p>Date :</p>',
    eus: '<p>Data:</p>',
    cat: '<p>Data:</p>',
  },

  iota_download_pdf_Data10: {
    es: '<p>Nombre:</p>',
    en: '<p>Name:</p>',
    pt: '<p>Nome:</p>',
    fr: '<p>Nom :</p>',
    eus: '<p>Izena:</p>',
    cat: '<p>Nom:</p>',
  },

  iota_download_pdf_Data11: {
    es: '<p>Hora:</p>',
    en: '<p>Time:</p>',
    pt: '<p>Hora:</p>',
    fr: '<p>Heure :</p>',
    eus: '<p>Ordua:</p>',
    cat: '<p>Hora:</p>',
  },

  iota_download_pdf_Data12: {
    es: '<p>Email:</p>',
    en: '<p>Email:</p>',
    pt: '<p>E-mail:</p>',
    fr: '<p>E-mail :</p>',
    eus: '<p>Helbide elektronikoa:</p>',
    cat: '<p>Adreça electrònica:</p>',
  },

  iota_download_pdf_Data13: {
    es: '<p>Acciones realizadas sobre el modelo 3D</p>',
    en: '<p>Actions performed on the 3D model</p>',
    pt: '<p>Ações realizadas sobre o modelo 3D</p>',
    fr: '<p>Actions réalisées sur le modèle 3D</p>',
    eus: '<p>3D ereduan burututako ekintzak</p>',
    cat: '<p>Accions efectuades sobre el model 3D</p>',
  },

  iota_download_pdf_Data14: {
    es: '<p>Usuario</p>',
    en: '<p>User</p>',
    pt: '<p>Utilizador</p>',
    fr: '<p>Utilisateur</p>',
    eus: '<p>Erabiltzailea</p>',
    cat: '<p>Usuari</p>',
  },

  iota_download_pdf_Data15: {
    es: '<p>Acción</p>',
    en: '<p>Action</p>',
    pt: '<p>Ação</p>',
    fr: '<p>Action</p>',
    eus: '<p>Ekintza</p>',
    cat: '<p>Acció</p>',
  },

  iota_download_pdf_Data16: {
    es: '<p>Fecha</p>',
    en: '<p>Date</p>',
    pt: '<p>Data</p>',
    fr: '<p>Date</p>',
    eus: '<p>Data</p>',
    cat: '<p>Data</p>',
  },

  iota_download_pdf_Data17: {
    es: '<p>Formato descarga</p>',
    en: '<p>Download format</p>',
    pt: '<p>Formato de transferência</p>',
    fr: '<p>Format de téléchargement</p>',
    eus: '<p>Deskargaren formatua</p>',
    cat: '<p>Format de baixada</p>',
  },

  iota_download_pdf_Data18: {
    es: '<p>Ficheros descargados</p>',
    en: '<p>Downloaded files</p>',
    pt: '<p>Ficheiros transferidos</p>',
    fr: '<p>Fichiers téléchargés</p>',
    eus: '<p>Deskargatutako fitxategiak</p>',
    cat: '<p>Fitxers baixats</p>',
  },

  iota_download_pdf_Data19: {
    es: '<p>Nodo de seguridad</p>',
    en: '<p>Security node</p>',
    pt: '<p>Nó de segurança</p>',
    fr: '<p>Nœud de sécurité</p>',
    eus: '<p>Segurtasun nodoa</p>',
    cat: '<p>Node de seguretat</p>',
  },

  iota_download_pdf_Data20: {
    es: '<p>Tipo de modelo 3D:</p>',
    en: '<p>Type of 3D model:</p>',
    pt: '<p>Tipo de modelo 3D:</p>',
    fr: '<p>Type de modèle 3D :</p>',
    eus: '<p>3D eredu mota:</p>',
    cat: '<p>Tipus de model 3D:</p>',
  },

  iota_download_pdf_Data21: {
    es: '<p>Formato de descarga:</p>',
    en: '<p>Download format:</p>',
    pt: '<p>Formato de transferência:</p>',
    fr: '<p>Format de téléchargement :</p>',
    eus: '<p>Deskargaren formatua</p>',
    cat: '<p>Format de baixada:</p>',
  },

  iota_download_pdf_Data22: {
    es: '<p>Nº puntos/vértices</p>',
    en: '<p>Number of points/vertices</p>',
    pt: '<p>N.º pontos/vértices</p>',
    fr: '<p>Nº de points/sommets</p>',
    eus: '<p>Puntu/erpin kopurua</p>',
    cat: '<p>Nre. punts / vèrtexs</p>',
  },

  iota_download_pdf_Data23: {
    es: '<p>Nº polígonos</p>',
    en: '<p>Number of polygons</p>',
    pt: '<p>N.º polígonos</p>',
    fr: '<p>Nº de polygones</p>',
    eus: '<p>Poligono kopurua</p>',
    cat: '<p>Nre. polígons</p>',
  },

  iota_download_pdf_Data24: {
    es: '<p>Total:</p>',
    en: '<p>Total:</p>',
    pt: '<p>Total:</p>',
    fr: '<p>Total :</p>',
    eus: '<p>Guztira:</p>',
    cat: '<p>Total:</p>',
  },

  iota_download_pdf_Data25: {
    es: '<p>Inserción en nodo IOTA</p>',
    en: '<p>Insertion in IOTA node</p>',
    pt: '<p>Inserção em nó IOTA</p>',
    fr: '<p>Insertion dans le nœud IOTA</p>',
    eus: '<p>IOTA nodoan sarrera</p>',
    cat: '<p>Inserció en node IOTA</p>',
  },

  iota_download_pdf_Data26: {
    es: '<p>Sí</p>',
    en: '<p>Yes</p>',
    pt: '<p>Sim</p>',
    fr: '<p>Oui</p>',
    eus: '<p>Bai</p>',
    cat: '<p>Sí</p>',
  },

  iota_download_pdf_Data27: {
    es: '<p>No</p>',
    en: '<p>No</p>',
    pt: '<p>Não</p>',
    fr: '<p>Non</p>',
    eus: '<p>Ez</p>',
    cat: '<p>No</p>',
  },

  iota_download_pdf_Data28: {
    es: '<p>Fecha/Hora de inserción</p>',
    en: '<p>Date/Time of insertion</p>',
    pt: '<p>Data/Hora de inserção</p>',
    fr: '<p>Date/heure d’insertion</p>',
    eus: '<p>Nodoan sartzeko data/ordua</p>',
    cat: '<p>Data i hora d’inserció</p>',
  },

  iota_download_pdf_Data29: {
    es: '<p>Ficheros descargados</p>',
    en: '<p>Downloaded files</p>',
    pt: '<p>Ficheiros transferidos</p>',
    fr: '<p>Fichiers téléchargés</p>',
    eus: '<p>Deskargatutako fitxategiak</p>',
    cat: '<p>Fitxers baixats</p>',
  },

  iota_download_pdf_Data30: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  iota_download_pdf_Data31: {
    es: '<p>Tamaño</p>',
    en: '<p>Size</p>',
    pt: '<p>Tamanho</p>',
    fr: '<p>Taille</p>',
    eus: '<p>Tamaina</p>',
    cat: '<p>Mida</p>',
  },

  iota_download_pdf_Data32: {
    es: '<p>Hash</p>',
    en: '<p>Hash</p>',
    pt: '<p>Hash</p>',
    fr: '<p>Hash</p>',
    eus: '<p>Hash</p>',
    cat: '<p>Hash</p>',
  },

  iota_download_pdf_Data33: {
    es: '<p>eyesCloud3D trabaja con un protocolo de seguridad IOTA basado en Tangle</p>',
    en: '<p>eyesCloud3D works with an IOTA security protocol based on Tangle</p>',
    pt: '<p>eyesCloud3D trabalha com um protocolo de segurança IOTA baseado em Tangle</p>',
    fr: '<p>eyesCloud3D utilise un protocole de sécurité IOTA basé sur Tangle</p>',
    eus: '<p>eyesCloud3D-k Tangle teknologian oinarritutako IOTA segurtasun protokoloa erabiltzen du.</p>',
    cat: '<p>eyesCloud3D treballa amb un protocol de seguretat IOTA basat en Tangle</p>',
  },

  iota_download_pdf_Data34: {
    es: '<p>N/D</p>',
    en: '<p>N/D</p>',
    pt: '<p>N/D</p>',
    fr: '<p>N/D</p>',
    eus: '<p>Ez dago eskuragarri</p>',
    cat: '<p>N/D</p>',
  },
};
