export const HOME_I18N_ENTRIES = {
  home_Data1: {
    es: '<p>Haz tu modelo 3D</p>',
    en: '<p>Make your own 3D model</p>',
    pt: '<p>Crie o seu modelo 3D</p>',
    fr: '<p>Créez votre modèle 3D</p>',
    eus: '<p>Sortu zure 3D eredua</p>',
    cat: '<p>Fes el teu model 3D</p>',
  },

  home_Data2: {
    es: '<p>Galería</p>',
    en: '<p>Gallery</p>',
    pt: '<p>Galeria</p>',
    fr: '<p>Galerie</p>',
    eus: '<p>Galeria</p>',
    cat: '<p>Galeria</p>',
  },

  home_Data3: {
    es: '<p>Modelos 3D en proceso</p>',
    en: '<p>3D Models in progress</p>',
    pt: '<p>Modelos 3D em processamento</p>',
    fr: '<p>Modèles 3D en cours</p>',
    eus: '<p>Abian dauden 3D ereduak</p>',
    cat: '<p>Models 3D en curs</p>',
  },

  home_Data4: {
    es: '<p>4U</p>',
    en: '<p>4U</p>',
    pt: '<p>4U</p>',
    fr: '<p>4U</p>',
    eus: '<p>4U</p>',
    cat: '<p>4U</p>',
  },

  home_Data5: {
    es: '<p>Tarifas</p>',
    en: '<p>Plans</p>',
    pt: '<p>Tarifas</p>',
    fr: '<p>Tarifs</p>',
    eus: '<p>Tarifak</p>',
    cat: '<p>Tarifes</p>',
  },

  home_Data6: {
    es: '<p>Modelos 3D destacados</p>',
    en: '<p>Featured 3D models</p>',
    pt: '<p>Modelos 3D destacados</p>',
    fr: '<p>Modèles 3D en vedette</p>',
    eus: '<p>Nabarmendutako 3D ereduak</p>',
    cat: '<p>Models 3D destacats</p>',
  },

  home_Data7: {
    es: '<p>Ir a galería</p>',
    en: '<p>Go to gallery</p>',
    pt: '<p>Ir para a galeria</p>',
    fr: '<p>Aller à la galerie</p>',
    eus: '<p>Joan galeriara</p>',
    cat: '<p>Ves a la Galeria</p>',
  },

  home_Data8: {
    es: '<p>Sube tus archivos o captura directamente</p><p>con la cámara de eyescloud3D.com...</p>',
    en: '<p>Upload your files or capture photos and videos</p><p>with the eyescloud3D.com camera.</p>',
    pt: '<p>Carregue os seus ficheiros ou capte diretamente</p><p>com a câmara de eyescloud3D.com...</p>',
    fr: '<p>Téléchargez vos fichiers ou capturez directement avec l’appareil photo de eyescloud3D.com...</p>',
    eus: '<p>Kargatu zure fitxategiak edo kapturatu irudiak zuzenean</p><p>eyescloud3D.com-en kamera erabiliz.</p>',
    cat: '<p>Carrega els teus fitxers o fes una captura directament</p><p>amb la càmera d\'eyescloud3D.com...</p>',
  },

  home_Data9: {
    es: '<p>Tu portfolio, modelos más populares,</p><p>tus modelos 3D favoritos…</p>',
    en: '<p>Your portfolio, the most popular models,</p><p>your favorite 3D models, and more.</p>',
    pt: '<p>O seu portfólio, modelos mais populares,</p><p>os seus modelos 3D favoritos…</p>',
    fr: '<p>Votre portfolio, les modèles les plus populaires, vos modèles 3D préférés…</p>',
    eus: '<p>Zure zorroa, eredu ezagunenak,</p><p>zure 3D eredurik gogokoenak...</p>',
    cat: '<p>La teva cartera, els models més populars,</p><p>els teus models 3D preferits…</p>',
  },
  home_Data10: {
    es: '<p>Explora</p>',
    en: '<p>Explore</p>',
    pt: '<p>Explorar</p>',
    fr: '<p>Découvrez</p>',
    eus: '<p>Esploratu</p>',
    cat: '<p>Explora</p>',
  },
};
