export const TWO_FACTOR_I18N_ENTRIES = {

  verificacion_Dos_Pasos_data1: {
    es: '<p>Verificación en dos pasos</p>',
    en: '<p>Two-step verification</p>',
    pt: '<p>Verificação em dois passos</p>',
    fr: '<p>Vérification en deux étapes</p>',
    eus: '<p>Bi urratseko egiaztapena</p>',
    cat: '<p>Verificació en dos passos</p>',
  },

  verificacion_Dos_Pasos_data2: {
    es: '<p>Para proteger tu cuenta, eyesCloud3d quiere verificar</p><p>que eres tu quién está intentanto iniciar sesión</p>',
    en: '<p>To protect your account, eyesCloud3d wants to verify</p><p>that you are the one trying to log in</p>',
    pt: '<p>Para proteger sua conta, a eyesCloud3d pretende verificar</p><p>se é o utilizador que está a tentar iniciar sessão</p>',
    fr: '<p>Afin de protéger votre compte, eyesCloud3d veut s’assurer que c’est bien vous qui essayez de vous connecter</p>',
    eus: '<p>Zure kontua babesteko, eyesCloud3d-k egiaztatu nahi du</p><p>zu zarela saioa hasten saiatzen ari dena</p>',
    cat: '<p>Per protegir el teu compte, eyesCloud3d vol verificar</p><p>que ets tu qui està intentant iniciar sessió </p>',
  },

  verificacion_Dos_Pasos_data3: {
    es: '<p>Elige cómo quieres iniciar sesión:</p>',
    en: '<p>Choose how you want to log in:</p>',
    pt: '<p>Escolha como pretende iniciar sessão:</p>',
    fr: '<p>Choisissez comment vous voulez vous connecter :</p>',
    eus: '<p>Aukeratu saioa hasteko modua:</p>',
    cat: '<p>Tria com vols iniciar sessió:</p>',
  },

  verificacion_Dos_Pasos_data4: {
    es: '<p>Recibe un <span>código de verificación</span> en el número *** ** ** %X%.</p>',
    en: '<p>Receive a <span>verification code</span> via phone *** ** ** %X%.</p>',
    pt: '<p>Receba um <span>código de verificação</span> no número *** ** ** %X%.</p>',
    fr: '<p>Recevez un <span>code de vérification</span> sur le numéro *** ** ** %X%.</p>',
    eus: '<p>Jaso <span>egiaztapen kodea</span> *** ** ** %X% zenbakian.</p>',
    cat: '<p>Rep un <span>codi de verificació</span> al número *** ** ** %X%.</p>',
  },

  verificacion_Dos_Pasos_data5: {
    es: '<p>No tienes añadido ningún número de teléfono.</p>',
    en: '<p>You have not added a phone number.</p>',
    pt: '<p>Não adicionou nenhum número de telefone.</p>',
    fr: '<p>Vous n\'avez ajouté aucun numéro de téléphone.</p>',
    eus: '<p>Ez duzu telefono zenbakirik gehitu.</p>',
    cat: '<p>No tens afegit cap número de telèfon.</p>',
  },

  verificacion_Dos_Pasos_data6: {
    es: '<p>Recibe un <span>código de verificación</span> en</p><p>el correo electrónico especificado en la parte superior.</p>',
    en: '<p>Receive a <span>verification code</span> via</p><p>the email specified at the top.</p>',
    pt: '<p>Receba um <span>código de verificação</span> no</p><p>e-mail especificado no topo.</p>',
    fr: '<p>Recevez un <span>code de vérification</span> par</p><p>e-mail à l’adresse spécifiée en haut.</p>',
    eus: '<p>Jaso <span>egiaztapen kodea</span> gorago zehaztu duzun</p><p>helbide elektronikoan.</p>',
    cat: '<p>Rep un <span>codi de verificació</span> a</p><p>l\'adreça electrònica especificada a la part superior.</p>',
  },

  verificacion_Dos_Pasos_data7: {
    es: '<p>O prueba la <span>recuperación de la cuenta.</span></p>',
    en: '<p>Or try to <span>recover your account.</span></p>',
    pt: '<p>Ou tente a <span>recuperação da conta.</span></p>',
    fr: '<p>Ou tentez la <span>récupération du compte.</span></p>',
    eus: '<p>Edo saiatu <span>kontua berreskuratzen.</span></p>',
    cat: '<p>O prova la <span>recuperació del compte.</span></p>',
  },

  verificacion_Dos_Pasos_data8: {
    es: '<p>Términos de uso</p>',
    en: '<p>Terms of use</p>',
    pt: '<p>Termos de utilização</p>',
    fr: '<p>Conditions générales d\'utilisation</p>',
    eus: '<p>Erabilera baldintzak</p>',
    cat: '<p>Termes d\'ús</p>',
  },

  verificacion_Dos_Pasos_data9: {
    es: '<p>Política de privacidad</p>',
    en: '<p>Privacy policy</p>',
    pt: '<p>Política de privacidade</p>',
    fr: '<p>Politique de confidentialité</p>',
    eus: '<p>Pribatutasun politika</p>',
    cat: '<p>Política de privadesa</p>',
  },

  verificacion_Dos_Pasos_data10: {
    es: '<p>Política de cookies</p>',
    en: '<p>Cookies policy</p>',
    pt: '<p>Política de cookies</p>',
    fr: '<p>Politique de cookies</p>',
    eus: '<p>Cookie politika</p>',
    cat: '<p>Política de galetes</p>',
  },

  verificacion_Dos_Pasos_data11: {
    es: '<p>Verificación en dos pasos</p>',
    en: '<p>Two-step verification</p>',
    pt: '<p>Verificação em dois passos</p>',
    fr: '<p>Vérification en deux étapes</p>',
    eus: '<p>Bi urratseko egiaztapena</p>',
    cat: '<p>Verificació en dos passos</p>',
  },

  verificacion_Dos_Pasos_data12: {
    es: '<p>Introduce el código de verificación</p><p>que hemos enviado al correo %X%.</p>',
    en: '<p>Please enter the verification code</p><p>we have sent to the email %X%.</p>',
    pt: '<p>Introduza o código de verificação</p><p>que enviámos para o e-mail %X%.</p>',
    fr: '<p>Saisissez le code de vérification</p><p>que nous avons envoyé à l\'e-mail %X%.</p>',
    eus: '<p>Sartu %X% helbide elektronikora</p><p>bidali dugun egiaztapen-kodea.</p>',
    cat: '<p>Introdueix el codi de verificació</p><p>que hem enviat a l\'adreça %X%.</p>',
  },

  verificacion_Dos_Pasos_data13: {
    es: '<p>Introduce el código de verificación</p><p>que hemos enviado al número de teléfono *** ** ** %X%.</p>',
    en: '<p>Please enter the verification code</p><p>we have sent to the phone number *** ** ** %X%.</p>',
    pt: '<p>Introduza o código de verificação</p><p>que enviámos para o número de telefone *** ** ** %X%.</p>',
    fr: '<p>Saisissez le code de vérification</p><p>que nous avons envoyé au numéro de téléphone *** ** ** %X%.</p>',
    eus: '<p>Sartu *** ** ** %X% telefono zenbakira</p><p>bidali dugun egiaztapen-kodea.</p>',
    cat: '<p>Introdueix el codi de verificació</p><p>que hem enviat al número de telèfon *** ** ** %X%.</p>',
  },

  verificacion_Dos_Pasos_data14: {
    es: '<p>Código de verificación</p>',
    en: '<p>Verification code</p>',
    pt: '<p>Código de verificação</p>',
    fr: '<p>Code de vérification</p>',
    eus: '<p>Egiaztapen kodea</p>',
    cat: '<p>Codi de verificació</p>',
  },

  verificacion_Dos_Pasos_data15: {
    es: '<p>El código introducido no es correcto</p>',
    en: '<p>The code entered is incorrect</p>',
    pt: '<p>O código introduzido não está correto</p>',
    fr: '<p>Le code saisi n\'est pas correct</p>',
    eus: '<p>Sartutako kodea ez da zuzena</p>',
    cat: '<p>El codi introduït no és correcte</p>',
  },

  verificacion_Dos_Pasos_data16: {
    es: '<p>SIGUIENTE</p>',
    en: '<p>NEXT</p>',
    pt: '<p>SEGUINTE</p>',
    fr: '<p>SUIVANT</p>',
    eus: '<p>AURRERA</p>',
    cat: '<p>SEGÜENT</p>',
  },

  verificacion_Dos_Pasos_data17: {
    es: '<p>Recupera tu cuenta</p>',
    en: '<p>Recover your account</p>',
    pt: '<p>Recupere a sua conta</p>',
    fr: '<p>Récupérez votre compte</p>',
    eus: '<p>Berreskuratu zure kontua</p>',
    cat: '<p>Recupera el teu compte</p>',
  },

  verificacion_Dos_Pasos_data18: {
    es: '<p>Escribe una dirección de correo electrónico</p><p>que sea diferente a la que intentas recuperar</p>',
    en: '<p>Write an email address</p><p>that is different from the one you are trying to recover</p>',
    pt: '<p>Escreva um endereço de e-mail</p><p>que seja diferente do que está a tentar recuperar</p>',
    fr: '<p>Saisissez une adresse e-mail</p><p>différente de celle que vous essayez de récupérer</p>',
    eus: '<p>Idatzi berreskuratu nahi duzun</p><p>helbide elektronikoa ez den beste bat</p>',
    cat: '<p>Escriu una adreça de correu electrònic</p><p>que sigui diferent de la que intentes recuperar</p>',
  },

  verificacion_Dos_Pasos_data19: {
    es: '<p>Email</p>',
    en: '<p>Email</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>E-mail</p>',
    eus: '<p>Helbide elektronikoa</p>',
    cat: '<p>Adreça electrònica</p>',
  },

  verificacion_Dos_Pasos_data20: {
    es: '<p>SIGUIENTE</p>',
    en: '<p>NEXT</p>',
    pt: '<p>SEGUINTE</p>',
    fr: '<p>SUIVANT</p>',
    eus: '<p>AURRERA</p>',
    cat: '<p>SEGÜENT</p>',
  },

  verificacion_Dos_Pasos_data21: {
    es: '<p>Marca la casilla que se muestra a continuación.</p>',
    en: '<p>Check the box shown below.</p>',
    pt: '<p>Marque a caixa apresentada a seguir.</p>',
    fr: '<p>Cocher la case ci-dessous.</p>',
    eus: '<p>Markatu jarraian agertzen den laukitxoa.</p>',
    cat: '<p>Marca la casella que es mostra a continuació.</p>',
  },

  verificacion_Dos_Pasos_data22: {
    es: '<p>Introducir caracteres</p>',
    en: '<p>Enter characters</p>',
    pt: '<p>Introduza caracteres</p>',
    fr: '<p>Entrer des caractères</p>',
    eus: '<p>Sartu karaktereak</p>',
    cat: '<p>Introdueix caràcters</p>',
  },

  verificacion_Dos_Pasos_data23: {
    es: '<p>Introduce el código de verificación</p><p>que hemos enviado al correo %X%.</p>',
    en: '<p>Please enter the verification code</p><p>we have sent to the email %X%.</p>',
    pt: '<p>Introduza o código de verificação</p><p>que enviámos para o e-mail %X%.</p>',
    fr: '<p>Saisissez le code de vérification</p><p>que nous avons envoyé à l\'e-mail %X%.</p>',
    eus: '<p>Sartu %X% helbide elektronikora</p><p>bidali dugun egiaztapen-kodea.</p>',
    cat: '<p>Introdueix el codi de verificació</p><p>que hem enviat a l\'adreça %X%.</p>',
  },

  verificacion_Dos_Pasos_data24: {
    es: '<p>Código de verificación</p>',
    en: '<p>Verification code</p>',
    pt: '<p>Código de verificação</p>',
    fr: '<p>Code de vérification</p>',
    eus: '<p>Egiaztapen kodea</p>',
    cat: '<p>Codi de verificació</p>',
  },

  verificacion_Dos_Pasos_data25: {
    es: '<p>El código introducido no es correcto</p>',
    en: '<p>The code entered is incorrect</p>',
    pt: '<p>O código introduzido não está correto</p>',
    fr: '<p>Le code saisi n\'est pas correct</p>',
    eus: '<p>Sartutako kodea ez da zuzena</p>',
    cat: '<p>El codi introduït no és correcte</p>',
  },

  verificacion_Dos_Pasos_data26: {
    es: '<p>Escribe un número de teléfono</p><p>para asociarlo a tu cuenta.</p>',
    en: '<p>Write a phone number</p><p>to associate it with your account.</p>',
    pt: '<p>Escreva um número de telefone</p><p>para associá-lo à sua conta.</p>',
    fr: '<p>Saisissez un numéro de téléphone</p><p>pour l\'associer à votre compte.</p>',
    eus: '<p>Idatzi zure kontuarekin lotu nahi</p><p>duzun telefono zenbakia.</p>',
    cat: '<p>Escriu un número de telèfon</p><p>per associar-lo al compte.</p>',
  },

  verificacion_Dos_Pasos_data27: {
    es: '<p>Teléfono</p>',
    en: '<p>Phone</p>',
    pt: '<p>Número de telefone</p>',
    fr: '<p>Téléphone</p>',
    eus: '<p>Telefonoa</p>',
    cat: '<p>Telèfon</p>',
  },

  verificacion_Dos_Pasos_data28: {
    es: '<p>Tu cuenta ha sido recuperada correctamente,</p><p>por favor, inicia sesión.</p>',
    en: '<p>Your account has been recovered correctly,</p><p>please log in.</p>',
    pt: '<p>A sua conta foi recuperada corretamente,</p><p>inicie sessão.</p>',
    fr: '<p>Votre compte a été récupéré correctement,</p><p>veuillez vous connecter.</p>',
    eus: '<p>Zure kontua ondo berreskuratu da,</p><p>hasi saioa, mesedez.</p>',
    cat: '<p>El compte ha estat recuperat correctament.</p><p>Si us plau, inicia sessió.</p>',
  },

  verificacion_Dos_Pasos_data29: {
    es: '<p>INICIAR SESIÓN</p>',
    en: '<p>LOG IN</p>',
    pt: '<p>INICIAR SESSÃO</p>',
    fr: '<p>SE CONNECTER</p>',
    eus: '<p>HASI SAIOA</p>',
    cat: '<p>INICIA SESSIÓ</p>',
  },

  verificacion_Dos_Pasos_data30: {
    es: '<p>Activar doble factor de verificación</p>',
    en: '<p>Activate two-factor verification</p>',
    pt: '<p>Ativar verificação de dois fatores</p>',
    fr: '<p>Activer la vérification en deux étapes</p>',
    eus: '<p>Aktibatu bi faktoreko egiaztapena</p>',
    cat: '<p>Activa el doble factor de verificació</p>',
  },
  verificacion_Dos_Pasos_data31: {
    es: '<p>*Rellena correctamente todos los campos obligatorios para poder guardar.</p>',
    en: '<p>*All required fields must be filled in correctly before saving.</p>',
    pt: '<p>*Preencha corretamente todos os campos obrigatórios para poder guardar.</p>',
    fr: '<p>* Veuillez remplir correctement tous les champs obligatoires pour sauvegarder.</p>',
    eus: '<p>*Bete itzazu behar bezala eremu guztiak gorde ahal izateko.</p>',
    cat: '<p>* Emplena tots els camps obligatoris correctament per poder desar.</p>',
  },
  verificacion_Dos_Pasos_data32: {
    es: '<p>Estás a un paso de activar la verificación en dos pasos.</p>',
    en: '<p>You are one step away from activating two-step verification.</p>',
    pt: '<p>Está a um passo de ativar a verificação em dois passos.</p>',
    fr: '<p>Vous allez activer la vérification en deux étapes.</p>',
    eus: '<p>Bi urratseko egiaztapena aktibatzear zaude.</p>',
    cat: '<p>Estàs a punt d\'activar la verificació en dos passos.</p>',
  },
  verificacion_Dos_Pasos_data33: {
    es: '<p>Esto significa que, además de tu contraseña,<p></p>necesitarás un segundo factor de autenticación<p></p>para acceder a tu cuenta cada vez que inicies sesión.</p>',
    en: '<p>This means that, in addition to your password,<p></p>you will need a second authentication factor<p></p>to access your account each time you log in.</p>',
    pt: '<p>Isto significa que, além da sua palavra-passe,<p></p>necessitará de um segundo fator de autenticação<p></p> para aceder à sua conta sempre que iniciar sessão.</p>',
    fr: '<p>Cela signifie qu\'en plus de votre mot de passe,<p></p>vous aurez besoin d\'un deuxième facteur d\'authentification<p></p>chaque fois que vous vous connecterez à votre compte.</p>',
    eus: '<p>Horrek esan nahi du, zure pasahitzaz gain,<p></p>beste autentifikazio-faktore bat beharko duzula zure kontuan<p></p>sartzeko saioa hasten duzun bakoitzean.</p>',
    cat: '<p>Això vol dir que, a banda de la contrasenya,<p></p>necessitaràs un segon factor d\'autenticació<p></p>per accedir al compte cada cop que iniciïs la sessió.</p>',
  },
  verificacion_Dos_Pasos_data34: {
    es: '<p>Estás a un paso de desactivar la verificación en dos pasos.</p>',
    en: '<p>You are one step away from deactivating two-step verification.</p>',
    pt: '<p>Está a um passo de desativar a verificação em dois passos.</p>',
    fr: '<p>Vous allez désactiver la vérification en deux étapes.</p>',
    eus: '<p>Bi urratseko egiaztapena desaktibatzear zaude.</p>',
    cat: '<p>Estàs a punt de desactivar la verificació en dos passos.</p>',
  },
  verificacion_Dos_Pasos_data35: {
    es: '<p>Esto significa que, además de tu contraseña,<p></p>no necesitarás un segundo factor de autenticación<p></p>para acceder a tu cuenta cada vez que inicies sesión.</p>',
    en: '<p>This means that, aside from your password,<p></p>you will not need a second authentication factor<p></p>to access your account each time you log in.</p>',
    pt: '<p>Isto significa que, além da sua palavra-passe,<p></p>não necessitará de um segundo fator de autenticação<p></p>para aceder à sua conta sempre que iniciar sessão.</p>',
    fr: '<p>Cela signifie qu\'en plus de votre mot de passe,<p></p>vous n\'aurez pas besoin d\'un deuxième facteur d\'authentification<p></p>chaque fois que vous vous connecterez à votre compte.</p>',
    eus: '<p>Horrek esan nahi du, zure pasahitzaz gain,<p></p>ez duzula beste autentifikazio-faktorerik beharko zure kontuan<p></p>sartzeko saioa hasten duzun bakoitzean.</p>',
    cat: '<p>Això vol dir que, a banda de la contrasenya,<p></p>ja no necessitaràs un segon factor d\'autenticació<p></p>per accedir al compte cada cop que iniciïs la sessió.</p>',
  },
  verificacion_Dos_Pasos_data36: {
    es: '<p>Introduce tu número de teléfono.</p>',
    en: '<p>Enter your phone number.</p>',
    pt: '<p>Introduza o seu número de telefone.</p>',
    fr: '<p>Saisissez votre numéro de téléphone.</p>',
    eus: '<p>Idatzi zure telefono zenbakia.</p>',
    cat: '<p>Introdueix el teu número de telèfon.</p>',
  },
  verificacion_Dos_Pasos_data37: {
    es: '<p>Número de teléfono</p>',
    en: '<p>Phone number</p>',
    pt: '<p>Número de telefone</p>',
    fr: '<p>Numéro de téléphone</p>',
    eus: '<p>Telefono zenbakia</p>',
    cat: '<p>Número de telèfon</p>',
  },
  verificacion_Dos_Pasos_data38: {
    es: '<p>El número de teléfono que has introducido es incorrecto. Por favor, asegúrate de introducir tu número correctamente.</p>',
    en: '<p>The phone number you have entered is incorrect. Please make sure to enter your number correctly.</p>',
    pt: '<p>O número de telefone que introduziu está incorreto. Certifique-se de introduzir o seu número corretamente.</p>',
    fr: '<p>Le numéro de téléphone que vous avez saisi est incorrect. Veuillez vous assurer de saisir correctement votre numéro.</p>',
    eus: '<p>Sartu duzun telefono zenbakia ez da zuzena. Mesedez, ziurtatu zenbakia ondo idatzi duzula.</p>',
    cat: '<p>El número de telèfon que has introduït és incorrecte. Assegura\'t d\'introduir el número correctament.</p>',
  },
  verificacion_Dos_Pasos_data39: {
    es: '<p>Selecciona cómo prefieres verificar tu</p><p>identidad en dos pasos al iniciar sesión.</p>',
    en: '<p>Select how you prefer to verify your</p><p>identity in two steps when logging in.</p>',
    pt: '<p>Selecione a forma de verificação da</p><p>sua identidade em dois passos ao iniciar sessão.</p>',
    fr: '<p>Sélectionnez comment vous préférez vérifier votre</p><p>identité en deux étapes lors de la connexion.</p>',
    eus: '<p>Aukeratu, saio hastean, nola egiaztatu</p><p>nahi duzun zure identitatea bi urratsetan.</p>',
    cat: '<p>Selecciona com prefereixes verificar la teva</p><p>identitat en dos passos en iniciar la sessió.</p>',
  },
  verificacion_Dos_Pasos_data40: {
    es: '<p>POR CORREO</p>',
    en: '<p>BY EMAIL</p>',
    pt: '<p>POR E-MAIL</p>',
    fr: '<p>PAR E-MAIL</p>',
    eus: '<p>POSTA BIDEZ</p>',
    cat: '<p>PER CORREU</p>',
  },
  verificacion_Dos_Pasos_data41: {
    es: '<p>POR TELÉFONO</p>',
    en: '<p>BY PHONE</p>',
    pt: '<p>POR TELEFONE</p>',
    fr: '<p>PAR TÉLÉPHONE</p>',
    eus: '<p>TELEFONO BIDEZ</p>',
    cat: '<p>PER TELÈFON</p>',
  },
  verificacion_Dos_Pasos_data42: {
    es: '<p>Límite de intentos alcanzado</p>',
    en: '<p>You have reached the verification attempt limit</p>',
    pt: '<p>Limite de tentativas atingido</p>',
    fr: '<p>Limite de tentatives atteinte</p>',
    eus: '<p>Saiakera kopurua gainditu duzu</p>',
    cat: '<p>S\'ha assolit el límit d\'intents</p>',
  },
  verificacion_Dos_Pasos_data43: {
    es: '<p>Parece que has excedido el número máximo de intentos para</p><p>recibir el código de verificación en tu móvil. Si necesitas</p><p>asistencia, contáctanos en <span class="i18n-underline">customercare@eyescloud3d.com</span></p><p>o inténtalo más tarde.</p>',
    en: '<p>It seems that you have exceeded the maximum number of attempts to</p><p>receive the verification code on your phone.If you need</p><p>assistance, please contact us at <span class="i18n-underline">customercare@eyescloud3d.com</span>,</p><p>or try again later.</p>',
    pt: '<p>Parece que excedeu o número máximo de tentativas para</p><p>receber o código de verificação no seu telemóvel.Se necessitar</p><p>de ajuda, contacte - nos através do e-mail <span class="i18n-underline">customercare@eyescloud3d.com</span> ou tente mais tarde.</p>',
    fr: '<p>Il semble que vous ayez dépassé le nombre maximum de tentatives pour recevoir le code de vérification sur votre téléphone portable. Si vous avez besoin d’aide,</p><p>veuillez nous contacter à l’adresse <span class="i18n-underline">customercare@eyescloud3d.com</span></p><p>ou réessayez plus tard.</p>',
    eus: '<p>Egiaztapen kodea mugikorrean jasotzeko gehienezko saiakera kopurua gainditu duzula dirudi. Laguntza behar baduzu,</p><p>jarri gurekin harremanetan <span class="i18n-underline">customercare@eyescloud3d.com</span> helbidean</p><p>edo saiatu geroago.</p>',
    cat: '<p>Sembla que has assolit la quantitat màxima d’intents per</p><p>rebre el codi de verificació al mòbil.Si necessites</p><p>ajuda, posa’t en contacte amb nosaltres a <span class="i18n-underline">customercare@eyescloud3d.com</span></p><p>o torna a provar-ho més tard.</p>',
  },
  correctMessageSaveText: {
    es: '<p>Tus datos se han guardado correctamente.</p>',
    en: '<p>Your information has been saved correctly.</p>',
    pt: '<p>Os seus dados foram guardados corretamente.</p>',
    fr: '<p>Vos données ont été sauvegardées correctement.</p>',
    eus: '<p>Zure datuak behar bezala gorde dira.</p>',
    cat: '<p>Les dades s\'han desat correctament.</p>'
  },

  twoStepVerificationActivated: {
    es: '<p>Verificación en dos pasos activada correctamente.</p>',
    en: '<p>Two-step verification is now activated.</p>',
    pt: '<p>Verificação em dois passos ativada corretamente.</p>',
    fr: '<p>La vérification en deux étapes a été activée correctement.</p>',
    eus: '<p>Bi urratseko egiaztapena behar bezala aktibatu da.</p>',
    cat: '<p>La verificació en dos passos s’ha activat correctament.</p>'
  },

  twoStepVerificationEmailActivation: {
    es: '<p>Estás a un paso de activar la verificación en dos pasos</p><p>por correo electrónico.</p>',
    en: '<p>You are one step away from activating two-step verification</p><p>via email.</p>',
    pt: '<p>Está a um passo de ativar a verificação em dois passos</p><p>por e-mail.</p>',
    fr: '<p>Vous allez activer la vérification en deux étapes par e-mail.</p>',
    eus: '<p>Bi urratseko egiaztapena helbide elektroniko bidez</p><p>aktibatzear zaude.</p>',
    cat: '<p>Estàs a punt d’activar la verificació en dos passos</p><p>per correu electrònic.</p>'
  },

  twoStepVerificationEmailDeactivation: {
    es: '<p>Estás a un paso de desactivar la verificación en dos pasos</p><p>por correo electrónico.</p>',
    en: '<p>You are one step away from deactivating two-step verification</p><p>via email.</p>',
    pt: '<p>Está a um passo de desativar a verificação em dois passos</p><p>por e-mail.</p>',
    fr: '<p>Vous allez désactiver la vérification en deux étapes</p><p>par e-mail.</p>',
    eus: '<p>Bi urratseko egiaztapena helbide elektroniko bidez</p><p>desaktibatzear zaude.</p>',
    cat: '<p>Estàs a punt de desactivar la verificació en dos passos</p><p>per correu electrònic.</p>'
  },

  activationMessage: {
    es: '<p>Esto significa que, además de su contraseña, <span class="i18n-bold">todos los integrantes</span></p><p><span class="i18n-bold">de tu equipo</span> necesitarán un segundo factor de autenticación</p><p>para acceder a su cuenta cada vez que inicien sesión.</p>',
    en: '<p>This means that, in addition to their password, all of your</p><p class="i18n-bold">team members will need a second authentication factor to access</p><p>their account each time they log in.</p>',
    pt: '<p>Isto significa que, além da sua palavra-passe, <span class="i18n-bold">todos os integrantes</span></p><p><span class="i18n-bold">da sua equipa</span> necessitarão de um segundo fator de autenticação</p><p>para aceder à sua conta sempre que iniciarem sessão.</p>',
    fr: '<p>Cela signifie qu\'en plus de leur mot de passe, <span class="i18n-bold">tous les membres</span></p><p><span class="i18n-bold">de votre équipe</span> auront besoin d\'un deuxième facteur d\'authentification</p><p>chaque fois qu\'ils se connecteront à leur compte.</p>',
    eus: '<p>Horrek esan nahi du, pasahitzaz gain, <span class="i18n-bold">zure taldeko kide guztiek</span></p><p>bigarren autentifikazio-faktore bat beharko dutela beren kontuan sartzeko</p><p>saioa hasten duten bakoitzean.</p>',
    cat: '<p>Això vol dir que, a banda de la contrasenya, <span class="i18n-bold">tots els integrants</span></p><p><span class="i18n-bold">de l\'equip</span> necessitaran un segon factor d\'autenticació</p><p>per accedir al compte cada cop que inicien la sessió.</p>',
  },

  noTwoStepVerificationRequired: {
    es: '<p>Esto significa que <span class="i18n-bold">todos los integrantes de tu equipo</span></p><p>ya no van a necesitar un segundo factor de autenticación</p><p>para acceder a su cuenta cada vez que inicien sesión.</p>',
    en: '<p>This means that <span class="i18n-bold">your team members</span></p><p>will no longer need a second authentication factor to access their</p><p>account each time they log in.</p>',
    pt: '<p>Isto significa que <span class="i18n-bold">todos os membros da sua equipa</span></p><p>já não vão necessitar de um segundo fator de autenticação</p><p>para aceder à sua conta sempre que iniciarem sessão.</p>',
    fr: '<p>Cela signifie que <span class="i18n-bold">tous les membres de votre équipe</span></p><p>n\'auront plus besoin d\'un deuxième facteur d\'authentification</p><p>chaque fois qu\'ils se connecteront à leur compte.</p>',
    eus: '<p>Horrek esan nahi du, <span class="i18n-bold">zure taldeko kide guztiek</span></p><p>ez dutela bigarren autentifikazio-faktorerik beharko</p><p>beren kontuan sartzeko saioa hasten duten bakoitzean.</p>',
    cat: '<p>Això vol dir que <span class="i18n-bold">els integrants de l\'equip</span></p><p>ja no necessitaran un segon factor d\'autenticació</p><p>per accedir al compte cada cop que inicien la sessió.</p>',
  },

  cancelVerifiaction: {
    es: '<p>CANCELAR</p>',
    en: '<p>CANCEL</p>',
    pt: '<p>CANCELAR</p>',
    fr: '<p>ANNULER</p>',
    eus: '<p>UTZI</p>',
    cat: '<p>CANCEL·LA</p>',
  },

  visores_PanelIzquierdo_Descarga_data8: {
    es: '<p>CONTINUAR</p>',
    en: '<p>CONTINUE</p>',
    pt: '<p>CONTINUAR</p>',
    fr: '<p>CONTINUER</p>',
    eus: '<p>JARRAITU</p>',
    cat: '<p>CONTINUA</p>',
  },

  enterCode: {
    es: '<p>Introduce el código</p>',
    en: '<p>Enter the code</p>',
    pt: '<p>Introduza o código</p>',
    fr: '<p>Saisissez le code</p>',
    eus: '<p>Sartu kodea</p>',
    cat: '<p>Introdueix el codi</p>',
  },

  enterVerificationCodeEmail: {
    es: '<p>Introduce el código de verificación</p><p> que hemos enviado a tu correo electrónico.</p>',
    en: '<p>Please enter the verification code</p><p>we\'ve sent to your email.</p>',
    pt: '<p>Introduza o código de verificação</p><p> que enviámos para o seu e-mail.</p>',
    fr: '<p>Saisissez le code de vérification que nous</p><p>vous avons envoyé par e-mail.</p>',
    eus: '<p>Sartu zure posta elektronikora</p><p>bidali dugun egiaztapen-kodea.</p>',
    cat: '<p>Introdueix el codi de verificació</p><p>que t\'hem enviat al correu electrònic.</p>',
  },

  verificationCode: {
    es: '<p>Código de verificación</p>',
    en: '<p>Verification code</p>',
    pt: '<p>Código de verificação</p>',
    fr: '<p>Code de vérification</p>',
    eus: '<p>Egiaztapen kodea</p>',
    cat: '<p>Codi de verificació</p>',
  },
  errorVerificationIncorrect: {
    es: '<p>El código de verificación que has introducido es incorrecto. Vuelve a intentarlo.</p>',
    en: '<p>The verification code you entered is incorrect. Please try again.</p>',
    pt: '<p>O código de verificação introduzido está incorreto. Tente novamente.</p>',
    fr: '<p>Le code de vérification que vous avez saisi est incorrect. Veuillez réessayer.</p>',
    eus: '<p>Sartu duzun egiaztapen-kodea es da zuzena. Saiatu berriro.</p>',
    cat: '<p>El codi de verificació que has introduït és incorrecte. Torna a intentar-ho.</p>',
  },

  dataNotSaved: {
    es: '<p>Tus datos no se han podido guardar.</p>',
    en: '<p>Your information could not be saved.</p>',
    pt: '<p>Não foi possível guardar os seus dados.</p>',
    fr: '<p>Vos données n\'ont pas pu être sauvegardées.</p>',
    eus: '<p>Zure datuak ezin izan dira gorde.</p>',
    cat: '<p>Les dades no s\'han pogut desar.</p>'
  },

  twoStepVerificationNotActivated: {
    es: '<p>La verificación en dos pasos no ha podido activarse.</p>',
    en: '<p>Unable to activate two-step authentication.</p>',
    pt: '<p>Não foi possível ativar a verificação em dois passos.</p>',
    fr: '<p>La vérification en deux étapes n\'a pas pu être activée.</p>',
    eus: '<p>Ezin izan da aktibatu bi urratseko egiaztapena</p>',
    cat: '<p>La verificació en dos passos no s\'ha pogut activar.</p>'
  },

  tryAgainLater: {
    es: '<p>Por favor, inténtalo de nuevo más tarde.</p>',
    en: '<p>Please try again later.</p>',
    pt: '<p>Tente novamente mais tarde.</p>',
    fr: '<p>Veuillez réessayer plus tard.</p>',
    eus: '<p>Mesedez, saiatu berriro geroago.</p>',
    cat: '<p>Torna a provar-ho més tard.</p>'
  },

  retry: {
    es: '<p>Reintentar</p>',
    en: '<p>Try again</p>',
    pt: '<p>Tentar novamente</p>',
    fr: '<p>Réessayer</p>',
    eus: '<p>Berriro saiatu</p>',
    cat: '<p>Reintenta</p>'
  },
};
