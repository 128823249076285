import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { StorageService } from 'src/app/models/storage/storage.service';

@Component({
  selector: 'app-iota-notice',
  templateUrl: './iota-notice.component.html',
  styleUrls: ['./iota-notice.component.scss'],
})
export class IotaNoticeComponent implements OnInit, AfterViewInit {
  showIOTA: boolean;

  is1536 = this.responsiveService.moreThan1536();

  constructor(
    private storageService: StorageService,
    private responsiveService: ResponsiveService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.showIOTA = true;

    if (this.storageService.get('IotaRead')) {
      this.showIOTA = false;
    }
  }
  adjustBottomPosition() {
    const footer = document.querySelector<HTMLElement>('.footer');
    const footerMobile = document.querySelector<HTMLElement>('.mobile-footer');
    const elementoDinamico =
      document.querySelector<HTMLElement>('.iota-container');
    if (footer && elementoDinamico && !footerMobile) {
      console.log('Dentro del if');
      const footerAltura = footer.offsetHeight;
      // Ajustamos el valor de bottom dinámicamente usando Renderer2
      this.renderer.setStyle(elementoDinamico, 'top', 'auto');
      this.renderer.setStyle(
        elementoDinamico,
        'bottom',
        `${footerAltura + 2}px`
      );
    }
  }
  ngAfterViewInit() {
    this.adjustBottomPosition(); // Ajustar bottom al cargar la vista
  }

  acceptIOTA() {
    this.storageService.set('IotaRead', '1');
    this.showIOTA = false;
  }

  onResize() {
    this.is1536 = this.responsiveService.moreThan1536();
    this.adjustBottomPosition();
  }
}
