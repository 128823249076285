/**
 * Enumerates all existing tool groups.
 */
export enum Groups
{
    STANDARD,
    ADVANCED,
    MEASUREMENT,
    SCALE,
    MODIFY,
    VIEW,
    MOVEMENT,
    IOTA,
    IMAGES,
    ADDONS,
    SECURITY,
    ENGINEERING,
    AGRICULTURE,
    MULTILAYER,
}
