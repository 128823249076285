import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { RestClientService } from '../../api-access/rest-client.service';
import { LogoCompany } from '../../../../commons/models/model/logo-company';

@Injectable({
  providedIn: 'root',
})
export class PrivateUrlRemoteService {
  private URL = '/private-version';
  public privateUrl = false;
  public privateComp = '';
  public checked = false;
  constructor(
    private restClientService: RestClientService,
    private router: Router
  ) {}

  public checkUrl(): void {
    this.restClientService
      .get(`${this.URL}/check-url`)
      .subscribe((result: { isPrivate: boolean; subdomain: string }) => {
        this.checked = true;
        // this.privateUrl =
        //   window.location.href.includes('localhost') || result.isPrivate;

        this.privateUrl =
          [
            'localhost',
            'dev.app.eyescloud3d.com',
            '192.168.',
            'devtunnels',
          ].some((url) => window.location.href.includes(url)) ||
          result.isPrivate;

        // this.privateUrl = true;
        this.privateComp = result.subdomain;
        // this.privateComp = 'itcl';
      });
  }

  public getAll(): Observable<any[]> {
    return this.restClientService.get(`${this.URL}/all-url`);
  }

  public getPrivateUrl(): Observable<any> {
    if (!this.checked) {
      this.getAll().subscribe((urls) => {
        this.checked = true;
        urls.forEach((url) => {
          const comp = url.url.split('.')[1];
          if (this.router.url.includes(comp)) {
            this.privateComp = comp;
            this.privateUrl = true;
          }
          console.log(url);
        });
        return [];
      });
    } else {
      return null;
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  public uploadLogo(file: File) {
    const formData = new FormData();
    formData.append('logo', file);
    return this.restClientService.post(`${this.URL}/logo`, formData);
  }

  public getLogo(): Observable<any> {
    return this.restClientService.get(`${this.URL}/logo`);
  }

  public deleteLogo(userId: number): Observable<any> {
    return this.restClientService.delete(`${this.URL}/${userId}/logo`);
  }

  public getLogoUrl(): Observable<any> {
    return this.restClientService.get(`${this.URL}/logo-url`);
  }
}
