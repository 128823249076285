import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ModelPointCloudDownloadRemoteService } from 'src/app/viewer/services/remotes/point-cloud/model-point-cloud-download-remote.service';

@Component({
  selector: 'app-selection-denied',
  styleUrls: ['./selection-denied.component.scss'],
  templateUrl: './selection-denied.component.html',
})
export class SelectionDeniedComponent implements OnInit, OnDestroy {
  public title: string;
  submitted = false;
  public errorMessage = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private modelPointCloudDownloadRemoteService: ModelPointCloudDownloadRemoteService
  ) {}

  ngOnInit(): void {
    this.loaderService.showLoader();
    const polygonId = this.route.snapshot.paramMap.get('polygonId');
    const polygonNum = parseInt(polygonId, 10);
    // console.log(polygonNum);
    this.subscriptions.push(
      this.modelPointCloudDownloadRemoteService
        .denySelection(polygonNum)
        .subscribe(
          (success) => {
            this.loaderService.hideLoader();
            this.router.navigateByUrl('');
          },
          (error) => {
            this.errorMessage = error;
            this.loaderService.hideLoader();
            this.router.navigateByUrl('');
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
