import {Component, OnInit, ViewEncapsulation, HostListener, Inject} from '@angular/core';
import { InformationPageEnum } from '../information-text/enum/information-page-enum';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-information-layout',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InformationPageComponent implements OnInit {
  informationPageEnum = InformationPageEnum;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.firstChild?.data.subscribe((data: any) => {
      const informationPageNumber = data.informationPageNumber;
      this.changeText(informationPageNumber);
    });
  }

  changeText(informationPageNumber) {
    // Obtén todos los elementos con la clase "active"
    const activeElements = document.querySelectorAll('.info-text-active');

    // Elimina la clase "active" de todos los elementos
    activeElements.forEach(element => {
      element.classList.remove('info-text-active');
    });

    // Según el argumento recibido, muestra el texto correcto
    if (informationPageNumber === 3) {
      document.querySelector('.cookie-policy')?.classList.add('info-text-active');
    } else if (informationPageNumber === 2) {
      document.querySelector('.privacy-policy')?.classList.add('info-text-active');
    } else {
      document.querySelector('.terms-of-use')?.classList.add('info-text-active');
    }
  }
}
