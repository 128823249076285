import { Pipe, PipeTransform } from '@angular/core';
import { EventService } from 'src/app/core/services/event.service';
import { I18nService } from '../../core/services/i18n.service';

@Pipe({
  name: 'i18n',
  pure: false,
})
export class I18nPipe implements PipeTransform {
  storeValue: any;
  storeKeyParams: object;
  result: string;

  constructor(
    private i18nService: I18nService,
    private eventService: EventService
  ) {
    // eventService.on('i18nRefreshTexts', () => {
    //   this.result = this.transform(this.storeValue, this.storeKeyParams);
    // });
  }

  public transform(value: any, ...keyParams: any[]): string {
    this.storeValue = value;
    this.storeKeyParams = keyParams;
    this.result = this.i18nService.getText(value, keyParams);
    return this.result;
  }
}
