import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NewModelStepsConfig } from 'src/app/commons/enum/first-experience-configs';
import { FirstExperienceNewModelStepsEnum } from 'src/app/commons/enum/first-experience-enum';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

@Component({
  selector: 'app-first-experience-new-model-dialog',
  templateUrl: './first-experience-new-model-dialog.component.html',
  styleUrls: ['./first-experience-new-model-dialog.component.scss'],
})
export class FirstExperienceNewModelDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  allSteps: typeof FirstExperienceNewModelStepsEnum =
    FirstExperienceNewModelStepsEnum;
  currentStep: FirstExperienceNewModelStepsEnum =
    FirstExperienceNewModelStepsEnum.FIRST;
  stepDescriptions = NewModelStepsConfig;

  /**
   * Tamaño del outline blanco al resaltar el elemento
   */
  outline: number;
  /**
   * Tamaño de la flecha del diálogo.
   */
  offSet: number;
  leftPosition: number;
  topPosition: number;
  initialDialogWidth: number;
  initialDialogHeight: number;

  buttonVideo: HTMLElement;
  buttonImages: HTMLElement;
  buttonName: HTMLElement;

  initialX: number;
  initialY: number;
  finalX: number;
  finalY: number;
  halfWidthElement: number;
  halfHeightElement: number;

  constructor(
    public dialog: MatDialogRef<FirstExperienceNewModelDialogComponent>,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.buttonVideo = document.querySelector('#dropzone-videos');
    this.buttonImages = document.querySelector('#dropzone-images');
    this.buttonName = document.querySelector('.new-3d-header');
    this.getInitialDialog();
  }

  ngOnDestroy(): void {
    this.removeAllClasses();
  }

  private removeAllClasses() {
    if (this.buttonVideo) {
      this.renderer.removeClass(this.buttonVideo, 'fe-mov-opt');
    }
    if (this.buttonImages) {
      this.renderer.removeClass(this.buttonImages, 'fe-mov-opt');
    }
    if (this.buttonName) {
      this.renderer.removeClass(this.buttonName, 'fe-mov-opt');
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.getInitialDialog();
  }

  close() {
    this.dialog.close();
  }

  getStepDescription(step: FirstExperienceNewModelStepsEnum): string {
    const stepObject = this.stepDescriptions.find((obj) => obj.key === step);
    return stepObject ? stepObject.description : '';
  }

  nextStep() {
    if (this.currentStep < FirstExperienceNewModelStepsEnum.LAST) {
      this.currentStep++;
      this.getInitialDialog();
    } else {
      this.dialog.close(FirstExperienceNewModelStepsEnum.LAST);
    }
  }

  goToStep(stepKey: FirstExperienceNewModelStepsEnum) {
    if (stepKey < this.currentStep) {
      this.currentStep = stepKey;
      this.getInitialDialog();
    }
  }

  getInitialDialog() {
    const initialDialog = document.querySelector(
      '.FirstExperienceNewModelDialog'
    ) as HTMLElement;
    if (initialDialog) {
      setTimeout(() => {
        this.initialDialogWidth = initialDialog.offsetWidth;
        this.initialDialogHeight = initialDialog.offsetHeight;
        this.moveDialog();
      }, 1);
    }
  }

  moveDialog(): void {
    this.removeAllClasses();
    if (this.currentStep === FirstExperienceNewModelStepsEnum.FIRST) {
      this.moveInitial();
    } else if (this.currentStep === FirstExperienceNewModelStepsEnum.SECOND) {
      this.moveToVideo();
    } else if (this.currentStep === FirstExperienceNewModelStepsEnum.THIRD) {
      this.moveToImages();
    } else {
      this.moveToName();
    }
  }

  moveInitial() {
    this.leftPosition = (window.innerWidth - this.initialDialogWidth) / 2;
    this.topPosition = (window.innerHeight - this.initialDialogHeight) / 2;
    this.dialog.updatePosition({
      left: `${this.leftPosition}px`,
      top: `${this.topPosition}px`,
    });
  }

  moveToVideo(): void {
    if (this.buttonVideo) {
      this.renderer.addClass(this.buttonVideo, 'fe-mov-opt');
      this.getElementSize(this.buttonVideo);

      if (
        this.responsiveService.firstExperience834() &&
        !this.responsiveService.firstExperience1024()
      ) {
        this.leftPosition =
          this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
        this.topPosition = this.finalY + this.outline + this.offSet;
      } else if (
        this.responsiveService.firstExperience830() &&
        !this.responsiveService.firstExperience834()
      ) {
        this.leftPosition =
          this.initialX + (this.halfWidthElement - this.initialDialogWidth / 2);
        this.topPosition = this.finalY + this.outline + this.offSet;
      } else if (this.responsiveService.firstExperience600()) {
        this.leftPosition = this.finalX + this.outline + this.offSet;
        this.topPosition =
          this.finalY - this.halfHeightElement - this.initialDialogHeight / 2;
      } else {
        this.leftPosition = this.initialX - this.outline;
        this.topPosition = this.finalY + this.outline + this.offSet;
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToImages(): void {
    if (this.buttonImages) {
      this.renderer.addClass(this.buttonImages, 'fe-mov-opt');
      this.getElementSize(this.buttonImages);

      if (
        this.responsiveService.firstExperience834() &&
        !this.responsiveService.firstExperience1024()
      ) {
        this.leftPosition =
          this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
        this.topPosition = this.finalY + this.outline + this.offSet;
      } else if (
        this.responsiveService.firstExperience830() &&
        !this.responsiveService.firstExperience834()
      ) {
        this.leftPosition =
          this.initialX + (this.halfWidthElement - this.initialDialogWidth / 2);
        this.topPosition = this.finalY + this.outline + this.offSet;
      } else if (this.responsiveService.firstExperience600()) {
        this.leftPosition =
          this.initialX - this.outline - this.offSet - this.initialDialogWidth;
        this.topPosition =
          this.finalY - this.halfHeightElement - this.initialDialogHeight / 2;
      } else {
        this.leftPosition =
          this.finalX + this.outline - this.initialDialogWidth;
        this.topPosition = this.finalY + this.outline + this.offSet;
      }

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  moveToName(): void {
    // if (this.buttonImages) {
    //   this.renderer.removeClass(this.buttonImages, 'fe-mov-opt');
    // }

    if (this.buttonName) {
      this.renderer.addClass(this.buttonName, 'fe-mov-opt');
      this.getElementSize(this.buttonName);

      this.leftPosition =
        this.finalX - this.halfWidthElement - this.initialDialogWidth / 2;
      this.topPosition = this.finalY + this.outline + this.offSet;

      this.dialog.updatePosition({
        left: `${this.leftPosition}px`,
        top: `${this.topPosition}px`,
      });
    }
  }

  getElementSize(element: HTMLElement): void {
    this.initialX = element.getBoundingClientRect().left;
    this.initialY = element.getBoundingClientRect().top;
    this.finalX = this.initialX + element.offsetWidth;
    this.finalY = this.initialY + element.offsetHeight;
    this.halfWidthElement = (this.finalX - this.initialX) / 2;
    this.halfHeightElement = (this.finalY - this.initialY) / 2;
    if (this.responsiveService.firstExperience1920()) {
      this.outline = 16;
      this.offSet = 18;
    } else if (this.responsiveService.firstExperience1280()) {
      this.outline = 8;
      this.offSet = 20;
    } else if (this.responsiveService.firstExperience1194()) {
      this.outline = 8;
      this.offSet = 24;
    } else if (this.responsiveService.firstExperience1024()) {
      this.outline = 8;
      this.offSet = 12;
    } else if (this.responsiveService.firstExperience834()) {
      this.outline = 8;
      this.offSet = 20;
    } else if (this.responsiveService.firstExperience830()) {
      this.outline = 4;
      this.offSet = 13;
    } else if (this.responsiveService.firstExperience600()) {
      this.outline = 8;
      this.offSet = 17;
    } else if (this.responsiveService.firstExperience414()) {
      this.outline = 8;
      this.offSet = 15;
    } else {
      this.outline = 4;
      this.offSet = 13;
    }
  }
}
