import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Groups } from '../models/tools/groups';
import { Tools } from '../models/tools/tools';
import { MinimizeComms } from '../models/comms/minimize-comms';
import { MinimizeStatus } from '../models/common/minimize-status';
import { filter } from 'rxjs/operators';
import { ViewerTypes } from '../models/common/viewer-types';
import { DownloadTypes } from '../models/downloads/download-types';
import { User } from 'src/app/commons/models/user/user';
import { Model } from 'src/app/models/model';
import { ViewerControls } from '../models/viewer-controls/viewer-controls';
import { CurrentTool } from '../models/tools/current-tool';
import { IotaInfo } from '../models/right-panel/iota/iota-info';
import { RightPanelIndexes } from '../models/right-panel/common/right-panel-indexes';
import {
  GroupInfo,
  ToolInfo,
} from 'src/app/core/services/remote/nr-info-remote.service';
import { DownloadData } from 'src/app/profile/models/external-api-download-data';
import { DownloadFinished } from 'src/app/core/models/new-rates/new-rate.types';
// import { DownloadTool } from 'src/app/viewer/services/tools/Other/download-tool';

/**
 * Class that allows communications between the different panel components.
 */
@Injectable({
  providedIn: 'root',
})
export class ViewerCommsService {
  public static scope: ViewerCommsService;

  /**
   * Counter used to emit a new value everytime the back button is pressed in the left panel.
   */
  private backValue = 0;

  /**
   * Counter used to emit a new value everytime the main button is pressed in the left panel.
   */
  private mainValue = 0;

  private auxValue = 0;

  /**
   * Subject that allows panels to communicate any group selection to the *ViewerLayout*.
   */
  private groupSubject = new BehaviorSubject<Groups>(Groups.STANDARD);

  /**
   * Subject that allows panels to communicate any tool selection to the *ViewerLayout*.
   */
  private selectToolSubject = new BehaviorSubject<Tools>(Tools.NONE);

  /**
   * Subject that allows right panels to communicate the removal of a tool to the *ViewerLayout*.
   */
  private removeToolSubject = new BehaviorSubject<Tools>(Tools.NONE);

  /**
   * Subject that allows the *ViewerLayout* to communicate the currently active group to all left panels.
   */
  private currentGroupSubject = new BehaviorSubject<Groups>(Groups.STANDARD);

  /**
   * Subject that allows the *ViewerLayout* to send an array with all opened tools to all panels and buttons.
   */
  private currentToolsSubject = new BehaviorSubject<CurrentTool[]>([]);

  /**
   * Subject that allows panel headers to notify a change in its minimized state to the *ViewerLayout*.
   */
  private minimizeSubject = new BehaviorSubject<MinimizeComms>(null);

  /**
   * Subject that allows the *ViewerLayout* to update the minimized status of all panels.
   */
  private minimizePanelsSubject = new BehaviorSubject<MinimizeStatus[][]>(null);

  /**
   * Subject that allows left panel headers to notify to the *ViewerLayout* that the back button has been pressed.
   */
  private backSubject = new BehaviorSubject<number>(this.backValue);

  /**
   * Subject that allows left panel headers to notify to the *ViewerLayout* that the main button has been pressed.
   */
  private mainSubject = new BehaviorSubject<number>(this.mainValue);

  /**
   * Subject that allows right panels to notify any change in their activation state to the *ViewerLayout*.
   */
  private activeToolSubject = new BehaviorSubject<CurrentTool>(null);

  /**
   * Subject that allows to open a measure tool inside the multiple measures panel.
   */
  private multipleMeasureTool = new BehaviorSubject<Tools>(null);

  /**
   * Subject that allows the *ViewerLayout* to enable/disable the Zoom functionality.
   */
  private zoomToolSubject = new BehaviorSubject<boolean>(false);

  /**
   * Subject that allows communication between *ViewerControl* buttons and the *ViewerControlsComponent*.
   */
  private viewerControlSubject = new BehaviorSubject<ViewerControls>(
    ViewerControls.NONE
  );

  /**
   * Subject used by the *ViewerControlsComponent* to send currently active viewer controls to all *ViewerControl* buttons.
   */
  private currentViewerControlsSubject = new BehaviorSubject<ViewerControls[]>(
    []
  );

  /**
   * Subject used to communicate any change on the selected viewer type.
   */
  private viewerTypeSubject = new BehaviorSubject<ViewerTypes>(
    ViewerTypes.NONE
  );

  /**
   * Subject used to communicate from the download panel content to the general download panel the intention
   * to download the selected format.
   */
  private requestDownloadSubject = new BehaviorSubject<DownloadTypes>(
    DownloadTypes.NONE
  );

  /**
   * Subject used by the general download panel to tell the *ViewerLayout* to start downloading the selected format.
   */
  private startDownloadSubject = new BehaviorSubject<DownloadTypes>(
    DownloadTypes.NONE
  );

  /**
   * Subject used to notify that a download has finished.
   */
  private finishDownloadSubject = new BehaviorSubject<DownloadFinished>({
    type: DownloadTypes.NONE,
    info: null,
  });

  /**
   * Subject used to get the currently authenticated user.
   */
  private currentUserSubject = new BehaviorSubject<User | null>(null);

  /**
   * Subject used to get the current model.
   */
  private currentModelSubject = new BehaviorSubject<Model | null>(null);

  /**
   * Subject used to get a boolean indicating if the current user is the model owner.
   */
  private currentOwnerSubject = new BehaviorSubject<boolean>(null);

  /**
   * Subject used to get iota information regarding the current model.
   */
  private iotaInfoSubject = new BehaviorSubject<IotaInfo>(null);

  /**
   * Subject used to get the current background color.
   */
  private currentBackgroundColor = new BehaviorSubject<string>('');

  /**
   * Subject used to get the current background color.
   */
  private fullscreenStateChange = new BehaviorSubject<boolean>(null);

  /**
   * Subject used to get the current background color.
   */
  private rightPanelsAndIndexes = new BehaviorSubject<RightPanelIndexes[]>([]);

  /**
   * Subject used to get which tools are accessible by the current user.
   */
  private accessibleToolsSubject = new BehaviorSubject<ToolInfo[]>([]);

  /**
   * Subject used to get which groups are accessible by the current user.
   */
  private accessibleGroupsSubject = new BehaviorSubject<GroupInfo[]>([]);

  /**
   * Subject used to request model data.
   */
  private requestViewerModelDataSubject = new BehaviorSubject<number>(null);

  /**
   * Subject used to request model data.
   */
  private sendViewerModelDataSubject = new BehaviorSubject<any>(null);

  private requestCarCrashDeformationDataSubject = new BehaviorSubject<any>(
    null
  );

  private sendCarCrashDeformationDataSubject = new BehaviorSubject<any>(null);

  private requestScreenshotSubject = new BehaviorSubject<number>(null);

  private sendScreenshotSubject = new BehaviorSubject<any>(null);

  private downloadReportSubject = new BehaviorSubject<number>(null);

  constructor() {
    ViewerCommsService.scope = this;
  }

  public sendDefaultValueToSubjects() {
    this.backValue = 0;
    this.mainValue = 0;
    this.groupSubject.next(Groups.STANDARD);
    this.selectToolSubject.next(Tools.NONE);
    this.removeToolSubject.next(Tools.NONE);
    this.currentGroupSubject.next(Groups.STANDARD);
    this.currentToolsSubject.next([]);
    this.minimizeSubject.next(null);
    this.minimizePanelsSubject.next(null);
    this.backSubject.next(this.backValue);
    this.mainSubject.next(this.mainValue);
    this.activeToolSubject.next(null);
    this.multipleMeasureTool.next(null);
    this.zoomToolSubject.next(false);
    this.viewerControlSubject.next(ViewerControls.NONE);
    this.currentViewerControlsSubject.next([]);
    this.viewerTypeSubject.next(ViewerTypes.NONE);
    this.requestDownloadSubject.next(DownloadTypes.NONE);
    this.startDownloadSubject.next(DownloadTypes.NONE);
    this.finishDownloadSubject.next({ type: DownloadTypes.NONE, info: null });
    this.currentUserSubject.next(null);
    this.currentModelSubject.next(null);
    this.currentOwnerSubject.next(null);
    this.iotaInfoSubject.next(null);
    this.currentBackgroundColor.next('');
    this.fullscreenStateChange.next(null);
    this.rightPanelsAndIndexes.next([]);
    this.accessibleToolsSubject.next([]);
    this.accessibleGroupsSubject.next([]);
  }

  /**
   * Processes the selection of a group.
   *
   * @param group Selected group.
   */
  public selectGroup(group: Groups): void {
    this.groupSubject.next(group);
  }

  /**
   * Method that sends an update everytime a group is selected.
   *
   * @returns An observable to the *groupSubject*.
   */
  public onGroupSelected(): Observable<Groups> {
    return this.groupSubject.asObservable();
  }

  /**
   * Processes the selection of a tool.
   *
   * @param tool Selected tool.
   */
  public selectTool(tool: Tools): void {
    this.selectToolSubject.next(tool);
  }

  /**
   * Sends an update everytime a tool is selected.
   *
   * @returns An observable to the *selectionToolSubject*.
   */
  public onToolSelected(): Observable<Tools> {
    return this.selectToolSubject
      .asObservable()
      .pipe(filter((tool) => tool !== Tools.NONE));
  }

  /**
   * Notifies that a tool must be removed.
   *
   * @param tool Tool to be removed.
   */
  public removeTool(tool: Tools): void {
    this.removeToolSubject.next(tool);
  }

  /**
   * Allows to receive updated on any tool removal request.
   *
   * @returns An observable to the *removeToolSubject*-
   */
  public onToolRemoved(): Observable<Tools> {
    return this.removeToolSubject.asObservable();
  }

  /**
   * Sets the group as the active one.
   *
   * @param group Currently active group.
   */
  public setCurrentGroup(group: Groups): void {
    this.currentGroupSubject.next(group);
  }

  /**
   * Sends updates with every change in the current active group.
   *
   * @returns An observable to the *currentGroupSubject*.
   */
  public onCurrentGroup(): Observable<Groups> {
    return this.currentGroupSubject.asObservable();
  }

  /**
   * Determines which tools are currently opened.
   *
   * @param tools Currently opened tools.
   */
  public setCurrentTools(tools: CurrentTool[]): void {
    this.currentToolsSubject.next(tools);
  }

  /**
   * Allows to receive updated everytime a tool is opened or closed.
   *
   * @returns An observable to the *currentToolsSubject*.
   */
  public onCurrentTools(): Observable<CurrentTool[]> {
    return this.currentToolsSubject.asObservable();
  }

  /**
   * Sends a notification with the new minimize state of a panel.
   *
   * @param min Identifies the panel and its minimize status.
   */
  public minimizePanel(min: MinimizeComms): void {
    this.minimizeSubject.next(min);
  }

  /**
   * Receives updated on every change in the minimize state of any panel.
   *
   * @returns An observable to the *minimizeSubject*.
   */
  public onMinimizedPanel(): Observable<MinimizeComms> {
    return this.minimizeSubject
      .asObservable()
      .pipe(filter((value) => value !== null));
  }

  /**
   * Sends an array with the minimize state of all panels. Any panel not present in the array is considered to be open.
   *
   * @param min Array with the minimize state of all panels.
   */
  public minimizePanels(min: MinimizeStatus[][]): void {
    this.minimizePanelsSubject.next(min);
  }

  /**
   * Receives updated on the minimize states of all panels.
   *
   * @returns An observable to the *minimizePanelsSubject*.
   */
  public onMinimizedPanels(): Observable<MinimizeStatus[][]> {
    return this.minimizePanelsSubject
      .asObservable()
      .pipe(filter((value) => value !== null));
  }

  /**
   * Notifies that the back button has been pressed.
   */
  public pressBack(): void {
    this.backValue++;
    this.backSubject.next(this.backValue);
  }

  /**
   * Receives a notification everytime the back button is pressed.
   *
   * @returns An observable to the *backSubject*.
   */
  public onBackPressed(): Observable<number> {
    return this.backSubject.asObservable().pipe(filter((value) => value !== 0));
  }

  /**
   * Notifies that the main button has been pressed.
   */
  public pressMain(): void {
    this.mainValue++;
    this.mainSubject.next(this.mainValue);
  }

  /**
   * Receives a notification everytime the main button is pressed.
   *
   * @returns An observable to the *mainSubject*.
   */
  public onMainPressed(): Observable<number> {
    return this.mainSubject.asObservable().pipe(filter((value) => value !== 0));
  }

  /**
   * Changes the activation status of the specified tool.
   *
   * @param state Activation status of a tool.
   */
  public changeToolActiveState(state: CurrentTool): void {
    this.activeToolSubject.next(state);
  }

  /**
   * Receives updates on changes to the activation status of any tool.
   *
   * @returns An observable to the *activeToolSubject*.
   */
  public onToolActiveStateChange(): Observable<CurrentTool> {
    return this.activeToolSubject
      .asObservable()
      .pipe(filter((value) => value !== null));
  }

  /**
   * Notifies that a new measure tool should be opened inside the multiple measure panel.
   *
   * @param tool Measure tool to open.
   */
  public openMultipleMeasureTool(tool: Tools): void {
    this.multipleMeasureTool.next(tool);
  }

  /**
   * Receives updates on changes to the activation status of any tool.
   *
   * @returns An observable to the *activeToolSubject*.
   */
  public onOpenMultipleMeasureTool(): Observable<Tools> {
    return this.multipleMeasureTool
      .asObservable()
      .pipe(filter((value) => value !== null));
  }

  /**
   * Notifies the zoom button to change its state.
   *
   * @param state Indicates whether the zoom button should be enabled or disabled.
   */
  public setZoomToolState(state: boolean): void {
    this.zoomToolSubject.next(state);
  }

  /**
   * Receives updates on the state of the zoom button.
   *
   * @returns An observable to the *zoomToolSubject*.
   */
  public onZoomToolStateChange(): Observable<boolean> {
    return this.zoomToolSubject.asObservable();
  }

  /**
   * Toggles the specified viewer control.
   *
   * @param viewerControl Viewer control button that has been pressed.
   */
  public setViewerControl(viewerControl: ViewerControls): void {
    this.viewerControlSubject.next(viewerControl);
  }

  /**
   * Receives updates on the toggling of any viewer control.
   *
   * @returns An observable to the *viewerControlSubject*.
   */
  public onViewerControl(): Observable<ViewerControls> {
    return this.viewerControlSubject
      .asObservable()
      .pipe(filter((value) => value !== ViewerControls.NONE));
  }

  /**
   * Sends an array containing all viewer controls that should be active.
   *
   * @param viewerControls Array with all currently active viewer controls.
   */
  public setCurrentViewerControls(viewerControls: ViewerControls[]): void {
    this.currentViewerControlsSubject.next(viewerControls);
  }

  /**
   * Receives updates on the group of viewer controls that should be active.
   *
   * @returns An observable to the *currentViewerControlsSubject*.
   */
  public onCurrentViewerControls(): Observable<ViewerControls[]> {
    return this.currentViewerControlsSubject.asObservable();
  }

  /**
   * Changes the current active viewer to the one specified.
   *
   * @param viewerType New viewer type.
   */
  public setViewerType(viewerType: ViewerTypes): void {
    this.viewerTypeSubject.next(viewerType);
  }

  /**
   * Receives updates when the viewer type is changed.
   *
   * @returns An observable to the *viewerTypeSubject*.
   */
  public onViewerType(): Observable<ViewerTypes> {
    return this.viewerTypeSubject
      .asObservable()
      .pipe(filter((value) => value !== ViewerTypes.NONE));
  }

  /**
   * Sends a new download request.
   *
   * @param downloadType Requested download format.
   */
  public requestDownload(downloadType: DownloadTypes): void {
    this.requestDownloadSubject.next(downloadType);
  }

  /**
   * Receives notifications for every download request.
   *
   * @returns An observable to the *requestDownloadSubject*.
   */
  public onRequestDownload(): Observable<DownloadTypes> {
    return this.requestDownloadSubject
      .asObservable()
      .pipe(filter((value) => value !== DownloadTypes.NONE));
  }

  /**
   * Mandates a new download to be processed.
   *
   * @param downloadType Download format.
   */
  public startDownload(downloadType: DownloadTypes): void {
    this.startDownloadSubject.next(downloadType);
  }

  /**
   * Receives orders to download a specific format.
   *
   * @returns An observable to the *startDownloadSubject*.
   */
  public onStartDownload(): Observable<DownloadTypes> {
    return this.startDownloadSubject
      .asObservable()
      .pipe(filter((value) => value !== DownloadTypes.NONE));
  }

  /**
   * Notifies that the download for the specified format has finished.
   *
   * @param downloadType Download format.
   */
  public finishDownload(downloadType: DownloadFinished): void {
    this.finishDownloadSubject.next(downloadType);
  }

  /**
   * Receives updates on download completions.
   *
   * @returns An observable to the *finishDownloadSubject*.
   */
  public onFinishDownload(): Observable<DownloadFinished> {
    return this.finishDownloadSubject
      .asObservable()
      .pipe(filter((value) => value.type !== DownloadTypes.NONE));
  }

  /**
   * Sends the current user's data.
   *
   * @param user Current user.
   */
  public setCurrentUser(user: User): void {
    this.currentUserSubject.next(user);
  }

  /**
   * Receives updates on the current user's data.
   *
   * @returns An observable to the *currentUserSubject*.
   */
  public onCurrentUser(): Observable<User> {
    return this.currentUserSubject
      .asObservable()
      .pipe(filter((user) => user !== null));
  }

  /**
   * Sends the data of the current model.
   *
   * @param model Current model.
   */
  public setCurrentModel(model: Model): void {
    this.currentModelSubject.next(model);
  }

  /**
   * Receives the data of the current model.
   *
   * @returns An observable to the *currentModelSubject*.
   */
  public onCurrentModel(): Observable<Model> {
    return this.currentModelSubject
      .asObservable()
      .pipe(filter((model) => model !== null));
  }

  /**
   * Sends the data of the current model.
   *
   * @param model Current model.
   */
  public setCurrentOwner(owner: boolean): void {
    this.currentOwnerSubject.next(owner);
  }

  /**
   * Receives the data of the current model.
   *
   * @returns An observable to the *currentModelSubject*.
   */
  public onCurrentOwner(): Observable<boolean> {
    return this.currentOwnerSubject
      .asObservable()
      .pipe(filter((owner) => owner !== null));
  }

  /**
   * Sends the iota data for the current model.
   *
   * @param iotaInfo Iota information.
   */
  public setIotaInfo(iotaInfo: IotaInfo): void {
    console.log('estableciendo iota');
    console.log(iotaInfo);
    this.iotaInfoSubject.next(iotaInfo);
  }

  /**
   * Receives iota data for the current model.
   *
   * @returns An observable to the *iotaInfoSubject*.
   */
  public onIotaInfo(): Observable<IotaInfo> {
    return this.iotaInfoSubject
      .asObservable()
      .pipe(filter((iotaInfo) => iotaInfo !== null));
  }

  /**
   * Sends the current background color.
   *
   * @param color New background color.
   */
  public setCurrentBackgroundColor(color: string): void {
    this.currentBackgroundColor.next(color);
  }

  /**
   * Receives the current background color.
   *
   * @returns An observable to the *currentBackgroundColor*.
   */
  public onCurrentBackgroundColor(): Observable<string> {
    return this.currentBackgroundColor
      .asObservable()
      .pipe(filter((backgroundColor) => backgroundColor !== ''));
  }

  /**
   * Sets the state of the fullscreen button.
   *
   * @param color New background color.
   */
  public setFullscreenState(state: boolean): void {
    this.fullscreenStateChange.next(state);
  }

  /**
   * Receives the current background color.
   *
   * @returns An observable to the *currentBackgroundColor*.
   */
  public onFullscreenChange(): Observable<boolean> {
    return this.fullscreenStateChange
      .asObservable()
      .pipe(filter((state) => state !== null));
  }

  /**
   * Sets the list of right panels and their indexes.
   *
   * @param panelIndexes List of right panels and indexes.
   */
  public setRightPanelIndexes(panelIndexes: RightPanelIndexes[]): void {
    this.rightPanelsAndIndexes.next(panelIndexes);
  }

  /**
   * Receives the list of right panels and indexes.
   *
   * @returns An observable to the *rightPanelsAndIndexes*.
   */
  public onRightPanelIndexes(): Observable<RightPanelIndexes[]> {
    return this.rightPanelsAndIndexes
      .asObservable()
      .pipe(filter((panelIndexes) => panelIndexes.length > 0));
  }

  /**
   * Sets the list of tools that can be accessed by the current user.
   *
   * @param accessibleTools List of accessibleTools.
   */
  public setAccessibleTools(accessibleTools: ToolInfo[]): void {
    this.accessibleToolsSubject.next(accessibleTools);
  }

  /**
   * Receives the list of tools accessible by the current user.
   *
   * @returns An observable to the *accessibleToolsSubject*.
   */
  public onAccessibleTools(): Observable<ToolInfo[]> {
    return this.accessibleToolsSubject
      .asObservable()
      .pipe(filter((accessibleTools) => accessibleTools.length > 0));
  }

  /**
   * Sets the list of groups that can be accessed by the current user.
   *
   * @param accessibleGroups List of accessibleGroups.
   */
  public setAccessibleGroups(accessibleGroups: GroupInfo[]): void {
    this.accessibleGroupsSubject.next(accessibleGroups);
  }

  /**
   * Receives the list of groups accessible by the current user.
   *
   * @returns An observable to the *accessibleGroupsSubject*.
   */
  public onAccessibleGroups(): Observable<GroupInfo[]> {
    return this.accessibleGroupsSubject
      .asObservable()
      .pipe(filter((accessibleGroups) => accessibleGroups.length > 0));
  }

  public requestViewerModelData(): void {
    this.auxValue++;
    this.requestViewerModelDataSubject.next(this.auxValue);
  }

  public onRequestViewerModelData(): Observable<number> {
    return this.requestViewerModelDataSubject
      .asObservable()
      .pipe(filter((ele) => ele !== null));
  }

  public sendViewerModelData(data: any): void {
    this.sendViewerModelDataSubject.next(data);
  }

  public onSendViewerModelData(): Observable<any> {
    return this.sendViewerModelDataSubject
      .asObservable()
      .pipe(filter((ele) => ele !== null));
  }

  public requestCarCrashDeformationData(deformationData: any): void {
    this.requestCarCrashDeformationDataSubject.next(deformationData);
  }

  public onRequestCarCrashDeformationData(): Observable<any> {
    return this.requestCarCrashDeformationDataSubject
      .asObservable()
      .pipe(filter((ele) => ele !== null));
  }

  public sendCarCrashDeformationData(data: {
    l: any;
    vehicleType: any;
    coef: any;
    method: any;
    edefForm: any;
    edefForm1: any;
  }): void {
    this.sendCarCrashDeformationDataSubject.next(data);
  }

  public onSendCarCrashDeformationData(): Observable<{
    l: any;
    vehicleType: any;
    coef: any;
    method: any;
    edefForm: any;
    edefForm1: any;
  }> {
    return this.sendCarCrashDeformationDataSubject
      .asObservable()
      .pipe(filter((ele) => ele !== null));
  }

  public requestScreenshot(): void {
    this.auxValue++;
    this.requestScreenshotSubject.next(this.auxValue);
  }

  public onRequestScreenshot(): Observable<number> {
    return this.requestScreenshotSubject
      .asObservable()
      .pipe(filter((ele) => ele != null));
  }

  public sendScreenshot(data: any): void {
    this.sendScreenshotSubject.next(data);
  }

  public onSendScreenshot(): Observable<any> {
    return this.sendScreenshotSubject
      .asObservable()
      .pipe(filter((ele) => ele !== null));
  }

  public doDownloadReport(option: number): void {
    this.downloadReportSubject.next(option);
  }

  public onDownloadReport(): Observable<number> {
    return this.downloadReportSubject.asObservable();
  }
}
