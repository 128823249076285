import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from 'src/app/core/services/i18n.service';
import { Language } from 'src/app/models/language';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class AppleAuthenticationService {
  private appleScriptUrl =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

  constructor(private i18nService: I18nService) {}

  private ready: Promise<boolean> = new Promise((resolve) => {
    if (
      this.i18nService.getCurrentLanguage().generalCode !== '' &&
      this.i18nService.getCurrentLanguage().generalCode !== 'en_US'
    ) {
      this.appleScriptUrl =
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/' +
        this.i18nService.getCurrentLanguage().generalCode +
        '/appleid.auth.js';
    }
    if (typeof window !== 'undefined') {
      const script = require('scriptjs');
      script(this.appleScriptUrl, () => resolve(true));
    } else {
      resolve(false);
    }
  });

  loadScript(): void {
    this.ready.then((isReady) => {
      if (isReady) {
        this.init();
      }
    });
  }

  /**
   * Call init method
   */
  private init(): void {
    window.AppleID.auth.init({
      clientId: 'com.eyescloud3d.pwa',
      scope: 'name email',
      redirectURI: 'https://api.eyescloud3d.com/apple-login',
      state: 'sign in',
      response_mode: 'form_post',
    });
  }
}
