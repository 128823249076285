import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from 'src/app/services/common/responsive.service';
import { WindowService } from 'src/app/services/common/window.service';

@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.scss'],
})
export class HiringComponent implements OnInit {
  @ViewChild('freeRate') freeRate!: ElementRef;
  @ViewChild('proRate') proRate!: ElementRef;
  @ViewChild('businessRate') businessRate!: ElementRef;

  rate: string = '';
  mobileXS: boolean = this.responsiveService.mobileXS();
  isLandscape: boolean = this.responsiveService.isLandscape();
  isPC: boolean = this.responsiveService.isPC();
  isFirefox: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private windowService: WindowService,
    private responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.rate = this.route.snapshot.paramMap.get('rate');
    // this.adjustMobileZoom();
  }

  ngAfterViewInit(): void {
    this.scrollToModule(this.rate);
    // this.adjustMobileZoom();
  }

  scrollToModule(rate: string): void {
    let targetElement: ElementRef | undefined;
    switch (rate) {
      case 'freeRate':
        targetElement = this.freeRate;
        break;
      case 'proRate':
        targetElement = this.proRate;
        break;
      case 'businessRate':
        targetElement = this.businessRate;
        break;
      default:
        break;
    }
    if (targetElement) {
      targetElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event) {
  //   this.mobileXS = this.responsiveService.mobileXS();
  //   this.isLandscape = this.responsiveService.isLandscape();
  //   this.isPC = this.responsiveService.isPC();
  //   this.adjustMobileZoom();
  // }
  // private adjustMobileZoom() {
  //   console.log('dentro del adjustMobilezoom');
  //   const zoomClass = this.isFirefox ? 'mz-zoom' : 'zoom';
  //   if (this.isPC) {
  //     console.log('111');
  //     document.body.classList.add(zoomClass);
  //     let vw = window.innerWidth;
  //     let zoom = 1 - (1920 - vw) / 2000;
  //     document.documentElement.style.setProperty('--zoom', zoom.toString());
  //   } else if (this.isLandscape) {
  //     console.log('222222');
  //     document.body.classList.add(zoomClass);
  //     let vw = window.innerWidth;
  //     let zoom = 1 - (1024 - vw) / 1100;
  //     document.documentElement.style.setProperty('--zoom', zoom.toString());
  //   } else if (this.mobileXS) {
  //     console.log('333333');
  //     document.body.classList.add(zoomClass);
  //     let vw = window.innerWidth;
  //     let zoom = 1 - (390 - vw) / 350;
  //     document.documentElement.style.setProperty('--zoom', zoom.toString());
  //   } else {
  //     console.log('44444');
  //     document.body.classList.remove(zoomClass);
  //     document.documentElement.style.removeProperty('--zoom');
  //   }
  // }
}
