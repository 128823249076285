import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GalleryOptions } from 'src/app/core/models/gallery/gallery-options';
import { Model } from 'src/app/models/model';
import { RestClientService } from '../../api-access/rest-client.service';

@Injectable({
  providedIn: 'root',
})
export class GalleryRemoteService {
  private readonly PREFIX = 'gallery';

  constructor(private restClientService: RestClientService) {}

  public getUserModels(options: GalleryOptions): Observable<any> {
    return this.restClientService.get(
      `${this.PREFIX}/user${options.toString()}`
    );
  }

  public getPopularModels(options: GalleryOptions): Observable<any> {
    return this.restClientService.get(
      `${this.PREFIX}/popular${options.toString()}`
    );
  }

  public refresh(modelId: number): Observable<Model> {
    return this.restClientService.get(`${this.PREFIX}/${modelId}/refresh`);
  }

  public massDeletion(models: number[]): Observable<number[]> {
    return this.restClientService.delete(`${this.PREFIX}`, { models });
  }
}
