export const GALLERY_I18N_ENTRIES = {
  popular: {
    es: '<p>Popular</p>',
    en: '<p>Popular</p>',
    pt: '<p>Popular</p>',
    fr: '<p>Populaire</p>',
    eus: '<p>Ezagunenak</p>',
    cat: '<p>Popular</p>',
  },

  myPortfolio: {
    es: '<p>Mi Portfolio</p>',
    en: '<p>My Portfolio</p>',
    pt: '<p>O meu portfólio</p>',
    fr: '<p>Mon portfolio</p>',
    eus: '<p>Nire zorroa</p>',
    cat: '<p>La meva cartera</p>',
  },

  myGallery: {
    es: '<p>Mi galería</p>',
    en: '<p>My gallery</p>',
    pt: '<p>A minha galeria</p>',
    fr: '<p>Ma galerie</p>',
    eus: '<p>Nire galeria</p>',
    cat: '<p>La meva galeria</p>',
  },

  myLast: {
    es: '<p>Mis Últimos</p>',
    en: '<p>Most recent models</p>',
    pt: '<p>Os meus últimos</p>',
    fr: '<p>Mes derniers modèles</p>',
    eus: '<p>Azken ereduak</p>',
    cat: '<p>Els meus últims</p>',
  },

  myFavorites: {
    es: '<p>Favoritos</p>',
    en: '<p>Favorites</p>',
    pt: '<p>Favoritos</p>',
    fr: '<p>Favoris</p>',
    eus: '<p>Gogokoenak</p>',
    cat: '<p>Preferits</p>',
  },

  anotherGallery: {
    es: '<p>Explora</p>',
    en: '<p>Explore</p>',
    pt: '<p>Explorar</p>',
    fr: '<p>Découvrez</p>',
    eus: '<p>Esploratu</p>',
    cat: '<p>Explora</p>',
  },

  selectAnotherGallery_data1: {
    es: '<p>Selecciona tu galería</p>',
    en: '<p>Select your gallery</p>',
    pt: '<p>Selecione sua galeria</p>',
    fr: '<p>Sélectionnez votre galerie</p>',
    eus: '<p>Hautatu zure galeria</p>',
    cat: '<p>Selecciona la teva galeria</p>',
  },

  selectAnotherGallery_data2: {
    es: '<p>GALERÍA EMPLEADO GENERAL DEL DEPARTAMENTO</p>',
    en: '<p>GENERAL DEPARTMENT EMPLOYEE GALLERY</p>',
    pt: '<p>GALERIA GERAL DE FUNCIONÁRIOS DO DEPARTAMENTO</p>',
    fr: '<p>GALERIE EMPLOYÉ GÉNÉRAL DU DÉPARTEMENT</p>',
    eus: '<p>DEPARTAMENTUKO LANGILE OROKORRAREN GALERIA</p>',
    cat: '<p>GALERIA GENERAL D\'EMPLEATS DEL DEPARTAMENT</p>',
  },

  selectAnotherGallery_data3: {
    es: '<p>GALERÍA EMPLEADO GENERAL EMPRESA</p>',
    en: '<p>GENERAL COMPANY EMPLOYEE GALLERY COMPANY</p>',
    pt: '<p>GALERIA GERAL DE FUNCIONÁRIOS DA EMPRESA</p>',
    fr: '<p>GALERIE EMPLOYÉ GÉNÉRAL ENTREPRISE</p>',
    eus: '<p>ENPRESAKO LANGILE OROKORRAREN GALERIA</p>',
    cat: '<p>GALERIA GENERAL D\'EMPLEATS DE L\'EMPRESA</p>',
  },

  selectCategory: {
    es: '<p>Selecciona una categoría</p>',
    en: '<p>Select a category</p>',
    pt: '<p>Selecione uma categoria</p>',
    fr: '<p>Sélectionnez une catégorie</p>',
    eus: '<p>Aukeratu kategoria bat</p>',
    cat: '<p>Selecciona una categoria</p>',
  },

  selectSubCategory: {
    es: '<p>Selecciona una subcategoría</p>',
    en: '<p>Select a subcategory</p>',
    pt: '<p>Selecione uma subcategoria</p>',
    fr: '<p>Sélectionnez une sous-catégorie</p>',
    eus: '<p>Aukeratu azpikategoria bat</p>',
    cat: '<p>Selecciona una subcategoria</p>',
  },

  deleteCategory: {
    es: '<p>Eliminar Selección</p>',
    en: '<p>Delete Selection</p>',
    pt: '<p>Eliminar seleção</p>',
    fr: '<p>Supprimer la sélection</p>',
    eus: '<p>Ezabatu hautaketa</p>',
    cat: '<p>Elimina la selecció</p>',
  },

  personalCategory: {
    es: '<p>Selecciona una categoría personalizada</p>',
    en: '<p>Select a custom category</p>',
    pt: '<p>Selecione uma categoria personalizada</p>',
    fr: '<p>Sélectionnez une catégorie personnalisée</p>',
    eus: '<p>Aukeratu kategoria pertsonalizatu bat</p>',
    cat: '<p>Selecciona una categoria personalitzada</p>',
  },

  personalCategory_2: {
    es: '<p>Selecciona una</p><p>categoría personalizada</p>',
    en: '<p>Select a</p><p>custom category</p>',
    pt: '<p>Selecione uma</p><p>categoria personalizada</p>',
    fr: '<p>Sélectionnez une</p><p>catégorie personnalisée</p>',
    eus: '<p>Aukeratu kategoria</p><p>pertsonalizatu bat</p>',
    cat: '<p>Selecciona una</p><p>categoria personalitzada</p>',
  },

  galleryCategory_data1: {
    es: '<p>Comercio electrónico</p>',
    en: '<p>E-commerce</p>',
    pt: '<p>Comércio eletrónico</p>',
    fr: '<p>E-commerce</p>',
    eus: '<p>Merkataritza elektronikoa</p>',
    cat: '<p>Comerç electrònic</p>',
  },

  galleryCategory_data2: {
    es: '<p>Ingeniería</p>',
    en: '<p>Engineering</p>',
    pt: '<p>Engenharia</p>',
    fr: '<p>Ingénierie</p>',
    eus: '<p>Ingeniaritza</p>',
    cat: '<p>Enginyeria</p>',
  },

  galleryCategory_data3: {
    es: '<p>Salud</p>',
    en: '<p>Healthcare</p>',
    pt: '<p>Saúde</p>',
    fr: '<p>Santé</p>',
    eus: '<p>Osasuna</p>',
    cat: '<p>Salut</p>',
  },

  galleryCategory_data4: {
    es: '<p>Seguridad</p>',
    en: '<p>Safety</p>',
    pt: '<p>Segurança</p>',
    fr: '<p>Sécurité</p>',
    eus: '<p>Segurtasuna</p>',
    cat: '<p>Seguretat</p>',
  },

  galleryCategory_data5: {
    es: '<p>Educación</p>',
    en: '<p>Education</p>',
    pt: '<p>Educação</p>',
    fr: '<p>Éducation</p>',
    eus: '<p>Hezkuntza</p>',
    cat: '<p>Educació</p>',
  },

  galleryCategory_data6: {
    es: '<p>Varios</p>',
    en: '<p>Miscellaneous</p>',
    pt: '<p>Vários</p>',
    fr: '<p>Divers</p>',
    eus: '<p>Beste batzuk</p>',
    cat: '<p>Miscel·lània</p>',
  },

  galleryCategory_data7: {
    es: '<p>Gastronomía</p>',
    en: '<p>Gastronomy</p>',
    pt: '<p>Gastronomia</p>',
    fr: '<p>Gastronomie</p>',
    eus: '<p>Gastronomia</p>',
    cat: '<p>Gastronomia</p>',
  },

  galleryCategory_data8: {
    es: '<p>Arte y cultura</p>',
    en: '<p>Arts and Culture</p>',
    pt: '<p>Arte e cultura</p>',
    fr: '<p>Arts et culture</p>',
    eus: '<p>Artea eta kultura</p>',
    cat: '<p>Art i cultura</p>',
  },

  galleryCategory_data9: {
    es: '<p>Selecciona una subcategoría</p>',
    en: '<p>Select a subcategory</p>',
    pt: '<p>Selecione uma subcategoria</p>',
    fr: '<p>Sélectionnez une sous-catégorie</p>',
    eus: '<p>Aukeratu azpikategoria bat</p>',
    cat: '<p>Selecciona una subcategoria</p>',
  },

  galleryCategory_data10: {
    es: '<p>Departamentos</p>',
    en: '<p>Departments</p>',
    pt: '<p>Departamentos</p>',
    fr: '<p>Départements</p>',
    eus: '<p>Departamentuak</p>',
    cat: '<p>Departaments</p>',
  },

  galleryCategory_data11: {
    es: '<p>Filtrar por</p>',
    en: '<p>Filter by</p>',
    pt: '<p>Filtrar por</p>',
    fr: '<p>Filtrer par :</p>',
    eus: '<p>Filtratu</p>',
    cat: '<p>Filtra per</p>',
  },

  galleryCategory_data12: {
    es: '<p>Aplicar</p>',
    en: '<p>Apply</p>',
    pt: '<p>Aplicar</p>',
    fr: '<p>Appliquer</p>',
    eus: '<p>Aplikatu</p>',
    cat: '<p>Aplica</p>',
  },

  galleryCategory_data13: {
    es: '<p>Todos</p>',
    en: '<p>All</p>',
    pt: '<p>Todos</p>',
    fr: '<p>Tous</p>',
    eus: '<p>Guztiak</p>',
    cat: '<p>Tots</p>',
  },

  galleryCategory_data14: {
    es: '<p>Favoritos</p>',
    en: '<p>Favorites</p>',
    pt: '<p>Favoritos</p>',
    fr: '<p>Favoris</p>',
    eus: '<p>Gogokoenak</p>',
    cat: '<p>Preferits</p>',
  },

  galleryCategory_data15: {
    es: '<p>Fallidos</p>',
    en: '<p>Failed</p>',
    pt: '<p>Falhados</p>',
    fr: '<p>Échoués</p>',
    eus: '<p>Hutsegiteak</p>',
    cat: '<p>Fallits</p>',
  },

  galleryCategory_data16: {
    es: '<p>Modelos en proceso</p>',
    en: '<p>Models in progress</p>',
    pt: '<p>Modelos em processamento</p>',
    fr: '<p>Modèles en cours</p>',
    eus: '<p>Abian dauden ereduak</p>',
    cat: '<p>Models en curs</p>',
  },

  galleryCategory_data17: {
    es: '<p>Generados por mi</p>',
    en: '<p>Generated by me</p>',
    pt: '<p>Criados por mim</p>',
    fr: '<p>Générés par moi-même</p>',
    eus: '<p>Nik sortutakoak</p>',
    cat: '<p>Generats per mi</p>',
  },

  galleryCategory_data18: {
    es: '<p>Generados por otros</p>',
    en: '<p>Generated by others</p>',
    pt: '<p>Criados por outros</p>',
    fr: '<p>Générés par d’autres</p>',
    eus: '<p>Besteek sortutakoak</p>',
    cat: '<p>Generats per altres persones</p>',
  },

  galleryCategory_data19: {
    es: '<p>Aplicar</p>',
    en: '<p>Apply</p>',
    pt: '<p>Aplicar</p>',
    fr: '<p>Appliquer</p>',
    eus: '<p>Aplikatu</p>',
    cat: '<p>Aplica</p>',
  },

  galleryCategory_data20: {
    es: '<p>Pendiente de aprobación por mi</p>',
    en: '<p>Model pending approval from me</p>',
    pt: '<p>A aguardar aprovação por mim</p>',
    fr: '<p>En attente d’approbation par moi-même</p>',
    eus: '<p>Nik onartzeke</p>',
    cat: '<p>Pendent d\'aprovació per mi</p>',
  },

  galleryCategory_data21: {
    es: '<p>Pendiente de aprobación por otros</p>',
    en: '<p>Model pending approval from others</p>',
    pt: '<p>A aguardar aprovação por outros</p>',
    fr: '<p>En attente d\'approbation par d’autres</p>',
    eus: '<p>Besteek onartzeke</p>',
    cat: '<p>Pendent d\'aprovació per altres persones</p>',
  },

  galleryCategory_data22: {
    es: '<p>Gestionar uso de modelos</p>',
    en: '<p>Manage use of models</p>',
    pt: '<p>Gerir utilização de modelos</p>',
    fr: '<p>Gérer utilisation de modèles</p>',
    eus: '<p>Kudeatu ereduen erabilera</p>',
    cat: '<p>Gestiona l\'ús de models</p>',
  },

  galleryCategory_data23: {
    es: '<p>Descargar modelos</p>',
    en: '<p>Download models</p>',
    pt: '<p>Transferir modelos</p>',
    fr: '<p>Télécharger modèles</p>',
    eus: '<p>Deskargatu ereduak</p>',
    cat: '<p>Baixa models</p>',
  },

  galleryCategory_data24: {
    es: '<p>Eliminar modelos</p>',
    en: '<p>Delete models</p>',
    pt: '<p>Eliminar modelos</p>',
    fr: '<p>Supprimer modèles</p>',
    eus: '<p>Ezabatu ereduak</p>',
    cat: '<p>Elimina models</p>',
  },

  galleryCategory_data25: {
    es: '<p>Atrás</p>',
    en: '<p>Back</p>',
    pt: '<p>Atrás</p>',
    fr: '<p>Retour</p>',
    eus: '<p>Atzera</p>',
    cat: '<p>Enrere</p>',
  },

  galleryCategory_data26: {
    es: '<p>Con solicitudes de eliminación</p>',
    en: '<p>With deletion requests</p>',
    pt: '<p>Com pedidos de eliminação</p>',
    fr: '<p>Avec demandes de suppression</p>',
    eus: '<p>Ezabatzeko eskaera dutenak</p>',
    cat: '<p>Amb sol·licituds d’eliminació</p>',
  },

  galleryCategory_data27: {
    es: '<p>Eliminar solicitudes</p>',
    en: '<p>Delete requests</p>',
    pt: '<p>Eliminar pedidos</p>',
    fr: '<p>Supprimer demandes</p>',
    eus: '<p>Ezabatu eskaerak</p>',
    cat: '<p>Elimina les sol·licituds</p>',
  },

  galleryCategory_data28: {
    es: '<p class="i18n-bold">Seleccionar</p>',
    en: '<p class="i18n-bold">Select</p>',
    pt: '<p class="i18n-bold">Selecionar</p>',
    fr: '<p class="i18n-bold">Sélectionner</p>',
    eus: '<p class="i18n-bold">Hautatu</p>',
    cat: '<p class="i18n-bold">Selecciona</p>',
  },

  galleryCategory_data29: {
    es: '<p class="i18n-bold">INFORMACIÓN:</p>',
    en: '<p class="i18n-bold">INFORMATION:</p>',
    pt: '<p class="i18n-bold">INFORMAÇÃO:</p>',
    fr: '<p class="i18n-bold">INFORMATIONS :</p>',
    eus: '<p class="i18n-bold">INFORMAZIOA:</p>',
    cat: '<p class="i18n-bold">INFORMACIÓ:</p>',
  },

  galleryCategory_data30: {
    es: '<p>Modelo fallido</p>',
    en: '<p>Failed model</p>',
    pt: '<p>Modelo com falha</p>',
    fr: '<p>Modèle échoué</p>',
    eus: '<p>Huts egindako eredua</p>',
    cat: '<p>Model fallit</p>',
  },

  galleryCategory_data31: {
    es: '<p>Modelo pendiente de aprobación (Por otros)</p>',
    en: '<p>Model pending approval (From others)</p>',
    pt: '<p>Modelo a aguardar aprovação (por outros)</p>',
    fr: '<p>Modèle en attente d’approbation (par d’autres)</p>',
    eus: '<p>Onartzeke dagoen eredua (besteen partetik)</p>',
    cat: '<p>Model pendent d\'aprovació(per altres persones)</p>',
  },

  galleryCategory_data32: {
    es: '<p>Modelo pendiente de aprobación (Por mi)</p>',
    en: '<p>Model pending approval (From me)</p>',
    pt: '<p>Modelo a aguardar aprovação (por mim)</p>',
    fr: '<p>Modèle en attente d’approbation (par moi-même)</p>',
    eus: '<p>Onartzeke dagoen eredua (nire partetik)</p>',
    cat: '<p>Model pendent d\'aprovació(per mi)</p>',
  },

  galleryCategory_data33: {
    es: '<p>Solicitudes para eliminación de modelos</p>',
    en: '<p>Requests to delete models</p>',
    pt: '<p>Pedidos de eliminação de modelos</p>',
    fr: '<p>Demandes de suppression de modèles</p>',
    eus: '<p>Ereduak ezabatzeko eskaerak</p>',
    cat: '<p>Sol·licituds per a l\'eliminació de models</p>',
  },

  galleryCategory_data34: {
    es: '<p>Las categorías están creadas por defecto y no se pueden editar ni eliminar. Las subcategorías pueden ser creadas y editadas por cualquier usuario que genere un modelo 3D.</p>',
    en: '<p>Categories are created by default and cannot be edited or deleted. Subcategories can be created and edited by any user who generates a 3D model.</p>',
    pt: '<p>As categorias estão criadas por predefinição e não podem ser editadas nem eliminadas. As subcategorias podem ser criadas e editadas por qualquer utilizador que crie um modelo 3D.</p>',
    fr: '<p>Les catégories sont créées par défaut et ne peuvent pas être éditées ou supprimées. Les sous-catégories peuvent être créées et éditées par n’importe quel utilisateur générant un modèle 3D.</p>',
    eus: '<p>Kategoriak balio lehenetsiak dira eta ezin dira editatu edo ezabatu. 3D eredu bat sortzen duen erabiltzaile orok sortu eta editatu ditzake azpikategoriak.</p>',
    cat: '<p>Les categories es generen per defecte i no es poden editar ni eliminar. Qualsevol usuari que generi un model 3D pot crear i editar les subcategories.</p>',
  },

  galleryCategory_data35: {
    es: '<p>RECUPERAR</p>',
    en: '<p>RECOVER</p>',
    pt: '<p>RECUPERAR</p>',
    fr: '<p>RÉCUPÉRER</p>',
    eus: '<p>BERRESKURATU</p>',
    cat: '<p>RECUPERA</p>',
  },
  galleryCategory_data36: {
    es: '<p>No se han encontrado resultados para %X%</p>',
    en: '<p>No results found for %X%</p>',
    pt: '<p>Não foram encontrados resultados para %X%</p>',
    fr: '<p>Aucun résultat n\'a été trouvé pour%X%</p>',
    eus: '<p>Ez da aurkitu %X% bilaketarekin bat datorren emaitzarik</p>',
    cat: '<p>No s\'ha trobat cap resultat per a %X%</p>',
  },
  galleryCategory_data37: {
    es: '<p>Busca por nombre</p>',
    en: '<p>Search by name</p>',
    pt: '<p>Pesquisa por nome</p>',
    fr: '<p>Rechercher par nom</p>',
    eus: '<p>Izenaren arabera bilatu</p>',
    cat: '<p>Cerca per nom</p>',
  },
  galleryCategory_data38: {
    es: '<p>%X% modelos de %X%<p>',
    en: '<p>%X% models of %X%</p>',
    pt: '<p>%X% modelos de %X%</p>',
    fr: '<p>%X% modèles sur %X%</p>',
    eus: '<p>%X% eredu, %X% guztira</p>',
    cat: '<p>%X% models de %X%</p>',
  },
  galleryCategory_data39: {
    es: '<p>Actualmente no tienes modelos 3D \n en tu galería<p>',
    en: '<p>You have no 3D models in your gallery right now</p>',
    pt: '<p>Atualmente, não tem modelos 3D na sua galeria</p>',
    fr: '<p>Vous ne disposez actuellement d’aucun modèle 3D dans votre galerie</p>',
    eus: '<p>Unean ez duzu 3D eredurik zure galerian</p>',
    cat: '<p>Actualment no tens models 3D a la teva galeria</p>',
  },
  galleryCategory_data40: {
    es: '<p>GENERAR MODELO 3D<p>',
    en: '<p>GENERATE 3D MODEL</p>',
    pt: '<p>CRIAR MODELO 3D</p>',
    fr: '<p>GÉNÉRER MODÈLE 3D</p>',
    eus: '<p>SORTU 3D EREDUA</p>',
    cat: '<p>GENERA MODEL 3D</p>',
  },
};
