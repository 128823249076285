import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public set(key: string, value: string): void {
        window.localStorage[key] = value;
    }

    public get(key: string, defaultValue?: string): string {
        return window.localStorage[key] || defaultValue;
    }

    public setObject(key: string, value: any): void {
        window.localStorage[key] = JSON.stringify(value);
    }

    public getObject(key: string): any {
        return JSON.parse(window.localStorage[key] || null);
    }

    public delete(key: string): any {
        delete window.localStorage[key];
    }
}
