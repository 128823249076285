import { Injectable } from '@angular/core';
import { RestClientService } from '../api-access/rest-client.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { I18nService } from '../i18n.service';

export class Category {
  id: number;
  name: string;
  nameI18n: string;

  constructor(id: number, name: string, private i18nService: I18nService) {
    this.id = id;
    this.name = name;
    this.nameI18n = this.getI18nKey();
  }

  private getI18nKey() {
    return `galleryCategory_data${this.id}`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  url = '/categories';
  url2 = '/model-filter';
  comesFromHome: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private rest: RestClientService,
    private i18nService: I18nService
  ) {}

  getAllCategories(): Observable<Category[]> {
    return new Observable((subscriber) => {
      this.rest.get(this.url).subscribe((categories: Category[]) => {
        const allCategories: Category[] = [];
        categories.forEach((category) => {
          allCategories.push(
            new Category(category.id, category.name, this.i18nService)
          );
        });
        subscriber.next(allCategories);
        subscriber.complete();
      });
    });
  }

  getMoreGalleryFilters(): Observable<any> {
    return this.rest.get(`${this.url2}/filters/more-filters`);
  }

  setComesFromHome(comesFromHome: boolean): void {
    this.comesFromHome.next(comesFromHome);
  }

  getComesFromHome(): Observable<boolean> {
    return this.comesFromHome.asObservable();
  }
}
