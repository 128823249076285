export class GalleryOptions {
  page: number;
  pageSize: number;
  search: string;
  categories: number[];
  subcategories: number[];
  extra_filters: number[];
  departments: number[];

  constructor(
    page: number,
    pageSize: number,
    search: string = '',
    categories: number[] = [],
    subcategories: number[] = [],
    extra_filters: number[] = [],
    departments: number[] = []
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.search = search;
    this.categories = categories;
    this.subcategories = subcategories;
    this.extra_filters = extra_filters;
    this.departments = departments;
  }

  public toString() {
    const stringParams: string[] = [];
    stringParams.push(`page=${this.page}`);
    stringParams.push(`pageSize=${this.pageSize}`);
    if (this.search !== '') {
      stringParams.push(`search=${this.search}`);
    }
    this.categories.forEach((category) => {
      stringParams.push(`ca[]=${category}`);
    });
    this.subcategories.forEach((subcategory) => {
      stringParams.push(`sc[]=${subcategory}`);
    });
    this.extra_filters.forEach((extra_filter) => {
      stringParams.push(`ef[]=${extra_filter}`);
    });
    this.departments.forEach((department) => {
      stringParams.push(`de[]=${department}`);
    });
    return `?${stringParams.join('&')}`;
  }
}
