export class RequestToBeACompany {
  nif: string;
  businessName: string;
  address: string;
  country: number;
  contact: string;
  phone: string;
  email: string;
  web: string;
  email_admin1: string;
  email_admin2: string;
  name_admin1: string;
  name_admin2: string;
}
