import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ChangesWillBeLostDialogComponent } from 'src/app/viewer-new/components/right-panel/right-panel-dialogs/changes-will-be-lost-dialog/changes-will-be-lost-dialog.component';
import { CompanyControlPanelSaveCsvNoMoreUsersComponent } from 'src/app/viewer/components/dialogs/company-control-panel-save-csv-no-more-users/company-control-panel-save-csv-no-more-users.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          [401, 403].includes(err.status) &&
          !this.router.url.includes('viewer') &&
          !this.router.url.includes('new3d/load/') &&
          !request.url.includes('auth-user') &&
          !request.url.includes('userSession') &&
          !request.url.includes('first-exp')
        ) {
          this.authenticationService.deleteSecurityTokenInfo();
        }

        if (
          err.status === 403 &&
          this.router.url.includes('viewer') &&
          ['POST', 'PUT', 'DELETE'].includes(request.method)
        ) {
          this.changesWillBeLostDialog();
        } else if (
          err.status === 418 &&
          this.router.url.includes('company-control-panel')
        ) {
          this.openNoUsersRemainingDialog();
        }

        // const error = (err && err.error && err.error.message) || err.statusText;
        return throwError(err);
      })
    );
  }

  private changesWillBeLostDialog(): void {
    if (
      this.matDialog.openDialogs.findIndex(
        (dialog) =>
          dialog._containerInstance._config.panelClass ===
          'changesWillBeLostDialog'
      ) === -1
    ) {
      this.matDialog.open(ChangesWillBeLostDialogComponent, {
        disableClose: true,
        maxWidth: '100vw',
        panelClass: 'changesWillBeLostDialog',
      });
    }
  }

  /**
   * Abre el popup indicando que no se dispone de usuarios suficientes en la tarifa business.
   */
  private openNoUsersRemainingDialog(): void {
    this.matDialog.open(CompanyControlPanelSaveCsvNoMoreUsersComponent, {
      panelClass: 'company-control-panel-save-csv-no-more-users-component',
      disableClose: true,
      maxWidth: '100vw',
      hasBackdrop: true,
    });
  }
}
