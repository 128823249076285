import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService } from '../../../../core/services/api-access/rest-client.service';
import { ModelConverter } from '../../../models/point-cloud/model-converter';

@Injectable({
  providedIn: 'root',
})
export class ModelPointCloudDownloadRemoteService {
  public static scope: ModelPointCloudDownloadRemoteService;

  private URL = '/viewer/point-cloud';

  constructor(private restClientService: RestClientService) {
    ModelPointCloudDownloadRemoteService.scope = this;
  }

  public downloadModel(modelId: number): Observable<Blob> {
    return this.restClientService.getBlob(
      `${this.URL}/${modelId}/download-zip`
    );
  }

  public convertAndDownloadModel(
    modelId: number,
    downloadParams
  ): Observable<ModelConverter> {
    return this.restClientService.post(
      `${this.URL}/${modelId}/send-to-convert`,
      downloadParams
    );
  }

  public getConversionProgress(
    converterId: number
  ): Observable<ModelConverter> {
    return this.restClientService.get(
      `${this.URL}/${converterId}/model-converter`,
      null
    );
  }

  public getConvertedModel(
    modelId: number,
    extension: string
  ): Observable<any> {
    return this.restClientService.getArrayBuffer(
      `${this.URL}/${modelId}/download-converter-zip/${extension}`
    );
  }
  public validateSelection(polygonId: number) {
    return this.restClientService.get(
      `${this.URL}/model-from-class-validate/${polygonId}`,
      null
    );
  }
  public denySelection(polygonId: number) {
    return this.restClientService.get(
      `${this.URL}/model-from-class-deny/${polygonId}`,
      null
    );
  }
}
