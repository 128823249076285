import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {SecurityTokenStorage} from '../security-token-storage';
import {UserToken} from '../../../models/security/user-token';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private securityTokenStorage: SecurityTokenStorage<UserToken>,
              private router: Router,
              private authenticationService: AuthenticationService) { }

  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const identity = this.securityTokenStorage.getObjectValue() ? this.securityTokenStorage.getObjectValue() : null;
    if (identity) {
      return true;
    }
    console.log('pasa aqui');
    this.router.navigateByUrl(AuthenticationService.getAuthenticationUrl());
    return false;
  }
}
