export const IOTA_PROCESS_PDF_I18N_ENTRIES = {
  iota_process_pdf_Data1: {
    es: '<p>Informe de procesamiento de modelo 3D</p>',
    en: '<p>3D model processing report</p>',
    pt: '<p>Relatório de processamento de modelo 3D</p>',
    fr: '<p>Rapport du traitement du modèle 3D</p>',
    eus: '<p>3D ereduaren prozesatze txostena</p>',
    cat: '<p>Informe de processament del model 3D</p>',
  },

  iota_process_pdf_Data2: {
    es: '<p>Nombre del modelo 3D:</p>',
    en: '<p>3D model name:</p>',
    pt: '<p>Nome do modelo 3D:</p>',
    fr: '<p>Nom du modèle 3D :</p>',
    eus: '<p>3D ereduaren izena:</p>',
    cat: '<p>Nom del model 3D:</p>',
  },

  iota_process_pdf_Data3: {
    es: '<p>Código asignado:</p>',
    en: '<p>Assigned code:</p>',
    pt: '<p>Código atribuído:</p>',
    fr: '<p>Code attribué :</p>',
    eus: '<p>Ezarritako kodea:</p>',
    cat: '<p>Codi assignat:</p>',
  },

  iota_process_pdf_Data4: {
    es: '<p>Inicio del procesamiento</p>',
    en: '<p>Process starting point</p>',
    pt: '<p>Início do processamento</p>',
    fr: '<p>Début du traitement</p>',
    eus: '<p>Prozesamenduaren hasiera</p>',
    cat: '<p>Inici del processament</p>',
  },

  iota_process_pdf_Data5: {
    es: '<p>Finalización del procesamiento</p>',
    en: '<p>Process ending point</p>',
    pt: '<p>Conclusão do processamento</p>',
    fr: '<p>Fin du traitement</p>',
    eus: '<p>Prozesamenduaren amaiera</p>',
    cat: '<p>Finalització del processament</p>',
  },

  iota_process_pdf_Data6: {
    es: '<p>Fecha:</p>',
    en: '<p>Date:</p>',
    pt: '<p>Data:</p>',
    fr: '<p>Date :</p>',
    eus: '<p>Data:</p>',
    cat: '<p>Data:</p>',
  },

  iota_process_pdf_Data7: {
    es: '<p>Hora:</p>',
    en: '<p>Time:</p>',
    pt: '<p>Hora:</p>',
    fr: '<p>Heure :</p>',
    eus: '<p>Ordua:</p>',
    cat: '<p>Hora:</p>',
  },

  iota_process_pdf_Data8: {
    es: '<p>Procesos realizados</p>',
    en: '<p>Processes completed</p>',
    pt: '<p>Processamentos efetuados</p>',
    fr: '<p>Processus réalisés</p>',
    eus: '<p>Burututako prozesuak</p>',
    cat: '<p>Processos efectuats</p>',
  },

  iota_process_pdf_Data9: {
    es: '<p>Procesado</p>',
    en: '<p>Processed</p>',
    pt: '<p>Processado</p>',
    fr: '<p>Traité</p>',
    eus: '<p>Prozesaketa</p>',
    cat: '<p>S’ha processat</p>',
  },

  iota_process_pdf_Data10: {
    es: '<p>Nombre del proceso</p>',
    en: '<p>Process name</p>',
    pt: '<p>Nome do processamento</p>',
    fr: '<p>Nom du processus</p>',
    eus: '<p>Prozesuaren izena</p>',
    cat: '<p>Nom del procés</p>',
  },

  iota_process_pdf_Data11: {
    es: '<p>Proceso número</p>',
    en: '<p>Process number</p>',
    pt: '<p>Processamento número</p>',
    fr: '<p>Processus numéro</p>',
    eus: '<p>Prozesuaren zenbakia</p>',
    cat: '<p>Procés número</p>',
  },

  iota_process_pdf_Data12: {
    es: '<p>Inicialización de procesamiento</p>',
    en: '<p>Start of processing</p>',
    pt: '<p>Inicialização do processamento</p>',
    fr: '<p>Initialisation du traitement</p>',
    eus: '<p>Prozesamendua hasi da</p>',
    cat: '<p>Inicialització de processament</p>',
  },

  iota_process_pdf_Data13: {
    es: '<p>Validación de formato de ficheros</p>',
    en: '<p>Validation of file formats</p>',
    pt: '<p>Validação de formato de ficheiros</p>',
    fr: '<p>Validation du format des fichiers</p>',
    eus: '<p>Fitxategien formatua baliozkotu da</p>',
    cat: '<p>Validació de format de fitxers</p>',
  },

  iota_process_pdf_Data14: {
    es: '<p>Detección de fotogramas clave de video</p>',
    en: '<p>Detection of key video frames</p>',
    pt: '<p>Deteção de fotogramas-chave de vídeo</p>',
    fr: '<p>Détection d’images clés dans la vidéo</p>',
    eus: '<p>Bideoaren gako fotogramak antzeman dira</p>',
    cat: '<p>Detecció de fotogrames clau de vídeo</p>',
  },

  iota_process_pdf_Data15: {
    es: '<p>Detección de tipo de escenario</p>',
    en: '<p>Detection of scenario type</p>',
    pt: '<p>Deteção de tipo de cena</p>',
    fr: '<p>Détection du type de scène</p>',
    eus: '<p>Gertaleku mota antzeman da</p>',
    cat: '<p>Detecció de tipus d\'escenari</p>',
  },

  iota_process_pdf_Data16: {
    es: '<p>Comprobación de datos hiperespectrales</p>',
    en: '<p>Checking of hyperspectral data</p>',
    pt: '<p>Comprovação de dados hiperespetrais</p>',
    fr: '<p>Vérification des données hyperspectrales</p>',
    eus: '<p>Datu hiperespektralak egiaztatu dira</p>',
    cat: '<p>Comprovació de dades hiperespectrals</p>',
  },

  iota_process_pdf_Data17: {
    es: '<p>Comprobación de datos 360</p>',
    en: '<p>Checking of 360 data</p>',
    pt: '<p>Comprovação de dados 360</p>',
    fr: '<p>Vérification des données 360</p>',
    eus: '<p>360 datuak egiaztatu dira</p>',
    cat: '<p>Comprovació de dades 360</p>',
  },

  iota_process_pdf_Data18: {
    es: '<p>Optimización de datos</p>',
    en: '<p>Optimization of data</p>',
    pt: '<p>Otimização de dados</p>',
    fr: '<p>Optimisation des données</p>',
    eus: '<p>Datuak optimizatu dira</p>',
    cat: '<p>Optimització de dades</p>',
  },

  iota_process_pdf_Data19: {
    es: '<p>Cálculo de datos de georreferenciación</p>',
    en: '<p>Calculation of georeferencing data</p>',
    pt: '<p>Cálculo de dados de georreferenciação</p>',
    fr: '<p>Calcul des données de géoréférencement</p>',
    eus: '<p>Geoerreferentziazio datuak kalkulatu dira</p>',
    cat: '<p>Càlcul de dades de georeferenciació</p>',
  },

  iota_process_pdf_Data20: {
    es: '<p>Detección de marcadores de escala en escenario</p>',
    en: '<p>Detection of scale markers in scenario</p>',
    pt: '<p>Deteção de marcadores de escala em cena</p>',
    fr: '<p>Détection des marqueurs d’échelle sur la scène</p>',
    eus: '<p>Gertalekuan eskala markatzaileak antzeman dira</p>',
    cat: '<p>Detecció de marcadors d\'escala en escenari</p>',
  },

  iota_process_pdf_Data21: {
    es: '<p>Obtención de puntos clave</p>',
    en: '<p>Obtainment of key points</p>',
    pt: '<p>Obtenção de pontos-chave</p>',
    fr: '<p>Obtention des points clés</p>',
    eus: '<p>Gako puntuak lortu dira</p>',
    cat: '<p>Obtenció de punts clau</p>',
  },

  iota_process_pdf_Data22: {
    es: '<p>Emparejamiento de puntos clave</p>',
    en: '<p>Matching of key points</p>',
    pt: '<p>Emparelhamento de pontos-chave</p>',
    fr: '<p>Couplage des points clés</p>',
    eus: '<p>Gako puntuak parekatu dira</p>',
    cat: '<p>Aparellament de punts clau</p>',
  },

  iota_process_pdf_Data23: {
    es: '<p>Calibración y orientación de cámaras</p>',
    en: '<p>Calibration/orientation of cameras</p>',
    pt: '<p>Calibração e orientação de câmaras</p>',
    fr: '<p>Calibration et orientation des caméras</p>',
    eus: '<p>Kamerak kalibratu eta orientatu dira</p>',
    cat: '<p>Calibratge i orientació de càmeres</p>',
  },

  iota_process_pdf_Data24: {
    es: '<p>Optimización de orientación</p>',
    en: '<p>Optimization of orientation</p>',
    pt: '<p>Otimização de orientação</p>',
    fr: '<p>Optimisation de l’orientation</p>',
    eus: '<p>Orientazioa optimizatu da</p>',
    cat: '<p>Optimització d\'orientació</p>',
  },

  iota_process_pdf_Data25: {
    es: '<p>Aplicación de escala mediante marcadores</p>',
    en: '<p>Application of scale via markers</p>',
    pt: '<p>Aplicação de escala através de marcadores</p>',
    fr: '<p>Application de l’échelle grâce aux marqueurs</p>',
    eus: '<p>Eskala aplikatu da markatzaileen bidez</p>',
    cat: '<p>Aplicació d\'escala mitjançant marcadors</p>',
  },

  iota_process_pdf_Data26: {
    es: '<p>Aplicación de datos de georreferenciación</p>',
    en: '<p>Application of georeferencing data</p>',
    pt: '<p>Aplicação de dados de georreferenciação</p>',
    fr: '<p>Application des données de géoréférencement</p>',
    eus: '<p>Geoerreferentziazio datuak aplikatu dira</p>',
    cat: '<p>Aplicació de dades de georeferenciació</p>',
  },

  iota_process_pdf_Data27: {
    es: '<p>Salvado de datos de escena</p>',
    en: '<p>Saving of scene data</p>',
    pt: '<p>Recuperação de dados de cena</p>',
    fr: '<p>Sauvegarde des données de scène</p>',
    eus: '<p>Eszenaren datuak gorde dira</p>',
    cat: '<p>Conservació de dades d\'escena</p>',
  },

  iota_process_pdf_Data28: {
    es: '<p>Generación de marco común para reconstrucción 3D</p>',
    en: '<p>Generation of common framework for 3D reconstruction</p>',
    pt: '<p>Criação de moldura comum para reconstrução 3D</p>',
    fr: '<p>Création d’un cadre commun pour la reconstruction 3D</p>',
    eus: '<p>3D berreraikuntzarako esparru komuna sortu da</p>',
    cat: '<p>Generació de marc comú per a reconstrucció 3D</p>',
  },

  iota_process_pdf_Data29: {
    es: '<p>Densificación de nube de puntos 3D</p>',
    en: '<p>Densification of 3D point clouds</p>',
    pt: '<p>Densificação de nuvem de pontos 3D</p>',
    fr: '<p>Densification du nuage de points 3D</p>',
    eus: '<p>3D puntu hodeia dentsifikatu da</p>',
    cat: '<p>Densificació de núvol de punts 3D</p>',
  },

  iota_process_pdf_Data30: {
    es: '<p>Generación modelo 3D</p>',
    en: '<p>3D model generation</p>',
    pt: '<p>Criação de modelo 3D</p>',
    fr: '<p>Génération modèle 3D</p>',
    eus: '<p>3D eredua sortu da</p>',
    cat: '<p>Generació de model 3D</p>',
  },

  iota_process_pdf_Data31: {
    es: '<p>Optimización de modelo 3D</p>',
    en: '<p>3D model optimization</p>',
    pt: '<p>Otimização de modelo 3D</p>',
    fr: '<p>Optimisation du modèle 3D</p>',
    eus: '<p>3D eredua optimizatu da</p>',
    cat: '<p>Optimització de model 3D</p>',
  },

  iota_process_pdf_Data32: {
    es: '<p>Generación de marco común para cálculo de texturas</p>',
    en: '<p>Generation of common framework for calculation of textures</p>',
    pt: '<p>Criação de moldura comum para cálculo de texturas</p>',
    fr: '<p>Création d’un cadre commun pour le calcul des textures</p>',
    eus: '<p>Testurak kalkulatzeko esparru komuna sortu da</p>',
    cat: '<p>Generació de marc comú per a càlcul de textures</p>',
  },

  iota_process_pdf_Data33: {
    es: '<p>Optimización de nube de puntos</p>',
    en: '<p>Optimization of point clouds</p>',
    pt: '<p>Otimização de nuvem de pontos</p>',
    fr: '<p>Optimisation du nuage de points</p>',
    eus: '<p>Puntu hodeia optimizatu da</p>',
    cat: '<p>Optimització de núvol de punts</p>',
  },

  iota_process_pdf_Data34: {
    es: '<p>Generación de texturas</p>',
    en: '<p>Generation of textures</p>',
    pt: '<p>Criação de texturas</p>',
    fr: '<p>Création des textures</p>',
    eus: '<p>Testurak sortu dira</p>',
    cat: '<p>Generació de textures</p>',
  },

  iota_process_pdf_Data35: {
    es: '<p>Optimización de texturas</p>',
    en: '<p>Optimization of textures</p>',
    pt: '<p>Otimização de texturas</p>',
    fr: '<p>Optimisation des textures</p>',
    eus: '<p>Testurak optimizatu dira</p>',
    cat: '<p>Optimització de textures</p>',
  },

  iota_process_pdf_Data36: {
    es: '<p>Salvado de log de proceso</p>',
    en: '<p>Saving of process log</p>',
    pt: '<p>Recuperação de log de processamento</p>',
    fr: '<p>Sauvegarde du log de traitement</p>',
    eus: '<p>Prozesuaren egunkaria gorde da</p>',
    cat: '<p>Conservació de registre de procés</p>',
  },

  iota_process_pdf_Data37: {
    es: '<p>Empaquetado resultado</p>',
    en: '<p>Unwrapped result</p>',
    pt: '<p>Embalamento resultado</p>',
    fr: '<p>Empaquetage du résultat</p>',
    eus: '<p>Emaitza paketatu da</p>',
    cat: '<p>Empaquetat resultat</p>',
  },

  iota_process_pdf_Data38: {
    es: '<p>Compresión de nube de puntos</p>',
    en: '<p>Compression of point cloud</p>',
    pt: '<p>Compressão de nuvem de pontos</p>',
    fr: '<p>Compression du nuage de points</p>',
    eus: '<p>Puntu hodeia konprimatu da</p>',
    cat: '<p>Compressió de núvol de punts</p>',
  },

  iota_process_pdf_Data39: {
    es: '<p>Compresión de modelo 3D</p>',
    en: '<p>Compression of 3D model</p>',
    pt: '<p>Compressão de modelo 3D</p>',
    fr: '<p>Compression du modèle 3D</p>',
    eus: '<p>3D eredua konprimatu da</p>',
    cat: '<p>Compressió de model 3D</p>',
  },

  iota_process_pdf_Data40: {
    es: '<p>N/D</p>',
    en: '<p>N/D</p>',
    pt: '<p>N/D</p>',
    fr: '<p>N/D</p>',
    eus: '<p>Ez dago eskuragarri</p>',
    cat: '<p>N/D</p>',
  },
};
