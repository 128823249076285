export const FOOTER_I18N_ENTRIES = {

  footer_Data1: {
    es: '<p>Inicio</p>',
    en: '<p>Home</p>',
    pt: '<p>Início</p>',
    fr: '<p>Démarrer</p>',
    eus: '<p>Hasiera</p>',
    cat: '<p>Inici</p>',
  },

  footer_Data2: {
    es: '<p>Nuevo 3D</p>',
    en: '<p>New 3D</p>',
    pt: '<p>Novo 3D</p>',
    fr: '<p>Nouveau 3D</p>',
    eus: '<p>3D berria</p>',
    cat: '<p>Nou 3D</p>',
  },

  footer_Data3: {
    en: '<p>Gallery</p>',
    es: '<p>Galería</p>',
    pt: '<p>Galeria</p>',
    fr: '<p>Galerie</p>',
    eus: '<p>Galeria</p>',
    cat: '<p>Galeria</p>',
  },

  footer_Data4: {
    es: '<p>Perfil</p>',
    en: '<p>Profile</p>',
    pt: '<p>Perfil</p>',
    fr: '<p>Profil</p>',
    eus: '<p>Profila</p>',
    cat: '<p>Perfil</p>',
  },

  footer_Data5: {
    es: '<p>Descarga la aplicación:</p>',
    en: '<p>Download the app:</p>',
    pt: '<p>Transfira a aplicação:</p>',
    fr: '<p>Téléchargez l\'application :</p>',
    eus: '<p>Deskargatu aplikazioa:</p>',
    cat: '<p>Baixa l\'aplicació:</p>',
  },

  footer_Data6: {
    es: '<p>Síguenos en:</p>',
    en: '<p>Follow us on:</p>',
    pt: '<p>Siga-nos em:</p>',
    fr: '<p>Suivez-nous sur :</p>',
    eus: '<p>Jarrai iezaguzu:</p>',
    cat: '<p>Segueix-nos a:</p>',
  },

  footer_Data7: {
    es: '<p>© 2024 app.eyescloud3d.com es un producto de ecaptureDtech. eyesCloud3D trabaja con protocolo de seguridad IOTA Tangle.</p>',
    en: '<p>© 2024 app.eyescloud3d.com is an ecaptureDtech product. eyesCloud3D works with the IOTA Tangle security protocol.</p>',
    pt: '<p>© 2024 app.eyescloud3d.com é um produto da ecaptureDtech. eyesCloud3D trabalha com protocolo de segurança IOTA Tangle.</p>',
    fr: '<p>© 2024 app.eyescloud3d.com est un produit de ecaptureDtech. eyesCloud3D travaille avec le protocole de sécurité IOTA Tangle.</p>',
    eus: '<p>© 2024 app.eyescloud3d.com ecaptureDtech-en produktua da. eyesCloud3D tresnak IOTA Tangle segurtasun protokoloa erabiltzen du.</p>',
    cat: '<p>© 2024 app.eyescloud3d.com és un producte d’ecaptureDtech. eyesCloud3D treballa amb el protocol de seguretat IOTA Tangle.</p>',
  },

  footer_Data8: {
    es: '<p>Términos de uso</p>',
    en: '<p>Terms of use</p>',
    pt: '<p>Termos de utilização</p>',
    fr: '<p>Conditions générales d’utilisation</p>',
    eus: '<p>Erabilera baldintzak</p>',
    cat: '<p>Termes d’ús</p>',
  },

  footer_Data9: {
    es: '<p>Política de privacidad</p>',
    en: '<p>Privacy policy</p>',
    pt: '<p>Política de privacidade</p>',
    fr: '<p>Politique de confidentialité</p>',
    eus: '<p>Pribatutasun politika</p>',
    cat: '<p>Política de privadesa</p>',
  },

  footer_Data10: {
    es: '<p>Política de cookies</p>',
    en: '<p>Cookies policy</p>',
    pt: '<p>Política de cookies</p>',
    fr: '<p>Politique de cookies</p>',
    eus: '<p>Cookie politika</p>',
    cat: '<p>Política de galetes</p>',
  },

  footer_Data11: {
    es: '<p>Contratación</p>',
    en: '<p>Terms and Conditions</p>',
    pt: '<p>Contratação</p>',
    fr: '<p>Conditions générales de vente</p>',
    eus: '<p>Kontratazio baldintzak</p>',
    cat: '<p>Contratació</p>',
  },

  footer_Data12: {
    es: '<p>AN IDEA BY</p>',
    en: '<p>AN IDEA BY</p>',
    pt: '<p>AN IDEA BY</p>',
    fr: '<p>AN IDEA BY</p>',
    eus: '<p>AN IDEA BY</p>',
    cat: '<p>AN IDEA BY</p>',
  },
};
