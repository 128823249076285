import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Model } from 'src/app/models/model';
import { ViewerType } from 'src/app/viewer/models/viewer-type';

import { CommsData } from '../models/comms/comms-data';
import { GeneralUserType } from 'src/app/commons/models/general-user-type';
import { User } from 'src/app/commons/models/user/user';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommsService {
  private subject = new ReplaySubject<Model>(1);
  private viewerType = new ReplaySubject<ViewerType>(1);
  private isMireia = new ReplaySubject<boolean>(1);
  private generalUser = new ReplaySubject<GeneralUserType>(1);

  private currentUser = new BehaviorSubject<User>(null);
  private userUpdateSubject = new BehaviorSubject<number>(null);

  private remainingSecondsSubject = new ReplaySubject<number>(1);

  private userUpdate: number;

  private languageChanged = new BehaviorSubject<string>(null);

  private navigationToLoadComesFromApp = new BehaviorSubject<boolean>(false);

  constructor() {
    this.userUpdate = 1;
  }

  sendData(data) {
    this.subject.next(data);
  }

  getData(): Observable<Model> {
    return this.subject.asObservable();
  }

  sendViewerType(data) {
    this.viewerType.next(data);
  }

  getViewerType(): Observable<ViewerType> {
    return this.viewerType.asObservable();
  }

  removeData() {
    this.subject.next(null);
  }

  getIsMireia(): Observable<boolean> {
    return this.isMireia.asObservable();
  }

  sendIsMireia(value: boolean) {
    this.isMireia.next(value);
  }

  getGeneralUser(): Observable<GeneralUserType> {
    return this.generalUser.asObservable();
  }

  sendGeneralUser(value: GeneralUserType) {
    this.generalUser.next(value);
  }

  setCurrentUser(user: User): void {
    this.currentUser.next(user);
  }

  getCurrentUser(): Observable<User> {
    return this.currentUser.asObservable().pipe(filter((ele) => ele !== null));
  }

  setNavigationToLoadComesFromApp(state: boolean): void {
    this.navigationToLoadComesFromApp.next(state);
  }

  getNavigationToLoadComesFromApp(): Observable<boolean> {
    return this.navigationToLoadComesFromApp.asObservable();
  }

  /**
   * Realiza una petición para actualizar los datos del usuario actual.
   */
  public requestUserUpdate(): void {
    this.userUpdate++;
    this.userUpdateSubject.next(this.userUpdate);
  }

  /**
   * Escucha peticiones de actualización de los datos del usuario actual.
   *
   * @returns Irrelevante, se usa para emitir siempre un valor nuevo.
   */
  public onRequestUserUpdate(): Observable<number> {
    return this.userUpdateSubject
      .asObservable()
      .pipe(filter((ele) => ele !== null));
  }

  public setRemainingSeconds(remainingSeconds: number): void {
    this.remainingSecondsSubject.next(remainingSeconds);
  }

  public getRemainingSeconds(): Observable<number> {
    return this.remainingSecondsSubject
      .asObservable()
      .pipe(filter((ele) => ele !== null));
  }

  // Métodos para manejar el cambio de idioma
  public setLanguage(language: string): void {
    this.languageChanged.next(language);
  }
  public getLanguage(): Observable<string> {
    return this.languageChanged.asObservable();
  }
}
