import { Tools } from './tools';

/**
 * Array with all tools inside the measure group, for quick access.
 */
export const ALL_MEASURE_TOOLS: Tools[] = [
  Tools.MEASURE,
  Tools.HEIGHT,
  Tools.AREA,
  Tools.VOLUME,
  Tools.SURFACE,
  Tools.MULTIPLE,
  Tools.CONTOUR,
];

export const ALL_MULTIPLE_MEASURE_TOOLS: Tools[] = [
  Tools.MEASURE_MULTIPLE,
  Tools.HEIGHT_MULTIPLE,
  Tools.AREA_MULTIPLE,
  Tools.VOLUME_MULTIPLE,
  Tools.CONTOUR_MULTIPLE,
  Tools.SURFACE_MULTIPLE,
  Tools.SURFACE_MEASURE_MULTIPLE,
  Tools.SURFACE_AREA_MULTIPLE,
  Tools.SURFACE_VOLUME_MULTIPLE,
];

/**
 * Array with all tools inside the scale group, for quick access.
 */
export const ALL_SCALE_TOOLS: Tools[] = [
  Tools.SCALE,
  Tools.COORDINATE,
  Tools.REFERENCE_SYSTEM,
  Tools.GEOREFERENCE_SYSTEM,
  Tools.FLOOR,
  Tools.Z_AXIS,
];

/**
 * Array with all tools inside the modify group, for quick access.
 */
export const ALL_MODIFY_TOOLS: Tools[] = [
  Tools.NOTES,
  Tools.CLIPPING,
  Tools.THREE_D_MATCHING,
  Tools.MODEL_JOIN,
  Tools.OBJECT_SELECTION,
  Tools.SECTION,
];

/**
 * Array with all tools inside the view group, for quick access.
 */
export const ALL_VIEW_TOOLS: Tools[] = [
  Tools.PROJECTION,
  Tools.ELEVATION_MAP,
  Tools.VERTEX_POINTS,
  Tools.VIRTUAL_TOUR,
  Tools.SEGMENTATION,
];

/**
 * Array with all tools inside the movement group, for quick access.
 */
export const ALL_MOVEMENT_TOOLS: Tools[] = [
  Tools.INITIAL_POSITION,
  Tools.ROTATION_CENTER,
  Tools.RESTORE,
];

/**
 * Array with all tools inside the security addons group, for quick access.
 */
export const ALL_ADDONS_SECURITY_TOOLS: Tools[] = [
  Tools.DEFORMATION_ENERGY,
  Tools.THREE_D_PROJECTION,
  Tools.BLOOD_SPLATTER,
  Tools.BULLET_PATH,
  Tools.INTERIOR_BLUEPRINTS,
  Tools.EXTERIOR_INTERIOR,
  Tools.OFFLINE_VIEWER,
  Tools.RUN_OVER,
  Tools.BRAKING_FOOTPRINT
];

/**
 * Array with all tools inside the image addons group, for quick access.
 */
export const ALL_ADDONS_IMAGE_TOOLS: Tools[] = [
  Tools.POINT_TO_POINT,
  Tools.PHOTORECTIFICATION,
];
