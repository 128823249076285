<div class="iota-container"*ngIf="showIOTA" (window:resize)="onResize()">
  <div class="close-container">
    <img class="close-icon" src="../../../../assets/images/main/iota-cookies/close.svg" (click)="acceptIOTA()">
  </div>

  <div class="iota-text-container">
    <p class="protocol" [innerHTML]="'iota_Dialog_Data1' | i18n"></p>
    <p class="title" [innerHTML]="'iota_Dialog_Data2' | i18n"></p>
    <p class="security" [innerHTML]="!is1536 ? ('iota_Dialog_Data3' | i18n) : ('iota_Dialog_Data4' | i18n)"></p>
  </div>

  <div class="iota-btn" [innerHTML]="'iota_Dialog_Data5' | i18n" (click)="acceptIOTA()"></div>
</div>
