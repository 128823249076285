export const VIEWPROJECT_INCIDENCES_I18N_ENTRIES = {
  viewproject_incidences_Data1: {
    es: '<p>Incidencias</p>',
    en: '<p>Incidents</p>',
    pt: '<p>Incidências</p>',
    fr: '<p>Incidents</p>',
    eus: '<p>Arazoak</p>',
    cat: '<p>Incidències</p>',
  },

  viewproject_incidences_Data2: {
    es: '<p>¿Tienes alguna incidencia</p><p>sobre este modelo 3D?</p>',
    en: '<p>Do you have any incidents</p><p>related to this 3D model?</p>',
    pt: '<p>Tem alguma incidência</p><p>relacionada com este modelo 3D?</p>',
    fr: '<p>Avez-vous un incident à signaler</p><p>par rapport à ce modèle 3D ?</p>',
    eus: '<p>Arazorik duzu</p><p>3D eredu honekin?</p>',
    cat: '<p>Tens cap incidència</p><p>sobre aquest model 3D?</p>',
  },

  viewproject_incidences_Data3: {
    es: '<p>Escribir Incidencia</p>',
    en: '<p>Report an incident</p>',
    pt: '<p>Escrever incidência</p>',
    fr: '<p>Signaler incident</p>',
    eus: '<p>Eman arazo baten berri</p>',
    cat: '<p>Escriu una incidència</p>',
  },

  viewproject_incidences_Data4: {
    es: '<p>Estado de incidencias</p>',
    en: '<p>Incident status</p>',
    pt: '<p>Estado de incidências</p>',
    fr: '<p>État des incidents</p>',
    eus: '<p>Arazoen egoera</p>',
    cat: '<p>Estat de les incidències</p>',
  },

  viewproject_incidences_Data5: {
    es: '<p>En revisión</p>',
    en: '<p>Under review</p>',
    pt: '<p>Em revisão</p>',
    fr: '<p>En cours de traitement</p>',
    eus: '<p>Berrikusketan</p>',
    cat: '<p>En revisió</p>',
  },

  viewproject_incidences_Data6: {
    es: '<p>Resuelta</p>',
    en: '<p>Resolved</p>',
    pt: '<p>Resolvida</p>',
    fr: '<p>Résolu</p>',
    eus: '<p>Konponduta</p>',
    cat: '<p>Resolta</p>',
  },

  viewproject_incidences_Data7: {
    es: '<p>Cerrada</p>',
    en: '<p>Closed</p>',
    pt: '<p>Fechada</p>',
    fr: '<p>Clos</p>',
    eus: '<p>Itxita</p>',
    cat: '<p>Tancada</p>',
  },

  viewproject_incidences_Data8: {
    es: '<p>[ VER TODAS ]</p>',
    en: '<p>[ VIEW ALL ]</p>',
    pt: '<p>[ VER TODAS ]</p>',
    fr: '<p>[ VOIR TOUT ]</p>',
    eus: '<p>[ IKUSI GUZTIAK ]</p>',
    cat: '<p>[ MOSTRA TOTES ]</p>',
  },

  viewproject_incidences_Data9: {
    es: '<p>Actualmente no tienes incidencias</p><p>en proceso</p>',
    en: '<p>You currently have no incidents in progress</p>',
    pt: '<p>Atualmente não tem incidências</p><p>em curso</p>',
    fr: '<p>Vous n\'avez actuellement</p><p>aucun incident en cours</p>',
    eus: '<p>Une honetan ez daukazu</p><p>arazorik abian</p>',
    cat: '<p>Actualment no hi ha incidències</p><p>en curs</p>',
  },

  viewproject_incidences_Data10: {
    es: '<p>Detalla la incidencia de tu modelo 3D:</p>',
    en: '<p>Describe the incident with your 3D model:</p>',
    pt: '<p>Detalhe a incidência do seu modelo 3D:</p>',
    fr: '<p>Détaillez l\'incident concernant votre modèle 3D :</p>',
    eus: '<p>Zehaztu 3D ereduarekin duzun arazoa:</p>',
    cat: '<p>Detalla la incidència del model 3D:</p>',
  },

  viewproject_incidences_Data11: {
    es: '<p>Escribe la incidencia</p>',
    en: '<p>Report the incident</p>',
    pt: '<p>Escreva a incidência</p>',
    fr: '<p>Décrivez l\'incident</p>',
    eus: '<p>Eman arazoaren berri</p>',
    cat: '<p>Escriu la incidència</p>',
  },

  viewproject_incidences_Data12: {
    es: '<p>¿Deseas adjuntar algún archivo de imagen?</p>',
    en: '<p>Do you want to attach an image file?</p>',
    pt: '<p>Pretende anexar um ficheiro de imagem?</p>',
    fr: '<p>Souhaitez-vous joindre un fichier d\'image?</p>',
    eus: '<p>Irudi fitxategirik erantsi nahi duzu?</p>',
    cat: '<p>Vols adjuntar algun fitxer d\'imatge?</p>',
  },

  viewproject_incidences_Data13: {
    es: '<p>Adjuntar un archivo</p>',
    en: '<p>Attach a file</p>',
    pt: '<p>Anexar um ficheiro</p>',
    fr: '<p>Joindre un fichier</p>',
    eus: '<p>Erantsi fitxategi bat</p>',
    cat: '<p>Adjunta un fitxer</p>',
  },

  viewproject_incidences_Data14: {
    es: '<p>(máx. 5MB)</p>',
    en: '<p>(max. 5MB)</p>',
    pt: '<p>(máx. 5 MB)</p>',
    fr: '<p>(5 Mo maxi.)</p>',
    eus: '<p>(geh. 5MB)</p>',
    cat: '<p>(màx. 5 MB)</p>',
  },

  viewproject_incidences_Data15: {
    es: '<p>Enviar Incidencia</p>',
    en: '<p>Submit Incident</p>',
    pt: '<p>Enviar incidência</p>',
    fr: '<p>Envoyer rapport d\'incident</p>',
    eus: '<p>Bidali arazoa</p>',
    cat: '<p>Envia la incidència</p>',
  },

  viewproject_incidences_Data16: {
    es: '<p>Incidencia enviada correctamente</p>',
    en: '<p>Your incident has been submitted successfully</p>',
    pt: '<p>Incidência enviada corretamente</p>',
    fr: '<p>Rapport d\'incident envoyé correctement</p>',
    eus: '<p>Arazoa behar bezala bidali da</p>',
    cat: '<p>La incidència s\'ha enviat correctament</p>',
  },

  viewproject_incidences_Data17: {
    es: '<p>Nos pondremos en contacto contigo</p><p>en la mayor brevedad posible.</p>',
    en: '<p>We will contact you</p><p>as soon as possible.</p>',
    pt: '<p>Entraremos em contacto consigo</p><p>o mais brevemente possível.</p>',
    fr: '<p>Nous vous contacterons</p><p>dans les meilleurs délais.</p>',
    eus: '<p>Zurekin harremanetan jarriko gara</p><p>ahalik eta lasterren.</p>',
    cat: '<p>Ens posarem en contacte amb tu</p><p>al més aviat.</p>',
  },

  viewproject_incidences_Data18: {
    es: '<p>Aceptar</p>',
    en: '<p>Accept</p>',
    pt: '<p>Aceitar</p>',
    fr: '<p>Accepter</p>',
    eus: '<p>Onartu</p>',
    cat: '<p>Accepta</p>',
  },

  viewproject_incidences_Data19: {
    es: '<p>No se ha podido</p><p>enviar la incidencia.</p>',
    en: '<p>Failed to submit</p><p>the incident.</p>',
    pt: '<p>Não foi possível</p><p>enviar a incidência.</p>',
    fr: '<p>Le rapport d\'incident</p><p>n\'a pas pu être envoyé.</p>',
    eus: '<p>Ezin izan da</p><p>zure arazoa bidali.</p>',
    cat: '<p>No s\'ha pogut enviar</p><p>la incidència.</p>',
  },

  viewproject_incidences_Data20: {
    es: '<p>Inténtalo de nuevo.</p>',
    en: '<p>Please try again.</p>',
    pt: '<p>Tente novamente.</p>',
    fr: '<p>Veuillez réessayer.</p>',
    eus: '<p>Berriz saiatu.</p>',
    cat: '<p>Torna a provar-ho.</p>',
  },
  viewproject_incidences_Data21: {
    es: '<p>Escribe tu mensaje…</p>',
    en: '<p>Write your message...</p>',
    pt: '<p>Escreva a sua mensagem…</p>',
    fr: '<p>Écrivez votre message…</p>',
    eus: '<p>Idatzi zure mezua...</p>',
    cat: '<p>Escriu el teu missatge...</p>'
  },
};
